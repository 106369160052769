import { Component, OnInit } from '@angular/core';
import { Building } from '../../classes/building';
import { BillingBuilding, BillingService } from "../../shared/billing.service";

@Component({
  selector: 'app-billing-buildings-list',
  templateUrl: './billing-buildings-list.component.html',
  styleUrls: ['./billing-buildings-list.component.css']
})
export class BillingBuildingsListComponent implements OnInit {

  static readonly STORAGE_KEY = 'app-billing-buildings-list:sort';
  list: BillingBuilding[];
  building: Building;
  sort: any = {};
  mustHaveGateways = true;
  isBillable = 1;

  constructor(private billingService: BillingService) {
    try {
      const sort = localStorage.getItem(BillingBuildingsListComponent.STORAGE_KEY);
      if (sort) {
        this.sort = JSON.parse(sort);
      }
    } catch (e) {
      console.log(e);
    }
  }

  billableClick() {
    this.isBillable ? this.isBillable = 0 : this.isBillable = 1;
    this.get();
  }

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.billingService
      .getList("isbillable=" + this.isBillable)
      .then(r => {
        this.list = r.filter(b => b.building.counts.gateways >= 0);
        this.sortData();
      });
  }

  sortData() {
    const field = Object.keys(this.sort)[0];
    const stateInt = this.sort[field] === 'asc' ? 1 : -1;
    this.list.sort((a, b) => {
      let v1, v2;
      switch (field) {
        case 'address':
          v1 = a.building.site.addressCounty;
          v2 = b.building.site.addressCounty;
          break;
        case 'gateways':
          v1 = a.building.counts.gateways;
          v2 = b.building.counts.gateways;
          break;
        case 'licenseExpiresAt':
          v1 = +a.building.license.expiresAt;
          v2 = +b.building.license.expiresAt;
          break;
        case 'org':
          v1 = a.building.org.title;
          v2 = b.building.org.title;
          break;

        default:
          v1 = a.building[field];
          v2 = b.building[field];
          break;
      }
      return v1 > v2 ? stateInt : stateInt * -1;
    });
  }

  headerClicked(field: string) {
    let state = this.sort[field];
    this.sort = {};
    switch (state) {
      case 'asc':
        state = 'desc';
        break;
      case 'desc':
        state = null;
        break;
      default:
        state = 'asc';
        break;
    }
    this.sort[field] = state;
    localStorage.setItem(BillingBuildingsListComponent.STORAGE_KEY, JSON.stringify(this.sort));
    this.get();
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acceptable-terms',
  templateUrl: './acceptable-terms.component.html',
  styleUrls: ['./acceptable-terms.component.css']
})
export class AcceptableTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0);
  }

}

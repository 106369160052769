<article>
	<app-toolbar>
		<div class="left">
			<app-button label="Saved Reports" icon="mdi mdi-account-details-outline" (click)="loadSavedReport()"></app-button>
			<app-button label="Start New Report" icon="mdi mdi-new-box" (click)="startNewReport()"></app-button>
		</div>
		<div class="right">
			@if(_results()) {
			<app-button [label]="exportItem().id ? 'Update Saved Report' : 'Save Report'" [icon]="exportItem().id ? 'mdi mdi-pencil' : 'mdi mdi-plus' " (click)="clickSaveReport()"></app-button>
			<app-button label="Download excel" icon="mdi mdi-file-excel" (click)="exportToExcel()"></app-button>
			}
			<app-button label="Run Report" icon="mdi mdi-play" (click)="run()"></app-button>
		</div>
	</app-toolbar>
	@if(exportItem()) {
	<app-toolbar>
		<app-button-trigger caption="Trigger" [exportItem]="exportItem()" (onSelect)="triggerSelected($event)"></app-button-trigger>
		<app-button-dates caption="Dates" [value]="exportItem()" (onSelect)="datesSelected($event)"></app-button-dates>
		<app-button-assets caption="Sensors" [selectedItems]="exportAssets()" (onSelect)="assetsSelected($event)"></app-button-assets>
		<app-button-sensors caption="Sensor Types" [selectedItems]="exportItem().assetTypeIds"  (onSelect)="sensorsSelected($event)"></app-button-sensors>
	</app-toolbar>
	}
	<app-panel [header]="'Results'">
		@if(_results()) {
		<div class="fourdtabs">
			<div (click)="tabIndexResults.set(0)" [class.active]="tabIndexResults()===0"><span>Telemetry</span>
			</div>
			<div (click)="tabIndexResults.set(1)" [class.active]="tabIndexResults()===1"><span>Sensors</span>
			</div>
			<div></div>
		</div>

		@switch(tabIndexResults()) {
		@case(1) {
		<table class="table table-hover" aria-label="Sensors">
			<thead>
				<tr>
					@if(isAdmin()) {
					<th>Id</th>
					}
					<th>Sensor</th>
					<th colspan="2">Gateway</th>
					<th>Site</th>
				</tr>
			</thead>
			<tbody>
				@for(asset of _results().assets; track asset) {
				<tr (click)="assetClick(asset, null)">
					@if(isAdmin()) {
					<td>{{asset.id}}</td>
					}
					<td>{{asset.title}}</td>
					<td>{{asset.gatewayId}}</td>
					<td>{{asset.gTitle}}</td>
					<td>{{asset.siteTitle}}</td>
				</tr>
				}
			</tbody>
		</table>
		}
		@default {
		<table class="table table-hover" aria-label="Telemetry">
			<thead>
				<tr>
					@if(isAdmin()) {
					<th>Id</th>
					}
					<th>Sensor</th>
					<th><app-table-header-item label="Gateway" [value]="_gatewayState()" (onHeaderClick)="headerClick('gateway', $event)" /></th>
					<th><app-table-header-item label="Site" [value]="_siteState()" (onHeaderClick)="headerClick('site', $event)" /></th>
					<th><app-table-header-item label="Value" [value]="_valueState()" (onHeaderClick)="headerClick('value', $event)" /></th>
					<th><app-table-header-item label="Ingested" [value]="_ingestedState()" (onHeaderClick)="headerClick('ingested', $event)" /></th>

				</tr>
			</thead>
			<tbody>
				@for(value of _results().values; track value) {
				<tr (click)="assetClick(value.a, value.d)">
					@if(isAdmin()) {
					<td>{{value.a.id}}</td>
					}
					<td>{{value.a.title}}</td>
					<td>{{value.a.gTitle}}</td>
					<td>{{value.a.siteTitle}}</td>
					<td>{{value.v}}</td>
					<td>{{value.d | date:'dd MMM YY HH:mm'}}</td>
					}
			</tbody>
		</table>
		}
		}
		@if(asset()) {
		<app-dialog (onClose)="asset.set(null)">
			<app-explorer-asset [asset]="asset()" [alwaysUseCustom]="true" [setpointQueryDateRange]="setpointQueryDateRange()"></app-explorer-asset>
		</app-dialog>
		}
		} @else {
		<app-info-panel>No results</app-info-panel>
		}
	</app-panel>
</article>
@if(isWorking()) {
<app-please-wait></app-please-wait>
}

@if(wantsToSaveReport()) {
<app-dialog [dimensions]="{ width: 480, height: 200}" [canSave]="true" (onClose)="saveReport($event)">
	<app-data-form [dataForm]="saveReportDataForm()">
	</app-data-form>
</app-dialog>
}

@if(hasDialogActive()) {
@switch (hasDialogActive()) {
@case('reports') {
<app-dialog [canScroll]="true" [width]="600" (onClose)="hasDialogActive.set(null)">
	<table class="table table-hover" aria-label="Reports">
		<thead>
			<tr>
				<th>Title</th>
				<th>Author</th>
				<th>Created</th>
			</tr>
		</thead>
		<tbody>
			@for(report of reports();track report) {
			<tr (click)="clickReport(report)">
				<td>{{report.title}}</td>
				<td>{{report.createdBy}}</td>
				<td>{{report.createdAt | date:'dd MMM YY'}}</td>
			</tr>
			}
		</tbody>
	</table>
</app-dialog> }
}
}

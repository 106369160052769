import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ServiceForTenants } from 'app/classes/service-for-tenant';
import { APIService } from 'app/shared/api.service';
import { TenantService } from 'app/shared/tenant.service';

@Component({
  selector: 'app-dialog-tenant-service-edit',
  templateUrl: './dialog-tenant-service-edit.component.html',
  styleUrls: ['./dialog-tenant-service-edit.component.css']
})
export class DialogTenantServiceEditComponent {
  @Input() servicesForTenants: ServiceForTenants;
  @Output() onClose = new EventEmitter<boolean>;

  constructor(private tenantService: TenantService, private apiService: APIService) {

  }

  cancel() {
    this.onClose.emit(false);
  }

  async submit() {
    if (!this.servicesForTenants.id) {
      const response = await this.tenantService.createServicesForTenants(this.servicesForTenants);
      this.servicesForTenants.id = response.id;
    } else {
      await this.tenantService.updateServiceForTenants(this.servicesForTenants);
    }
    this.onClose.emit(true);
  }

}

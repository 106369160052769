import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { StoreService } from "../../shared/store.service";
import { Site } from "../../classes/site";
import { SiteService } from "../../shared/site.service";

@Component({
  selector: 'app-site-picker',
  templateUrl: './site-picker.component.html',
  styleUrls: ['./site-picker.component.css']
})
export class SitePickerComponent implements OnInit {
  @Output()
  submit: EventEmitter<Site> = new EventEmitter<Site>();
  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  sites: Site[];

  constructor(private siteService: SiteService, private router: Router, private route: ActivatedRoute, private storeService: StoreService, private apiService: APIService) {
  }

  ngOnInit() {
    this.siteService.getSites().then(sites => {
      this.sites = sites;
      this.getFavSites();
    });
  }

  selectSite(site: Site) {
    this.submit.emit(site);
  }

  favSite(site: Site) {
    const favs = this.siteService.toggleFavouriteSite(site);
    this.getFavSites();
  }

  getFavSites() {
    this.sites.map(s => s.isFav = false);
    this.siteService.getFavouriteSites().map(i => {
      const site = this.sites.find(s => s.id === i);
      if (site) {
        site.isFav = true;
      }
    });
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { ReportService } from '../../shared/report.service';
import { Asset } from '../../classes/asset';
import { ReportBlock } from '../../classes/reports/report-block';
import { APIService } from 'app/shared/api.service';
import moment from 'moment';
import { AssetService } from 'app/shared/asset.service';

@Component({
  selector: 'app-report-telemetry-min-max',
  templateUrl: './report-telemetry-min-max.component.html',
  styleUrls: ['./report-telemetry-min-max.component.css']
})
export class ReportTelemetryMinMaxComponent implements OnInit {

  // Raw data received from the API
  @Input()
  set data(data: any[]) {
    this._data = data;
  }
  _data: any;

  @Input()
  set config(data: any) {
    const hasData = typeof this._config !== 'undefined';
    if (data) {
      this._config = data;
      this.startsAt = moment(data.startAt);
      this.endsAt = moment(data.endAt);
      if (hasData) {
        // Fresh data received
        this.prepareData();
      }
    }
  }
  _config: any;

  startsAt: any;
  endsAt: any;

  @Input()
  reportBlock: ReportBlock;

  @Input()
  blockReady: boolean;

  canAlert: boolean;
  selectedIndex: number = null;

  // Take the raw "data", generate the chart data into "tableData"
  tableData: any;
  assetKeys: any[];
  days: any[] = [{ line1: 'Sensor', line2: '' }, 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  daysLine2: any[] = ['', '', '', '', '', '', '', ''];
  isShowingPopupSensorData: boolean;
  selectedAsset: Asset;

  constructor(private assetService: AssetService, private reportService: ReportService, private apiService: APIService) { }

  ngOnInit() {
    this.prepareData();
  }

  drill(asset: Asset, index: number) {
    if (this.selectedIndex === index) {
      this.selectedIndex = null;
    } else {
      this.selectedIndex = index;
    }
  }

  prepareData() {
    let table = {};
    let dateFrom, dayFrom;

    dateFrom = new Date(this._config.startAt);
    let weekData;
    if (this._config.fragment_id === 8) {
      this.canAlert = true;
      // operational min/max has ranges array
      weekData = this._data[0];
    }
    if (this._config.fragment_id === 7) {
      // non-operational min/max does not have ranges array
      weekData = this._data;
    }
    if (this._config.fragment_id === 15) {
      // non-operational min/max does not have ranges array
      weekData = this._data;
    }
    this.tableData = [];
    for (var dayx = 0; dayx < 7; dayx++) {
      let dayFrom = dateFrom.getDate();
      let dtDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][dateFrom.getDay()];
      this.days[dayx + 1] = { line1: dtDay, line2: dateFrom.getDate() + '/' + (dateFrom.getMonth() + 1) };

      dateFrom.setDate(dateFrom.getDate() + 1);
    }
    dateFrom.setDate(dateFrom.getDate() - 7);

    // Process 7 days
    for (var dayx = 0; dayx < 7; dayx++) {
      dayFrom = dateFrom.getDate();

      let value: any = { min: '-', max: '-', orangeMinAlert: 0, redMinAlert: 0, orangeMaxAlert: 0, redMaxAlert: 0, setpoint: {} };
      for (var idx = 0; idx < weekData.length; idx++) {
        if (weekData[idx].day == dayFrom) {
          const assetId = "a" + weekData[idx].id;
          value = {
            min: weekData[idx].min.toFixed(1),
            max: weekData[idx].max.toFixed(1),
            orangeMinAlert: weekData[idx].minOrangeAlert,
            orangeMaxAlert: weekData[idx].maxOrangeAlert,
            redMaxAlert: weekData[idx].maxAlert,
            redMinAlert: weekData[idx].minAlert,
            setpoint: {}
          };

          if (this.canAlert) {
            value.setpoint = this._data[1].filter(sp => sp.asset_id === weekData[idx].id)[0];
            if (value.setpoint) {
              if (value.redMaxAlert || value.redMinAlert) {
                value.setpoint.min = value.setpoint.red_min;
                value.setpoint.max = value.setpoint.red_max;
              } else {
                value.setpoint.min = value.setpoint.amber_min;
                value.setpoint.max = value.setpoint.amber_max;
              }
            }
          }

          if (!table[assetId]) {
            table[assetId] = { title: weekData[idx].title, asset_id: weekData[idx].id, values: ['', '', '', '', '', '', ''], inRedAlert: 0, inOrangeAlert: 0 };
          }
          table[assetId].values[dayx] = value;
          table[assetId].inRedAlert += weekData[idx].minAlert + weekData[idx].maxAlert;
          table[assetId].inOrangeAlert += weekData[idx].minOrangeAlert + weekData[idx].maxOrangeAlert;
        }
      }
      dateFrom.setDate(dateFrom.getDate() + 1);
    }
    // Map JSON to array
    this.assetKeys = Object.keys(table);
    this.tableData = this.assetKeys.map(v => table[v]);
  }

  showPopup() {
    this.assetService.getAsset(this.tableData[this.selectedIndex].asset_id).then(asset => {
      this.selectedAsset = asset;
      this.isShowingPopupSensorData = true;
    });
  }
}

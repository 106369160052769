@defer(when sites) {
<div class="col-xs-12">
  <div class="component-list">
    <table class="table table-sm table-hover" aria-label="Sites">
      <thead>
        <tr>
          <th></th>
          <th>Site</th>
          <th class="hidden-xs">Address</th>
          <th>PostCode</th>
          <th>Org</th>
        </tr>
      </thead>
      <tbody>
        @for(site of sites;track site.id){
        <tr>
          <td (click)="favSite(site)">
            <i class="fa" [class.fa-star]="site.isFav" [class.fa-star-o]="!site.isFav"></i>
          </td>
          <td (click)="selectSite(site)">{{site.title}} </td>
          <td (click)="selectSite(site)" class="hidden-xs">{{site.address.address1}}</td>
          <td (click)="selectSite(site)">{{site.address.addressPostcode}}</td>
          <td (click)="selectSite(site)">{{site.org.shortTitle}}</td>
        </tr>
        }
      </tbody>
    </table>
  </div>
</div>
} @placeholder {
<i class="mdi mdi-loading mdi-spin-x2"></i>
}

<app-theme-page-section [label]="'elogbooks'" logo="elogbooks">
	<p-tabView>

		<p-tabPanel>
			<ng-template pTemplate="header">
				<span><i class="mdi mdi-chart-box-outline"></i> Audit</span>
			</ng-template>
			<app-theme-page-section *ngIf="stats">
				<table class="table table-sm">
					<thead>
						<tr>
							<th colspan="3">Job</th>
							<th>Rule</th>
							<th>Org</th>
							<th colspan="2">Last Update</th>
							<th>Closed</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let job of stats.jobs">
							<td pTooltip="elogbooks job id">{{job.job_id}}</td>
							<td><a target="_blank"
									href="http://fourd.sandpit.v2.elogbooks.net/#/user/jobs/{{b64(job.job_id)}}/status?jobsPage=1&jobsLimit=30&jobsOrder=-id&fileOrder=-id">{{job.jobdesc}}</a>
							</td>
							<td>{{job.createdAt | amDateFormat:'DD MMM YYYY HH:mm'}}</td>
							<td pTooltip="#{{job.ruleId}}">{{job.ruleTitle}} <span class="chip"
									*ngIf="job.alarmState!=='resolved'">{{job.alarmState}}</span>
							</td>
							<td pTooltip="#{{job.orgId}}">{{job.orgShortTitle}}</td>
							<td>{{job.receivedStatus}}</td>
							<td>
								<span *ngIf="job.receivedStatusAt">
									{{job.receivedStatusAt | amDateFormat:'DD MMM YYYY HH:mm'}}
								</span>
							</td>
							<td>{{job.isFinalStatus ? 'Yes' : 'No'}}</td>
						</tr>
					</tbody>
				</table>
			</app-theme-page-section>
		</p-tabPanel>

		<p-tabPanel>
			<ng-template pTemplate="header">
				<span><i class="mdi mdi-chart-box-outline"></i> Rules</span>
			</ng-template>
			<app-theme-page-section *ngIf="stats">
				<table class="table table-sm">
					<thead>
						<tr>
							<th>Rule</th>
							<th>Org</th>
							<th>Alarm</th>
							<th>Enabled</th>
						</tr>
					</thead>
					<tbody>
						<ng-template ngFor [ngForOf]="stats.rules" let-rule>
							<tr>
								<td pTooltip="#{{rule.id}}">{{rule.title}} </td>
								<td pTooltip="#{{rule.orgId}}">{{rule.orgShortTitle}}</td>
								<td>{{rule.inAlert }}</td>
								<td>{{rule.isEnabled}}</td>
							</tr>
							<tr>
								<td colspan="4">{{rule.settings}}</td>
							</tr>
						</ng-template>
					</tbody>
				</table>
			</app-theme-page-section>
		</p-tabPanel>

		<p-tabPanel>
			<ng-template pTemplate="header">
				<span><i class="fa fa-list"></i> Configuration</span>
			</ng-template>
			<div class="mt-1" *ngIf="integration">
				<app-theme-page-section *ngIf="value">
					<div class="row">
						<div class="col-sm-12 col-xs-12 col-md-6">
							<div class="text-center">
								Job Creation
								<app-d3-pie [fontSize]="20" [data]="data" [innerRing]="35" [legend]="legend" [width]="100"
									[height]="100" [margin]="{top:0, left:0, right:0, bottom:0}" [value]="value"></app-d3-pie>
							</div>
						</div>
						<div class="col-sm-12 col-xs-12 col-md-6">
							<p>How many jobs were accepted by the elogbooks API today.</p>
							<p> <i>Any failures will be investigated by 4D, who are automatically notified.</i></p>
						</div>
					</div>
				</app-theme-page-section>


				<div class="col-sm-12 col-xs-12 col-md-6">
					<div class="form-group" pTooltip="No code exists for alternative">
						<label for="siteContactAvailableOnSite">Site Contact Available OnSite</label>
						<select class="form-control" [(ngModel)]="integration.settings.siteContactAvailableOnSite"
							disabled="disabled">
							<option [value]="true">Yes</option>
							<option [value]="false">No</option>
						</select>
						<small>Is the Site Contact available</small>
					</div>
				</div>

				<div class="col-sm-12 col-xs-12 col-md-6">
					<div class=" form-group" pTooltip="No code exists for alternative">
						<label for="siteContactSameAsReporter">Site Contact Same As Reporter</label>
						<select class="form-control" [(ngModel)]="integration.settings.siteContactSameAsReporter"
							disabled="disabled">
							<option [value]="true">Yes</option>
							<option [value]="false">No</option>
						</select>
						<small>Is the Site Contact the same as the reporter</small>
					</div>
				</div>

				<div class="clearfix"></div>
				<div class="col-sm-12 col-xs-12 col-md-6">
					<div class="form-group">
						<label for="notifyOnCreate">Notify On Create</label>
						<select class="form-control" [(ngModel)]="integration.settings.notifyOnCreate">
							<option [value]="true">Yes</option>
							<option [value]="false">No</option>
						</select>
					</div>
				</div>
				<div class="col-sm-12 col-xs-12 col-md-6">
					<div class="form-group">
						<label for="notifyOnComplete">Notify On Complete</label>
						<select class="form-control" [(ngModel)]="integration.settings.notifyOnComplete">
							<option [value]="true">Yes</option>
							<option [value]="false">No</option>
						</select>
					</div>
				</div>

				<div class="col-sm-12 col-xs-12 col-md-2">
					<label for="url">http/s</label>
					<div class="form-group ">
						<button class="btn" [class.btn-primary]="!integration.settings.https"
							[class.btn-secondary]="integration.settings.https"
							(click)="integration.settings.https=false">http</button>
						<button class="btn" [class.btn-primary]="integration.settings.https"
							[class.btn-secondary]="!integration.settings.https"
							(click)="integration.settings.https=true">https</button>
						<small>
							<i pTooltip="Not secure" class="fa fa-warning amber" *ngIf="!integration.settings.https">&nbsp;not
								secure</i>
						</small>
					</div>
				</div>

				<div class="col-sm-12 col-xs-12 col-md-10">
					<label for="url">elogbooks endpoint</label>
					<div class="form-group ">
						<input type="text" class="form-control" [(ngModel)]="integration.url">
						<small>xxxx.elogbooks.net <a target="_blank" href="{{elogbooksLink}}"> elogbooks</a></small>
					</div>
				</div>

				<div class="col-sm-6 col-xs-12 col-md-3">
					<label for="userid">elogbooks User Id</label>
					<div class="form-group ">
						<div class="input-group">
							<input type="number" class="form-control" [(ngModel)]="integration.settings.userid">
						</div>
						<small>User id to get site lists etc.</small>
					</div>
				</div>

				<div class="col-sm-6 col-xs-12 col-md-3">
					<label for="userid">Client name</label>
					<div class="form-group ">
						<div class="input-group">
							<input type="text" class="form-control" [(ngModel)]="integration.settings.client">
						</div>
						<small>Elogbooks client ref</small>
					</div>
				</div>

				<div class="col-sm-6 col-xs-12 col-md-6">
					<label for="userid">Create when open jobs exist?</label>
					<div class="form-group ">
						<div class="input-group">
							<select class="form-control" [(ngModel)]="integration.settings.duplicate">
								<option [value]="0">Yes, don't check open elogbooks jobs</option>
								<option [value]="1">No, add message to existing job </option>
								<option [value]="2">No, don't do anything</option>
							</select>
						</div>
						<small>How to handle duplicate open jobs.</small>
					</div>
				</div>

				<div class="col-sm-12 col-xs-12 col-md-12">
					<label for="token">elogbooks Token <span *ngIf="isAccidentalClickProtected"
							pTooltip="Accidental modification protection, click to enable modification"
							(click)="isAccidentalClickProtected=!isAccidentalClickProtected">
							<button class="btn btn-sm"><i class="fa fa-lock"></i> Allow edits</button>
						</span>
					</label>
					<div class="form-group" [class.is-disabled]="isAccidentalClickProtected">
						<textarea rows="9" type="text" class="form-control" [(ngModel)]="integration.settings.token"
							[disabled]="isAccidentalClickProtected"></textarea>
						<small></small>
					</div>
				</div>
				<p-toolbar>
					<div class="p-toolbar-group-left">
						<a href="https://4d-documents.s3.eu-west-2.amazonaws.com/elogbooks-V2AP-Jobs-110521-1041.pdf"
							target="_blank">
							<p-button label="API Docs" icon="mdi mdi-file-pdf-outline"
								styleClass="p-button-sm p-button-outlined p-button-secondary"></p-button>
						</a>
						<p-button label="Get User" icon="mdi mdi-playlist-check"
							styleClass="p-button-sm p-button-outlined p-button-secondary ml-1" (click)="test('user')">
						</p-button>
						<p-button label="Get Sites" icon="mdi mdi-playlist-check"
							styleClass="p-button-sm p-button-outlined p-button-secondary ml-1" (click)="test('sites')">
						</p-button>
					</div>
					<div class="p-toolbar-group-right">
						<p-button label="Save" icon="pi pi-check" styleClass="p-button-sm" (click)="save()"></p-button>
					</div>
				</p-toolbar>
				<div class="clearfix"></div>

			</div>
		</p-tabPanel>

		<p-tabPanel>
			<ng-template pTemplate="header">
				<span><i class="mdi mdi-book-open-variant"></i> Help</span>
			</ng-template>
			<app-theme-page-section>
				<ol>
					<li>Webhooks from elogbooks re-send 3 times if they can't deliver to 4D.</li>
				</ol>
			</app-theme-page-section>
		</p-tabPanel>
	</p-tabView>

</app-theme-page-section>


<app-elogbooks-popup *ngIf="sitesDialog" target="site" [userId]="integration.settings.userid"
	(onDismiss)="elogbooksPopupDismissed($event)">
</app-elogbooks-popup>
<!-- no access
<app-elogbooks-popup *ngIf="userDialog" target="user" (onDismiss)="elogbooksPopupDismissed($event)"
	[id]="integration.settings.userid">
</app-elogbooks-popup>
-->


<app-please-wait *ngIf="isWorking"></app-please-wait>
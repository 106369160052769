import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-system-upload',
  templateUrl: './system-upload.component.html',
  styleUrls: ['./system-upload.component.css']
})
export class SystemUploadComponent implements OnInit, OnDestroy {

  @Output()
  onDidCapture: EventEmitter<any> = new EventEmitter();

  @ViewChild('webcamcontainer', { static: true })
  webcamcontainer: ElementRef;

  isCameraExist = true;

  isFullscreen = true;

  private trigger: Subject<void> = new Subject<void>();

  check: any;
  width: number;
  height: number;
  constructor(private apiService: APIService) { }


  ngOnDestroy(): void {
    clearInterval(this.check);
  }

  ngOnInit(): void {


    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.isCameraExist = mediaDevices && mediaDevices.length > 0;
      });

    this.width = window.innerWidth - 10;
    this.height = window.innerHeight - 46;

    this.check = setInterval(() => {
      //console.log(this.webcamcontainer.nativeElement);
      this.width = window.innerWidth - 10;
      this.height = window.innerHeight - 46;
    }, 1000);
  }

  handleImage(webcamImage: WebcamImage) {
    // this.getPicture.emit(webcamImage);

    this.apiService.toastSuccess('uploading', '');

    const title = 'CAMERA_UPLOAD';
    const hasPrivacyConcerns = false;
    const target = 'camera_upload';
    const targetId = 0;
    const fileType = 'image/jpeg';
    const fileName = 'camera_upload.jpg';
    const imageBase64 = webcamImage.imageData;

    const arr = webcamImage.imageAsDataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], fileName, { type: fileType });
    console.log(file);


    this.apiService.postUploader(title, hasPrivacyConcerns, target, targetId, fileType, fileName, file)
      .then(r => {
        console.log(r);
        this.apiService.toastSuccess('uploaded', '');
        this.onDidCapture.emit(r);
      });
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }



}

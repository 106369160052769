<article>
	<p-tabView>

		<p-tabPanel [cache]="true">
			<ng-template pTemplate="header">
				Categories
			</ng-template>
			<ng-template pTemplate="content">
				<table class="table table-hover table-striped">
					<thead>
						<tr>
							<th>Title</th>
							<th>Notes</th>
							<th pTooltip="">#</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of categories">
							<td>{{item.title}}</td>
							<td [innerHTML]="item.notes"></td>
							<td>-</td>
						</tr>
					</tbody>
				</table>

				<div class="text-right">
					<p-button label="Create Category" icon="mdi mdi-account-plus" styleClass="mt-1" (click)="create()">
					</p-button>
				</div>
			</ng-template>
		</p-tabPanel>
		<!--

		<p-tabPanel [cache]="true">
			<ng-template pTemplate="header">
				Services
			</ng-template>
			<ng-template pTemplate="content">
				<table class="table table-hover table-striped">
					<thead>
						<tr>
							<th>Title</th>
							<th>Notes</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of servicesForTenants" (click)="editServiceForTenants(item)">
							<td>{{item.title}}</td>
							<td [innerHTML]="item.notes"></td>
						</tr>
					</tbody>
				</table>

				<div class="text-right">
					<p-button label="Create Service" icon="mdi mdi-account-plus" styleClass="mt-1"
						(click)="createServiceForTenant()">
					</p-button>
				</div>
			</ng-template>
		</p-tabPanel>
	-->

	</p-tabView>

</article>

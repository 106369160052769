@defer(when areas()) {
@if(areas().length) {
<article [class.is-fullscreen]="isFullscreen()">
	<nav>
		<header>
			<div>Areas</div>
			<div><i class="mdi mdi-fullscreen" (click)="toggleFullscreen()" pTooltip="Toggle fullscreen" tooltipPosition="left"></i></div>
		</header>
		<section>
			@for(areaItem of areas(); track areaItem.id) {
			<div (click)="selectArea(areaItem)" [class.active]="area()?.id === areaItem.id">{{areaItem.title}}</div>
			}
		</section>
	</nav>
	<main>
		@if(area()) {
		<app-foorfall-area [area]="area()" [isFullscreen]="isFullscreen()"></app-foorfall-area>
		}
	</main>
</article>
} @else {
<app-info-panel>No footfall areas exist</app-info-panel>
}
} @placeholder {
<app-please-wait></app-please-wait>
}

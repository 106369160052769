<div class="page-info-2 {{styling}} no-print" [class.minimum]="styling === 'minimum'" [class.has-actions]="actions" [class.has-clicked]="hasClicked" [class.view-detail]="detail" [class.view-overview]="!detail">
    <div class="overview">
        <div class="title">{{title}}</div>
        <ng-template [ngIf]="actions">
            <div class="actions">
                <ng-template ngFor let-action [ngForOf]="actions">
                    <button class="btn btn-sm btn-secondary" [routerLink]="action.link">
            <i class="fa fa-{{action.icon}}"></i> {{action.title}}</button>
                </ng-template>
            </div>
            <div></div>
        </ng-template>
        <p class="body">{{subtitle}}</p>
    </div>
    <div class="detail">
        <p class="title">{{title}}</p>
        <p class="body" (click)="back()">
            <i class="fa fa-chevron-left"></i>&nbsp; {{detail}}</p>
    </div>
</div>
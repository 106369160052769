import { Org } from './org';

import moment from 'moment';

export class Engagement {
	startsAt: Date;
	endsAt: Date;
	org: Org;
	view: 'week' | 'month';
	adjusted: boolean;

	constructor(data?: any) {
		if (!data) {
			this.view = 'week';
			this.startsAt = moment().startOf('day').toDate();
			this.endsAt = moment().isoWeekday(1).startOf('isoWeek').add(6, 'days').toDate();
			this.adjusted = true;

			return;
		}
		this.view = data.view;
		this.startsAt = data.startsAt;
		this.endsAt = data.endsAt;
		this.adjusted = data.adjusted;
	}

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { ElogbooksSite } from "../../classes/elogbooks-site";
import { ElogbooksPriority } from "../../classes/elogbooks-priority";


@Component({
  selector: 'app-elogbooks-popup',
  templateUrl: './elogbooks-popup.component.html',
  styleUrls: ['./elogbooks-popup.component.css']
})
export class ElogbooksPopupComponent implements OnInit {

  @Input()
  target: 'site' | 'priority' | 'user';

  @Input()
  id: number;

  @Input()
  userId: number;

  @Output()
  onDismiss: EventEmitter<ElogbooksSite | ElogbooksPriority> = new EventEmitter();

  selectedSite: any;
  sites: any[];
  priorities: any[];
  user: any;
  site: ElogbooksSite;
  title: string;
  isLoading: boolean;
  webhooks: any[];

  constructor(private apiService: APIService) { }

  ngOnInit(): void {
    switch (this.target) {
      case 'site':
        this.title = 'Sites';
        this.getSites();
        break;
      case 'priority':
        this.title = 'Priorities';
        this.getPriorities();
        break;
      case 'user':
        this.title = 'User';
        this.getUser();
        break;
    }
  }

  m(e) {
    console.log('blocked');
    e.stopPropagation();
  }


  /** no access */
  getUser() {
    this.isLoading = true;
    this.apiService.getElogbooks('action=get-user&id=' + this.id)
      .then(r => {
        this.isLoading = false;
        if (!r || !r.sites) {
          return;
        }
      });
  }

  getSite(siteId: number) {
    this.isLoading = true;
    this.apiService.getElogbooks('action=get-site&id=' + siteId)
      .then(r => {
        this.isLoading = false;
        this.site = new ElogbooksSite(r);
      });
  }

  getUserWebhooks(userId: number) {
    this.isLoading = true;
    this.apiService.getElogbooks('action=get-user-webhooks&id=' + userId)
      .then(r => {
        this.isLoading = false;
        this.webhooks = r.webhooks;
      });
  }

  /* not used elogbooks side */
  getSiteWebhooks(siteId: number) {
    this.isLoading = true;
    this.apiService.getElogbooks('action=get-site-webhooks&id=' + siteId)
      .then(r => {
        this.isLoading = false;
        this.webhooks = r;
      });
  }

  getSites() {
    this.isLoading = true;
    this.apiService.getElogbooks('action=get-sites')
      .then(r => {
        this.isLoading = false;
        if (!r || !r.sites) {
          return;
        }
        this.sites = r.sites.map(s => {
          let record: any = {
            id: s.id,
            name: s.name,
            activeAt: new Date(s.activeAt),
            reference: s.reference,
            address: ''
          };
          const ci = s.contactInformation;
          if (ci.addresses.length) {
            record.address = {
              line1: ci.addresses[0].lines[0],
              town: ci.addresses[0].town,
              postcode: ci.addresses[0].postcode
            };
          }

          return record;
        });

      });
  }


  getPriorities() {
    this.isLoading = true;

    this.apiService.getElogbooks('action=get-priorities&id=' + this.id)
      .then(r => {
        this.isLoading = false;
        if (!r) {
          return;
        }
        switch (this.target) {
          case 'site':
            if (!r.sites) {
              return;
            }
            this.sites = r.sites.map(s => {
              let record: any = {
                id: s.id,
                name: s.name,
                activeAt: new Date(s.activeAt),
                reference: s.reference,
                address: ''
              };
              const ci = s.contactInformation;
              if (ci.addresses.length) {
                record.address = {
                  line1: ci.addresses[0].lines[0],
                  town: ci.addresses[0].town,
                  postcode: ci.addresses[0].postcode
                };
              }

              return record;
            });
            break;
          case 'priority':
            if (!r.priorities) {
              return;
            }
            this.priorities = r.priorities.map(p => {
              return {
                id: p.id,
                name: p.name
              };
            });
            break;
        }

      });
  }

  goBack() {
    this.onDismiss.emit(null);
  }

  siteClick(site: any) {
    this.getUserWebhooks(this.userId);
    this.getSite(site.id);
  }

  selectSite() {
    this.onDismiss.emit(this.site);
  }

  priorityClick(priority: any) {
    this.onDismiss.emit(new ElogbooksPriority(priority));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { APIService } from './api.service';
import { OrgExport } from "../org-dashboard/org-export/org-export";
import { User } from 'app/classes/user';
import { Org } from 'app/classes/org';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  readonly API_URL = 'https://lycsk61ty9.execute-api.eu-west-2.amazonaws.com/prod';

  constructor(private http: HttpClient, private apiService: APIService) { }

  async run(orgExport: OrgExport): Promise<any> {
    const response: IRunReportResponse = await new Promise(resolve => {
      this.http
        .post<any>(this.API_URL + '/trigger', orgExport.serialise(), this.apiService.getUAOHeaders())
        .subscribe(async response => {
          response.values.forEach(value => {
            value.a = response.assets.find(a => a.id === value.a);
            value.d = new Date(value.d);
          });
          response.assets = response.assets.sort((a, b) => a.title > b.title ? 1 : -1);
          resolve(response);
        });
    });

    return response;
  }

  async saveCustomReport(orgExport: OrgExport): Promise<any> {
    const response = await new Promise(resolve => {
      const qs = orgExport.id ? `?id=${orgExport.id}` : '';
      this.http
        .post<any>(this.API_URL + '/trigger/manage' + qs, orgExport.serialise(), this.apiService.getUAOHeaders())
        .subscribe(async response => resolve(response));
    });

    return response;
  }

  async getCustomReports(): Promise<ICustomReportListItem[]> {
    const response: ICustomReportListItem[] = await new Promise(resolve => {
      this.http
        .get<any>(this.API_URL + '/trigger/manage', this.apiService.getUAOHeaders())
        .subscribe(async response => resolve(response));
    });

    return response;
  }

  async getCustomReport(id: number): Promise<OrgExport> {
    const response: OrgExport = await new Promise(resolve => {
      this.http
        .get<any>(this.API_URL + '/trigger/manage?id=' + id, this.apiService.getUAOHeaders())
        .subscribe(async response => resolve(new OrgExport(response.config)));
    });

    return response;
  }
}

export interface ICustomReportListItem {
  id: number;
  title: string;
  createdBy: User;
  createdAt: Date;
}

export interface IRunReportResponse {
  assets: { id: number, title: string, siteTitle: string, gTitle: string, gatewayId: string, assetType_id: number }[];
  dates: any;
  sql?: any;
  values: { d: Date, v: number, a: { id: number, title: string, siteTitle: string, gTitle: string, gatewayId: string, assetType_id: number } }[];
}

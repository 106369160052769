<div #blockRef class="block" [class.pagebreakbefore]="block.pagebreak == 'Y'">

	<div class="title" [class.no-print]=" block.fragment_id === 14">
		<i *ngIf="!blockReady && block.fragment_id !==3" class="fa fa-refresh fa-spin"></i> {{block.title}}
	</div>

	<ng-template [ngIf]="data">
		<div *ngIf="block.fragment_id === 1">
			<div *ngFor="let item of refresh">
				<!--  hack to refresh chart -->
				<p-chart class="4d-{{item}}" *ngIf="data" type="line" [data]="data"></p-chart>
			</div>
		</div>
		<div *ngIf="block.fragment_id === 2">
			<app-report-weather-table [config]="master" [data]="data"></app-report-weather-table>
		</div>
		<div *ngIf="(block.fragment_id >= 3 && block.fragment_id <= 5)">
			<div *ngFor="let item of refresh; ">
				<!--  hack to refresh chart -->
				<app-report-count-by-day [(blockReady)]="blockReady" [reportBlock]="block" [config]="master" [data]="data">
				</app-report-count-by-day>
			</div>
		</div>
		<div *ngIf="block.fragment_id === 6">
			<app-report-telemetry-by-day [config]="master" [data]="data"></app-report-telemetry-by-day>
		</div>
		<div *ngIf="(block.fragment_id === 7 || block.fragment_id === 8 || block.fragment_id === 15)">
			<app-report-telemetry-min-max [config]="master" [data]="data"></app-report-telemetry-min-max>
		</div>
		<div *ngIf="block.fragment_id === 14">
			<app-report-monthly [config]="master" [data]="data"></app-report-monthly>
		</div>
		<div *ngIf="(block.fragment_id === 99) ">
			<app-report-text [reportBlock]="block" [config]="master" [data]="data"></app-report-text>
		</div>
	</ng-template>
</div>

import { Component, input, effect, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { DataForm } from 'app/layout/data-form/data-form.component';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { OfflineIssue, OfflineIssueEffect } from "app/shared/offline-issue";

@Component({
  selector: 'app-asset-offline-communication',
  templateUrl: './asset-offline-communication.component.html',
  styleUrl: './asset-offline-communication.component.css'
})
export class AssetOfflineCommunicationComponent {
  asset = input.required<Asset>();
  isAdmin = signal<boolean>(false);
  isEditingIssue = signal<boolean>(false);
  offlineIssue = signal<OfflineIssue>(null);
  issueDataForm = signal<DataForm>(null);

  constructor(private assetService: AssetService, private apiService: APIService) {
    this.isAdmin.set(apiService.isAdmin());
    effect(() => {
      this.get();
    });
  }

  get() {
    this.assetService.getOfflineCommunications(this.asset().id).then(response => {
      console.log(response);
      this.offlineIssue.set(response);
      this.isEditingIssue.set(!this.offlineIssue().id);

      if (this.isAdmin()) {
        const dataForm = {
          items: [
            { id: 'issue', label: 'The issue', value: response.issue || '', type: 'textarea', width: '100%', options: { rows: 4 } }
            , {
              id: 'effected', label: 'Effected', value: response.effected || 'asset', type: 'group', options: [
                { id: 'asset', title: 'Asset' },
                { id: 'gateway', title: 'Gateway' },
                { id: 'site', title: 'Site' }]
            }]
        };
        this.issueDataForm.set(new DataForm(dataForm));
      }
    });
  }

  async submit() {
    this.offlineIssue.update(offlineIssue => {
      offlineIssue.assetId = this.asset().id;
      offlineIssue.issue = <string>this.issueDataForm().getValue('issue');
      offlineIssue.effected = <OfflineIssueEffect>this.issueDataForm().getValue('effected');

      return offlineIssue;
    });
    await this.assetService.updateOfflineIssue(this.offlineIssue());
    this.get();
  }
}

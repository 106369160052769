import { Component, effect, input, output, signal } from '@angular/core';
import { Site } from 'app/classes/site';
import { SiteFloorplanAsset } from 'app/classes/site-floorplan-asset';
import { SiteFloorplanShape } from 'app/classes/site-floorplan-shape';
import { SitePlan } from 'app/classes/site-plan';
import { APIService } from 'app/shared/api.service';
import { FloorplanService } from 'app/shared/floorplan.service';
import { CombinedCollectionItem } from 'app/shared/site.service';

@Component({
  selector: 'app-site-collections',
  templateUrl: './site-collections.component.html',
  styleUrl: './site-collections.component.css'
})
export class SiteCollectionsComponent {
  site = input.required<Site>();
  onDialog = signal<'floorplans'>(null);
  plans = signal<SitePlan[]>(null);
  plan = signal<SitePlan>(null);
  assets = signal<SiteFloorplanAsset[]>(null);
  shapes = signal<SiteFloorplanShape[]>(null);

  combinedCollections = input<CombinedCollectionItem[]>(null);
  clickCollection = signal<CombinedCollectionItem>(null);

  onUpdated = output<any>();

  can = { update: true };

  constructor(private apiService: APIService, private floorplanService: FloorplanService) {
    effect(() => {
      setTimeout(() => {
        this.plans.set([]);
        if (!this.plans) {
          return;
        }
        if (this.site()?.id && !this.plans()?.length) {
          this.apiService.getSiteFloorplans(this.site().id).then(plans => {
            this.plans.set(plans);
          });
        }
      }, 100);
    });
  }

  createCollection() {
    this.clickCollection.set(new CombinedCollectionItem({ title: 'New Collection', site_id: this.site().id, sTitle: this.site().title }));
  }

  collectionWasUpdated() {
    this.clickCollection.set(null);
    this.onUpdated.emit(true);
  }

  selectPlan(sitePlan: SitePlan) {
    this.plan.set(sitePlan);
    this.assets.set(null);
    if (!sitePlan) {
      return;
    }
    this.floorplanService.getSiteFloorplan(this.site().id, sitePlan.id).then(plan => {
      this.assets.set(plan.assets);
      plan.shapes.forEach(shape => {
        shape.assets = plan.assets.filter(asset => asset.shapeId === shape.id);
      });
      this.shapes.set(plan.shapes);
    })
  }

}

<article>
	<section *ngIf="assets?.length">
		<h3>
			<span tooltipPosition="right" pTooltip="Assets that have been setup by 4D staff to push changes to your endpoints">Realtime updates <i class="fa fa-info-circle"></i></span>
			<span [showDelay]="1000" tooltipPosition="top" pTooltip="Refresh" class="pull-right" [class.refresh-disabled]="!canRefresh" [class.can-refresh]="canRefresh" (click)="refresh()">
				<i [class.fa-spin]="isLoading" class="fa fa-refresh"></i>
			</span>
		</h3>
		<div pTooltip="Show transformations of data for an asset"><i class="fa fa-check-circle"></i> Show
			transformations</div>
		<table class="table table-striped" *ngIf="assets?.length > 0">
			<thead>
				<tr>
					<th>Title</th>
					<th class="hidden-sm hidden-xs">Gateway</th>
					<th class="hidden-xs">Type</th>
					<th class="hidden-sm hidden-xs">Data Type</th>
					<!--<th>Purpose</th>-->
					<th title="The value pushed top the API">Value Pushed</th>
					<th title="When this asset was pushed to the API">Pushed At</th>
					<th title="Status code returned">Status Code</th>
					<th class="hidden-xs" title="How many times this asset has been pushed over 24 hours">#24hr</th>
				</tr>
			</thead>
			<tbody>
				<ng-template ngFor [ngForOf]="assets" let-asset>
					<tr [pTooltip]="asset.status==='disabled' ? 'Disabled' : ''" [class.never-pushed]="!asset.pushedAt" (click)="assetClick(asset)" [class.disabled]="asset.status === 'disabled'">
						<td>{{asset.title}}</td>
						<td class="hidden-sm hidden-xs">{{asset.gatewayTitle}}</td>
						<td class="hidden-xs">{{asset.purpose || asset.assetType}}</td>
						<td class="hidden-sm hidden-xs">{{asset.dataType}}</td>
						<!--<td>{{asset.purpose}}</td>-->
						<td>{{asset.lastValue}}</td>
						<td>
							<span pTooltip="{{asset.pushedAt |  date:'dd-MM-YY HH:mm'}}" *ngIf="asset.pushedAt">{{asset.pushedAt |
								amTimeAgo}}</span>
							<span *ngIf="!asset.pushedAt">Never pushed</span>

						</td>
						<td>
							<span tooltipPosition="right" [pTooltip]="asset.pushedStatusCode===200 ? 'OK' : 
				asset.pushedStatusCode===201 ? 'Created' :
				asset.pushedStatusCode===204 ? 'No Content' :
				asset.pushedStatusCode===400 ? 'BAD REQUEST' :
				asset.pushedStatusCode===401 ? 'UNAUTHORISED' :
				asset.pushedStatusCode===403 ? 'FORBIDDEN' :
				asset.pushedStatusCode===404 ? 'NOT FOUND' :
				asset.pushedStatusCode===500 ? 'SERVER ERROR' : ''
				" [class.critical]="asset.pushedStatusCode>204" [class.ok]="asset.pushedStatusCode>=200 && asset.pushedStatusCode<=210">{{asset.pushedStatusCode
								}}</span>
						</td>
						<td class="hidden-xs" pTooltip="This asset was pushed {{asset.pushedCountDay}} times over the last 24 hours.">
							<abbr>{{asset.pushedCountDay}}</abbr>
						</td>
					</tr>
					<ng-template [ngIf]="asset.transforms">
						<tr class="features">
							<td colspan="8">
								<span class="badge" *ngFor="let transform of asset.transforms" pTooltip="value {{transform.op}} {{transform.vfrom}}, transform to {{transform.vto}}">
									<abbr>{{transform.title}}</abbr></span>
							</td>

						</tr>
					</ng-template>


				</ng-template>


				<tr class="footer">
					<td></td>
					<td class="hidden-sm hidden-xs"></td>
					<td class="hidden-xs"></td>
					<td class="hidden-sm hidden-xs"></td>
					<td></td>
					<td></td>
					<td></td>
					<td class="hidden-xs" pTooltip="{{totals.pushedCountDay}} asset values were pushed in the last 24 hours.">
						<abbr>{{totals.pushedCountDay}}</abbr>
					</td>
				</tr>
			</tbody>
		</table>
		<div *ngIf="!isLoading && assets.length === 0"><i class="fa fa-info"></i> There are no assets configured to push to
			your endpoints, please
			contact 4D to enquire about this feature.</div>
	</section>

	<section>
		<h3>
			<span tooltipPosition="bottom" pTooltip="Keys that allow you to access the 4D API">API Keys</span>
			<span [showDelay]="1000" tooltipPosition="top" pTooltip="Refresh" class="pull-right" [class.refresh-disabled]="!canRefresh" [class.can-refresh]="canRefresh" (click)="refresh()">
				<i [class.fa-spin]="isLoading" class="fa fa-refresh"></i>
			</span>

			<span *ngIf="can.createKey" class="pull-right" (click)="refresh()">

				<span tooltipPosition="bottom" pTooltip="Disabled, this feature is in development">
					<button disabled="disabled" class="btn btn-sm btn-secondary" routerLink="/developer/keys/create">
						<i class="fa fa-key fa-fw"></i>
						Create Key</button>
				</span>
			</span>
		</h3>
		<p *ngIf="usage && usage[0]">
			You have used <strong tooltipPosition="bottom" pTooltip="The amount of GET requests today">{{usage[0]}}</strong>
			and
			have <strong tooltipPosition="bottom" pTooltip="The amount of GET requests remaining, requests will fail until the next day when this is reset.">{{usage[1]}}</strong>
			API requests remaining.
		</p>
		<table class="table table-striped table-hover" *ngIf="!isLoading && apikeys.length > 0">
			<thead>
				<tr>
					<th>Title</th>
					<th>key</th>
					<th>Quota</th>
					<th>Created At</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let apikey of apikeys" (click)="clickedKey(apikey)" pTooltip="Drill into this key, then explore to reveal an assets API">
					<td>{{apikey.title}}</td>
					<td>{{apikey.apikey}}</td>
					<td>{{apikey.quotaLeft}} left ( {{apikey.rateValue}} <i class="gray">calls per</i> {{apikey.ratePer}} )</td>
					<td>{{apikey.createdAt | amDateFormat:apiService.getDateFormat()}}</td>
				</tr>
			</tbody>
		</table>
		<div *ngIf="!isLoading && apikeys.length === 0"><i class="fa fa-info"></i>
			There are no api keys configured.
		</div>

		<!--<span class="link" routerLink="/developer/keys/create">create an API key</span> to access the 4D API.</div>-->
	</section>


	<section class="selected-api" *ngIf="apikeySelected">
		<p-card>
			<p-header>
				<h3>{{apikeySelected.title}}</h3>
			</p-header>

			<h4>Example usage</h4>
			<p>
				GET <a target="_blank" href="https://2rx4xrq7i1.execute-api.eu-west-2.amazonaws.com/v1/assets?key={{apikeySelected.apikey}}">
					https://2rx4xrq7i1.execute-api.eu-west-2.amazonaws.com/v1/assets?key={{apikeySelected.apikey}}
				</a>
			</p>

			<p-footer>
				<button class="btn btn-primary btn-block" (click)="apikeySelected=null">Close</button>
			</p-footer>
		</p-card>
		<div class="overlay"></div>
	</section>



	<section class="flyout" *ngIf="selectedAsset">
		<div>
			<div class="pull-right"><span class="fa fa-fw fa-close " (click)="selectedAsset=null"></span></div>
			<h3>{{selectedAsset.title}}</h3>
			<div *ngIf="auditLog">
				<table class="">
					<thead>

					</thead>
					<tbody>
						<ng-template ngFor let-log [ngForOf]=" auditLog">
							<tr>
								<td>{{log.createdAt | amTimeAgo}}</td>
								<td><span [class.critical]="log.statusCode>204" [class.ok]="log.statusCode>=200 && log.statusCode<=210">{{log.statusCode}}</span>
								</td>
							</tr>
							<tr colspan="2">
								<h5>Payload</h5>{{log.payload}}
							</tr>
							<tr colspan="2">
								<h5>Response</h5>{{log.response}}
								<hr>
							</tr>
						</ng-template>
					</tbody>
				</table>
			</div>
		</div>
	</section>
</article>

<div #viewContainer class="report-view  view-container" [class.print-preview]="wantsPrintPreview">
	<div class="report-view-title">
		<div class="row">

			<div class="col-md-12 text-center">
				<p></p>{{report?.title}}
				<p class="no-screen">{{_masterReportConfig?.startAt | amDateFormat:'DD MMMM YYYY'}}</p>
				<i class="fa fa-{{report.icon}} pull-right"></i>
				<!--<i class="no-print pull-right fa fa-cog hvr-grow" pTooltip="Show page breaks" (click)="configToggle()"></i>-->
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12" *ngFor="let rc of _reportConfigs;let i = index" [class.pagebreakbefore]="i > 0">
			<div class="row gateway-title">
				<div class="col-sm-12">{{rc.forGateways[0].title}}</div>
			</div>
			<div class="row" *ngIf="report.blocks">
				<div class="col-md-12 " *ngFor="let item of report.blocks; let i=index">
					<app-report-block-render *ngIf="item" [block]="item" [report]="report" [reportConfig]="rc"
						(onLoaded)="hasLoaded(item)"></app-report-block-render>
				</div>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="report.blocks">
		<div class="col-md-12 text-center">
			Report created at
			<app-date [date]="createdAt"></app-date>
		</div>
	</div>
</div>
<p style=" margin-bottom: 0.42cm;">
  <b>Policy statement</b>
</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a920478" id="a920478"></a> Everyone has rights with regard to the way in which their personal data in handled. During the course of our activities
  we will collect, store and process personal data about our employees, customers, suppliers and other third parties, and
  we recognise that the correct and lawful treatment of this data will maintain confidence in the organisation and will provide
  for successful business operations.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a742941" id="a742941"></a> Data users are obliged to comply with this policy when processing personal data on our behalf. Any breach of this
  policy may result in disciplinary action.
</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a1009974" id="a1009974"></a>
  <b>About this policy</b>
</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a1012062" id="a1012062"></a> The types of personal data that 4D Monitoring Limited (&ldquo;<b>We</b>&rdquo;) may be required to handle include information about current, past and prospective job applicants, employees,
  contractors, suppliers, customers and others that we communicate with. The personal data, which may be held on paper or
  on a computer or other media, is subject to certain legal safeguards specified in the Data Protection Act 1998 ("<b>DPA</b>") and the General Data Protection Regulation (&ldquo;<b>GDPR</b>&rdquo;) and other regulations.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a766392" id="a766392"></a> This policy and any other documents referred to in it sets out the basis on which we will process any personal data
  we collect from data subjects, or that is provided to us by data subjects or other sources.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a604887" id="a604887"></a> This policy does not form part of any employee&rsquo;s contract of employment and may be amended at any time.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a503404" id="a503404"></a> This policy sets out rules on data protection and the legal conditions that must be satisfied when we obtain, handle,
  process, transfer and store personal data.
</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a456633" id="a456633"></a>
  <b>Definition of data protection terms</b>
</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a882432" id="a882432"></a> &ldquo;<b>biometric data&rdquo;</b> means personal data resulting from specific technical processing relating to the physical, physiological
  or behavioural characteristics of a natural person, which allow or confirm the unique identification of that natural person,
  such as facial images or dactyloscopic data;</p>

<p style=" margin-bottom: 0.42cm">&ldquo;<b>consent&rdquo;</b>
  means consent given by the data subject which is freely given, specific, informed and an unambiguous indication of the data
  subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing
  of personal data relating to him or her;</p>

<p style=" margin-bottom: 0.42cm">&ldquo;<b>controller&rdquo;</b>
  means the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines
  the purposes and means of the processing of personal data;
</p>

<p style=" margin-bottom: 0.42cm">&ldquo;<b>data&rdquo;</b> means information which is stored electronically, on a computer, or in certain paper-based filing systems.</p>

<p style=" margin-bottom: 0.42cm">&ldquo;<b>genetic data&rdquo;</b>
  means personal data relating to the inherited or acquired genetic characteristics of a natural person which give unique information
  about the physiology or the health of that natural person and which result, in particular, from an analysis of a biological
  sample from the natural person in question;</p>

<p style=" margin-bottom: 0.42cm">&ldquo;<b>personal data&rdquo;
  </b> means any information relating to an identified or identifiable natural person (&ldquo;<b>data subject</b>&rdquo;); an identifiable natural person is one who can be identified, directly or indirectly, in particular
  by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or
  more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural
  person and where referred to in this policy includes special categories of personal data;</p>

<p style=" margin-bottom: 0.42cm">&ldquo;<b>personal data breach&rdquo;
  </b> means a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure
  of, or access to, personal data transmitted, stored or otherwise processed;</p>

<p style=" margin-bottom: 0.42cm">&ldquo;<b>processing&rdquo;</b>
  means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not
  by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval,
  consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction,
  erasure or destruction;</p>

<p style=" margin-bottom: 0.42cm">&ldquo;<b>processor&rdquo;</b>
  means a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller;</p>

<p style=" margin-bottom: 0.42cm">&ldquo;<b>profiling&rdquo;</b>
  means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal
  aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person&rsquo;s
  performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements;</p>

<p style=" margin-bottom: 0.42cm">
  &ldquo;<b>pseudonymisation&rdquo;</b> means the processing of personal data in such a manner that the personal data can no longer
  be attributed to a specific data subject without the use of additional information, provided that such additional information
  is kept separately and is subject to technical and organisational measures to ensure that the personal data are not attributed
  to an identified or identifiable natural person;
</p>

<p style=" margin-bottom: 0.42cm">&ldquo;<b>special categories of personal data&rdquo;</b> means personal data revealing racial or ethnic origin, political opinions,
  religious or philosophical beliefs, or trade union membership, and genetic data, biometric data for the purpose of uniquely
  identifying a natural person, data concerning health or data concerning a natural person&rsquo;s sex life or sexual orientation.
</p>

<p style=" margin-bottom: 0.42cm">
  <b>Material scope and territorial scope
  </b>
</p>

<p style=" margin-bottom: 0.42cm">The DPA and GDPR applies to the processing of personal data for wholly or partly automated means and to processing other
  than by automated means of personal data which form part of a filing system or are intended to form part of a filing system.</p>

<p style=" margin-bottom: 0.42cm">The GDPR applies to controllers and processors in the Union whether or not processing takes place in the Union.</p>

<p style=" margin-bottom: 0.42cm">The GDPR applies to the processing of personal data of data subjects who are in the Union where certain processing activities
  are undertaken.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a746166" id="a746166"></a>
  <a name="a812845" id="a812845"></a>
  <b>lawfulness of processing</b>
</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a960646" id="a960646"></a>
  <a name="a331847" id="a331847"></a> In the course of our business, we may collect and process personal data and are, for that purpose a controller. This
  may include data we receive directly from a data subject (for example, by completing forms or by corresponding with us
  by mail, phone, email or otherwise) and data we receive from other sources (including, for example, business partners,
  sub-contractors in technical, payment and delivery services, credit reference agencies and others).</p>

<p style=" margin-bottom: 0.42cm">The DPA and the GDPR are not intended to prevent the processing of personal data, but to ensure that it is done lawfully
  and without adversely affecting the rights of the data subject.</p>

<p style=" margin-bottom: 0.42cm">We will only process personal data as specifically permitted by the DPA and GDPR. We will usually obtain the data subjects
  consent to process their personal data unless one of the exemptions within the GDPR permits processing without the consent
  of the data subject.</p>

<p style=" margin-bottom: 0.42cm">For personal data to be processed lawfully, one of the following conditions must apply:</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the data subject has given consent to the processing of his or her personal data for one or more specific purposes;
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">processing is necessary for the performance of a contract to which the data subject is party or in order to take steps
    at the request of the data subject prior to entering into a contract;</span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">processing is necessary for compliance with a legal obligation to which the controller is subject;</span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">processing is necessary in order to protect the vital interests of the data subject or of another natural person;</span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">processing is necessary for the performance of a task carried out in the public interest or in the exercise of official
    authority vested in the controller; or
  </span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, except
    where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require
    protection of personal data, in particular where the data subject is a child.
  </span>
</p>

<p lang="en-US" style="margin-left: 3.18cm; margin-bottom: 0.42cm" xml:lang="en-US">
  <br />
  <br />
</p>

<p style=" margin-bottom: 0.42cm">
  <b>principles relating to processing of personal Data</b>
</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a179428" id="a179428"></a> We will process all personal data fairly and in a transparent manner. In particular personal data will be collected
  for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes.
  All personal data shall be:</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed (&ldquo;<b>data minimisation</b>&rdquo;);</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">accurate and, where necessary, kept up to date; every reasonable step will be taken to ensure that personal data that are
  inaccurate, having regard to the purposes for which they are processed, are erased or rectified without delay (&ldquo;<b>accuracy</b>&rdquo;);</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the
  personal data are processed; and</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">processed in a manner that ensures appropriate security of the personal data, including protection against unauthorised or
  unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organisational measures
  (&ldquo;<b>integrity and confidentiality</b>&rdquo;).</p>

<p style=" margin-bottom: 0.42cm">
  <b>Consent to process personal data
  </b>
</p>

<p style=" margin-bottom: 0.42cm">
  <span style="font-weight: normal">We shall usually seek the data subjects consent to process personal data using clear and plain language. The data subject
    has the right to withdraw their consent at any time.</span>
</p>

<p style=" margin-bottom: 0.42cm">
  <b>Processing of special categories of personal data</b>
</p>

<p style=" margin-bottom: 0.42cm">
  <span style="font-weight: normal">We shall only process special categories of personal data:
  </span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span style="font-weight: normal">if the data subject has given explicit consent to the processing of the special categories of personal data; or</span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span style="font-weight: normal">if the processing is necessary for the purposes of carrying out our obligations or specific rights of the data subject
    in the field of employment and social security and social protection law in so far as it is authorised by Union or Member
    State law.</span>
</p>

<p style=" margin-bottom: 0.42cm">
  <span style="font-weight: normal">Criminal convictions are not special categories of personal data but shall be dealt with in accordance with statutory obligations
    in the United Kingdom.
  </span>
</p>

<p style=" margin-bottom: 0.42cm; page-break-before: always">
  <b>Records of processing activities</b>
</p>

<p style=" margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">We shall maintain a record of processing activities under our responsibility. Those records shall contain all of the following
    information:</span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">where applicable, our data protection officer;</span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">the purposes of the processing;</span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">a description of the categories of data subjects and of the categories of personal data;</span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">the categories of recipients to whom the personal data have been or will be disclosed including recipients in third countries
    or international</span>
  organisations
  <span lang="en-US" xml:lang="en-US">;</span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">where applicable, transfers of personal data to a third country or an international
  </span> organisation
  <span lang="en-US" xml:lang="en-US">, including the identification of that third country or international</span> organisation
  <span lang="en-US" xml:lang="en-US">and, where appropriate documentation of suitable safeguards;
  </span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">where possible, the envisaged time limits for erasure of the different categories of data; and</span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span lang="en-US" xml:lang="en-US">where possible, a general description of the technical and</span>
  organisational
  <span lang="en-US" xml:lang="en-US">security measures we have in place.
  </span>
</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a807958" id="a807958"></a>
  <b>information and access to personal data</b>
</p>

<p style=" margin-bottom: 0.42cm">
  <span style="font-weight: normal">When we collect a data subject&rsquo;s personal data we shall, at the time the personal data is collected, provide the
    data subject with all of the following information:</span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <span style="font-weight: normal">our</span> identity;</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the contact details of the data protection officer, where applicable;</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the purposes of the processing for which the personal data are intended as well as the legal basis for the processing;
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">if the processing is being pursued for our legitimate interests or pursued for those of a third party;</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the recipients or categories of recipients of the personal data, if any; and</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">where applicable, that we intend to transfer personal data to a third country or international organisation (&ldquo;<b>Relevant Information</b>&rdquo;).</p>

<p style=" margin-bottom: 0.42cm">
  <span style="font-weight: normal">We will, at the time when personal data are obtained, provide the data subject with the following information to ensure
    fair and transparent processing:
  </span>
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the period for which the personal data will be stored, or if that is not possible, the criteria used to determine that period;
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the existence of the right to request from the controller access to and rectification or erasure of personal data or restriction
  of processing concerning the data subject or to object to processing as well as the right to data portability;</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">where the data subject have given consent to process personal data, the existence to withdraw consent at any time, without
  affecting the lawfulness of processing based on consent before it is withdrawn;
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the right to lodge a complaint with a supervisory authority;</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">whether the provision of personal data is a statutory or contractual requirement, or a requirement necessary to enter into
  a contract, as well as whether the data subject is obliged to provide the personal data and of the possible consequences
  of failure to provide such data; and</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the existence of automated decision-making, including profiling (&ldquo;<b>Further Information</b>&rdquo;).</p>

<p style=" margin-bottom: 0.42cm">
  <span style="font-weight: normal">Where personal data have not been obtained from the data subject we shall provide the data subject with the categories
    of personal data together with the Relevant Information. We shall, also provide information to ensure fair and transparent
    processing, namely we shall advise of the legitimate interests pursued by us or a third party in collection the data,
    the source from which the data originates, and if applicable, whether it came from publically accessible sources together
    with the further information</span>
  <span style="font-weight: normal">.</span>
  <span style="font-weight: normal">The information will be provided</span>
  <span style="font-weight: normal">within a reasonable</span>
  <span style="font-weight: normal">period</span>
  <span style="font-weight: normal">after obtaining the data but no later than 1 month after receipt, or if we are using the data to communicate with the data
    subject, at the latest, at the time of the first communication to that data subject or if disclosure</span>
  <span style="font-weight: normal">to another recipient is envisaged, at the latest when the personal data are first disclosed.</span>
</p>

<p style=" margin-bottom: 0.42cm">
  <span style="font-weight: normal">If any other purpose for processing is undertaken we shall so advise the data subject prior to commencing processing.</span>
</p>

<p style=" margin-bottom: 0.42cm">
  <span style="font-weight: normal">There are certain occasions where the provision of information will not be possible and we shall advise the data subject
    if this is the case, where possible.
  </span>
</p>

<p style=" margin-bottom: 0.42cm">
  <b>right to access by the data subject
  </b>
</p>

<p style=" margin-bottom: 0.42cm">The data subject shall have the right to obtain from us confirmation as to whether or not personal data concerning him or
  her are being processed, and where that is the case, access to the personal data and the following information:</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the purpose of the processing;
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the categories of the personal data;
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the recipients or categories of recipient to whom the personal data have been or will be disclosed, in particular recipients
  in third countries or international organisations;</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to
  determine that period;</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the existence of the right to request from the controller rectification or erasure of personal data or restriction of processing
  concerning the data subject or to object to such processing;</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the right to lodge a complaint with a supervisory body;</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">where the personal data are not collected from the data subject, any available information as to their source; and</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">the existence of automated decision-making, including profiling.</p>

<p style=" margin-bottom: 0.42cm">Information will usually be provided free of charge. Where requests from a data subject are manifestly unfounded or excessive
  we may:</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">charge a reasonable fee taking into account the administrative costs of providing the information or communication or taking
  the action required; or</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">refuse to act on the request.</p>

<p style=" margin-bottom: 0.42cm">Should we have any doubts concerning the identity of the person making the request we may request additional information
  necessary to confirm the identity of the data subject.</p>

<p style=" margin-bottom: 0.42cm">
  <b>the right to rectification and erasure and restriction of processing</b>
</p>

<p style=" margin-bottom: 0.42cm">Data subjects shall have the right to rectification of inaccurate personal data concerning him or her. Data subjects shall
  also have the right to have incomplete personal data completed including by means of providing a supplementary statement.</p>

<p style=" margin-bottom: 0.42cm">Data subjects shall have the right to obtain from us erasure of personal data (the right to be forgotten).</p>

<p style=" margin-bottom: 0.42cm">In certain circumstances data subjects shall have the right to restriction of processing.</p>

<p style=" margin-bottom: 0.42cm">Where any rectification or erasure of personal data or restriction of processing has taken place we shall communicate any
  rectification or erasure of personal data or restriction of processing to each recipient to whom the personal data have
  been disclosed, unless this proves impossible or involves disproportionate effort. We shall, if the data subject asks,
  inform the data subject about those recipients.</p>

<p style=" margin-bottom: 0.42cm; page-break-before: always">
  <b>Right to portability</b>
</p>

<p style=" margin-bottom: 0.42cm">Where a data subject provides personal data they shall have the right to be provided with a structured, commonly used and
  machine-readable format and have the right to transmit these data to another without hindrance from us in certain circumstances.</p>

<p style=" margin-bottom: 0.42cm">
  <b>Right to object and automated individual decision-making</b>
</p>

<p style=" margin-bottom: 0.42cm">Data subjects shall have the right to object to automated decision-making, including profiling. If an objection is made we
  shall only continue processing where there are compelling legitimate grounds for processing which override the interests,
  rights and freedoms of the data subject or for the establishment, exercise or defence of legal claims.</p>

<p style=" margin-bottom: 0.42cm">Data subjects shall also have the right to object to processing for direct marketing purposes.</p>

<p style=" margin-bottom: 0.42cm">
  <b>Data security &ndash; Data protection by design and by default</b>
</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a241848" id="a241848"></a> We will take appropriate security measures against unlawful or unauthorised processing of personal data, and against
  the accidental loss of, or damage to, personal data and shall implement appropriate technical and organisational measures
  to ensure a level of security appropriate to the risk.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a81629" id="a81629"></a>
  We will put in place procedures and technologies to maintain the security of all personal data from the point of collection
  to the point of destruction. Personal data will only be transferred to a data processor if the data processor agrees to
  comply with those procedures and policies, or if the data processor puts in place adequate measures. By default only personal
  data which are necessary for each specific purpose of the processing shall be processed.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a562288" id="a562288"></a> We will maintain data security by protecting the confidentiality, integrity and availability of the personal data,
  defined as follows:</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a854729" id="a854729"></a>
  <b>Confidentiality</b> means that only people who are authorised to use the data can access it.</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a977405" id="a977405"></a>
  <b>Integrity</b> means that personal data should be accurate and suitable for the purpose for which it is processed.</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a671692" id="a671692"></a>
  <b>Availability</b> means that authorised users should be able to access the data if they need it for authorised purposes.
  Personal data should therefore be stored on 4D Monitoring Limited's central computer system instead of individual
  PCs.
</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">Data minimisation.</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">Pseudonymisation and encryption of data.</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">Risk assessments where necessary.
</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a323254" id="a323254"></a> Security procedures include:</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a368570" id="a368570"></a>
  <b>Entry controls.</b> Any stranger seen in entry-controlled areas should be reported.</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a763779" id="a763779"></a>
  <b>Secure lockable desks and cupboards.</b> Desks and cupboards should be kept locked if they hold confidential information
  of any kind. (Personal information is always considered confidential.)</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a312572" id="a312572"></a>
  <b>Methods of disposal.</b> Paper documents should be shredded. Digital storage devices should be physically destroyed when
  they are no longer required.</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a400614" id="a400614"></a>
  <b>Equipment.</b> Data users must ensure that individual monitors do not show confidential information to passers-by and that
  they log off from their PC when it is left unattended.</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">Pseudonymisation and encryption of personal data.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a667647" id="a667647"></a>
  <b>Transferring personal data to a country outside the EEA</b>
</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a528790" id="a528790"></a> We may transfer any personal data we hold to a country outside the European Economic Area (&ldquo;<b>EEA</b>&rdquo;), provided that one of the following conditions applies:</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a283120" id="a283120"></a> The country to which the personal data are transferred ensures an adequate level of protection for the data subjects&rsquo; rights and freedoms.</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a1020131" id="a1020131"></a> The data subject has given his or her consent.</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a543516" id="a543516"></a> The transfer is necessary for one of the reasons set out in the GDPR, including the performance of a contract between
  us and the data subject, or to protect the vital interests of the data subject.</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a973360" id="a973360"></a> The transfer is legally required on important public interest grounds or for the establishment, exercise or defence
  of legal claims.</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a949407" id="a949407"></a> The transfer is authorised by the relevant data protection authority where we have adduced adequate safeguards with
  respect to the protection of the data subjects&rsquo; privacy, their fundamental rights and freedoms, and the exercise
  of their rights.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a490110" id="a490110"></a> Subject to the above requirements, personal data we hold may also be processed by staff operating outside the EEA
  who work for us or for one of our suppliers. Those staff may be engaged in, among other things, the fulfilment of contracts
  with the data subject, the processing of payment details and the provision of support services.</p>

<p style=" margin-bottom: 0.42cm; page-break-before: always">
  <a name="a133581" id="a133581"></a>
  <b>Disclosure and sharing of personal information
  </b>
</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a542062" id="a542062"></a> We may share personal data we hold with any member of our group, which means our subsidiaries, our ultimate holding
  company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a1040039" id="a1040039"></a> We may also disclose personal data we hold to third parties:</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a546107" id="a546107"></a> In the event that we sell or buy any business or assets, in which case we may disclose personal data we hold to the
  prospective seller or buyer of such business or assets.</p>

<p style="margin-left: 3.18cm; margin-bottom: 0.42cm">
  <a name="a762325" id="a762325"></a> If we sell all of our assets or substantially all of our assets are acquired by a third party, in which case personal
  data we hold will be one of the transferred assets.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a644830" id="a644830"></a> If we are under a duty to disclose or share a data subject&rsquo;s personal data in order to comply with any legal
  obligation, or in order to enforce or apply any contract with the data subject or other agreements; or to protect our rights,
  property, or safety of our employees, customers, or others. This includes exchanging information with other companies and
  organisations for the purposes of fraud protection and credit risk reduction.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a997314" id="a997314"></a> We may also share personal data we hold with selected third parties for the purposes set out in this policy.</p>

<p style=" margin-bottom: 0.42cm">
  <b>Notification of a personal data breach</b>
</p>

<p style=" margin-bottom: 0.42cm">We shall, without undue delay, and where feasible, not later than 72 hours after having become aware of a personal data breach,
  notify the personal data breach to the Information Commissioner, unless the personal data breach is unlikely to result
  in a risk to the rights and freedoms of any data subject.</p>

<p style=" margin-bottom: 0.42cm">Where the personal data breach is likely to result in a high risk to the rights and freedoms of the data subject we shall
  communicate the personal data breach to the data subject without delay.</p>

<p style=" margin-bottom: 0.42cm">
  <a name="a268393" id="a268393"></a>
  <b>Changes to this policy</b>
</p>

<p style=" margin-bottom: 0.42cm">We reserve the right to change this policy at any time. Where appropriate, we will notify data subjects of those changes
  by mail or email.</p>
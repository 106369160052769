<article *ngIf="engagementSharedSites">
	<section>
		<table class="table">
			<thead>
				<tr>
					<th>Site</th>
					<th>Organisation</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let sharedSite of engagementSharedSites">
					<td> <span class="site-id" *ngIf="user?.role === 'admin'" pTooltip="Site id"
							tooltipPosition="top">{{sharedSite.site.id}}</span>
						{{sharedSite.site.title}}</td>
					<td>
						<span class="org" *ngFor="let sharedOrg of sharedSite.orgs" title="{{sharedOrg.count}}" [showDelay]="500"
							[escape]="false" [class.has-routes]="sharedOrg.count"
							[pTooltip]="sharedOrg.count ? '<b style=\'color:yellow\'>Has user engagement</b>' : 'No user engagement'"
							tooltipPosition="top">
							{{sharedOrg.org.title}}
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	</section>
</article>

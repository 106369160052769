<div class="row">
  <div class="page-info">
    <div class="image">
      <i class="fa fa-building"></i>
    </div>
    <p class="title">Submit New Site</p>
    <p class="body">Submit a new site for a gateway installation.</p>
  </div>

  <div class="col-sm-12 form-container">
    <form>
      <div class="form-group">
        <label for="title">Site Name</label>
        <input name="title" [(ngModel)]="title" type="title" class="form-control" id="title" aria-xdescribedby="title" placeholder="Enter the name of the site.">
        <small id="titleHelp" class="form-text text-muted">Required, The name of the site / building.</small>
      </div>

      <div class="form-group">
        <label for="address">Site Address</label>
        <textarea [(ngModel)]="address" name="address" id="address" class="form-control" rows="4"></textarea>
        <small id="addressHelp" class="form-text text-muted">Required, please provide at least the 1st address line and postcode.</small>
      </div>

      <div class="form-group">
        <label for="notes">Notes</label>
        <textarea [(ngModel)]="notes" name="notes" id="address" class="form-control"></textarea>
        <small id="notesHelp" class="form-text text-muted">Optional, any notes on this address.</small>
      </div>
      <button type="submit" class="btn btn-primary" (click)="submit()"><i class="fa fa-floppy-o"></i> &nbsp;Submit site</button>
    </form>
  </div>

  <div class="col-sm-12">
    <div class="well well-sm">Please allow the 4DML team 24 hours to review and activate the site.</div>
  </div>
</div>
<div>
  <div class="title" (click)="navigateBack()">
    <span pTooltip="Back to floorplan">
      <i class="fa fa-fw fa-chevron-left"></i>
      <span>
        <app-asset-icon [asset]="asset"></app-asset-icon>
      </span> {{asset.title}}&nbsp; <small>{{asset.location}}</small></span>
  </div>
  <div>

  </div>
  <div class="chart">
    <!--
    <div class="line-chart" *ngIf="asset.assetType_id !== 42 || (asset.assetType_id === 42 && asset.purpose.id === 6)">
      <app-asset-chart theme="white" [asset]="asset" [discoverSetpoints]="true" [fullwidth]="true"></app-asset-chart>
    </div>
  -->
    <div>
      <div class="asset-view">
        <app-asset-view [asset]="asset" styling="telemetry" [fullscreen]="fullscreen"></app-asset-view>
      </div>
    </div>
  </div>
</div>
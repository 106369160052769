@defer() {
<article>
	<main>
		<app-toolbar class="mb-1">
			<div class="right">
				<app-button label="Add Template" icon="mdi mdi-plus" (click)="addTemplate()"></app-button>
			</div>
		</app-toolbar>
		@if(templates) {
		<div class="content-container">
			<table class="table table-hover table-striped" aria-label="List of templates">
				<thead>
					<tr>
						<th>Template</th>
						<th>Category</th>
						<th>Author</th>
						<th>Notes</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of templates" [routerLink]="[item.id]">
						<td>{{item.title}}</td>
						<td>{{item.category.title}}</td>
						<td>{{item.createdBy.name}}</td>
						<td [innerHTML]="item.notes"></td>
					</tr>
				</tbody>
			</table>
		</div>
		}
		<app-info-panel type="note">
			<p>Templates hold setpoint values which are used by Setpoint Schedules. The Setpoint Schedules contain the assets to change and a date to apply the changes.
			</p>
		</app-info-panel>
	</main>

</article>
} @placeholder (minimum 250ms) {
<i class="mdi mdi-loading mdi-spin-x2"></i>
}

import { Component, Input } from '@angular/core';
import { Steps } from '../setpoint-template-item-scheduler.component';

@Component({
  selector: 'app-scheduler-help',
  templateUrl: './scheduler-help.component.html',
  styleUrls: ['./scheduler-help.component.css']
})
export class SchedulerHelpComponent {

  @Input()
  step: Steps;

}

import { Component, OnInit, AfterViewInit } from '@angular/core';

import * as c3 from 'c3';
import * as d3 from 'd3';

@Component({
  selector: 'app-charts-landing',
  templateUrl: './charts-landing.component.html',
  styleUrls: ['./charts-landing.component.css']
})
export class ChartsLandingComponent implements OnInit, AfterViewInit {
  constructor() { }

  ngAfterViewInit(): void {

  }

  ngOnInit() {
  }

  getChart() {
    let chart = c3.generate({
      bindto: '#chart',
      data: {
        x: 'createdat',
        xFormat: '%Y-%m-%d %H:%M:%S.%L',
        url: 'https://4d-public.s3.eu-west-2.amazonaws.com/863ae203-7799-4732-bf89-56ac816b8c69.csv',
        keys: { value: ['value'] },
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%d %m'
          },
        }
      },
      point: {
        show: true,
        r: 1,
        focus: {
          expand: {
            enabled: true,
            r: 5
          }
        }
      },
      grid: {
        x: {
          show: true
        },
        y: {
          show: true
        }
      },
      tooltip: {
        format: {
          title: function (x, index) {

            return 'Data ' + x;
          },
          value: function (value, ratio, id) {

            return String(value);
          }
          //            value: d3.format(',') // apply this format to both y and y2
        }
      }

    });
  }

}

<article>
	<header>

	</header>

	<main>


		<section>
			<app-occupancy-admin-collections></app-occupancy-admin-collections>
		</section>

	</main>

	<footer>

	</footer>
</article>

import { Asset } from '../asset';
import { Gateway } from '../gateway';
import { Report } from './report';
import moment from 'moment';

export class ReportConfig {
    forAsset: Asset;
    forGateways: Gateway[];
    forIdentifier: string;
    forOrg: number;
    startAt: Date;
    endAt: Date;
    startOfMonth: string;
    tabTitle: string;

    constructor(data?: any, gateway?: Gateway) {
        this.startAt = null;
        this.endAt = null;
        this.forAsset = null;
        if (data) {
            this.forAsset = data.forAsset;
            this.forIdentifier = data.forIdentifier;
            this.forOrg = data.forOrg;
            this.startAt = data.startAt;
            this.startOfMonth = data.startAt.getFullYear() + '-' + (data.startAt.getMonth() + 1) + '-01';
            this.endAt = data.endAt;
            if (gateway) {
                this.forGateways = [gateway];
            } else {
                this.forGateways = data.forGateways;
            }
        }
    }

    setTabTitle(report: Report) {
        if (report.dateSpan === 'week') {
            this.tabTitle = moment(this.startAt).format('DD MMM YYYY');
        } else {
            this.tabTitle = moment(this.startAt).format('MMM YYYY');
        }
    }
}

import { FilterMenuItem } from './filter-menu-item';

export class FilterMenu {
  title: string;
  action: string;
  tooltip: string;
  count: number;
  items: FilterMenuItem[];

  constructor() {
    this.items = [];
  }
}

<article>

	<p-tabView>
		<p-tabPanel header="Add another">
			<app-theme-page-section>
				<app-collection-assets [type]="type" [building]="building" (onUpdated)="collectionsUpdated()">
				</app-collection-assets>
			</app-theme-page-section>
		</p-tabPanel>
		<p-tabPanel header="Collections">
			<section *ngIf="building && collectionForAssets?.hasCollections">
				<table class="table">
					<thead>
						<tr>
							<th>Collection</th>
							<th>Assets</th>
						</tr>
					</thead>
					<tbody>
						<ng-template ngFor [ngForOf]="collectionForAssets.collections" let-collection>
							<tr class="can-click">
								<td (click)="clickCollection(collection)">
									{{collection.title}}
								</td>
								<td class="assets">
									<span *ngFor="let a of collection.assets" [pTooltip]="a.title" tooltipPosition="bottom">{{a.title ||
										a.id}}</span>
								</td>
							</tr>
						</ng-template>
					</tbody>
				</table>
			</section>
			<section>

			</section>
		</p-tabPanel>
	</p-tabView>

</article>

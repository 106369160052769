<div class="table-container">
  <div class="table-row">

    <div class="table-col reviews">

      <h3 class="section new">New Reviews</h3>

      <ng-template ngFor let-review [ngForOf]="reviews">

        <app-review-list-item [routerLink]="[review.id]" [review]="review" (click)="clickReviewItem(review)"></app-review-list-item>

      </ng-template>
      <!--<h3 class="section delivered">Delivered Reviews</h3>-->
    </div>
    <div class=" table-col audit">
        <h3 class="section new">Recent Activity</h3>
      <app-reviews-audit-trail></app-reviews-audit-trail>
    </div>

  </div>
</div>
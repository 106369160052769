<div class="modal-background"></div>
<div class="help-overlay no-hero" [ngClass]="{'in-progress': !!show}">
  <div class="help-internal-wrapper">
    <div class="help-title">{{title}}</div>
    <div class="help-content">
      <ng-template ngFor let-row [ngForOf]="content">

        <p *ngIf="row !== '[break]'" [innerHTML]="row"></p>
        <hr *ngIf="row === '[break]'" />
      </ng-template>
    </div>
    <div class="help-close" (click)="close()"><i class="fa fa-times"></i></div>
  </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ReportService } from '../../shared/report.service';
import { User } from '../../classes/user';
import { Org } from '../../classes/org';
import { Report } from '../../classes/reports/report';
import { ReportBlock } from '../../classes/reports/report-block';
import { ReportFragment } from '../../classes/reports/report-fragment';

@Component({
  selector: 'app-report-telemetry-by-day',
  templateUrl: './report-telemetry-by-day.component.html',
  styleUrls: ['./report-telemetry-by-day.component.css']
})
export class ReportTelemetryByDayComponent implements OnInit {

  // Raw data received from the API
  @Input()
  set data(data: any[]) {
    // console.log('ReportTelemetryByDayComponent setData', data);
    this._data = data;
  }
  _data: any;
  @Input()
  set config(data: any[]) {
    // console.log('ReportTelemetryByDayComponent setData', data);
    let hasData = typeof this._config !== 'undefined';
    if (data) {
      this._config = data;
      if (hasData) {
        // Fresh data received
        this.prepareData();
      }
    }
  }
  _config: any;
  @Input()
  reportBlock: ReportBlock;
  @Input()
  blockReady: boolean;

  // Take the raw "data", generate the chart data into "tableData"
  tableData: any;
  assetKeys: any[];
  days: any[] = ['Asset', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  constructor(private reportService: ReportService) { }

  ngOnInit() {
    this.prepareData();
  }

  prepareData() {
    var table = {};
    var dateFrom, dayFrom;

    dateFrom = new Date(this._config.startAt);

    let weekData = this._data;
    this.tableData = [];

    for (var dayx = 0; dayx < 7; dayx++) {
      dayFrom = dateFrom.getDate();
      let value = 0;
      for (var idx = 0; idx < weekData.length; idx++) {
        //console.log(data.dayNumber , dayx , data.hourOfDay , hourx);
        if (weekData[idx].day == dayFrom) {
          //console.log(value);
          value = weekData[idx].value;
          var assetId = "a" + weekData[idx].id;
          if (!table[assetId]) {
            table[assetId] = { title: weekData[idx].title, values: ['', '', '', '', '', '', ''] };
          }
          table[assetId].values[dayx] = value;
        }
      }
      dateFrom.setDate(dateFrom.getDate() + 1);
    }
    // console.log('table', table);
    // Map JSON to array
    this.assetKeys = Object.keys(table);
    this.tableData = this.assetKeys.map(v => table[v]);
    // console.info();
    // console.log('%c ' + this.tableData, 'color:orange');
  }
}

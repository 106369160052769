
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { ICalendarDay } from '../../../../layout/calendar/calendar.component';
import { Tenant } from 'app/classes/tenant';
import moment from 'moment';

@Component({
  selector: 'app-button-site-plan-dashboard-config',
  templateUrl: './button-site-plan-dashboard-config.component.html',
  styleUrl: './button-site-plan-dashboard-config.component.css'
})
export class ButtonSitePlanDashboardConfigComponent {
  readonly DEFAULT_LABEL = 'Configure report';

  _onDialog = signal<boolean>(false);
  label = signal<string>(this.DEFAULT_LABEL);
  _config = signal<ITenantDateConfig>({ tenantId: null, dates: { from: new Date(), to: new Date() } });
  _caption = signal<string>(null);
  _tenants = signal<Tenant[]>([]);

  @Input()
  public set caption(v: string) {
    this._caption.set(v);
  }

  @Input()
  public set value(v: ITenantDateConfig) {
    console.log(`set config`, v);
    this._config.set(v);
    this.changed();
  }

  @Input()
  public set tenants(v: Tenant[]) {
    this._tenants.set(v);
  }

  @Output()
  onSelect = new EventEmitter<any>();

  @Input()
  size = signal<string>('medium');

  constructor() {

  }

  closed(event: any) {
    this.onSelect.emit(null);
    this._onDialog.set(false);
    this.buildLabel();
  }

  buildLabel() {

    if (this._config()) {
      const dates = this._config().dates;
      const from = dates.from ? moment(dates.from).format('DD/MM/YYYY') : null;
      const to = dates.to ? moment(dates.to).format('DD/MM/YYYY') : null;
      const t = this._config().tenantId;
      let pre = '';
      if (t) {
        pre = this._tenants().find(tenant => tenant.id === +t).title + ', ';
      }
      if (from === null || to === null) {
        this.label.set(`${pre} Select Dates`);
      } else {
        this.label.set(`${pre} ${from} to ${to}`);
      }
    } else {
      this.label.set(this.DEFAULT_LABEL);
    }
  }

  ngOnDestroy(): void {

  }

  changed() {

    if (this._config().dates.to && this._config().dates.from) {
      this.onSelect.emit(this._config());
    }

    this.buildLabel();
  }

  changeDate(part: 'from' | 'to', day: ICalendarDay) {
    console.log(part, day);
    this._config.update(obj => {
      obj.dates[part] = day.date;
      return obj;
    });
    this.changed();
  }

  clear() {
    this._config.set({ tenantId: null, dates: { from: new Date(), to: new Date() } });
  }

  save() {
    this.onSelect.emit(this._config());
    this._onDialog.set(false);
    this.buildLabel();
  }

  tenantSelected(ev: any) {
    console.log(ev);
    this._config.update(item => {
      item.tenantId = ev;
      return item;
    });
    this.buildLabel();
  }
}

export interface ITenantDateConfig {
  tenantId?: number;
  dates?: { from: Date, to: Date };
}

<article [class.is-mobile]="isMobile()">
    <div class="error-message back-{{messageColour}}" *ngIf="message" [class.new-msg]="flashMessage">{{message}}
    </div>
    <main>
        <section>
            <div class="login">
                <div [class.is-inputting]="_inputFocus()">
                    <img class="logo" src="assets/svg/4d_logo_{{theme()}}.svg" width="140px" alt="logo" />
                </div>
                <div>
                    <div class="form">
                        <div>
                            <div *ngIf="tokenId" class="hero">
                                <h3>Please reset your password.</h3>
                            </div>
                            <div>
                                <div class="form-group">
                                    <label>Email</label>
                                    <input #email [(ngModel)]="user.email" name="email" pInputText (focus)="inputFocus()" />
                                </div>

                                <div class="form-group">
                                    <label>Password</label>
                                    <p-password styleClass="password" name="password" [(ngModel)]="user.password" [toggleMask]="true" [feedback]="false"></p-password>
                                </div>

                                <div class="form-group" *ngIf="tokenId">
                                    <label>Confirm Password</label>
                                    <input [(ngModel)]="newPassword" type="password" name="password" class="form-control" />
                                </div>

                                <div class="form-group mt-1">
                                    <button (click)="login()" type="submit" class="btn btn-primary btn-block">
                                        <span *ngIf="!tokenId">
                                            <span *ngIf="state===0">Login</span>
                                            <span *ngIf="state===1">
                                                <i class="fa fa-circle-o-notch fa-spin"></i>
                                            </span>
                                        </span>
                                        <span *ngIf="tokenId">Update password</span>
                                    </button>
                                </div>

                                <div class="secondary-actions forgot-password" [hidden]="siteDown || tokenId ">
                                    @if(isMobile()) {
                                    <app-button label="Forgot Password" (click)="forgotPassword()" />
                                    } @else {
                                    <a (click)="forgotPassword()" class="btn" [class.btn-secondary]="!attempts" [class.btn-success]="attempts">
                                        <span *ngIf="isProcessingForgotPassword">Sending link...</span>
                                        <span class="forgot-password" *ngIf="!isProcessingForgotPassword">Forgot
                                            Password?</span>
                                    </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>

                    <div class="award">
                        <img src="https://s3.eu-west-2.amazonaws.com/4d-images-cms/pfm-awards-2018-354x222.png">
                    </div>
                    <!--<div class="ingested">{{ingested}} packets of telemetry processed by the 4D platform.</div> -->
                </div>
            </div>
        </section>
        <section>
            <div class="news">
                <div class="placeholder" *ngIf="!cmsItems">
                    <h1>4D News</h1>
                </div>
                <div *ngFor="let feature of cmsItems">
                    <app-cms-item [item]="feature" theme="light" [showDate]="false"></app-cms-item>
                </div>
            </div>
        </section>
    </main>
</article>

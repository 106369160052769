@defer( when offlineIssue()) {
<app-info-panel><i class="mdi mdi-test-tube"></i> You are testing an experimental feature </app-info-panel>

@if(isAdmin()) {
<app-toolbar>
	<div class="left"><i class="mdi mdi-shield-account-outline" pTooltip="Admin toolbar"></i> </div>
	<div class="right">
		<app-button label="Issue detail" icon="mdi mdi-pencil" (click)="isEditingIssue.set(true)"></app-button>
	</div>
</app-toolbar>
@if(!offlineIssue().id) {
<!--
<p>There is currently no 4D communication logged here, click <app-button [isDisabled]="true" icon="mdi mdi-pencil" size="small" label="Issue Detail"></app-button> to start the process.</p> -->
} @else {
<h3 class="mt-1">{{offlineIssue().issue}}</h3>
}

@if(isEditingIssue()) {
<div style="border:1px solid var(--border-colour);padding:10px;margin:10px" class="edit">
	<app-data-form [dataForm]="issueDataForm()" />
	<app-toolbar>
		<div class="right">
			<app-button label="Submit" icon="mdi mdi-check" (click)="submit()" />
		</div>
	</app-toolbar>
</div>
}

}



} @placeholder {
<span><i class="mdi mdi-loading mdi-spin-x2"></i></span>
}

@defer() {
@if(reports?.length) {
@switch (view()) {

@case('card') {
<main>
    @for(report of reports;track report.id) {
    <div [routerLink]="['/reports',report.title === 'Air Quality' ? 'airquality' : report.id]">
        <h1>{{report.title}}</h1>
        <h4>Charts</h4>
        <ul>
            @for(row of report.details;track row) {
            <li>{{row}}</li>
            }
        </ul>
    </div>
    }
    <div [routerLink]="['/reports', 'trigger']">
        <h1>Sensor Trigger</h1>
        <p>Report on sensors below or above a value between times.</p>
        <h4>Charts</h4>
        <ul>
            <li>Telemetry</li>
            <li>Sensor List</li>
        </ul>
    </div>
    @if(isAdmin) {
    <div [routerLink]="['/reports', 'custom']">
        <h1>Custom Report (4D preview)</h1>
        <p>Report on your sensors.</p>
        <h4>Charts</h4>
        <ul>
            <li>Sensor Telemetry</li>
        </ul>
    </div>
    }
</main>
}

@default {
<table class="table table-hover sticky">
    <thead>
        <tr>
            <th>Title</th>
        </tr>
    </thead>
    <tbody>
        @for( item of reports; let i=$index; track item) {
        <tr [routerLink]="['/reports',item.title === 'Air Quality' ? 'airquality' : item.id]">
            <td><i class="fa fa-fw fa-{{item.icon}}"></i> {{item.title}}</td>
        </tr>
        }
        @if(isAdmin) {
        <tr [routerLink]="['/reports','trigger']">
            <td>
                <i class="mdi mdi-list-box-outline"></i> Custom Report (preview for 4D admins)
            </td>
        </tr>

        }
    </tbody>
</table>
}
}

} @else {
@if(reports?.length === 0) {
<div>
    <app-info-panel>No reports available.</app-info-panel>
</div>
}
}
} @placeholder (minimum 200ms) {

}
<app-please-wait *ngIf="!reports"></app-please-wait>

<article>

	<main>
		<p>The scheduler defines setpoints for a collection for assets, whilst enable auditing of setpoints with the ability
			to go back and base
			future setpoint schedules on past schedules.</p>
		<ol>
			<li [class.selected]="step === 'sites'">Select sites</li>
			<li [class.selected]="step === 'assets'">Select assets</li>
			<li [class.selected]="step === 'date'">Set processing date, title & reason</li>
		</ol>
	</main>
</article>

<section *ngIf="setpointTemplateCategory">
	<app-theme-page-container>
		<app-theme-page-section>

			<div class="form-group">
				<label for="title">Name of Category </label>
				<input id="title" name="title" maxlength="50" class="form-control" [(ngModel)]="setpointTemplateCategory.title">
			</div>

			<div class="form-group">
				<div for="notes">Notes</div>
				<textarea [(ngModel)]="setpointTemplateCategory.notes" [style]="{ 'width':'100%','height':'200px' }"></textarea>
			</div>
			<div class="buttons">
				<div class="text-right">
					<p-button label="Cancel" icon="pi pi-times" styleClass="p-button-outlined p-button-secondary mr-1" (click)="cancel()">
					</p-button>
					<p-button [label]="setpointTemplateCategory.id ? 'Update' : 'Create'" icon="mdi mdi-content-save" styleClass="" (click)="submit()">
					</p-button>
				</div>
			</div>
		</app-theme-page-section>
	</app-theme-page-container>
</section>

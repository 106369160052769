<article>
	<header>
		<app-toolbar>
			<div class="left">
				<app-button-assets (onSelect)="assetsSelected($event)" [selectedItems]="explorerSelectedItems()"></app-button-assets>

				<span class="seperator">|</span>

				@for(dateConfig of dateConfigs();track dateConfig) {
				@if(dateConfig.dateRange === 'custom') {
				<app-button-dates [value]="dateConfig" />
				}
				}

				<span class="seperator">|</span>

				<!--<app-button icon="mdi mdi-calendar" label="Add Date range" (click)="addDateRange()" />-->
				<app-button icon="mdi mdi-cog" label="Configure" (click)="clickConfigure()" />
			</div>
		</app-toolbar>
	</header>

	<main class="content-container">
		<div class="asset-chips mt-1">
			@for(asset of assets();track asset.id;let i=$index) {
			<div [style.border-color]="colours[i]">
				<app-chip [label]="asset.title" (click)="assetChipClick(asset)" />
				@if(assetsDateConfigs() && assetsDateConfigs()[asset.id]?.dateRange) {
				<app-chip [label]="assetsDateConfigs()[asset.id].dateRange" (click)="assetChipClick(asset)" icon="mdi mdi-calendar" [noBackground]="true" />
				} @else {
				<app-chip label="No dataset" [isDanger]="true" (click)="assetChipClick(asset)" pTooltip="Select dataset for this sensor" />
				}
				<i class="mdi mdi-close can-click ml-1" (click)="removeAsset(asset)" pTooltip="remove Asset"></i>
			</div>
			}
		</div>

		<div class="mt-1">
			<app-button label="Build charts" (click)="buildCharts()" icon="mdi mdi-play" />
		</div>

		<div class="chart">
			<canvas id="chart-1"></canvas>
		</div>

		<footer>
			<i>{{analysis?.message}}</i>
		</footer>
	</main>

	@if(assetSelected()) {
	<app-dialog [header]="assetSelected().title" (onClose)="assetSelected.set(null)" [dimensions]="{width:400, height:200}">
		<div>{{assetSelected().title}}</div>
		@for(dateConfig of dateConfigs();track dateConfig) {
		<app-button label="{{dateConfig.dateRange}}" (click)="assetUseDateConfig(dateConfig)"></app-button>
		}
	</app-dialog>
	}

	@if(configureSelected()) {
	<app-dialog header="Chart Configuration" (onClose)="configureSelected.set(null)" [dimensions]="{width:380, height:160}">

		<app-button icon="mdi mdi-rotate-3d-variant" [label]="dialogConfig.pointStyle ? 'Point style circle' : 'No point style'" (click)="toggleProperty('pointStyle')" />

		<app-button icon="mdi mdi-rotate-3d-variant" [label]="dialogConfig.fill ? 'Fill chart' : 'Do not fill chart'" (click)="toggleProperty('fill')" />
		<div></div>

		<app-button icon="mdi mdi-rotate-3d-variant" [label]="dialogConfig.lineWidth === 1 ? 'Thin line' : 'Thick line'" (click)="toggleProperty('lineWidth')" />

		<app-button icon="mdi mdi-rotate-3d-variant" [label]="dialogConfig.lineCurve ? 'Curve at points' : 'No line curve'" (click)="toggleProperty('lineCurve')" />

	</app-dialog>
	}
</article>

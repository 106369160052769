<article class="content-container">
  <section>
    <h2>Orgs</h2>
    <table class="table">
      <thead>
        <tr>
          <th>Org</th>
          <th>Sites</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody>
        @for (org of orgs(); track org.id) {
          <tr>
            <td>{{ org.title }}</td>
            <td>{{ org.siteCount }}</td>
            <td>{{ org.isActive ? 'Yes' : 'No' }}</td>
          </tr>
        }
      </tbody>
    </table>
  </section>

  <section>
    <h2>Sites</h2>
    {{ sites?.length }}
  </section>
  <!--
  <section>
    <h2>Tracked</h2>
    <table class="table">
      <thead>
        <tr>
          <th>Site</th>
          <th>Gateway</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        @for (report of reports(); track report) {
          <tr>
            <td>{{ report.title }}</td>
            <td>{{ report.gatewayTitle }}</td>
            <td>{{ org.engagementStatus }}</td>
          </tr>
        }
      </tbody>
    </table>
  </section>
--></article>

import { Component, OnInit, Input } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { SitePlan } from '../../classes/site-plan';
import { SiteFloorplanAsset } from '../../classes/site-floorplan-asset';

@Component({
  selector: 'app-site-chart',
  templateUrl: './site-chart.component.html',
  styleUrls: ['./site-chart.component.css']
})
export class SiteChartComponent implements OnInit {

  @Input()
  siteFloorplan: SitePlan;

  @Input()
  range: RangeInterface;

  @Input()
  assets: SiteFloorplanAsset[];

  chartData: any;

  constructor(private apiService: APIService) {

    var days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    this.chartData = {
      labels: days,
      datasets: [],
      percTotalChange: null
    };
    this.getHistory();
  }

  ngOnInit() {
  }

  getHistory() {
    return new Promise((resolve, reject) => {
      this.apiService.getSitePlanAssetHistory(this.siteFloorplan, this.range.startsAt, this.range.endsAt).then(results => {
        console.log('got history');
        results.values.forEach((historyItem) => {
          console.log(historyItem);
          this.assets.forEach(profileAsset => {
            if (profileAsset.id === historyItem.asset_id) {
              profileAsset.history[historyItem.daysAgo] = historyItem;
            }
          });
          resolve(true);
        })
      });
    });

  }
}

export interface RangeInterface {
  startsAt: string;
  endsAt: string;
  type: string;
}
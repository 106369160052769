<div class="setpoint-bars" [class.responsive]="screenAdjust">
  
  <div class="bars" *ngIf="days" [style.width.px]="barWidth">
    <div class="bar day-{{i}}" (mouseenter)="mouseenter(i)" (mouseleave)="mouseleave()" *ngFor="let day of days;let i = index"
      [class.green]="day?.maxAlert===0 && day?.minAlert===0 && day?.minOrangeAlert=== 0 && day?.maxOrangeAlert===0"
      [class.orange]="day?.maxOrangeAlert>0 || day?.minOrangeAlert>0" [class.red]="day?.maxAlert>0 || day?.minAlert>0"
      (click)="hasClicked(i)">
    </div>
  </div>

  <div class="info">
    <div class="details">
      <span *ngIf="hoverDay && hoverDay.date">{{hoverDay.date}} min:<span>{{hoverDay.min}}</span> max:<span>{{hoverDay.max}}</span></span>
    </div>
  </div>

</div>
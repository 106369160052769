import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FloorplanService, SitePlanComplete } from 'app/shared/floorplan.service';
import { IToolbarChanged, ViewType } from '../site-plan-floorplan/site-plan-floorplan-toolbar/site-plan-floorplan-toolbar.component';
import { IDatesFromTo } from 'app/interfaces/dates-from-to';

@Component({
  selector: 'app-site-plan-floorplan-container',
  templateUrl: './site-plan-floorplan-container.component.html',
  styleUrls: ['./site-plan-floorplan-container.component.css']
})
export class SitePlanFloorplanContainerComponent {
  view: ViewType = 'realtime';
  planId: number;
  siteId: number;
  dates: IDatesFromTo;
  isReady = false;

  @Input()
  public set config(v: { planId: number, siteId: number }) {
    if (!v || !v.planId || !v.siteId) {
      return;
    }
    if (v.planId !== this.planId) {
      this.planId = v.planId;
      this.siteId = v.siteId;
    }
    setTimeout(() => {
      this.isReady = true;
    }, 100);

  }

  @Output()
  onFloorplanLoaded = new EventEmitter<SitePlanComplete>();

  sitePlanComplete: SitePlanComplete;

  constructor(private floorplanService: FloorplanService) {

  }

  floorplanLoaded(sitePlanComplete: SitePlanComplete) {
    this.sitePlanComplete = sitePlanComplete;
    this.onFloorplanLoaded.emit(sitePlanComplete);
  }

  toolbarChanged(toolbarChange: IToolbarChanged) {
    console.log(`toolbarChanged(${JSON.stringify(toolbarChange, null, 2)})`);

    if (this.view !== toolbarChange.what) {
      this.isReady = false;

      setTimeout(() => {
        this.view = toolbarChange.what;
        this.dates = toolbarChange.change?.dates || null;
        this.isReady = true;
      }, 500);
    } else {
      console.log(toolbarChange);
      switch (toolbarChange.what) {
        case 'occupancy daily':
        case 'occupancy weekly':
          this.floorplanService.setDataSubject('occupancy', toolbarChange.change.dates);
          break;
      }
    }
  }
}

<app-toolbar>
    <div class="right">
        <app-button label="Create Group" icon="mdi mdi-account-multiple-plus" (click)="createGroup()"></app-button>
    </div>
</app-toolbar>
@defer(when groupings) {
@if(groupings.length) {
<table class="table table-hover table-striped">
    <thead>
        <tr>
            <th>Title</th>
            <th>Users</th>
            <th>Created</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let group of groupings" (click)="showGroup(group)">
            <td>{{group.title}}</td>
            <td>{{group.userCount}}</td>
            <td>{{group.createdAt | amDateFormat:'DD MMM YYYY HH:mm:ss'}}</td>
        </tr>
    </tbody>
</table>
} @else {
<app-info-panel>No Groups</app-info-panel>
}

} @placeholder {
<app-please-wait></app-please-wait>
}

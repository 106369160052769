<app-theme-page-container *ngIf="item" label="Compliance" [titleLink]="['/org']">
	<app-theme-page-section [label]="'Create Compliance Group'">

		<div class="col-sm-12 col-xs-12 col-md-6">
			<div class="form-group">
				<label for="title">Title</label>
				<input maxlength="50" id="title" name="title" class="form-control" type="text" [(ngModel)]="item.title">
			</div>
		</div>

		<div class="col-sm-12 col-xs-12 col-md-6">
			<div class="form-group">
				<label for="title">Frequency</label>
				<select [(ngModel)]="item.frequency" class="form-control">
					<option value="week">Every Week</option>
					<option value="month">Every Month</option>
				</select>
			</div>
		</div>

		<div class="col-sm-12 col-xs-12 col-md-12">
			<div class="form-group">
				<label for="description">Description</label>
				<textarea [(ngModel)]="item.description" name="description" class="form-control" rows="6"></textarea>
			</div>
		</div>

		<p-toolbar>
			<div class="p-toolbar-group-left">
			</div>
			<div class="p-toolbar-group-right">
				<p-button label="Save" icon="pi pi-check" styleClass="p-button-sm" (click)="save()"></p-button>
			</div>
		</p-toolbar>
		<div class="clearfix"></div>
	</app-theme-page-section>
</app-theme-page-container>

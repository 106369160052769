<div class="building recent inset">
    <!--<div class="subtitle">Recent</div>
    <div class="telemetry" [class.new-value]="hasValue" *ngIf="recent">
        <div><img *ngIf="recent.logo" src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{recent.logo}}"></div>
        <div>
            {{recent.gatewayTitle}}<br>
            <span class="received">{{recent.receivedAt | date}}</span>
        </div>
    </div>
    <div *ngIf="!recent">
        <i class="fa fa-fw fa-spin fa-refresh"></i> Waiting for next telemetry...
    </div> -->
</div>

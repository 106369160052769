<article>
  @defer (when batteryAssets()) {
    @if (batteryAssets().length) {
      <section class="annotation">
        <div class="asset mb-1">
          <div class="asset-title"><app-asset-icon [assetTypeId]="67"></app-asset-icon> Batteries</div>

          <div class="no-print annotation-select">
            <div>Annotation</div>
          </div>
          <div class="annotation-edit">
            <div class="rag rag-{{ annotation.rag }}" (click)="clickRAG()"></div>
            <div>
              <!-- prettier-ignore -->
              <div [contentEditable]="true" class="annotation-asset annotation-battery" #annotationDiv (focusout)="focusOutDIV()">{{ annotation.text }}</div>
            </div>
          </div>
        </div>
      </section>

      <section class="assets">
        @for (asset of batteryAssets(); track asset.id) {
          <div class="asset asset-{{ asset.id }} mb-1 rag-{{ asset.battery.rag }}" (click)="batteryAsset.set(asset)">
            <div class="asset-title"><app-asset-icon [assetTypeId]="asset.assetType_id"></app-asset-icon> {{ asset.title }}</div>
            <div class="flex">
              @if (asset.battery.rag !== 'no-data') {
                <div class="flex-1 text-center">{{ asset.battery.max }}</div>
                <div class="text-center">to</div>
                <div class="flex-1 text-center">{{ asset.battery.min }}</div>
              } @else {
                No Data
              }
            </div>
          </div>
        }
      </section>
    }
  } @placeholder {
    <i class="mdi-loading mdi mdi-spin-x2"></i>
  }
</article>

@if (batteryAsset()) {
  <app-dialog (onClose)="batteryAsset.set(null)" [dimensions]="{ height: '600', width: null }">
    <app-setpoint-query [asset]="batteryAsset()" [fullscreen]="fullscreen"></app-setpoint-query>
  </app-dialog>
}

/**
 * @todo load recent setpoint asset
 * @todo load RAG counters
 */
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DashboardService } from 'app/shared/dashboard.service';
import { Asset } from 'app/classes/asset';

@Component({
  selector: 'app-dashboard-tile-data-setpoints',
  templateUrl: './dashboard-tile-data-setpoints.component.html',
  styleUrls: ['./dashboard-tile-data-setpoints.component.css']
})
export class DashboardTileDataSetpointsComponent implements OnInit, OnDestroy {
  _recent: any;
  hasValue: boolean;
  totals: RAG = { green: null, amber: null, red: null };
  timer: any;

  @Input()
  public get recent(): any {
    return this._recent;
  }
  public set recent(v: any) {
    this.hasValue = !!v;
    this._recent = v;
    setTimeout(() => {
      this.hasValue = false;
    }, 1500);
  }

  asset: Asset;
  constructor(private dashboardService: DashboardService) { }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  ngOnInit() {
    // @deprecated this.asset = new Asset({ id: 128, title: 'AHU Left', assetType_id: 2, value: 28 });
    this.asset = new Asset();
    this.getSetpointTotals();

    this.timer = setInterval(() => {
      this.getSetpointTotals();
    }, 60000);
  }

  getSetpointTotals() {
    this.dashboardService.getSetpointTotals().subscribe(totals => {
      if (JSON.stringify(this.totals) !== JSON.stringify(<RAG>totals)) {
        this.totals = <RAG>totals;
      }
    });
  }
}

export interface RAG {
  red: number;
  amber: number;
  green: number;
}

<div class="col-sm-12">
  <h2>Submit an issue</h2>
</div>

<div class="col-sm-12 selection">
  <div class="row">
    <div class="selected" *ngIf="selectedSite">

      <div *ngIf="selectedSite">
        <div class="col-xs-3 col-sm-2">
          Site
        </div>
        <div class="col-xs-9 col-sm-10">
          :
          <button class="btn btn-secondary" (click)="cancelSite()">
            <i class="fa fa-times"></i> {{selectedSite.title}}</button>
        </div>
      </div>

      <div *ngIf="selectedGateway">
        <div class="col-xs-3 col-sm-2">
          Gateway
        </div>
        <div class="col-xs-9 col-sm-10">
          :
          <button class="btn btn-secondary" (click)="cancelGateway()">
            <i class="fa fa-times"></i> {{selectedGateway.title}}</button>
        </div>
      </div>

      <div *ngIf="selectedAsset">
        <div class="col-xs-3 col-sm-2">
          Sensor
        </div>
        <div class="col-xs-9 col-sm-10">
          :
          <button class="btn btn-secondary" (click)="cancelAsset()">
            <i class="fa fa-times"></i> {{selectedAsset.title}}</button>
        </div>
      </div>
    </div>
  </div>


  <!-- Objects awaiting selection -->
  <div class="create-condition" *ngIf="!selectedSite">
    <h4>Select a
      <strong>site</strong>
    </h4>
    <app-site-picker (submit)="siteSelected($event)"></app-site-picker>
  </div>

  <div class="create-condition" *ngIf="selectedSite && !selectedGateway && step===1">
    <h4>Select a
      <strong>gateway</strong> for {{selectedSite.title}} <small>or <a (click)="gotoStep(2)">click here for a site issue</a></small></h4>
    <app-gateway-picker [site]="selectedSite" (submit)="gatewaySelected($event)"></app-gateway-picker>
  </div>

  <div class="create-condition" *ngIf="selectedGateway && !selectedAsset  && step===1">
    <h4>Select a
      <strong>sensor</strong> for {{selectedGateway.title}} <small>or <a (click)="gotoStep(2)">click here for a gateway issue</a></small></h4>
    <app-sensor-picker [gateway]="selectedGateway" (submit)="sensorSelected($event)"></app-sensor-picker>
  </div>

</div>


<div class="col-sm-12" *ngIf="selectedSite && step === 2">

  <form>
    <div class="form-group">
      <label for="title">Title</label>
      <input name="title" [(ngModel)]="title" type="title" class="form-control" id="title" aria-xdescribedby="title" placeholder="Enter the issue title.">
      <small id="titleHelp" class="form-text text-muted">Required, a short description of the issue.</small>
    </div>

    <div class="form-group">
      <label for="description">Description</label>
      <textarea [(ngModel)]="description" name="description" id="description" class="form-control" rows="4"></textarea>
      <small id="descriptionHelp" class="form-text text-muted">Required, please describe the issue in as much detail as possible.</small>
    </div>

    <button type="submit" class="btn btn-primary" (click)="submit()">
      <i class="fa fa-floppy-o"></i> &nbsp;Submit issue</button>
  </form>
</div>
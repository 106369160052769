@defer( when compliance?.current) {
@if(compliance?.current?.assets.length) {
<main>
    <section class="flex-2">
        <div>
            <app-compliance-dial *ngIf="compliance.previous" [compliance]="compliance.previous" [title]="compliance.previous.title"></app-compliance-dial>
        </div>
        <div>
            <app-compliance-dial *ngIf="compliance.current" [compliance]="compliance.current" [title]="compliance.current.title"></app-compliance-dial>
        </div>
    </section>
    <section>
        <p-tabView (onChange)="handleChange($event)" [(activeIndex)]="tabIndex">
            <p-tabPanel [header]="compliance.current?.title" [cache]="true">
                <div *ngIf="compliance.current">
                    <app-compliance-assets *ngIf="compliance" [compliance]="compliance.current"></app-compliance-assets>
                </div>
            </p-tabPanel>
            <p-tabPanel [header]="compliance.previous?.title" [cache]="true">
                <div *ngIf="compliance.previous">
                    <app-compliance-assets [compliance]="compliance.previous"></app-compliance-assets>
                </div>
            </p-tabPanel>
        </p-tabView>
    </section>
</main>
} @else {
<main>
    <app-info-panel icon="info">No compliance data available.</app-info-panel>
</main>
}
} @placeholder(minimum 100ms) {
<i class="mdi mdi-loading mdi-spin-x2"></i>
}

<ng-template [ngIf]="cameras && cameras.length">
  <section class="cameras">
    <div *ngFor="let camera of cameras; let i = index">
      <div class="" (click)="selectItem(camera)">
        <div>
          <app-camara-box selectable="true" [camera]="camera"></app-camara-box>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<app-please-wait *ngIf="!cameras"></app-please-wait>
<div *ngIf="cameras && !cameras.length">
  <div class="nothing-returned">
    <app-info-box title="No camera devices" link="Find out more &rarr;" (trayLinkClicked)="clickShowMore()"> Your
      organisation has no cameras, timelapse and carpark light monitoring options are available.
    </app-info-box>
  </div>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { StoreService } from '../../shared/store.service';
import { Router, ActivatedRoute, Params, RouterOutlet, RouterLink, RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { FilterMenus } from '../../classes/filter-menus';
import { FilterMenu } from '../../classes/filter-menu';
import { FilterMenuItem } from '../../classes/filter-menu-item';
import { filter } from 'rxjs/operators';
import { SiteService } from 'app/shared/site.service';
import { SetpointsService } from 'app/shared/setpoints.service';

export const ROUTER_DIRECTIVES = [RouterOutlet, RouterLink, RouterLinkWithHref,
  RouterLinkActive];

@Component({
  selector: 'app-dashboard-tag',
  templateUrl: './dashboard-tag.component.html',
  styleUrls: ['./dashboard-tag.component.css']
})
export class DashboardTagComponent implements OnInit, OnDestroy {
  tag: string;
  title: string;
  results: any;
  gettingData: boolean;

  selectedAsset: any;
  selectedSite: any;
  isAdmin: boolean;
  offset: number;
  filterSubscription: any;

  constructor(private setpointService: SetpointsService, private siteService: SiteService, private storeService: StoreService, private router: Router, private route: ActivatedRoute, private apiService: APIService) { }

  loadMore(tagLabel: string) {
    this.offset += 50;
    this.load(tagLabel);
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.offset = 0;
    this.route.params.subscribe((params: Params) => {
      const myOrg = this.apiService.getUserOrg();
      this.tag = params['tag'];
      console.log(this.tag);
      this.load(this.tag);
      if (this.tag === 'gateways-telemetry-today') {
        // Telemetry has filtering
        this.siteService.getSites().then(sites => {
          let menus = new FilterMenus();
          let menu = new FilterMenu();
          menus.items.push(menu);
          menu.title = 'Sites';
          menu.action = ''; // Can't display for - too much for lsh
          sites.forEach(site => {
            let menuItem = new FilterMenuItem();
            menuItem.title = site.title;
            menuItem.count = null;
            menuItem.id = site.id;
            menuItem.rag = site.rag;
            menuItem.site = site;
            if (site.org && site.org.id !== myOrg.id) {
              // Sites owned by other orgs have a tooltip
              menuItem.tooltip = site.org.shortTitle;
            }
            menu.items.push(menuItem);
          });

          let savedSite = localStorage.getItem('gateways-telemetry-today:site');
          let savedSiteTitle = localStorage.getItem('gateways-telemetry-today:site-title') || 'Telemetry Today';

          if (params['siteid']) {
            savedSite = params['siteid'];
            savedSiteTitle = '';
          }

          if (savedSite && sites.filter(s => s.id === +savedSite).length) {
            this.title = savedSiteTitle;
            this.loadSiteTelemetry(+savedSite, savedSiteTitle);
            // Register the site url, not that a blank page was displayed (/dashboard/gateways-telemetry-today)
            this.apiService.postRoute(`/dashboard/gateways-telemetry-today/site/${savedSite}`);
            this.storeService.setFilterMenuSelect(+savedSite);
          }
          this.filterSubscription = this.storeService.filterSelect
            .pipe(filter(f => !!f))
            .subscribe(filterMenu => {
              if (!filterMenu) {
                return;
              }
              // Reset the selected asset
              this.selectedAsset = null;
              this.selectedSite = filterMenu.site;
              console.log(JSON.stringify(filterMenu, null, 2));
              this.apiService.postRoute(`/dashboard/gateways-telemetry-today/site/${filterMenu.id}`);
              this.loadSiteTelemetry(filterMenu.id, filterMenu.title);
            });
          this.storeService.setFiltering(menus);

        });
      }
    });

    console.log('dashboard-tag-component', this.tag);

    this.isAdmin = this.apiService.isAdmin();
  }

  navigateBack() {
    if (this.selectedAsset) {
      this.selectedAsset = null;
      this.title = 'Telemetry Today';
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  loadSiteTelemetry(siteId: number, title: string) {
    if (!siteId) {
      // Must have a site.
      return;
    }
    this.apiService.getOrgTelemetry({ site: { id: siteId } })
      .then(result => {
        let gateways = [];
        if (result.length === 0) {
          this.results = [];

          return;
        }
        result = result.filter(asset => asset.assetType !== 32 && asset.assetType !== 21 && asset.assetType !== 31 && asset.assetType !== 12);
        // Allocate gateways their own array location
        for (let idx = 0; idx < result.length; idx++) {
          let gatewayId = result[idx].gatewayId;
          if (idx === 0 || result[idx - 1].gatewayId !== gatewayId) {
            gateways.push([]);
          }
          gateways[gateways.length - 1].push(result[idx]);
        }

        console.log(gateways);
        this.gettingData = false;
        this.results = gateways.reverse();
        localStorage.setItem('gateways-telemetry-today:site', siteId.toString());
        localStorage.setItem('gateways-telemetry-today:site-title', title);
        if (this.title) {
          this.title = title;
        }
      }
      );
  }

  load(tag: string) {
    switch (this.tag) {
      case 'alarms-delayed':
        this.apiService.getAlarms('delayed').then(
          (result) => {
            this.results = result;
            this.title = "Alarms Delayed";
          }
        );
        break;
      case 'alarms-acknowledged':
        this.apiService.getAlarms('acknowledged').then(
          (result) => {
            this.results = result;
            this.title = "Alarms Acknowledged";
          }
        );
        break;
      case 'alarms-not-actioned':
        this.apiService.getAlarms('new').then(
          (result) => {
            this.results = result;
            this.title = "Alarms actioned";
          }
        );
        break;
      case 'alarms-resolved-today':
        this.apiService.getAlarms('resolved').then(
          (result) => {
            this.results = result;
            this.title = "Alarms resolved";
          }
        );
        break;
      case 'gateways-telemetry-today':
        this.title = "Telemetry Today";
        this.gettingData = true;

        break;
      case 'gateways-disconnected':
        this.title = "Disconnects within the last 24 hours";
        this.gettingData = true;
        this.apiService.getTimelineForOrg(this.offset).then(
          (result) => {
            this.gettingData = false;
            this.results = result;
          }
        );
        break;
      case 'assets-inerror':
        this.title = "Sensors in error";
        this.gettingData = true;
        this.apiService.getAssetsInError().then(
          (result) => {
            this.gettingData = false;
            this.results = result;
          }
        );
        break;
      case 'gateways-online':
        this.title = "Gateways Online";
        this.gettingData = true;
        this.results = [];
        this.setpointService.getSetPointsHistoryForUserOrg(2, 60).then(
          assets => {
            let gateways = [];

            assets.forEach(asset => {
              if (gateways[asset.gatewayId]) {

              } else {
                gateways[asset.gatewayId] = {
                  title: asset.gatewayTitle,
                  assets: {}
                };
              }
              let aId = 'A' + asset.assetId;
              if (gateways[asset.gatewayId].assets[aId]) {

              } else {
                gateways[asset.gatewayId].assets[aId] = {
                  title: asset.assetTitle,
                  id: asset.assetId,
                  days: []
                };
              }

              gateways[asset.gatewayId].assets[aId].days.push(asset);

            });
            // Convert object keys into arrays
            Object.keys(gateways).forEach(gatewayId => {
              let gateway = gateways[gatewayId];
              gateway.id = gatewayId;
              let assets = [];

              Object.keys(gateway.assets).forEach(assetId => {
                assets.push(gateway.assets[assetId]);
              });
              // Sort assets by title
              gateway.assets = assets.sort((a, b) => {
                if (a.title < b.title) { return -1; }
                if (a.title > b.title) { return 1; }
                return 0;
              });
              this.results.push(gateway);
            });
            // Sort gateways by title
            this.results.sort((a, b) => {
              if (a.title < b.title) { return -1; }
              if (a.title > b.title) { return 1; }
              return 0;
            });

            this.gettingData = false;
          }
        );
        break;
      default:
        console.log(`unhandled dashboard ${this.tag}`);
        break;
    }
  }

  showTelemetry(asset: any) {
    this.selectedAsset = asset;

    this.title = asset.title;
    try {
      this.apiService.postRoute(`/dashboard/${this.tag}/${asset.assetId}`);
    } catch (e) {
      console.log(e);
    }
  }

  ngOnDestroy() {
    this.storeService.setFiltering(null);
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { APIService } from '../shared/api.service';
import { Ticket } from '../classes/ticket';
import { Message } from '../classes/message';
import { AssetTicket } from '../classes/asset-ticket';
import { GatewayTicket } from '../classes/gateway-ticket';
import { SiteTicket } from '../classes/site-ticket';
import { TicketService } from "../shared/ticket.service";
import { ActivatedRoute } from '@angular/router';

import { TicketKind } from "../classes/factory";

@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.css']
})
export class IssuesComponent implements OnInit, OnDestroy {

  init: boolean;
  tickets: Ticket[];
  sub: any;

  counts:any = { asset: 0, gateway: 0, site: 0};
  ticketKind: any;

  constructor(private ticketService: TicketService, private apiService: APIService, private route: ActivatedRoute) {

    this.ticketKind = TicketKind;
   }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      console.log(params);
    });

    this.apiService.getTickets().then(tickets => {
      let assetTickets = tickets[0];
      let gatewayTickets = tickets[2];
      let siteTickets = tickets[4];

      this.counts.asset = assetTickets.length;
      this.counts.gateway = gatewayTickets.length;
      this.counts.site = siteTickets.length;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  enterBox(ticketType: TicketKind) {
    console.log(ticketType);
    this.ticketService.setTicketFilter(ticketType);
  }

  exitBox() {
    this.ticketService.setTicketFilter(null);
  }
  

}

import { Collection } from './collection';
import { SettingItem } from './setting-item';

/**
 * @version 1.0.2
 */
export class SettingCollection {
    settings: Collection<SettingItem> = new Collection<SettingItem>();

    setSettings(settings: SettingItem[] | Object) {
        if (Array.isArray(settings)) {
            this.settings.list = <SettingItem[]>settings;
        } else {
            this.settings.list = this.objectToSettings(settings);
        }
    }

    objectToSettings(obj: Object) {
        return Object.keys(obj).map(key => {
            const settingItem = obj[key];
            if (typeof settingItem === 'string') {
                return new SettingItem({ setting: key, value: settingItem, type: 'string' });
            } else {
                return new SettingItem({ setting: key, value: settingItem.value, type: settingItem.type });
            }
        });
    }

    addSettings(settings: any[]) {
        this.settings.addAll(settings);
    }

    get(setting: any) {
        const matching = this.settings.list.filter(item => item.setting === setting);

        if (matching.length) {
            return matching[0];
        } else {
            return null;
        }
    }

    hasChanged(settings: SettingItem[] | Object): boolean {
        let dirty = false;
        let checkSettings: SettingItem[];

        if (!Array.isArray(settings)) {
            checkSettings = this.objectToSettings(settings);
        } else {
            checkSettings = settings;
        }

        checkSettings.filter(changedItem => {
            const changed = this.settings.list.filter(existingItem => {
                console.log(existingItem.setting, changedItem.setting, existingItem.value, changedItem.value);
                return existingItem.setting === changedItem.setting && existingItem.value !== changedItem.value;
            });
            let isNew: boolean;
            if (changed.length === 0) {
                // Check if new
                isNew = this.settings.list.filter(existingItem => existingItem.setting === changedItem.setting).length === 0;
            }
            if (changed.length || isNew) {
                console.log(changed[0], changedItem);
                dirty = true;
            }
        });
        return dirty;
    }

    /**
     * Merge settings
     * @param merge 
     * @param options {missing: boolean}
     */
    mergeSettings(merge: SettingItem[], options: MergeOptions = {}) {
        const { missing, canExtendSettings } = options;

        if (!Array.isArray(merge)) {
            merge = this.objectToSettings(merge);
        }

        for (let index0 = 0; index0 < this.settings.list.length; index0++) {
            const element = this.settings.list[index0];

            for (let index = 0; index < merge.length; index++) {
                const mergeItem = merge[index];
                if (mergeItem.setting === element.setting) {
                    if (!missing) {
                        this.settings.list[index0] = mergeItem;
                    } else {
                        this.settings.add(element);
                    }
                }
            }
        }
    }

    asObject() {
        const obj = {};
        this.settings.list.forEach(settingItem => {
            obj[settingItem.setting] = { value: settingItem.value, type: settingItem.type };
        });

        return obj;
    }
}

export interface MergeOptions {
    missing?: boolean;
    canExtendSettings?: boolean;
}

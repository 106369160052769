import { Component, OnInit, effect, input, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Org } from 'app/classes/org';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-org-switch',
  templateUrl: './org-switch.component.html',
  styleUrl: './org-switch.component.css'
})
export class OrgSwitchComponent implements OnInit {

  orgsIn = input.required<Org[]>();
  orgs: Org[] = [];
  orgSwaps: Org[] = [];
  LOCALSTORAGE_ORGS_SWAP = 'orgs:swap';
  showInactive = false;
  showId = false;
  isWorking = signal<boolean>(false);
  switchToOrg: Org;

  constructor(private apiService: APIService, private router: Router) {
    const orgSwaps = JSON.parse(localStorage.getItem(this.LOCALSTORAGE_ORGS_SWAP) || '{}');
    this.showInactive = orgSwaps.showInactive;
    this.showId = orgSwaps.showId ?? false;

    effect(() => {
      this.orgs = this.orgsIn();
      this.switchToOrg = this.orgs[0];
      this.orgSwaps = this.orgs.filter(o => o.swapCount)
        .sort((a, b) => a.swapCount < b.swapCount ? 1 : -1)
        .sort((a, b) => a.swapUpdatedAt < b.swapUpdatedAt ? 1 : -1);
    });
  }

  ngOnInit() {
  }

  toggleInactiveOrgs() {
    this.showInactive = !this.showInactive;
    this.saveLocalStorage();
  }

  saveLocalStorage() {
    localStorage.setItem(this.LOCALSTORAGE_ORGS_SWAP, JSON.stringify({ showInactive: this.showInactive, showId: this.showId }));
  }

  sortOrgBy(property: string) {
    this.orgs.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
  }

  switchOrg(org: Org) {
    this.switchToOrg = org;
    this.isWorking.set(true);

    window.scroll(0, 0);

    this.apiService.switchOrg(org).then((result) => {
      this.isWorking.set(false);
      if (result) {

        this.router.navigate(['/login']);
      }
    });
  }

  toggleOrgId() {
    this.showId = !this.showId;
    this.saveLocalStorage();
  }
}

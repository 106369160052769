import { Component, Input, OnInit } from '@angular/core';
import { Grouping } from 'app/classes/grouping';
import { RuleNotification } from 'app/classes/rule-service/rule-notification';
import { RulePackage } from 'app/classes/rule-service/rule-package';

@Component({
  selector: 'app-rulesv3-item-contacts',
  templateUrl: './rulesv3-item-contacts.component.html',
  styleUrls: ['./rulesv3-item-contacts.component.css']
})
export class Rulesv3ItemContactsComponent implements OnInit {
  @Input()

  public set rule(v: RulePackage) {
    this.groupings = v?.groupings || undefined;
    this.notifications = v?.notifications || undefined;
  }

  groupings: Grouping[];
  notifications: RuleNotification[];

  constructor() {

  }

  ngOnInit(): void {

  }

}

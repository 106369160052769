import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-media-carousel',
  templateUrl: './media-carousel.component.html',
  styleUrls: ['./media-carousel.component.css']
})
export class MediaCarouselComponent {

  @Input()
  title = 'Related videos';

  @Input()
  keyword: string;

  @Input()
  items: { title: string, overview: string, thumbnail: string, type: 'video', target: string }[];

  media: {
    'setpoints': [{

    }]
  }

  showVideo: any;

  constructor() {


  }

  itemClick(item) {
    this.showVideo = item;
  }

}

import { Component, OnInit, OnDestroy, signal } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from '../../shared/store.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { EngagementService, EngagementSharedSite, EngagementUserLogItem } from "../../shared/engagement.service";
import { User } from 'app/classes/user';

@Component({
  selector: 'app-partner-landing',
  templateUrl: './partner-landing.component.html',
  styleUrls: ['./partner-landing.component.css']
})
export class PartnerLandingComponent implements OnInit, OnDestroy {
  tabIndex = signal<0 | 1>(0);
  isAdmin = signal<boolean>(false);
  percOf = 95;
  totalSites: number;
  sub: any;
  results: any[];
  userLog: EngagementUserLogItem[];
  loading = false;
  engagementSubscription: Subscription;
  userSuscription: Subscription;
  selectedOrg: number;

  users: SelectItem[] = [];
  usersSelected = null;

  isFiltering = false;
  unfilteredResults = [];
  displayAudit: boolean;

  sharedSites: EngagementSharedSite[];

  user: User;

  constructor(private apiService: APIService, private route: ActivatedRoute, private storeService: StoreService, private engagementService: EngagementService) {
    const key = this.apiService
      .getUserOrg().id + ':' + this.apiService.getUserId() + ':engagement:users';
    const usersSelected: any = localStorage.getItem(key);
    if (usersSelected) {
      this.usersSelected = JSON.parse(usersSelected);
    } else {
      this.usersSelected = [];
    }

    this.userSuscription = this.apiService.user.subscribe(user => {
      this.user = user;
    });

    this.engagementSubscription = this.storeService.engagement
      .pipe(filter(engagement => engagement !== null))
      .subscribe(engagement => {
        if (this.loading) {
          return
        };
        this.loading = true;
        this.storeService.setDataLoading(true);
        this.users = engagement.users.map(user => { return { label: user.name, value: user.name } });
        const roleEngagementOrgContractor = this.user.orgRoles.includes('engagement_org_contractor');
        this.engagementService.getRoutes(engagement.startsAt, engagement.endsAt, 'org', roleEngagementOrgContractor, engagement.wantsAdjustment)
          .then(results => {
            this.sharedSites = results[results.length - 1].map(sharedSite => new EngagementSharedSite(sharedSite));
            this.storeService.setDataLoading(false);
            this.unfilteredResults = results;
            this.selectedOrg = null;
            this.filterResults(true);
            this.loading = false;
          })
          .catch(e => {
            this.results = [];
            this.storeService.setDataLoading(false);
            this.loading = false;
          });
      });

    this.isAdmin.set(this.apiService.isAdmin());
  }

  tabIndexClick(tabIndex: 0 | 1) {
    this.tabIndex.set(tabIndex);
  }

  filterResults(isInitialising: boolean) {
    const results = this.unfilteredResults;

    if (!results || results[1].message) {
      // Not setup for engagement or an error
      this.results = [];
      return;
    }

    this.isFiltering = true;
    this.results = results[0];
    if (this.results === null) {
      // This is a participating org
      this.selectedOrg = this.apiService.getUserOrg().id;
    }
    const usersFound = {};
    this.userLog = results[1].map(userLogItem => new EngagementUserLogItem(userLogItem));
    this.userLog.forEach(logRow => {
      usersFound[logRow.userName] = true;
    });

    if (isInitialising) {
      Object.keys(usersFound).map(userName => {
        //  this.users.push({ label: userName, value: userName });
      });
      if (this.usersSelected.length === 0) {
        Object.keys(usersFound).map(userName => {
          this.usersSelected.push(userName);
        });
      }
    }

    this.userLog.sort((a, b) => {
      return a.createdAt > b.createdAt ? 1 : -1;
    });

    if (results.length && this.results) {
      this.calc();
    }

    this.isFiltering = false;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      console.log(params);
    });
  }

  userSelectionChanged(event) {
    this.usersSelected = event;
    if (this.usersSelected.length === 0) {
      // Can't save with no users selected
      return;
    }
    const key = this.apiService.getUserOrg().id + ':' + this.apiService.getUserId() + ':engagement:users';
    localStorage.setItem(key, JSON.stringify(this.usersSelected));
    this.filterResults(false);
  }

  calc() {
    this.calcResults(this.percOf);
  }

  selectOrg(orgId: number) {
    this.selectedOrg = +orgId;
  }

  calcResults(orange: number) {

    orange = 100 - orange;

    const perc = 100 / this.results[0].engagementCount;
    this.totalSites = 0;
    this.results.map(org => {
      this.totalSites += org.siteCount;
      if (org.engagementCount * perc >= 4) {
        org.engagementStatus = 'green';
      } else if (org.engagementCount * perc >= orange) {
        org.engagementStatus = 'orange';
      } else {
        org.engagementStatus = 'red';
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.engagementSubscription.unsubscribe();
    this.userSuscription.unsubscribe();
  }
}

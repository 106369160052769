<article>
	<header>
		<app-toolbar>
			<div class="right">
				<app-button label="Create" icon="mdi mdi-plus" (click)="createCollection()"></app-button>
				@if(plans()?.length) {
				<!--	<app-button label="Floorplans..." icon="mdi mdi-floor-plan" (click)="onDialog.set('floorplans')"></app-button> -->
				}
			</div>
		</app-toolbar>
	</header>
	<main>
		<table class="table table-hover" aria-label="Collections">
			<thead>
				<tr>
					<th>Title</th>
					<th>Type</th>
					<th><span pTooltip="Created by a user or system generated">Source</span></th>
					<th>Sensors</th>
				</tr>
			</thead>
			<tbody>
				@for(item of combinedCollections(); track item.id) {
				<tr (click)="clickCollection.set(item)">
					<td>{{item.title}}</td>
					<td><span pTooltip="{{item.type.id}}">{{item.type.title}}</span></td>
					<td>{{item.origin === 'shape' ? 'room' : (item.source ? item.source : 'user')}}</td>
					<td>{{item.assets.length}}</td>
				</tr>
				}
			</tbody>
		</table>
	</main>
</article>
@if(clickCollection()) {
<app-dialog (onClose)="clickCollection.set(null)" [canScroll]="true" [header]="clickCollection().title">
	<app-site-collection [collection]="clickCollection()" [site]="site()" (onUpdated)="collectionWasUpdated()" />
</app-dialog>
}

@switch (onDialog()) {
@case('floorplans') {
<app-dialog (onClose)="onDialog.set(null)" [dimensions]="{width:900, height: 600}" [canScroll]="true">

	@if(!plan()) {
	<table class="table table-hover" aria-label="Floorplans">
		<thead>
			<tr>
				<th>Floorplan</th>
				<th>Floor</th>
				<th>Occupancy?</th>
			</tr>
		</thead>
		<tbody>
			@for(plan of plans();track plan.id) {
			<tr (click)="selectPlan(plan)">
				<td>{{plan.title}}</td>
				<td>{{plan.floor}}</td>
				<td>{{plan.hasOccupancy ? 'Yes' : 'No'}}</td>
			</tr>
			}
		</tbody>
	</table>
	} @else {
	<app-page-title [canGoBack]="true" (click)="selectPlan(null)">{{plan()?.title}}</app-page-title>
	@if(assets()?.length) {
	<app-toolbar>
		<div class="right">
			<app-button label="Update Collections" icon="mdi mdi-check" />
		</div>
	</app-toolbar>
	<h3>Rooms</h3>
	<table class="table shapes" aria-label="Rooms">
		<thead>
			<tr>
				<th></th>
				<th>Room</th>
			</tr>
		</thead>
		<tbody>
			@for(shape of shapes();track shape.id) {
			<tr [class.selected]="shape.isSelected" class="can-click" (click)="shape.isSelected = !shape.isSelected">
				<td>
					<i class="mdi" [class.mdi-checkbox-marked-outline]="shape.isSelected" [class.mdi-checkbox-blank-outline]="!shape.isSelected"></i>
				</td>
				<td>{{shape.title}}</td>
			</tr>
			<tr>
				<td colspan="2">
					@for(asset of shape.assets;track asset.id) {
					<app-chip [label]="asset.title" [checked]="asset.isSelected" (click)="asset.isSelected = !asset.isSelected"></app-chip>
					}
				</td>
			</tr>
			}

		</tbody>
	</table>

	<h3>Assets</h3>
	<table class="table">
		<thead>
			<tr>
				<th>Asset</th>
			</tr>
		</thead>
		<tbody>
			@for(asset of assets();track asset.id) {
			<tr>
				<td><app-asset-icon [assetTypeId]="asset.assetType_id"></app-asset-icon> {{asset.title}}</td>
			</tr>
			}

		</tbody>
	</table>
	} @else {
	@if(assets()?.length === 0) {
	<app-info-panel>No assets on plan</app-info-panel>
	}
	}
	}

</app-dialog>
}
}

import { Component, OnInit, Input } from '@angular/core';
import { Camera } from 'app/classes/asset';

@Component({
  selector: 'app-camara-box',
  templateUrl: './camara-box.component.html',
  styleUrls: ['./camara-box.component.css']
})
export class CamaraBoxComponent implements OnInit {

  @Input()
  camera: Camera;

  @Input()
  selectable: boolean;

  constructor() { }

  ngOnInit() {
  }

}

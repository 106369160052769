import { Asset } from "../asset";

export class AssetFinding {
    id: number;
    title: string;
    findings: string;
    action: string
    createdAt: Date;
    status: number;
    rag: string;
    asset: Asset;
    // The telemetry id start / end
    telemetry: { 
        start: { id: number, x?:number},
        end: { id: number, x?: number}
    };

    constructor(data?: any) {

        if (data) {
            this.createdAt = data.createdAt;
            this.title = data.title;
            this.id = data.id;
            this.status = data.status;
            this.telemetry = { 
                start : { id :  data.telemetryStartId },
                end : { id :  data.telemetryEndId }
            };
            this.rag = data.rag || 'green';

        } else {
            this.createdAt = new Date();
            this.status = 0;
            this.action = null;
            this.title = null;
            this.telemetry = { 
                start : { id :  null },
                end : { id :  null }
            };
            this.rag = 'green';
        }
    }
}

export class BuildAssetFinding extends AssetFinding {
    
    telemetryChoices:  any;
    trackid: string;

    constructor(data?: any) {
        super(data);
        this.telemetryChoices = {start: [], end : []};
        this.trackid = Math.floor((1 + Math.random()) * 0x10000).toString(16);
    }
}
import { Component, NgZone, OnDestroy, OnInit, signal } from '@angular/core';
import { License, LicenseBuilding, LicenseGateway } from 'app/classes/license';
import { LicenseCollection } from 'app/classes/license-collection';
import { BillingService } from 'app/shared/billing.service';
import { ExportService } from 'app/shared/export.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DateService } from "../../shared/date.service";

@Component({
  selector: 'app-billing-licenses-list',
  templateUrl: './billing-licenses-list.component.html',
  styleUrls: ['./billing-licenses-list.component.css'],
  providers: [DialogService]
})
export class BillingLicensesListComponent implements OnInit, OnDestroy {
  licenseCollection = signal<LicenseCollection>(null);
  selectedLicense: LicenseBuilding | LicenseGateway;
  searchText: string;

  licenses: any;

  dialogRef: DynamicDialogRef;
  sortBag: { column: string, order?: string } = { column: 'title', order: 'asc' };
  monthFilter: string = '';
  licenseSelected = signal<LicenseBuilding | LicenseGateway>(null);

  filteredLicenses = signal<any[]>(null);

  licenseMustHaveValue = signal<boolean>(true);

  counts = signal<any>(null);

  constructor(
    private exportService: ExportService,
    private billingService: BillingService,
    private zone: NgZone, private confirmationService: ConfirmationService, private dateService: DateService, public dialogService: DialogService, public messageService: MessageService) { }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {
    this.get();
    this.searchText = localStorage.getItem('billing:licenses:search') || '';
    this.sortBag = JSON.parse(localStorage.getItem('billing:licenses:sort') || '{"column":"title"}');
  }

  licenseMustHaveValueClick() {
    this.licenseMustHaveValue.update(value => !value);
    this.filterLicenses();
  }

  filterLicenses() {
    this.filteredLicenses.set(this.licenses.filter(l => !this.licenseMustHaveValue() || l.value > 0));
    this.counts.set(null);
    setTimeout(() => {
      this.counts.set({
        all: { l: this.filteredLicenses().length },
        expired: {
          l: (<License[]>this.filteredLicenses()).filter(l => l.hasExpired).length,
          g: null,
          b: null
        }
      });
    }, 10);

  }

  search() {
    if (!this.searchText || this.searchText.length < 1) {

      localStorage.removeItem('billing:licenses:search');
      this.processSearch();

      return;
    }

    localStorage.setItem('billing:licenses:search', this.searchText);
    this.processSearch();
  }

  get() {
    this.billingService.getLicenseCollection('isbillable=0').then(a => {
      this.licenseCollection.set(a);
      this.licenses = a.licenses;
      this.search();
      this.filterLicenses();
      this.sortList();
    });
  }

  sortList() {
    let o1 = this.sortBag.order === 'asc' ? 1 : -1;
    let o2 = this.sortBag.order === 'asc' ? -1 : 1;

    let fn;
    switch (this.sortBag.column) {
      case 'expiresAt':
        fn = (a, b) => { return +a.expiresAt > +b.expiresAt ? o1 : o2 };
        break;
      case 'value':
        fn = (a, b) => { return +a.value > +b.value ? o1 : o2 };
        break;
      case 'org':
        fn = (a, b) => {
          const ta = a.building ? a.building.org.shortTitle : a.gateway.org.shortTitle;
          const tb = b.building ? b.building.org.shortTitle : b.gateway.org.shortTitle;

          return ta > tb ? o1 : o2;
        };
        break;
      case 'billingClientTitle':
        fn = (a, b) => {
          const t1 = a.billingClient?.title ? a.billingClient.title : '';
          const t2 = b.billingClient?.title ? b.billingClient.title : '';
          return t1 > t2 ? o1 : o2;
        };
        break;
      default:
        fn = (a, b) => {
          const ta = a.building ? a.building.title : a.gateway.title;
          const tb = b.building ? b.building.title : b.gateway.title;

          return ta > tb ? o1 : o2;
        };
    }

    this.filteredLicenses.update(licenses => {
      return licenses.sort(fn);
    });
  }

  sort(column: string) {
    let order = 'asc';
    if (this.sortBag.column === column) {
      order = this.sortBag.order === 'asc' ? 'desc' : 'asc';
    }
    const payload = { column, order };
    localStorage.setItem('billing:licenses:sort', JSON.stringify(payload));
    this.sortBag = payload;
    this.sortList();
  }

  licenseClick(license: LicenseBuilding | LicenseGateway) {
    this.licenseSelected.set(license);
  }

  licenseUpdated() {
    this.licenseSelected.set(null);
  }

  /*
  licenseClick(license: LicenseBuilding | LicenseGateway) {
    //this.selectedLicense = license;

    this.dialogRef = this.dialogService.open(DialogLicenseComponent, {
      header: `License for ${license.title}`,
      width: '80%',
      data: { license }
    });
    this.dialogRef.onClose.subscribe((license: License) => {
      if (license) {
        this.messageService.add({ severity: 'info', summary: 'License updated', detail: license.title });
        this.get();
      }
    });
  }
  */

  advanceExpiry(typeLicense: LicenseBuilding | LicenseGateway) {
    const oldExpiry = new Date(typeLicense.expiresAt);
    const newExpiry = typeLicense.advanceExpiryToFutureDate();
    const months = DateService.monthsDiff(oldExpiry, newExpiry);

    this.confirmationService.confirm({
      header: `Advance license expiry for #${typeLicense.id}, ${typeLicense.title} `,
      message: `Extend license by ${months} months, from ${DateService.date(oldExpiry)} to ${DateService.date(newExpiry)}?`,
      accept: () => {
        this.billingService.putFieldForLicense('expiresat', newExpiry, typeLicense.id)
          .then(r => {
            this.get();
          });
      }
    });
  }

  toolbarClick(item) {
    this.messageService.add({ summary: 'Exporting...', severity: 'info' });
    const filename = 'license_export';
    this.exportService.exportLicenses(this.licenses, filename);
  }

  toolbarSelectChange(item: string) {
    this.monthFilter = item;
    this.processSearch();
  }

  processSearch() {
    if (this.searchText && this.searchText.length > 0) {
      this.licenses = [];
      for (let index = 0; index < this.licenseCollection().licenses.length; index++) {
        const element: LicenseBuilding | LicenseGateway = this.licenseCollection().licenses[index];
        let found = JSON.stringify(element).toLowerCase().search(this.searchText.toLowerCase());
        /*
          switch (element.licenseFor) {
            case 'building':
              const building = (<LicenseBuilding>element).building;
              break;
            case 'gateway':
              break;
          }
        */
        if (found >= 0) {
          this.licenses.push(element);
        }
      }
    } else {
      this.licenses = this.licenseCollection().licenses;
    }

    this.filterLicenses();
    this.sortList();

    if (this.monthFilter) {
      this.licenses = this.licenses.filter(l => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];

        const d = (<License>l).expiresAt;
        return monthNames[d.getMonth()] === this.monthFilter;
      });
    }
  }
}

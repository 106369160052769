import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CmsItemBlock, BuildCmsItemBlock } from '../../../classes/cms-item-block';

@Component({
  selector: 'app-cms-item-block',
  templateUrl: './cms-item-block.component.html',
  styleUrls: ['./cms-item-block.component.css']
})
export class CmsItemBlockComponent implements OnInit {

  @Input()
  block: CmsItemBlock;

  @Input()
  isEditing: boolean;

  @Output() 
  onBlockClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  @Output() 
  OnBlockTrashed: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() 
  OnBlockMoved: EventEmitter<string> = new EventEmitter<string>();


  constructor() { }

  ngOnInit() {
  }

  wasClicked(event) {
    console.log(`CmsItemBlockComponent.onBlockClick.emit()`);
    this.onBlockClicked.emit(true);
  }

  wasTrashed(event) {
    console.log(`CmsItemBlockComponent.OnBlockTrashed.emit()`);
    this.OnBlockTrashed.emit(true);
    // Don't process block click
    event.stopPropagation();
  }

  wasMoved( direction: string) {
    console.log(`CmsItemBlockComponent.onBlockMove.emit()`);
    this.OnBlockMoved.emit(direction);
    // Don't process block click
    event.stopPropagation();
  }
}


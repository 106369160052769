<div class="feature theme-{{ theme }}" *ngIf="item">
  <div class="title">
    <span>{{ item.title }}</span>
  </div>
  @for (block of item.blocks; track block.id) {
    <div class="blocks">
      <app-cms-item-block
        [isEditing]="isEditing"
        [block]="block"
        (onBlockClicked)="blockClicked(block)"
        (OnBlockTrashed)="blockTrashed(block)"
        (OnBlockMoved)="blockMoved($event, block)"></app-cms-item-block>
    </div>
  }
  @if (showDate) {
    <div class="date">
      {{ item.createdAt | amDateFormat: 'MMMM YYYY' }}
    </div>
  }
</div>
@if (item) {
  <div>
    <i class="fa fa-refresh fa-spin"></i>
  </div>
}

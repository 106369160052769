import { Component, signal } from '@angular/core';
import { OccCollection } from 'app/classes/occ-collection';
import { DialogOccCollectionUpdateComponent } from 'app/dialogs/dialog-occ-collection-update/dialog-occ-collection-update.component';
import { APIService } from 'app/shared/api.service';
import { OccupancyService } from 'app/shared/occupancy.service';
import { WindowService } from 'app/shared/window.service';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-occupancy-admin-collections',
  templateUrl: './occupancy-admin-collections.component.html',
  styleUrls: ['./occupancy-admin-collections.component.css'],
  providers: [DialogService]

})
export class OccupancyAdminCollectionsComponent {

  collections: OccCollection[];
  dows = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  isAdmin = false;
  isWorking = signal<boolean>(false);
  collection = signal<OccCollection>(null);

  constructor(private occupancyService: OccupancyService, public messageService: MessageService, public dialogService: DialogService, private apiService: APIService, private windowService: WindowService) {
    this.get();
    this.isAdmin = this.apiService.isAdmin();
  }

  get() {
    this.isWorking.set(true);
    this.occupancyService.getCollections().then(collections => {
      this.collections = collections;
      this.isWorking.set(false);
    });
  }

  autorebuild() {
    const startDate = new Date();
    this.apiService.toastSuccess('Building recent missing dates...', '');
    this.occupancyService.autoRebuild().then(response => {
      console.log(response);
      const endDate = new Date();
      const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
      if (seconds >= 29) {
        this.apiService.toastSuccess('Rebuilding may take some time.', '');
      } else {
        this.apiService.toastSuccess('Rebuilding done', '');
      }
    });
  }

  collectionClick(collection: OccCollection) {
    // this.createOrUpdateCollectionOfDays(collection);
    this.collection.set(collection);

  }

  rebuild(collectionId: number, jobId: number) {
    const startDate = new Date();
    this.apiService.toastSuccess('Collection rebuilding...', '');
    this.occupancyService.rebuildCollection(collectionId, jobId).then(response => {
      console.log(response);
      const endDate = new Date();
      const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
      if (seconds >= 29) {
        this.apiService.toastSuccess('Rebuilding may take some time', '');
      } else {
        this.apiService.toastSuccess('Rebuilding done', '');
      }
    });
  }

  createOrUpdateCollectionOfDays(collection?: OccCollection) {


    this.collection.set(new OccCollection());
    return;

    const width = this.windowService.isMobile ? '98%' : '80%';

    const dialogRef = this.dialogService.open(DialogOccCollectionUpdateComponent, {
      header: collection?.id ? 'Update Collection' : 'New Collection',
      width,
      data: { collection: collection || new OccCollection() }
    });

    dialogRef.onClose.subscribe((obj: { collection: OccCollection, rebuild: boolean }) => {
      if (!obj?.collection) {
        return;
      }
      const { collection, rebuild } = obj;
      if (collection) {
        this.messageService.add({ severity: 'info', summary: ' updating' });
        this.occupancyService.updateCollection(collection, rebuild).then(() => {
          this.messageService.add({ severity: 'info', summary: ' updated' });
          this.get();
        });
      }
    });
  }
}

import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { Alarm } from 'app/classes/alarm';
import { Asset } from 'app/classes/asset';
import { ChartPin } from 'app/classes/chart-pin';
import { timingSafeEqual } from 'crypto';

@Component({
  selector: 'app-rulesv3-item-alarm',
  templateUrl: './rulesv3-item-alarm.component.html',
  styleUrls: ['./rulesv3-item-alarm.component.css']
})
export class Rulesv3ItemAlarmComponent implements OnInit {

  _assets: Asset[];
  @Input()

  public set assets(assets: Asset[]) {
    this._assets = assets;
  }

  public get assets(): Asset[] {
    return this._assets;
  }

  @Input()
  alarm: Alarm;

  pins: ChartPin[]

  @Output()
  onGoBack: EventEmitter<any> = new EventEmitter();

  custom: { from: Date, to: Date } = { from: null, to: null };


  constructor() { }

  ngOnInit(): void {

    const from = new Date(this.alarm.createdAt);
    const to = this.alarm.findings?.at ? new Date(this.alarm.findings.at) : from;

    this.custom = { from, to };


    this.pins = [
      new ChartPin({ date: from, text: 'Alarmed', fill: 'red', size: 10, stroke: 'none' })
    ];

    if (this.alarm.findings) {
      // In alarm
      this.pins.push(new ChartPin({ date: to, text: 'Resolved', fill: 'green', size: 10, stroke: 'none' }));
    }
  }

}

<div class="policies-container">
    <div class="login" *ngIf="!user?.email" routerLink="/login"><i class="mdi mdi-chevron-left"></i> 4D Portal Login</div>
    <div class="no-print links">
        <div routerLink="/policies/terms" routerLinkActive="active">Terms and Conditions</div>
        <div routerLink="/policies/data" routerLinkActive="active">Data Protection Policy</div>
        <div routerLink="/policies/acceptance" routerLinkActive="active">Acceptable Use Policy</div>
        <div routerLink="/policies/privacy" routerLinkActive="active">Privacy Policy</div>
        <div routerLink="/policies/cookies" routerLinkActive="active">Cookies</div>
    </div>
    <div class="content-container">
        <router-outlet></router-outlet>
    </div>
</div>

<article>
	@if(!isLoading) {
	@if(!offline.length) {
	<app-info-panel icon="check">
		<i class="mdi mdi-check"></i> All assets online
	</app-info-panel>
	} @else {
	@if(asset()) {
	<app-asset-offline-landing [asset]="asset()" (onClose)="assetClick(null)"></app-asset-offline-landing>
	} @else {


	<div class="tabview-list">
		@if(can().issues) {
		<div [class.active]="tabIndex()===0" (click)="tabIndex.set(0)">
			<i class="mdi mdi-forum"></i> Communication
		</div>
		}
		<div [class.active]="tabIndex()===1" (click)="tabIndex.set(1)">
			<i class="mdi mdi-view-list"></i> Assets
		</div>
		<div></div>
	</div>

	@switch(tabIndex()) {

	@case(0) {
	<app-offline-communication-landing></app-offline-communication-landing>
	}
	@case(1) {
	<table class="table table-hover" aria-label="Offline Assets">
		<thead>
			<tr>
				<th class="can-sort" (click)="headerClicked('asset')">
					<span>
						Asset
						<i class="fa" *ngIf="sort.current?.field === 'asset'" [ngClass]="{'fa-angle-down':sort.current.state==='desc', 'fa-angle-up':sort.current.state==='asc'}"></i>
					</span>
				</th>

				<th class="can-sort" (click)="headerClicked('type')">
					<span>
						Type
						<i class="fa" *ngIf="sort.current?.field === 'type'" [ngClass]="{'fa-angle-down':sort.current.state==='desc', 'fa-angle-up':sort.current.state==='asc'}"></i>
					</span>
				</th>



				<th class="can-sort" (click)="headerClicked('updatedAt')">
					<span>
						Last Updated
						<i class="fa" *ngIf="sort.current?.field === 'updatedAt'" [ngClass]="{'fa-angle-down':sort.current.state==='desc', 'fa-angle-up':sort.current.state==='asc'}"></i>
					</span>
				</th>

				<th class="can-sort" (click)="headerClicked('gateway')">
					<span>
						Gateway
						<i class="fa" *ngIf="sort.current?.field === 'gateway'" [ngClass]="{'fa-angle-down':sort.current.state==='desc', 'fa-angle-up':sort.current.state==='asc'}"></i>
					</span>
				</th>
				<th class="can-sort text-right" (click)="headerClicked('value')">
					<span>
						Last value
						<i class="fa" *ngIf="sort.current?.field === 'value'" [ngClass]="{'fa-angle-down':sort.current.state==='desc', 'fa-angle-up':sort.current.state==='asc'}"></i>
					</span>
				</th>
			</tr>
		</thead>
		<tbody>
			@for(asset of offline;track asset.id) {
			<tr (click)="assetClick(asset)">
				<td pTooltip="#{{asset.id}}" tooltipPosition="left">{{asset.title}}</td>
				<td>{{asset.assetTypeTitle}}</td>

				<td pTooltip="{{asset.updatedAt | amDateFormat:'DD-MM-YYYY HH:mm'}}" tooltipPosition="left">{{asset.updatedAt
					|
					amTimeAgo}}</td>
				<td pTooltip="#{{asset.gateway.id}}" tooltipPosition="left">{{asset.gateway.title}}</td>
				<td class="text-right">{{asset.value}}</td>
			</tr>
			}
		</tbody>
	</table>
	}

	}


	}


	}

	} @else {
	<span><i class="mdi mdi-loading mdi-spin-x2"></i></span>
	}
</article>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';

@Component({
  selector: 'app-floorplan-asset',
  templateUrl: './floorplan-asset.component.html',
  styleUrls: ['./floorplan-asset.component.css']
})
export class FloorplanAssetComponent implements OnInit {

  @Input()
  public set assetId(id: number) {
    this.asset = new Asset({ id });
    console.log('got asset', id);
    this.getAsset();
  }

  @Input()
  asset: Asset;

  @Output()
  onLoad = new EventEmitter<any>();

  plan: any;
  viewbox: any = { x: 0, y: 0, w: 0, h: 0 };
  radius = 40;
  isShowingPopupSensorData: boolean;

  constructor(private assetService: AssetService, private apiService: APIService) { }

  ngOnInit() {
    // this.getAsset();
  }

  getAsset() {
    this.plan = null;
    if (!this.asset || !this.asset.id) {
      return;
    }
    this.apiService.getFloorplanForAsset(this.asset.id)
      .then(plan => {
        this.onLoad.emit(plan);

        if (!plan) {
          return;
        }
        this.plan = plan;
        this.viewbox.w = plan.viewboxWidth;
        this.viewbox.h = plan.viewboxHeight;
      });
  }

  popupDetails() {
    if (!this.asset.title) {
      // Fully load the asset
      this.assetService.getAsset(this.asset.id).then(asset => {
        this.asset = asset;
        this.isShowingPopupSensorData = true;
      })
    } else {
      this.isShowingPopupSensorData = true;
    }
  }
}

<div class="col-md-12">
    <h5>Camera Control</h5>
    <!-- hasImage exists when the user receives a notification session_image -->

    <div *ngIf="camera" class="row">

        <div class="col-sm-12 info">
            <div *ngIf="sessionId">Session id is {{sessionId}}
                <span *ngIf="isGettingImage">Getting latest image....</span>
            </div>
        </div>

        <div *ngIf="camera.last_image_url" class="col-lg-8 col-md-12">
            <img class="img-responsive img-rounded" [src]="camera.last_image_url" />
        </div>
        <div class="buttons col-lg-4 col-md-12">
            <table class="table">
                <thead>
                    <tr>
                        <th>Actions</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let preset of camera.presets">
                        <td>{{preset.title}}</td>
                        <td>
                            <button class="btn btn-sm btn-secondary pull-right" (click)="panTo(preset)">
                                <span *ngIf="camera.assetType_id === 44"><i class="fa fa-arrows fa-fw"></i> Pan & Take
                                    Picture
                                </span>
                                <span *ngIf="camera.assetType_id === 43"><i class="fa fa-camera fa-fw"></i> Take Picture
                                </span>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="camera.presets.length === 0">

                        <td>
                            <button class="btn btn-sm btn-secondary pull-right" (click)="takePicture()">
                                <span><i class="fa fa-camera fa-fw"></i> Take Picture
                                </span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="row" [hidden]="true">
                <div class="col-md-2">
                    <button class="btn btn-block btn-primary" (click)="takePicture()">
                        <i class="fa fa-camera"></i>
                    </button>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-block btn-secondary" (click)="panToString('002')">
                        <i class="fa fa-arrows"></i> Morissons</button>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-block btn-secondary" (click)="panToString('000')">
                        <i class="fa fa-arrows"></i> Visitors</button>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-block btn-secondary" (click)="panToString('004')">
                        <i class="fa fa-arrows"></i> Colin</button>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-block btn-secondary" (click)="panToString('001')">
                        <i class="fa fa-arrows"></i> Barrier</button>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-block btn-secondary" (click)="panToString('006')">
                        <i class="fa fa-arrows"></i> Entrance</button>
                </div>
            </div>
        </div>
        <!-- <div class="col-sm-12 ">
            <div class="workplan">
                <app-workplan-list [camera]="camera"></app-workplan-list>
            </div>
        </div>
    -->
    </div>
</div>

<article class="theme4d23" *ngIf="schedule">
	<header>
		<app-scheduler-help *ngIf="!schedule.id" [step]="step"></app-scheduler-help>

		<h3 *ngIf="schedule.id" pTooltip="The schedule title">{{schedule.title}}</h3>

		<div class="tabview-list">
			<div [class.active]="step==='sites'" (click)="tabClick('sites')"><i class="mdi mdi-office-building-outline"></i>
				Sites
			</div>
			<div [class.active]="step==='assets'" (click)="tabClick('assets')"><i class="mdi mdi-radiobox-blank"></i>
				Assets
			</div>
			<div [class.active]="step==='date'" (click)="tabClick('date')"><i class="mdi mdi-calendar-clock"></i>
				Calendar
			</div>

			<!-- <div [class.active]="section==='overview'" routerLink="/setpoints/overview"><i class="fa fa-barcode"></i> Overview 
		    </div> -->
			<div>&nbsp;</div>
		</div>
	</header>


	<!--           S I T E S           -->

	<section class="sites" *ngIf="step === 'sites'">
		<main>
			<table>
				<thead>
					<tr>
						<th (click)="selectAllClick('sites')"> <app-tick [selected]="selectAllSites"></app-tick> Site</th>
						<th>Address</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let site of sites">
						<td (click)="site.checked = !site.checked">
							<span>
								<app-tick [selected]="site.checked"></app-tick>
								{{site.title}}
							</span>
						</td>
						<td>{{site.address.address1}}</td>
					</tr>
				</tbody>
			</table>
		</main>



		<footer>
			<div>
				<button pButton type="button" routerLink=".." icon="pi pi-times" class="p-button-outlined p-button-secondary p-button-sm" label="Cancel"></button>
			</div>

			<button pButton type="button" (click)="next()" class="p-button-outlined p-button-secondary p-button-sm" icon="mdi mdi-page-next-outline" label="Select assets"></button>
		</footer>

		<section class="mt-1">
			<p-panel header="Tip">
				<p>If you don't select any sites and you don't select any assets, this schedule
					will apply to every site and every matching asset type at the time the schedule runs</p>
			</p-panel>
		</section>
	</section>


	<!--           A S S E T S           -->


	<section class="assets" *ngIf="step === 'assets'">
		<main *ngIf="assets">
			<div>
				<h4>Asset Title <small>optional</small></h4>
				<div>
					<p-dropdown [options]="operations" [(ngModel)]="schedule.filter.asset.op" optionLabel="name" optionValue="code" (onChange)="locationUpdated($event)"></p-dropdown>

					<input pInputText [(ngModel)]="schedule.filter.asset.value" placeholder="Filter by asset title containing text" (keyup)="locationUpdated()">
				</div>

			</div>
			<div>
				<h4>Location <small>optional</small></h4>
				<div>
					<p-dropdown [options]="operations" [(ngModel)]="schedule.filter.location.op" optionLabel="name" optionValue="code" (onChange)="locationUpdated($event)"></p-dropdown>

					<input pInputText [(ngModel)]="schedule.filter.location.value" placeholder="Filter by location containing" (keyup)="locationUpdated()">
				</div>

			</div>
			<table>
				<thead>
					<tr>
						<th (click)="selectAllClick('assets')"> <app-tick [selected]="selectAllAssets"></app-tick> Asset</th>
						<th>Sensor</th>
						<th>Location</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let asset of assets" (click)="asset.checked = !asset.checked">
						<td>
							<span>
								<app-tick [selected]="asset.checked"></app-tick>
								{{asset.title}}
							</span>
						</td>
						<td>{{asset.assetTypeTitle}}</td>
						<td>{{asset.location}}</td>
					</tr>
				</tbody>
			</table>
		</main>
		<app-please-wait *ngIf="!assets"></app-please-wait>

		<footer>
			<div>
				<button pButton type="button" routerLink=".." icon="pi pi-times" class="p-button-outlined p-button-secondary p-button-sm" label="Cancel"></button>
			</div>
			<button pButton type="button" (click)="previous()" class="p-button-outlined p-button-secondary p-button-sm" icon="mdi mdi-page-previous-outline" label="Back to Sites"></button>
			<button pButton type="button" (click)="next()" class="p-button-outlined p-button-secondary p-button-sm" icon="mdi mdi-page-next-outline" label="Next"></button>
		</footer>

		<section class="mt-1 site-tip">
			<p-panel header=" Tip" *ngIf="this.selectAllSites">
				<p><u>You didn't select any sites</u>, if you don't select any assets, this schedule
					will apply to every site and every <span *ngFor="let assetType of selectedAssetTypes">{{assetType.title}}</span> sensor, at the time the schedule
					runs.</p>
			</p-panel>
		</section>
	</section>

	<!-- OVERVIEW -->


	<section class="date" *ngIf="step === 'date'">
		<main>
			<div>
				<p-calendar [(ngModel)]="schedule.processAt" [inline]="true"></p-calendar>
			</div>
			<div>
				<h4>Title</h4>
				<input class="form-control" [(ngModel)]="schedule.title" placeholder="Title for lists,etc.">
				<h4>Repeat</h4>
				<p-dropdown [options]="repeatOptions" [(ngModel)]="schedule.repeats.frequency" optionLabel="name" optionValue="code"></p-dropdown>
				<h4>Reason</h4>
				<textarea class="form-control" [(ngModel)]="schedule.reason" rows="10" placeholder="optional"></textarea>
			</div>
		</main>
		<footer>
			<div>

			</div>
			<button pButton type="button" (click)="delete()" icon="pi pi-trash" class="p-button-outlined p-button-danger" label="Delete" *ngIf="schedule.id"></button>

			<button pButton type="button" routerLink="/setpoints/templates" icon="pi pi-times" class="p-button-outlined p-button-sm" label="Cancel"></button>

			<!--<button pButton type="button" (click)="previous()" class="p-button-outlined p-button-secondary p-button-sm"
				icon="mdi mdi-page-previous-outline" label="Back to Assets"></button>-->
			<button pButton type="button" (click)="next()" class="p-button-primary p-button-sm" icon="mdi mdi-page-next-outline" label="Submit"></button>
		</footer>

		<section class="mt-1 site-tip">
			<p-panel header=" Tip" *ngIf="this.selectAllSites && this.selectAllAssets">
				<p><u>You didn't select any sites OR assets</u>, this schedule
					will apply to every site and every
					<span *ngFor="let assetType of selectedAssetTypes">{{assetType.title}}</span>
					sensor, at the time the schedule runs.
				</p>
			</p-panel>
		</section>
	</section>

</article>

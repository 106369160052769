<section>
    <ng-template ngFor [ngForOf]="uploads" let-item>
        <div [class.view-meta]="item.viewMeta > 0">
            <p-card
                [style]="item.viewMeta < 0 ? {'width': '25rem', 'margin-bottom': '2em'} : {'width': '100%', 'margin-bottom': '2em'}">
                <ng-template pTemplate="header">
                    <img [style]="item.viewMeta < 0 ? {'height': '200px','object-fit':'cover'} : {'height': '600px','object-fit':'fill'}"
                        alt="Card" src="https://4d-documents.s3.eu-west-2.amazonaws.com/{{item.key}}"
                        (click)="item.viewMeta=-1">
                </ng-template>
                <h4>{{item.createdAt | amDateFormat:'ddd Do MMMM HH:mm'}}</h4>


                <p-tabView *ngIf="item.viewMeta > 0">
                    <p-tabPanel header="Processed">
                        <table class="table">
                            <tbody>
                                <tr *ngFor="let row of item.metaTable">
                                    <td>{{row.confidence}}</td>
                                    <td>{{row.text}}</td>
                                    <td>{{row.type}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </p-tabPanel>

                    <p-tabPanel header="raw">
                        <pre>
                            {{item.metaDisplay}}
                        </pre>
                    </p-tabPanel>
                </p-tabView>

                <div class="actions">
                    <div>
                        <p><small>{{item.uName}}</small></p>
                        <p-button *ngIf="!item.meta" label="OCR" icon="mdi mdi-ocr"
                            styleClass="p-button-primary p-button-sm" [style]="{'margin-left': '.5em'}"
                            (click)="ocrClick(item)">
                        </p-button>
                        <p-button *ngIf="item.meta" label="View Meta" icon="mdi mdi-ocr"
                            styleClass="p-button-secondary p-button-sm" [style]="{'margin-left': '.5em'}"
                            (click)="toggleMeta(item)">
                        </p-button>
                    </div>
                    <div class="helper">
                       <div *ngFor="let help of item.helper">
                           <p>{{help[0]}}</p>
                           <p>{{help[1]}}</p>
                       </div>
                    </div>
                </div>
            </p-card>

        </div>
    </ng-template>
</section>
<!--
<table class="table">
    <thead>
        <tr>
            <th>Time</th>
            <th>Image</th>
            <th>Meta</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of uploads">
            <td>{{item.createdAt | amDateFormat:'ddd Do MMMM HH:mm'}}</td>
            <td><img src="https://4d-documents.s3.eu-west-2.amazonaws.com/{{item.key}}"></td>
            <td></td>
        </tr>
    </tbody>
</table> -->
import { Component, OnInit, OnDestroy } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { User } from '../../classes/user';
import { Subscription } from 'rxjs';
import { StoreService } from 'app/shared/store.service';

@Component({
  selector: 'app-policies-container',
  templateUrl: './policies-container.component.html',
  styleUrls: ['./policies-container.component.css']
})
export class PoliciesContainerComponent implements OnInit, OnDestroy {
  user: User;
  userSubscription: Subscription;

  constructor(private apiService: APIService, private storeService: StoreService) {
    this.userSubscription = apiService.user.subscribe(user => this.user = user);
    // Policy documents are in the white theme
    this.storeService.setTheme('light');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}

<div class="tag">
  <!-- telemetry today -->
  <div class="col-xs-12" *ngIf="tag==='gateways-telemetry-today'">
    <div class="telemetry" [hidden]="gettingData" *ngIf="!selectedAsset">
      <!-- Loop arrays of gateways -->
      <div *ngFor="let gatewayAssets of results;let gi = index" class="gateway-container">
        <!-- Display the gateway -->
        <div [ngClass]="{'gateway-is-offline': gatewayAssets[0].gatewayOnline === 'offline'}"
          title="{{ gatewayAssets[0].gatewayOnline }}" class="col-md-12 gateway-title" *ngIf="!selectedAsset"><i
            class="fa fa-circle"
            [ngClass]="{'status-online': gatewayAssets[0].gatewayOnline === 'online','status-standby': gatewayAssets[0].gatewayOnline === 'standby', 'status-offline': gatewayAssets[0].gatewayOnline === 'offline'}"></i>
          {{gatewayAssets[0].gatewayTitle}}</div>

        <div *ngFor="let assetValue of gatewayAssets;let i = index" class="col-md-6 col-lg-6">
          <app-dashboard-telemetry-asset [asset]="assetValue" (onAssetClick)="showTelemetry(assetValue)"></app-dashboard-telemetry-asset>
        </div>
      </div>
      <div *ngIf="results?.length === 0">
        No telemetry available.
      </div>
    </div>

    <div class="asset-view" *ngIf="selectedAsset">
      <div class="go-back">
        <span (click)="selectedAsset = null"><i class="fa fa-chevron-left"></i> Back to gateways</span>
      </div>
      <app-asset-viewer [assetId]="selectedAsset.assetId"></app-asset-viewer>
      <!--<app-asset-view [assetId]="selectedAsset.assetId"></app-asset-view>-->
    </div>

    <p-overlayPanel #op>
      Content
    </p-overlayPanel>
    <div class="telemetry-data" [hidden]="!selectedAsset">
    </div>
  </div>

</div>

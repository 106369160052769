import { Component, signal } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { Site } from 'app/classes/site';
import { SitePlan } from 'app/classes/site-plan';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';

import { FloorplanService } from 'app/shared/floorplan.service';
import { StoreService } from 'app/shared/store.service';

@Component({
  selector: 'app-site-plan-dashboard-container',
  templateUrl: './site-plan-dashboard-container.component.html',
  styleUrls: ['./site-plan-dashboard-container.component.css']
})
export class SitePlanDashboardContainerComponent {

  isLoading: boolean;
  hasLoaded = signal<boolean>(false);
  planId: number;
  site: Site;
  siteFloorplans: SitePlan[];
  siteFloorplan: SitePlan;

  constructor(private floorplanService: FloorplanService, private apiService: APIService, private router: Router, private route: ActivatedRoute, private storeService: StoreService, private assetService: AssetService) {

    this.isLoading = true;

    route.params.subscribe((params: Params) => {
      this.planId = params.floorplanid;
      apiService.getSite(params.siteid).then(site => {
        this.site = site;
        this.hasLoaded.set(true);
      });
    });
  }

  getSiteFloorplan(siteId: number, siteFloorplanId: number) {
    this.hasLoaded.set(false);

    this.floorplanService.getSiteFloorplan(siteId, siteFloorplanId)
      .then(results => {
        this.siteFloorplan = results.siteplan;
        this.hasLoaded.set(true);
      });
  }


  goBack() {

  }
}



<ng-template [ngIf]="theme === 'compact'">
  <div class="compact">
    {{title}}
  </div>
</ng-template>
<ng-template [ngIf]="theme === 'extended'">
  <div class="info-box">
    <div class="info-container">
      <div class="image">
        <span class="fa-stack fa-lg">
          <i class="fa fa-fw fa-database fa-stack-1x"></i>
          <i class="fa fa-fw fa-search fa-stack-1x"></i>
        </span>
      </div>
      <div class="punchout">
        <div>{{title}}</div>
      </div>
      <div class="text">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="bottom">
      <div (click)="clickTray()"><span>{{link}}</span></div>
    </div>
  </div>
</ng-template>
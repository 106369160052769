<article *ngIf="insightContainer?.percentages">
	<h4>Weekly changes</h4>
	<section>
		<table class="table">
			<thead>
				<tr>
					<th *ngFor="let p of insightContainer.percentages[forCollection]" [title]="p.annotation">
						{{p.d | amDateFormat:'DD'}}
					</th>
				</tr>
				<tr>
					<th *ngFor="let p of insightContainer.percentages[forCollection]" [title]="p.annotation">
						{{p.d | amDateFormat:'dd'}}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td *ngFor="let p of insightContainer.percentages[forCollection]" [title]="p.annotation">{{p.v}}%</td>
				</tr>
				<tr>
					<td *ngFor="let p of insightContainer.percentages[forCollection]" [pTooltip]="p.annotation" class="arrows">
						<span class="mdi" [class.mdi-arrow-down-bold-outline]="p.v<0" [class.mdi-arrow-up-bold-outline]="p.v>0"
							[class.green]="p.v>0" [class.red]="p.v<0"></span>
					</td>
				</tr>
			</tbody>
		</table>
	</section>
	<section>
		<app-d3-bar [legendHasMultipleRows]="true" [telemetry]="insightContainer.percentagesFullMonth[forCollection]"
			[min]="-100" [max]="100" [dateFrom]="insightContainer.current.configuration.start"
			[dateTo]="insightContainer.current.configuration.end" [ranges]="insightContainer.current.configuration.ranges"
			[colours]="[ {from:-101,to:0,colour:'red'},{from:0,to:101,colour:'green'}]">
		</app-d3-bar>
	</section>
</article>

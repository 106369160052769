import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { Asset } from 'app/classes/asset';
import { Gateway } from 'app/classes/gateway';
import { Org } from 'app/classes/org';
import { Site } from 'app/classes/site';
import { APIService } from 'app/shared/api.service';
import { DashboardService } from 'app/shared/dashboard.service';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

declare const google: any;

@Component({
  selector: 'app-dashboard-map',
  templateUrl: './dashboard-map.component.html',
  styleUrls: ['./dashboard-map.component.css']
})
export class DashboardMapComponent implements OnInit, AfterViewInit, OnDestroy {

  stack: IStackPacket[] = [];

  MAP_TIMER = { interval: 200, counter: 1000 };

  @Input()
  showMapControls = false;

  @Input()
  showId = true;

  @Input()
  canNavigate = false;

  @Output()
  onSelected: EventEmitter<Gateway> = new EventEmitter<Gateway>();

  @Output()
  isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  index = 0;

  gateway: Gateway;
  asset: Asset;

  options: any;
  mapOptions: google.maps.MapOptions;

  defaults: any = { zoom: 6, hideSiteDetails: false };

  report: any;

  view: 'gateways' | 'assets' | 'asset' = 'gateways';

  subscription: Subscription;

  sites: Site[];
  gateways: Gateway[];
  stackTimer: any;
  addressLong: number;
  addressLat: number;

  position: { lat: number, lng: number };
  positions: { lat: number, lng: number }[] = [];

  fullscreen: boolean;

  org: Org;

  // Co-ords seen
  seen: { point: google.maps.LatLngLiteral, gateways: string[] }[] = [];

  offline: {point: google.maps.LatLngLiteral}[];

  constructor(private apiService: APIService, private dashboardService: DashboardService) {
    // this.loadDefaults();
  }

  toggleFullscreen() {
    const sub = this.apiService.fullscreen
      .pipe(take(1))
      .subscribe(isFullscreen => {
        this.apiService.setFullscreen(!isFullscreen);
        this.fullscreen = !isFullscreen;
      });
    console.log(sub);
  }

  ngOnInit(): void {
    let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

    this.org = this.apiService.getUserOrg();
    this.mapOptions = {
      disableDefaultUI: !this.showMapControls,
      styles: [{ "featureType": "all", "elementType": "geometry", "stylers": [{ "color": "#202c3e" }] }, { "featureType": "all", "elementType": "labels.text.fill", "stylers": [{ "gamma": 0.01 }, { "lightness": 20 }, { "weight": 1.39 }, { "color": "#ffffff" }] }, {
        "featureType": "all", "elementType": "labels.text.stroke", "stylers": [{ "weight": 0.96 }, { "saturation": 9 },
        { "visibility": "on" }, { "color": "#000000" }]
      },
      {
        "featureType": "all", "elementType": "labels.icon", "stylers": [
          { "visibility": "off" }
        ]

      },
      {
        "featureType": "administrative.country",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.province",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },

      { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "lightness": 30 }, { "saturation": 9 }, { "color": "#29446b" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "saturation": 20 }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "lightness": 20 }, { "saturation": -20 }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "lightness": 10 }, { "saturation": -30 }] }, { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#193a55" }] }, { "featureType": "road", "elementType": "geometry.stroke", "stylers": [{ "saturation": 25 }, { "lightness": 25 }, { "weight": 0.01 }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "lightness": -20 }] }]
    };

    this.apiService.getGateways().then(gateways => {
      this.gateways = gateways;

      this.offline = [];
      this.gateways.forEach(gw => {
        const found = this.offline.findIndex(ogw => ogw.point.lat === gw.lat && ogw.point.lng === gw.lng);
        if (found === -1) {
          this.offline.push({point: {lat:gw.lat, lng: gw.lng}});
        }
      });

      this.subscription = this.dashboardService.recent
        .pipe(filter(websocketIn => !!websocketIn && websocketIn.action === 'telemetry'))
        .subscribe(webSocketIn => {
          switch (webSocketIn.action) {
            case 'telemetry':
              const assets: { id: number, t: string, value: any }[] = webSocketIn.assets;
              // id, title
              const gateway = gateways.find(g => g.id === webSocketIn.gateway.id);
              if (!gateway || gateway.lat === null || gateway.lat === undefined || gateway.lng === null || gateway.lng === undefined || isNaN(gateway.lng) || isNaN(gateway.lat)) {
                console.error(`${gateway.id} gateway has no lat/lng`, gateway.lat, gateway.lng);
                return;
              }
              const point: google.maps.LatLngLiteral = { lat: gateway.lat, lng: gateway.lng };
              const packet: IStackPacket = { assets, gateway, counter: 30000, point };
              //if (this.stack.length < 150) {
              this.stack.push(packet);
              this.addGatewayToSeen(gateway.id, point);
              //}
              break;

            default:
              console.log(webSocketIn);
              break;
          }
        });

      this.stackTimer = setInterval(() => {
        if (this.stack.length) {
          for (let stackIndex = 0; stackIndex < this.stack.length; stackIndex++) {
            const element = this.stack[stackIndex];
            //this.position = { lat: element.gateway.lat, lng: element.gateway.lng };
            element.counter = element.counter - this.MAP_TIMER.counter;
            if (element.counter < 0) {
              this.stack.shift();
            }
          }
        }
      }, this.MAP_TIMER.interval);

    });
  }

  ngAfterViewInit(): void {
  }

  addGatewayToSeen(gatewayId: string, point: google.maps.LatLngLiteral) {
    const found = this.seen.find(i => i.gateways.findIndex(g => g === gatewayId) !== -1);
    // console.log('addGatewayToSeen', gatewayId, found);
    if (!found) {
      const foundLatLng = this.seen.find(i => i.point.lat === point.lat && i.point.lng === point.lng);
      // console.log(foundLatLng);
      
      if (foundLatLng) {
        foundLatLng.gateways.push(gatewayId);
      } else {
        //  const point: google.maps.LatLngLiteral = { lat, lng };
        this.seen.push({ point, gateways: [gatewayId] })
        const found = this.offline.findIndex(ogw => point.lat === ogw.point.lat && point.lng === ogw.point.lng);
        if (found) {
          // Remove it from offline
          this.offline = this.offline.slice(0,found).concat(this.offline.slice(found+1, this.offline.length));
        }
      }
    }
  }

  gatewayClick(gateway: Gateway) {
    this.onSelected.emit(gateway);
    if (this.canNavigate) {
      this.apiService.getAssetsForGatewayId(gateway.id).then(a => {
        gateway.assets.addAll(a);
        this.view = 'assets';
        this.gateway = gateway;
      });
    }
  }

  assetCick(asset: Asset) {
    this.asset = asset;
    if (this.canNavigate) {
      // this.asset = asset;
      // this.view = 'asset';
    }
  }

  tilesLoaded() {

  }

  zoomChanged() {
    // Only accept a zoom change if we have a map loaded
    if (this.options && this.map) {
      this.saveDefaults();
    }
  }

  loadDefaults() {
    setTimeout(() => {
      const defaults = localStorage.getItem('dashboard:map:defaults') || '{}';
      this.defaults = {
        zoom: 7,
        hideSiteDetails: false,
        ...JSON.parse(defaults)
      };
    });
  }

  saveDefaults() {
    const zoom = this.map ? this.map.getZoom() : this.defaults.zoom;
    const defaults = {
      zoom,
      hideSiteDetails: this.defaults.hideSiteDetails
    }
    localStorage.setItem('dashboard:map:defaults', JSON.stringify(defaults));
  }


  toggleDefault(prop: string) {
    this.defaults[prop] = !this.defaults[prop];
    this.saveDefaults();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

export interface IStackPacket {
  assets: any[];
  gateway: Gateway;
  counter: number;
  point: google.maps.LatLngLiteral;
}

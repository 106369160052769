@defer(when alarmHistory) {
<div class="content-container">
	<table class="table table-hover sticky" aria-label="Alarm History">
		<thead>
			<tr>
				<th>Rule</th>
				<th>State</th>
				<th>Severity</th>
				<th>Alarmed</th>
				<th>Updated</th>
				<th class="text-right" tooltipPosition="left" pTooltip="Age in days">Age</th>
				<th class="text-right" tooltipPosition="left" pTooltip="Hours in alarm">Hrs</th>
			</tr>
		</thead>
		<tbody>
			@for(alarmHistoryItem of alarmHistory;track alarmHistoryItem.id) {
			<tr [routerLink]="['/rules',alarmHistoryItem.ruleId]">
				<td>{{alarmHistoryItem.title}} </td>
				<td>{{alarmHistoryItem.state}} </td>
				<td>{{alarmHistoryItem.severity}} </td>
				<td>{{alarmHistoryItem.createdAt | date:'E dd/MM/YY HH:mm'}} </td>
				<td>
					@if(+alarmHistoryItem.updatedAt !== +alarmHistoryItem.createdAt) {
					{{alarmHistoryItem.updatedAt | date:'dd/MM/YY HH:mm'}}
					}
				</td>
				<td class="text-right"><span>{{alarmHistoryItem.age}}</span></td>
				<td class="text-right">{{alarmHistoryItem.hoursInAlarm}}</td>
			</tr>
			}
		</tbody>
	</table>
</div>
<footer class="center">
	<app-button size="small" label="< Previous page" (click)="previousPage()"></app-button>
	<div><span class="can-click" (click)="toPage(1)" pTooltip="Back to page 1">Page {{page}}</span></div>
	<app-button size="small" label="Next page >" (click)="nextPage()"></app-button>
</footer>
} @placeholder() {
<i class="mdi mdi-loading mdi-spin-x2"></i>
}

<div class="row" *ngIf="rulePackage">
  <app-section-banner [section]="'rules'" [title]="'Rules'" [subtitle]="''" [detail]="rulePackage.title">
  </app-section-banner>

  <div class="rule-content-wrapper">
    <div class="rule-content">

      <div class="title col-sm-12 col-md-8" *ngIf="rulePackageId">
        <h3>{{rulePackage.title}}
          <span *ngIf="rulePackage.asset && rulePackage.asset.id">
            <small>
              <a [routerLink]="['/setpoints', rulePackage.asset.id ]"> <i class="fa fa-long-arrow-right"></i> goto
                setpoint.</a>
            </small>
          </span>
        </h3>
      </div>
      <div class="is-enabled col-sm-12 col-md-4" *ngIf="rulePackageId">
        <span *ngIf="rulePackage.isEnabled==='Y'">
          <i class="fa fa-fw fa-circle online"></i> enabled</span>
        <span *ngIf="rulePackage.isEnabled==='N'">
          <i class="fa fa-fw fa-circle disabled"></i> disabled</span>
      </div>

      <div>
        <div *ngIf="rulePackage.alarm" class="section col-xs-12 alarm">
          <h4>In alarm at state
            <strong>{{rulePackage.alarm.state}}</strong> for
            <strong>{{rulePackage.alarm.createdAt | amTimeAgo:true}}</strong>
          </h4>
        </div>

        <div class="actions">
          <div class="">
            <!-- hidden-sm hidden-md hidden-lg"-->

            <div class="col-xs-4 button-wrapper" *ngIf="rulePackage.inAlert==='Y'">
              <div class="mobile-action alarm"
                [routerLink]="['/rules',  rulePackage.id, 'alarm', rulePackage.alarm.id]">
                <p>
                  <i class="fa fa-fw fa-bell"></i> Alarm Actions
                </p>
              </div>
            </div>
            <div class="col-xs-4 button-wrapper" *ngIf="rulePackage.inAlert==='N'">
              <div class="mobile-action review-alarm-history" (click)="viewHistory()">
                <p>
                  <i class="fa fa-fw fa-bell"></i> <span *ngIf="subview!=='history'">History</span><span
                    *ngIf="subview==='history'">Overview</span>
                </p>
              </div>
            </div>

            <div class="col-xs-4 button-wrapper" *ngIf="rulePackage.isEnabled==='N'">
              <div class="mobile-action enable" (click)="enableRule()" title="Click to enable this rule.">
                <p>Enable</p>
              </div>
            </div>

            <div class="col-xs-4 button-wrapper" *ngIf="rulePackage.isEnabled==='Y'">
              <div class="mobile-action disable" (click)="disableRule()" title="Click to disable this rule.">
                <p>
                  <i class="fa fa-fw fa-ban"></i> Disable
                </p>
              </div>
            </div>

            <div class="col-xs-4 button-wrapper">
              <div class="mobile-action modify" [routerLink]="['/rules',  rulePackage.id, 'change']">
                <p>
                  <i class="fa fa-fw fa-pencil"></i> Modify
                </p>
              </div>
            </div>

          </div>

        </div>
        <div class="totals">
          <div class="col-xs-4">
            <div>Today</div>
            <div>{{rulePackage.counts.todaysCount}}</div>
          </div>
          <div class="col-xs-4">
            <div>This Week</div>
            <div>{{rulePackage.counts.thisWeeksCount}}</div>
          </div>
          <div class="col-xs-4">
            <div>Total</div>
            <div>{{rulePackage.counts.totalCount}}</div>
          </div>
        </div>

        <section [hidden]="subview">


          <div *ngIf="rulePackage.alarm && rulePackage.alarm.timeline" class="section col-xs-12 alarm-history">
            <h4>Alarm history</h4>
            <app-alarm-timeline [ruleAlarm]="rulePackage.alarm"></app-alarm-timeline>
          </div>

          <div class="section col-xs-12 rule-conditions">
            <h4>Sensor conditions</h4>
            <ng-template ngFor let-condition [ngForOf]="rulePackage.conditions">
              <div class="row condition">

                <div class="col-xs-5 col-sm-4">
                  <div class="computed asset" (click)="selectAsset(condition.leftAsset)" title="Click for details">
                    <app-asset-icon [asset]="condition.leftAsset.asset"></app-asset-icon>
                    {{condition.leftAsset.computedValue || '- -'}}
                  </div>
                </div>

                <div class="col-xs-4 col-sm-4">
                  <div class="operator">
                    <span *ngIf="condition.operator==='='"> equals </span>
                    <span *ngIf="condition.operator==='>'"> is greater than </span>
                    <span *ngIf="condition.operator==='<'"> is less than </span>
                    <span *ngIf="condition.operator==='diff'"><small>different by at least
                        {{condition.rightAsset.value}}</small> </span>
                    <span *ngIf="condition.operator==='<diff'"><small>less than difference by at least
                        {{condition.rightAsset.value}}</small> </span>
                    <span *ngIf="condition.operator==='diff>'"><small>greater than difference by at least
                        {{condition.rightAsset.value}}</small> </span>
                  </div>
                </div>

                <div class="col-xs-3 col-sm-4 text-right">
                  <div class="computed asset" *ngIf="condition.rightAsset.asset?.id"
                    (click)="selectAsset(condition.rightAsset)">
                    <app-asset-icon [asset]="condition.rightAsset.asset"></app-asset-icon>
                    {{condition.rightAsset.computedValue || '- -'}}
                  </div>

                  <div class="computed" *ngIf="!condition.rightAsset.asset?.id">
                    {{condition.rightAsset.computedValue || '- -'}}
                  </div>

                </div>

              </div>
              <div class="row live-feed" *ngIf="condition.leftAsset.asset">
                <app-asset-chart theme="white" stroke="black" [asset]="condition.leftAsset.asset"
                  [setpoints]="setpoints">
                </app-asset-chart>
              </div>
            </ng-template>
            <div class="row" *ngIf="rulePackage.ruleTimeRanges[0]">
              <div class="section restrictions col-xs-12">
                <h4>Time Restrictions</h4>
                <table class="table table-sm">

                  <tbody>
                    <tr>
                      <td class="dow-indicator" [class.enabled]="rulePackage.ruleTimeRanges[0].monday">
                        Mo
                      </td>
                      <td class="dow-indicator" [class.enabled]="rulePackage.ruleTimeRanges[0].tuesday">
                        Tu
                      </td>
                      <td class="dow-indicator" [class.enabled]="rulePackage.ruleTimeRanges[0].wednesday">
                        We
                      </td>
                      <td class="dow-indicator" [class.enabled]="rulePackage.ruleTimeRanges[0].thursday">
                        Th
                      </td>
                      <td class="dow-indicator" [class.enabled]="rulePackage.ruleTimeRanges[0].friday">
                        Fr
                      </td>
                      <td class="dow-indicator" [class.enabled]="rulePackage.ruleTimeRanges[0].saturday">
                        Sa
                      </td>
                      <td class="dow-indicator" [class.enabled]="rulePackage.ruleTimeRanges[0].sunday">
                        Su
                      </td>
                      <td class="text-right">
                        <span
                          *ngIf="rulePackage.ruleTimeRanges[0].startTimeAt === '00:00' && rulePackage.ruleTimeRanges[0].endTimeAt === '23:59'">
                          All day
                        </span>
                        <span
                          *ngIf="rulePackage.ruleTimeRanges[0].startTimeAt !== '00:00' || rulePackage.ruleTimeRanges[0].endTimeAt !== '23:59'">
                          {{rulePackage.ruleTimeRanges[0].startTimeAt}}
                          <i class="fa fa-long-arrow-right"></i>
                          {{rulePackage.ruleTimeRanges[0].endTimeAt}}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      <!-- Alarm History -->

      <div class="col-xs-12" *ngIf="subview==='history'">
        <app-rule-engine-v2-history [rule]="rulePackage"></app-rule-engine-v2-history>

      </div>
    </div>
  </div>
  <!-- footer -->
  <div class="footer col-xs-12 text-right"># {{rulePackage.id}}</div>
</div>

<app-sensor-popup *ngIf="selectedAsset" [(show)]="isShowingPopupSensorData" [asset]="selectedAsset"></app-sensor-popup>
<div class="row">

  <div class="col-sm-12">
    <div class="ticket">
      <div class="row">

        <div class="col-sm-12 col-md-8">
          <div class="title">{{ticket.title}}
            <small>at state {{ticket.state}}</small>
          </div>

          <div class="asset-title" *ngIf="assetTicket">
            {{assetTicket.asset.title}}
            <span> sensor at </span>{{ticket.site.title}}
          </div>
          <div class="description">
            <pre>{{ticket.description}}</pre>
          </div>

          <div class="created-by text-right">
            Created {{ticket.createdAt | amTimeAgo}}
          </div>

        </div>

        <div class="col-sm-12 col-md-4 messages">
          <app-message-item [message]="item" *ngFor="let item of ticket.messages"></app-message-item>

        </div>
      </div>

    </div>

  </div>

</div>

<article>
	<app-info-panel>Users who are part of the sites notification group</app-info-panel>
	<table class="table table-hover" aria-label="Users">
		<thead>
			<tr>
				<th></th>
				<th>Name</th>
				<th>email</th>
			</tr>
		</thead>
		<tbody>
			@for(user of users();track user.id) {
			<tr (click)="toggleSelected(user)" class="can-click" [class.selected]="user.isSelected">
				<td>
					<i class="mdi" [class.mdi-checkbox-marked-outline]="user.isSelected" [class.mdi-checkbox-blank-outline]="!user.isSelected"></i>
				</td>
				<td>{{user.name}}</td>
				<td>{{user.email}}</td>
			</tr>
			}
		</tbody>
	</table>
</article>

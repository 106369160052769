<app-theme-page-container *ngIf="compliance">
    <ng-template ngFor [ngForOf]="compliance.groups" let-group>
        <app-theme-page-section [label]="group.title">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>Asset</th>
                        <th class="hidden-sm hidden-xs">Site</th>
                        <th class="text-center hidden-sm hidden-xs">Compliant</th>
                        <th class="hidden-sm hidden-xs">Compliant On</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let asset of group.assets" class="can-click" [class.compliant]="asset.compliant" (click)="select(asset)">
                        <td>{{asset.title}}</td>
                        <td class="hidden-sm hidden-xs">{{asset.site.title}}</td>
                        <td class="text-center compliant hidden-sm hidden-xs">{{asset.compliant ? 'Yes' : 'No'}}</td>
                        <td class="hidden-sm hidden-xs"><span *ngIf="asset.compliantAt">{{asset.compliantAt |
								amDateFormat:'DD-MM-YYYY'}}</span></td>
                    </tr>
                </tbody>
            </table>
        </app-theme-page-section>
    </ng-template>
</app-theme-page-container>

<div class="_modal" *ngIf="asset">
    <main>
        <nav>
            <span (click)="asset=null" class="can-click"><i class="mdi mdi-chevron-left"></i> {{asset.title}}</span>
        </nav>
        <app-setpoint-query [asset]="asset" showValue="true" compact="true" dateType="custom" [custom]="{from:compliance.df, to:compliance.dt}" saveState="false">
        </app-setpoint-query>
    </main>
</div>
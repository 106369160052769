/**
 * @version 1
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from 'app/shared/store.service';
import { DashboardService } from 'app/shared/dashboard.service';
import { APIService } from 'app/shared/api.service';
import { WebsocketIn } from 'app/classes/websocket-in';

@Component({
  selector: 'app-dashboard-landing',
  templateUrl: './dashboard-landing.component.html',
  styleUrls: ['./dashboard-landing.component.css']
})
export class DashboardLandingComponent implements OnInit, OnDestroy {
  static readonly MAX_RECENT = 100;

  activeTile  = 'telemetry';
  timer: any;
  timerSubscription: any;
  recent = {
    telemetry: null,
    rag: null
  }
  isAuthenticated: boolean;
  recents: WebsocketIn[] = [];
  tableMode: 'map' | 'telemetry' | 'realtime' | 'buildings' | 'charts'= 'realtime';
  tableFilter = 'all';

  constructor(private apiService: APIService, private storeService: StoreService, public dashboardService: DashboardService) { }

  ngOnInit() {
    this.tableMode = 'map';
    this.getDashboard();
    this.storeService.setIsLoading(false);
  }

  getDashboard() {
    this.dashboardService.connect();
  }

  ngOnDestroy() {
    this.dashboardService.disconnect();
    console.log('DASHBOARD_DESTROYED');
  }

  setTableMode(mode: 'map' | 'telemetry' | 'realtime' | 'buildings' | 'charts') {
    this.tableMode = mode;
  }

  filter(filterAs) {
    this.tableFilter = filterAs;

    this.dashboardService.setFilter(filterAs);
  }
}

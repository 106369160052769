<div>
	<div class="title" (click)="navigateBack()">
		<span pTooltip="Back to floorplan">
			<i class="fa fa-fw fa-chevron-left"></i>
			<span>
			</span> {{shape.title}}&nbsp;</span>
	</div>
	<div>
		<p-tabView (onChange)="handleChange($event)">
			<p-tabPanel header="Telemetry" [cache]="true">
				<app-setpoint-query [assets]="shape.assets" view="compact" showValue="true"></app-setpoint-query>
			</p-tabPanel>
			<p-tabPanel header="Manage" [cache]="true" *ngIf="shape.category === 'Occupancy'">
				<app-shape-manage [shape]="shape"></app-shape-manage>
			</p-tabPanel>
		</p-tabView>

	</div>
</div>

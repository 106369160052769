<article>
	<header>
		<div class="tabview-list no-print">
			<div [class.active]="tabIndex()===0" (click)="tabIndexSet(0)">
				<i class="mdi mdi-view-list"></i> Assets
			</div>
			<div [class.active]="tabIndex()===1" (click)="tabIndexSet(1)">
				<i class="mdi mdi-view-list"></i> Sites
			</div>
			<div></div>
		</div>
	</header>
	<main>

		@switch(tabIndex()) {
		@case(0) {
		<app-toolbar size="small">
			<div class="right">
				<app-button size="small" label="Previous Year" icon="mdi mdi-arrow-left" (click)="goBack()" />
				<app-button pTooltip="Year displayed" tooltipPosition="left" size="small" [label]="onYear()" [isDisabled]="true"></app-button>
				<app-button size="small" label="Next Year" icon="mdi mdi-arrow-right" iconPos="right" (click)="goForward()" />
			</div>
		</app-toolbar>

		<table class="table table-hover sticky">
			<thead>
				<tr>
					<th>Asset</th>
					<th>Site</th>
					@for(month of months; track month) {
					<th class="text-right">{{month}}</th>
					}
				</tr>
			</thead>
			<tbody>
				@for(asset of assets();track asset.id) {
				<tr [routerLink]="['/energy/site', asset.site_id]">
					<td>{{asset.title}}</td>
					<td> {{asset.site_title}} </td>

					@for(month of months; track month; let monthIndex = $index) {
					@if(!asset.months) { <td class="text-right" style="font-size:8px" pTooltip="No Data"> x </td>} @else {
					@if(asset.months[monthIndex] === null) {
					<td class="text-right" style="color:#a0a0a0">-</td>
					} @else {
					<td class="text-right">
						{{asset.months[monthIndex] | number:'1.0'}}
					</td>
					}

					}
					}
				</tr>
				}
				<tr class="footer">
					<td colspan="2" class="text-left">Total</td>
					@for(total of grandTotals; track $index) {
					<td class="text-right">{{total | number: '1.0'}}</td>
					}
				</tr>

			</tbody>
		</table>
		@if (!assets()) {
		<i class="mdi mdi-loading mdi-spin-x2"></i>
		}
		}
		@case(1) {
		<table class="table table-hover sticky">
			<thead>
				<tr>
					<th>Sites with meter readings</th>
					<th>Address</th>
				</tr>
			</thead>
			<tbody>
				@for(site of sites();track site.id) {
				<tr [routerLink]="['/energy/site', site.id]">
					<td>{{site.title}}</td>
					<td>@if(site.address.addressCounty) {
						{{site.address.addressCounty}},}
						{{site.address.addressTown}}
					</td>
				</tr>
				}
			</tbody>
		</table>
		}
		}


	</main>
</article>

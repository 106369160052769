import { Component, OnInit, Input } from '@angular/core';
import { TrainingModule } from '../../classes/training-module';

@Component({
  selector: 'app-training-module-list-item',
  templateUrl: './training-module-list-item.component.html',
  styleUrls: ['./training-module-list-item.component.css']
})
export class TrainingModuleListItemComponent implements OnInit {


  @Input()
  module: TrainingModule;

  @Input()
  nextModule: TrainingModule;

  constructor() { }

  ngOnInit() {
    
  }

  watch(module: TrainingModule) {
    console.log('watch' + module.title);
  }
}

<div class="panel panel-default panel-fourd inventory-item"  [class.collapsed]="!expanded"  [class.expanded]="expanded" [class.selected]="item.qty">
    <div class="panel-heading">
      <h3 class="panel-title">
        
          <i class="fa fa-plus-circle" (click)="clickCollectionQty(1)"></i>&nbsp;
          <i class="fa fa-minus-circle" (click)="clickCollectionQty(-1)"></i>
          
       <!-- <i (click)="selected = !selected" class="fa fa-fw" [class.fa-square-o]="!selected" [class.fa-check-square-o]="selected" title="Click to order typical parts for this."></i> -->
        
        &nbsp; <span *ngIf="item.qty">{{item.qty}} of </span> {{item.title}} 
        <span class="pull-right"> <i (click)="expanded = !expanded" class="fa fa-fw" [class.fa-chevron-up]="expanded" [class.fa-chevron-down]="!expanded" title="More details on this."></i></span>
      </h3>
    </div>

    <div class="panel-body" [hidden]="!expanded">
      <div class="row">
        <div class="col-xs-12 image">
          <!--<img class="img-responsive" src="https://s3.eu-west-2.amazonaws.com/4d-inventory/placeholder-160.jpg">-->{{item.details}}
          <img *ngIf="item.diagram" src="https://s3.eu-west-2.amazonaws.com/4d-inventory/{{item.diagram}}" class="img-responsive">
        </div>
        <div class="col-xs-12 inventory-list">
            <div class="row">
          <ng-template ngFor let-inventory [ngForOf]="item.inventorys">

              <div class="col-xs-6 col-sm-12">
          
                <form class="form-inline">
                  <div class="form-group quantity pull-right">
                    <label for="qty">{{inventory.title}} : </label>
                    <div class="input-group">
                      <input name="qty" class="form-control" aria-label="Order Quantity" [(ngModel)]="inventory.qty">
                      <div class="input-group-btn">
                        <button type="button" class="btn btn-default" aria-label="Help" (click)="incdec(1,item)">
                          <i class="fa fa-plus-circle"></i>
                        </button>
                        <button type="button" class="btn btn-default" aria-label="Help" (click)="incdec(-1,item)">
                          <i class="fa fa-minus-circle"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

              </div>
         
          </ng-template>
        </div>
        </div>

      </div>
    </div>

  </div>
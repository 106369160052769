import { Component, OnInit, Input, Output, EventEmitter, HostListener, signal } from "@angular/core";
import { DocumentsService } from "app/shared/documents.service";
import { APIService } from "app/shared/api.service";
import { Document } from "app/classes/document";
import { Building } from "app/classes/building";
import { CalendarEntry } from "app/classes/calendar-entry";
import { BuildingsService } from "app/shared/buildings.service";

@Component({
  selector: "app-documents-list",
  templateUrl: "./documents-list.component.html",
  styleUrls: ["./documents-list.component.css"],
})
export class DocumentsListComponent implements OnInit {
  @Input()
  documents: Document[];

  @Output()
  onDidLoad: EventEmitter<any> = new EventEmitter();

  showUpdate: boolean;
  updateDocument: Document;
  originalDoc: any;
  exploreDocument: Document;
  showExploreDocument: boolean;
  building: Building;
  drillDown: boolean;
  view: IDocumentView = 'default';

  popupStyle = "{width: '560px', height: '400px'}";
  isMobile: boolean;
  isDragging: boolean;
  isUploading: boolean;
  hasDropped: boolean;
  uploadDate: Date;
  uploadFile: File;
  buildingList: any[];
  uploadBuilding: any;
  uploadBuildingId: number;
  uploadRAG: string = "green";
  uploadTitle: string = "";
  config: any = { onlyActiveSites: false };

  isAdmin = signal<boolean>(false);

  filtered: { list: Document[]; text: string, count: number } = { list: [], text: "", count: null };

  tooltipOptions = {
    showDelay: 150,
    autoHide: false,
    tooltipEvent: 'hover',
    tooltipPosition: 'left',
    appendTo: 'body'
  };

  isLoading = signal<boolean>(false);

  /*
    @HostListener("dragenter", ["$event"])
    onDragStartHandler(event: DragEvent) {
      if (this.hasDropped) {
        return;
      }
      this.isDragging = true;
      event.dataTransfer.dropEffect = "copy";
      event.preventDefault();
      console.log("dragstart", event);
    }
  
    @HostListener("dragover", ["$event"])
    onDragOverHandler(event: DragEvent) {
      if (this.hasDropped) {
        return;
      }
      this.isDragging = true;
      event.dataTransfer.dropEffect = "copy";
      event.preventDefault();
    }
  
    @HostListener("dragleave", ["$event"])
    onDragLeaveHandler(event: any) {
      if (event.toElement.localName === "rect") {
        return;
      }
      this.isDragging = false;
      event.preventDefault();
      console.info("dragleave", event.toElement.localName, event);
    }
  
    @HostListener("drop", ["$event"])
    onDropHandler(event: DragEvent) {
      if (!this.isDragging) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      console.log("drop", event);
      this.isDragging = false;
      const file = event.dataTransfer.files[0];
      const { name, type, size, lastModified } = file;
  
      this.uploadFile = file;
  
      if (!name || name.toLowerCase().substr(name.length - 3) !== "pdf") {
        return this.apiService.toastWarn("Not a PDF", `received ${name}`);
      }
  
      this.apiService.getBuildings().then((b) => {
        this.buildingList = b.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
        if (name.startsWith("Building Review for ")) {
          const buildingName = name.split(" from ")[0].substring(20);
          this.uploadTitle = `Building Review for ${buildingName}`;
          console.log(` [${buildingName}] `);
          this.uploadBuilding = this.buildingList.find((b) => b.title.toLowerCase() === buildingName.toLowerCase().substr(0, b.title.length));
          if (this.uploadBuilding) {
            this.uploadBuildingId = this.uploadBuilding.id;
          }
        } else {
          this.uploadTitle = name;
          this.uploadBuildingId = null;
        }
  
        this.hasDropped = true;
      });
    }
*/

  constructor(public apiService: APIService, private documentService: DocumentsService, private buildingService: BuildingsService) {
    this.getLocalStorage();
    this.isAdmin.set(apiService.isAdmin());
  }

  ngOnInit() {
    this.isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

    if (this.isMobile) {
      this.popupStyle = "{width: '375px', height: '400px'}";
    }
    if (!this.documents) {
      this.getDocuments();
      // Multiple buildings, allow drilldown
      this.drillDown = true;
    } else {
      this.applyFilter();
    }
  }

  getDocuments() {
    this.isLoading.set(true);
    this.documentService.get("uniqebuildings=1").then((documents) => {
      this.documents = documents;
      this.onDidLoad.emit(this.documents.length);
      this.applyFilter();
      this.isLoading.set(false);
    });
  }

  hasViewed(document: Document) {
    this.apiService.postRoute(`documents-list/${document.key}`).then(() => {
      document.isRead = true;
      console.log("posted route");
    });
  }

  updateNotes(document: Document) {
    console.log(document);
    this.showUpdate = true;
    this.originalDoc = { ...document };
    this.updateDocument = document;
  }

  buildingClick(clickedDocument: Document) {
    if (!this.drillDown) {
      return;
    }
    this.showExploreDocument = true;

    this.documentService.getForBuilding(clickedDocument.buildingId).then((building) => {
      this.building = building;
      this.exploreDocument = clickedDocument;
    });
  }

  saveNotes() {
    this.documentClosed();
  }

  documentClosed() {
    this.showUpdate = false;

    if (this.originalDoc.notes === this.updateDocument.notes && this.originalDoc.notesColour === this.updateDocument.notesColour) {
      // no changes
      this.updateDocument = null;

      return;
    }
    this.apiService.toastSuccess('Updating note..', '', 500);

    const documentId = this.updateDocument.id;

    this.documentService.putNotes(this.updateDocument).then(() => {
      const routePayload = {
        document: {
          id: documentId,
          notes: this.updateDocument.notes,
          notesColour: this.updateDocument.notesColour
        },
      };
      const routePath = "/documents/" + documentId + "/notes";
      this.apiService.postRoute(routePath, "update", JSON.stringify(routePayload));
      this.updateDocument = null;
      this.apiService.toastSuccess("Note updated", "");
    });
  }

  defaultViewClick() {
    this.saveLocalStorage();
  }

  uploadBuildingChanged(id: number) {
    this.uploadBuildingId = id;
    console.log('now uploading to building #', id);
  }

  uploadDocument() {
    this.apiService.toastSuccess("Adding calendar entry", "");

    const calendarEntry = new CalendarEntry({
      title: this.uploadTitle,
      startAt: this.apiService.dbDate(this.uploadDate),
      endAt: this.apiService.dbDate(this.uploadDate),
      allDay: "Y",
      labels: "report",
      type: "action",
      isCompleted: "Y",
      rag: this.uploadRAG,
      body: null,
    });

    this.buildingService.postCalendarEntryForBuilding(this.uploadBuildingId, calendarEntry).then((r) => {
      console.log(r);
      if (r.length < 4) {
        this.apiService.toastWarn("Unable to create calendar entry", "");
        return;
      }
      const title = calendarEntry.title;
      const hasPrivacyConcerns = false;
      const target = "calendar";
      const targetId = r[4][0].calendarId;
      const userId = this.apiService.getUserId();

      const fileName = `u_${userId}_b_${this.uploadBuildingId}_d_${+this.uploadDate}_upload.pdf`;

      this.apiService.toastSuccess("Uploading document to calendar", "");
      this.apiService.postUploader(title, hasPrivacyConcerns, target, targetId, this.uploadFile.type, fileName, this.uploadFile).then((r) => {
        console.log(r);
        this.apiService.toastSuccess("uploaded", "");
        this.hasDropped = false;
        this.isDragging = false;
        this.getDocuments();
      });
    });
  }

  searchChanged(search: string) {
    console.log(search);
    this.filtered.text = search.toLowerCase().trim();
    this.saveLocalStorage();
    this.applyFilter();
  }

  applyFilter() {
    this.filtered.text = this.filtered.text.trim();

    if (this.filtered.text === "") {
      this.filtered.list = this.documents;
      this.filtered.count = this.documents.length;

      return;
    }

    this.filtered.list = this.documents.filter((d) => {
      // What to look for in the switch block
      let searchCommand = this.filtered.text;
      let searchForText = this.filtered.text;
      if (searchCommand.startsWith("org:")) {
        searchCommand = "org:";
        searchForText = this.filtered.text.substring(4);
      }
      // What to search for when matched in the switch block

      switch (searchCommand) {
        case "rag:red":
          if (d.rag === "red") return true;
          break;
        case "rag:amber":
        case "rag:orange":
          if (d.rag === "amber") return true;
          break;
        case "rag:green":
          // Green if null as well as 'green'
          if (!d.rag || d.rag === "green") return true;
          break;
        case "document:unread":
        case "documents:unread":
        case "doc:unread":
        case "docs:unread":
          if (!d.isRead) return true;
          break;
        case "document:read":
        case "doc:read":
        case "docs:read":
        case "documents:read":
          if (d.isRead) return true;
          break;
        case "organisation:":
        case "org:":
          if (d.orgShortTitle.toLowerCase().includes(searchForText)) return true;
          break;
        case "notes:red":
          if (d.notesColour === "red") return true;
          break;
        case "notes:amber":
        case "notes:orange":
          if (d.notesColour === "orange") return true;
          break;
        case "notes:black":
          if (d.notes && d.notes.length && !d.notesColour) return true;
          break;
        case "notes:any":
          if (d.notes && d.notes.length) return true;
          break;
        case "notes:green":
          if (d.notesColour === "green") return true;
          break;

        default:
          if (d.title.toLowerCase().includes(searchForText)) return true;
          if (d.notes && d.notes.toLowerCase().includes(searchForText)) return true;

          break;
      }
    });

    this.filtered.count = this.filtered.list.length;
  }

  getLocalStorage() {
    try {
      this.filtered.text = localStorage.getItem(`org:${this.apiService.getUserOrg()?.id}:livefeed:search`) || "";
      const onlyActiveSites = localStorage.getItem(`building-reviews:config`);
      if (onlyActiveSites) {
        this.config = JSON.parse(onlyActiveSites);
      }
    } catch (error) { }

    try {
      this.view = <IDocumentView>localStorage.getItem("documents-list:view") || "default";
    } catch (e) { }
  }

  saveLocalStorage() {
    localStorage.setItem(`org:${this.apiService.getUserOrg()?.id}:livefeed:search`, this.filtered.text);
    localStorage.setItem("documents-list:view", this.view);
    localStorage.setItem(`building-reviews:config`, JSON.stringify(this.config));
  }

  toggleActive() {
    this.config.onlyActiveSites = !this.config.onlyActiveSites;
    this.saveLocalStorage();
  }
}

export type IDocumentView = 'default' | 'notes';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlatformModuleService {

  static modules = {
    live: { route: '/live', title: 'Live' },
    api: { route: '/developer', title: 'API' },
    profiling: { route: '/profiling', title: 'floorplan' },
    billing: { route: '/org', title: 'billing' }
  };

  constructor() { }
}

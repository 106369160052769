<div class="row">
  <!-- -->
  <app-section-banner section="issues" title="4D Issues" [subtitle]="'Issues Dashboard'" [detail]="" [actions]="[{title:'Submit Issue',link:['/issues','create'], icon:'plus'}]"></app-section-banner>

  <div *ngIf="init===true" class="loader">Loading...</div>
  <!-- -->

  <div class="counts">
    <div class="col-sm-4">

      <div class="box" (mouseenter)="enterBox(this.ticketKind.Asset)" (mouseleave)="exitBox()">
        <div class="count">{{counts.asset}}</div>
        <div class="title">Sensor </div>
        <div class="sub-title">issues</div>
        <div class="back-card"><img src="assets/4d_logo_128x128.png" /></div>
      </div>

    </div>
    <div class="col-sm-4">

      <div class="box" (mouseenter)="enterBox(this.ticketKind.Gateway)" (mouseleave)="exitBox()">
        <div class="count">{{counts.gateway}}</div>
        <div class="title">Gateway </div>
        <div class="sub-title">issues</div>
        <div class="back-card"><img src="assets/4d_logo_128x128.png" /></div>
      </div>

    </div>
    <div class="col-sm-4">

      <div class="box" (mouseenter)="enterBox(this.ticketKind.Site)" (mouseleave)="exitBox()">
        <div class="count">{{counts.site}}</div>
        <div class="title">Site </div>
        <div class="sub-title">issues</div>
        <div class="back-card"><img src="assets/4d_logo_128x128.png" /></div>
      </div>

    </div>
  </div>

	<router-outlet></router-outlet>

</div>

<article>
    <main>
        <h4>The report is being generated.</h4>
        <p>Depending on the quantity of data, this may take a minute.</p>
        <p>Subsequent runs of this month are cached and will generate instantly.</p>
    </main>
    <footer>
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-sm p-button-outlined p-button-secondary mr-1"
            (click)="cancel()">
        </p-button>
    </footer>
</article>

<article>
	<nav>
		<ul>
			<li>3.2</li>
		</ul>
	</nav>
	<main>
		@switch (version()) {
		@case('3.2') {

		<section class="hero">
			<div class="content">4D Change log, version 3.2</div>
		</section>

		<section class="left">
			<h1>Navigation</h1>
			<p>Left navigation has been refreshed and can now be condensed down into icons to give you more room.</p>
			<div class="flex center">
				<p>Added a new </p>
				<span><i class="mdi mdi-home menu-icon"> Home</i></span>
				<p> navigation option for easier discovery, before returning to home page ment clicking on the top left logo.</p>
			</div>
			<div class="flex center">
				<p>The menu can now be collapsed into icons, to give you more room. Just press

				</p>
				<i class="mdi mdi-format-horizontal-align-left menu-icon"></i>
				<p>
					to toggle the menu.
				</p>
			</div>

		</section>

		<section class="left">
			<h1>Dark Mode</h1>
			<p>You can now toggle between light and dark mode in a couple of places.</p>
			<p>At the bottom left of the navigation menu, there is an <i class="mdi mdi-theme-light-dark menu-icon"></i>
				icon where you can click to toggle.</p>
			<p>You can also change the theme in your account page, where there is another dark mode variant to choose
				from.</p>
		</section>

		<section class="left">
			<h1>Telemetry</h1>
			<p>A Search input has now been added, you can now get to sensors quickly without scrolling down long lists.</p>
		</section>

		<section class="left pagebreakbefore">
			<h1>Floorplans</h1>
			<div class="flex">
				<div>
					<p>A brand new floorplan view has been added, Occupancy Daily.</p>
					<p>Occupancy Daily, desks overlayed with occupancy rating for any day you choose.</p>
					<p>Floorplan dashboards are now integrated into Tenancy and Services.</p>
					<p>Dashboards can now be exported to Excel.</p>
				</div>
				<img src="https://4d-public.s3.eu-west-2.amazonaws.com/website/floorplan-occupancy-daily.png" class="right">
			</div>
		</section>

		<section class="left">
			<h1>Reports</h1>
			<p>The report selection has changed from a list to cards, you can now see an overview of what charts the reports produce.</p>
			<p>A new report has been added, Sensor Trigger. This new report allows you to see if a trigger value was reached between a time range for a selection of sensors or sites.</p>
		</section>

		<section class="left">
			<h1>Rules</h1>
			<p>Rule lists are now organised, with the rules in alarm status grouped to the top of the list.</p>
			<p>Alarm History tab introduced, shows the last 100 alarms.</p>
			<p>The tabs (Rules, Setpoints, Alarm History) now show a count, enabling you can know if rules or setpoint rules exist, without having to click into them.</p>
			<p>Creating and updating rules has had a usability UI overhaul.</p>
		</section>

		<section class="left">
			<h1>Organisation</h1>
			<p>A new section, Tenants added. This section allows you to manage tenants and services associated with your floorplans.</p>
		</section>


		<footer>

		</footer>
		}
		}
	</main>
</article>

<app-theme-page-container *ngIf="summary">
    <app-theme-page-section>
        <header>
            <p-toolbar>
                <div class="p-toolbar-group-left">
                    <span class="mdi mdi-calendar"></span>&nbsp; &nbsp;
                    <span>{{dateFrom.toDate() | date:'dd MMM yy'}} -
                        {{dateTo.toDate() | date:'dd MMM yy'}}</span>
                </div>

                <div class="p-toolbar-group-right no-print">
                    <p-button label="Print page" icon="mdi mdi-printer"
                        styleClass="p-button-outlined p-button-sm p-button-secondary" (click)="printPage()"></p-button>
                    &nbsp;

                    <p-button label="Previous week" icon="mdi mdi-arrow-left"
                        styleClass="p-button-outlined p-button-sm p-button-secondary" (click)="setDates('previous')"
                        iconPos="left">
                    </p-button>

                    <p-button label="This week" icon="mdi mdi-calendar" [disabled]="!can.thisWeek"
                        [pTooltip]="!can.thisWeek ? 'No data for this week on Monday' : ''"
                        styleClass="p-button-outlined p-button-sm p-button-secondary" (click)="setDates('this')">
                    </p-button>

                    <p-button label="Next week" icon="mdi mdi-arrow-right" [disabled]="!can.nextWeek"
                        styleClass="p-button-outlined p-button-sm p-button-secondary" (click)="setDates('next')"
                        iconPos="right">
                    </p-button>
                </div>
            </p-toolbar>
        </header>
        <main>
            <div>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Floor</th>
                            <th>Room</th>
                            <th>Mon</th>
                            <th>Tue</th>
                            <th>Wed</th>
                            <th>Thu</th>
                            <th>Fri</th>
                            <th>Sat</th>
                            <th>Sun</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let asset of summary.assets">
                            <td>{{asset.floorTitle}}</td>
                            <td>{{asset.shapeTitle || asset.title}}</td>
                            <td class="b-{{asset.weeks[0][0].c}} can-click" tooltipPosition="bottom"
                                pTooltip="{{asset.weeks[0][0].h}} ({{asset.weeks[0][0].m}}) - {{asset.weeks[0][0].d}}"
                                (click)="adc(asset,0, asset.weeks[0][0])">
                                {{asset.weeks[0][0].p}}</td>
                            <td class="b-{{asset.weeks[0][1].c}} can-click" tooltipPosition="bottom"
                                pTooltip="{{asset.weeks[0][1].h}} ({{asset.weeks[0][1].m}}) - {{asset.weeks[0][1].d}}"
                                (click)="adc(asset,1, asset.weeks[0][1])">
                                {{asset.weeks[0][1].p}}</td>
                            <td class="b-{{asset.weeks[0][2].c}} can-click" tooltipPosition="bottom"
                                pTooltip="{{asset.weeks[0][2].h}} ({{asset.weeks[0][2].m}}) - {{asset.weeks[0][2].d}}"
                                (click)="adc(asset,2, asset.weeks[0][2])">
                                {{asset.weeks[0][2].p}}</td>
                            <td class="b-{{asset.weeks[0][3].c}} can-click" tooltipPosition="bottom"
                                pTooltip="{{asset.weeks[0][3].h}} ({{asset.weeks[0][3].m}}) - {{asset.weeks[0][3].d}}"
                                (click)="adc(asset,3, asset.weeks[0][3])">
                                {{asset.weeks[0][3].p}}</td>
                            <td class="b-{{asset.weeks[0][4].c}} can-click" tooltipPosition="bottom"
                                pTooltip="{{asset.weeks[0][4].h}} ({{asset.weeks[0][4].m}}) - {{asset.weeks[0][4].d}}"
                                (click)="adc(asset,4, asset.weeks[0][4])">
                                {{asset.weeks[0][4].p}}</td>
                            <td class="b-{{asset.weeks[0][5].c}} can-click" tooltipPosition="bottom"
                                pTooltip="{{asset.weeks[0][5].h}} ({{asset.weeks[0][5].m}}) - {{asset.weeks[0][5].d}}"
                                (click)="adc(asset,5, asset.weeks[0][5])">
                                {{asset.weeks[0][5].p}}</td>
                            <td class="b-{{asset.weeks[0][6].c}} can-click" tooltipPosition="bottom"
                                pTooltip="{{asset.weeks[0][6].h}} ({{asset.weeks[0][6].m}}) - {{asset.weeks[0][6].d}}"
                                (click)="adc(asset,6, asset.weeks[0][6])">
                                {{asset.weeks[0][6].p}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <app-rating-ae [percentage]="summary.totalPerc" [width]="240"></app-rating-ae>
            </div>

        </main>
        <footer>

        </footer>

    </app-theme-page-section>
</app-theme-page-container>

<!--
<div class="_modal" *ngIf="asset">
    <div>
        <nav>
            <span (click)="asset=null" class="can-click"><i class="mdi mdi-chevron-left"></i>
                {{asset.asset.title}}</span>
        </nav>
        <app-theme-page-section>
            <app-d3-occupancy [asset]="asset.asset" [df]="asset.df" [dt]="asset.dt"></app-d3-occupancy>
        </app-theme-page-section>
    </div>
</div>
-->

import { Component, OnInit, AfterViewInit, Input, ElementRef, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { TrainingService } from '../../shared/training.service';
import { StoreService } from "../../shared/store.service";
import { TrainingModule } from '../../classes/training-module';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-training-overview',
  templateUrl: './training-overview.component.html',
  styleUrls: ['./training-overview.component.css']
})
export class TrainingOverviewComponent implements OnInit, AfterViewInit {
  courseId: number;
  moduleId: number;
  module: TrainingModule;
  subscription: any;
  transitionToModuleId: number;
  moduleTitle: string;

  @Output()
  moduleCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('video1') video1: ElementRef;


  constructor(private trainingService: TrainingService, private router: Router, private route: ActivatedRoute, private storeService: StoreService, private apiService: APIService) {
    console.log('constructor');
    route.params.subscribe((params: Params) => {
      this.transitionToModuleId = null;
      this.courseId = params['courseid'];
      this.moduleId = params['moduleid'];
      try { this.video1.nativeElement.src = null } catch (e) { }

      apiService.getTrainingModule(this.courseId, this.moduleId).then(module => {
        this.module = module;
        try { this.video1.nativeElement.src = 'https://s3-eu-west-1.amazonaws.com/4d-training/videos/' + module.video } catch (e) { }


        try { this.video1.nativeElement.play(); } catch (e) { }

      });
    });
  }

  ngOnInit() {
    console.log('nginit');
    this.subscription = this.storeService.sectionBannerBack
      .pipe(filter(sbb => !!sbb && sbb.state === true && sbb.section === 'training'))
      .subscribe(state => {
        console.log('back to training list');
        // Set to handled
        state.state = false;
        this.storeService.setSectionBannerBack(state);
        // Back to training
        this.router.navigate(['/training']);
      });
  }

  goBack() {
    this.router.navigate(['/training', this.courseId]);
  }

  ngOnDestroy() {
    console.log('destroyed');
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  videoProgress(event) {
    console.log(event.target.currentTime, event.target.duration);
    if (!event.target.duration) {
      return;
    }

    //console.log(event.target);

    let progress = event.target.currentTime;
    console.log(!this.module.isCompleted, progress, ' >', (event.target.duration - 5));
    if (!this.module.isCompleted && progress >= (event.target.duration - 5)) {
      this.module.isCompleted = 'Y';
      console.log('completed module');
      this.moduleCompleted.emit(true);
      this.apiService.putTrainingModuleProgress(this.courseId, this.module.id, progress, this.module.isCompleted);
    }

    if (progress >= (event.target.duration) && this.module.nextModuleId) {
      this.transitionToModuleId = this.module.nextModuleId;
      this.moduleTitle = this.module.title;
      console.log('done', this.transitionToModuleId);

      try { this.video1.nativeElement.pause(); this.video1.nativeElement.currentTime = 0; } catch (e) { console.log(e) }


      setTimeout(() => {
        // Null  the module to force video from DOM to be removed.
        this.module = null;
        this.router.navigate(['/training', this.courseId, 'modules', this.transitionToModuleId]);

      }, 2000);
    }

  }

}

<div *ngIf="false" class="options text-center">
    <button (click)="setTableMode('map')" pButton type="button" label="map" class="ui-button-rounded"
        [class.ui-button-primary]="tableMode==='map'" [class.ui-button-secondary]="tableMode!=='map'"></button>
    <button (click)="setTableMode('realtime')" pButton type="button" label="realtime" class="ui-button-rounded"
        [class.ui-button-primary]="tableMode==='realtime'" [class.ui-button-secondary]="tableMode!=='realtime'"
        title="View data as it arrives in realtime"></button>
    <button (click)="setTableMode('buildings')" pButton type="button" label="buildings" class="ui-button-rounded"
        [class.ui-button-primary]="tableMode==='buildings'" [class.ui-button-secondary]="tableMode!=='buildings'"
        title="View data for all of your buildings"></button>
    <button (click)="setTableMode('charts')" pButton type="button" label="charts" class="ui-button-rounded"
        [class.ui-button-primary]="tableMode==='charts'" [class.ui-button-secondary]="tableMode!=='charts'"
        title="View data as charts"></button>
    <div class="btn-group">
        <button type="button" class="btn btn-sm  btn-default dropdown-toggle rounded" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-filter"></i> {{tableFilter}} <span class="caret"></span>
        </button>
        <!--        <ul class="dropdown-menu">
            <li (click)="filter('setpoints')">setpoints</li>
            <li (click)="filter('telemetry')">telemetry</li>
            <li (click)="filter('all')">all</li>
        </ul>-->
    </div>

    <!--<button pButton disabled="true" type="button" label="gateways" class="ui-button-rounded" [class.ui-button-primary]="tableMode==='buildings'" [class.ui-button-secondary]="tableMode!=='buildings'"
        title="View data for all of your gateways" title="View data for all of your buildings"></button>-->
</div>
<div class="tables" *ngIf="dashboardService.history.length && tableMode !== 'map'">
    <app-dashboard-table [rows]="dashboardService.history" [mode]="tableMode">
    </app-dashboard-table>
</div>
<div *ngIf="tableMode === 'map'">
    <app-dashboard-map></app-dashboard-map>
</div>
<div class="please-wait" *ngIf="!dashboardService.history.length">
    <span *ngIf="!dashboardService.isAuthenticated">Connecting to the realtime service...<br />This page is currently
        not available for Internet Explorer</span>
    <span *ngIf="dashboardService.isAuthenticated">Realtime telemetry will appear here, waiting...</span>
</div>

<table class="table table-sm table-hover">
  <thead>
    <tr>
      <th> Assets </th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let asset of assets; let i = index">
      <td>{{asset.title}} </td>
      <td>
        <!-- <div class="pull-right"> <i (click)="gotoAsset(asset)" class="fa fa-chevron-right"></i></div> -->
      </td>
    </tr>
  </tbody>
</table>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceForTenants } from 'app/classes/service-for-tenant';
import { Tenant } from 'app/classes/tenant';
import { TenantService } from 'app/shared/tenant.service';
import { WindowService } from 'app/shared/window.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-dialog-tenant-edit',
  templateUrl: './dialog-tenant-edit.component.html',
  styleUrls: ['./dialog-tenant-edit.component.css']
})
export class DialogTenantEditComponent implements OnInit {


  @Input() tenant: Tenant;
  servicesForTenants: ServiceForTenants[];
  newServiceForTenantsId: number;
  isMobile: boolean;

  @Output() onClose = new EventEmitter<boolean>;

  constructor(public messageService: MessageService, private tenantService: TenantService, private windowService: WindowService) {


  }
  ngOnInit(): void {
    this.tenantService.getServices().then(services => this.servicesForTenants = services);
    this.isMobile = this.windowService.isMobile();
  }

  addService() {

  }

  newServiceForTenantsChanged(event: any) {
    if (!event?.value || !event?.originalEvent) {
      return;
    }

    console.log(this.newServiceForTenantsId);
    const service = this.servicesForTenants.find(service => service.id === this.newServiceForTenantsId);
    this.tenant.addServiceForTenants(service);
    this.newServiceForTenantsId = null;
  }

  cancel() {
    this.onClose.emit(false);
  }

  async submit() {
    if (!this.tenant.id) {
      const response = await this.tenantService.create(this.tenant);
      this.tenant.id = response.id;
    } else {
      await this.tenantService.update(this.tenant);
    }
    this.onClose.emit(true);
  }

}

@defer(when rule) {
<app-page-title [label]="rule.title" [canGoBack]="true" (click)="goBack()" [links]="_links()" (onLinkClick)="linkClick($event)"></app-page-title>

<div class="hero mt-1">
    <div class="chips">
        @if(assets.left) {
        <app-chip tooltipPosition="top" [label]="assets.left.gateway.site.title" pTooltip="Site" [canClick]="false" />
        <app-chip tooltipPosition="top" [label]="assets.left.gateway.title" pTooltip="Gateway" [canClick]="false" />
        }
    </div>
</div>

<app-theme-page-section *ngIf="rule.inAlert==='Y'" label="In Alarm, is {{rule.alarm?.state}}." [isWarning]="true">
    <app-rulesv3-item-alarm-control [rule]="rule" (onNoteAdded)="noteWasAdded($event)" (onStateChanged)="stateChanged($event)"></app-rulesv3-item-alarm-control>
</app-theme-page-section>

<div class="fourd section-title">Conditions</div>

<p *ngIf="hasNoConditions" class="red">This rule has no conditions and is disabled.</p>
<app-rulesv3-item-conditions [rule]="rule"></app-rulesv3-item-conditions>
@if(assets.left && limitLines) {
<div class="fourd section-title">Sensors</div>
@if(assets.right) {
<div>
    <app-setpoint-query view="compact" [showValue]="true" [assets]="[assets.left, assets.right]">
    </app-setpoint-query>
</div>
} @else {
<div>
    <app-setpoint-query view="compact" [showValue]="true" [assets]="[assets.left]" [limitLines]="limitLines" [fullscreen]="false">
    </app-setpoint-query>
</div>
}
}

<div class="fourd section-title">Notifications</div>
<app-rulesv3-item-contacts [rule]="rule"></app-rulesv3-item-contacts>

@if(rule.integration) {
<app-rulesv3-item-elogbooks-details [rule]="rule"></app-rulesv3-item-elogbooks-details>
}

<div class="fourd section-title">Alarms</div>

<app-rulesv3-item-history [rule]="rule" [assets]="[assets.left]"></app-rulesv3-item-history>

} @placeholder (minimum 100ms) {
Please wait...
}

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { APIService } from './api.service';
import { TrainingModule } from '../classes/training-module';

@Injectable({ providedIn: 'root' })
export class TrainingService {
    private timer: any;
    private timerSubscription: any;

    private _block: BehaviorSubject<any> = new BehaviorSubject(null);
    public block: Observable<any> = this._block.asObservable();

    modules: TrainingModule[];

    constructor(private apiService: APIService) {

        this.modules = [];
        this.modules.push(new TrainingModule(
            {
                id: 1,
                image: 'https://s3-eu-west-1.amazonaws.com/4d-training/module-list-item/module-list-item-introduction2.png',
                title: 'Introduction',
                video: '4d-introduction.mp4',
                body: 'An introduction to 4D and concepts.',
                state: 'current',
                viewedAt: null
            }
        ));

        this.modules.push(new TrainingModule(
            {
                id: 2,
                video: '4d-whatis4d.mp4',
                image: 'https://s3-eu-west-1.amazonaws.com/4d-training/module-list-item/module-list-item-sensors.png',
                title: 'What is 4D?',
                body: 'A brief introduction to the 4D Monitoring solution.',
                state: 'current',
                viewedAt: null
            })
        );

        this.modules.push(new TrainingModule(
            {
                id: 3,
                video: 'example.mp4',
                image: 'https://s3-eu-west-1.amazonaws.com/4d-training/module-list-item/module-list-item-installation.png',
                title: 'Example Data',
                body: 'Examples of the type of data that 4D can provide, and how that data is viewed through the 4D portal. ',
                state: 'current',
                viewedAt: null
            })
        );

        this.modules.push(new TrainingModule(
            {
                id: 4,
                video: 'howdoesitwork.mp4',
                image: 'https://s3-eu-west-1.amazonaws.com/4d-training/module-list-item/module-list-item-onboarding.png',
                title: 'How does it Work?',
                body: 'Having explained what 4D is, and what it does, we now look at how it actually works.',
                state: 'current',
                viewedAt: null
            })
        );

        this.modules.push(new TrainingModule(
            {
                id: 5,
                video: '4dbenefits.mp4',
                image: 'https://s3-eu-west-1.amazonaws.com/4d-training/module-list-item/module-list-item-onboarding.png',
                title: 'Benefits',
                body: 'Summary of 4D benefits.',
                state: 'current',
                viewedAt: null
            })
        );

        this.modules.push(new TrainingModule(
            {
                id: 6,
                video: '4dprepatingforinstallation.mp4',
                image: 'https://s3-eu-west-1.amazonaws.com/4d-training/module-list-item/module-list-item-onboarding.png',
                title: 'Preparing for Installation', body: 'The steps required to prepare for the installation of 4D Monitoring equipment.', state: 'current', viewedAt: null
            })
        );

        this.modules.push(new TrainingModule(
            {
                id: 7,
                video: '4d-installing-the-equipment.mp4',
                image: 'https://s3-eu-west-1.amazonaws.com/4d-training/module-list-item/module-list-item-onboarding.png',
                title: 'Installing 4D Equipment', body: 'Step-by-step guide to the 4D Gateway, MiFi Device, Satellite and Sensor installation. ', state: 'current', viewedAt: null
            })
        );

        this.modules.push(new TrainingModule(
            {
                id: 8,
                video: '4d-scanning-qr-code.mp4',
                image: 'https://s3-eu-west-1.amazonaws.com/4d-training/module-list-item/module-list-item-onboarding.png',
                title: 'Scanning the QR Code', body: 'Once the equipment is all installed, the on-boarding process begins with scanning the QR code on the Gateway.', state: 'current', viewedAt: null
            })
        );

        this.modules.push(new TrainingModule(
            {
                id: 9,
                video: '4d-system-configuration.mp4',
                image: 'https://s3-eu-west-1.amazonaws.com/4d-training/module-list-item/module-list-item-onboarding.png',
                title: 'System Configuration', body: 'Configure the equipment, once configured we see how it is deployed so that the 4D Monitoring is live.', state: 'current', viewedAt: null
            })
        );

        this.modules.push(new TrainingModule(
            {
                id: 10,
                video: '4d-training-completed.mp4',
                image: 'https://s3-eu-west-1.amazonaws.com/4d-training/module-list-item/module-list-item-onboarding.png',
                title: 'Training Completed!', body: 'Congratulations! You\'ve completed the training. This final video tells you what happens next. ', state: 'current', viewedAt: null
            })
        );

        this.modules.push(new TrainingModule(
            {
                id: 11,
                video: null,
                questionnaire: 1,
                image: 'https://s3-eu-west-1.amazonaws.com/4d-training/module-list-item/module-list-item-onboarding.png',
                title: 'Online post course evaluation questionnaire', body: '', state: 'current', viewedAt: null
            })
        );

    }

    getModule(moduleId: number): TrainingModule {
        let modules = this.modules.filter(v => v.id === +moduleId);

        return modules[0];
    }
}

@defer( when users) {
<header>
	<app-toolbar>
		<div class="right">
			@if(can.invite) {
			@if(userSelected()) {
			@if(userSelected().hasActivated) {
			@if(userSelected().isActive) {
			<!-- <app-button icon="mdi mdi-account-off" label="Disable user" (click)="disableUserToggle()"></app-button> -->
			} @else {
			<!-- <app-button icon="mdi mdi-account-off" label="Enable user" (click)="disableUserToggle()"></app-button> -->
			}
			}
			<app-button icon="mdi mdi-trash-can-outline" label="Delete user" (click)="deleteUser()"></app-button>
			@if(dirtyData) {
			<app-button icon="mdi mdi-check" label="Save changes" (click)="updateUser()" [isCTA]="true"></app-button>
			}
			} @else {
			<app-button icon="mdi mdi-account-plus" label="Invite" routerLink="/org/invite"></app-button>
			}
			} @else {
			<app-button icon="mdi mdi-account-plus" label="Invite" [isDisabled]="true" pTooltip="4D can allow access to this for you"></app-button>
			}
		</div>
	</app-toolbar>
</header>

@if(userSelected()) {
<app-page-title size="medium" [label]="userSelected().name" [canGoBack]="true" (click)="userSelected.set(null)"></app-page-title>
<app-org-user-details [user]="userSelected()" (dirtyChange)="onDataChange($event)" />
} @else {

<main>
	<table class="table table-striped" [class.table-hover]="is.admin">
		<thead>
			<tr>
				<th>Name</th>
				<th class="isDesktop">Email</th>
				<th class="isDesktop">Last Active</th>

				<th>Status</th>
				<th class="text-right isDesktop"> Sites</th>
			</tr>
		</thead>
		<tbody>
			@for(user of users;track user.id) {
			<tr [ngClass]="{'inactive': !user.isActive}" (click)="clickUserRow( user)">
				<td title="{{user.email}}">{{user.name}} <span class="staff-label" *ngIf="user.role==='admin'">4D
						Staff</span></td>
				<td class="isDesktop">{{user.email}}</td>
				<td>@if(user.updatedAt) {
					<span>{{user.updatedAt | amTimeAgo }}</span>
					}
				</td>
				<td class="isDesktop">
					<span>@if(user.isActive) { Active } @else { Not activated} </span>
				</td>
				<td class="text-right isDesktop"> {{user.siteCount}}</td>
			</tr>
			}
		</tbody>
	</table>
</main>
}
} @placeholder {
<app-please-wait></app-please-wait>
}

@if(wantsToDeleteUser) {
<app-dialog [header]="'Delete ' + userSelected().name" [hasToolbar]="false" [zIndex]="11" [dimensions]="{width:480, height:140}" [canSave]="false" [canScroll]="false" (onClose)="wantsToDeleteUser = false">
	<p class="mb-1">This action will remove the user permanently.</p>
	<app-toolbar [hasBackground]="false">
		<div class="right">
			<app-button icon="mdi mdi-delete" label="Delete user" (click)="deleteUser(true)" [isCTA]="false"></app-button>
			<app-button icon="mdi mdi-close" label="Cancel" (click)="wantsToDeleteUser = false" [isCTA]="false"></app-button>
		</div>
	</app-toolbar>
</app-dialog>
}

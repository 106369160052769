import { Component, OnInit, signal } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Asset } from 'app/classes/asset';
import { RulePackage } from 'app/classes/rule-service/rule-package';
import { ILink } from 'app/layout/page-title/page-title.component';
import { LimitLine } from 'app/setpoints/setpoint-query/setpoint-query.component';
import { APIService } from 'app/shared/api.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-rulesv3-item',
  templateUrl: './rulesv3-item.component.html',
  styleUrls: ['./rulesv3-item.component.css']
})
export class Rulesv3ItemComponent implements OnInit {

  rule: RulePackage;
  assets: { left: Asset, right: Asset } = { left: null, right: null };
  chartAssets: Asset[];
  hasNoConditions: boolean;
  limitLines: LimitLine[];
  _links = signal<any[]>(null);


  constructor(private apiService: APIService, private router: Router, private route: ActivatedRoute) {
    this.route.params.subscribe((params: Params) => {
      const id = params['ruleid'];
      this.getRulePackage(id);
    });
  }

  ngOnInit(): void {

  }

  linkClick(ev: ILink) {
    switch (ev.value) {
      case 'modify':
        this.modifyRuleClick();
        break;
      case 'setpoint':
        this.router.navigate(['/setpoints', this.rule.asset.id, this.rule.setpointRangeId]);
        break;
    }
    console.log(ev);

  }

  goBack() {
    this.router.navigate(['/rules']);
  }

  noteWasAdded(notes: string) {
    this.apiService.toastSuccess('Notes added', '');
    this.getRulePackage(this.rule.id);
  }

  modifyRuleClick() {
    this.router.navigate(['change'], { relativeTo: this.route });
  }

  stateChanged(state: string) {
    this.apiService.toastSuccess(`Alarm ${state}`, '');
    this.getRulePackage(this.rule.id);
  }

  /**
   * Build the helper lines for the sensor charts
   * @param rule 
   */
  buildLimitLines(rule: RulePackage) {
    const timeRange = rule.ruleTimeRanges[0];
    const restrictions = timeRange.startTimeAt ? timeRange.startTimeAt + ' to ' + timeRange.endTimeAt : 'all day';
    const ruleWords = restrictions;
    const dayOfWeek = timeRange.dayOfWeeks.length ? [false, false, false, false, false, false, false] : [true, true, true, true, true, true, true];
    const value = +(rule.conditions[0].rightAsset.value);
    //Monday is 0 in d3 chart
    // timeRange.dayOfWeeks.forEach(d => d === 0 ? dayOfWeek[6] = true : dayOfWeek[+d - 1] = true);
    timeRange.dayOfWeeks.forEach(d => dayOfWeek[+d] = true);

    const limitLine: LimitLine = {
      text: ruleWords,
      dayOfWeek,
      startTimeAt: timeRange.startTimeAt,
      endTimeAt: timeRange.endTimeAt,
      colour: 'red',
      value
    };

    this.limitLines = [limitLine];
  }

  getRulePackage(id: number) {
    this.apiService
      .getRulePackage(id)
      .then(rulePackage => {
        this.buildLimitLines(rulePackage);
        this.rule = rulePackage;

        if (this.rule.setpointRangeId) {
          this._links.set([{ label: 'Modify setpoint', icon: 'pencil', value: 'setpoint', type: 'button-sm' }]);
        } else {
          this._links.set([{ label: 'Modify rule', icon: 'pencil', value: 'modify', type: 'button-sm' }]);
        }

        if (rulePackage.conditions.length) {
          const condition = rulePackage.conditions[0];
          this.apiService
            .getAsset(condition.leftAsset.asset.id, { cache: false })
            .then(a => this.assets.left = a);
          if (condition.rightAsset.asset.id) {
            this.apiService
              .getAsset(condition.rightAsset.asset.id, { cache: false })
              .then(a => this.assets.right = a);
          }
        } else {
          // Must have conditions.
          this.hasNoConditions = true;
        }
      });
  }

  featureToggle() {
    this.apiService.user
      .pipe(take(1))
      .subscribe(u => {
        const c = u.prefs.rules_module_v || '';
        if (c === 3) {
          u.prefs.rules_module_v = '';
        } else {
          u.prefs.rules_module_v = 3;
        }
        this.router.navigate(['/rules' + u.prefs.rules_module_v]);
      });
  }
}

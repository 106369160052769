<article>
	<main>
		<section *ngIf="notifications?.length">
			<table class="table table-sm">
				<thead>
					<tr>
						<th class="text-left">Contact</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let user of notifications">
						<td>{{user.value}}</td>
					</tr>
				</tbody>
			</table>
		</section>
		<section *ngIf="groupings?.length">
			<table>
				<thead>
					<tr>
						<th>Group</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let group of groupings">
						<td>{{group.title}}</td>
					</tr>
				</tbody>
			</table>
		</section>
	</main>
</article>

<div class="sensor-overlay no-hero light" [ngClass]="{'in-progress': !!show}">
  <div class="sensor-internal-wrapper">
    <div class="sensor-title">
      <i class="{{icon}}"></i> {{title}}

      <div class="pull-right configure" *ngIf="_asset">
        <button class="btn btn-sm btn-primary" [routerLink]="['/','setpoints', _asset.id]">Configure setpoints</button>
        &nbsp;
      </div>

    </div>
    <div class="sensor-content" *ngIf="_asset">

      <div class="chart-wrapper">
        <app-asset-chart theme="white" [showPoints]="showPoints" [asset]="_asset" [setpoints]="setpoints"
          [startsAt]="startsAt" [endsAt]="endsAt"></app-asset-chart>
      </div>

      <div class="option-switch">
        <label class="switch">
          <input type="checkbox" name="typetoggle" [(ngModel)]="pagePreferences.showLiveFeed"
            (click)="toggle('showLiveFeed')">
          <span class="checkboxslider round"></span>
        </label><span class="hidden-xs">Live feed</span>
      </div>
      <div class="realtime-wrapper" *ngIf="iframeSrc && pagePreferences.showLiveFeed">
        <iframe #iframe (load)="iframeOnLoad($event)" allowscriptaccess="always"
          [src]="sanitizer.bypassSecurityTrustResourceUrl(iframeSrc)" width="100%" height="100%"
          allowfullscreen></iframe>
      </div>
    </div>
    <div class="sensor-close" (click)="close()"><i class="fa fa-times"></i></div>
  </div>
</div>

<app-please-wait *ngIf="!api || !assets"></app-please-wait>
<article *ngIf="api && assets">
	<div class="no-screen">
		<div class="print-header">
			<!--<img src="https://4d-org-logos.s3.eu-west-2.amazonaws.com/chp-logo.png" />-->
			<h1>4D API Documentation</h1>

		</div>
	</div>

	<section class="no-print text-right">
		<button pButton (click)="printPage()" label="Print Documentation" icon="mdi mdi-printer"></button>
	</section>
	<section>
		<h2>Overview</h2>
		<p>This documentation will enable access to data for <b>{{this.apiService.getUserOrg().title}}</b>.</p>
		<p>
			Return current or historic sensor (asset) values for individual
			assets, assets on a
			gateway or the entire catalog of assets.</p>
	</section>

	<section>
		<h2>Using the REST API</h2>

		<p>
			To request telemetry from 4D you'll need to supply the 4D API key in the header of your API call.
		</p>
		<app-theme-page-section>
			<table class="table table-sm">
				<thead>
					<tr>
						<th>Header Key</th>
						<th>Header Value</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>x-api-key</td>
						<td>{{api.apikey}}</td>
					</tr>
				</tbody>

			</table>
		</app-theme-page-section>
	</section>

	<section>
		<h2>Example asset response</h2>
		<p>Data is returned as json similar to the following</p>
		<pre>
&lcub;
 "id":12345,
 "createdAt":"2022-03-17T12:30:33.000Z",
 "uom":"°C",
 "category":"temperature",
 "value":"14.5"
&rcub;</pre>
	</section>

	<section>
		<h2>Request sites</h2>
		<app-dev-curl [api]="api" path="sites"></app-dev-curl>
	</section>

	<section>
		<h2>Request gateways</h2>
		<app-dev-curl [api]="api" path="gateways"></app-dev-curl>
	</section>

	<section *ngIf="assetSelected">
		<h2>Request latest telemetry for all assets</h2>
		<app-dev-curl [api]="api" path="assets"></app-dev-curl>
	</section>

	<section *ngIf="gatewaySelected">
		<h2>Request latest telemetry for all gateway assets</h2>
		<p>In this example we get the latest asset telemetry for asset id {{assetSelected?.id}}</p>
		<app-dev-curl [api]="api" path="assets?gateway_id={{gatewaySelected?.id}}"></app-dev-curl>
	</section>

	<section *ngIf="assetSelected">
		<h2>Request latest telemetry for an asset</h2>
		<p>In this example we get the latest asset telemetry for asset id {{assetSelected?.id}}</p>
		<app-dev-curl [api]="api" path="assets?id={{assetSelected?.id}}"></app-dev-curl>
	</section>

	<section *ngIf="assetSelected" class="page-break">
		<h2>Request telemetry for asset(s) in date range</h2>
		<p>In this example we get the the asset telemetry from yesterday to today, for asset id {{assetSelected?.id}}</p>

		<app-dev-curl [api]="api"
			path="assets?id={{assetSelected?.id}}&date_from={{yesterdayCurlDate}}&date_to={{todayCurlDate}}">
		</app-dev-curl>

		<p>You can include multiple assets, for example id={{assetSelected?.id}}&id={{assets[1]?.id}}</p>
		<app-dev-curl [api]="api"
			path="assets?id={{assetSelected?.id}}&id={{assets[1]?.id}}&date_from={{yesterdayCurlDate}}&date_to={{todayCurlDate}}">
		</app-dev-curl>

		<p>Return all assets by omitting id(s)</p>
		<app-dev-curl [api]="api" path="assets?date_from={{yesterdayCurlDate}}&date_to={{todayCurlDate}}">
		</app-dev-curl>

	</section>

	<section *ngIf="assetSelected" class="page-break">
		<h2>Request weather data</h2>
		<p>In this example we get all weather data for yesterday</p>

		<app-dev-curl [api]="api" path="weather?date_from={{yesterdayCurlDate}}&date_to={{yesterdayCurlDate}}">
		</app-dev-curl>

		<p>You can include multiple metobsids (found in /v2/sites), for example id={{siteSelected?.metobsid}}</p>
		<app-dev-curl [api]="api"
			path="weather?id={{siteSelected?.metobsid}}&date_from={{yesterdayCurlDate}}&date_to={{yesterdayCurlDate}}">
		</app-dev-curl>

	</section>

	<section class="pagebreakbefore">
		<h2>Unit of measures (uom)</h2>
		<table class="table table-sm">
			<thead>
				<tr>
					<td></td>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let uom of uoms">
					<td>{{uom.id}}</td>
					<td>{{uom.title}}</td>
				</tr>
			</tbody>
		</table>
	</section>

</article>

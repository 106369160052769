import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RuleAlarm } from "../../classes/rule-service/rule-alarm";
import { Alarm } from "../../classes/alarm";

@Component({
  selector: 'app-alarm-timeline',
  templateUrl: './alarm-timeline.component.html',
  styleUrls: ['./alarm-timeline.component.css']
})
export class AlarmTimelineComponent implements OnInit {

  @Input()
  alarm: Alarm;

  @Input()
  public set ruleAlarm(v : RuleAlarm) {
    this.alarm = new Alarm();
    this.alarm.timeline = v.timeline;
  }
  
  constructor() { }

  ngOnInit() {
  }

}

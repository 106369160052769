/**
 * Represent a viewbox
 * @version 1.0.0
 */
export class Viewbox {
    x: number;
    y: number;
    w: number;
    h: number;

    calculated: { x: number, y: number, w: number, h: number };

    zoomLevel: number;

    constructor(x: number, y: number, w: number, h: number) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
        this.reset();
    }

    panDirection(direction: 'left' | 'right' | 'up' | 'down') {
        const pw = this.w / 100;
        const ph = this.h / 100;

        const cx = pw * this.zoomLevel;
        const cy = ph * this.zoomLevel;

        switch (direction) {
            case 'left':
                this.calculated.x -= cx;
                break;
            case 'right':
                this.calculated.x += cx;
                break;
            case 'up':
                this.calculated.y -= cy;
                break;
            case 'down':
                this.calculated.y += cy;
                break;
        }
    }

    zoomInToZoomLevel(zoomLevel: number) {
        this.zoomLevel = zoomLevel;

        if (zoomLevel <= 0) {
            this.reset();
            return;
        }

        const pw = this.w / 10;
        const ph = this.h / 10;

        const w = this.w - (pw * zoomLevel);
        const h = this.h - (ph * zoomLevel);

        const x = this.x + ((pw * zoomLevel) * .5);
        const y = this.y + ((ph * zoomLevel) * .5);

        this.calculated = { x, y, w, h };
    }

    reset() {
        this.calculated = { x: this.x, y: this.y, w: this.w, h: this.h };
    }

    public toString = (): string => {
        return `${this.x} ${this.y} ${this.w} ${this.h}`;
    }
}

import { Component, signal } from '@angular/core';
import { DataForm } from 'app/layout/data-form/data-form.component';
import { APIService } from 'app/shared/api.service';
import { InsightsService } from 'app/shared/insights.service';

@Component({
  selector: 'app-aq-configure-rag',
  templateUrl: './aq-configure-rag.component.html',
  styleUrl: './aq-configure-rag.component.css'
})
export class AqConfigureRagComponent {

  view = signal<'item' | 'list'>('list');

  list = signal<any[]>([]);

  title: string = 'New RAG';
  id: number;

  types = [
    {
      id: "pm1", config: {
        measurement: 'µg/m3',
        alarms: {
          low: { action: { from: null, to: null }, warn: { from: null, to: null } },
          normal: { to: null, from: 10 },
          high: { warn: { from: 35, to: null }, action: { from: 10, to: 35 } }
        },
        title: 'PM1',
        description: "Particles <1 μm in size. Examples: dust, combustion particles*, bacteria and viruses. Exposure can trigger asthma and allergies, as well as cause irritation of eyes, ears, nose and throat. High levels can indicate problems with your filters or ventilation system."
      }
    }
    ,
    {
      id: "pm2.5", config: {
        measurement: 'μg/m3',
        alarms: {
          low: { action: { from: null, to: null }, warn: { from: null, to: null } },
          normal: { to: null, from: 10 },
          high: { warn: { from: 35, to: null }, action: { from: 10, to: 35 } }
        },
        title: 'PM2.5',
        description: "Particles <2.5 μm in size. Examples: pollen, spoors and other organic particles.  Exposure can trigger asthma and allergies, as well as cause irritation of eyes, ears, nose and throat. High levels can indicate problems with your filters or ventilation system."
      }
    },
    {
      id: "pm10", config: {
        measurement: 'μg/m3',
        alarms: {
          low: { action: { from: null, to: null }, warn: { from: null, to: null } },
          normal: { to: null, from: 10 },
          high: { warn: { from: 35, to: null }, action: { from: 10, to: 35 } }
        },
        title: 'PM10',
        description: "particles <10 μm in size. Examples: coarser fine dust and organic particles. Exposure can trigger asthma and allergies, as well as cause irritation of eyes, ears, nose and throat. High levels can indicate problems with your filters or ventilation system."
      }
    },
    {
      id: "co2", config: {
        measurement: 'ppm',
        alarms: {
          low: { action: { from: null, to: null }, warn: { from: null, to: null } },
          normal: { from: 800, to: null },
          high: { action: { from: 800, to: 1000 }, warn: { from: 1000, to: null } }
        },
        title: 'CO₂',
        description: "Carbon dioxide (CO₂) is an important consideration when it comes to comfort and productivity. Air with high levels of CO₂ can lead to difficulty concentrating, decreased cognitive ability, and fatigue. Typically, CO₂ levels outdoors are around 400 parts per million (ppm). Concentrations below 800 ppm are considered ideal for a healthy and productive workspace. To reduce your CO₂ levels increase space ventilation."
      }
    },
    {
      id: "humidity", config: {
        measurement: '%',
        alarms: {
          low: { action: { from: 25, to: 35 }, warn: { from: 25, to: null } },
          normal: { from: 30, to: 60 },
          high: { action: { from: 60, to: 70 }, warn: { from: 70, to: null } }
        },
        title: 'Humidity',
        description: "Humidity has a significant impact on comfort, respiratory health, and infectious disease transmission. Humidity levels between 30-60% are considered optimal. This range is recommended especially for those with allergies, asthma, or other respiratory illnesses. Maintaining humidity within these levels can also minimize the growth and spread of mold, viruses, and bacteria."
      }
    },
    {
      id: "temperature", config: {
        measurement: '°',
        alarms: {
          low: { action: { from: null, to: null }, warn: { from: 18, to: null } },
          normal: { from: 18, to: 25 },
          high: { action: { from: null, to: null }, warn: { from: 25, to: { from: null, to: null } } }
        },
        title: 'Temperature',
        description: "The temperature is an important component of occupant comfort and productivity. The optimal temperature is in the range of 18-25°C. An indoor temperature either above or below this range will reduce the overall indoor air quality rating."
      }
    },
    {
      id: "voc", config: {
        measurement: 'ppb',
        alarms: {
          low: { action: { from: null, to: null }, warn: { from: null, to: null } },
          normal: { from: 250, to: null },
          high: { action: { from: 250, to: 2000 }, warn: { from: 2000, to: null } }
        },
        title: 'VOC',
        description: "Total Volatile Organic Compounds (VOCs) are a diverse group of chemicals that are commonly found in the air in homes and offices. Common sources of VOCs include paints (such as formaldehyde), lacquers, cleaning supplies, furnishings, office equipment, glues, alcohol, and human breath. Moderate levels of exposure can cause headaches, fatigue, eye and throat irritation, and other symptoms that can affect comfort and concentration. In order to reduce the VOC levels, improve ventilation and identify and remove the potential sources."
      }
    }
  ];

  form: DataForm;
  isLocked: boolean;

  constructor(private insightsService: InsightsService, private apiService: APIService) {
    this.form = new DataForm({
      items: [{ label: 'Title', id: 'title', value: '', type: 'string' }]
    })

    this.getList();
  }

  getList() {
    this.insightsService.updateAQRAGConfigList().then(response => {
      this.list.set(response);
    });
  }

  updateItem(item: any) {
    this.id = item.id;
    this.isLocked = item.isLocked;
    this.form.setValue('title', item.title);
    const config = JSON.parse(item.config);
    this.types.forEach(type => {
      const item = config.find(c => c.id === type.id);
      if (item) {
        type.config.alarms = item.alarms;
      } else {
        console.log('could not find ', type.id);
      }
    });
    this.view.set('item');
  }

  createClick() {
    this.id = null;
    this.isLocked = false;
    this.form.setValue('title', '');
    this.view.set('item');
  }

  clearClick() {
    this.types.forEach(type => {
      type.config.alarms = {
        low: { action: { from: null, to: null }, warn: { from: null, to: null } },
        normal: { from: null, to: null },
        high: { action: { from: null, to: null }, warn: { from: null, to: { from: null, to: null } } }
      };
    });
  }

  cancelClick() {
    this.id = null;
    this.form.setValue('title', '');
    this.view.set('list');
  }

  async deleteClick() {
    if (!this.id) {

      return;
    }
    this.apiService.toastSuccess('Removing RAG config...', null, 1000);
    await this.insightsService.deleteAQRAGConfigItem(this.id);
    this.apiService.toastSuccess('Removed RAG config.', null, 2000);
    this.getList();
    this.view.set('list');
  }

  async save() {
    const title = <string>this.form.getValue('title');
    if (title.length === 0) {
      this.apiService.toastWarn('Please enter a title.', '');
      return false;
    }
    await this.insightsService.updateAQRAG(this.id, title, this.types.map(type => {
      return { alarms: type.config.alarms, id: type.id };
    }));

    return true;
  }

  async updateClick() {
    const didSave = await this.save();
    if (didSave) {
      this.getList();
      this.view.set('list');
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Site } from 'app/classes/site';
import { APIService, IPostQuery } from 'app/shared/api.service';
import { SiteFloorplanDashboard } from "app/classes/site-floorplan-dashboard";

import moment from "moment";
import { DateService } from 'app/shared/date.service';
import { OccupancyService } from 'app/shared/occupancy.service';

@Component({
  selector: 'app-spd-hourly',
  templateUrl: './spd-hourly.component.html',
  styleUrls: ['./spd-hourly.component.css']
})
export class SpdHourlyComponent implements OnInit {
  occupancy: any;
  assets: { collection?: any, collOfDaysId?: number, floor: { t: string, v: number }, hours?: { i: number, d: Date, v: number }[], hoursRestricted?: { i: number, d: Date, v }, i: number, t: string, title?: string }[];
  // The date the data is for
  dt: Date;
  dtAsText: string;

  isLoading: boolean;

  @Input()
  dashboard: SiteFloorplanDashboard;

  @Input()
  site: Site;

  @Input()
  public set date(d: Date) {
    if (this.dateFrom) {
      this.dateFrom = d;

    } else {
      this.dateFrom = d;
    }
    if (d) {
      this.setDate(d);
    }

  }

  dateFrom: Date;

  canGoNext: boolean;
  canGoNextWeek: boolean;

  dataView: '24hours' | 'restricted' = 'restricted';

  pins: { date: number } = { date: null };

  collections: any[];

  noDataExists: boolean;

  constructor(private apiService: APIService, private dateService: DateService, private occupancyService: OccupancyService) { }

  ngOnInit(): void {

  }

  togglePin(key: 'date' = 'date') {
    switch (key) {
      case 'date':
        if (this.pins[key]) {
          this.pins.date = null;
        } else {
          this.pins.date = +this.dt;
        }
        break;
    }

    localStorage.setItem(`spd-hourly:pins`, JSON.stringify(this.pins));
  }

  printPage() {
    window.print();
  }

  toggleAllDay() {
    switch (this.dataView) {
      case '24hours':
        this.dataView = 'restricted';
        break;
      case 'restricted':
        this.dataView = '24hours';
        break;
    }
    this.get(this.dt);
  }

  get(dt: Date, id?: number[] | number) {
    this.noDataExists = false;
    if (!id) {
      id = this.dashboard.current.map(c => c.asset.id);
    }
    this.isLoading = true;
    const payload: IPostQuery = {
      t: 'occupancy',
      action: 'occupancy-day-by-hour',
      df: this.apiService.dbDate(dt),
      dt: this.apiService.dbDate(dt),
      id: id
    };

    this.apiService.postQuery(payload)
      .then(r => {

        this.occupancy = r;
        this.assets = [];
        if (!r) {
          // No data!
          this.isLoading = false;
          this.noDataExists = true;
          return;
        }
        const assetIds: number[] = r.map(r => r.i);

        for (let assetIndex = 0; assetIndex < r.length; assetIndex++) {
          const data = [];
          const asset = r[assetIndex];
          const assetId = +asset.i;
          const hours = asset.hours;
          for (let hour = 0; hour < hours.length; hour++) {
            const d = new Date(dt.getFullYear() + '-' + ('00' + (dt.getMonth() + 1)).slice(-2) + '-' + ('00' + dt.getDate()).slice(-2) + ' ' + ('00' + hour).slice(-2) + ':00:00');
            //  dateString + ' ' + ('00' + hour).slice(-2) + ':00:00'
            if (d > new Date()) {
              data.push({
                v: null,
                i: hour,
                d
              });
            } else {
              data.push({
                v: hours[hour].summary.minutes,
                i: hour,
                d
              });
            }
          }
          const dashboardAsset = this.dashboard.current.find(a => a.asset.id === assetId);
          let title = dashboardAsset.shape.title || dashboardAsset.asset.title;


          this.assets.push({ i: assetId, floor: { t: dashboardAsset.plan.title, v: dashboardAsset.plan.floor }, t: title, hours: data });
        }
        this.assets.sort((a, b) => a.t > b.t ? -1 : 1);
        this.assets.sort((a, b) => a.floor.v > b.floor.v ? 1 : -1);

        if (this.dataView === 'restricted') {
          this.injectRestrictedHours(assetIds, dt);
        } else {
          this.isLoading = false;
        }

      });
  }

  injectRestrictedHours(assetIds: number[], dt: Date) {
    const dayOfWeek = dt.getDay() > 0 ? dt.getDay() - 1 : 6;
    this.occupancyService.getHourlyForAsset(assetIds, dt).then(response => {
      if (!response) {
        this.noDataExists = true;
        this.isLoading = false;
        return;
      }
      const hourlyAssets = response.assets;
      this.collections = response.collections;
      console.log('OCCUPANCY_HOURS', hourlyAssets);
      const ids = Object.keys(hourlyAssets);
      for (const assetId of ids) {
        let asset = this.assets.find(a => a.i === +assetId);
        const restrictedHours: { c: number, h: number, d: number }[] = hourlyAssets[assetId].hours;
        const collectionId = restrictedHours[0].c;
        const collectionDayRowForAsset = this.collections.find(c => c.i === collectionId).days.find(dayList => dayList.days[dayOfWeek]);
        if (!asset) {
          // Asset does not exist but was retrieved from restricted hours
          asset = { i: +assetId, floor: { t: '???', v: 0 }, hours: [], t: '???' };
          this.assets.push(asset);
        }
        asset.collOfDaysId = collectionId;
        asset.collection = { id: collectionId, dayRow: collectionDayRowForAsset, startHour: collectionDayRowForAsset.startTime, endHour: collectionDayRowForAsset.endTime };

        asset.hours = restrictedHours.map(hour => { return { i: null, d: null, v: hour.d } });
        asset.title = hourlyAssets[assetId].title;
        this.isLoading = false;
      }
    });
  };

  nextWeek() {
    if (!this.canGoNextWeek) {
      return;
    }
    this.setDate(moment(this.dt).add(1, 'week').toDate());
  }

  previousWeek() {
    this.setDate(moment(this.dt).subtract(1, 'week').toDate());
  }


  next() {
    if (!this.canGoNext) {
      return;
    }
    this.setDate(moment(this.dt).add(1, 'day').toDate());
  }

  previous() {
    this.setDate(moment(this.dt).subtract(1, 'day').toDate());
  }

  today() {
    this.setDate(moment().toDate());
  }

  setDate(dt: Date) {
    this.dt = dt;
    this.dtAsText = moment(dt).format('dddd') + ' ' + DateService.date(dt);
    this.get(this.dt);
    if (DateService.date(dt) === DateService.date(new Date())) {
      this.canGoNext = false;
    } else {
      this.canGoNext = true;
    }

    if (moment(dt).add(1, 'week') > moment(new Date())) {
      this.canGoNextWeek = false;
    } else {
      this.canGoNextWeek = true;
    }
  }


}

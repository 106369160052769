import { Org } from "./org";
import { IDatesFromTo } from "app/interfaces/dates-from-to";
import { IMinMax } from "app/interfaces/min-max";
export class VisualiseItem {
	id: number;
	title: string;
	category: VisualiseCategory;
	org: Org;
	dataset: { label: string };

	// data config
	dataType: VisualiseDataType;
	minmax: IMinMax;
	isPercentage: boolean;
	dates: IDatesFromTo;

	// data values
	rows: any[];

	// Preferred Size
	height: string;

	constructor(data?: any) {
		if (!data) {

		} else {
			this.id = data.id;
			this.title = data.title;
			this.category = data.category;
			this.dataType = data.dataType;
			this.isPercentage = data.isPercentage;
			this.dates = data.dates || { from: data.dateFrom, to: data.dateTo };
			this.rows = data.rows;
			this.org = new Org({ id: data.org_id || data.orgId, title: data.orgTitle });
			this.dataset = data.dataset;
			this.minmax = data.minmax || { min: data.min, max: data.max };
			this.height = data.height;
		}
	}
}

export type VisualiseCategory = 'occupancy';
export type VisualiseDataType = 'rows' | 'number';

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComplianceItem } from 'app/classes/compliance/compliance-item';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-org-compliance-add',
  templateUrl: './org-compliance-add.component.html',
  styleUrls: ['./org-compliance-add.component.css']
})
export class OrgComplianceAddComponent implements OnInit {
  item: ComplianceItem = new ComplianceItem();

  @Output()
  hasSaved: EventEmitter<ComplianceItem> = new EventEmitter<ComplianceItem>();

  constructor(private apiService: APIService, private activatedRoute: ActivatedRoute, private router: Router) {

    this.item = new ComplianceItem();

  }

  ngOnInit(): void {
  }

  save() {
    this.apiService
      .postComplianceConfig(this.item)
      .then(r => this.hasSaved.emit(this.item));
  }
}

import { Component, OnInit, input, output, effect } from '@angular/core';

@Component({
  selector: 'app-telemetry-list-legend',
  templateUrl: './telemetry-list-legend.component.html',
  styleUrls: ['./telemetry-list-legend.component.css']
})
export class TelemetryListLegendComponent implements OnInit {

  ragCounts = input<IRagCounts>();

  ragSelected = output<'green' | 'amber' | 'red' | 'none'>();

  constructor() {
    effect(() => {
      console.log(this.ragCounts(), 'changed');
    });
  }

  ngOnInit(): void {

  }

  clickRag(rag: 'green' | 'amber' | 'red' | 'none') {
    if (this.ragCounts()[rag]) {
      this.ragSelected.emit(rag);
    }
  }

}


export interface IRagCounts {
  red: number;
  amber: number;
  green: number;
  none: number;
}

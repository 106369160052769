<P>
  <br>
  <B>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE</B>
</P>
<P>
  <B>What&rsquo;s in these terms?</B>
</P>
<P>These terms tell you the rules for using our website
  <a href="https://portal.4dml.com">https://portal.4dml.com</a> (
  <B>&ldquo;our site&rdquo;</B>).
</P>

<P>
  <a href="https://portal.4dml.com">https://portal.4dml.com</a> is a site operated by 4D Monitoring Limited
  (&ldquo;<B>We</B>&rdquo;). We are registered in England and Wales under company number 09839120 and have our
  registered office at
  LDH House, Parsons Green, St. Ives, Cambridgeshire PE27 4AA which is also our main trading address.
</P>
<P>To contact us, please email
  <B>askus&#64;4dml.com</B>.
</P>
<p>
  <br>
</p>
<P>
  <B>By using our site you accept these terms</B>
</P>
<P>By using our site, you confirm that you accept these terms of use and you agree to comply with them.
</P>
<P>If you do not agree to these terms, you must not use our site.</P>
<P>We recommend that you print a copy of these terms for future reference.</P>
<P>
  <BR>
</P>
<P>
  <B>There are other terms that may apply to you</B>
</P>
<P>These terms of use refer to the following additional terms, which also apply to your use of our site:
</P>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">
      <A HREF="/policies/privacy">Our Privacy Policy</A>.
    </P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">
      <A HREF="/policies/acceptance">
        Our Acceptable Use Policy</A>, which sets out the permitted uses and prohibited uses of our site. When using our
      site, you must comply with this Acceptable Use Policy.
    </P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">
      <A HREF="/policies/cookies">Our Cookie Policy</A>, which sets out information about the cookies on
      our site.
    </P>
</UL>
<P>
  <BR>

</P>
<P>
  <B>Our site and our terms and conditions
  </B>
</P>
<P>We amend these terms from time to time. Every time you wish to use our site, please check these terms
  to ensure you understand
  the terms that apply at that time. We may update and change our site from time to time or suspend or withdraw our
  site.
  Our side it made available free of charge.</P>
<P STYLE="margin-bottom: 0.42cm; page-break-before: always">
  <BR>

</P>
<P>We do not guarantee that our site, or any content on it, will always be available or be
  uninterrupted. We may suspend or
  withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will try
  to give you reasonable notice of any suspension or withdrawal.</P>
<P>
  <BR>

</P>
<P>You are also responsible for ensuring that all persons who access our site through your internet
  connection are aware of
  these terms of use and other applicable terms and conditions, and that they comply with them.</P>
<P>
  <BR>

</P>
<P>
  <B>You must keep your account details safe</B>
</P>
<P>If you choose, or you are provided with, a user identification code, password or any other piece of
  information as part of
  our security procedures, you must treat such information as confidential. You must not disclose it to any third party.
</P>
<P>We have the right to disable any user identification code or password, whether chosen by you or
  allocated by us, at any time,
  if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</P>
<P>If you know or suspect that anyone other than you knows your user identification code or password,
  you must promptly notify
  us at askus&#64;4dml.com.</P>
<P>
  <BR>
</P>
<P>
  <B>How you may use material on our site </B>
</P>
<P>We are the owner or the licensee of all intellectual property rights in our site, and in the material
  published on it. Those
  works are protected by copyright laws and treaties around the world. All such rights are reserved.</P>
<P>You may print off one copy, and may download extracts, of any page(s) from our site for your personal
  use and you may draw
  the attention of others within your organisation to content posted on our site.</P>
<P>You must not modify the paper or digital copies of any materials you have printed off or downloaded
  in any way, and you must
  not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying
  text.</P>
<P>Our status (and that of any identified contributors) as the authors of content on our site must
  always be acknowledged.
</P>
<P>You must not use any part of the content on our site for commercial purposes without obtaining a
  licence to do so from us
  or our licensors.</P>
<P>If you print off, copy or download any part of our site in breach of these terms of use, your right
  to use our site will
  cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</P>
<P>
  <BR>
</P>
<P STYLE="margin-bottom: 0.42cm; page-break-before: always">
  <B>Do not rely on information on this site</B>
</P>
<P>The content on our site is provided for general information only. It is not intended to amount to
  advice on which you should
  rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of
  the
  content on our site.</P>
<P>Although we make reasonable efforts to update the information on our site, we make no
  representations, warranties or guarantees,
  whether express or implied, that the content on our site is accurate, complete or up to date.</P>
<P>
  <BR>
</P>
<P>
  <B>We are not responsible for websites we link to</B>
</P>
<P>Where our site contains links to other sites and resources provided by third parties, these links are
  provided for your information
  only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain
  from
  them.
</P>
<P>We have no control over the contents of those sites or resources.</P>
<P>
  <BR>
</P>
<P>
  <B>User-generated content is not approved by us</B>
</P>
<P>This website may include information and materials uploaded by other users of the site, including to
  bulletin boards and
  chat rooms. This information and these materials have not been verified or approved by us. The views expressed by
  other
  users on our site do not represent our views or values.</P>
<P>If you wish to complain about information and materials uploaded by other users please <A HREF="https://4dmonitoring.co.uk/contact-us/">contact us</A>.
</P>
<P>
  <BR>
</P>
<P>
  <B>Our responsibility for loss or damage suffered by you</B>
</P>
<P>Whether you are a consumer or a business user:</P>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">We do not exclude or limit in any way our liability to you where
      it would be unlawful to do so. This includes liability
      for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors
      and for fraud or fraudulent misrepresentation.
    </P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Different limitations and exclusions of liability will apply to
      liability arising as a result of the supply of any products
      or services to you, which will be set out in our any contract which we enter into with you.</P>
  </LI>
</UL>
<P>
  <B>If you are a business user</B>:
</P>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">We exclude all implied conditions, warranties, representations or
      other terms that may apply to our site or any content
      on it.</P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">We will not be liable to you for any loss or damage, whether in
      contract, tort (including negligence), breach of statutory
      duty, or otherwise, even if foreseeable, arising under or in connection with:</P>
  </LI>
</UL>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm">use of, or inability to use, our site; or</P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm">use of or reliance on any content displayed on our site.</P>
  </LI>
</UL>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">In particular, we will not be liable for:</P>
  </LI>
</UL>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm">loss of profits, sales, business, or revenue;</P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm">business interruption;</P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm">loss of anticipated savings;</P>
  </LI>

  <LI>
    <P STYLE="margin-bottom: 0cm">loss of business opportunity, goodwill or reputation; or</P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm">any indirect or consequential loss or damage.</P>
  </LI>
</UL>
<P>
  <B>If you are a consumer user</B>:
</P>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Please note that we only provide our site for domestic and
      private use. You agree not to use our site for any commercial
      or business purposes, and we have no liability to you for any loss of profit, loss of business, business
      interruption,
      or loss of business opportunity.</P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">If defective digital content that we have supplied, damages a
      device or digital content belonging to you and this is
      caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation.
      However,
      we will not be liable for damage that you could have avoided by following our advice to apply an update offered to
      you free of charge or for damage that was caused by you failing to correctly follow installation instructions or
      to
      have in place the minimum system requirements advised by us.</P>
  </LI>
</UL>
<P STYLE="margin-left: 1.27cm; margin-bottom: 0cm">
  <BR>
</P>
<UL>
  <P STYLE="margin-bottom: 0cm; line-height: 0.53cm"></P>
</UL>
<P>
  <B>How we may use your personal information
  </B>
</P>
<P>We will only use your personal information as set out in our
  <U>
    <A HREF="/policies/privacy">Privacy Policy</A>
  </U>
</P>
<P>
  <BR>
</P>
<P>
  <B>Uploading content to our site</B>
</P>
<P>Whenever you make use of a feature that allows you to upload content to our site, or to make contact
  with other users of
  our site, you must comply with the content standards set out in our Acceptable Use Policy
  <U>
    <A HREF="/policies/acceptance">Acceptance Policy</A>.
  </U>
</P>
<P>You warrant that any such contribution does comply with those standards, and you will be liable to us
  and indemnify us for
  any breach of that warranty. This means you will be responsible for any loss or damage we suffer as a result of your
  breach
  of warranty.
</P>
<P>We also have the right to disclose your identity to any third party who is claiming that any content
  posted or uploaded by
  you to our site constitutes a violation of their intellectual property rights, or of their right to privacy.</P>
<P>We have the right to remove any posting you make on our site if, in our opinion, your post does not
  comply with the content
  standards set out in our Acceptable Use Policy

  <U>
    <A HREF="/policies/acceptance">Acceptance Policy</A>
  </U>

</P>
<P>You are solely responsible for securing and backing up your content.</P>
<P>
  <BR>

</P>
<P>
  <B>Rights you are giving us to use material you upload</B>
</P>
<P>When you upload or post content to our site, you grant us the right to use the material on our site
  and to other users of
  our site or to third parties.</P>
<P>
  <BR>

</P>
<P>
  <B>We are not responsible for viruses and you must not introduce them</B>
</P>
<P>We do not guarantee that our site will be secure or free from bugs or viruses.</P>
<P>You are responsible for configuring your information technology, computer programmes and platform to
  access our site. You
  should use your own virus protection software.</P>
<P>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other
  material that is malicious
  or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site
  is stored or any server, computer or database connected to our site. You must not attack our site via a
  denial-of-service
  attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence
  under
  the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will
  co-operate
  with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site
  will
  cease immediately.</P>
<P>
  <BR>

</P>
<P>
  <B>Rules about linking to our site</B>
</P>
<P>You may link to our home page, provided you do so in a way that is fair and legal and does not damage
  our reputation or take
  advantage of it.</P>
<P>You must not establish a link in such a way as to suggest any form of association, approval or
  endorsement on our part where
  none exists.</P>
<P>You must not establish a link to our site in any website that is not owned by you.</P>
<P>Our site must not be framed on any other site, nor may you create a link to any part of our site
  other than the home page.
</P>
<P>We reserve the right to withdraw linking permission without notice.</P>
<P>The website in which you are linking must comply in all respects with the content standards set out
  in our Acceptable Use
  Policy

  <U>
    <A HREF="/policies/acceptance">Acceptance Policy</A>
  </U>

</P>
<P>If you wish to link to or make any use of content on our site other than that set out above, please
  contact

  <U>
    <A HREF="mailto:askus@4dml.com">askus&#64;4dml.com</A>.
  </U>

</P>
<P STYLE="margin-bottom: 0.42cm; page-break-before: always">
  <B>Which country&rsquo;s laws apply to any disputes?</B>
</P>
<P>If you are a consumer, please note that these terms of use, their subject matter and their formation,
  are governed by English
  law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction except that if you
  are
  a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of
  Scotland,
  you may also bring proceedings in Scotland.</P>
<P>If you are a business, these terms of use, their subject matter and their formation (and any
  non-contractual disputes or
  claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.
</P>
<P>
  <BR>

</P>
<P>
  <B>Our trade marks are registered</B>
</P>
<P>You are not permitted to use any of our Trade Marks without our approval.</P>
<DIV TYPE=FOOTER>
  <P ALIGN=CENTER STYLE="margin-top: 1.17cm; margin-bottom: 0cm">

  </P>
  <P STYLE="margin-bottom: 0cm">
    <BR>
  </P>
  <P ALIGN=CENTER STYLE="margin-bottom: 0cm">
    <BR>
  </P>
</DIV>

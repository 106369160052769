<p-dropdown
  [options]="adminAnnotations"
  [(ngModel)]="selectedAnnotation"
  optionLabel="annotation"
  [filter]="false"
  (onChange)="selected($event)"
  filterBy="annotation"
  [showClear]="false"
  placeholder="Common annotations">
  <ng-template pTemplate="selectedItem">
    <div *ngIf="selectedAnnotation">
      <div><i class="mdi mdi-checkbox-blank-circle" class="{{ selectedAnnotation.rag }}"></i> {{ selectedAnnotation.text }}</div>
    </div>
  </ng-template>
  <ng-template let-annotation pTemplate="item">
    <div>
      <div>
        <i class="mdi mdi-checkbox-blank-circle" class="{{ annotation.rag }}"></i>
        {{ annotation.text }}
      </div>
    </div>
  </ng-template>
</p-dropdown>

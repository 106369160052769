<article class="content-container">
  <main [class.asset-selected]="selectedAsset" [class.site-selected]="defaultSite !== null">
    <section class="master">
      <header>
        Sites
        @if (isLoadingSites) {
          <i class="mdi mdi-loading mdi-spin-x2"></i>
        }
      </header>

      <div class="sites">
        @for (site of sites(); track site.id) {
          <div (click)="siteClick(site)" [class.active]="site.id === defaultSite?.id">
            <span [class.smaller]="site.title.length > 30" [class.x2]="site.title.length > 38" [class.x3]="site.title.length >= 44">{{ site.title }}</span>
          </div>
        }
      </div>
    </section>

    <section class="child" #top>
      <header>
        <app-telemetry-list-search [searchFor]="searchFor" (onSearchChanged)="searchChanged($event)" [resultCount]="filterCount()"></app-telemetry-list-search>
        <app-telemetry-list-legend [ragCounts]="ragCounts()" (ragSelected)="ragSelected($event)"></app-telemetry-list-legend>
      </header>

      @if (breadcrumb.length) {
        <div class="crumbs">
          <span (click)="crumbClick()" pTooltip="" tooltipPosition="bottom"><i class="mdi" [class.mdi-home-outline]="!isMobile" [class.mdi-arrow-left]="isMobile"></i> <i class="mdi" [class.mdi-chevron-right]="!isMobile"></i></span>
          @for (crumb of breadcrumb; track crumb.label; let i = $index) {
            <span class="crumb" (click)="crumbClick(i)">
              {{ crumb.label }}
              @if (isLoadingAssetsForSite) {
                <i class="mdi mdi-loading mdi-spin-x2"></i>
              }
            </span>
          }
        </div>
      }
      <div>
        @if (!selectedAsset) {
          <div class="ml-half mr-half telemetry-toolbar view-{{ view() }}">
            <app-toolbar size="small">
              <div class="right">
                @if (view() === 'list') {
                  @if (false) {
                    <app-button size="small" label="chart" icon="mdi mdi-chart-areaspline-variant" />
                  } @else {
                    @if (isAdmin()) {
                      <app-button size="small" label="select assets" pTooltip="Select assets to perform an action (4D Admins only see this)" (click)="startSelectingAssets()" icon="mdi mdi-checkbox-multiple-blank" />
                    }
                  }

                  <span class="dim"> | </span>
                }
                <span tooltipPosition="left" class="mdi mdi-format-list-bulleted-square vlist" pTooltip="table view" (click)="view.set('list')"></span>
                <span tooltipPosition="left" class="mdi mdi-cards-variant vcard" pTooltip="card view" (click)="view.set('card')"></span>
              </div>
            </app-toolbar>
          </div>
          @switch (view()) {
            @case ('list') {
              @if (filterResults) {
                <div class="ml-1 mr-1 mt-1">
                  <app-telemetry-table [gatewaysBySite]="filterResults" (onAssetSelected)="telemetryTableAssetSelected($event)" />
                </div>
              }
            }
            @default {
              <div class="telemetry mt-1">
                @for (gatewayAssets of filterResults; track $index; let gi = $index) {
                  <div class="gateway-container" (click)="gatewayHeaderClick(gi)">
                    <div [ngClass]="{ 'gateway-is-offline': gatewayAssets[0].gatewayOnline === 'offline' }" title="{{ gatewayAssets[0].gatewayOnline }}" class="gateway-title flex">
                      <div>
                        <i
                          style="font-style: normal; font-size: 14px; padding-top: 2px"
                          [ngClass]="{
                            'status-online': gatewayAssets[0].gatewayOnline === 'online',
                            'status-standby': gatewayAssets[0].gatewayOnline === 'standby',
                            'status-offline': gatewayAssets[0].gatewayOnline === 'offline'
                          }">
                          <span>{{ gatewayAssets[0].gatewayOnline === 'online' ? 'online' : gatewayAssets[0].gatewayOnline === 'offline' ? 'offline' : 'standby' }}</span>
                        </i>
                      </div>
                      <div class="flex-1">
                        {{ gatewayAssets[0].gatewayTitle }}
                      </div>
                      <div>
                        <i class="mdi" [class.mdi-chevron-up]="!gatewaysHiddenAsObject[gatewayAssets[0].gatewayId]" [class.mdi-chevron-down]="gatewaysHiddenAsObject[gatewayAssets[0].gatewayId]"></i>
                      </div>
                    </div>
                    @if (!gatewaysHiddenAsObject[gatewayAssets[0].gatewayId]) {
                      <div class="gateway-assets">
                        @for (assetValue of gatewayAssets; track assetValue; let i = $index) {
                          <div class="telemetry-asset-container">
                            <app-dashboard-telemetry-asset [asset]="assetValue" (onAssetClick)="showTelemetry(assetValue)" />
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
                @if (results?.length === 0) {
                  <div>No telemetry available.</div>
                }
              </div>
            }
          }
        } @else {
          <div class="asset-view">
            <app-asset-viewer [assetId]="selectedAsset.assetId" (hasLoaded)="assetLoaded($event)" [wantsDetails]="false" />
          </div>
        }
      </div>
    </section>
  </main>
</article>

<article>
	<div class="tabview-list">
		<div [class.active]="tabIndex()===0" (click)="tabIndex.set(0)">
			<i class="mdi mdi-pen"></i>Configuration
		</div>
		<div [class.active]="tabIndex()===1" (click)="tabIndex.set(1)">
			<i class="mdi mdi-view-list"></i>Assets
		</div>
		<div></div>
	</div>
	<div class="tabview-content">

		@switch(tabIndex()) {
		@case(1) {
		<div class="tabview-list asset" *ngIf="asset()">
			<div [class.active]="assetTabIndex()==0" (click)="assetTabIndex.set(0)">List</div>
			<div [class.active]="assetTabIndex()==1" (click)="assetTabIndex.set(1)">Asset</div>
			<div></div>
		</div>
		@switch(assetTabIndex()) {
		@case(0) {
		<header>
			<app-toolbar>
				<div class="right">
					<app-button icon="mdi mdi-cog" label="Rebuild 0 Day assets" (click)="rebuildAll()"></app-button>
					<app-button icon="mdi mdi-database-search-outline" label="Analyse assets" (click)="getExtendedData()"></app-button>
					<app-button icon="mdi mdi-link" label="Re-Link assets connected" (click)="rebuildAssetsLinked()"></app-button>
				</div>
			</app-toolbar>
		</header>
		<main class="assets">
			<table class="table table-hover" aria-label="Assets">
				<thead>
					<tr>
						<th colspan="2">Asset</th>
						<th>Gateway</th>
						<th>Site</th>
						<th>Updated</th>
						<th class="text-right">Value</th>
						<th class="text-right" pTooltip="Days collected" tooltipPosition="left">Days</th>
					</tr>
				</thead>
				<tbody>
					@for(asset of collection.assets;track asset.id) {
					<tr (click)="clickAsset(asset)">
						<td>{{asset.id}}</td>
						<td>{{asset.title}}</td>
						<td>{{asset.gTitle}}</td>
						<td>{{asset.sTitle}}</td>
						<td>{{asset.updatedAt | date:'dd/MM/YY HH:mm' }}</td>
						<td class="text-right ">{{asset.value}}</td>
						<td class="text-right">{{asset.dayCount}}</td>
					</tr>
					}
				</tbody>
			</table>
		</main>
		}
		@case(1) {
		<div class="setpoint-container">
			<app-setpoint-query [asset]="asset()" />
		</div>
		}
		}

		}
		@case(0) {
		<main>
			<section>
				<div class="form-group">
					<label for="title">Occupancy Days Title</label>
					<input id="title" name="title" maxlength="50" class="form-control" [(ngModel)]="collection.title">
				</div>
			</section>

			<section class="existing">
				<div class="header" *ngIf="collection.days.length">
					<div>Day of week</div>

					<div>Start</div>
					<div>End</div>
					<div class="duration">Duration</div>
					<div></div>
				</div>
				<div class="header" *ngIf="!collection.days.length">
					Add some days to monitor occupancy between times within those days.
				</div>

				<div *ngFor="let dayRow of collection.days;let i = index" (click)="daysClick(dayRow)" class="day-row-clickable">
					<div class="day-spans">
						<span *ngFor="let day of days;let i = index" [class.selected]="dayRow.days[i]">
							<i class="mdi" [class.mdi-checkbox-marked-outline]="dayRow.days[i]" [class.mdi-checkbox-blank-outline]="!dayRow.days[i]"></i> {{day}}
						</span>
					</div>

					<div>{{dayRow.startTime}}</div>
					<div>{{dayRow.endTime}}</div>
					<div class="duration">{{dayRow.duration}}</div>
					<div><i class="mdi mdi-trash-can" pTooltip="Delete this row" (click)="deleteRow(i)"></i></div>
				</div>
			</section>

			<section class="new-days" *ngIf="newDays">
				<div class="new">
					<div class="days">
						<label>Day of Week</label>
						<div class="day-spans">
							<span *ngFor="let day of days;let i = index" [class.selected]="newDays.days[i]" (click)="newDays.days[i] = newDays.days[i] ? 0 : 1">
								<i class="mdi" [class.mdi-checkbox-marked-outline]="newDays.days[i]" [class.mdi-checkbox-blank-outline]="!newDays.days[i]"></i> {{day}}
							</span>
						</div>
					</div>

					<div class="start mr-1">
						<div>
							<label for="startTime">Start </label>
							<div>
								<input pInputText type="time" [(ngModel)]="newDays.startTime">
							</div>

						</div>
					</div>
					<div class="end">
						<div class="form-group">
							<label for="endTime">End </label>
							<div>
								<input pInputText type="time" [(ngModel)]="newDays.endTime" (ngModelChange)="changed($event)">
							</div>

						</div>
					</div>
					<div class="duration mr-1">
						<label>Duration</label>
						<div class="p-inputgroup">
							<input type="number" pInputText [(ngModel)]="newDays.duration" />
							<span class="p-inputgroup-addon">Mins</span>
						</div>
					</div>
				</div>
				<div>
					<div class="text-right">
						<div class="buttons">
							<p-button label="Cancel" icon="pi pi-times" styleClass="p-button-outlined p-button-secondary mr-1 p-button-sm" (click)="cancelNewRow()">
							</p-button>
							<p-button [disabled]="!newDays.duration " [label]="newDays.id ? 'Update' : 'Add'" icon="mdi mdi-plus" styleClass="p-button-sm" (click)="submitNewRow()">
							</p-button>
						</div>
					</div>
				</div>
			</section>
		</main>
		<footer>
			<div class="buttons">
				<div>
					<span pTooltip="Select to rebuild the data for this collection">
						<p-checkbox name="group1" [binary]="true" value="Rebuild Request" [(ngModel)]="rebuildRequest" inputId="rebuildRequest"></p-checkbox>
						<label> Rebuild data</label>
					</span>
				</div>
				<div class="text-right">
					<p-button label="Cancel" icon="pi pi-times" styleClass="p-button-outlined p-button-secondary mr-1" (click)="cancel()">
					</p-button>
					<p-button [disabled]="!collection.days.length || !collection.title" [label]="collection.id ? 'Update' : 'Create'" icon="mdi mdi-content-save" styleClass="" (click)="submit()">
					</p-button>
				</div>
			</div>
		</footer>
		}
		}
	</div>
</article>

@if(isRebuildingAssetsLinked() || isGatheringAnalyseRequest() || isSaving()) {
<app-please-wait></app-please-wait>
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-insights-review-first-time',
  templateUrl: './insights-review-first-time.component.html',
  styleUrl: './insights-review-first-time.component.css'
})
export class InsightsReviewFirstTimeComponent {

}

<article>
	<app-page-title [label]="asset().title" [canGoBack]="true" (click)="goBack()" />

	<div class="tabview-list">
		@if(can().issues) {
		<div [class.active]="tabIndex()===0" (click)="tabIndex.set(0)"><i class="mdi mdi-view-list"></i>Messages</div>
		}
		<div [class.active]="tabIndex()===1" (click)="tabIndex.set(1)"><i class="mdi mdi-view-list"></i>Query</div>
		<div></div>
	</div>
	<section>
		@switch(tabIndex()) {
		@case(0) {
		<app-asset-offline-communication [asset]="asset()"></app-asset-offline-communication>
		}
		@case(1) {
		<app-setpoint-query [asset]="asset()" />
		}
		}
	</section>

</article>

<div class="cms-item-block" [class.is-editing]="isEditing" (click)="wasClicked($event)" [ngClass]="{'align-right': block.align === 'right', 'align-center': block.align === 'center'}">
  <div class="body" *ngIf="block.type === 'text'">
    <span>{{block.body}}</span>
  </div>
  <div class="image" *ngIf="block.type === 'image'">
    <div *ngIf="block.reference">
      <img class="img-responsive" *ngIf="block.reference.substring(0,4)!=='http'" src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{block.reference}}">
      <img class="img-responsive" *ngIf="block.reference.substring(0,4)==='http'" src="{{block.reference}}">
    </div>
  </div>
  <div class="link" *ngIf="block.type === 'link'">
    <span>
      <a target="_blank" href="{{block.reference}}">
        <i class="fa fa-external-link"></i> {{block.body || 'link'}}</a>
    </span>
  </div>
  <div class="actions">
    <i class="fa fa-angle-up" (click)="wasMoved('up')"></i>
    <i class="fa fa-angle-down" (click)="wasMoved('down')"></i>
    <i class="fa fa-trash" (click)="wasTrashed($event)"></i>
  </div>
</div>
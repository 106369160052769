<div class="row" *ngIf="ticket">
  <div class="col-sm-12">
    <div class="title">{{ticket.title}}</div>
  </div>
  <div class="col-sm-12">
    <div class="description">{{ticket.description}}</div>
  </div>
  <div class="col-sm-12">
    <div class="question">
      <div [hidden]="hasSentMessage">
        <p>You can ask a question regarding this issue, submit your question and 4D will get back to you as soon as possible.</p>
        <input class="form-control" [(ngModel)]="usersQuestion" name="usersQuestion" placeholder="Ask a question">
        <button (click)="sendQuestion()" class="btn btn-block btn-primary">Send question</button>
      </div>
      <div class="delivery-message" [hidden]="!hasSentMessage">
        <p ><i class="fa fa-tick"></i> Your message <span>"{{usersQuestion}}"</span>&nbsp; has been delivered.</p>
      </div>
    </div>
  </div>
</div>
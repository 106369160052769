import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APIService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class HelpService {

	static readonly API_URL = 'https://dibmmfqfnlhhciooaqq54exdla0ptrjv.lambda-url.eu-west-2.on.aws';

	constructor(private http: HttpClient, private apiService: APIService) { }

	getContent(id: string): Promise<any> {
		return new Promise((resolve) => {
			const url = `${HelpService.API_URL}?i=${id}`;
			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

}

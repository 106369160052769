import { Component, effect, input, model, OnInit, signal } from '@angular/core';
import { DataTable, IDataTableColumnItem } from 'app/classes/data-table/data-table';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrl: './data-table.component.css'
})
export class DataTableComponent implements OnInit {

  dataTable = input.required<DataTable>();

  rows = signal<any[]>(null);

  constructor() {

    effect(() => {
      if (this.dataTable()) {
        this.changed();
      }
    }, { allowSignalWrites: true });
  }

  ngOnInit(): void {
    console.log(`DATA_TABLE`, this.dataTable());

    if (this.dataTable()) {
      this.rows.set(null);
      return;
    }
    this.sort();
  }

  changed() {
    this.sort();
  }

  sortColumnClick(column: IDataTableColumnItem) {
    console.log(column);

    const currentSortColumn = this.dataTable().columns.find(c => !!c.sortDirection);

    if (currentSortColumn?.attribute === column.attribute) {
      currentSortColumn.sortDirection = currentSortColumn.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      if (currentSortColumn) {
        currentSortColumn.sortDirection = null;
      }
      column.sortDirection = 'asc';
    }
    this.sort();
  }

  rowClick(row: any) {
    console.log(row);
  }

  sort() {
    if (!this.dataTable()) {
      return;
    }

    let sortedColumn = this.dataTable().columns.find(c => !!c.sortDirection);
    if (!sortedColumn) {
      this.rows.set(this.dataTable().data);
      return;
    }

    let config = this.dataTable().dataClassConfig[sortedColumn.attribute];
    if (!config) {
      console.error(`No config for ${sortedColumn.attribute}`);

      return;
    }

    const sortDirection = sortedColumn.sortDirection;

    if (config.sortUsingKey) {
      // Sort using alternative column
      const useKey = config.sortUsingKey;
      config = this.dataTable().dataClassConfig[useKey];
      sortedColumn = this.dataTable().columns.find(c => c.attribute === useKey);
    }


    const sorted = this.dataTable().data.sort((a, b) => {
      let aValue, bValue;

      switch (config.valueType) {
        case 'number':
        case 'date':
          aValue = +a[sortedColumn.attribute];
          bValue = +b[sortedColumn.attribute];
          break;
        default:
          aValue = a[sortedColumn.attribute];
          bValue = b[sortedColumn.attribute];
          break;
      }

      switch (sortDirection) {
        case 'asc':
          return aValue > bValue ? 1 : -1;
        case 'desc':
          return aValue < bValue ? 1 : -1;
        default:
          return 0;
      }
    });

    this.rows.set(sorted);
    console.table(this.rows());
  }
}


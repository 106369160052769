<article class="theme4d23" *ngIf="schedule">
	<header>
		<section class="info">
			<div>
				<label>Schedule</label>
				<a [routerLink]="['/setpoints','templates',schedule.template.id,'scheduler', schedule.id]"
					pTooltip="Edit the schedule"><i class="mdi mdi-square-edit-outline"></i> {{schedule.title}}</a>
			</div>

			<div>
				<label>Template</label>
				<a [routerLink]="['/setpoints','templates',schedule.template.id]" pTooltip="Edit the template"
					tooltipPosition="left"><i class="mdi mdi-square-edit-outline"></i>
					{{schedule.template.title}}
				</a> <small class="chip" pTooltip="Category">{{schedule.template.category.title}}</small>
			</div>
			<div>
				<label>Process&nbsp;Date</label>
				{{schedule.processAt | date: 'dd-MM-YY'}}
			</div>
		</section>
		<section class="counts">
			<div pTooltip="There are {{assetCount}} assets attached to this schedule" tooltipPosition="top">
				<label>Assets</label>
				{{assetCount | number:'1.0':'en-GB' }}
			</div>
			<div pTooltip="There are {{alarmCount}} out of 4 rag's toggled (red/amber min/max)" tooltipPosition="top">
				<label>Alarms in template</label>
				{{alarmCount}}
			</div>
			<div pTooltip="There are {{dayCount}} out of 7 days enabled" tooltipPosition="top">
				<label>Days in template</label>
				{{dayCount}}
			</div>
			<div pTooltip="Generate {{alarmCount}} x {{dayCount}} x {{assetCount}} alarms" tooltipPosition="top">
				<label>Total Alarms</label>
				{{ruleCount | number:'1.0':'en-GB' }}
			</div>
		</section>
	</header>
	<main>

		<section>
			<h3>Assets</h3>
			<table class="table">
				<thead>
					<tr>
						<th>Site</th>
						<th>Gateway</th>
						<th>Asset <small pTooltip="asset must match this" tooltipPosition="top" class="chip"
								*ngIf="schedule.filter?.asset">{{schedule.filter.asset.op === '=' ?
								'contains' : 'not'}} {{schedule.filter.asset.value}}</small></th>
						<th>Location <small pTooltip="Location must match this" tooltipPosition="top" class="chip"
								*ngIf="schedule.filter?.location">{{schedule.filter.location.op === '=' ?
								'contains' : 'not'}} {{schedule.filter.location.value}}</small></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let asset of schedule.wouldProcess?.assets">
						<td>{{asset.gateway.site.title}}</td>
						<td>{{asset.gateway.title}}</td>
						<td>{{asset.title}}</td>
						<td>{{asset.location}}</td>
					</tr>
				</tbody>
			</table>
		</section>
	</main>
	<footer>

	</footer>
</article>

import { Component, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Building } from 'app/classes/building';
import { AssetCollectionItem, SimpleAssetCollection } from "app/classes/asset-collection";
import { AssetCollectionService } from "app/shared/asset.collection.service";
import { Gateway } from 'app/classes/gateway';
import { Insight } from 'app/classes/insight';
import { Org } from 'app/classes/org';
import { APIService } from 'app/shared/api.service';
import { BuildingsService } from 'app/shared/buildings.service';
import { CombinedCollectionItem, SiteService } from 'app/shared/site.service';

import { Subscription } from 'rxjs';
import { CollectionAsset, CollectionForAssets } from 'app/classes/collection-for-assets';
import { InsightsService } from 'app/shared/insights.service';

@Component({
  selector: 'app-aq-collections',
  templateUrl: './aq-collections.component.html',
  styleUrl: './aq-collections.component.css'
})
export class AqCollectionsComponent {

  readonly LOCALSTORAGE = 'iaq:config';
  previousBuildings = signal<IBuildingQuickPick[]>(null);
  isGettingBuilding = signal<boolean>(false);
  buildings = signal<Building[]>(null);
  building = signal<Building>(null);
  dialogOpen = signal<'buildings' | 'configure' | 'aq-select'>(null);
  buildingForReport = signal<Building>(null);
  gateways: Gateway[];
  gatewaysSelected: Gateway[];
  month: any;
  subs: Subscription[] = [];
  runReport: boolean;
  reportWasGenerated: boolean;
  reportWasGeneratedAt: Date;
  // Re-run report with latest data if true
  clearCache: boolean;
  org: Org;
  today = new Date();
  useOrgLogo: boolean;
  gatewaysNotAvailable = signal<Gateway[]>(null);
  collections = signal<SimpleAssetCollection[]>(null)
  gateway = signal<Gateway>(null);
  combinedCollections = signal<CombinedCollectionItem[]>(null);
  collectionForAssets = signal<CollectionForAssets>(null);
  selectedCollectionsAssets: CollectionAsset[];
  showHelp = signal<string>(null);
  aqList = signal<any[]>(null);
  isEditingExistingHours = signal<boolean>(false);


  constructor(private assetCollectionService: AssetCollectionService, private apiService: APIService, private buildingService: BuildingsService, public siteService: SiteService, private insightsService: InsightsService) {
    this.getLocalStorage();
  }

  ngOnInit(): void {
    const buildingSub = this.buildingService.buildingList.subscribe(list => {
      if (!list) {
        return;
      }
      this.buildings.set(list);
    });

    this.subs.push(buildingSub);
    this.org = this.apiService.getUserOrg();
  }

  clickConfigureRags() {
    this.dialogOpen.set('configure');
  }

  clickCollection(collection: AssetCollectionItem) {
    console.log(collection);
  }

  useAssetsForGateway(gateway: Gateway, assets: Asset[]) {
    this.gateway.set(null);
    assets.forEach(asset => gateway.assets.add(asset));
    this.gateways = [gateway];
  }

  buildingChange(building: Building) {
    this.dialogOpen.set(null);

    if (!building) {
      return;
    }

    this.building.set(building);
    this.addToPreviousBuildings(building);
    this.runReport = false;
    this.reportWasGenerated = false;
    this.buildingForReport.set(null);
    this.getBuilding(this.building().id);
  }

  addToPreviousBuildings(building: Building) {
    this.previousBuildings.set([{ id: building.id, title: building.title }]);
    this.saveLocalStorage();
  }

  getLocalStorage() {
    try {
      const storageString = localStorage.getItem(this.LOCALSTORAGE);
      if (storageString) {
        const storageObject = JSON.parse(storageString);
        this.previousBuildings.set(storageObject.previous);
      }
    } catch (e) { }
  }

  saveLocalStorage() {
    const payload = JSON.stringify({ previous: this.previousBuildings() });
    localStorage.setItem(this.LOCALSTORAGE, payload);
  }

  hoursUpdated() {
    this.getBuilding(this.buildingForReport().id);
  }


  async getBuilding(id: number) {
    this.isGettingBuilding.set(true);

    const building = await this.buildingService.getOne(id, 'aq=1');
    console.log('Building data loaded', building.id);
    // const collections = await this.siteService.getAssetCollections(building.site.id, [CombinedCollectionItem.TYPE_AQ]);
    // console.log('Collections loaded');
    this.buildingForReport.set(building);
    // this.collections.set(collections);

    // this.siteService.getCollections(building.site.id).then(collections => this.combinedCollections.set(collections.filter(c => c.type.id === CombinedCollectionItem.TYPE_AQ)));

    const collectionAssets = await this.buildingService.getCollections(building.id, 'aq');
    collectionAssets.collections.forEach(c => c.selected = true);
    this.collectionForAssets.set(collectionAssets);
    console.log('collections loaded');
    this.isGettingBuilding.set(false);
  }

  clickGatewaysNotAvailable(gateway: Gateway) {
    this.gateway.set(gateway);
  }

  getGateways() {

  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  toolbarClick(event: any) {
    const building = this.buildingForReport();
    throw new Error('NOT_IMPLEMENTED');
    /*
    this.dialogRef = this.dialogService.open(DialogOpeningHoursComponent, {
      header: `Hours for ${building.title}`,
      data: { openingHours: building.openingHours }
    });
    this.dialogRef.onClose.subscribe((openingHours: OpeningHours) => {
      if (openingHours) {
        this.buildingForReport.openingHours = openingHours;
        this.buildingService.updateHours(this.buildingForReport).then(b => {
          this.messageService.add({ severity: 'info', summary: 'hours updated', detail: building.title });
          this.getBuilding(building.id);
        });
      }
    });
    */
  }

  generate() {
    if (!this.month) {
      this.apiService.toastWarn('Select a month', '');
      return;
    }

    if (this.runReport && !this.reportWasGenerated) {
      console.log('REPORT_GENERATING_PLEASE_WAIT');
      return;
    }

    if (this.runReport) {
      this.runReport = false;
      setTimeout(() => {
        this.runReport = true;
        this.reportWasGenerated = false;
      }, 10);
    } else {
      this.selectedCollectionsAssets = this.collectionForAssets().collections.filter(c => c.selected);
      this.reportWasGenerated = false;
      this.runReport = true;
    }
  }

  onReportWasGenerated(insight: Insight) {
    if (insight === null) {
      this.reportWasGenerated = false;
      this.runReport = false;
      this.reportWasGeneratedAt = null;
    } else {
      this.reportWasGenerated = true;
      this.reportWasGeneratedAt = insight.generatedAt;
    }
  }

  printPage() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.print();
    }, 100);
  }

  chooseRAGClick() {
    this.insightsService.updateAQRAGConfigList().then(response => {
      this.aqList.set(response);
    });
    this.dialogOpen.set('aq-select');
  }

  async selectAQConfig(item: any) {
    this.buildingForReport().aqConfig = { id: item.id, title: item.title, config: item.config, isDefault: item.isDefault ?? false };
    this.dialogOpen.set(null);
    this.apiService.toastSuccess('Updating RAG configuration for building...', null, 1000);
    await this.insightsService.updateAQRAGforBuiilding(item.id, this.buildingForReport().id);
    this.apiService.toastSuccess('Updated RAG configuration for building.', null, 2000);
  }
}

export interface IBuildingQuickPick {
  id: number;
  title: string;
}

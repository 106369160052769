<article [class.fullscreen]="fullscreen" *ngIf="gateways">
  <google-map height="100%" width="100%" [zoom]="defaults.zoom" (zoomChanged)="zoomChanged()"
    (tilesloaded)="tilesLoaded()" [center]="{
      lat: 53.884,
      lng:-1.857,
      lat2: 53.34,
      lng2: -1.35
    }" [options]="mapOptions">
    <map-circle *ngFor="let item of offline" [center]="item.point" [radius]="80"
    [options]="{strokeColor:'red', fillColor: 'red'}">
  </map-circle>
    <map-circle *ngFor="let item of stack" [center]="item.point" [radius]="item.counter"
      [options]="{strokeColor:'DodgerBlue', fillColor: 'DodgerBlue', strokeOpacity:0.8, strokeWeight:1}">
    </map-circle>
    <map-circle *ngFor="let item of seen" [center]="item.point" [radius]="100"
      [options]="{strokeColor:'orange', fillColor: 'orange'}">
    </map-circle>
  </google-map>
  <ng-template [ngIf]="fullscreen">
    <div class="logo" *ngIf="org.id !== 4 && org.id !== 12">
      <img src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{org.settings.theme_menu_logo}}" />
    </div>
  
    <div class="logo" *ngIf="org.id === 12" style="width:300px" >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.88 55.977">
        <style>
          .w {
            fill: white
          }
        </style>
        <path class="w"
          d="M0,16.86H4.15l1.494,7.915L7.83,16.86h4.135l2.193,7.905,1.5-7.905h4.128L16.664,31H12.38L9.9,22.1,7.43,31H3.146Z"
          fill="#ff000f" />
        <path class="w"
          d="M28.776,28.665H23.815L23.126,31H18.665L23.979,16.86h4.766L34.058,31H29.483Zm-.907-3.058-1.561-5.082-1.544,5.082Z"
          fill="#ff000f" />
        <path class="w" d="M32.284,16.86H45.565v3.491H41.109V31H36.74V20.351H32.284Z" fill="#ff000f" />
        <path class="w"
          d="M55.53,25.212l3.828,1.157a7.055,7.055,0,0,1-1.215,2.691,5.269,5.269,0,0,1-2.059,1.63,7.7,7.7,0,0,1-3.13.55,9.081,9.081,0,0,1-3.766-.67,5.967,5.967,0,0,1-2.522-2.356A7.993,7.993,0,0,1,45.605,23.9,7.339,7.339,0,0,1,47.471,18.5a7.1,7.1,0,0,1,5.281-1.884,7.17,7.17,0,0,1,4.2,1.08,6.351,6.351,0,0,1,2.272,3.318l-3.859.858a3.294,3.294,0,0,0-.424-.945,2.5,2.5,0,0,0-.9-.772,2.757,2.757,0,0,0-3.462.925,5.071,5.071,0,0,0-.6,2.784,5.3,5.3,0,0,0,.713,3.222,2.818,2.818,0,0,0,3.9.168A4.374,4.374,0,0,0,55.53,25.212Z"
          fill="#ff000f" />
        <path class="w" d="M60.749,16.86h4.369v4.947h4.774V16.86h4.389V31H69.892V25.28H65.118V31H60.749Z"
          fill="#ff000f" />
        <path class="w"
          d="M76.385,16.86h5.746l2.216,8.6,2.2-8.6h5.742V31H88.711V20.216L85.954,31H82.715L79.963,20.216V31H76.385Z"
          fill="#ff000f" />
        <path class="w"
          d="M103.018,28.665h-4.96L97.369,31H92.908L98.222,16.86h4.766L108.3,31h-4.575Zm-.906-3.058-1.56-5.082-1.545,5.082Z"
          fill="#ff000f" />
        <path class="w" d="M108.968,16.86h4.08l5.324,7.822V16.86h4.118V31h-4.118l-5.3-7.764V31h-4.109Z" fill="#ff000f" />
        <path class="w"
          d="M2.331,50.989V38.7H4.194v1.724A4.378,4.378,0,0,1,5.733,38.97a4.323,4.323,0,0,1,2.188-.55,4.042,4.042,0,0,1,2.239.567,3.033,3.033,0,0,1,1.233,1.586,4.41,4.41,0,0,1,3.8-2.153A3.736,3.736,0,0,1,18,39.433a4.326,4.326,0,0,1,.984,3.119v8.437H16.914V43.246a5.7,5.7,0,0,0-.2-1.8,1.745,1.745,0,0,0-.735-.885,2.293,2.293,0,0,0-1.25-.336,2.91,2.91,0,0,0-2.153.863,3.8,3.8,0,0,0-.856,2.76v7.141H9.634V43a3.509,3.509,0,0,0-.51-2.083,1.927,1.927,0,0,0-1.666-.7,3.029,3.029,0,0,0-1.626.463A2.622,2.622,0,0,0,4.75,42.042a7.622,7.622,0,0,0-.336,2.57v6.377Z"
          fill="#ff000f" />
        <path class="w"
          d="M22.556,44.843a6.334,6.334,0,0,1,1.9-5.057A5.718,5.718,0,0,1,28.32,38.42a5.51,5.51,0,0,1,4.143,1.661,6.316,6.316,0,0,1,1.609,4.589A8.123,8.123,0,0,1,33.36,48.4a5.032,5.032,0,0,1-2.071,2.112,6.031,6.031,0,0,1-2.969.753,5.524,5.524,0,0,1-4.172-1.655A6.613,6.613,0,0,1,22.556,44.843Zm2.141,0a5.24,5.24,0,0,0,1.03,3.536,3.43,3.43,0,0,0,5.174-.006,5.358,5.358,0,0,0,1.03-3.6A5.077,5.077,0,0,0,30.9,41.319a3.435,3.435,0,0,0-5.168-.006A5.217,5.217,0,0,0,24.7,44.843Z"
          fill="#ff000f" />
        <path class="w"
          d="M37.77,50.989V38.7h1.875v1.747a4.419,4.419,0,0,1,3.912-2.025,5.135,5.135,0,0,1,2.043.4A3.21,3.21,0,0,1,47,39.867a4.037,4.037,0,0,1,.648,1.539,11.968,11.968,0,0,1,.115,2.025v7.558H45.675V43.512a5.619,5.619,0,0,0-.243-1.9A2,2,0,0,0,44.57,40.6a2.751,2.751,0,0,0-1.453-.376,3.376,3.376,0,0,0-2.3.845q-.966.845-.966,3.206v6.713Z"
          fill="#ff000f" />
        <path class="w" d="M52.221,36.418v-2.4H54.3v2.4Zm0,14.571V38.7H54.3V50.989Z" fill="#ff000f" />
        <path class="w"
          d="M63.28,49.126l.3,1.84a7.762,7.762,0,0,1-1.574.185,3.567,3.567,0,0,1-1.76-.359,2.009,2.009,0,0,1-.879-.943,7.454,7.454,0,0,1-.255-2.46V40.318H57.585V38.7h1.528V35.654l2.072-1.25V38.7H63.28v1.62H61.185v7.187a3.612,3.612,0,0,0,.11,1.146.863.863,0,0,0,.359.4,1.385,1.385,0,0,0,.711.151A6.779,6.779,0,0,0,63.28,49.126Z"
          fill="#ff000f" />
        <path class="w"
          d="M80.988,50.989V38.7h1.875v1.863a5.069,5.069,0,0,1,1.326-1.724,2.31,2.31,0,0,1,1.336-.417,4.067,4.067,0,0,1,2.141.671l-.717,1.933a2.98,2.98,0,0,0-1.528-.451,1.991,1.991,0,0,0-1.227.41,2.228,2.228,0,0,0-.775,1.14,8.1,8.1,0,0,0-.347,2.431v6.435Z"
          fill="#ff000f" />
        <path class="w" d="M90.173,36.418v-2.4h2.083v2.4Zm0,14.571V38.7h2.083V50.989Z" fill="#ff000f" />
        <path class="w"
          d="M96.683,50.989V38.7h1.875v1.747a4.419,4.419,0,0,1,3.912-2.025,5.135,5.135,0,0,1,2.043.4,3.21,3.21,0,0,1,1.395,1.048,4.037,4.037,0,0,1,.648,1.539,11.968,11.968,0,0,1,.115,2.025v7.558h-2.083V43.512a5.619,5.619,0,0,0-.243-1.9,2,2,0,0,0-.862-1.007,2.751,2.751,0,0,0-1.453-.376,3.376,3.376,0,0,0-2.3.845q-.966.845-.966,3.206v6.713Z"
          fill="#ff000f" />
        <path class="w"
          d="M110.741,52.007l2.025.3a1.929,1.929,0,0,0,.706,1.366,3.467,3.467,0,0,0,2.118.579,3.721,3.721,0,0,0,2.234-.579,2.85,2.85,0,0,0,1.064-1.62,13.281,13.281,0,0,0,.151-2.674,4.285,4.285,0,0,1-3.4,1.609,4.638,4.638,0,0,1-3.923-1.829,7.04,7.04,0,0,1-1.389-4.386,8.174,8.174,0,0,1,.636-3.247,5.1,5.1,0,0,1,1.846-2.3,4.989,4.989,0,0,1,2.842-.81,4.407,4.407,0,0,1,3.588,1.759V38.7h1.921V49.322a10.064,10.064,0,0,1-.585,4.068,4.274,4.274,0,0,1-1.851,1.893,6.42,6.42,0,0,1-3.12.694,5.887,5.887,0,0,1-3.553-.989A3.349,3.349,0,0,1,110.741,52.007Zm1.724-7.384a5.327,5.327,0,0,0,.961,3.53,3.168,3.168,0,0,0,4.814.006,5.159,5.159,0,0,0,.972-3.466,5.038,5.038,0,0,0-1-3.4,3.094,3.094,0,0,0-2.413-1.146,3.005,3.005,0,0,0-2.361,1.129A5.011,5.011,0,0,0,112.465,44.623Z"
          fill="#ff000f" />
        <path d="M78.607,44.74a6.582,6.582,0,1,1-6.582-6.582A6.582,6.582,0,0,1,78.607,44.74Z" fill="none" stroke="#f0f0f0"
          stroke-linecap="square" stroke-linejoin="round" stroke-width="1.406" />
        <path d="M65.443,44.74H78.607" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M65.548,44.74h1.177" fill="none" stroke="#ff000f" stroke-width="0.79" />
        <path d="M77.325,44.74H78.5" fill="none" stroke="#ff000f" stroke-width="0.79" />
        <path d="M67.544,45.135v-.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M69.038,45.135v-.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M70.531,45.135v-.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M73.518,45.135v-.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M75.012,45.135v-.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M76.506,45.135v-.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M72.025,45.135v-.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M72.025,38.158V51.322" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M72.025,38.264V39.44" fill="none" stroke="#ff000f" stroke-width="0.79" />
        <path d="M72.025,50.04v1.177" fill="none" stroke="#ff000f" stroke-width="0.79" />
        <path d="M71.63,40.259h.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M71.63,41.753h.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M71.63,43.247h.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M71.63,46.234h.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M71.63,47.728h.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M71.63,49.221h.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M71.63,44.74h.79" fill="none" stroke="#ff000f" stroke-width="0.342" />
        <path d="M134.694,15.861A10.714,10.714,0,0,0,124.069,5.186l0,2.94a7.774,7.774,0,0,1,7.689,7.731Z"
          fill="#ff000f" />
        <path d="M137.028,15.863l2.852,0A15.9,15.9,0,0,0,124.074,0l0,2.852A13.049,13.049,0,0,1,137.028,15.863Z"
          fill="#ff000f" />
        <path d="M129.421,15.855a5.443,5.443,0,0,0-5.358-5.4l-.007,5.389Z" fill="#ff000f" />
      </svg>
      <div style="color: white;font-size: 27px;text-align: center;padding-right:50px;">www.cmsl.co.uk</div>
      <div style="color: white;font-size: 27px;text-align: center;padding-right:50px;">01480 493800</div>
    </div>
  
    <div class="logo" *ngIf="org.id === 4" style="width:300px">
      <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 406.8 240.9">
        <title>4D_monitoring-logo</title>
  
        <path class="fadein"
          d="M124.2,123.4v-1.6a10.7,10.7,0,0,1,2.5-7.4L155,74a8.9,8.9,0,0,1,8-4h14.2c4.3,0,6.7,2.1,6.7,6v37.5h3.6c4.4,0,6.7,2.1,6.7,6v3.9c0,3.9-1.8,6-5.1,6h-5.2v12.9c0,4-2.4,6-6.7,6h-7.7c-4.4,0-6.7-2-6.7-6V129.4H130.9C126.5,129.4,124.2,127.3,124.2,123.4Zm38.6-9.9V96a66.2,66.2,0,0,1,.7-8.8h-.2a37.8,37.8,0,0,1-4.3,8.1l-13.1,18v.2Z"
          style="fill:white" />
  
  
        <path class="fadein"
          d="M206.7,76c0-3.9,2.3-6,6.7-6h24.2c27.4,0,44.9,14.4,44.9,39s-17.5,39.3-44.9,39.3H213.4c-4.4,0-6.7-2-6.7-6Zm30.1,55.9c14.7,0,23.7-7.9,23.7-22.9s-9.4-22.6-23.7-22.6H228v45.5Z"
          style="fill:white" />
  
  
        <path class="fadein-2"
          d="M0,188.4a2.3,2.3,0,0,1,2.5-2.6H4.7c1.7,0,2.5.9,2.5,2.6v3.4A12.5,12.5,0,0,1,7,194h.1c2-4.5,7.4-9.1,13.6-9.1s10.3,3,11.7,8.8h.1a15.9,15.9,0,0,1,13.8-8.8c8.8,0,12.8,5,12.8,14.9v22.4a2.2,2.2,0,0,1-2.5,2.5H54.2a2.3,2.3,0,0,1-2.6-2.5V201.4c0-5.4-1-9.7-6.8-9.7s-11.5,7.4-11.5,14.9v15.6a2.2,2.2,0,0,1-2.5,2.5H28.4a2.2,2.2,0,0,1-2.5-2.5V201.4c0-5-.8-9.7-6.8-9.7S7.5,199.4,7.5,206.6v15.6c0,1.7-.9,2.5-2.6,2.5H2.5A2.2,2.2,0,0,1,0,222.2Z"
          style="fill:white" />
  
        <path class="fadein-3"
          d="M90.8,184.9c11.6,0,21,8.5,21,20.3s-9.4,20.4-21,20.4S69.9,217,69.9,205.2,79.2,184.9,90.8,184.9Zm0,34.3c7.3,0,13.3-5.9,13.3-14s-6-13.8-13.3-13.8a13.4,13.4,0,0,0-13.4,13.8C77.4,213.3,83.5,219.2,90.8,219.2Z"
          style="fill:white" />
  
        <path class="fadein4"
          d="M123,188.4a2.4,2.4,0,0,1,2.6-2.6h2.1a2.3,2.3,0,0,1,2.6,2.6v3.4a8.5,8.5,0,0,1-.3,2.2h.2a16.2,16.2,0,0,1,14.8-9.1c9.1,0,13.2,5,13.2,14.9v22.4a2.2,2.2,0,0,1-2.5,2.5h-2.4a2.2,2.2,0,0,1-2.5-2.5V201.5c0-5.5-1.1-9.8-7.3-9.8s-13,6.3-13,14.4v16.1a2.2,2.2,0,0,1-2.5,2.5h-2.4a2.3,2.3,0,0,1-2.6-2.5Z"
          style="fill:white" />
  
        <path class="fadein5"
          d="M171.5,175.5V173a2.2,2.2,0,0,1,2.5-2.5h2.6a2.3,2.3,0,0,1,2.6,2.5v2.5a2.4,2.4,0,0,1-2.6,2.6H174A2.3,2.3,0,0,1,171.5,175.5Zm0,12.9a2.4,2.4,0,0,1,2.6-2.6h2.4c1.7,0,2.5.9,2.5,2.6v33.8a2.2,2.2,0,0,1-2.5,2.5h-2.4a2.3,2.3,0,0,1-2.6-2.5Z"
          style="fill:white" />
  
        <path class="fadein6"
          d="M194.7,192.3H192a2.3,2.3,0,0,1-2.5-2.6v-1a2.3,2.3,0,0,1,2.6-2.5h2.7v-8.5a2.4,2.4,0,0,1,2.6-2.6h2.2c1.7,0,2.5.9,2.5,2.6v8.5h6.8a2.2,2.2,0,0,1,2.5,2.5v1c0,1.7-.8,2.6-2.4,2.6h-6.9v17.3c0,7.4,4.6,8.5,7.4,8.7s2.7.8,2.7,2.6v1.6c0,1.8-1,2.5-3.1,2.5-7.7,0-14.4-4.4-14.4-14.3Z"
          style="fill:white" />
  
        <path class="fadein7"
          d="M240.2,184.9c11.6,0,21,8.5,21,20.3s-9.4,20.4-21,20.4-20.9-8.6-20.9-20.4S228.6,184.9,240.2,184.9Zm0,34.3c7.4,0,13.4-5.9,13.4-14s-6-13.8-13.4-13.8a13.4,13.4,0,0,0-13.4,13.8C226.8,213.3,232.9,219.2,240.2,219.2Z"
          style="fill:white" />
  
        <path class="fadein8"
          d="M272.5,188.4a2.3,2.3,0,0,1,2.5-2.6h2.1a2.3,2.3,0,0,1,2.6,2.6v4.4a14.4,14.4,0,0,1-.3,2.9h.2c1.7-5.1,6.1-10.2,11.9-10.2,1.8,0,2.5.9,2.5,2.6v2.1c0,1.7-1,2.5-2.8,2.5-7.6,0-11.3,8.3-11.3,16v13.5a2.2,2.2,0,0,1-2.5,2.5H275a2.2,2.2,0,0,1-2.5-2.5Z"
          style="fill:white" />
  
        <path class="fadein9"
          d="M302.8,175.5V173c0-1.7.9-2.5,2.6-2.5H308a2.2,2.2,0,0,1,2.5,2.5v2.5a2.3,2.3,0,0,1-2.5,2.6h-2.6A2.4,2.4,0,0,1,302.8,175.5Zm.1,12.9a2.3,2.3,0,0,1,2.5-2.6h2.4c1.7,0,2.5.9,2.5,2.6v33.8a2.2,2.2,0,0,1-2.5,2.5h-2.4a2.2,2.2,0,0,1-2.5-2.5Z"
          style="fill:white" />
  
        <path class="fadein10"
          d="M324.2,188.4a2.3,2.3,0,0,1,2.5-2.6h2.2c1.7,0,2.5.9,2.5,2.6v3.4a12.5,12.5,0,0,1-.2,2.2h.1a16.4,16.4,0,0,1,14.8-9.1c9.1,0,13.3,5,13.3,14.9v22.4c0,1.7-.9,2.5-2.6,2.5h-2.3c-1.7,0-2.6-.8-2.6-2.5V201.5c0-5.5-1-9.8-7.3-9.8s-13,6.3-13,14.4v16.1a2.2,2.2,0,0,1-2.5,2.5h-2.4a2.2,2.2,0,0,1-2.5-2.5Z"
          style="fill:white" />
  
        <path class="fadein11"
          d="M378.7,232.7a21.8,21.8,0,0,0,8.4,1.7c6.6,0,12.2-3,12.2-10.9v-3.4a13,13,0,0,1,.3-2.5h-.2c-2.2,4.1-5.9,6.7-11.5,6.7-10.9,0-17.5-8.7-17.5-20s6.1-19.4,17.3-19.4c9.6,0,12.2,6.5,12.2,6.5h.1a5.5,5.5,0,0,1-.1-1.2V188c0-1.3.8-2.2,2.5-2.2h1.9c1.7,0,2.5.9,2.5,2.6v34.7c0,12.8-9.8,17.8-19.3,17.8a29.5,29.5,0,0,1-11.3-2.2c-1.6-.7-2-1.8-1.3-3.4l.5-1.3C376.1,232.5,377.1,232.1,378.7,232.7Zm20.8-28.2c0-10.2-5-13.1-10.8-13.1S378,196.3,378,204s4.2,13.8,11.2,13.8C394.6,217.8,399.5,214.6,399.5,204.5Z"
          style="fill:white" />
  
        <path class="path" d="M325.8,69.8a47.2,47.2,0,0,0-46.8-47v13a34.3,34.3,0,0,1,33.9,34Z" style="fill:#009fe3" />
        <path d="M336.1,69.8h12.5A70,70,0,0,0,279,0V12.6A57.4,57.4,0,0,1,336.1,69.8Z" class="path" style="fill:#009fe3" />
        <path d="M302.6,69.8A23.9,23.9,0,0,0,279,46.1V69.8Z" style="fill:#009fe3" class="path" />
      </svg>
      <div style="color: white;font-size: 27px;">www.4dmonitoring.co.uk</div>
      <div style="color: white;font-size: 27px;text-align: center;">01480 484999</div>
    </div>

  </ng-template>
 

  <section class="overlay"></section>
  <section class="data">
    <div class="data-card header">
      <!--{{stack.length}} items in the asset stack.-->
      <!--  <span class="mdi mdi-menu pull-right" (click)="fullscreen = !fullscreen"></span> -->

    </div>
    <div *ngFor="let item of stack.slice().reverse()" class="data-card">
      <p>{{item.gateway.title}}</p>
      <p class="info">{{item.assets.length}} asset<span *ngIf="item.assets.length>1">s</span> in packet.</p>

    </div>
  </section>
  <section class="header">
  
  </section>
  <div pTooltip="toggle fullscreen" class="mdi mdi-fullscreen can-click"
    (click)="toggleFullscreen()"></div>
</article>

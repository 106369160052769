import { Component, OnInit, Input, Output, EventEmitter, input, signal } from '@angular/core';
import { Site } from "../../classes/site";
import { Gateway } from "../../classes/gateway";
import { Asset } from "../../classes/asset";
import { RuleCondition, RuleConditionOperator } from "../../classes/rule-service/rule-condition";
import { SelectedAsset } from "../../classes/rule-service/selected-asset";
import { ConfirmationService } from "primeng/api";
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-rule-condition-new',
  templateUrl: './rule-condition-new.component.html',
  styleUrls: ['./rule-condition-new.component.css']
})
export class RuleConditionNewComponent implements OnInit {
  id: number;
  uuid: string;
  leftSelectedSite: Site;
  leftSelectedGateway: Gateway;
  leftSelectedAsset: Asset;
  rightSelectedSite: Site = null;
  rightSelectedGateway: Gateway = null;
  rightSelectedAsset: Asset = null;
  selectedOperator: RuleConditionOperator;
  sites: Site[];
  value: string;
  readonly LOCALSTORAGE = 'rule:condition:new';

  showAssetQuery = signal<boolean>(false);

  index = input<number>(null);

  @Input() defaultSite: Site;
  @Input() defaultGateway: Gateway;
  @Input() selectionOnly: boolean;
  @Input()
  public set modifyCondition(value: RuleCondition) {
    if (value) {
      this.leftSelectedSite = value.leftAsset.asset.gateway.site;
      this.leftSelectedGateway = value.leftAsset.asset.gateway;
      this.leftSelectedAsset = value.leftAsset.asset;

      this.rightSelectedSite = value.rightAsset.asset ? value.rightAsset.asset.gateway.site : null;
      this.rightSelectedGateway = value.rightAsset.asset ? value.rightAsset.asset.gateway : null;
      this.rightSelectedAsset = value.rightAsset.asset ? value.rightAsset.asset : null;

      this.value = value.rightAsset.value;
      this.selectedOperator = value.operator;
      this.id = value.id;
      this.uuid = value.uuid;

      this.selectionOnly = !!(this.rightSelectedAsset && this.rightSelectedAsset.id);
    } else {
      this.leftSelectedSite = null;
      this.leftSelectedAsset = null;
      this.leftSelectedGateway = null;
      this.rightSelectedSite = null;
      this.rightSelectedGateway = null;
      this.rightSelectedAsset = null;
      this.id = null;
      this.value = null;
    }
  }

  @Output() submit: EventEmitter<RuleCondition> = new EventEmitter<RuleCondition>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteCondition: EventEmitter<number> = new EventEmitter<number>();

  constructor(private confirmationService: ConfirmationService, private apiService: APIService) {
    this.getLocalStorage();
  }

  ngOnInit() {
    if (!this.leftSelectedSite && this.defaultSite) {
      this.leftSelectedSite = this.defaultSite;
      if (this.selectionOnly) {
        this.rightSelectedSite = this.defaultSite;
      }
      if (this.defaultGateway) {
        this.leftSelectedGateway = this.defaultGateway;
        if (this.selectionOnly) {
          this.rightSelectedGateway = this.defaultGateway;
        }
      }
    }

    if (this.selectionOnly && !this.rightSelectedAsset) {
      this.selectedOperator = 'diff';
    }
  }

  getLocalStorage() {
    const localStorageString = localStorage.getItem(this.LOCALSTORAGE);
    if (localStorageString) {
      try {
        const data = JSON.parse(localStorageString);
        this.showAssetQuery.set(data.showAssetQuery);
      } catch (e) { }
    }
  }

  saveLocalStorage() {
    const payload = { showAssetQuery: this.showAssetQuery() };
    localStorage.setItem(this.LOCALSTORAGE, JSON.stringify(payload));
  }

  clickShowQuery() {
    this.showAssetQuery.update(state => !state);
    this.saveLocalStorage();
  }

  deleteConditionClick() {
    this.deleteCondition.emit(this.index());
  }

  siteSelected(side: string, site: Site) {
    if (side === 'left') {
      this.leftSelectedSite = site;
      if (this.selectionOnly && !this.rightSelectedSite) {
        // Default right site to be the left
        this.rightSelectedSite = site;
      }
    } else {
      this.rightSelectedSite = site;
    }
  }

  gatewaySelected(side: string, gateway: Gateway) {
    if (side === 'left') {
      this.leftSelectedGateway = gateway;
      if (this.selectionOnly && this.rightSelectedSite && this.leftSelectedSite.id === this.rightSelectedSite.id) {
        // Default right site to be the left
        this.rightSelectedGateway = gateway;
      }
    } else {
      this.rightSelectedGateway = gateway;
    }
  }

  sensorSelected(side: string, asset: Asset) {
    if (side === 'left') {
      this.leftSelectedAsset = asset;
      // If sensor to sensor, the gateways match and asset is empty
      // if (this.selectionOnly && this.rightSelectedGateway && this.leftSelectedGateway.id === this.rightSelectedGateway.id) {
    } else {
      this.rightSelectedAsset = asset;
    }
  }

  operatorSelected(operator: RuleConditionOperator) {
    this.selectedOperator = operator;
  }

  cancelSite(side: string) {
    if (side === 'left') {
      this.leftSelectedAsset = null;
      this.leftSelectedGateway = null;
      this.leftSelectedSite = null;
    } else {
      this.rightSelectedAsset = null;
      this.rightSelectedGateway = null;
      this.rightSelectedSite = null;
    }
  }

  cancelGateway(side: string) {
    if (side === 'left') {
      this.leftSelectedAsset = null;
      this.leftSelectedGateway = null;
    } else {
      this.rightSelectedAsset = null;
      this.rightSelectedGateway = null;
    }
  }

  cancelAsset(side: string) {
    if (side === 'left') {
      this.leftSelectedAsset = null;
    } else {
      this.rightSelectedAsset = null;
    }
  }

  submitCondition() {
    if ((this.value === '' || this.value === undefined) && !this.rightSelectedAsset) {
      this.apiService.toastWarn('Please enter a value', '');
      return;
    }

    if (!this.rightSelectedAsset && this.selectedOperator === undefined) {
      this.apiService.toastWarn('Please select a condition operator', '');
      return;
    }

    let rc = new RuleCondition();
    if (this.id) {
      rc.id = this.id;
    } else if (this.uuid) {
      rc.uuid = this.uuid;
    }

    // Package up some useful objects
    this.leftSelectedGateway.site = this.leftSelectedSite;
    if (this.rightSelectedSite) this.rightSelectedGateway.site = this.rightSelectedSite;

    this.leftSelectedAsset.gateway = this.leftSelectedGateway;
    if (this.rightSelectedGateway) this.rightSelectedAsset.gateway = this.rightSelectedGateway;

    // Build the rule condition
    rc.leftAsset = new SelectedAsset(null, null, this.leftSelectedAsset);

    rc.rightAsset = new SelectedAsset(null, null, this.rightSelectedAsset, this.value);
    rc.operator = this.selectedOperator;

    this.submit.emit(rc);
  }

  cancelCondition() {
    this.cancel.emit();
  }
}

import { Component, OnInit } from '@angular/core';
import { ElogbooksSite } from 'app/classes/elogbooks-site';
import { APIService, IPostQuery } from 'app/shared/api.service';

@Component({
  selector: 'app-org-integration-elogbooks',
  templateUrl: './org-integration-elogbooks.component.html',
  styleUrls: ['./org-integration-elogbooks.component.css']
})
export class OrgIntegrationElogbooksComponent implements OnInit {

  isWorking: boolean;

  default: any = {
    siteContactAvailableOnSite: false,
    siteContactSameAsTenant: true,
    siteContactSameAsReporter: true,
    notifyOnCreate: true,
    notifyOnComplete: true,
    url: 'fourd-api.integration.v2.elogbooks.net',
    token: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXUyJ9.eyJ1c2VybmFtZSI6IjREVXNlciIsImlhdCI6IjE1NzA0MzY4NjAifQ.lDSRRR-bYCQVitcjdWWlgxinBtiNLkwVThKZckGYmVVm3L7iBur7ftokjn7zmy2CgcBt0vwdBUpqWYuTqnLnfTk9Xdx6YR9v8YxLmIq0CNxr86sYXOqC9NXic1JmmxYpZCxXrTYjXXs6KiW9xYAUEvB12AUVJ0R1o3gqjR6cvkxKVO2_fNZ7CDido3LWQOEuewM-c_pmIcgy8JeGJt_nPZqhez-Gvh8ru8kWkPKRualhbXc_fhGaQYTviJVnmXwhSAOd2yAlONNc3AuU9wTMjge2Vbo1qYykrwP_QTqYHiYUNlnBHU91QjtFsVK9UwPOPJ_3Su1rfVxvcq1Xvufu9JwZat_hUyPtonIjpUWjav9HgOAEcuimk6kMaznY2TFZbdF-vewVw4-a0XENqSfb1vDYEnEerIEMlEgGOZCQXzUfCCJeL4uk0FB22hLtE87KX7bfiIMzKfdicq9pjagLUrtxT5kukwwam-Uzo0Gikp3gUim5bZoDLW0zB4Y80CnMbW71p5im77JP3RrDkKkRtTCQR74N2EEahssVZMWz9w0p8nwfGW2Ph3gcuCPHZ_W1e8ZoRDamZQMnF4d4zDMCQ6lqBLXd-UKCOdAZR3tDsdvDr3_2PS6TbuWT4ku-z7PYRuRoWqBJ3EViruJwT6VsIwAEcjgyG_hSv8672wt1ZNY',
    userid: null,
    duplicate: 1,
    client: null,
    https: false
  };

  integration: Integration;
  stats: any;

  legend: any = {
    Success: { label: '', colour: 'green' },
    Failure: { label: '', colour: 'red' }
  };
  data = { Success: 0, Failure: 0 };
  value = null;

  sitesDialog: boolean;
  userDialog: boolean;
  isAccidentalClickProtected = true;


  elogbooksLink = '';

  constructor(private apiService: APIService) { }

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.isWorking = true;
    this.apiService
      .postQuery({ t: 'integrations', action: 'get-integrator', id: 'elogbooks', oid: this.apiService.getUserOrg().id })
      .then(r => {
        this.isWorking = false;
        if (!r || r.length === 0) {
          this.integration = new Integration({ integrator: 'elogbooks' });
          return;
        }
        this.integration = new Integration({
          integrator: 'elogbooks', ...r[0]
        });
        this.elogbooksLink = 'http://' + this.integration.url.replace('-api', '');
        console.log(r);
      });

    this.apiService
      .postQuery({ t: 'integrations', action: 'get-stats', id: 'elogbooks', oid: this.apiService.getUserOrg().id })
      .then(r => {
        if (!r || r.length === 0) {
          this.stats = null;
          return;
        }
        this.stats = r;

        // base64 -- atob('L3NpdGVzLzEvam9icy8xMg==')
        // "/sites/1/jobs/12"
        /* r.jobs.forEach(job => {
           job.uuid = 
         });*/
        console.log(r);
      });
  }

  b64(job_id) {
    return btoa('/sites/1/jobs/' + job_id);
  }

  test(operation: 'sites' | 'user') {
    this.sitesDialog = false;
    this.userDialog = false;
    switch (operation) {
      case 'sites':
        this.sitesDialog = true;
        break;
      case 'user':
        this.userDialog = true;
        break;
    }
  }

  save() {
    const payload: IPostQuery = {
      t: 'integrations',
      action: 'update',
      oid: this.apiService.getUserOrg().id,
      changes: this.integration.serialise()
    };
    this.apiService
      .postQuery(payload)
      .then(r => {
        this.apiService.toastSuccess('Integration updated', '');
        console.log(r);
        this.get();
      });
  }


  elogbooksPopupDismissed(site: ElogbooksSite) {
    if (site) {
      this.apiService.toastSuccess(site.name, site.reference);
    }
    this.sitesDialog = false;
  }
}

export class Integration {
  id: number;
  integrator: 'elogbooks';
  settings: any;
  isActive: boolean;
  url: string;

  constructor(data: any) {
    this.id = data.id;
    this.integrator = data.integrator;
    this.url = data.url;
    switch (this.integrator) {
      case 'elogbooks':
        this.elogbooks(data);
    }
  }

  elogbooks(data: any) {
    this.settings = {
      siteContactAvailableOnSite: false,
      siteContactSameAsReporter: true,
      notifyOnCreate: true,
      notifyOnComplete: true,
      token: null,
      userid: null,
      duplicate: 1,
      client: null,
      https: false
    };
    if (data.settings) {
      this.settings = {
        ...this.settings, ...(JSON.parse(data.settings))
      }
    }
  }

  serialise() {
    return {
      id: this.id,
      url: this.url,
      integrator: this.integrator,
      settings: this.settings
    }
  }
}

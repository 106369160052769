<article>
	<section>
		<h1>Request more info</h1>
		<p>If you'd like to arrange a demo, get a quote or you simply have more questions, contact us today!</p>
	</section>
	<section>

		<div class="form">
			<div>
				<input [(ngModel)]="contact.name" placeholder="Name">
				<input [(ngModel)]="contact.email" placeholder="Email">
				<input [(ngModel)]="contact.company" placeholder="Company">
			</div>
			<div>
				<textarea rows="5" [(ngModel)]="contact.message" placeholder="Message"></textarea>
			</div>
		</div>

		<p>This form collects your name, email address, phone number and any other information you include in your message,
			so that we can respond to your enquiry. Please read our privacy policy for the full details on how we use, protect
			and manage your submitted data.</p>

		<p><input type="checkbox" [(ngModel)]="contact.terms"> I have read and understood the privacy policy and consent to
			4D Monitoring collecting and using my name, and email
			address, as well as other details I include in my enquiry, for the purposes outlined in the privacy policy.

		</p>

	</section>
</article>
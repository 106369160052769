@defer(when complianceItem()) {
<app-page-title [label]="complianceItem().title" [canGoBack]="true" (click)="goBack()"></app-page-title>

<div>
	Runs every {{complianceItem().frequency}}, created {{complianceItem().createdAt | amDateFormat:'DD MMM YYYY'}}
</div>


<app-toolbar>
	<div class="right"><app-button label="Disable" icon="pi pi-times" (click)="disable()"></app-button> &nbsp;
		<app-button-assets label="Add Assets" (onSelect)="assetsSelected($event)" [selectOnly]="true"></app-button-assets>
	</div>
</app-toolbar>
@if(complianceItem().assets?.length) {

<table class="table table-hover">
	<thead>
		<tr>
			<th>Asset</th>
		</tr>
	</thead>
	<tbody>
		@for(asset of complianceItem().assets;track asset.id) {
		<tr (click)="assetClick(asset)">
			<td>{{asset.title}}</td>
		</tr>
		}
	</tbody>
</table>

@if(asset()) {
<app-dialog [header]="asset().title" (onClose)="asset.set(null)" [dimensions]="{height:150, width: 460}">
	<app-toolbar>
		<app-button label="Remove from compliance" (click)="removeAsset()" />
	</app-toolbar>
	<div class="mt-1">

		Site: {{asset().site.title}}

	</div>

</app-dialog>
}

} @else {
No assets.
}

}

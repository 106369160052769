<article>
	<section class="map">
		<div>
			<app-map *ngIf="_sites?.length" [sites]="_sites"></app-map>
		</div>
		<div *ngIf="site" class="site">
			<header class="close-panel text-right" (click)="site=null"><i class="fa fa-times"></i></header>
			<div>
				<app-explorer-site [site]="site" [showMapControls]="false" [showId]="false"
					(onSelected)="gatewaySelected($event)" [canNavigate]="true" [weather]="site.weather"></app-explorer-site>
			</div>
		</div>
	</section>
</article>

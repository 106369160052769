@defer(when rows) {
<main>
	<div class="header">
		<div>
			<div>Mo</div>
		</div>
		<div>
			<div>Tu</div>
		</div>
		<div>
			<div>We</div>
		</div>
		<div>
			<div>Th</div>
		</div>
		<div>
			<div>Fr</div>
		</div>
		<div>
			<div>Sa</div>
		</div>
		<div>
			<div>Su</div>
		</div>
	</div>
	@for(row of rows;track row ) {
	<div class="week">
		@for(day of row;track day) {
		<div [class.first-of-month]="day.dayOfMonth===1" [class.previous-month]="day.previousMonth" [class.is-future]="day.isFuture" [class.has-items]="day.hasItems" [pTooltip]="day.tooltip" [class.is-today]="day.isToday" [class.top-border]="day.topBorder">
			<div>{{day.date | amDateFormat:'D'}}</div>
		</div>
		}
	</div>
	}
</main>
} @placeholder {
<i class="mdi mdi-loading mdi-spin-x2"></i>
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ReportService } from '../../shared/report.service';
import { User } from '../../classes/user';
import { Org } from '../../classes/org';
import { Report } from '../../classes/reports/report';
import { ReportBlock } from '../../classes/reports/report-block';
import { ReportFragment } from '../../classes/reports/report-fragment';


@Component({
  selector: 'app-report-count-by-day',
  templateUrl: './report-count-by-day.component.html',
  styleUrls: ['./report-count-by-day.component.css']
})
export class ReportCountByDayComponent implements OnInit {

  // Raw data received from the API
  @Input()
  data: any;

  @Input()
  config: any;

  @Input()
  reportBlock: ReportBlock;

  @Input()
  blockReady: boolean;

  @Output()
  blockReadyChange = new EventEmitter<boolean>();


  // Take the raw "data", generate the chart data into "chartData"
  chartData: any;

  constructor(private reportService: ReportService) { }

  ngOnInit() {
    this.prepareData();
  }

  prepareData() {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    let chartData = {
      labels: days,
      datasets: [],
      percTotalChange: null
    };

    let dateFrom, dayFrom;

    for (var week = 0; week < 2; week++) {
      let daySet = {
        label: (week ? 'Previous week' : 'Current week'),
        backgroundColor: this.reportService.colourForDay(days[week]),
        data: [],
        perc: [],
        total: 0,
        fill: true
      };
      if (week === 0) {
        dateFrom = new Date(this.config.startAt);
      } else {
        dateFrom = new Date(this.config.previousStartAt);
      }

      let weekData = this.data[week];

      for (var dayx = 0; dayx < 7; dayx++) {
        dayFrom = dateFrom.getDate();
        let value = 0;
        for (var idx = 0; idx < weekData.length; idx++) {
          if (weekData[idx].day == dayFrom) {
            value = weekData[idx].value;
          }
        }
        daySet.total += value;
        daySet.data.push(value);
        if (week === 1) {
          // On previous week, work out perc from "This week"

          let previousValue = chartData.datasets[0].data[dayx];
          if (previousValue || value) {
            let perc;
            if (previousValue > value) {
              perc = String((previousValue - value) / (previousValue / 100));
            } else {
              perc = String(((value - previousValue) / (value / 100)) * -1);
            }

            daySet.perc.push(parseFloat(perc).toFixed(1));
          } else {
            daySet.perc.push(parseFloat("0").toFixed(1));
          }
        }
        dateFrom.setDate(dateFrom.getDate() + 1);
      }
      chartData.datasets.push(daySet);
    }
    if (chartData.datasets[0].total > chartData.datasets[1].total) {
      chartData.percTotalChange = parseFloat(String((chartData.datasets[0].total - chartData.datasets[1].total) / (chartData.datasets[0].total / 100))).toFixed(1);
    } else if (chartData.datasets[0].total == 0 && chartData.datasets[1].total == 0) {
      chartData.percTotalChange = "0.0";
    } else {
      chartData.percTotalChange = parseFloat(String((chartData.datasets[1].total - chartData.datasets[0].total) / (chartData.datasets[1].total / 100))).toFixed(1);
      chartData.percTotalChange *= -1;
    }

    this.chartData = chartData;
    this.blockReady = true;
    this.blockReadyChange.emit(this.blockReady);
  }
}

import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { StoreService } from '../../shared/store.service';
import { ReportConfig } from '../../classes/reports/report-config';
import { Report } from '../../classes/reports/report';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.css']
})
export class ReportViewComponent implements OnInit, AfterViewInit {

  @ViewChild('viewContainer', { static: true }) viewContainer;

  _masterReportConfig: ReportConfig;
  _reportConfigs: ReportConfig[] = [];
  @Input()
  report: Report;
  @Input()
  set reportConfig(value: ReportConfig) {
    if (value) {
      this.createdAt = new Date();
      this._masterReportConfig = value;
      this._reportConfigs = [];
      // Make this work 100% compatible with old reporting mechanism, but multpile gateways
      for (let index = 0; index < value.forGateways.length; index++) {
        const gateway = value.forGateways[index];
        this._reportConfigs.push(new ReportConfig(value, gateway));
      }

      // console.log('report-view got new config', value);
    }
  }
  createdAt: any;
  wantsPrintPreview: boolean;

  @Output()
  onLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private apiService: APIService,
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService) {
  }

  ngOnInit() {
    //this.report = new Report();
    this.createdAt = new Date();
    //this.route.params.subscribe((params: Params) => {
    //	this.reportId = params['reportid'];
    //	console.log('Loading report #' + this.reportId);

    //	return this.apiService.getReport(this.reportId).then(
    //		(report) => {
    //			console.log('got the report, ' + report.blocks.length + ' blocks.');
    //			this.report = report;
    //		}
    //	);
    //	});

    //  console.log('ready to view', this.report);
  }

  ngAfterViewInit(): void {
    // console.log('ReportViewComponent AfgerViewInit()', this.viewContainer);
  }

  hasLoaded(block: any) {
    // Let the caller know that at least one has loaded.
    this.onLoaded.emit(true);
  }

  configToggle() {
    this.wantsPrintPreview = !this.wantsPrintPreview;
  }
}

import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { APIService } from '../../shared/api.service';
import { Ticket } from '../../classes/ticket';
import { Message } from '../../classes/message';
import { AssetTicket } from '../../classes/asset-ticket';
import { GatewayTicket } from '../../classes/gateway-ticket';
import { SiteTicket } from '../../classes/site-ticket';
import { TicketService } from "../../shared/ticket.service";
import { TicketKind } from "../../classes/factory";
import { Subject } from 'rxjs';


@Component({
  selector: 'app-issue-dashboard',
  templateUrl: './issue-dashboard.component.html',
  styleUrls: ['./issue-dashboard.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(100, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        style({ height: 'auto' }),
        animate(100, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class IssueDashboardComponent implements OnInit {

  init: boolean;
  tickets: Ticket[];
  filter: TicketKind;
  ticketSubscription: Subject<TicketKind>;
  ticketKind: any;

  constructor(private ticketService: TicketService, private apiService: APIService) {
    this.ticketKind = TicketKind;
  }

  ngOnInit() {
    this.apiService.getTickets().then(tickets => {
      let assetTickets = tickets[0];
      let gatewayTickets = tickets[2];
      let siteTickets = tickets[4];

      this.tickets = assetTickets.map(ticket => new AssetTicket(ticket));
      gatewayTickets.map(ticket => this.tickets.push(new GatewayTicket(ticket)));
      siteTickets.map(ticket => this.tickets.push(new SiteTicket(ticket)));

      let assetMessages: any[] = tickets[1];
      assetMessages.concat(tickets[3]);
      assetMessages.concat(tickets[5]);
      assetMessages.map(assetMessage => {
        let message = new Message(assetMessage);
        this.tickets.map(ticket => {
          if (ticket.id === assetMessage.ticketId) {
            ticket.messages.push(message);
          }
        })
      });
    });

    this.ticketService.ticketFilter.subscribe(filter => {
      // Filter the onscreen tickets
      console.log('got ', filter)
      this.filter = filter;
    });
  }

  ngOnDestroy() {
    if (this.ticketSubscription) {
      this.ticketSubscription.unsubscribe();
    }
  }
}

import { SiteFloorplanAsset } from "./site-floorplan-asset";

export class SiteFloorplanShape {
	id: number;
	title: string;
	svg: string;
	siteFloorplanId: number;
	siteFloorplanAreaId: number;
	createdAt: Date;
	purpose: string;
	atRAG: string = 'green';
	upData: string;
	upDataValue: number;
	upDataOp: '=' | '<' | '>' | '>=' | '<=' | '!=';
	category: string;
	panel = {
		x: null,
		y: null,
		width: null,
		height: null,
		ox: null,
		oy: null
	}
	assets: SiteFloorplanAsset[] = [];
	isSelected = false;

	constructor(data: ISiteFoorPlanShapeAPI) {
		this.id = data.id;
		this.title = data.title;
		this.svg = data.svg;
		this.createdAt = data.createdAt ? new Date(data.createdAt) : null;
		this.siteFloorplanId = data.site_floorplan_id;
		this.siteFloorplanAreaId = data.site_floorplan_area_id;
		this.purpose = data.purpose;
		this.upData = data.upData;
		this.upDataOp = data.upDataOp;
		this.upDataValue = data.upDataValue;
		this.category = data.category;
		this.panel = {
			x: data.panelX,
			y: data.panelY,
			width: data.panelWidth,
			height: data.panelHeight,
			ox: data.panelOX,
			oy: data.panelOY
		}
	}
}

export interface ISiteFoorPlanShapeAPI {
	id: number;
	title: string;
	svg: string;
	createdAt: Date;
	site_floorplan_id: number;
	site_floorplan_area_id: number;
	panelX: number;
	panelY: number;
	panelWidth: number;
	panelHeight: number;
	panelOX: number;
	panelOY: number;
	purpose: string;
	upData: string;
	upDataOp: '=' | '<' | '>' | '>=' | '<=' | '!=';
	upDataValue: number;
	category: string;
}

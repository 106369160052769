import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { UserSelection } from 'app/classes/user';
import { APIService } from './api.service';
import { UserService } from './user.service';

@Injectable()
export class AuthGuardService  {

  constructor(private apiService: APIService, private router: Router, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // User must accept terms
    // let hasApprovedTerms = this.apiService.hasApprovedTerms();

    const hasApprovedTerms = true;

    if (hasApprovedTerms) {
      if (this.apiService.getIsRestricted()) {
        console.log('RESTRICTED USER');
        if (state.url !== '/login' && state.url !== '/profile' && state.url !== '/invite' && state.url.indexOf('/public') === -1) {
          console.log('checking modules against ' + this.apiService.getRestrictedModule());
          switch (this.apiService.getRestrictedModule()) {
            case 'live':
              if (state.url.indexOf('/live') === -1) {
                this.apiService.toastWarn('You do not have access to this.', '');
                return false;
              }
              break;
            case 'profiling':
              if (state.url.indexOf('/profiling') === -1) {
                this.apiService.toastWarn('You do not have access to this.', '');
                return false;
              }
              break;
          }
          console.log(state.url);
        }
      }

      console.log(this.apiService.getUserId() + ' authorised for ', state.url);
      this.apiService.postRoute(state.url);
      // @todo create this on backend
      // this.userService.getRouteConfig(state.url);

      return true;
    } else {
      if (this.apiService.getUserId()) {
        // they are logged in, get them to accept the terms of the site.
        console.log('need to accept terms');
        this.router.navigate(['/terms']);

        return true;
      } else {
        this.router.navigate(['/login'], {
          queryParams: {
            return: state.url
          }
        });

        return false;
      }
    }
  }
}

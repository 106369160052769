<div class="alarm" *ngIf="alarm && alarm.rulePackage" [class.has-plan]="floorplan" [class.no-plan]="floorplan===null">
  <p-toolbar>
    <!--<div  class="ui-toolbar-group-left">
      <button (click)="addNote()" [disabled]="alarm.state === 'resolved'" pButton type="button" label="Add note" icon="fa fa-plus"
        class="ui-button-secondary"></button>
    </div>-->

    <div class="p-toolbar-group-right">
      <p-button (click)="toState('acknowledged')" [disabled]="alarm.state !== 'new' && alarm.state !=='paused'" label="Acknowledge" icon="fa fa-eye"></p-button>
      <p-button (click)="toState('paused')" [disabled]="alarm.state === 'resolved' || alarm.state === 'paused'" label="Pause" icon="fa fa-pause"></p-button>
      <p-button (click)="toState('resolved')" [disabled]="alarm.state === 'resolved'" label="Resolve" icon="fa fa-check"></p-button>
    </div>
  </p-toolbar>

  <div class="alarm-slider" *ngIf="showDialog" #mytoolbar>
    <div class="slider-container">
      <div class="showDialog === 'add-note'">
        <textarea placeholder="Type note..." class="form-control" [rows]="5"></textarea>
        <button class="btn btn-primary">Save Note</button>
      </div>
    </div>
  </div>

  <div class="col-xs-12 " *ngIf="alarm.state === 'paused'">
    <div class="paused-help">
      <h3>This alarm is paused</h3>
      <p>When an alarm is paused it cannot be automatically resolved by sensors.</p>
      <p>This alarm will stay paused indefinitely until:</p>
      <ul>
        <li>
          <button (click)="toState('acknowledged')" [disabled]="alarm.state !== 'new' && alarm.state !=='paused'" type="button" icon="fa fa-eye" class="btn-sm">Acknowledge</button> is selected
          and the alarm waits for sensors to resolve the alarm
        </li>

        <li>
          <button (click)="toState('resolved')" [disabled]="alarm.state === 'resolved'" type="button" label="" icon="fa fa-check" class="btn-sm">Resolve</button> is selected and the alarm is
          immediately resolved
        </li>

      </ul>
    </div>

  </div>

  <div class="col-xs-4 col-sm-3 field">
    Rule
  </div>
  <div class="col-xs-8 col-sm-9">
    <a [routerLink]="['/rules', alarm.rulePackage.id]">{{alarm.rulePackage.title}}</a>
  </div>

  <div class="col-xs-4 col-sm-3 field">
    Created
  </div>
  <div class="col-xs-8 col-sm-9">
    {{alarm.createdAt | amTimeAgo}}
  </div>

  <div class="col-xs-4 col-sm-3 field">
    State
  </div>
  <div class="col-xs-8 col-sm-9">
    {{alarm.state}}
  </div>

  <div class="col-xs-4 col-sm-3 field">
    Severity
  </div>
  <div class="col-xs-8  col-sm-9">
    {{alarm.rulePackage.severity}}
  </div>

  <!-- floorplan asset -->
  <div class="col-xs-12 col-sm-7" *ngIf="alarm.rulePackage.conditions.length">
    <div class="floorplan-wrapper">
      <app-floorplan-asset [assetId]="alarm.rulePackage.conditions[0].leftAsset_id" (onLoad)="planLoaded($event)">
      </app-floorplan-asset>
    </div>
  </div>

  <div class="timeline col-xs-12" [class.col-sm-6]="floorplan" [class.col-sm-12]="!floorplan">
    <h4>Timeline</h4>

    <p-scrollPanel [style]="{width: '100%', height: '200px'}">
      <table class="table">
        <thead>
          <tr>
            <th>Action</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of alarm.timeline;let i=index">
            <td>

              <div *ngIf="item.action === 'email'">
                <span><i class="fa fa-envelope fa-fw"></i> emailed {{item.value}}</span>
              </div>
              <div *ngIf="item.action === 'state_change'">
                <span><i class="fa fa-level-down fa-fw"></i> state changed to "{{item.value}}"</span>
              </div>
              <div *ngIf="item.action === 'notes'">
                <span><i class="fa fa-pencil-square-o  fa-fw"></i> notes added - "{{item.value}}"</span>
              </div>


            </td>
            <td>{{item.createdAt | amTimeAgo}}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </p-scrollPanel>

  </div>

  <!-- instructions -->
  <div class="col-xs-12 col-sm-12" *ngIf="alarm.state !== 'resolved' && alarm.rulePackage.instructions">
    <div class="instructions-wrapper">
      <div class="instructions">
        {{alarm.rulePackage.instructions}}
      </div>
    </div>
  </div>
</div>

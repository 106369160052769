<!-- Selected objects -->
<div class="selected" *ngIf="selectedSite">
  @if(selectedSite) {
  <div>
    <div class="col-xs-3 col-sm-2">
      Site
    </div>
    <div class="col-xs-9 col-sm-10">
      : <button class="btn btn-secondary" (click)="cancelSite()"><i class="fa fa-times"></i>
        {{selectedSite.title}}</button>
    </div>
  </div>
  }
  @if(selectedGateway) {
  <div>
    <div class="col-xs-3 col-sm-2">
      Gateway
    </div>
    <div class="col-xs-9 col-sm-10">
      : <button class="btn btn-secondary" (click)="cancelGateway()"><i class="fa fa-times"></i>
        {{selectedGateway.title}}</button>
    </div>
  </div>
  }
  @if(selectedAsset) {
  <div>
    <div class="col-xs-3 col-sm-2">
      Sensor
    </div>
    <div class="col-xs-9 col-sm-10">
      : <button class="btn btn-secondary" (click)="cancelAsset()"><i class="fa fa-times"></i>
        {{selectedAsset.title}}</button>
    </div>
  </div>
  }

</div>

<!-- Objects awaiting selection -->
@if(!selectedSite) {
<div class="create-condition">
  <h4>Select a <strong>site</strong></h4>
  <app-site-picker (submit)="siteSelected($event)"></app-site-picker>
</div>
} @else if (selectedSite && !selectedGateway) {
<div class="create-condition">
  <h4>Select a <strong>gateway</strong> for {{selectedSite.title}}</h4>
  <app-gateway-picker [site]="selectedSite" (submit)="gatewaySelected($event)"></app-gateway-picker>
</div>
} @else if (selectedGateway && !selectedAsset) {
<div class="create-condition">
  <h4>Select a <strong>sensor</strong> for {{selectedGateway.title}}</h4>
  <app-sensor-picker [gateway]="selectedGateway" (submit)="sensorSelected($event)"></app-sensor-picker>
</div>
}
<!-- Final section -->
@if(selectedAsset){
<div class="condition">

  <div class="col-xs-12 col-sm-5 asset">
    {{selectedAsset.title}}
  </div>
  <div class="col-xs-12 col-sm-2 value">
    <select class="form-control" [(ngModel)]="trigger">
      <option value="new">New</option>
      <option value="resolved">Resolved</option>
    </select>
  </div>

  <div class="col-xs-12 col-sm-5 value">

    <input *ngIf="selectedAsset.purpose?.id !== 6" class="form-control" name="value" [(ngModel)]="value" placeholder="Enter value">
    <select class="form-control" *ngIf="selectedAsset.purpose?.id === 6" [(ngModel)]="value">
      <option value="In Use">In Use</option>
      <option value="Free">Free</option>
    </select>
  </div>

  <div class="clearfix actions-spacer"></div>
  <div class="actions" *ngIf="value && selectedAsset">
    <div class="col-xs-6">
      <button (click)="submitCondition()" class="btn btn-success btn-block">Save action</button>
    </div>
    <div class="col-xs-6">
      <button (click)="cancelCondition()" class="btn btn-secondary btn-block">Cancel</button>
    </div>
  </div>
  <div class="clearfix actions-spacer"></div>
</div>
}

<main>
  <h4 *ngIf="asset">
    <app-asset-icon [asset]="asset"></app-asset-icon> {{ asset.title }}
    <i
      *ngIf="missingDaysCount"
      class="mdi mdi-alert red"
      pTooltip="Missing data for {{ missingDaysCount }} days"
    ></i>
  </h4>
  <h5 *ngIf="asset">{{ asset.gateway.title }}</h5>
  <!--
    <input [(ngModel)]="height" placeholder="h">
    <input [(ngModel)]="width" placeholder="w">
    <input [(ngModel)]="margin.left" placeholder="ml">
    <input [(ngModel)]="margin.right" placeholder="mr">
    <input [(ngModel)]="margin.top" placeholder="mt">
    <input [(ngModel)]="margin.bottom" placeholder="mb">
--></main>
<section [class.row]="presentation === 'row'">
  <div class="chart" #svg></div>
  <div *ngIf="presentation === 'row'" title=" {{ hoverItem | json }}">
    <div class="hover-item">
      <div>
        <span>Date</span>
        <span> {{ hoverItem?.date | amDateFormat: "dddd Do MMMM" }}</span>
      </div>
      <div>
        <span>Min</span>
        <span> {{ hoverItem?.min | number: "0.2-2" }}</span>
      </div>
      <div>
        <span>Avg</span>
        <span> {{ hoverItem?.avg | number: "0.2-2" }}</span>
      </div>
      <div>
        <span>Max</span>
        <span> {{ hoverItem?.max | number: "0.2-2" }}</span>
      </div>
      <div>
        <span>RAG</span>
        <span> {{ ["Green", "Amber", "Red"][hoverItem?.rag] }}</span>
      </div>
    </div>
  </div>
</section>

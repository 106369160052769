<div *ngIf="report">
	<app-page-title [label]="report?.title" [canGoBack]="true" routerLink="/reports"></app-page-title>
	<div class="col-md-12">
		<div *ngIf="!runReport && report">


			<div class="row">
				<div class="col-sm-6 calendar">
					<div class="config-block">

						<p-calendar *ngIf="report.dateSpan === 'week'" [disabledDays]="[0,2,3,4,5,6]" [(ngModel)]="reportConfig.startAt" [dateFormat]="getDateFormat().toLowerCase()" [locale]="en" [inline]="true" [showIcon]="true" firstDayOfWeek="1"></p-calendar>


						<p-calendar *ngIf="report.dateSpan === 'month'" [(ngModel)]="reportConfig.startAt" view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="2020:2021" [readonlyInput]="true" [inline]="true" [locale]="en"></p-calendar>

					</div>
				</div>
				<div class="col-sm-6">
					<div class="config-block">
						<h3 class="section">Report Options</h3>

						<app-toolbar>
							<div class="right">
								<!--
								<app-tick-button [title]="defaultTab === 0 ? 'Report on all gateways' : 'Report on all favourites'" [(selected)]="reportAllGateways"></app-tick-button>-->
								<app-button [disabled]="(selectedCount === 0 && !reportAllGateways) || !reportConfig.startAt" type="button" (click)="runReportClick()" label="Generate report" icon="mdi mdi-play" />
							</div>
						</app-toolbar>


						<!--<br/>
							<h3 class="section">Report Info</h3>
							<div class="report-info">
								<p>{{details}}</p>
							</div>-->
					</div>
				</div>
			</div>
			<div class="row">
				<!-- asset picker not required currently, replaced by gateway picker -->

				<div class="col-sm-12" *ngIf="report.forGateway==='Y' && !reportAllGateways">
					<div class="component-list">
						<p-tabView (onChange)="handleChange($event)">

							<!-- ALL GATEWAYS TAB -->
							<p-tabPanel header="All Gateways" [cache]="false">

								<ng-template pTemplate="content">
									<app-report-gateway-list *ngIf="gateways" [(gateways)]="gateways" (gatewayToggle)="gatewayToggle($event)"></app-report-gateway-list>
									<div *ngIf="!gateways">
										Please wait...
									</div>
								</ng-template>

							</p-tabPanel>
							<!-- FAVOURITE GATEWAYS TAB -->
							<p-tabPanel header="Favourite Gateways" [selected]="defaultTab===1" [cache]="false">
								<ng-template pTemplate="content">
									<app-report-gateway-list [(gateways)]="gateways" show="favourites" (gatewayToggle)="gatewayToggle($event)"></app-report-gateway-list>
								</ng-template>
							</p-tabPanel>
						</p-tabView>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<button class="btn btn-block btn-primary" [disabled]="(selectedCount === 0 && !reportAllGateways) || !reportConfig.startAt" type="button" (click)="runReportClick()">Generate report</button>
					<br />
				</div>
				<div class="col-sm-12">

				</div>
			</div>
		</div>
		<div *ngIf="runReport" class="generated">
			<div class="overview">
				<div *ngIf="reportConfig.forAsset?.id">
					<div class="row">
						<div class="col-md-2 col-sm-3">Asset</div>
						<div class="col-md-10 col-sm-9">
							<app-asset-overview [asset]="reportConfig.forAsset" [showLocation]=true [showSite]=true>
							</app-asset-overview>
						</div>
					</div>
				</div>
				<div *ngIf="reportConfig.startAt">
					<div class="row date-from no-print">
						<div class="col-sm-12 navigation" [class.disabled]="reportsRunning">
							<div class="text-center">
								<i *ngIf="report.dateSpan === 'week'" pTooltip="Back a week" tooltipPosition="bottom" class="fa fa-angle-double-left fa-fw" (click)="reportStartAt(-7)"></i>
								<i [pTooltip]="report.dateSpan === 'week' ? 'previous day' : 'previous month'" tooltipPosition="bottom" class="fa fa-angle-left fa-fw" (click)="reportStartAt(-1)"></i>

								<span class="report-date" *ngIf="report.dateSpan === 'week'">{{startAt | amDateFormat:'DD MMMM
									YYYY'}}</span>
								<span class="report-date" *ngIf="report.dateSpan === 'month'">{{startAt | amDateFormat:'MMMM
									YYYY'}}</span>

								<i [pTooltip]="report.dateSpan === 'week' ? 'next day' : 'next month'" tooltipPosition="bottom" class="fa fa-angle-right fa-fw" (click)="reportStartAt(1)"></i>
								<i *ngIf="report.dateSpan === 'week'" pTooltip="forward a week" tooltipPosition="bottom" class="fa fa-angle-double-right fa-fw" (click)="reportStartAt(7)"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="runReport">
				<p-tabView [activeIndex]="reportTabIndex">
					<p-tabPanel [header]="item.reportConfig.tabTitle" *ngFor="let item of collection; let i = index">
						<app-report-view [report]="item.report" [reportConfig]="item.reportConfig" (onLoaded)="hasLoaded()">
						</app-report-view>
					</p-tabPanel>
				</p-tabView>
			</div>

			<!--	<app-report-view [report]="report" [reportConfig]="reportConfig" (onLoaded)="hasLoaded()"></app-report-view> -->
		</div>
		<div *ngIf="!runReport && !report"><i class="fa fa-refresh fa-spin"></i></div>
	</div>
</div>

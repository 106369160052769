<section *ngIf="isReady">

	<table class="table table-hover" *ngIf="building.licenses && building.licenses.length">
		<thead>
			<th>License</th>
			<th class="text-right">Value</th>
			<th class="text-right expire">License Expires</th>
		</thead>
		<tbody>
			<tr *ngFor="let license of building.licenses" (click)="setBuildingLicense(building,license)">
				<td>{{license.package.title}} <span class="badge" *ngIf="!license.isActive">disabled</span></td>
				<td class="text-right">{{license.value}}</td>
				<td class="text-right">
					<span *ngIf="license.expiresAt">{{license.expiresAt |amDateFormat:'DD MMM YYYY'}}</span>

					<span *ngIf="!license.expiresAt">No license</span>
				</td>
			</tr>
		</tbody>
	</table>

	<section *ngIf="building.licenses && !building.licenses.length">
		<app-theme-page-section title="Create building license">
			<app-building-license-home [building]="building" [gateway]="null" (onSave)="licenseUpdated()">
			</app-building-license-home>
		</app-theme-page-section>
	</section>

	<i *ngIf="!building.licenses" class="fa fa-spin fa-fw fa-refresh"></i>

	<!-- GATEWAYS -->

	<table class="table table-hover">
		<thead>
			<tr>
				<th>Gateway</th>
				<th>Title</th>
				<th>Status</th>
				<th>License</th>
				<th class="text-right">Value</th>
				<th class="text-right expire">License Expires</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let gateway of gateways" (click)="setGatewayLicense(gateway)">
				<td>{{gateway.id}}</td>
				<td>{{gateway.title}}</td>
				<td>{{gateway.statusTitle}}</td>
				<td colspan="2" *ngIf="!gateway.license?.id">Included in building license</td>
				<td *ngIf="gateway.license?.id">
					{{gateway.license.package.title}} <span class="badge" *ngIf="!gateway.license.isActive">disabled</span>
				</td>
				<td *ngIf="gateway.license?.id" class="text-right">
					{{gateway.license.value}}
				</td>
				<td *ngIf="gateway.license?.id" class="text-right">
					<span *ngIf="gateway.license">{{gateway.license.expiresAt | amDateFormat:'DD MMM YYYY'}}</span>
				</td>
			</tr>
		</tbody>
	</table>
	<i *ngIf="!gateways" class="fa fa-spin fa-fw fa-refresh"></i>
</section>

<!--
<section *ngIf="selected">
	<app-theme-page-section>
		<app-theme-page-container
			[title]="'License ' + (selected.gateway ? ' for gateway ' + selected.gateway.title : 'for building')"
			[canClose]="true" (onClose)="selected=null">
			<app-theme-page-section>
				<app-building-license-home [building]="building" [gateway]="selected.gateway"
					(onSave)="licenseUpdated($event)">
				</app-building-license-home>
			</app-theme-page-section>
		</app-theme-page-container>
	</app-theme-page-section>
</section>
-->

<app-theme-page-section>
	<ul>
		<li>Each building has a "default" license that covers all unlicensed gateways in the building</li>
		<li>Licenses can be added to specific gateways</li>
	</ul>
</app-theme-page-section>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

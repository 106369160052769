<table class="table">
	<thead>
		<tr>
			<th>Mon</th>
			<th>Tue</th>
			<th>Wed</th>
			<th>Thu</th>
			<th>Fri</th>
			<th>Sat</th>
			<th>Sun</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let week of weeks">
			<td *ngFor="let day of week" [class.today]="day.isToday" [class.invalid]="day.isInvalid"
				[class.has-processing]="day.hasProcessing" tooltipPosition="top"
				[pTooltip]="day.isToday ? (day.count ? day.count + ' schedules to process this evening' : 'Nothing scheduled this evening') : ''">
				{{day.date}} <span *ngIf="day.count">{{day.count}} to process</span></td>
		</tr>
	</tbody>
</table>

import { Component, computed, input, model, OnInit, output, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Site } from 'app/classes/site';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { CombinedCollectionItem, SiteService } from 'app/shared/site.service';

@Component({
  selector: 'app-site-collection',
  templateUrl: './site-collection.component.html',
  styleUrl: './site-collection.component.css'
})
export class SiteCollectionComponent implements OnInit {
  site = input.required<Site>();
  collection = model.required<CombinedCollectionItem>();
  canUpdate = input.required<boolean>();
  assets = signal<Asset[]>(null);

  onUpdated = output<CombinedCollectionItem>();

  canDelete: 0 | 1 | 2 = 0;

  mode = signal<'view' | 'edit'>('view');
  isDirty = signal<boolean>(false);

  list = signal<IListAsset[]>(null);

  unfilteredList: IListAsset[];

  types: { id: number, title: string }[];

  selectedCount = computed(() => {
    console.log('selectedCount()');
    return this.list()?.filter(a => a.checked).length;
  });

  constructor(private assetService: AssetService, private siteService: SiteService, private apiService: APIService) {
    this.types = CombinedCollectionItem.TYPES.map((t, index) => { return { id: index, title: t } });
  }

  ngOnInit(): void {
    // force edit mode if creating.
    if (!this.collection().id) {
      this.setMode('edit');
    }
  }

  async switchMode() {
    await this.setMode(this.mode() === 'view' ? 'edit' : 'view');
    this.list.set(this.filter());
  }

  itemChecked(item: IListAsset) {
    this.list.update(list => {
      item.checked = !item.checked;

      return list;
    });
  }

  async delete() {
    if (this.canDelete === 2) {
      this.apiService.toastSuccess('deleting..', '');
      await this.siteService.deleteCollection(this.collection().id);
      this.apiService.toastSuccess('deleted.', '');
      this.onUpdated.emit(this.collection());
    }
    this.canDelete = 1;
    setTimeout(() => {
      this.canDelete = 2;
    }, 2000);
  }

  async setMode(mode: 'view' | 'edit') {
    this.mode.set(mode);

    const assets = await this.assetService.getAssetsForSite(this.site().id);

    this.assets.set(assets);
    this.unfilteredList = assets.map(a => { return { id: a.id, value: a.value, updatedAt: a.updatedAt, checked: this.collection().assets?.some(x => x.id === a.id), title: a.title, assetType_id: a.assetType_id, gateway: { id: a.gateway_id, title: a.gatewayTitle } } });
  }

  onAssetSelected(assetId: number) {
    console.log(assetId);
    if (assetId) {
      this.isDirty.set(true);
      this.addAsset(+assetId);
    }
  }

  addAsset(assetId: number) {
    const asset = this.assets().find(a => +a.id === +assetId);
    this.collection.update(collection => {
      collection.addAsset(asset);
      return collection;
    });

    console.log(this.collection().assets);
  }

  async save() {
    if (!this.collection().type?.id) {
      this.apiService.toastWarn('Please select a type', '');
      return;
    }

    this.collection().assets = this.list().filter(i => i.checked).map(a => { return { id: a.id, value: a.value, typeId: a.assetType_id, title: a.title, gateway: a.gateway } })

    this.apiService.toastSuccess('saving..', '');
    await this.siteService.updateCollection(this.collection());
    this.apiService.toastSuccess('saved', '');
    this.isDirty.set(false);
    this.onUpdated.emit(this.collection());
  }

  filter() {
    console.log(this.collection().type.id);
    switch (this.collection().type.id) {
      case 3:
        //  { CO2: 25, HUMIDITY: 8, TEMPERATURE: 2, VOC: 27, PM1: 62, PM25: 29, PM10: 30 };

        return this.unfilteredList.filter(asset => [2, 8, 25, 27, 62, 29, 30].includes(asset.assetType_id));

      default:
        return this.unfilteredList;
    }
  }

  onTypeSelected(typeIndex: number) {
    this.collection.update(collection => {
      collection.type = { id: +typeIndex, title: CombinedCollectionItem.TYPES[typeIndex] };
      return collection;
    });

    this.list.set(this.filter());
  }
}

export interface IListAsset {
  id: number;
  assetType_id: number;
  value?: string;
  title: string;
  gateway: { id: string, title: string };
  checked: boolean;
}

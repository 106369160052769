<!--<app-page-title label="Rules" [links]="[ {label:'Add rule', icon:'plus', value:'add', type: 'button-sm'} ]" (onLinkClick)="clickAddRule()"></app-page-title>-->

@if( !loading) {

<div class="tabview-list">
    <div [class.active]="activeTabIndex()===0" (click)="tabChanged(0)">Rules <app-chip size="small" [canClick]="false" [label]="countUserRules()" /></div>
    @if(countSetpointRules()) {
    <div [class.active]="activeTabIndex()===1" (click)="tabChanged(1)"><i class="mdi mdi-bullseye"></i> Setpoint Rules <app-chip size="small" [noColour]="true" [canClick]="false" [label]="countSetpointRules()" /></div>
    }
    <div [class.active]="activeTabIndex()===2" (click)="tabChanged(2)"><i class="mdi mdi-alarm"></i> Alarm history</div>
    <div></div>
</div>

<div class="tabview-content">
    @if(activeTabIndex()===0) {

    <app-toolbar>
        <div class="right">
            <app-button label="Add Rule" icon="mdi mdi-plus" (click)="clickAddRule()" />
        </div>
    </app-toolbar>

    @if(countUserRules()) {
    <div class="content-container">
        <table class="table table-striped table-hover listing" aria-label="Rules">
            <thead>
                <tr>
                    <th></th>
                    <th class="col-md-4 can-sort" (click)="headerClicked('title')">
                        <span>Title
                            <i class="fa" [ngClass]="{'fa-angle-down':sort.title==='desc', 'fa-angle-up':sort.title==='asc'}"></i>
                        </span>
                    </th>

                    <th class="hidden-xs hidden-md can-sort" (click)="headerClicked('author')">
                        <span>
                            Author
                            <i class="fa" [ngClass]="{'fa-angle-down':sort.author==='desc', 'fa-angle-up':sort.author==='asc'}"></i>
                        </span>
                    </th>

                    <th class="hidden-xs can-sort" (click)="headerClicked('gateway')">
                        <span>
                            Gateway
                            <i class="fa" [ngClass]="{'fa-angle-down':sort.gateway==='desc', 'fa-angle-up':sort.gateway==='asc'}"></i>
                        </span>
                    </th>

                    <th class="hidden-xs can-sort" tooltipPosition="left" pTooltip="Last alarm date" (click)="headerClicked('alarmed')">
                        <span>
                            Alarmed
                            <i class="fa" [ngClass]="{'fa-angle-down':sort.alarmed==='desc', 'fa-angle-up':sort.alarmed==='asc'}"></i>
                        </span>
                    </th>
                    <th class="hidden-xs hidden-sm"></th>
                    <th class="text-center hidden-xs hidden-sm time">Time</th>
                </tr>
            </thead>
            <tbody>
                @for(inAlert of ['Y','N'];track inAlert; ) {
                @if(inAlert === 'N' && alarms?.length) {
                <tr class="spacer">
                    <td colspan="6">&nbsp;</td>
                </tr>
                }
                @for(rulePackage of rulePackages; track rulePackage.id; let idx = $index) {
                @if(!rulePackage.tag && rulePackage.inAlert === inAlert){
                <tr [routerLink]="[rulePackage.id]" [class.is-first]="idx === 0 && inAlert === 'N'" [class.selected]="highlightRule === rulePackage" [class.in-alert]="rulePackage.inAlert==='Y' && rulePackage.alarm && rulePackage.alarm.state !== 'delayed'" [class.is-delayed]="rulePackage.inAlert==='Y' && rulePackage.alarm && rulePackage.alarm.state === 'delayed'" [class.disabled]="rulePackage.isEnabled==='N'">
                    <td [ngClass]="{
    'background-online': rulePackage.isEnabled === 'Y', 
    'background-offline':rulePackage.inAlert === 'Y' && rulePackage.alarm?.state !== 'delayed',
    'background-standby':rulePackage.inAlert === 'Y' && rulePackage.alarm?.state === 'delayed'
     }">
                    </td>
                    <td>
                        {{rulePackage.title}}
                        @if(rulePackage.alarm?.state === 'paused') {
                        <span pTooltip="Paused, not resolved until unpaused." class="badge">paused</span>
                        }
                        @if(rulePackage.lastAlertedAt) {
                        <div class="hidden-sm hidden-md hidden-lg pull-right">
                            <small>Last alarm was {{rulePackage.lastAlertedAt | date:'dd-MM-YY HH:mm'}}</small>
                        </div>
                        }
                    </td>
                    <td class="hidden-xs hidden-md">
                        {{rulePackage.userName}}
                    </td>
                    <td class="hidden-xs" tooltipPosition="bottom" pTooltip="{{rulePackage.siteTitle}} site.">
                        {{rulePackage.gatewayTitle}}
                    </td>
                    <td class="hidden-xs" tooltipPosition="bottom" pTooltip="{{rulePackage.lastAlertedAt | amDateFormat:'ddd Do MMMM HH:mm'}}">
                        @if(rulePackage.lastAlertedAt) {
                        <span>
                            {{rulePackage.lastAlertedAt | amDateFormat:apiService.getDateFormat()}}
                        </span>
                        }
                    </td>

                    <td class="dow-labels hidden-xs hidden-sm">
                        @if(rulePackage.ruleTimeRanges[0]) {
                        @if(rulePackage.ruleTimeRanges[0].monday) {
                        <span pTooltip="Monday" tooltipPosition="bottom">M</span>
                        }
                        @if(rulePackage.ruleTimeRanges[0].tuesday) {
                        <span pTooltip="Tuesday" tooltipPosition="bottom">T</span>
                        }
                        @if(rulePackage.ruleTimeRanges[0].wednesday) {
                        <span pTooltip="Wednesday" tooltipPosition="bottom">W</span>
                        }
                        @if(rulePackage.ruleTimeRanges[0].thursday) {
                        <span pTooltip="Thursday" tooltipPosition="bottom">T</span>
                        }
                        @if(rulePackage.ruleTimeRanges[0].friday) {
                        <span pTooltip="Friday" tooltipPosition="bottom">F</span>
                        }
                        @if(rulePackage.ruleTimeRanges[0].saturday) {
                        <span pTooltip="Saturday" tooltipPosition="bottom">S<span class="subscript">a</span></span>
                        }
                        @if(rulePackage.ruleTimeRanges[0].sunday) {
                        <span pTooltip="Sunday" tooltipPosition="bottom">S<span class="subscript">u</span></span>
                        }
                        }
                    </td>
                    @if(rulePackage.ruleTimeRanges[0]) {
                    <td class="hidden-xs hidden-sm time">
                        @if(rulePackage.ruleTimeRanges[0].startTimeAt === '00:00' && rulePackage.ruleTimeRanges[0].endTimeAt === '23:59') {
                        <span> All day </span>
                        } @else {
                        {{rulePackage.ruleTimeRanges[0].startTimeAt}} - {{rulePackage.ruleTimeRanges[0].endTimeAt}}
                        }
                    </td>
                    } @else {
                    <td class="hidden-xs hidden-sm time">

                    </td>
                    }
                </tr>
                }
                }
                }
            </tbody>
        </table>
    </div>
    } @else {
    <div class="col-md-12 nothing-returned">
        <app-info-panel>
            <p>Your organisation has no user created rules{{countSetpointRules() ? ', although you do have '+ countSetpointRules() +' setpoint created rules.' : '.'}} </p>
        </app-info-panel>
    </div>
    }
    }
    @if(activeTabIndex()===1) {
    @if(countSetpointRules()) {
    <div class="content-container">
        <table class="table table-striped table-hover listing" aria-label="Setpoint rules">
            <thead>
                <tr>
                    <th></th>

                    <th class="can-sort" (click)="headerClicked('site', sortSetpoints)">
                        <span>Site
                            <i class="fa" [ngClass]="{'fa-angle-down':sortSetpoints.site==='desc', 'fa-angle-up':sortSetpoints.site==='asc'}"></i>
                        </span>
                    </th>
                    <th class=" can-sort" (click)="headerClicked('gateway', sortSetpoints)">
                        <span>Gateway
                            <i class="fa" [ngClass]="{'fa-angle-down':sortSetpoints.gateway==='desc', 'fa-angle-up':sortSetpoints.gateway==='asc'}"></i>
                        </span>
                    </th>

                    <th class=" can-sort" (click)="headerClicked('title', sortSetpoints)">
                        <span>Title
                            <i class="fa" [ngClass]="{'fa-angle-down':sortSetpoints.title==='desc', 'fa-angle-up':sortSetpoints.title==='asc'}"></i>
                        </span>
                    </th>

                    <th class="hidden-xs can-sort alarmed" (click)="headerClicked('alarmed', sortSetpoints)">
                        <span>Alarmed
                            <i class="fa" [ngClass]="{'fa-angle-down':sortSetpoints.alarmed==='desc', 'fa-angle-up':sortSetpoints.alarmed==='asc'}"></i>
                        </span>
                    </th>

                    <th class="text-center hidden-xs">M</th>
                    <th class="text-center hidden-xs">T</th>
                    <th class="text-center hidden-xs">W</th>
                    <th class="text-center hidden-xs">T</th>
                    <th class="text-center hidden-xs">F</th>
                    <th class="text-center hidden-xs">S</th>
                    <th class="text-center hidden-xs">S</th>
                    <th class="text-center hidden-xs hidden-sm hidden-md time">Time</th>
                </tr>
            </thead>
            <tbody>
                @for(rulePackage of rules.setpoints;track rulePackage.id) {

                <tr [routerLink]="['/rules', rulePackage.id]" [class.selected]="highlightRule === rulePackage" [class.in-alert]="rulePackage.inAlert==='Y' && rulePackage.alarm && rulePackage.alarm.state !== 'delayed'" [class.is-delayed]="rulePackage.inAlert==='Y' && rulePackage.alarm && rulePackage.alarm.state === 'delayed'" [class.disabled]="rulePackage.isEnabled==='N'">
                    <td [ngClass]="{
    'background-online': rulePackage.isEnabled === 'Y', 
    'background-offline':rulePackage.inAlert === 'Y' && rulePackage.alarm?.state !== 'delayed',
    'background-standby':rulePackage.inAlert === 'Y' && rulePackage.alarm?.state === 'delayed'
     }">

                    </td>
                    <td>{{rulePackage.siteTitle}} </td>
                    <td>{{rulePackage.gatewayTitle}}</td>
                    <td>
                        {{rulePackage.title}}
                        @if(rulePackage.tag.slice(-3)==='ooh') {
                        <span class="badge info" pTooltip="Triggers when out of defined hours">ooh</span>
                        }
                        @if(rulePackage.lastAlertedAt) {
                        <div class="hidden-sm hidden-md hidden-lg pull-right">
                            <small>Last alarm was {{rulePackage.lastAlertedAt | date:'dd-MM-YY HH:mm'}}</small>
                        </div>
                        }
                    </td>
                    <td class="hidden-xs">
                        @if(rulePackage.lastAlertedAt) {
                        <span tooltipPosition="bottom" pTooltip="{{rulePackage.lastAlertedAt | amDateFormat:'ddd Do MMMM HH:mm'}}">
                            {{rulePackage.lastAlertedAt | amDateFormat:apiService.getDateFormat()}}
                        </span>
                        }
                    </td>
                    <ng-template [ngIf]="rulePackage.ruleTimeRanges[0]">
                        <td class="text-center hidden-xs">
                            <i class="dow fa fa-fw fa-check" [class.isrestricted]="rulePackage.ruleTimeRanges[0]?.monday"></i>
                        </td>
                        <td class="text-center hidden-xs">
                            <i class="dow fa fa-fw fa-check" [class.isrestricted]="rulePackage.ruleTimeRanges[0]?.tuesday"></i>
                        </td>
                        <td class="text-center hidden-xs">
                            <i class="dow fa fa-fw fa-check" [class.isrestricted]="rulePackage.ruleTimeRanges[0]?.wednesday"></i>
                        </td>
                        <td class="text-center hidden-xs">
                            <i class="dow fa fa-fw fa-check" [class.isrestricted]="rulePackage.ruleTimeRanges[0]?.thursday"></i>
                        </td>
                        <td class="text-center hidden-xs">
                            <i class="dow fa fa-fw fa-check" [class.isrestricted]="rulePackage.ruleTimeRanges[0]?.friday"></i>
                        </td>
                        <td class="text-center hidden-xs">
                            <i class="dow fa fa-fw fa-check" [class.isrestricted]="rulePackage.ruleTimeRanges[0]?.saturday"></i>
                        </td>
                        <td class="text-center hidden-xs">
                            <i class="dow fa fa-fw fa-check" [class.isrestricted]="rulePackage.ruleTimeRanges[0]?.sunday"></i>
                        </td>
                        <td class="hidden-xs hidden-sm hidden-md time">
                            @if(rulePackage.ruleTimeRanges[0]?.startTimeAt === '00:00' && rulePackage.ruleTimeRanges[0]?.endTimeAt === '23:59') {
                            <span> All day </span>
                            } @else {
                            {{rulePackage.ruleTimeRanges[0]?.startTimeAt}} - {{rulePackage.ruleTimeRanges[0]?.endTimeAt}}
                            }
                        </td>
                    </ng-template>
                    <ng-template [ngIf]="!rulePackage.ruleTimeRanges[0]">
                        <td class="text-center hidden-xs">-</td>
                        <td class="text-center hidden-xs">-</td>
                        <td class="text-center hidden-xs">-</td>
                        <td class="text-center hidden-xs">-</td>
                        <td class="text-center hidden-xs">-</td>
                        <td class="text-center hidden-xs">-</td>
                        <td class="text-center hidden-xs">-</td>
                        <td class="hidden-xs hidden-sm"></td>
                    </ng-template>
                </tr>
                }
            </tbody>
        </table>
    </div>
    }
    }
    @if(activeTabIndex()===2) {
    <app-rule-engine-v2-alarms></app-rule-engine-v2-alarms>
    }

</div>

} @else {
<app-please-wait></app-please-wait>
}

<!-- https://4dmonitoring.co.uk -->
<div
  #rootContainer
  class="root-container group root-org-{{ org?.id }} domain-{{ subdomain }} menu-v2"
  [class.is-logging-in]="onLogin"
  [class.nomenus]="!showMenus"
  [class.authorised]="authorised"
  [class.fullscreen]="isFullscreen"
  [class.filtering]="filtering"
  [class.scrolled]="isScrolled"
  [class.loaded]="!isLoading"
  [ngClass]="{ 'theme-dark-fourd': theme === 'dark-fourd', 'theme-dark': theme === 'dark', 'theme-light': theme === 'light' }"
  [class.restricted]="user && user.isRestricted"
  [class.lm-expanded]="isLeftMenuExpanded !== false"
  [class.lm-icons]="isLeftMenuExpanded === false">
  <!-- COLUMN 1 -->
  <!-- LEFT HAND MENU ITEMS - DESKTOP -->
  <!-- NEW MENU -->
  <div [hidden]="!authorised" class="left-menu no-print hidden-xs">
    @if (authorised) {
      <div routerLink="/dashboard" class="org-logo" [class.is-svg]="logo === '4d-logo.png'">
        @if (!logo && isLeftMenuExpanded) {
          {{ org.shortTitle }}
        }
        @if (logo && isLeftMenuExpanded) {
          @if (logo === '4d-logo.png') {
            <img width="110px" src="assets/svg/4d_logo_{{ theme }}.svg" alt="logo" />
          } @else {
            <img src="https://4d-org-logos.s3.eu-west-2.amazonaws.com/{{ logo }}" alt="logo" />
          }
        }
      </div>
      <div>
        <app-menus-left [user]="user" [alarmCount]="alarmCount"></app-menus-left>
      </div>
      <div class="menus-left-footer">
        <div class="policy">
          <i class="mdi mdi-theme-light-dark" (click)="themeToggle()" pTooltip="Toggle dark/light theme"></i>
          <a href="/policies">policies</a>
        </div>
        <div
          [showDelay]="1000"
          [escape]="false"
          tooltipPosition="top"
          pTooltip="<span style='text-decoration: underline;'>Versions</span> <div>Portal <span style='color:yellow'>{{
            appVersion
          }}</span></div><div>Angular <span style='color:yellow'>{{ ngVersion }}</span></div><div>Date <span style='color:yellow'>{{
            buildDate?.date
          }}</span></div>">
          <div #orglogo>
            <span>v{{ appVersion }}</span>
          </div>
        </div>
      </div>
    }
  </div>

  <!-- COLUMN 2 -->
  <div class="filter-column display-{{ filtering?.display }}" [class.disabled]="!filtering">
    @if (filtering?.display === 'engagement') {
      <div>
        <app-engage-org></app-engage-org>
      </div>
    } @else {
      <div>
        <filter-menus>...</filter-menus>
      </div>
    }
    @if (filtering?.display === 'engagement') {
      <div>
        <app-engage-menus></app-engage-menus>
      </div>
    }
  </div>

  <!-- COLUMN 3 -->
  <div>
    <div class="no-print mobile-menu hidden-sm hidden-md hidden-lg">
      @if (user) {
        <app-org-title-changeable [org]="org" routerLink="/dashboard" routerLinkActive="active" />
      }
      <div></div>
      @if (user && user.hasAcceptedTerms) {
        <div class="visible-xs-block pull-right">
          @if (!restricted) {
            @if (moduleAccess.live) {
              <i class="fa fa-area-chart" routerLink="/live" routerLinkActive="active"></i>
            }
            @if (moduleAccess.profiling) {
              <i class="fa fa-building-o" routerLink="/profiling" routerLinkActive="active"></i>
            }
            @if (moduleAccess.rules) {
              <i class="icon-font_Rules" routerLink="/rules" routerLinkActive="active"
                ><small class="mobile-alert" [class.alarmed]="alarmCount">{{ alarmCount }}</small></i
              >
            }
            @if (moduleAccess.telemetry) {
              <i class="fa fa-thermometer-empty" routerLink="/telemetry" routerLinkActive="active"></i>
            }
            @if (moduleAccess.setpoints) {
              <i class="fa fa-bullseye" routerLink="/setpoints"></i>
            }
          }
          <i
            class="fa fa-bars"
            (click)="isShowingMobileMenu = true"
            onclickx="document.getElementById('myNav').style.width = '100%';document.getElementById('myNav').style.display = 'block';"></i>
        </div>
      }
      @if (pageOptions) {
        <div class="hidden-xs hidden-md hidden-lg hidden-sm page-options">
          <div>
            <div class="dropdown">
              <button type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <i id="dropdownMenu1" class="fa fa-fw fa-ellipsis-v"></i>
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                @for (option of pageOptions; track option) {
                  <li>
                    <a (click)="clickPageOption(option)">
                      @if (option.icon) {
                        <i class="fa fa-fw fa-{{ option.icon }}"></i>
                      }
                      {{ option.title }}</a
                    >
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      }
    </div>
    <!-- header -->
    @if (authorised) {
      <div class="dashboard tiles no-print hidden-xs">
        <div class="logo-container">
          @if (!logo && !isLeftMenuExpanded) {
            {{ org.shortTitle }}
          }
          @if (logo && !isLeftMenuExpanded) {
            <img src="https://4d-org-logos.s3.eu-west-2.amazonaws.com/{{ logo }}" alt="logo" />
          }
        </div>
        <app-dashboard-tile tileName="setpoints" />
        @if (roles.offline_assets) {
          <app-dashboard-tile class="can-click" tileName="issues" routerLink="/offline" />
        }
        <div>
          @if (restricted) {
            <div class="hidden-xs pull-right bar-menu">
              <i class="fa fa-bars" (click)="isShowingMobileMenu = true"></i>
            </div>
          }
          @if (backgroundCount) {
            <div class="text-right mr-1">
              <span pTooltip="When you perform batch operations, they are entered into a queue. "
                ><i class="mdi mdi-bell gray"></i> {{ backgroundCount }} job waiting.</span
              >
            </div>
          }
        </div>
      </div>
    }
    <div id="container" class="container-fluid">
      @if (filtering) {
        <div class="top-filter">
          <filter-menus>...</filter-menus>
        </div>
      }
      <div>
        @if (ticketsPinned) {
          <app-tickets-pinned [tickets]="ticketsPinned" />
        }
      </div>
      <p-toast position="top-right"></p-toast>

      <router-outlet></router-outlet>

      <div id="loader-wrapper">
        <div id="loader"></div>
        <div class="loader-section section-left"></div>
        <div class="loader-section section-right"></div>
        <div id="loader-4d">
          @if (subdomain === 'portal') {
            <span>4D</span>
          }
        </div>
      </div>
    </div>
  </div>
  @if (isShowingMobileMenu) {
    <app-menu-mobile [isRestricted]="restricted" [moduleAccess]="moduleAccess" [user]="user" (onMenuClick)="isShowingMobileMenu = false"></app-menu-mobile>
  }
</div>

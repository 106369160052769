<div class="report-telemetry-min-max-component">
  <table class="table table-sm table-striped master">
    <thead>
      <tr>
        <ng-template class="hidden-xs" ngFor let-heading [ngForOf]="days">
          <th *ngIf="heading.line1==='Sensor'"></th>
          <th colspan="2" *ngIf="heading.line1!=='Sensor'">{{heading.line1}}<br /><span
              class="day-line2">{{heading.line2}}</span></th>
        </ng-template>
      </tr>
      <tr>
        <ng-template class="hidden-xs" ngFor let-heading [ngForOf]="days">
          <th *ngIf="heading.line1==='Sensor'">{{heading.line1}}<br />{{heading.line2}}</th>
          <th colspan="1" *ngIf="heading.line1!=='Sensor'"><small>min</small></th>
          <th colspan="1" *ngIf="heading.line1!=='Sensor'"><small>max</small></th>
        </ng-template>
        <th colspan="1" *ngIf="tableData && canAlert">
          <span class="hidden-xs"><small>Performance</small></span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let asset of tableData; let i=index" [hidden]="selectedIndex !== null && selectedIndex !== i"
        (click)="drill(asset, i)">
        <td> <i [hidden]="selectedIndex !== i" class="fa fa-chevron-left"></i> {{asset.title}}</td>
        <ng-template class="hidden-xs" ngFor let-value [ngForOf]="asset.values">
          <td [class.inRedAlert]="value.redMinAlert" [class.inOrangeAlert]="value.orangeMinAlert && !value.redMinAlert">
            <span class="pull-right">{{value.min}}<span *ngIf="value?.min==null">--.-</span></span>
            <div *ngIf="canAlert">
              <br /> <span *ngIf="value?.min!=null"
                class="range pull-right">{{value.setpoint?.min |number:'1.1'}}</span>
            </div>
          </td>
          <td [class.inRedAlert]="value.redMaxAlert" [class.inOrangeAlert]="value.orangeMaxAlert && !value.redMaxAlert">
            <span class="pull-right">{{value.max}}<span *ngIf="value?.max==null">--.-</span></span>
            <div *ngIf="canAlert">
              <br />
              <span *ngIf="value?.max!=null" class="range pull-right">{{value.setpoint?.max |number:'1.1' }}</span>
            </div>
          </td>
        </ng-template>
        <td class="performance-column" *ngIf="canAlert"><i class="fa fa-fw fa-circle"
            [class.inRedAlert]="asset.inRedAlert" [class.inOrangeAlert]="asset.inOrangeAlert && !asset.inRedAlert"></i>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- Drilldown details for mobile -->
  <div class="drilldown" *ngIf="selectedIndex !== null">

    <table class="table">
      <thead>
        <th>Day</th>
        <th *ngIf="canAlert" class="red">Setpoint<br />Red Min</th>
        <th *ngIf="canAlert" class="amber">Setpoint<br />Amber Min</th>
        <th>Min</th>
        <th>Max</th>
        <th *ngIf="canAlert" class="amber">Setpoint<br />Amber Max</th>
        <th *ngIf="canAlert" class="red">Setpoint<br />Red Max</th>
      </thead>
      <tbody>
        <tr *ngFor="let value of tableData[selectedIndex].values; let i = index">
          <td><span class="day-line1">{{days[i+1].line1}} </span><span class="day-line2">{{days[i+1].line2}}</span></td>
          <td *ngIf="canAlert" class="red"><span *ngIf="value?.min!=null"
              class=" pull-right">{{value.setpoint?.red_min |number:'1.1'}}</span></td>
          <td *ngIf="canAlert" class="amber"><span *ngIf="value?.min!=null"
              class=" pull-right">{{value.setpoint?.amber_min |number:'1.1'}}</span></td>
          <td class="value" [class.inRedAlert]="value.redMinAlert"
            [class.inOrangeAlert]="value.orangeMinAlert && !value.redMinAlert" (click)="showPopup()">
            <span class="pull-right">{{value.min}}<span *ngIf="value?.min==null">--.-</span></span>
          </td>
          <td class="value" [class.inRedAlert]="value.redMaxAlert"
            [class.inOrangeAlert]="value.orangeMaxAlert && !value.redMaxAlert" (click)="showPopup()">
            <span class="pull-right">{{value.max}}<span *ngIf="value?.max==null">--.-</span></span>
          </td>
          <td *ngIf="canAlert" class="amber"><span *ngIf="value?.min!=null"
              class=" pull-right">{{value.setpoint?.amber_max |number:'1.1'}}</span></td>
          <td *ngIf="canAlert" class="red"><span *ngIf="value?.max!=null"
              class=" pull-right">{{value.setpoint?.red_max |number:'1.1'}}</span></td>
        </tr>
      </tbody>
    </table>
    <app-sensor-popup [showPoints]="true" *ngIf="isShowingPopupSensorData" [(show)]="isShowingPopupSensorData"
      [asset]="selectedAsset" [startsAt]="startsAt" [endsAt]="endsAt"></app-sensor-popup>
  </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Building } from 'app/classes/building';
import { APIService } from 'app/shared/api.service';
import { AssetForCollection, BuildingsService } from 'app/shared/buildings.service';

@Component({
  selector: 'app-collection-assets',
  templateUrl: './collection-assets.component.html',
  styleUrls: ['./collection-assets.component.css']
})
export class CollectionAssetsComponent implements OnInit {

  @Input()
  building: Building;

  @Input()
  type: 'reporting' | 'footfall';

  @Output()
  onUpdated: EventEmitter<number> = new EventEmitter<number>();

  assets: AssetForCollection[];
  title: string;
  isWorking: boolean;

  constructor(private buildingService: BuildingsService, private apiService: APIService) { }

  ngOnInit(): void {
    if (this.building) {
      this.buildingService
        .getAssetsForCollections(this.building.id)
        .then(assets => {
          this.assets = assets.filter(a => a.assetType_id !== this.apiService.ASSET_TYPES_BY_TITLE.WIFI && a.assetType_id !== this.apiService.ASSET_TYPES_BY_TITLE.IP);
        });
    }
    if (!this.type) {
      this.apiService.toastWarn('IMPLEMENTATION ERROR', this.type);

    }
  }
  clear() {
    this.title = null;
    this.assets.forEach(a => a.checked = false);
  }

  submit() {

    const assets = this.assets.filter(a => a.checked);

    if (!assets.length) {
      this.apiService.toastWarn('Select at least one asset', '');
      return;
    }
    this.isWorking = true;
    this.buildingService
      .createCollection(this.building.id, this.type, this.title, assets)
      .then(collectionId => {
        this.onUpdated.emit(collectionId);
        this.isWorking = false;
        this.title = null;
      });
  }

}

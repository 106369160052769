import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { APIService } from '../shared/api.service';
import { RuleService } from '../shared/rule.service';
import { RulePackage } from '../classes/rule-service/rule-package';

@Component({
    selector: 'app-alarm-action-from-token',
    templateUrl: './alarm-action-from-token.component.html',
    styleUrls: ['./alarm-action-from-token.component.css']
})
export class AlarmActionFromTokenComponent implements OnInit {

    tokenId: string;
    rulePackage: RulePackage;
    tokenResult: string;
    tokenAction: string;
    alarm: any;
    newNote: string;
    pleaseWait: boolean;
    timerSubscription: any;
    isSubmitting: boolean;

    constructor(private ruleService: RuleService,
        private apiService: APIService,
        private route: ActivatedRoute,
        private router: Router) {
    }

    ngOnInit() {
        this.apiService.setFullscreen(true);
        this.rulePackage = new RulePackage();
        this.route.params.subscribe((params: Params) => {
            this.tokenId = params['tokenid'];
            this.tokenAction = this.router.url.split('/')[4];
            switch (this.tokenAction) {
                default:
                    this.getData();
            }
        });
    }

    getData() {
        return this.apiService.tokenAction(this.tokenId, this.tokenAction).then(
            (result) => {
                // Remove the action, convert to a view alarm mode
                this.tokenAction = null;
                if (result.alarm) {
                    this.alarm = result.alarm;
                }
                if (result.message) {
                    // If only a message received, display the mesasge
                    this.tokenResult = JSON.stringify(result.message);
                } else {
                    // If a more complex result returned, just set the result and let the
                    // specific component deal with it
                    this.tokenResult = result;
                }
            }
        );
    }

    submitNote(action?: string) {
        if (this.isSubmitting) {
            return false;
        }

        if (!action) {
            action = 'new_alarm_note';
        }

        this.pleaseWait = true;
        this.isSubmitting = true;
        this.apiService.tokenAction(this.tokenId, action, this.newNote).then(
            () => {
                this.isSubmitting = false;
                setTimeout(() => {
                    this.alarm = null;
                    this.getData();
                    this.pleaseWait = false;
                }, 500);
            }
        );
    }
}

import { Injectable } from '@angular/core';
import { Engagement } from 'app/classes/engagement';
import { APIService } from 'app/shared/api.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EngageService {

  private _data: BehaviorSubject<any> = new BehaviorSubject(null);
  public data: Observable<any> = this._data.asObservable();

  private _processedData: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public processedData: Observable<any[]> = this._processedData.asObservable();

  constructor(private apiService: APIService, private http: HttpClient) { }


  setProcessed(data: any[]) {
    console.log('PASSING', data);
    this._processedData.next(data);
  }

  /**
   * Get stats
   * @param engagement Engagement properties
   */
  get(engagement: Engagement): any {
    let query = `/partners/engagement?type=org&startsAt=`;
    query += this.apiService.dbDate(engagement.startsAt) + `&endsAt=`;
    query += this.apiService.dbDate(engagement.endsAt);
    if (engagement.adjusted) {

    } else {
      // Remove adjustement
      query += '&adjustBy=0';
    }
    return this.http.get(this.apiService.getUserAPI() + query, { headers: this.apiService.getHttpHeaders() })
      .toPromise()
      .then(response => {
        this._data.next(response);

        return response;
      });
  }

  getUsers(): Promise<any> {
    const query = this.apiService.getUserAPI() + '/partners/engagement?type=users';

    return this.http.get(query, { headers: this.apiService.getHttpHeaders() })
      .toPromise()
      .then(response => {

        return response;
      });
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dev-api-update',
  templateUrl: './dev-api-update.component.html',
  styleUrls: ['./dev-api-update.component.css']
})
export class DevApiUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

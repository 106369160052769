<article class="content-container">
  <section>
    <h2>Create a Building Review by following these simple steps</h2>
    <ol>
      <li>
        Click "Create Review (due)" to choose the starting month for the report, and select the number of months. Suggested defaults will be presented based on
        the previous report generated.
      </li>
      <li>Once the data is loaded, annotate each asset. Use the dropdown for common annotations. Remember to save the annotations when you're done.</li>
      <!--   <li>The report will auto save if you leave the report, or you can manually save at any point.</li> -->
      <li>
        When you have entered ALL annotations, when you save a dialog box will ask you if you would like to compelete your review. If you choose to complete the
        review, you have completed all you need to do. The PDF for the review will be generated on the server and attached to the buildings documents. This can
        take a few minutes.
      </li>
      <!--
      <li>
        Once completed, print the page and save to your computer as a pdf, then drag the pdf from your local folder to the building review web page you just
        printed. This will upload it to that review and make it available to 4D clients.
      </li>
    --></ol>
  </section>
</article>

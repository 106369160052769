<main *ngIf="job">
	<nav *ngIf="!job.isFinalStatus">
		<div class="text-right">
			<!--<button pButton label="Cancel" icon="fa fa-times" (click)="cancel()"
				class="p-button-sm p-button-outlined p-button-secondary mr-1"></button>-->
			<button pButton label="Message" icon="fa fa-comment-o" (click)="isMessaging=true" class="p-button-sm p-button-outlined p-button-secondary"></button>
		</div>
	</nav>
	<!--<div class="final-status" *ngIf="job.isFinalStatus">
		Job is at final status.
	</div>-->
	<article>
		<section>
			<h3>
				<span pTooltip="elogbooks site #{{job.site.id}}">
					<i class="fa fa-fw fa-building"></i> {{job.site.title}}
				</span>
			</h3>
			<div>
				<label>Summary</label>
				<p>{{job.summary || 'no summary'}}</p>
			</div>
			<div>
				<label>Description</label>
				<p>{{job.description || 'no description'}}</p>
			</div>

			<div>
				<label>Status</label>
				<p><span pTooltip="{{job.status}}">{{job.statusDisplay}}</span></p>
			</div>
			<div>
				<label>Created</label>
				<p pTooltip="{{job.createdAt | amTimeAgo}}">{{job.createdAt | date:'dd-MM-YY HH:mm'}}</p>
			</div>
			<div *ngIf="job.reminderAt">
				<label>Reminder</label>
				<p pTooltip="{{job.reminderAt | amTimeAgo}}">{{job.reminderAt | date:'dd-MM-YY HH:mm'}}</p>
			</div>
		</section>
		<section>
			<div class="mt-1">
				<p-timeline [value]="events" align="alternate" styleClass="customized-timeline">
					<ng-template pTemplate="marker" let-event>
						<span class="custom-marker p-shadow-2" [style.backgroundColor]="'#a0a0a0'">
							<i [ngClass]="event.icon"></i>
						</span>
					</ng-template>
					<ng-template pTemplate="content" let-event>
						<p-card>
							<p>{{event.body}} </p>
							<p>{{event.date | date:'dd-MM-YY HH:mm'}}</p>
						</p-card>
					</ng-template>
				</p-timeline>
			</div>

		</section>
	</article>
</main>

<main *ngIf="job===null">
	<p>No job available, may still be open on another alarm if expected OR there may be a delay in receiving the job from elogbooks.</p>
</main>

<div class="__modal0" *ngIf="isMessaging" (click)="m($event);isMessaging=null;false"></div>
<div class="_modal" *ngIf="isMessaging">
	<div>
		<div class="form-group">
			<label>Message</label>
			<textarea class="form-control" [(ngModel)]="note"></textarea>
		</div>

		<button pButton label="OK" (click)="modalOK()"></button>
	</div>
</div>

import { Injectable } from '@angular/core';
import { VisualiseItem } from '../classes/visualise-item';
import { IDatesFromTo } from 'app/interfaces/dates-from-to';
import { HttpClient } from '@angular/common/http';
import { APIService } from './api.service';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class VisualiseService {

  API_URL = 'https://njrlszkrui.execute-api.eu-west-2.amazonaws.com/prod';

  constructor(private apiService: APIService, private http: HttpClient, private windowService: WindowService) { }

  getVisualisationData(visualisationId: number, dates: IDatesFromTo, orgId?: number, siteId?: number): Promise<VisualiseItem> {
    return new Promise((resolve) => {

      const qs = `id=${visualisationId}&df=${this.apiService.dbDate(dates.from)}&dt=${this.apiService.dbDate(dates.to)}&sid=${siteId || ''}`;

      const url = `${this.API_URL}/data?${qs}`;
      return this.http.get<any>(url, this.apiService.getUAOHeaders()).subscribe(b => {
        resolve(b);
      });

      // resolve(this.getVisualisationMock(visualisationId, dates, orgId));

    });
  }

  // mock

  getVisualisationMock(visualisationId, dates: IDatesFromTo, orgId?: number) {
    const data: any = {
      id: visualisationId,
      dates,
      orgId,
      minmax: { min: 0, max: 100 }
    };

    switch (visualisationId) {
      case 1:
        data.title = 'Occupation by Tenant';
        data.category = 'occupancy';
        data.dataType = 'rows';
        data.isPercentage = true;
        data.rows = [
          { label: 'Shared space', value: 45, backgroundColor: this.getColourRatingFromValue(45) },
          { label: 'South London', value: 39, backgroundColor: this.getColourRatingFromValue(39) },
        ];
        data.dataset = { label: 'Tenant' };
        break;
      case 2:
        data.title = 'Occupation by Service';
        data.category = 'occupancy';
        data.dataType = 'rows';

        data.isPercentage = true;
        data.rows = [
          { label: 'CDT Doctor', value: 25, backgroundColor: this.getColourRatingFromValue(25) },
          { label: 'Children\'s Therapies', value: 65, backgroundColor: this.getColourRatingFromValue(65) },
        ];
        data.dataset = { label: 'Service' };
        break;
      case 3:
        data.title = 'Occupation by Building';
        data.category = 'occupancy';
        data.dataType = 'rows';
        data.isPercentage = true;
        data.rows = [
          { label: 'Heart Of Houndslow', value: 45, backgroundColor: this.getColourRatingFromValue(45) },
        ];
        data.dataset = { label: 'Building' };
        break;
    }

    return new VisualiseItem(data);
  }

  getColourRatingFromValue(value: number) {
    if (value <= 20) {
      return '#e00303 ';
    } else if (value <= 40) {
      return 'darkorange';
    } else if (value <= 60) {
      return '#fffb00';
    } else if (value <= 80) {
      return '#ade232';
    } else {
      return 'green';
    }
  }
}


export interface IVisualisationConfig {
  id: number;
  dates: IDatesFromTo;
  orgId?: number;
  siteId?: number;
  size?: { height?: string, width?: string };
}



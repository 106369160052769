import { Component, OnDestroy, OnInit } from '@angular/core';
import { Building } from 'app/classes/building';
import { CollectionAsset, CollectionForAssets } from 'app/classes/collection-for-assets';
import { InsightFF } from 'app/classes/insight-ff';
import { Org } from 'app/classes/org';
import { DialogManageCollectionsComponent } from 'app/dialogs/dialog-manage-collections/dialog-manage-collections.component';
import { APIService } from 'app/shared/api.service';
import { BuildingsService } from 'app/shared/buildings.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-insights-ff-landing',
  templateUrl: './insights-ff-landing.component.html',
  styleUrls: ['./insights-ff-landing.component.css'],
  providers: [DialogService]
})
export class InsightsFfLandingComponent implements OnInit, OnDestroy {
  building: Building;
  collectionForAssets: CollectionForAssets;
  minDate: Date = new Date('2020-12-12 23:59:59');
  maxDate: Date = new Date();

  reportAssets: any[];
  reportCollections: CollectionAsset[];
  runReport: boolean;
  reportWasGenerated: boolean;
  reportWasGeneratedAt: Date;
  clearCache: boolean;
  month: Date;
  can: { manageCollections: boolean } = { manageCollections: false };

  generateItems = [
    {
      label: 'Clear cache & run', icon: 'pi pi-refresh', command: () => {
        this.generate(true);
      }
    }];

  org: Org;
  isLoading: boolean;
  dialogRef: DynamicDialogRef;
  toolbar: any;

  useOrgLogo: boolean;

  constructor(public dialogService: DialogService, public messageService: MessageService, private apiService: APIService, private buildingService: BuildingsService) { }

  ngOnInit(): void {
    this.org = this.apiService.getUserOrg();
    this.can.manageCollections = this.apiService.isAdmin() || this.apiService.hasOrgRole('collection_admin');
    if (this.can.manageCollections) {
      this.toolbar = [{ icon: 'mdi-pencil', label: 'Manage Collection', tag: 'EDIT_COLLECTIONS' }];
    }
  }

  buildingChanged(building: Building) {
    this.collectionForAssets = null;
  }

  buildingSelected(building: Building) {
    console.log('got building', building);
    this.isLoading = true;
    this.collectionForAssets = null;

    this.buildingService.getCollections(building.id, 'footfall').then(collectionAssets => {
      // Pre select collections
      collectionAssets.collections.forEach(c => c.selected = true);
      this.collectionForAssets = collectionAssets;
      this.building = building;
      this.isLoading = false;
    });
  }

  generate(reGenerateDate = false) {
    this.reportAssets = [];
    this.reportCollections = this.collectionForAssets.collections.filter(c => c.selected);

    this.clearCache = reGenerateDate;

    if (!this.month) {
      this.messageService.add({ detail: 'Please select a month', severity: 'info' });

      return;
    }

    if (this.runReport && !this.reportWasGenerated) {
      console.log('REPORT_GENERATING_PLEASE_WAIT');

      return;
    }

    if (this.runReport) {
      this.runReport = false;
      setTimeout(() => {
        this.runReport = true;
        this.reportWasGenerated = false;
      }, 10);
    } else {
      this.reportWasGenerated = false;
      this.runReport = true;
    }
  }

  onReportWasGenerated(insight: InsightFF) {
    if (insight === null) {
      this.reportWasGenerated = false;
      this.runReport = false;
      this.reportWasGeneratedAt = null;
    } else {
      this.reportWasGenerated = true;
      this.reportWasGeneratedAt = insight.generatedAt;
    }
  }

  printPage() {
    window.print();
  }

  manageCollections() {
    if (!this.can.manageCollections) {
      return;
    }
    this.dialogRef = this.dialogService.open(DialogManageCollectionsComponent, {
      header: `Manage Collections`,
      width: '90vw',
      height: '80vw',
      data: { type: 'footfall', building: this.building, collectionForAssets: this.collectionForAssets }
    });
    this.dialogRef.onClose.subscribe(() => {
      this.buildingSelected(this.building);
    });
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.destroy();
    }
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { StoreService } from "../../shared/store.service";
import { Site } from "../../classes/site";
import { Gateway } from "../../classes/gateway";
import { GatewayService } from "../../shared/gateway.service";

@Component({
  selector: 'app-gateway-picker',
  templateUrl: './gateway-picker.component.html',
  styleUrls: ['./gateway-picker.component.css']
})
export class GatewayPickerComponent implements OnInit {

  @Output()
  submit: EventEmitter<Gateway> = new EventEmitter<Gateway>();
  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  site: Site;

  gateways: Gateway[];
  gateway: Gateway;

  constructor(private gatewayService: GatewayService, private router: Router, private route: ActivatedRoute, private storeService: StoreService, private apiService: APIService) {
  }

  ngOnInit() {
    this.apiService
      .getGatewaysForSiteId(this.site.id)
      .then(gateways => {
        if (!gateways) {
          this.gateways = [];

          return;
        }
        this.gateways = gateways.sort((a, b) => {
          if (a.title < b.title) { return -1; }
          if (a.title > b.title) { return 1; }
          return 0;
        });
        this.getFavGateways();
      });
  }

  selectGateway(gateway: Gateway) {
    this.submit.emit(gateway);
  }

  favGateway(gateway: Gateway) {
    const favs = this.gatewayService.toggleFavouritGateway(gateway);
    this.getFavGateways();
  }

  getFavGateways() {
    this.gateways.map(s => s.isFav = false);
    this.gatewayService
      .getFavouriteGateways()
      .map(i => {
        const gw = this.gateways.find(g => g.id === i);
        if (gw) {
          gw.isFav = true;
        }
      });
  }


}


<article>
    <app-please-wait *ngIf="isLoading"></app-please-wait>

    <table class="table" *ngIf="!selectedAsset">
        <thead>
            <tr>
                <th></th>
                <th>Asset</th>
                <th>Gateway</th>
                <th>Site</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let asset of list" (click)="selectedAsset=asset" class="can-click">
                <td><i class="fa fa-square {{asset.rag}} rag"></i></td>
                <td>{{asset.title}}</td>
                <td>{{asset.gatewayTitle}}</td>
                <td>{{asset.siteTitle}}</td>
            </tr>
        </tbody>
    </table>
</article>

<main *ngIf="selectedAsset">
    <app-setpoint-detail [assetId]="selectedAsset.id" [rangeId]="2" (onBack)="selectedAsset=null"></app-setpoint-detail>
</main>
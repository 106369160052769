import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, ChildActivationEnd } from '@angular/router';
import { User } from 'app/classes/user';
import { APIService } from 'app/shared/api.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-setpoint-container',
  templateUrl: './setpoint-container.component.html',
  styleUrls: ['./setpoint-container.component.css']
})
export class SetpointContainerComponent implements OnInit, OnDestroy {

  routeSub: Subscription;
  userSub: Subscription;
  section: SetpointContainerSection = 'listing';

  can = {
    listing: true,
    schedule: false,
    template: false
  };
  user: User;


  constructor(private activatedRoute: ActivatedRoute, private router: Router, private apiService: APIService) {
    this.routeSub = router.events
      .pipe(filter(event => event instanceof ChildActivationEnd && !!event.snapshot.url.length))
      .subscribe(event => {
        console.log(event);
        let e: any = <ChildActivationEnd>event;
        this.section = e.snapshot._routerState.url === '/setpoints' ? 'listing' : e.snapshot._routerState.url.split('/')[2];
      });

    this.userSub = apiService.user.subscribe(user => {
      if (user) {
        this.can.schedule = this.can.template = (user.role === 'admin' || user.orgRoles.some(role => role === 'setpoint_admin'));
      }
      this.user = user;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.userSub.unsubscribe();
  }
}

export type SetpointContainerSection = 'listing' | 'templates' | 'schedules';

import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { User } from 'app/classes/user';
import { HttpClient } from '@angular/common/http';
import { UserGroup } from 'app/classes/user-group';

@Injectable({
  providedIn: 'root'
})
export class UserGroupService {

  constructor(private http: HttpClient, private apiService: APIService) { }

  get(target: string, targetId: number): Promise<any[]> {

    let url = this.apiService.getUserAPI() + `/`;
    switch (target) {
      case 'sites':
        url += `sites/${targetId}/usergroups`;
    }

    return this.http.get<any[]>(url, { headers: this.apiService.getHttpHeaders() })
      .toPromise()
      .then(response => response.map(userGroup => new UserGroup(userGroup)));
  }

  patch(op: string, target: string, targetId: number, userGroup: UserGroup): Promise<any> {
    const payload = {
      op,
      target,
      targetId,
      userId: userGroup.user.id,
      grouping: userGroup.grouping
    };
    return this.http.patch(this.apiService.getUserAPI() + '/users/groups', payload,
      { headers: this.apiService.getHttpHeaders() })
      .toPromise()
      .then(response => response);
  }
}

export interface UserGroupPatchInterface {
  user: User;
  op: string;
}

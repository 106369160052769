import { Component, signal } from '@angular/core';
import { OrgExport } from 'app/org-dashboard/org-export/org-export';

@Component({
  selector: 'app-report-trigger',
  templateUrl: './report-trigger.component.html',
  styleUrl: './report-trigger.component.css'
})
export class ReportTriggerComponent {

  label = signal<string>('Custom report');

  reportLoaded(orgExport: OrgExport) {
    this.label.set(orgExport?.title || 'Custom Report')
  }
}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { Asset } from '../../classes/asset';
import { timer } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-footfall-chart',
  templateUrl: './footfall-chart.component.html',
  styleUrls: ['./footfall-chart.component.css']
})
export class FootfallChartComponent implements OnInit, OnDestroy {

  data: any;
  options: any;
  now: any = new Date();

  telemetry: any[] = [];

  @Input()
  asset: Asset;

  count: any = { in: 0, out: 0, counter: 0, lastId: 0 };
  charts: any[];
  timer: any;
  timerSubscription: any;
  constructor(private apiService: APIService) {
    this.data = {
      labels: [],
      datasets: [
        {
          label: 'Recent footfall',
          data: [],
          backgroundColor: '#02ACEE'
        }
      ]
    };

    this.options = {
      scales: {
        yAxes: [{
          ticks: {
            max: 4,
            min: -4,
            stepSize: 1
          }
        }]
      }
    };
  }

  ngOnInit() {
    console.log('footfall-chart init');

    this.startTimer();
    this.now = new Date();
    this.charts = [];
  }

  startTimer() {
    this.count = { in: 0, out: 0, counter: 0, lastId: 0 };
    if (!this.timerSubscription) {
      const telemetryTick = timer(1, 15000);
      this.timerSubscription = telemetryTick.subscribe(t => {
        // Get the rule package, which in turn notifies subscribers
        console.log('getTelemetry tick', t);
        this.getTelemetry();
      });
    } else {
      this.getTelemetry();
    }
  }


  getTelemetry() {
    this.apiService.getTelemetry(this.asset.id, 20).then(
      (results) => {
        console.log(results[results.length - 1].id, this.count.lastId);
        if (results[results.length - 1].id <= this.count.lastId) {
          return;
        }
        this.data.datasets[0].data = [];
        this.data.labels = [];
        this.charts = [];
        for (let idx = 0; idx < results.length; idx++) {
          const line = results[idx];
          line.value = Number(line.value);
          if (this.count.lastId !== 0) {
            if (line.id > this.count.lastId) {
              if (line.value < 0) {
                this.count.out = this.count.out + Math.abs(line.value);
              }
              if (line.value > 0) {
                this.count.in = this.count.in + line.value;
              }
            }
          }
          this.data.datasets[0].label = this.asset.title + (this.asset.location ? '(' + this.asset.location + ')' : '');
          this.data.datasets[0].data.push(line.value);
          this.data.labels.push(moment(line.createdAt).fromNow());
        }
        this.count.lastId = results[results.length - 1].id;
        this.charts = [{ id: this.count.lastId }];
        console.log(this.charts);
      });
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}

/**
 * @version 0.0.0
 */
import { IConfigurationRange } from "./insight";
import { CollectionAsset } from "./collection-for-assets";
import moment from 'moment';
import { Building } from "./building";
import { IInsightReviewAdminSupporting, IInsightReviewAdminSupportingAlarm } from "app/shared/insights.review.service";
import { IChartCompletedMinMax } from "app/charts/d3-chart/d3-chart.component";

export class InsightReview {
	configuration: IConfigurationReview;
	telemetry: { [key: number]: { asset: any, telemetry: any[], annotations: any[], review: AssetReview, alarms: IInsightReviewAdminSupporting[], chartCompleted?: IChartCompletedMinMax } } = {};
	collection: CollectionAsset;
	generatedAt: Date;
	hours: any[];
	uuid: string;
	stage: 'new' | 'done';

	constructor() { }

	setTelemetry(building: Building, telemetry: any, annotations: { asset_id: number, rag: 'red' | 'amber' | 'green', annotation: string }[]) {
		// const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		// const daysShort = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

		// Initialise telemetry array, key by asset id.
		this.collection.assets.forEach(asset => {

			const assetReview = new AssetReview();
			if (annotations) {
				const annotationAsset = annotations.find(a => a.asset_id === asset.id);
				if (annotationAsset) {
					assetReview.annotation = annotationAsset.annotation;
					assetReview.rag = annotationAsset.rag;
				}
			}
			if (telemetry[asset.id]) {
				this.telemetry[String(asset.id)] = { asset, telemetry: telemetry[asset.id].map(t => { return { i: t.i, v: +t.v, d: new Date(t.d) } }), review: assetReview, alarms: [], chartCompleted: {} };
			} else {
				this.telemetry[String(asset.id)] = { asset, telemetry: [], review: assetReview, alarms: [], chartCompleted: {} };
			}
		});

		//telemetry.forEach(t => this.telemetry[String(t.a)].telemetry.push({ i: t.i, v: t.v, d: new Date(t.d) }));

		// Clean up telemetry, make value a number and timestamp a date
		// Footfall only has once collection of data currently (counts per hour)
		// Remove outside of hours
		this.hours = this.configuration.ranges.map(r => {
			return { from: +new Date(r.from), to: +new Date(r.to) };
		});

		// Initialise the day summary
		// const start = moment(this.configuration.start);
		// const daysInMonth = start.daysInMonth();
	}

	setAlarms(alarms: IInsightReviewAdminSupportingAlarm[]) {
		if (!alarms) {
			return;
		}
		alarms.forEach(a => {
			if (a.la) {
				this.telemetry[String(a.la)]?.alarms.push(a);
			}
			if (a.ra) {
				this.telemetry[String(a.ra)]?.alarms.push(a);
			}
		});
	}

	setConfiguration(configuration: IConfigurationReview) {
		const parts = configuration.title.split(' from ');
		const startString = parts[1].split(' ')[0];
		const endString = parts[1].split(' ')[2];
		configuration.start = moment(startString, "DD/MM/YYYY");
		configuration.end = moment(endString, "DD/MM/YYYY");
		this.configuration = configuration;
	}

	serialise() {
		const assets = [];
		Object.keys(this.telemetry).forEach(key => {
			const review = this.telemetry[String(key)].review;
			assets.push({ id: key, rag: review.rag || null, annotation: review.annotation || null });
		});

		return {
			stage: this.stage,
			uuid: this.uuid,
			assets
		};
	}
}

export interface IConfigurationReview {
	// Assets used in telemetry
	assets: { id: number, collectionId: number, assetType_id: number, metobsid?: number }[];
	// The start date for the month
	start: any; // moment
	end: any; // moment
	// The date ranges used to pull the telemetry
	ranges: IConfigurationRange[];
	// The types included in the telemetry
	title: string;
}

export class AssetReview {
	annotation: string;
	rag: 'green' | 'amber' | 'red';
}


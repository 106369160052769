<app-theme-page-container *ngIf="user && siteList">
	<app-theme-page-section>

		<div class="basic-details">
			<div>
				<label>Full Name</label>
				<input type="name" class="form-control mt-1" [(ngModel)]="invite.name" placeholder="Firstname Lastname">
			</div>
			<div>
				<label>Email address</label>
				<input type="email" class="form-control mt-1" [(ngModel)]="invite.email" placeholder="person@email.com">
			</div>
			<div class="is-tracked" [pTooltip]="isTrackable ? 'Included in engagement':'NOT included in engagement'"
				tooltipPosition="top">
				<label>Track</label>
				<div class="form-control mt-1">
					<p-checkbox name="isTrackable"
						[label]="isTrackable ? 'Tracked, in engagement' : 'Not tracked, not in engagement'" [binary]="true"
						[(ngModel)]="isTrackable"></p-checkbox>
				</div>

			</div>
		</div>

		<div class="select-box">
			<label>Modules <small *ngIf="totals.modules">{{totals.modules}} selected</small> <b style="color:red"
					*ngIf="totals.modules === 0">You must select at least one module</b></label>
			<div class="module-list">
				<div *ngFor="let module of moduleList" [class.active]="module.selected" (click)="toggleModule(module)">
					<span tooltipPosition="top" pTooltip="{{module.tooltip}}">{{module.label}}</span>
				</div>
			</div>
		</div>
		<div class="select-box" #site>
			<div class="sites-header">
				<label>Sites <small *ngIf="totals.sites">{{totals.sites}} selected</small><b style="color:red"
						*ngIf="totals.sites === 0">You must select at least one site</b></label>
				<button class="btn btn-sm btn-secondary" (click)="clearSites()">clear selected </button>
			</div>
			<div class="module-list">
				<div *ngFor="let site of siteList" [class.active]="site.selected"> <span (click)="toggleSite(site)"
						tooltipPosition="top" [pTooltip]="site.tooltip" [escape]="false">{{site.label}}</span></div>
			</div>
		</div>
		<div class="other-options">
			<div class="expires">
				<label
					pTooltip="Leave blank if the user never expires, otherwise the user will be unable to login after this date.">Expires
					(optional)</label>

				<div>
					<p-calendar [(ngModel)]="expiresAt" dateFormat="dd/mm/yy" [minDate]="tomorrow"></p-calendar>
				</div>

			</div>
			<div>
				<label>Notes</label>
				<textarea rows=1 class="form-control" name="notes" [(ngModel)]="invite.notes"></textarea>
			</div>
		</div>

	</app-theme-page-section>
	<div class="buttons">
		<div class="text-right">
			<p-button label="Cancel" icon="pi pi-times" styleClass="p-button-outlined p-button-secondary mr-1"
				(click)="cancel()">
			</p-button>
			<p-button label="Invite" icon="mdi mdi-card-account-mail-outline" styleClass="" (click)="inviteUser()">
			</p-button>
		</div>
	</div>
</app-theme-page-container>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RuleCondition } from '../../classes/rule-service/rule-condition';
import { Site } from '../../classes/site';
import { Gateway } from '../../classes/gateway';
import { RuleAction } from '../../classes/rule-service/rule-action';

@Component({
  selector: 'app-rule-action-picker',
  templateUrl: './rule-action-picker.component.html',
  styleUrls: ['./rule-action-picker.component.css']
})
export class RuleActionPickerComponent implements OnInit {


  @Output()
  submit: EventEmitter<RuleAction> = new EventEmitter<RuleAction>();
    
  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  _modifyAction: RuleAction;

  @Input() 
  public set modifyAction(value : RuleAction) {
    if (value) {
      this._modifyAction = value;
      this.action = 'modify';
    }
  }


  action: any;
  details: string;

  @Input() 
  defaultGateway: Gateway;

  @Input()
  defaultSite: Site;

  constructor() { }

  ngOnInit() {
  }

  newAction(typeOfCondition: string) {
    this.action = {action: 'new', type: typeOfCondition};
    this.details = 'New Condition';
  }

  newRuleActionSubmitted(action: RuleAction) {
    this.action = null;
    this.submit.emit(action)
  }

  newRuleActionCancelled() {
    this.action = null;
    this.cancel.emit();
  }

  newRuleHours() {
    this.action = 'restrict-hours';
  }
}

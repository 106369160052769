<app-please-wait *ngIf="isLoading"></app-please-wait>

<app-theme-page-container *ngIf="summary">
    <app-theme-page-section>
        <p-toolbar>
            <div class="p-toolbar-group-left">
                <span class="can-click" (click)="setDates('this')" pTooltip="Go to todays month"><span
                        class="mdi mdi-calendar"></span> {{dtAsText}}
                </span>
            </div>
            <div class="p-toolbar-group-right no-print">
                <p-button label="Export" icon="mdi mdi-file-excel"
                    styleClass="p-button-outlined p-button-sm p-button-secondary mr-1"
                    (click)="exportDialog()"></p-button>
                <p-button label="Print page" icon="mdi mdi-printer"
                    styleClass="p-button-outlined p-button-sm p-button-secondary mr-1" (click)="printPage()"></p-button>

                <p-button label="Previous month" icon="mdi mdi-calendar-arrow-left"
                    styleClass="p-button-outlined p-button-sm p-button-secondary" (click)="setDates('last')"></p-button>
                <p-button label="Next month" icon="mdi mdi-calendar-arrow-right"
                    styleClass="p-button-outlined p-button-sm p-button-secondary" (click)="setDates('forward')"
                    iconPos="right">
                </p-button>
            </div>
        </p-toolbar>

        <main>
            <div>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Floor</th>
                            <th>Room</th>
                            <th *ngFor="let d of summary.header.days" pTooltip="{{d.dd}} {{d.f}}" tooltipPosition="top">
                                {{d.day + 1}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let asset of summary.assets">
                            <td>{{asset.floorTitle}}</td>
                            <td class="room" [showDelay]="1000" tooltipPosition="right"
                                pTooltip="{{asset.shapeTitle || asset.title}}">
                                <span>{{asset.shapeTitle || asset.title}}</span>

                            </td>

                            <td *ngFor="let d of summary.header.days" class="b-{{asset.days[d.day].c}}"
                                [class.is-hovering]="asset.days[d.day].hover" tooltipPosition="bottom"
                                pTooltip="{{asset.days[d.day].h}}">
                                {{asset.days[d.day].p || '-'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <app-rating-ae [percentage]="summary.totalPerc" [width]="240"></app-rating-ae>
            </div>

        </main>

    </app-theme-page-section>
</app-theme-page-container>

<div class="_modal" *ngIf="asset">
    <div>
        <nav>
            <span (click)="asset=null" class="can-click"><i class="mdi mdi-chevron-left"></i>
                {{asset.asset.title}}</span>
        </nav>
        <app-theme-page-section>
            <app-d3-occupancy [asset]="asset.asset" [df]="asset.df" [dt]="asset.dt"></app-d3-occupancy>
        </app-theme-page-section>
    </div>
</div>

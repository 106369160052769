<div class="new-conditions row" [hidden]="action">
  <div class="col-xs-12 col-sm-6 col-md-4">
    <app-button label="mdi mdi-cog-outline" title="Control something" label="Send value to a sensor" (click)="newAction('switch')"></app-button>
  </div>
</div>
@if(action) {
<app-dialog [canScroll]="true" (onClose)="action=null">
  <app-rule-action-new [modifyAction]="_modifyAction" [defaultSite]="defaultSite" [defaultGateway]="defaultGateway" (cancel)="newRuleActionCancelled()" (submit)="newRuleActionSubmitted($event)">
  </app-rule-action-new>
</app-dialog>
}

<div class="movement-controls isMobile" *ngIf="viewbox && hasLoaded">
    <!-- set the container for the content-->
    <div>
        <!-- divide the content into left and right -->
        <div>
            <span class="mdi mdi-chevron-left" (click)="navigateBack()"></span>
        </div>
        <div>
            <span class="mdi mdi-magnify-minus-outline" (click)="zoom('out')"></span>
            <span class="mdi mdi-fullscreen" (click)="zoom('reset')"></span>
            <span class="mdi mdi-magnify-plus-outline" (click)="zoom('in')"></span>
            <span class="mdi mdi-arrow-left-thin-circle-outline" (click)="panDirection('left')"></span>
            <span class="mdi mdi-arrow-right-thin-circle-outline" (click)="panDirection('right')"></span>
            <span class="mdi mdi-arrow-up-thin-circle-outline" (click)="panDirection('up')"></span>
            <span class="mdi mdi-arrow-down-thin-circle-outline" (click)="panDirection('down')"></span>
        </div>


    </div>
</div>
<div [class.svg-image-rendered]="isSVGImageRendered" class="plan-root">
    <div class="hidden-xs can-click" (click)="back()">
        <app-page-title [canGoBack]="true" [label]="siteFloorplan?.title ? siteFloorplan.site.title + ' - ' + siteFloorplan.title: site?.title">
        </app-page-title>
    </div>

    <div class="floorplan-container" [class.dashboard-selected]="dashboardSelected" *ngIf="hasLoaded">

        <div class="left no-print">
            <div *ngIf="hasDashboard" class="can-click" [class.selected]="dashboardSelected" routerLink="dashboard">
                <i class="mdi mdi-view-dashboard-outline"></i>
                Dashboard
            </div>
            <div class="no-print">
                <h4>Floors</h4>
                <div>
                    <div *ngFor="let plan of siteFloorplans" class="can-click" (click)="onClickSitePlanLabel(plan)" [class.selected]="plan.id === siteFloorplan?.id">
                        {{plan.title}}
                    </div>
                </div>
            </div>
            <div class="no-print" *ngIf="selected.presentation==='floorplan' && areas.length">
                <h4>Areas</h4>
                <div class="area-list">
                    <div class="can-click" [class.selected]="selectedArea?.id === area.id" *ngFor="let area of areas" class="can-click" (click)="clickFloorplanLabel(area)">
                        <i class="mdi mdi-eye" (mouseenter)="previewArea(area)" (mouseleave)="previewArea(null)"></i>
                        &nbsp;<span style="color:#e0e0e0">|</span> &nbsp; <span (mouseenter)="onMouseEnterAreaLabel(area)" (mouseleave)="onMouseLeaveAreaLabel(area)">{{area.title}}</span>
                    </div>
                </div>
            </div>
            <div class="no-print">
                <h4>Presentations</h4>
                <div class="can-click" (click)="switchTo('floorplan')" [class.selected]="selected.presentation==='floorplan'">Plan</div>
                <div class="can-click" (click)="switchTo('table')" [class.selected]="selected.presentation==='table'">
                    Table</div>
                <ng-template [ngIf="selected.presentation==='table'" ]>
                    <h1>Options</h1>
                    <ul class="option-list">
                        <li>
                            <span>From:</span>
                            <input class="form-control" [(ngModel)]="selected.range.startTime">
                        </li>
                        <li>
                            <span>To:</span>
                            <input class="form-control" [(ngModel)]="selected.range.endTime">
                        </li>
                        <li>
                            <span>Type:</span>
                            <select class="form-control" [(ngModel)]="selected.range.type">
                                <option value="avg">Average</option>
                                <option value="min">Minimum</option>
                                <option value="max">Maximum</option>
                            </select>
                        </li>
                        <li>&nbsp;</li>
                        <li>
                            <button (click)="updateOptions()" class="btn btn-sm btn-block btn-secondary">
                                <i class="fa fa-refresh"></i> Update</button>
                        </li>
                    </ul>
                </ng-template>

            </div>
            <div class="weather">
                <h4>Site Weather</h4>
                <div class="site-weather" pTooltip="Weather at site" tooltipPosition="bottom">
                    <img width="32px" height="32px" *ngIf="site.localWeatherIcon" src="https://s3.eu-west-2.amazonaws.com/4d-icons/weather/v2/{{site.localWeatherIcon}}.png">
                    <p>{{site.localWeatherTemp}} °C</p>
                </div>
            </div>
        </div>

        <div class="plan" [class.dashboard]="dashboardSelected">
            <div *ngIf="dashboardSelected && site?.id" class="dashboard-wrapper">
                <app-page-title [canGoBack]="true" label="Dashboard" (click)="dashboardSelected=false"></app-page-title>
                <app-site-plan-dashboard [site]="site" [plans]="siteFloorplans"></app-site-plan-dashboard>
            </div>

            <div class="chart-table" *ngIf="!dashboardSelected && siteFloorplan && assets && assets.length && selected.presentation === 'table'">
                <app-site-table [siteFloorplan]="siteFloorplan" [assets]="assets" [range]="selected.range">
                </app-site-table>
            </div>

            <div class="chart-parent" *ngIf="!dashboardSelected && siteFloorplan && assets && selected.presentation === 'chart'">
                <app-site-chart [siteFloorplan]="siteFloorplan" [assets]="assets" [range]="selected.range">
                </app-site-chart>
            </div>
            <!-- <div class="DELETETHIS">
                    <p></p>
                    <app-shape-manage *ngIf="shapes" [shape]="shapes[0]"></app-shape-manage>
                </div>
            -->
            <div class="svg-parent" *ngIf="!dashboardSelected && siteFloorplan && selected.presentation === 'floorplan' && assets && assets.length >= 0">

                <img [class.bw]="!siteFloorplan?.isColour" src="https://55auilnnlh.execute-api.eu-west-2.amazonaws.com/4d/floorplans/{{siteFloorplan.planKey}}.svg#svgView(viewBox({{viewbox.calculated.x}},{{viewbox.calculated.y}},{{viewbox.calculated.w}},{{viewbox.calculated.h}}))" (load)="svgLoaded()">
                <svg class="svg-data" #svgDataContainer (mousemove)="svgMouseMove($event)" [attr.viewBox]="viewbox.calculated.x + ' ' + viewbox.calculated.y + ' ' + viewbox.calculated.w + ' ' + viewbox.calculated.h" *ngIf="isSVGImageRendered">
                    <filter x="0" y="0" width="1" height="1" id="label-background">
                        <feFlood flood-color="green"></feFlood>
                        <feComposite in="SourceGraphic"></feComposite>
                    </filter>
                    <filter id="blurme">
                        <feGaussianBlur stdDeviation="5"></feGaussianBlur>
                    </filter>

                    <g *ngFor="let area of areas">
                        <rect class="focused-area" *ngIf="areaFocus?.id === area.id" [attr.width]="area.width" [attr.height]="area.height" [attr.x]="area.x" [attr.y]="area.y"></rect>
                    </g>

                    <!-- SHAPES -->
                    <g *ngFor="let shape of shapes" class="shape" (click)="shapeClick(shape)">
                        <g class="shape-container" [innerHTML]="sanitizedHTML(shape)" [class.rag-red]="shape.atRAG==='red'" [class.rag-amber]="shape.atRAG==='amber'" [class.selected]="shape.id === selectedShape?.id">
                        </g>
                    </g>

                    <g *ngFor="let asset of assets" class="asset {{asset.rag}}" [attr.transform]="asset.rotateDeg ? 'rotate(' + asset.rotateDeg + ')' :''">
                        <g *ngIf="asset.svg.x !== null && ((siteFloorplan.type === 'basic' && (asset.isAlwaysVisible || !asset.shapeId || asset.shapeId === selectedShape?.id)) || (siteFloorplan.type === 'shape' && selectedArea)) || (asset.shapeId && (asset.rag === 'amber' || asset.rag === 'red'))" [ngClass]="{'rag-green':asset.rag==='green',
                                'rag-orange':asset.rag==='orange' || asset.rag==='amber',
                                'rag-red':asset.rag==='red',
                                'rag-none': !asset.rag,
                                'disconnected': asset.daysOld > 30}" [style.font-size]="(siteFloorplan.fontSize ? siteFloorplan.fontSize : 14) + 'px'" (click)="clickSelectAsset(asset)">

                            <circle *ngIf="asset.type==='circle' && !asset.svgIcon" class="asset-circle" [attr.cx]="asset.svg.x" [attr.cy]="asset.svg.y" [attr.r]="scales.r" [attr.fill]="asset.rag || (asset.purpose.id === 6 && (asset.value === 'occupied' || asset.value === 'In Use') ? 'red' : 'green') || '#f0f0f0'">
                                <title>{{asset.id}} - {{asset.title}} - {{asset.identifier}} --
                                    {{asset.daysOld > 0 ? 'Updated ' + asset.daysOld + ' days ago.' :
                                    asset.updatedAt |
                                    date:'dd-MM-YY HH:mm'}}
                                </title>
                            </circle>

                            <!--- ICON ASSET-->

                            <g *ngIf="asset.svgIcon" class="icon-asset" [class.no-value]="!asset.showValue" [class.change-up]="asset.changeDiff > 0" [class.change-down]="asset.changeDiff < 0">
                                <rect [attr.x]="asset.svg.x" [attr.y]="asset.svg.y + asset.svgPrecision?.rect.y" [attr.width]="asset.svgWidth" [attr.height]="asset.svgHeight  + asset.svgPrecision?.rect.h" [attr.fill]="asset.background.colour" [attr.stroke]="asset.border.colour" rx="3" class="hover-target" />

                                <rect [attr.x]="asset.svg.x" [attr.y]="asset.svg.y + asset.svgPrecision?.rect.y" [attr.width]="asset.svgWidth" [attr.height]="asset.svgHeight + asset.svgPrecision?.rect.h" [attr.fill]="asset.background.colour" [attr.stroke]="asset.border.colour" rx="3" style="pointer-events: none;" />

                                <g class="images">

                                    <svg:image class="change-direction up" [attr.x]="asset.svg.x + asset.svgIconDimensions.x + 1" [attr.y]="asset.svg.y + asset.svgIconDimensions.y + 1" href="assets/svg/black/up-arrow.svg" [attr.height]="asset.svgIconDimensions.h /2" [attr.width]="asset.svgIconDimensions.w / 2" />

                                    <svg:image class="change-direction down" [attr.x]="asset.svg.x + asset.svgIconDimensions.x + 1" [attr.y]="asset.svg.y + asset.svgIconDimensions.y + 1" href="assets/svg/black/down-arrow.svg" [attr.height]="asset.svgIconDimensions.h /2" [attr.width]="asset.svgIconDimensions.w / 2" />

                                    <svg:image class="icon" [attr.href]="asset.svgIconPath" [attr.height]="asset.svgIconDimensions.h" [attr.width]="asset.svgIconDimensions.w" [attr.x]="asset.svg.x + asset.svgIconDimensions.x" [attr.y]="asset.svg.y + asset.svgIconDimensions.y + 1" />
                                </g>

                                <text *ngIf="asset.showValue" [attr.x]="(asset.svg.x + asset.svgTextAdjust.x) + asset.svgPrecision?.text.x" [attr.y]="(asset.svg.y + asset.svgTextAdjust.y) + asset.svgPrecision?.text.y" font-family="Roboto" [style.font-size]="asset.mfs" class="value">{{asset.value}}</text>
                            </g>

                            <!-- END -->

                            <g *ngIf="asset.type==='panel'">
                                <rect [attr.x]="asset.svg.x" [attr.y]="asset.svg.y" [attr.width]="asset.mw || 10" [attr.height]="asset.mh || (asset.mfs * 2) || ( siteFloorplan.fontSize * 1.2)" [attr.fill]="asset.rag || (asset.purpose.id === 6 && (asset.value === 'occupied' || asset.value === 'In Use') ? 'red' : 'green') || '#f0f0f0'">
                                    <title>{{asset.id}} - {{asset.title}} - {{asset.identifier}}
                                    </title>
                                </rect>
                                <!--  [attr.filter]="url('label-background')"-->
                                <text title="{{asset.id}} - {{asset.title}} - {{asset.identifier}}" id="asset-{{asset.id}}" class="asset-panel title" [attr.x]="asset.svg.x + ((asset.mw || 10) / 2)" [attr.y]="asset.svg.y + asset.my" text-anchor="middle" [style.font-size]="asset.mfs">
                                    {{asset.svg.label}}
                                </text>
                                <text title="{{asset.id}} - {{asset.title}} - {{asset.identifier}}" id="asset-{{asset.id}}" class="asset-panel value" [attr.x]="asset.svg.x + ((asset.mw || 10) / 2)" [attr.y]="asset.svg.y + asset.my + asset.my " text-anchor="middle" [style.font-size]="asset.mfs">
                                    {{asset.value || (asset.purpose.id === 6 ? (asset.rag === 'red' ? 'In Use' :
                                    'Free')
                                    : 'Free') }}
                                </text>
                            </g>

                            <g *ngIf="asset.type === 'circle' && ! asset.svgIcon">
                                <text [attr.x]="asset.svg.x" [attr.y]="asset.svg.y + 1 " text-anchor="middle">
                                    <tspan *ngIf="asset.assetType_id === 17">
                                        {{asset.assetType_id === 17 ? (asset.value === '0' || asset.value ===
                                        '0.0'
                                        ?
                                        'Closed' : 'Open') : ''}}
                                    </tspan>
                                    <text *ngIf="asset.purpose.id === 6"> {{asset.svg.label}}</text>
                                    <tspan *ngIf="asset.purpose.id !== 6">
                                        {{asset.assetType_id !== 17 ?
                                        (asset.assetType_id !== 20 ? asset.value : (+asset.value).toFixed(0)) :
                                        ''}}
                                    </tspan>
                                </text>
                            </g>
                            <g *ngIf="asset.type !== 'panel' && asset.assetType_id !== 17 &&  !asset.svgIcon">
                                <!-- default is to have a label -->
                                <text class="label" [attr.x]="asset.svg.x" [attr.y]="asset.svg.y + scales.labelYOffset" text-anchor="middle">
                                    {{asset.purpose.id !== 6 ? asset.svg.label : ''}}
                                </text>
                            </g>
                        </g>
                    </g>
                </svg>
                <div [class.hidden]="!hoverSensor">
                    <div class="info-box" #info>
                        <div *ngIf="hoverSensor">
                            <div class="title">{{hoverSensor?.asset.title}}</div>
                            <div class="type">{{hoverSensor?.asset.assetTypeTitle}}</div>
                            <div class="id">#{{hoverSensor?.asset.id}} -
                                {{hoverSensor?.asset.identifier}}</div>

                            <div>{{hoverSensor?.asset.updatedAt |
                                amDateFormat:'DD/MM/YY HH:mm' }}, <i>{{hoverSensor?.asset.updatedAt |
                                    amTimeAgo}}</i>
                            </div>

                            <div *ngIf="hoverSensor.telemetry && hoverSensor.asset.assetType_id !== 1" class="asset-chart">
                                <div>Today</div>
                                <div><span *ngIf="hoverSensor.asset.assetType_id !== 50">{{hoverSensor.max}}</span></div>
                                <div><span *ngIf="hoverSensor.asset.assetType_id !== 50">{{hoverSensor.min}}</span></div>
                                <div class="d3-chart">

                                    <ng-template [ngIf]="hoverSensor.asset.assetType_id === 50">

                                        <app-setpoint-query-occ-squares [assetId]="hoverSensor.asset.id"></app-setpoint-query-occ-squares>
                                    </ng-template>
                                    <ng-template [ngIf]="hoverSensor.asset.assetType_id !== 50">
                                        <!-- chart only when not a button-->
                                        <app-d3-chart width="298" height="100" [asset]="hoverSensor.asset" [telemetry]="hoverSensor.telemetry" [showLegend]="false" [minMaxFromData]="true">
                                        </app-d3-chart>
                                    </ng-template>

                                </div>
                            </div>

                            <div *ngIf="hoverSensor.asset.assetType_id === 1" class="asset-button">
                                <div *ngFor="let row of hoverSensor.telemetry?.reverse();let i = index">
                                    <div *ngIf="i < 10">
                                        Touched {{row.d | amTimeAgo}}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="current-area" *ngIf="selectedArea">
                    <span (click)="clickFloorplanLabel()">{{selectedArea.title}}
                        <i class="fa fa-fw fa-times"></i></span>
                </div>

                <div class="hover-panel" *ngIf="hoverObject" [style.left.px]="hoverObject.left" [style.top.px]="hoverObject.top">
                    <div>{{hoverObject.obj.title}}</div>
                    <div>{{hoverObject.left}}</div>
                    <div>{{hoverObject.top}}</div>
                </div>
                <span [class.is-loading]="isLoading" [class.is-not-loading]="!isLoading">
                    <i class="fa fa-fw fa-circle fa-spin"></i>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="viewAsset">
        <div class="modal__overlay">
            <app-site-plan-asset [asset]="viewAsset" (onBack)="viewAsset=null" [fullscreen]="true">
            </app-site-plan-asset>
        </div>
    </div>
    <div *ngIf="selectedShape">
        <div class="modal__overlay">
            <app-site-plan-shape [shape]="selectedShape" (onBack)="selectedShape=null" [fullscreen]="true">
            </app-site-plan-shape>
        </div>
    </div>
</div>
<app-please-wait *ngIf="isLoading"></app-please-wait>

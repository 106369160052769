import { Component, OnInit } from '@angular/core';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-dev-landing',
  templateUrl: './dev-landing.component.html',
  styleUrls: ['./dev-landing.component.css']
})
export class DevLandingComponent implements OnInit {

  apis: any[];

  constructor(private apiServivce: APIService) { }

  ngOnInit() {
  }

}

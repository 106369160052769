import { Component, OnInit, Input } from '@angular/core';
import { Asset } from '../../classes/asset';
import { Gateway } from '../../classes/gateway';
import { Site } from '../../classes/site';
import { APIService } from '../../shared/api.service';
import moment from 'moment';

@Component({
	selector: 'app-asset-overview',
	templateUrl: './asset-overview.component.html',
	styleUrls: ['./asset-overview.component.css']
})
export class AssetOverviewComponent implements OnInit {

	@Input()
	position: string;
	@Input()
	asset: Asset;
	@Input() // Optional
	gateway: Gateway;
	@Input() // Site
	site: Site;
	@Input()
	showLocation: boolean;
	@Input()
	showSite: boolean;
	charts: any[];
	data: any;
	options: any;
	constructor(public apiService: APIService) { }

	ngOnInit() {
		this.charts = [];

		this.data = {
			labels: [],
			datasets: [
				{
					label: 'recent Telemetry',
					data: [],
					backgroundColor: '#02ACEE'
				}
			]
		};

		this.options = {};

		console.log('===========================');
		console.log('AssetOverviewComponent init', this.asset);
		console.log('===========================');
		this.apiService.getGateway(this.asset.gateway_id).then((gateway) => {

			if (gateway && gateway.id === this.asset.gateway_id) {
				console.log('SUB:AssetOverviewComponent got gateway', gateway);
				this.gateway = gateway;
				this.apiService.getSite(this.gateway.site_id).then((site) => {
					this.site = site;
				});
				this.data.datasets[0].label = this.asset.assetType_id + ' ' + this.apiService.getAssetTypeTitle(this.asset);
				this.apiService.getTelemetry(this.asset.id, 20).then(
					(results) => {
						console.log('got telemetry');
						if (this.asset.assetType_id === this.apiService.ASSET_TYPES_BY_TITLE.FOOTFALL) {
							// For footfall set the bar chart to consistant maximum/minimum
							this.options = {
								scales: {
									yAxes: [{
										ticks: {
											max: 4,
											min: -4,
											stepSize: 1
										}
									}]
								}
							};
						} else if (this.asset.assetType_id === this.apiService.ASSET_TYPES_BY_TITLE.ROOM_TEMPERATURE) {
							this.options = {
								scales: {
									yAxes: [{
										ticks: {
											min: 0,
											stepSize: 5
										}
									}]
								}
							};
						}

						this.data.datasets[0].data = [];
						this.data.labels = [];
						this.charts = [];
						for (var idx = 0; idx < results.length; idx++) {
							var line = results[idx];
							line.value = Number(line.value);

							this.data.datasets[0].data.push(line.value);
							this.data.labels.push(moment(line.createdAt).fromNow());
						}

						this.charts = [{ id: 1 }];

						console.log(this.charts);
					});

			}
		});
	}

}

<div class="tabview-list">
    <div [class.active]="tabIndex()===0" (click)="tabIndex.set(0)">
        <i class="mdi mdi-license"></i> Licenses
    </div>
    <div [class.active]="tabIndex()===1" (click)="tabIndex.set(1)">
        <i class="mdi mdi-office-building-outline"></i> Buildings
    </div>
    <div [class.active]="tabIndex()===2" (click)="tabIndex.set(2)">
        <i class="mdi mdi-database-check"></i> Audit
    </div>
    <div></div>
</div>

<div class="tabview-content">
    @switch (tabIndex()) {

    @case(0) {
    <app-billing-licenses-list></app-billing-licenses-list>
    }

    @case(1) {
    <app-billing-buildings-list></app-billing-buildings-list>
    }

    @case(2) {
    <app-billing-audit></app-billing-audit>
    }

    }
</div>
<!--
<p-tabView>

    <p-tabPanel [cache]="false">
        <ng-template pTemplate="header">
            <span><i class="mdi mdi-license"></i> Licenses</span>
        </ng-template>
        <ng-template pTemplate="content">
            <app-billing-licenses-list></app-billing-licenses-list>
        </ng-template>
    </p-tabPanel>

    <p-tabPanel [cache]="true">
        <ng-template pTemplate="header">
            <span><i class="mdi mdi-office-building-outline"></i> Buildings</span>
        </ng-template>
        <app-theme-page-section>
            <app-billing-buildings-list></app-billing-buildings-list>
        </app-theme-page-section>
    </p-tabPanel>

    <p-tabPanel [cache]="false">
        <ng-template pTemplate="header">
            <span><i class="mdi mdi-database-check"></i> Audit</span>
        </ng-template>
        <ng-template pTemplate="content">
            <app-billing-audit></app-billing-audit>
        </ng-template>
    </p-tabPanel>

</p-tabView>
-->

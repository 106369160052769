import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';

import moment from 'moment';
import { Building } from 'app/classes/building';
import { CollectionAsset } from 'app/classes/collection-for-assets';
import { InsightReview } from 'app/classes/insight-review';
import { Org } from 'app/classes/org';
import { User } from 'app/classes/user';

@Injectable({
	providedIn: 'root'
})
export class InsightsReviewService {
	API_URL = 'https://t59hry4us9.execute-api.eu-west-2.amazonaws.com/4d/reviews';
	API_URL_PARENT = 'https://t59hry4us9.execute-api.eu-west-2.amazonaws.com/4d';

	constructor(private apiService: APIService, private http: HttpClient) { }

	async getReviewAdmin(insight: InsightReview): Promise<IInsightReviewAdminSupporting> {
		const { dbStart, dbEnd } = this.startEndDate(insight.configuration.start, 2);
		const assets = insight.collection.assets.map(a => a.id);
		const response = await this.post({ assets }, `a=get-review-admin&start=${dbStart}&end=${dbEnd}`);

		return response;
	}

	async getReviewFromUUID(uuid: string): Promise<any> {
		const response = await this.get(`a=get-review-from-uuid&uuid=${uuid}`);

		return response;
	}

	async updateReviewAnnotations(insight: InsightReview): Promise<any> {
		return new Promise(async (resolve, reject) => {
			const qs = `a=update-review-annotations`;
			const url = `${this.API_URL}?${qs}`;

			return this.http
				.post<any>(url, insight.serialise(), this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	startBuildingJob(building: Building, collectionAssets: CollectionAsset[], startDate: Date, endDate: Date, clearCache: boolean, title: string, qs = ''): Promise<IStartBuildingJob> {
		return new Promise(resolve => {
			const payload = this.buildPayload(building, startDate, endDate, title, 2);

			collectionAssets
				.forEach(c => c.assets.forEach(a => payload.assets.push({
					id: a.id,
					assetType_id: a.assetType_id,
					collectionId: c.id,
					metobsid: a.metobsid
				})));
			// Make sure the order is always the same for caching
			payload.assets.sort((a, b) => a.id > b.id ? 1 : -1);

			payload.assets.forEach(a => {
				switch (a.assetType_id) {
					case 15: //footfall
						payload.types.push({ id: a.id, type: a.assetType_id, title: a.title, c: a.collectionId, metobsid: a.metobsid });
						break;
				}
			});

			if (payload.assets.length === 0) {
				throw new Error('No assets');
			}

			const dbStart = payload.start.clone().utc().format('YYYY-MM-DD HH:mm:ss');
			const dbEnd = payload.end.clone().utc().format('YYYY-MM-DD HH:mm:ss');

			qs = qs + `&a=start-background-job&job=get-review&&i=${building.id}&start=${dbStart}&end=${dbEnd}`;
			if (clearCache) {
				qs += `&clearcache=1`;
			}

			return this.post(payload, qs, this.API_URL)
				.then(b => {
					resolve(b);
				});
		});
	}


	/**
	 * Build payload in UTC
	 * 
	 * @param building 
	 * @param startDate 
	 * @param title 
	 * @returns 
	 */
	private buildPayload(building: Building, startDate: Date, endDate: Date, title: string, months = 0) {
		const start = moment(startDate).startOf('day');
		const end = moment(endDate).endOf('day');
		const days = Math.abs(end.diff(start, 'days')) + 1;

		title += ' for ' + building.title + ' from ' + start.format('DD/MM/YYYY') + ' to ' + end.format('DD/MM/YYYY');
		const payload = { ranges: [], assets: [], types: [], title, start, end };

		for (let index = 0; index < days; index++) {
			const dt = moment(start).add(index, 'days');
			const dow = dt.isoWeekday() - 1;
			const hours = building.openingHours.hours.find(day => day.dow === dow && !day.isClosed);
			if (hours) {

				const timeFrom = hours.from;
				const timeTo = hours.to;
				try {
					const from = moment(dt).set('hour', +timeFrom.substr(0, 2)).set('minutes', +timeFrom.substr(3, 2)).set('seconds', 0).utc();
					const to = moment(dt).set('hour', +timeTo.substr(0, 2)).set('minutes', +timeTo.substr(3, 2)).set('seconds', 0).utc();

					payload.ranges.push({ from: from.format('YYYY-MM-DD HH:mm:ss'), to: to.format('YYYY-MM-DD HH:mm:ss') });
				} catch (e) {
					payload.ranges.push({ from: null, to: null });
				}
			}
			// SERVER NEEDS UTC
		}

		return payload;
	}

	getReviewsForBuilding(building: Building, collectionId: number = null): Promise<IGetReviewsForBuildingItem> {
		let qs = `a=get-building-reviews-list&i=${building.id}`;
		if (collectionId) {
			qs += `&c=${collectionId}`;
		}

		return this.get(qs);
	}

	async getReviewQuickview(id: number): Promise<IGetReviewQuickview> {
		const response: IGetReviewQuickview = await this.get(`a=get-review-quick-view&id=${id}`);

		return response;
	}

	getReviewsForOrg(): Promise<IGetReviewsForBuildingItem> {
		let qs = `a=get-org-building-reviews-list`;

		return this.get(qs);
	}


	getReviewsForAdmin(orgs: Org[]): Promise<ReviewsForBuildingItemReview[]> {
		return new Promise((resolve) => {
			let qs = `a=get-all-building-reviews-list`;

			this.get(qs).then((review: IGetReviewsForBuildingItem) => {

				resolve(review.reviews.map(r => new ReviewsForBuildingItemReview(r, orgs, review.users)));
			});
		});
	}

	getReviewJSON(uuid: string): Promise<any> {
		return new Promise((resolve) => {
			let qs = `a=get-json&uuid=${uuid}`;
			this.get(qs).then((json) => {
				resolve(json);
			});
		});
	}

	get(qs: string = '', api = this.API_URL): Promise<any> {
		const url = `${api}?${qs}`;
		return this.http
			.get<any>(url, this.apiService.getUAOHeaders())
			.toPromise();
	}

	post(body, qs: string = '', api = this.API_URL): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `${api}?${qs}`;
			return this.http
				.post<any>(url, body, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	private startEndDate(startDate: Date, months = 0) {
		const start = moment(startDate).startOf('month').startOf('day');
		const end = moment(startDate).add(months, 'months').endOf('month').endOf('day');
		const days = Math.abs(end.diff(start, 'days')) + 1;
		console.log('DIFF_DATE', start.format('DD/MM/YY HH:mm:ss'), end.format('DD/MM/YY HH:mm:ss'), days);
		if (months == 2 && days > 93) {
			console.error('ERROR_DIFF_DATE');
		}
		const dbStart = start.clone().utc().format('YYYY-MM-DD HH:mm:ss');
		const dbEnd = end.clone().utc().format('YYYY-MM-DD HH:mm:ss');

		return { start, end, days, dbStart, dbEnd };
	}
}

export interface IGetReviewsForBuildingItem {
	reviews: IGetReviewsForBuildingItemReview[];
	collectionGroup: any[];
	users?: User[];
}

export interface IGetReviewQuickview {
	review: {
		building_id: number,
		collection_id: number,
		createdAt: Date,
		createdBy: number,
		endDate: Date,
		forDate: Date,
		id: number,
		job_uuid: string,
		notes: string,
		org_id: number,
		state: string,
		title: string,
		building: {
			title: string,
			id: number,
			createdAt: Date,
			isActive: number,
			site_id: number
		},
		collection: {
			building_id: number,
			createdAt: Date,
			createdBy: number,
			id: number,
			org_id: number,
			site_id: number,
			title: string,
			type_id: number,
			uuid: string,
			assets: {
				assetTitle: string,
				asset_id: number,
				assetType_id: number,
				collection_id: number,
				createdAt: Date,
				gatewayTitle: string,
				gateway_id: string,
				orgShortTitle: string,
				siteTitle: string,
				title: string,
			}[]
		}
	};
	collections: {
		building_id: number,
		createdAt: Date,
		createdBy: number,
		id: number,
		org_id: number,
		site_id: number,
		title: string,
		type_id: number,
		uuid: string
	}[];
	collectionAssets: {
		assetTitle: string,
		asset_id: number,
		assetType_id: number,
		collection_id: number,
		createdAt: Date,
		gatewayTitle: string,
		gateway_id: string,
		orgShortTitle: string,
		siteTitle: string,
		title: string,
	}[];
	calendarItems: {
		allDay: string,
		body: string,
		createdAt: Date,
		createdBy: number,
		createdByName: string,
		documentKey: string,
		document_id: number,
		endAt: Date,
		id: number,
		isCompleted: 'Y' | 'N',
		labels: string,
		rag: string,
		startAt: Date,
		title: string,
		type: string,
		isHighlighted: boolean
	}[];
};

export interface IGetReviewsForBuildingItemReview {
	id: number; // insight_review.id
	title: string; // job.title
	state: string;
	job_uuid: string;
	createdAt: Date;
	createdBy: number;
	org_id: number;
	orgTitle?: string;
	orgShortTitle?: string;
	collection_id: number;
	collectionTitle?: string
	forDate: Date;
	endDate: Date;
	building_id: number;
	// Admin
	coid?: number;
	cbid?: number;
}
export class ReviewsForBuildingItemReview {
	id: number; // insight_review.id
	title: string; // job.title
	state: string;
	job_uuid: string;
	createdAt: Date;
	createdBy: User;
	org: Org;
	collection_id: number;
	collectionTitle?: string
	collectionOrg?: Org;
	forDate: Date;
	endDate: Date;
	building_id: number;
	collectionBuilding_id: number;


	constructor(data: IGetReviewsForBuildingItemReview, orgs: Org[], users: User[]) {
		this.id = +data.id;
		this.title = data.title;
		this.job_uuid = data.job_uuid;
		this.createdAt = new Date(data.createdAt);
		this.collection_id = data.collection_id;
		this.collectionTitle = data.collectionTitle;
		this.forDate = new Date(data.forDate);
		this.endDate = new Date(data.endDate);
		this.building_id = +data.building_id;
		this.org = orgs.find(o => o.id === data.org_id);
		this.collectionOrg = orgs.find(o => o.id === data.coid);
		this.collectionBuilding_id = +data.cbid;
		this.createdBy = users.find(u => u.id === data.createdBy);
	}
}

export interface IInsightReviewAdminSupporting {
	alarms: IInsightReviewAdminSupportingAlarm[];
	commonAnnotations: { rag: string, annotation: string }[];
	telemetryAnnotations: {
		asset_id: number;
		asset_value_id: number;
		createdAt: Date;
		name: string;
		org_id: number;
		rag: string;
		text: string;
		value: string | number;
	}[];
}

export interface IInsightReviewAdminSupportingAlarm {
	id: number,
	d: Date,
	t: string,
	la: number,
	ra: number,
	tag: string
}

export interface IStartBuildingJob {
	uuid: string;
	createdAt: Date;
	stage: StartBuildingJobStage;
	state: string;
	wait: number;
	annotations: IStartBuildingJobAnnotation[];
}

export interface IStartBuildingJobAnnotation {
	annotation: string;
	asset_id: number;
	id: number;
	createdAt: string;
	insight_review_id: number;
	irc_id?: number;
	rag: 'red' | 'amber' | 'green';
}

export type StartBuildingJobStage = 'new' | 'done';

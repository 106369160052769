import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { Asset } from '../../classes/asset';
import { Gateway } from '../../classes/gateway';
import { StoreService } from '../../shared/store.service';
import { Report } from '../../classes/reports/report';
import { ReportConfig } from '../../classes/reports/report-config';
import { SelectedAsset } from '../../classes/rule-service/selected-asset';
import { filter } from 'rxjs/operators';
import { AssetService } from 'app/shared/asset.service';
import moment from 'moment';

@Component({
  selector: 'app-report-configure',
  templateUrl: './report-configure.component.html',
  styleUrls: ['./report-configure.component.css']
})
export class ReportConfigureComponent implements OnInit {

  @Input()
  report: Report; // Report

  formValid: boolean;
  reportConfig: ReportConfig = new ReportConfig();
  startAt: Date;
  // The asset seelcted by the user in asset picker
  selectedAsset: SelectedAsset;
  selectedCount = 0;
  reportAllGateways: boolean = false;
  runReport: boolean;
  en: any;

  gateways: Gateway[];
  reportsRunning: boolean;
  defaultTab: any;

  collection: { report: Report, reportConfig: ReportConfig }[] = [];
  reportTabIndex: number = 0;

  constructor(private assetService: AssetService, private storeService: StoreService, private apiService: APIService, private route: ActivatedRoute, private router: Router) {

    this.defaultTab = +(localStorage.getItem('reports:defaultTab') || 0);

    storeService.sectionBannerBack
      .pipe(filter(sbb => !!sbb && sbb.state === true && sbb.section === 'reports'))
      .subscribe(state => {
        // Set to handled
        state.state = false;
        this.storeService.setSectionBannerBack(state);
        if (this.runReport) {
          this.runReport = null;
        } else {
          this.router.navigate(['/reports']);
        }
      });
  }

  sortGateways() {

  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.apiService.getReport(params['reportid']).then(
        (report) => {
          this.report = report;

          // Report is restricted to a specific asset type
          if (this.report.forAssetType) {
            // Check for one of the type - default to the asset if only one.
            this.storeService.assets.subscribe((assets) => {
              for (var item of assets) {
                if (this.selectedAsset.asset && item.assetType_id === this.report.forAssetType) {
                  // Already exists - clear it
                  this.selectedAsset.asset = new Asset();
                } else if (item.assetType_id === this.report.forAssetType) {
                  this.selectedAsset.asset = item;
                }
              }
            });
          }
          if (this.report.forGateway) {
            this.apiService.getGateways(this.report.forGatewayType > 0 ? this.report.forGatewayType : null)
              .then((gateways) => {
                this.gateways = gateways;
              });
          }
        }
      );
      this.reportConfig.startAt = this.getMonday();
    });

    this.en = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    };

    this.selectedAsset = new SelectedAsset();
  }

  handleChange(event: any) {
    localStorage.setItem('reports:defaultTab', event.index);
    this.defaultTab = event.index;
  }

  runReportClick() {
    if ((this.selectedCount === 0 && !this.reportAllGateways) || !this.reportConfig.startAt) {
      return;
    }

    this.collection = [];
    this.reportTabIndex = 0;
    if (this.reportAllGateways) {
      // Ensure no gateways are selected if all required.
      if (this.defaultTab === 0) {
        this.reportConfig.forGateways = this.gateways;
      } else {
        this.reportConfig.forGateways = this.getFavs();
      }
    } else {
      this.reportConfig.forGateways = this.gateways.filter(g => g.selected);
    }
    this.startAt = moment(this.reportConfig.startAt).toDate();
    this.reportConfig.forAsset = this.selectedAsset.asset;
    this.reportConfig.forOrg = this.apiService.getUserOrg().id;
    this.collection.push({ report: this.report, reportConfig: this.reportConfig });

    this.reportConfig.setTabTitle(this.report);
    ``
    this.runReport = true;
    this.apiService.postRoute(`/reports/${this.report.id}/generate`);
  }


  getFavs(): Gateway[] {
    try {
      const favs = JSON.parse(localStorage.getItem('gateways:favs') || '{}');
      return this.gateways.filter(gateway => !!favs[gateway.id]);
    } catch (e) {
      return [];
    }
  }

  getDateFormat() {
    return this.apiService.getDateFormat();
  }

  reportStartAt(days: number): void {
    if (this.reportsRunning) {
      return;
    }

    let newConfig = new ReportConfig();
    newConfig.startAt = this.reportConfig.startAt;
    newConfig.forAsset = this.reportConfig.forAsset;
    newConfig.forGateways = this.reportConfig.forGateways;

    let newReport = new Report(this.report);

    switch (this.report.dateSpan) {
      case 'week':
        newConfig.startAt.setDate(newConfig.startAt.getDate() + days);
        break;
      case 'month':
        newConfig.startAt.setMonth(newConfig.startAt.getMonth() + days);
        break;
    }

    this.startAt = moment(this.reportConfig.startAt).toDate();

    newConfig.setTabTitle(this.report);

    for (let index = 0; index < this.collection.length; index++) {
      const element = this.collection[index];
      if (element.reportConfig.tabTitle === newConfig.tabTitle) {
        this.reportTabIndex = index;
        return;
      }
    }
    // Check if the tab exists.

    this.reportsRunning = true;
    this.collection.push({ report: newReport, reportConfig: newConfig });
    this.reportConfig = newConfig;
    this.runReport = true;

    setTimeout(() => {
      this.reportTabIndex = this.collection.length - 1;
    }, 10);
  }

  getMonday() {
    var d = new Date();
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  hasLoaded() {
    this.reportsRunning = false;
    //alert('loaded');
  }

  gatewayToggle(gateway) {
    this.gateways.find(gw => gw.id === gateway.id).selected = gateway.selected;
    this.selectedCount = this.gateways.filter(gw => gw.selected).length;
  }
}

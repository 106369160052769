@defer( when user) {
<article class="content-container mb-1">
	<header>
		<h1>Invite User</h1>
	</header>

	<div class="details-grid">
		<div>
			Full Name
		</div>
		<div>
			Email address
		</div>
		<div class="is-tracked" [pTooltip]="isTrackable ? 'Included in engagement':'NOT included in engagement'" tooltipPosition="top">
			Engagement
		</div>
		<div>
			<input type="name" class="fourd" [(ngModel)]="invite.name" placeholder="Firstname Lastname">
		</div>
		<div>
			<input type="email" class="fourd" [(ngModel)]="invite.email" placeholder="person@email.com">
		</div>
		<div>
			<span (click)="isTrackable = !isTrackable" class="can-click">
				<span class=" mdi" [class.mdi-checkbox-marked-outline]="isTrackable" [class.mdi-checkbox-blank-outline]="!isTrackable"></span> <span>{{isTrackable ? ' Tracked in engagement' : 'Not tracked in engagement'}}</span>
			</span>
		</div>
	</div>

	<div class="mt-1">
		<div class="mb-1">Notes</div>
		<textarea rows=2 class="fourd" name="notes" [(ngModel)]="invite.notes"></textarea>
	</div>

	<h1>Modules access</h1>

	<div class="select-box">
		<label>Modules <small *ngIf="totals.modules">{{totals.modules}} selected</small>
			@if(totals.modules === 0) {
			<b style="color:red">You must select at least one module</b>
			}
		</label>
		<div class="module-list">
			<div *ngFor="let module of moduleList" [class.active]="module.selected" (click)="toggleModule(module)">
				<span tooltipPosition="top" pTooltip="{{module.tooltip}}">

					@switch(module.label) {
					@case ('profiling') {
					floorplans
					}
					@default {
					{{module.label}}
					}
					}

				</span>
			</div>
		</div>
	</div>

	<h1>Site access</h1>

	<div class="select-box" #site>

		<app-toolbar>
			<div class="left">
				Sites <small>{{siteSelectedCount()}} / {{siteList().length}}</small>
				@if(siteSelectedCount() === 0) {
				<b style="color:red"> Select at least one site</b>
				}

			</div>
			<div class="right">
				<app-button label="Toggle selected" (click)="toggleSites()"></app-button>
			</div>
		</app-toolbar>

		<div class="module-list">
			<div *ngFor="let site of siteList()" [class.active]="site.selected">
				<span (click)="toggleSite(site)" tooltipPosition="top" [pTooltip]="site.tooltip" [escape]="false">{{site.label}}</span>
			</div>
		</div>
	</div>

	<h1>Auto Expiration </h1>

	<div class="other-options">
		<div class="expires">
			<label pTooltip="Leave blank if the user never expires, the user will be unable to login after this date.">Expires
				(optional)</label>
			<div>
				<p-calendar [(ngModel)]="expiresAt" dateFormat="dd/mm/yy" [minDate]="tomorrow"></p-calendar>
			</div>
		</div>
	</div>

	<div class="buttons">
		<div class="text-right">
			<app-button label="Cancel" icon="mdi mdi-close" styleClass="p-button-outlined p-button-secondary mr-1" (click)="cancel()" class="mr-1" />
			<app-button label="Invite" icon="mdi mdi-card-account-mail-outline" (click)="inviteUser()" [isCTA]="true" />
		</div>
	</div>
</article>
} @placeholder (minimum 100ms) {
<i class="mdi mdi-loading mdi-spin-x2"></i>
}

@if(isInviting) {
<app-dialog [canInteract]="false" [dimensions]="{'width':190, 'height':90}">
	<h2>Inviting user...</h2>
</app-dialog>
}

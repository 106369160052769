<section *ngIf="blockReady">
  <ng-template ngFor [ngForOf]="assetCharts" let-asset>
    <div *ngIf="asset.data" [class.pagebreakbefore]="asset.printing.pagebreak">
      <h3>{{asset.options.title}}</h3>
      <p-chart type="line" [data]="asset.data" [options]="asset.options"> </p-chart>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Weekday</th>
            <th>Time</th>
            <th>Red Min</th>
            <th>Amber Min</th>
            <th>Amber Max</th>
            <th>Red Max
            </th>
            <th>
              <a target="_blank" pTooltip="show setpoints" class="btn btn-sm btn-secondary"
                href="/setpoints/{{asset.data.id}}">
                <i class="fa fa-external-link"></i>
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rag of asset.rags" (click)="weekdayClick(rag)"
            [class.selected]="rag.weekday === selectedRag?.weekday" [class.inactive]="!rag.isActive">
            <td>{{dows[rag.weekday]}}</td>
            <td>
              <span *ngIf="rag.allday">All Day</span>
              <span *ngIf="!rag.allday">
                <span>{{rag.startsAt | slice:0:5}}</span> - <span>{{rag.endsAt | slice:0:5}}</span>
              </span>
            </td>
            <td>{{rag.red_min}}</td>
            <td>{{rag.amber_min}}</td>
            <td>{{rag.amber_max}}</td>
            <td>{{rag.red_max}}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</section>

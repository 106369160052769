import { Component, Input, OnInit } from '@angular/core';
import { IAlarmBlock, IGetGateways, IInsightAPITypeItem, Insight } from 'app/classes/insight';
import { LimitLine } from 'app/setpoints/setpoint-query/setpoint-query.component';
import { StoreService } from 'app/shared/store.service';

@Component({
  selector: 'app-insights-report-type',
  templateUrl: './insights-report-type.component.html',
  styleUrls: ['./insights-report-type.component.css']
})
export class InsightsReportTypeComponent implements OnInit {

  @Input()
  insight: Insight;

  @Input()
  type: 'voc' | 'co2' | 'temperature' | 'humidity' | 'pm1' | 'pm2.5' | 'pm10';

  @Input()
  wantsPageBreak = false;

  gateways: IGetGateways[];
  collections: IGetGateways[];
  alarmBlocks: IAlarmBlock[];

  data: IInsightAPITypeItem;

  limitLines: LimitLine[];
  svgLocation = 'assets/svg/';

  constructor(private storeService: StoreService) {
    if (storeService.getTheme(true) === 'dark') {
      this.svgLocation = 'assets/svg/white/';
    }
  }

  ngOnInit(): void {
    if (this.insight?.telemetryCollection) {
      this.calc();
    }
  }

  calc() {
    // Get gateways
    this.gateways = this.insight.getGatewaysForType(this.type);
    this.collections = this.insight.getCollectionsForType(this.type);
    this.alarmBlocks = this.insight.getAlarmsForType(this.type);
    const assets = this.gateways.map(g => g.asset.id);
    const collectionIndex = this.insight.typeList.findIndex(v => v === this.type);
    this.data = this.insight.telemetryCollection[collectionIndex];
    //this.telemetry = this.insight.getTelemetryAverageForAssets(assets);
    this.limitLines = this.insight.getLimitLines(this.type);

  }

}

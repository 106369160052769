import { Component, Input, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OpeningHour, OpeningHours } from "../../classes/opening-hours";

@Component({
  selector: 'app-dialog-opening-hours',
  templateUrl: './dialog-opening-hours.component.html',
  styleUrls: ['./dialog-opening-hours.component.css'],
  providers: [DialogService]
})
export class DialogOpeningHoursComponent implements OnInit {


  openingHours: OpeningHours;

  constructor(public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.openingHours = new OpeningHours();
  }

  ngOnInit(): void {
    if (this.config.data.openingHours) {
      this.openingHours = this.config.data.openingHours;
    }
  }

  cancel() {
    this.ref.close();
  }

  update() {
    this.ref.close(this.openingHours);
  }

  copyToAll(hour: OpeningHour) {
    this.openingHours.hours.filter(day => !day.isClosed).forEach(day => {
      day.from = hour.from;
      day.to = hour.to;
    });
  }
}

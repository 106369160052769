import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AssetService } from 'app/shared/asset.service';

@Component({
  selector: 'app-dashboard-tile-data-offline',
  templateUrl: './dashboard-tile-data-offline.component.html',
  styleUrls: ['./dashboard-tile-data-offline.component.css']
})
export class DashboardTileDataOfflineComponent implements OnInit, OnDestroy {

  fetchEvery = 60000 * 60;
  hasValue: boolean;

  _recent: any;
  @Input()
  public get recent(): any {
    return this._recent;
  }
  public set recent(v: any) {
    this.hasValue = !!v;
    this._recent = v;
    setTimeout(() => {
      this.hasValue = false;
    }, 1500);
  }

  timer: any;
  offlineCount: number = null;

  constructor(private assetService: AssetService) { }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  ngOnInit() {
    this.getTotals();
    this.timer = setInterval(() => {
      this.getTotals();
    }, this.fetchEvery);
  }

  getTotals() {
    this.assetService.getOffline().then(assets => {
      this.offlineCount = assets.length;
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Gateway } from 'app/classes/gateway';

@Component({
  selector: 'app-report-gateway-list',
  templateUrl: './report-gateway-list.component.html',
  styleUrls: ['./report-gateway-list.component.css']
})
export class ReportGatewayListComponent implements OnInit {

  @Input()
  gateways: Gateway[];

  @Input()
  show: 'all' | 'favourites' = 'all';

  @Output()
  gatewayToggle: EventEmitter<Gateway> = new EventEmitter();

  favs: any = {};

  selectedGateway: Gateway;

  constructor() {
  }

  ngOnInit(): void {
    this.getFavs();
  }

  getFavs() {
    try {
      const favs = JSON.parse(localStorage.getItem('gateways:favs') || '{}');
      this.favs = favs;
    } catch (e) {
      localStorage.removeItem('gateways:favs');
    }
  }

  clickFav(gateway: Gateway) {
    if (this.favs[gateway.id]) {
      delete this.favs[gateway.id];
    } else {
      this.favs[gateway.id] = 1;
    }
    localStorage.setItem('gateways:favs', JSON.stringify(this.favs));
  }

  selectGateway(gateway: Gateway) {
    this.gateways
      .filter(item => item.id === gateway.id)
      .map(item => item.selected = !item.selected);
    this.selectedGateway = gateway;
    this.gatewayToggle.emit(gateway);
  }

}

<article>
	<header>
		<section>
			<div>
				<div>Email</div>
				<div>{{user.email}}</div>
			</div>
			<div class="sites">
				<div>Sites</div>
				<div>
					<span><span *ngIf="user?.sites?.length === 0" class="mdi mdi-alert red" pTooltip="There must be at least one site"></span> {{user?.sites?.length}}</span>
					<span>/</span>
					<span>{{sites?.length}}</span>
				</div>
			</div>
		</section>

	</header>
	<main>


		<div class="fourdtabs">
			<div (click)="tabClick(0)" [class.active]="tabIndex()===0"><span class="">Sites</span>
			</div>
			@if(isAdmin) {
			<div (click)="tabClick(1)" [class.active]="tabIndex()===1"><span class="" pTooltip="4D Admin feature"><i class="mdi mdi-shield-account-outline"></i> Daily Export</span>
			</div>
			}
			<div></div>
		</div>
		<div class="fourdtabpanels">
			@switch (tabIndex()) {
			@case(1) {
			<app-user-manage-export [exportItem]="exportItem()" (onChanged)="exportChanged($event)"></app-user-manage-export>
			}
			@default {
			<table class="table table-hover">
				<thead>
					<tr>
						<th>Site</th>
						<th>Org</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let site of sites" [ngClass]="{'selected': site.checked}" (click)="siteClick(site)">
						<td><span class="mdi" [ngClass]="{ 
												'mdi-checkbox-blank-outline ': !site.checked,
												'mdi-checkbox-marked-outline': site.checked
											}"></span> {{site.title}}</td>
						<td>{{site.org.shortTitle}}</td>
						<td></td>
					</tr>
				</tbody>
			</table>
			}
			}
		</div>

	</main>
	<footer>
		<p-toolbar>
			<div class="p-toolbar-group-left">
				<span *ngIf="hasChanged">Sites modified.</span>
			</div>
			<div class="p-toolbar-group-right">
				<p-button tooltipPosition="left" label="Reset" icon="mdi mdi-cancel" styleClass="p-button-sm mr-1 p-button-outline p-button-secondary" (click)="reset()" pTooltip="Reset sites to users original list"></p-button>
				<p-button tooltipPosition="top" label="All Sites" icon="mdi mdi-cancel" styleClass="p-button-sm mr-1 p-button-outline p-button-secondary" (click)="allSites()" pTooltip="Select all sites"></p-button>
				<p-button tooltipPosition="left" [pTooltip]="hasChanged ? 'Commit changes' : 'No sites have changed'" [disabled]=" !hasChanged" label=" Save" icon="mdi mdi-content-save-outline" styleClass="p-button-sm p-button-outline p-button-primary" (click)="save()"></p-button>
			</div>
		</p-toolbar>
	</footer>
</article>

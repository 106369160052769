import { Component, OnInit, OnDestroy, signal } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { RulePackage } from '../../classes/rule-service/rule-package';
import { StoreService } from '../../shared/store.service';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rules-engine-v2',
  templateUrl: './rules-engine-v2.component.html',
  styleUrls: ['./rules-engine-v2.component.css']
})
export class RulesEngineV2Component implements OnInit, OnDestroy {
  loading = false;
  rulePackages: RulePackage[];

  rules: { setpoints: RulePackage[] } = { setpoints: null };

  hasDetails: boolean;
  highlightRule: RulePackage;
  previousY: number;
  domElementSelected: any;
  userId: number;
  selectedInAlarm: RulePackage = new RulePackage();
  alarms: RulePackage[] = [];
  countSetpointRules = signal(0);
  countUserRules = signal(0);

  alarmIsPulledUp: boolean;
  activeTabIndex = signal<number>(0);
  dataChangedSubscription: Subscription;

  sort: any = {};
  sortSetpoints: any = { site: null };
  initTimestamp: number = +(new Date());

  constructor(private storeService: StoreService, protected apiService: APIService, private router: Router) {
    this.storeService.sectionBannerBack
      .pipe(filter(sbb => !!sbb && sbb.state === true && sbb.section === 'rules'))
      .subscribe(state => {
        console.log('state changed to', state);

        if (this.domElementSelected) {
          setTimeout(() => {
            window.scrollTo(0, this.previousY);
          });
        }
      });

    this.dataChangedSubscription = this.storeService.dataChanged.pipe(filter(data => !!data && data.timestamp > this.initTimestamp)).subscribe(change => {
      if (change.target === 'rule' || change.target === 'alarm') {
        // If a rule changed -- update list
        this.getRules();
      }
    });

    try {
      const sort = localStorage.getItem('rules:list:sort');
      if (sort) {
        this.sort = { ...this.sort, ...JSON.parse(sort) };
      }
      const sortSetpoints = localStorage.getItem('setpointrules:list:sort');
      if (sortSetpoints) {
        this.sortSetpoints = JSON.parse(sortSetpoints);
      }
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.userId = this.apiService.getUserId();
    this.getRules();
  }

  clickAddRule() {
    this.router.navigate(['/rules', 'new']);
  }

  getRules() {
    this.loading = true;
    this.countSetpointRules.set(0);
    this.countUserRules.set(0);

    this.apiService
      .getRulePackages('alarms-conditions', this.sort, this.sortSetpoints)
      .then(r => {
        this.rulePackages = r.filter(rule => !rule.asset);
        this.rules.setpoints = this.rulePackages.filter(r => r.tag);
        this.sortRules();
        this.loading = false;
        this.alarms = this.rulePackages.filter(rule => rule.inAlert === 'Y');
        if (this.alarms.length) {
          this.selectedInAlarm = this.alarms[0];
        }

        //this.rulePackages.forEach(rule => rule.tag ? this.countSetpointRules.set(this.countSetpointRules()++) : this.counts.userRules++);

        this.countSetpointRules.set(this.rulePackages.filter(r => !!r.tag).length);
        this.countUserRules.set(this.rulePackages.filter(r => !r.tag).length);
        this.loadOptions();

        this.rulePackages = this.rulePackages.sort((a, b) => a.isEnabled === 'Y' ? -1 : 1);
      });
  }

  sortRules() {
    if (!this.rules.setpoints?.length) {
      return;
    }
    this.rules.setpoints = this.rules.setpoints.sort((a, b) => {
      if (this.sortSetpoints.site) {
        const direction = this.sortSetpoints.site === 'asc' ? 1 : -1;
        return (a.siteTitle > b.siteTitle ? direction : direction * -1);
      }
      if (this.sortSetpoints.gateway) {
        const direction = this.sortSetpoints.gateway === 'asc' ? 1 : -1;
        return (a.gatewayTitle > b.gatewayTitle ? direction : direction * -1);
      }
      if (this.sortSetpoints.title) {
        const direction = this.sortSetpoints.title === 'asc' ? 1 : -1;
        return (a.title > b.title ? direction : direction * -1);
      }
      if (this.sortSetpoints.alarmed) {
        const direction = this.sortSetpoints.alarmed === 'asc' ? 1 : -1;
        return (+(a.lastAlertedAt || 0) > +(b.lastAlertedAt || 0) ? direction : direction * -1);
      }
    });
  }

  selectedRulepackage(rulePackage: RulePackage) {
    this.selectedInAlarm = null;
    this.selectedInAlarm = rulePackage;
  }

  selectRule(rulePackage: RulePackage, event: any) {
    this.highlightRule = rulePackage;
    this.domElementSelected = event.target;
    this.previousY = window.scrollY;
  }

  ruleDirty() {
    this.getRules();
  }

  next() {
    let found = false;
    for (let index = 0; index < this.alarms.length; index++) {
      if (!found && this.alarms[index].id === this.selectedInAlarm.id) {
        found = true;
      } else if (found) {
        return this.selectedRulepackage(this.alarms[index]);
      }
    }
    this.selectedRulepackage(this.alarms[0]);
  }

  previous() {
    let found = false;
    for (let index = this.alarms.length - 1; index > 0; index--) {
      if (!found && this.alarms[index].id === this.selectedInAlarm.id) {
        found = true;
      } else if (found) {
        return this.selectedRulepackage(this.alarms[index]);
      }
    }
    this.selectedRulepackage(this.alarms[this.alarms.length - 1]);
  }

  loadOptions() {
    const payload: any = localStorage.getItem(this.apiService.getUserOrg().id + ':' + this.apiService.getUserId() + ':views:rules');
    if (payload) {
      try {
        const options = JSON.parse(payload);
        // Check the tab is available
        if (options.tab === 1 && this.rules.setpoints.length === 0) {
          options.tab = 0;
        }
        this.activeTabIndex.set(options.tab);
      } catch (e) { console.log(e) }
    }
  }

  tabChanged(tabIndex: number) {
    // Save the active tab
    localStorage
      .setItem(this.apiService
        .getUserOrg().id + ':' + this.apiService
          .getUserId() + ':views:rules', JSON.stringify({ tab: tabIndex }));
    this.activeTabIndex.set(tabIndex);
  }

  headerClicked(field: string, sortObject = null) {
    if (!sortObject) {
      sortObject = this.sort;
    }

    let state = sortObject[field];
    // Only one field can be sorted

    for (var member in sortObject) delete sortObject[member];

    switch (state) {
      case 'asc':
        state = 'desc';
        break;
      case 'desc':
        state = null;
        break;
      default:
        state = 'asc';
        break;
    }

    sortObject[field] = state;

    localStorage.setItem('rules:list:sort', JSON.stringify(this.sort));
    localStorage.setItem('setpointrules:list:sort', JSON.stringify(this.sortSetpoints));
    this.getRules();
  }

  ngOnDestroy() {
    this.dataChangedSubscription.unsubscribe();
  }
}

<div class="sites-dashboard" title="{{title}}">
    <div class="row">
        <div class="col-md-12">
            <section class="documents">

                <div class="tabs">
                    <div [class.active]="tab==='reviews'" (click)="tabClick('reviews')"><span class="isDesktop">Building</span> Reviews</div>
                    <div [class.active]="tab==='explorer'" (click)="tabClick('explorer')">Explorer</div>
                    <div *ngIf="hasComplianceAccess" [class.active]="tab==='compliance'" (click)="tabClick('compliance')">
                        Compliance</div>
                </div>

                <div class="tab-content">
                    <ng-template [ngIf]="tab==='reviews'">
                        <app-documents-list (onDidLoad)="didLoad($event)"></app-documents-list>
                        <p *ngIf="docCount===0" class="text-center" class="no-reports">There are no reports at the
                            moment.</p>
                    </ng-template>
                    <ng-template [ngIf]="tab==='explorer'">
                        <app-explorer></app-explorer>
                    </ng-template>
                    <ng-template [ngIf]="tab==='compliance'">
                        <app-compliance-home></app-compliance-home>
                    </ng-template>
                </div>

            </section>
        </div>
    </div>

    <div class="row fullscreen-toggle">
        <div class="col-md-12">
            <i class="fa fa-desktop fa-fw pull-right" (click)="toggleFullscreen()"></i>
        </div>
    </div>
    <div *ngIf="user.role === 'admin'" routerLink='/status'>status</div>
</div>

import { Component, input, output } from '@angular/core';
import { IBuildingReviewListItem } from 'app/shared/buildings.service';

@Component({
  selector: 'app-insights-reviews-list',
  templateUrl: './insights-reviews-list.component.html',
  styleUrl: './insights-reviews-list.component.css'
})
export class InsightsReviewsListComponent {

  reviews = input<IBuildingReviewListItem[]>(null);

  onReviewClick = output<IBuildingReviewListItem>();

  reviewClick(review: IBuildingReviewListItem) {
    this.onReviewClick.emit(review);
  }

}

<main class="_modal0" (click)="m($event);false">

</main>
<main class="_modal">
	<div class="modal-container">

		<app-theme-page-container [label]="title" [canNavigateBack]="true" (onBack)="goBack()" logo="elogbooks">
			<app-theme-page-section *ngIf="sites && !site">

				<table class="table table-hover can-click">
					<thead>
						<tr>
							<th>Site </th>
							<th></th>
							<th colspan="3">Address</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let site of sites" (click)="siteClick(site)">
							<td>{{site.id}}</td>
							<td>{{site.name}}</td>
							<td>{{site.address?.line1}}</td>
							<td>{{site.address?.town}}</td>
							<td>{{site.address?.postcode}}</td>

						</tr>
					</tbody>
				</table>

			</app-theme-page-section>
			<app-theme-page-section *ngIf="priorities">
				<table class="table table-hover can-click">
					<thead>
						<tr>
							<th>Priority </th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let priority of priorities" (click)="priorityClick(priority)">
							<td>{{priority.id}}</td>
							<td>{{priority.name}}</td>
						</tr>
					</tbody>
				</table>
			</app-theme-page-section>
			<app-theme-page-section *ngIf="site">
				<article>
					<p><span>Purchase Order Required</span>{{site.poRequired}}</p>
					<p><span>Planned Job Approval Required</span>{{site.plannedJobApprovalRequired}}</p>
					<p><span>Reactive Job Approval Required</span>{{site.reactiveJobApprovalRequired}}</p>

					<ng-template ngFor [ngForOf]="webhooks" let-w>
						<p>
							<span>Webhook Active</span> {{w.active}}
						</p>
						{{w.url}}
					</ng-template>
					<button class="btn btn-secondary btn-block mt-1" (click)="site=null;webhooks=[]">Cancel</button>
					<button class="btn btn-primary btn-block mt-1" (click)="selectSite()">Select</button>
				</article>
			</app-theme-page-section>
		</app-theme-page-container>
	</div>
	<app-please-wait *ngIf="isLoading"></app-please-wait>
</main>

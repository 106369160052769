<article>

	<section class="days">
		<div>
			<div *ngFor="let hour of openingHours.hours" [class.is-open]="!hour.isClosed"
				(click)="hour.isClosed = !hour.isClosed">
				{{hour.shortDay}}
			</div>
		</div>
	</section>

	<section class="day-hours mt-1">

		<ng-template ngFor [ngForOf]="openingHours.hours" let-hour>
			<div [class.disabled]="hour.isClosed">
				{{hour.day}}
				<div class="times">
					<div>
						<input [disabled]="hour.isClosed" class="form-control" type="time" [(ngModel)]="hour.from">
					</div>
					<div>
						&nbsp;to&nbsp;
					</div>
					<div>
						<input [disabled]="hour.isClosed" class=" form-control" type="time" [(ngModel)]="hour.to">
					</div>
					<div class="ml-1">
						<button pButton pRipple type="button" label="" class="p-button-rounded p-button-sm  p-button-secondary"
							icon="mdi mdi-content-copy" pTooltip="Copy to all" (click)="copyToAll(hour)"></button>
					</div>
				</div>
			</div>
		</ng-template>
	</section>

	<section class="actions mt-1">

		<div class="text-center">
			<p-button label="Cancel" icon="pi pi-times" styleClass="p-button-sm p-button-outlined p-button-secondary mr-1"
				(click)="cancel()">
			</p-button>
			<p-button label="Update" icon="mdi mdi-card-account-mail-outline" styleClass="p-button-sm" (click)="update()">
			</p-button>
		</div>

	</section>
</article>

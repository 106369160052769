<article>
	<section class="org-display" (click)="dropdownOpen = !dropdownOpen">
		<div> {{selectedOrg ? selectedOrg.shortTitle : 'All Organisations'}} </div>
		<div><span class="mdi mdi-chevron-down"></span></div>
	</section>
	<section>
		<div class="dropdown" [class.open]="dropdownOpen">
			<div *ngFor="let org of orgs" (click)="wantsOrg(org)">
				<img src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{org.logo}}">{{org.shortTitle}}
			</div>
		</div>
	</section>
</article>
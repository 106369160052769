import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { RuleAction } from '../../classes/rule-service/rule-action';
import { Site } from '../../classes/site';
import { Gateway } from '../../classes/gateway';
import { Asset } from '../../classes/asset';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-rule-action-new',
  templateUrl: './rule-action-new.component.html',
  styleUrls: ['./rule-action-new.component.css']
})
export class RuleActionNewComponent implements OnInit {

  static readonly PURPOSE_OCCUPANCY_ID = 6;

  @Output()
  submit: EventEmitter<RuleAction> = new EventEmitter<RuleAction>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  sites: Site[];

  @Input()
  defaultSite: Site;

  @Input()
  defaultGateway: Gateway;

  @Input()
  public set modifyAction(value: RuleAction) {
    if (value) {
      this.selectedSite = value.asset.gateway.site;
      this.selectedGateway = value.asset.gateway;
      this.selectedAsset = value.asset;
      this.value = value.value;
      this.id = value.id;
    }
  }

  id: number;
  selectedSite: Site;
  selectedGateway: Gateway;
  selectedAsset: Asset;

  value: string;
  trigger = 'new';

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);

    if (event.key === "Escape" ) {
      // User pressed escape on the modal
      this.cancel.emit();
    }
  }

  constructor(private confirmationService: ConfirmationService, ) { }

  ngOnInit() {
    if (this.defaultSite) {
      this.selectedSite = this.defaultSite;
      if (this.defaultGateway) {
        this.selectedGateway = this.defaultGateway;
      }
    }
  }

  siteSelected(site: Site) {
    this.selectedSite = site;
  }

  gatewaySelected(gateway: Gateway) {
    this.selectedGateway = gateway;
  }

  sensorSelected(asset: Asset) {
    this.selectedAsset = asset;
  }

  cancelSite() {
    this.selectedGateway = null;
    this.selectedAsset = null;
    this.selectedSite = null;
  }

  cancelGateway() {
    this.selectedGateway = null;
    this.selectedAsset = null;
  }

  cancelAsset() {
    this.selectedAsset = null;
  }

  submitCondition() {
    let ruleAction = new RuleAction();
    if (this.id) {
      ruleAction.id = this.id;
    }

    // Package up some useful objects
    this.selectedGateway.site = this.selectedSite;
    this.selectedAsset.gateway = this.selectedGateway;

    ruleAction.asset = this.selectedAsset;
    ruleAction.value = this.value;
    ruleAction.trigger = this.trigger;

    this.submit.emit(ruleAction);
  }

  cancelCondition() {
    this.confirmationService.confirm(
      {
        message: 'Cancel updates to this Action? ',
        accept: () => {
          this.cancel.emit();
        }
      });
  }
}

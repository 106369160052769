import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Asset } from 'app/classes/asset';
import { ReportBlock } from 'app/classes/reports/report-block';
import { Setpoint } from 'app/classes/setpoint';
import { ReportService } from 'app/shared/report.service';
import moment from 'moment';

declare const Chart;

@Component({
  selector: 'app-report-monthly',
  templateUrl: './report-monthly.component.html',
  styleUrls: ['./report-monthly.component.css']
})
export class ReportMonthlyComponent implements OnInit {

  // Raw data received from the API
  @Input()
  set data(data: any[]) {
    this._data = data;
    if (this._config) {
      if (this._data[0]) {
        this._data[0].sort((a, b) => a.title > b.title ? 1 : -1);
      }
      this.prepareData();
    }
  }
  _data: any;

  @Input()
  set config(data: any) {
    const hasData = typeof this._config !== 'undefined';
    if (data) {
      this._config = data;
      this.startsAt = moment(data.startAt);
      this.endsAt = moment(data.endAt);
      if (hasData) {
        // Fresh data received
        this.prepareData();
      }
    }
  }
  _config: any;

  startsAt: any;
  endsAt: any;

  @Input()
  reportBlock: ReportBlock;

  @Input()
  blockReady: boolean;

  // Take the raw "data", generate the chart data into "chartData"
  chartData: any;

  // Take the raw "data", generate the chart data into "tableData"
  tableData: any;
  assetKeys: any[];
  days: any[] = [{ line1: 'Sensor', line2: '' }, 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  daysLine2: any[] = ['', '', '', '', '', '', '', ''];
  isShowingPopupSensorData: boolean;
  selectedAsset: Asset;

  assetCharts: any;

  dows = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  canvas: any;
  ctx: any;
  selectedRag: Setpoint;

  constructor(private reportService: ReportService, private router: Router) { }

  ngOnInit(): void {
  }



  prepareData() {
    const monthStart = new Date(this._config.startAt);
    const monthDays = (new Date(monthStart.getFullYear(), monthStart.getMonth() + 1, 0)).getDate();

    let chartData = {
      labels: [],
      datasets: [],
      percTotalChange: null
    };

    let assets = []
    let assetIndex = [];
    let weekData = this._data[0];
    let ragData = this._data[1];

    for (let day = 1; day <= monthDays; day++) {
      chartData.labels.push(String(day));
      const dt = new Date((new Date(monthStart.getTime())).setDate(day));
      const dow = dt.getDay() === 0 ? 6 : dt.getDay() - 1;
      let isOnASelectedRag = false;
      let pointBorderWidth = 0;
      let pointWidth = 4;

      const assetsForDay = weekData.filter(wday => wday.day === day);
      let xAxisLineColour = '#a0a0a0';
      if (this.selectedRag) {
        // User clicked on a rag row in the table

        if (dow === this.selectedRag.weekday) {
          isOnASelectedRag = true;
          xAxisLineColour = 'blue';
          pointWidth = 8;
        }
      }

      assetsForDay.forEach(asset => {
        const index = assetIndex.findIndex(idx => idx == asset.id);
        const rag = ragData.find(r => (r.weekday === dow && r.asset_id === asset.id));
        let ragColour = '#f0f0f0';
        let ragColours = { min: 'green', max: 'green', avg: 'green' };

        const checkRAG = (value: number) => {
          let rColour = 'green';
          if (value <= rag.amber_min || value >= rag.amber_max) {
            rColour = 'orange';
          }

          if (value <= rag.red_min || value >= rag.red_max) {
            rColour = 'red';
          }
          return rColour;
        };

        if (rag && rag.isActive) {
          ragColours.min = checkRAG(asset.min);
          ragColours.max = checkRAG(asset.max);
          ragColours.avg = checkRAG(asset.avg);
        } else {
          ragColour = '#888';
          ragColours = { min: '#f0f0f0', max: '#f0f0f0', avg: '#f0f0f0' };
        }

        if (index === -1) {
          // If the asset hasn't been used set it up!
          //  printing: { pagebreak: assets.length > 1 ? (assets.length + 1) % 2 : 0},

          const chartData = {
            data: {
              id: asset.id,
              labels: [[String(day), this.dows[dow].substr(0, 1)]],
              datasets: [
                {
                  label: `Minimum`, data: [asset.min], fill: false, borderColor: '#4bc0c0',
                  backgroundColor: [ragColours.min],
                  pointBorderWidth: [pointBorderWidth],
                  pointRadius: [pointWidth]
                },
                {
                  label: `Maximum`, data: [asset.max], fill: false, borderColor: '#8704fb',
                  backgroundColor: [ragColours.max],
                  pointBorderWidth: [pointBorderWidth],
                  pointRadius: [pointWidth]
                },
                {
                  label: `Average`, data: [asset.avg], fill: false, borderColor: '#000000',
                  backgroundColor: [ragColours.avg],
                  pointBorderWidth: [pointBorderWidth],
                  pointRadius: [pointWidth]
                }
              ],
              percTotalChange: null
            },
            printing: { pagebreak: assets.length },
            rags: ragData.filter(r => r.asset_id === asset.id),
            options: {
              title: asset.title,
              animation: {
                duration: 0
              },
              scales: {
                xAxes: [{
                  gridLines: {
                    drawBorder: false,
                    color: [xAxisLineColour]
                  }
                }]
              }
            }
          };
          assets.push(chartData);
          assetIndex.push(asset.id);
        } else {
          const assetDay = assets[index];
          assetDay.data.labels.push([String(day), this.dows[dow].substr(0, 1)]);
          assetDay.data.datasets[0].data.push(asset.min);
          assetDay.data.datasets[1].data.push(asset.max);
          assetDay.data.datasets[2].data.push(asset.avg);

          assetDay.data.datasets[0].backgroundColor.push(ragColours.min);
          assetDay.data.datasets[1].backgroundColor.push(ragColours.max);
          assetDay.data.datasets[2].backgroundColor.push(ragColours.avg);

          assetDay.data.datasets[0].pointRadius.push(pointWidth);
          assetDay.data.datasets[1].pointRadius.push(pointWidth);
          assetDay.data.datasets[2].pointRadius.push(pointWidth);

          assetDay.data.datasets[0].pointBorderWidth.push(pointBorderWidth);
          assetDay.data.datasets[1].pointBorderWidth.push(pointBorderWidth);
          assetDay.data.datasets[2].pointBorderWidth.push(pointBorderWidth);

          assetDay.options.scales.xAxes[0].gridLines.color.push(xAxisLineColour);
        }
      });
    }

    this.assetCharts = assets;

    setTimeout(() => {
      this.blockReady = true;
      //setTimeout(() => {
      //this.buildCharts();
      //}, 500);
    }, 10);

  }

  buildCharts() {
    this.assetCharts.forEach(assetChart => {
      const assetId = assetChart.data.id;
      const canvas: any = document.getElementById('chart_' + assetId);
      const ctx = canvas.getContext('2d');
      const myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: ['USA', 'Spain', 'Italy', 'France', 'Germany', 'UK', 'Turkey', 'Iran', 'China', 'Russia', 'Brazil', 'Belgium', 'Canada', 'Netherlands', 'Switzerland', 'India', 'Portugal', 'Peru', 'Ireland', 'Sweden'],
          datasets: [{
            label: 'Total cases.',
            data: [886789, 213024, 189973, 158183, 153129, 138078, 101790, 87026, 82804, 62773, 50036, 42797, 42110, 35729, 28496, 23502, 22353, 20914, 17607, 16755],
            backgroundColor: ['red', , , , , , , , 'orange'],
            borderWidth: 1
          }]
        },
        options: {
          legend: {
            display: false
          },
          responsive: false,
          display: true
        }
      });

    });
  }

  weekdayClick(rag: Setpoint) {
    this.selectedRag = rag;
    this.prepareData();
  }

  gotoSetpoints(assetId: number) {
  }

}

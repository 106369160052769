<p-card [style]="{width: '600px',margin: '0 auto'}">
	<p-header>
		<h3>Create API Key</h3>
	</p-header>

	<h4>Key Name</h4>
	<input name="title" type="text" pInputText [(ngModel)]="apikey.title">

	<p-footer>
		<button class="btn btn-primary" (click)="createKey()">Create key</button>
	</p-footer>
</p-card>
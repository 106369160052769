import { Component, Input, OnInit } from '@angular/core';
import { LicenseBuilding, LicenseGateway } from 'app/classes/license';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-license',
  templateUrl: './dialog-license.component.html',
  styleUrls: ['./dialog-license.component.css'],
  providers: [DialogService]
})
export class DialogLicenseComponent implements OnInit {

  license: LicenseBuilding | LicenseGateway;

  constructor(public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.license = this.config.data.license;
  }

  goBack() {
    this.ref.close();
  }

  updated() {
    this.ref.close(this.license);
  }
}

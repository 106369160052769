import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { StoreService } from '../../shared/store.service';
import { APIService } from '../../shared/api.service';
import { Subscription } from 'rxjs';
import { UserService } from 'app/shared/user.service';
import { User } from 'app/classes/user';
import moment from 'moment';
import { CalendarService, IDateConfig } from 'app/shared/calendar.service';

@Component({
  selector: 'app-partner-container',
  templateUrl: './partner-container.component.html',
  styleUrls: ['./partner-container.component.css']
})
export class PartnerContainerComponent implements OnInit, OnDestroy {
  readonly LOCALSTORAGE_DATES_KEY = 'engagement:dates';
  readonly LOCALSTORAGE_ADJUST_KEY = 'engagement:adjust';

  adjust = signal<boolean>(true);
  tabIndex = signal<number>(0);
  startsAt = signal<Date>(null);
  endsAt = signal<Date>(null);
  calendarDates = signal<IDateConfig>(null);
  en: any;
  // Don't allow the user to pick a future date
  maximumDate: Date;
  minimumDate: Date;
  isAdmin: boolean;
  selectedView = 'month';

  users: User[];
  isLoading: boolean = true;


  subscription: Subscription;

  constructor(private calendarService: CalendarService, private storeService: StoreService, private apiService: APIService, private userService: UserService) {
    this.maximumDate = new Date();
    this.minimumDate = new Date(2021, 1, 1);
    this.en = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    };

    this.isAdmin = apiService.isAdmin();


    this.startsAt.set(moment().isoWeekday(1).startOf('isoWeek').toDate());
    this.endsAt.set(moment().isoWeekday(1).startOf('isoWeek').add(6, 'day').toDate());

    const storedCalendarString = localStorage.getItem('partner:container:dates');
    if (storedCalendarString) {

    } else {
      const dates = this.calendarService.calculateDatesFromRangeText('thismonth');
      this.calendarDates.set({ dateRange: 'thismonth', dates });
    }

    this.subscription = storeService.dataLoading
      .subscribe(state => {
        this.isLoading = state;
      });

    this.getOrgUsers();

    this.tabIndex.set(+(localStorage.getItem('engagement:defaultTab') || '0'));
  }

  setCalendarDates() {
    // this.calendarDates.set({ dateRange: 'custom', dates: { from: this.startsAt(), to: this.endsAt() } });
  }


  ngOnInit() {
    const adjust = localStorage.getItem(this.LOCALSTORAGE_ADJUST_KEY);
    if (typeof adjust === 'string') {
      this.adjust.set(!!(+adjust));
    }
  }

  calendarDateChange(dateConfig: IDateConfig) {
    console.log(dateConfig);
    if (JSON.stringify(this.calendarDates()) !== JSON.stringify(dateConfig)) {

      this.calendarDates.set(dateConfig);
      this.updateStoreFromDateConfig();
    }
  }


  getOrgUsers() {
    this.userService.getOrgUsersForEngagement().then(users => {
      this.users = users
        .filter(user => user.role === 'user')
        .sort((a, b) => a.updatedAt > b.updatedAt ? 1 : -1)
        .sort((a, b) => a.isActive ? -1 : 1);

      this.updateStoreFromDateConfig();
    });
  }

  startAtChanged() {
    //  this.updateStore();
  }

  updateStoreDeprecated() {
    const view = this.selectedView ? this.selectedView : 'month';

    console.log('engagement for ', view);

    switch (view) {
      case 'year':
        this.startsAt.set(moment(this.startsAt()).startOf('year').toDate()); // Align to start of month
        this.endsAt.set(moment(this.startsAt()).endOf('year').toDate());
        break;
      case 'month':
        this.startsAt.set(moment(this.startsAt()).startOf('month').toDate()); // Align to start of month
        this.endsAt.set(moment(this.startsAt()).endOf('month').toDate());
        break;
      case 'week':
        this.endsAt.set(moment(this.startsAt()).add(6, 'day').toDate());
        break;
    }

    this.storeService.setEngagementStore({ calendarDates: this.calendarDates(), startsAt: this.startsAt(), endsAt: this.endsAt(), view, wantsAdjustment: this.adjust(), users: this.users });

    this.setCalendarDates();
  }

  updateStoreFromDateConfig() {
    const { from, to } = this.calendarService.calculateDatesFromDateConfig(this.calendarDates());
    this.storeService.setEngagementStore({ startsAt: from, endsAt: to, view: this.calendarDates().dateRange, wantsAdjustment: this.adjust(), users: this.users });
  }

  adjustChanged() {
    this.adjust.update(value => !value);
    localStorage.setItem(this.LOCALSTORAGE_ADJUST_KEY, this.adjust() ? '1' : '0');
    this.updateStoreFromDateConfig();
  }

  handleChange(tabIndex: number) {
    this.tabIndex.set(tabIndex);
    localStorage.setItem('engagement:defaultTab', String(tabIndex));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

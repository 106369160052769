export class SiteFloorplanDashboard {

	current: { plan: any, asset: any, site: any, shape: any, occupancy: any }[];

	constructor(data) {
		const ingestCurrentItem = (i) => {
			if (!i) {
				return null;
			}
			switch (i.assetType_id) {
				case 50:
					i.value = i.value === "1" ? 'Occupied' : 'Free';
					break;
			}
			const minutes = i.occFullUtilisationMins;
			let formattedMinutes = '';

			if (minutes !== undefined) {
				if (minutes > 60) {
					let hours = parseInt(String(minutes / 60));
					formattedMinutes = `${hours} hours ${(minutes - (hours * 60))} minutes`;
				} else {
					formattedMinutes = `${minutes} minutes`;
				}
			}

			return {
				plan: { id: i.sfId, title: i.sfTitle, floor: i.sfFloor },
				occupancy: {},
				asset: { id: i.asset_id, title: i.title, value: i.value, updatedAt: new Date(i.updatedAt) },
				site: { id: i.site_id, title: i.siteTitle },
				shape: { id: i.sfsId, title: i.shapeTitleUser || i.sfsTitle, occupation: { fullMinutes: i.occFullUtilisationMins, days: i.occDays, fullMinutesAsString: formattedMinutes } }
			}
		};

		this.current = data.current ? data.current.map(i => ingestCurrentItem(i)) : [];
	}
}

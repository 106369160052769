import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-partner-org-users-breakdown',
  templateUrl: './partner-org-users-breakdown.component.html',
  styleUrls: ['./partner-org-users-breakdown.component.css']
})
export class PartnerOrgUsersBreakdownComponent implements OnInit {

  @Input()
  org: any;

  @Input()
  selectedOrg: any;

  @Input()
  userLog: any[];

  @Input()
  users: SelectItem[] = [];

  @Input()
  usersSelected = null;

  isFiltering: boolean;
  loading: boolean;

  @Output()
  onUserSelectionChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onGoBack: EventEmitter<void> = new EventEmitter<void>();

  constructor() {

  }

  goBack() {
    this.onGoBack.emit();
    console.log('back');
  }

  ngOnInit(): void {
    console.log(this.userLog.length);
  }

  userSelectionChanged(event) {
    this.onUserSelectionChanged.emit(this.usersSelected);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor() { }

  hostReachable() {
    const url = 'www.google.com/?rand=' + Math.floor((1 + Math.random()) * 0x10000)
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', '//' + url, false);
    try {
      xhr.send();
      return (xhr.status >= 200 && (xhr.status < 300 || xhr.status === 304));
    } catch (error) {
      return false;
    }
  }
}

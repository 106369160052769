<article class="">
  <header>
    <app-toolbar>
      <div class="right">
        @if (view() === 'list') {
          <app-button label="Create" (click)="createClick()" icon="mdi mdi-plus" />
        } @else {
          @if (id && !isLocked) {
            <app-button label="Delete" (click)="deleteClick()" icon="mdi mdi-trash-can-outline" [isMuted]="true" class="mr-1" />
          }
          @if (!isLocked) {
            <app-button label="Clear Values" (click)="clearClick()" icon="mdi mdi-eraser" class="mr-1" />
          }
          <app-button label="Cancel" (click)="cancelClick()" icon="mdi mdi-close" />
          @if (!isLocked) {
            <app-button label="Update" (click)="updateClick()" icon="mdi mdi-check" [isCTA]="true" />
          } @else {
            <app-button label="Locked" icon="mdi mdi-lock" [isCTA]="true" />
          }
        }
      </div>
    </app-toolbar>
  </header>
  @switch (view()) {
    @case ('list') {
      <main>
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Title</th>
              <th>Created By</th>
              <th class="text-right">Locked</th>
            </tr>
          </thead>
          <tbody>
            @for (item of list(); track item.id) {
              <tr (click)="updateItem(item)">
                <td>{{ item.title }}</td>
                <td>{{ item.createdByName }}</td>
                <td class="text-right">
                  @if (item.isLocked) {
                    <i class="mdi mdi-lock" pTooltip="This cannot be changed."></i>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </main>
    }
    @case ('item') {
      <main>
        <section class="">
          <app-data-form [dataForm]="form"></app-data-form>
        </section>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th>Type</th>
                <th>&lt; Warning</th>
                <th colspan="1">≥ Action</th>
                <th colspan="1">&lt; Action</th>
                <th colspan="1">≥ Normal</th>
                <th colspan="1">&lt; Normal</th>
                <th colspan="1">≥ Action</th>
                <th colspan="1">&lt; Action</th>
                <th>&gt; Warning</th>
              </tr>
            </thead>
            <tbody>
              @for (type of types; track type.id) {
                <tr>
                  <td>{{ type.id }}</td>
                  <td><input class="fourd" type="number" [(ngModel)]="type.config.alarms.low.warn.from" /></td>
                  <td>
                    <input class="fourd" type="number" [(ngModel)]="type.config.alarms.low.action.from" />
                  </td>
                  <td>
                    <input class="fourd" type="number" [(ngModel)]="type.config.alarms.low.action.to" />
                  </td>
                  <td>
                    <input class="fourd" type="number" [(ngModel)]="type.config.alarms.normal.from" />
                  </td>
                  <td>
                    <input class="fourd" type="number" [(ngModel)]="type.config.alarms.normal.to" />
                  </td>
                  <td>
                    <input class="fourd" type="number" [(ngModel)]="type.config.alarms.high.action.from" />
                  </td>
                  <td>
                    <input class="fourd" type="number" [(ngModel)]="type.config.alarms.high.action.to" />
                  </td>
                  <td><input class="fourd" type="number" [(ngModel)]="type.config.alarms.high.warn.from" /></td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </main>
    }
  }
</article>

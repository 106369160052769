<article>
  <section>
    <div class="options">
      <div (click)="wantsView('month')" [class.selected]="selectedView === 'month'"><span>Month</span></div>
      <div (click)="wantsView('week')" [class.selected]="selectedView === 'week'"><span>Week</span></div>
    </div>

    <div class="calendar">
      <p-calendar
        appendTo="body"
        [inputStyle]="{ 'background-color': 'white', 'text-align': 'center', 'z-index': 1 }"
        #calendar
        (onSelect)="startAtChanged()"
        [minDate]="minimumDate"
        [maxDate]="maximumDate"
        [locale]="en"
        [dateFormat]="selectedView === 'month' ? 'MM yy' : 'dd MM yy'"
        [(ngModel)]="startsAt"
        tabindex="0"
        [view]="selectedView === 'month' ? 'month' : 'date'"
        [disabledDays]="selectedView === 'month' ? [] : [0, 2, 3, 4, 5, 6]"
        readonlyInput="true"></p-calendar>
    </div>
  </section>

  <section class="menu">
    <div routerLink="/engage" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><span class="mdi mdi-home"></span> Home</div>
    <div routerLink="/engage/users" routerLinkActive="active"><span class="mdi mdi-account-group"></span> Users</div>
    <!--<div routerLink="/engage/config" routerLinkActive="active" class="mt-1"><span class="mdi mdi-cog-outline"></span> Config</div>-->
  </section>
</article>

import { Injectable } from '@angular/core';
import { Alarm } from 'app/classes/alarm';
import { RuleAlarm } from 'app/classes/rule-service/rule-alarm';

import { AlarmTimeline } from "../classes/alarm-timeline";
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AlarmService {

  constructor(private apiService: APIService) { }


  getNewState(alarm: RuleAlarm): AlarmTimeline {
    return alarm.timeline.find(i => i.action === 'state_change' && i.value === "new");
  }

  saveNotes(timeline: AlarmTimeline, notes: string): Promise<any> {
    // General alarm notes are saved against the new state change
    return this.apiService.updateAlarmNotes(timeline, notes);
  }

  getHistory(history: AlarmTimeline[]) {
    let findings;
    const newState = history.find(item => item.value === "new");
    const resolvedState = history.find(item => item.value === "resolved");

    if (newState === undefined) {
      // Delay not met
      findings = { at: null, text: 'Resolved within delay', state: 'rdelay' };
    } else if (newState && resolvedState) {
      const diff = (+resolvedState.createdAt) - (+newState.createdAt);
      const diffDays = Math.floor(diff / 86400000); // days
      const diffHrs = Math.floor((diff % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes
      findings = { at: resolvedState.createdAt, text: 'Resolved in' };
      if (diffDays > 0) {
        findings.text += ` ${diffDays} days`;
      }
      if (diffHrs > 0) {
        findings.text += ` ${diffHrs} hours`;
      }
      if (diffMins > 0) {
        findings.text += ` ${diffMins} minutes`;
      }
      if (diff <= 60000 && findings.text.length === 11) {
        findings.text = 'Resolved immediately';
      }
      findings.state = 'resolved';
    } else {
      findings = { state: 'alarm', at: newState.createdAt, text: "In alarm" };
    }
    const compressed = [];
    let lastAction = null;
    let emails = [];
    for (let index = 0; index < history.length; index++) {
      const item = history[index];
      if (item.action === 'email') {
        emails.push(item.value);
      } else {
        if (emails.length) {
          compressed.push({ action: 'email', value: emails.join(', ') });
          emails = [];
        }
        compressed.push(item);
      }
    }
    if (emails.length) {
      compressed.push({ action: 'email', value: emails.join(', ') });
    }
    history = compressed;

    return { findings, history };
  }


}

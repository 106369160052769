<header>
    <app-home-header></app-home-header>
</header>
<article>
    <div class="error-message back-{{messageColour}}" *ngIf="message" [class.new-msg]="flashMessage">{{message}}</div>
    <section>
        <figure class="text-login">
            <div>
                <h1>Smart data</h1>
                <div> for sustainable </div>
                <div>buildings</div>
                <div class="copy">
                    <p>4D Monitoring’s smart building technology</p>
                    <p>provides the intuitive insights needed to make </p>
                    <p>property portfolios energy efficient</p>
                    <p>- and keep them that way.</p>
                </div>
            </div>
            <div>
                <div class="form">
                    <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                        [(ngModel)]=" email" type="email" placeholder="email">
                    <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                        [(ngModel)]=" password" type="password" placeholder="password">
                    <button>Login</button>
                    <div class="opacity"></div>
                </div>
            </div>
        </figure>
    </section>
</article>
<footer>
    <app-home-footer></app-home-footer>
</footer>

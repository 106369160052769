<div>
    <div class="tabview-list">
        <div [class.active]="section==='listing'" routerLink="/setpoints" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" *ngIf="can.listing"><i class="mdi mdi-view-list"></i> Listing
        </div>
        <div [class.active]="section==='schedules'" routerLink="/setpoints/schedules" *ngIf="can.schedule" pTooltip="4D Labs, early access"><i class="mdi mdi-calendar-clock"></i>
            Schedules</div>
        <div [class.active]="section==='templates'" routerLink="/setpoints/templates" *ngIf="can.template" pTooltip="4D Labs, early access"><i class="mdi mdi-text-box-multiple-outline"></i>
            Templates</div>
        <!-- <div [class.active]="section==='overview'" routerLink="/setpoints/overview"><i class="fa fa-barcode"></i> Overview 
    </div> -->
        <div>&nbsp;</div>
    </div>

    <div>
        <router-outlet></router-outlet>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Exporter, ExportCondition, ExportTableGateways, ExportTableSetpoints } from "../../classes/export";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { APIService } from '../../shared/api.service';

@Component({
  selector: 'app-export-landing',
  templateUrl: './export-landing.component.html',
  styleUrls: ['./export-landing.component.css']
})
export class ExportLandingComponent implements OnInit {
  export: Exporter;
  isExporting: boolean;
  exportKey: string;

  constructor(private apiService: APIService, private route: ActivatedRoute, private router: Router) {

    if (apiService.moduleAccess.export) {
      this.route.params.subscribe(params => {
        let table = params['table'];
        switch (table) {
          case 'gateways':
            this.export = new Exporter(new ExportTableGateways());
            break;
          case 'setpoints':
            this.export = new Exporter(new ExportTableSetpoints());
            break;
        }

        this.export.selectAll();
      });
    } else {
      // No access
      router.navigate(['/']);
    }
  }

  ngOnInit() {
  }

  submit() {
    this.isExporting = true;
    this.exportKey = null;
    this.apiService.postExporter(this.export).then(resp => {
      console.log(resp);
      this.isExporting = false;
      this.exportKey = resp.key;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Review, SiteReview } from '../../classes/reviews/review';
import { Asset } from '../../classes/asset';
import { APIService, GetSiteReviewsInterface } from '../../shared/api.service';

@Component({
  selector: 'app-reviews-list',
  templateUrl: './reviews-list.component.html',
  styleUrls: ['./reviews-list.component.css']
})
export class ReviewsListComponent implements OnInit {

  reviews: SiteReview[];

  constructor(private apiService: APIService) {

    this.apiService.getSiteReviews().then(reviews => {
      this.reviews = reviews.map( review => new SiteReview({review: review }) );
    });

  }

  ngOnInit() {
  }

  clickReviewItem(review: SiteReview) {

  }

}

import { Component, Input, OnInit } from '@angular/core';
import { ComplianceCollection } from 'app/classes/compliance/compliance-collection';

@Component({
  selector: 'app-compliance-dial',
  templateUrl: './compliance-dial.component.html',
  styleUrls: ['./compliance-dial.component.css']
})
export class ComplianceDialComponent implements OnInit {

  constructor() { }

  legend: any = {
    compliant: { label: '', colour: 'green' },
    noncompliant: { label: '', colour: 'red' }
  };

  @Input()
  public set compliance(value: ComplianceCollection) {
    if (!value || !value.groups.length) return;

    this.data = value.groups[0].compliance;
    const total = value.groups[0].compliance.compliant + value.groups[0].compliance.noncompliant;
    this.value = String(parseInt(String(value.groups[0].compliance.compliant * (100 / total)))) + '%';
  }

  @Input()
  title: string;

  data: any;

  value: string;
  ngOnInit(): void {

  }

  onSliceClick(event: any) {
  }
}

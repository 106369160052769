import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ComplianceService {

    readonly API_URL = 'https://55auilnnlh.execute-api.eu-west-2.amazonaws.com/4d/compliance';

    constructor(private http: HttpClient, private apiService: APIService) { }

    async updateAssets(complianceItemId: number, assets: number[]): Promise<any> {
        const params = new HttpParams()
            .set('action', 'update-assets')
            .set('oid', String(this.apiService.getUserOrg().id));

        const body = { id: complianceItemId, assets };

        return this.http.post<any>(this.apiService.apiUrl + 'compliance', body, {
            headers: this.apiService.getHttpHeaders(),
            params
        })
            .toPromise()
            .then(r => r);
    };

    async removeAssets(complianceItemId: number, assets: number[]): Promise<any> {
        const params = new HttpParams()
            .set('action', 'remove-assets')
            .set('oid', String(this.apiService.getUserOrg().id));

        const body = { id: complianceItemId, assets };

        return this.http.post<any>(this.apiService.apiUrl + 'compliance', body, {
            headers: this.apiService.getHttpHeaders(),
            params
        }).toPromise().then(r => r);
    };
}

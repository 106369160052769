import { Component, OnInit, Input } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { AssetService } from '../../shared/asset.service';

@Component({
  selector: 'app-field-view',
  templateUrl: './field-view.component.html',
  styleUrls: ['./field-view.component.css']
})
export class FieldViewComponent implements OnInit {

  @Input() value: any;
  @Input() displayValue: any;
  @Input() id: any;
  @Input() title: string;
  @Input() property: string;
  @Input() canEdit: boolean;
  @Input() propertyType: string;
  @Input() propertyList: any[];
  @Input() orgId: number;

  inEditMode: boolean;
  isAdmin: boolean;

  constructor(private apiService: APIService, private assetService: AssetService) { }

  ngOnInit() {
    this.canEdit = this.apiService.hasOrgRole('amend_asset') || this.apiService.isAdmin();
    this.isAdmin = this.apiService.isAdmin();

    switch (this.property) {
      case 'asset.assetType_id':
        // Only allow edits if admin
        this.canEdit = !this.isAdmin;
        break;
    }

    if (this.orgId && this.apiService.getUserOrg().id !== this.orgId) {
      // Stop the user editing another orgs asset
      this.canEdit = false;
    }

    console.log('property', this.property);
    this.parseDisplay();
  }

  editMode() {
    this.inEditMode = this.inEditMode ? false : true;
    console.log(this.inEditMode);
  }

  save() {
    this.apiService.save(this.id, this.property, this.value).then((results) => {
      if (results.errorMessage) {
        console.log('Error', results.errorMessage);
      } else {
        this.inEditMode = false;
        this.parseDisplay();
      }
    });
  }

  parseDisplay() {
    if (this.property === 'asset.assetType_id') {
      this.displayValue = this.assetService.getTypeTitle(this.value);
      this.propertyType = 'list';
      let types = [];
      this.assetService.getAssetTypes().map(atype => types[atype.id] = { id: atype.id, title: atype.id });

      this.propertyList = this.assetService.getAssetTypes();
    }
  }
}

<div class="site-list" *ngIf="!selectedSite">
  <h4>Select a
    <strong>site</strong>
  </h4>
  <app-site-picker (submit)="siteSelected($event)"></app-site-picker>
</div>

<div class="gateway-list" *ngIf="selectedSite && !selectedGateway">
  <h4>Select a
    <strong>gateway</strong> for
    <span (click)="selectedSite=null">{{selectedSite.title}}</span>
  </h4>
  <app-gateway-picker [site]="selectedSite" (submit)="gatewaySelected($event)"></app-gateway-picker>
</div>

<div class="selected-overview" *ngIf="selectedGateway">
  <h4>
    Site
    <span (click)="selectedSite=null;selectedGateway=null">{{selectedSite.title}}</span>, Gateway
    <span (click)="selectedGateway=null">{{selectedGateway.title}}</span>
  </h4>

  <table class="table table-hover asset-pool">
    <thead>
      <tr>
        <th></th>
        <th colspan="2">Asset</th>
        <th>Identifier</th>
        <th>
          <label class="switch" title="Hide uncommon sensors">
            <input type="checkbox" name="typetoggle" [(ngModel)]="filterForCommonSensors">
            <span class="checkboxslider round"></span>
          </label> Common Types
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let asset of assetPool" [ngClass]="{selected: asset.checked}">
        <ng-template
          [ngIf]="!filterForCommonSensors || (filterForCommonSensors && (asset.assetType_id!==12 && asset.assetType_id!==17 && asset.assetType_id!==20 && asset.assetType_id!==21 && asset.assetType_id!==31 && asset.assetType_id!==32 && asset.assetType_id!==1 && asset.assetType_id!==4))">
          <td (click)="clickAssetPool(asset)" class="check">
            <i class="fa fa-fw " [class.fa-check-square-o]="asset.checked" [class.fa-square-o]="!asset.checked"></i>
          </td>
          <td>
            <app-asset-icon [asset]="asset"></app-asset-icon>
          </td>
          <td>{{asset.title}}</td>
          <td>{{asset.identifier}}</td>
          <td>{{asset.value}}</td>
        </ng-template>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="siteReview.assets && siteReview.assets.length > 0">

  <input [(ngModel)]="siteReview.title" class="form-control" name="title">

  <h3 class="section">Review Assets</h3>

  <table class="table table-hover">
    <thead>
      <tr>
        <th></th>
        <th colspan="2">Asset</th>
        <th>Identifier</th>
        <th>Latest</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let reviewAsset of siteReview.assets">
        <td class="check">
          <i class="fa fa-fw " [class.fa-check-square-o]="reviewAsset.checked"
            [class.fa-square-o]="!reviewAsset.checked"></i>
        </td>
        <td>
          <app-asset-icon [asset]="reviewAsset"></app-asset-icon>
        </td>
        <td>{{reviewAsset.title}}</td>
        <td>{{reviewAsset.identifier}}</td>
        <td>{{reviewAsset.gatewayTitle}}</td>
        <td>{{reviewAsset.value}}</td>
      </tr>
    </tbody>
  </table>

  <div class="clearfix" *ngIf="siteReview.assets.length">
    <button (click)="clickCreateReview()" class="pull-right btn btn-primary">
      <i class="fa fa-floppy-o"></i> Create Review</button>
  </div>
</div>

<!--
     <app-sensor-picker [gateway]="selectedGateway" (submit)="sensorSelected($event)"></app-sensor-picker>
-->

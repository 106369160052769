@defer(when assets) {
<div class="col-xs-12">
  <div class="component-list">
    <table class="table table-sm" aria-label="Assets">
      <thead>
        <tr>
          <th width="48px"></th>
          <th>Sensor</th>
          <th>
            <span class="pull-right">Value</span>
          </th>
        </tr>
      </thead>
      <tbody>
        @for(asset of assets;let i = $index;track asset.id) {
        <tr class="heading" *ngIf="i===0 || asset.assetType_id !== assets[i-1].assetType_id" [class.off]="!selected.types[asset.assetType_id]">
          <td>
            <label class="switch" title="Hide sensors of this type">
              <input type="checkbox" name="typetoggle" [(ngModel)]="selected.types[asset.assetType_id]">
              <span class="checkboxslider round"></span>
            </label>
          </td>

          <td colspan="2">
            {{asset.assetTypeTitle}}
          </td>
        </tr>

        @if(selected.types[asset.assetType_id]) {
        <tr class="asset" (click)="selectAsset(asset)">
          <td></td>
          <td>
            <app-asset-icon [asset]="asset"></app-asset-icon> {{asset.title}}
            <span *ngIf="asset.location">, {{asset.location}}</span>
          </td>
          <td>
            <span class="pull-right">{{asset.value}}</span>
          </td>
        </tr>
        }
        }
      </tbody>
    </table>
  </div>
</div>
} @placeholder {
<i class="mdi mdi-loading mdi-spin-x2"></i>
}

<div class="row">
  <div class="no-print">
    <!-- -->
    <app-section-banner [section]="'orders'" [title]="'4DML Ordering'" [subtitle]="subtitle" [detail]="detail">
    </app-section-banner>



    <!-- SELECT SITE -->
    <div class="col-xs-12 delivery-site">
      <h3 *ngIf="!site">Delivery site</h3>
    </div>
    <div class="col-xs-12" *ngIf="!site && !creatingSite">
      <div class="panel panel-default site-choice" (click)="creatingSite=true">
        <div class="panel-body">
          <i class="fa fa-plus"></i>&nbsp; Create a new site
        </div>
      </div>
    </div>

    <div class="col-xs-12" *ngIf="!site && creatingSite">
      <app-address-entry [titleLabel]="'Site Name'" (submit)="newSiteSubmit($event)" (cancel)="creatingSite=false">
      </app-address-entry>
    </div>

    <div class="col-xs-12" *ngIf="!site && !creatingSite">
      <div class="component-list">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>Site</th>
              <th class="hidden-xs">Address</th>
              <th>PostCode</th>
              <th>Org</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let site of sites; let i = index" (click)="selectSite(site)">
              <td>{{site.title}} </td>
              <td class="hidden-xs">{{site.address.address1}}</td>
              <td>{{site.address.addressPostcode}}</td>
              <td>{{site.org.shortTitle}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- /SITE SELECTED -->




    <!-- SURVEY COMPLETED / SKIPPED -->
  </div>
  <div *ngIf="site " class="col-xs-12">
    <div class="row site-header">
      <div class="col-xs-8  address">
        <blockquote>
          <p class="" *ngIf="site.title">{{site.title}}</p>
          <p class="">{{site.address.address1}}</p>
          <p class="" *ngIf="site.address.address2">{{site.address.address2}}</p>
          <p class="" *ngIf="site.address.address3">{{site.address.address3}}</p>
          <p class="">{{site.address.addressTown}}</p>
          <p class="">{{site.address.addressCounty}}</p>
          <p class="">{{site.address.addressPostcode}}</p>
        </blockquote>
      </div>
      <div class="col-xs-4">
        <button class="btn btn-sm btn-default pull-right no-print" (click)="selectSite()">Choose another
          site.</button>
      </div>
    </div>


    <!-- SELECT DATE -->
    <div *ngIf="site" class="no-print text-center col-sm-6 date-entry">
      <h4><i class="fa fa-fw fa-truck"></i> Delivery Date</h4>
      <p-calendar [(ngModel)]="deliverAt" [dateFormat]="getDateFormat().toLowerCase()" [locale]="en" [showIcon]="true" [minDate]="minDeliveryDate" [defaultDate]="minDeliveryDate" (onBlur)="dateBlur()" (onSelect)="dateBlur()" [inline]="true"></p-calendar>
    </div>
    <div *ngIf="site" class="text-center col-sm-6 date-entry">
      <h4><i class="fa fa-fw fa-plug"></i> Install Date</h4>
      <p-calendar [(ngModel)]="installAt" [dateFormat]="getDateFormat().toLowerCase()" [locale]="en" [showIcon]="true" [minDate]="minInstallDate" [defaultDate]="minInstallDate" (onBlur)="dateBlur()" (onSelect)="dateBlur()" [inline]="true"></p-calendar>
    </div>


    <div *ngIf="!surveyCompleted" class="row collections no-print">
      <div class="col-xs-12">
        <h3>Site Survey</h3>
      </div>
      <ng-template ngFor let-item [ngForOf]="collections">
        <div class="col-xs-12 col-md-6">
          <app-order-collection-item [item]="item"></app-order-collection-item>
        </div>
      </ng-template>
    </div>

    <div class="row">
      <div class="col-xs-12 no-print">
        <h3>Parts Confirmation</h3>
      </div>

      <div class="col-xs-12 receipt-col">
        <div class="row receipt banner">
          <div class="">
            <p>Deliver to {{site.address1}} <span *ngIf="deliverAt"> by {{deliverAt.toLocaleDateString()}}</span> <span *ngIf="installAt">for installation on {{installAt.toLocaleDateString()}}</span></p>
            <ng-template ngFor let-item [ngForOf]="collections">
              <div class="col-xs-12 col-md-12">
                <div *ngIf="item.qty" class="collection-item">{{item.title}}</div>
              </div>
              <div class="col-xs-12 col-md-12" *ngFor="let inventory of item.inventorys">
                <div *ngIf="item.qty" class="collection-part">{{inventory.title}} <span class="pull-right">{{inventory.qty * item.qty}}</span></div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-xs-12 text-center no-print order-actions">
        <button class="btn btn-secondary" (click)="save('editing')">Save order, don't submit yet</button>
        <button class="btn btn-success" (click)="save('submit')">Order ready, submit for authorisation</button>
        <button *ngIf="orderId" class="btn btn-danger" (click)="orderCancel('')">Cancel this order</button>
        <br />
      </div>
    </div>
    <!-- ORDER PICKER - DEPRECTAED BY SURVEY -->

    <div *ngIf="false" class="row inventory no-print">
      <ng-template ngFor let-item [ngForOf]="inventorys">
        <div class="col-xs-12 col-sm-6">
          <div class="panel panel-default panel-fourd inventory-item">
            <div class="panel-heading">
              <h3 class="panel-title">{{item.title}}</h3>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-xs-12 image">
                  <img class="img-responsive" src="https://s3.eu-west-2.amazonaws.com/4d-inventory/{{item.shortTitle}}-1-160.jpg">
                  {{item.description}}
                </div>
                <div class="col-xs-12">

                  <form class="form-inline pull-right">
                    <div class="form-group quantity">
                      <label for="orderquantity">Quantity: </label>
                      <div class="input-group">
                        <input name="qty" class="form-control" aria-label="Order Quantity" [(ngModel)]="item.order.qty">
                        <div class="input-group-btn">
                          <button type="button" class="btn btn-default" aria-label="Help" (click)="incdec(1,item)">
                            <i class="fa fa-plus-circle"></i>
                          </button>
                          <button type="button" class="btn btn-default" aria-label="Help" (click)="incdec(-1,item)">
                            <i class="fa fa-minus-circle"></i>
                          </button>

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </ng-template>
    </div>
  </div>

</div>
<!-- @TODO 
<p-confirmDialog [icon]="dialogIcon" header="Confirmation">
  {{dialogText}}
</p-confirmDialog>
-->

<div class="tile" [class.active]="active">
    <div class="title">{{tileName}}</div>
    <div class="data">
        <app-dashboard-tile-data-setpoints [recent]="recent" *ngIf="tileName==='setpoints'">
        </app-dashboard-tile-data-setpoints>
        <app-dashboard-tile-data-telemetry [recent]="recent" *ngIf="tileName==='telemetry'">
        </app-dashboard-tile-data-telemetry>
        <app-dashboard-tile-data-offline [recent]="recent" *ngIf="tileName==='issues'">
        </app-dashboard-tile-data-offline>
    </div>
</div>

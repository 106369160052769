<article>
    <div *ngIf="isLoading">
        <div class="progress-materializecss">
            <div class="indeterminate"></div>
        </div>
    </div>
    <ng-template ngFor [ngForOf]="assets" let-asset>
        <div class="">
            <app-c3-chart [asset]="asset" [days]="7"></app-c3-chart>
        </div>
    </ng-template>
</article>

@defer(when isReady) {
<article>
    <div class="tabview-list no-print">
        @if(can.reviews) {
        <div [class.active]="tabIndex()==='reviews'" (click)="tabChange('reviews')">
            <span><i class="mdi mdi-file-document-multiple-outline heading"></i><span class="isDesktop"> Building
                    Reviews</span></span>
        </div>
        }
        @if(can.explorer) {
        <div [class.active]="tabIndex()==='explorer'" (click)="tabChange('explorer')">
            <span><i class="mdi mdi-format-list-text heading"></i> <span class="isDesktop"> Explorer</span></span>
        </div>
        }
        @if(can.compliance) {
        <div [class.active]="tabIndex()==='compliance'" (click)="tabChange('compliance')">
            <span> <i class="mdi mdi-license heading"></i><span class="isDesktop">Compliance</span></span>
        </div>
        }
        @if(can.visualise) {
        <div [class.active]="tabIndex()==='visualise'" (click)="tabChange('visualise')">
            <span>
                <i class="mdi mdi-view-dashboard-variant-outline heading"></i>
                <span class="isDesktop">Your Dashboard</span>
            </span>
        </div>
        }
        <!-- <div [class.active]="tabIndex()==='changelog'" (click)="tabChange('changelog')">
            <span>
                <i class="mdi mdi-web-plus heading"></i>
                <span class="isDesktop">Change Log</span>
            </span>
        </div>-->
        <div> </div>
    </div>
    <div class="tabview-content">
        @switch(tabIndex()) {
        @case('reviews') {
        <app-documents-list (onDidLoad)="didLoad($event)"></app-documents-list>
        <app-info-panel *ngIf="docCount===0">There are no building reviews.</app-info-panel>
        }
        @case('explorer') {
        <app-explorer></app-explorer>
        }
        @case('compliance') {
        <app-compliance-home></app-compliance-home>
        }
        @case('visualise') {
        <app-yourdashboard></app-yourdashboard>
        }
        @case('changelog') {
        <app-changelog-landing></app-changelog-landing>
        }
        }
    </div>

</article>
} @placeholder {
Please wait...
}

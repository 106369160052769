import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { User } from '../../classes/user';
import { Org } from '../../classes/org';
import { Report } from '../../classes/reports/report';
import { ReportBlock } from '../../classes/reports/report-block';
import { ReportFragment } from '../../classes/reports/report-fragment';

@Component({
  selector: 'app-report-weather-table',
  templateUrl: './report-weather-table.component.html',
  styleUrls: ['./report-weather-table.component.css']
})
export class ReportWeatherTableComponent implements OnInit {

  @Input()
  data: any;
  @Input()
  config: any;

  constructor(public apiService: APIService) { }

  ngOnInit() {
    // 	console.log('weather table has data', this.data);
  }
}

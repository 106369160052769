import { Component, OnDestroy, OnInit } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import moment from 'moment';
import { APIService } from 'app/shared/api.service';
import { Site } from 'app/classes/site';
import { DashboardMonthly } from 'app/site-plan/site-plan-dashboard/spd-monthly/spd-monthly.component';
import { ExportService } from 'app/shared/export.service';
@Component({
  selector: 'app-dialog-occupancy-export',
  templateUrl: './dialog-occupancy-export.component.html',
  styleUrls: ['./dialog-occupancy-export.component.css'],
  providers: [DialogService]
})
export class DialogOccupancyExportComponent implements OnInit, OnDestroy {

  dates: { min: Date | null, max: Date } = { min: new Date(2022, 0, 1), max: new Date() };
  dateFrom: Date;
  dateTo: Date;
  asset: Asset | null;
  isLoading: boolean;
  site: Site;

  constructor(public exportService: ExportService, public apiService: APIService, public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.dateFrom = new Date();
    this.dateTo = new Date();
  }

  ngOnInit(): void {
    this.site = this.config.data.site;


    if (this.asset) {
      if (this.dateFrom < this.asset.createdAt) {
        this.dateFrom = this.asset.createdAt;
      }
      if (this.dateTo > this.asset.updatedAt) {
        this.dateTo = this.asset.updatedAt;
      }
    }

    this.getLocalStorage();
  }

  async get() {
    const df = this.apiService.dbDate(this.dateFrom);
    const dt = this.apiService.dbDate(this.dateTo);
    this.isLoading = true;
    const r = await this.apiService.postQuery({ t: 'sites', id: this.site.id, df, dt, action: 'site-floorplan-dashboard-summary' });

    console.log('MONTHLY-GOT DATA');
    this.isLoading = false;
    const month = new DashboardMonthly(r);
    this.exportService.exportOccupancyMonthly(month);

  }

  cancel() {
    this.ref.close();
  }

  async submit() {
    await this.get();

    let from = moment(this.dateFrom).startOf('day').toDate();
    let to = moment(this.dateTo).endOf('day').toDate();
    if (from.getTime() > to.getTime()) {
      // Ensure from is before to
      [to, from] = [from, to];
    }

    setTimeout(() => {
      this.ref.close({ from, to });
    }, 1000);
  }

  update() {
    this.ref.close(this.dates);
  }

  getLocalStorage() {
    try {
      const storageString = localStorage.getItem('export:site:occupancy:config');
      if (storageString) {
        const { start, end } = JSON.parse(storageString);
        if (start) {
          this.dateFrom = new Date(start);
        }
        if (end) {
          this.dateTo = new Date(end);
        }
      }
    } catch (e) { }
  }

  ngOnDestroy(): void {
    try {
      localStorage.setItem('export:site:occupancy:config', JSON.stringify({ start: +this.dateFrom, end: +this.dateTo }));
    } catch (e) { }
  }

}

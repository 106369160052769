<section class="mb-1">
	<p-toolbar>
		<div class="p-toolbar-group-left">

		</div>

		<div class="p-toolbar-group-right">
			<app-button size="small" icon="mdi mdi-rotate-3d-variant" (click)="billableClick()" [label]="isBillable ? 'Billable buildings' : 'Not Billable buildings'" />

		</div>
	</p-toolbar>
</section>

<section [class.is-hidden]="building">

	<table class="table table-hover">
		<thead>
			<tr>
				<th class="can-sort" pTooltip="Sort by this column" tooltipPosition="left" (click)="headerClicked('title')">
					<span>
						<i class="fa" [ngClass]="{'fa-angle-down':sort.title==='desc', 'fa-angle-up':sort.title==='asc'}"></i>
					</span>
				</th>
				<th class="can-sort" pTooltip="Sort by this column" tooltipPosition="left" (click)="headerClicked('address')">
					<span>
						Address
						<i class="fa" [ngClass]="{'fa-angle-down':sort.address==='desc', 'fa-angle-up':sort.address==='asc'}"></i>
					</span>
				</th>
				<th>
					<span>
						Postcode

					</span>
				</th>
				<th class="can-sort text-right" pTooltip="Number of gateways" tooltipPosition="left" (click)="headerClicked('gateways')">
					<span>
						GW#
						<i class="fa" [ngClass]="{'fa-angle-down':sort.gateways==='desc', 'fa-angle-up':sort.gateways==='asc'}"></i>
					</span>
				</th>

				<th class="can-sort" pTooltip="Sort by this column" tooltipPosition="left" (click)="headerClicked('org')">
					<span>
						Org
						<i class="fa" [ngClass]="{'fa-angle-down':sort.org==='desc', 'fa-angle-up':sort.org==='asc'}"></i>
					</span>
				</th>
				<th class="can-sort" pTooltip="Sort by this column" tooltipPosition="left" (click)="headerClicked('licenseExpiresAt')">
					<span>
						Expires
						<i class="fa" [ngClass]="{'fa-angle-down':sort.licenseExpiresAt==='desc', 'fa-angle-up':sort.licenseExpiresAt==='asc'}"></i>
					</span>
				</th>

			</tr>
		</thead>

		<tbody>
			@for(item of list;track item) {
			<tr [class.has-expired]="item.building.license?.hasExpired" (click)="building=item.building">
				<td pTooltip="#{{item.building.id}}">{{item.building.title}}
					@if(item.building.isDeleted) {
					<app-chip pTooltip="Building has been deleted" label="deleted" size="small" [isDanger]="true" />
					}
				</td>
				<td>{{item.building.site.address.addressTown}}</td>
				<td>{{item.building.site.address.addressPostcode}}</td>
				<td class="text-right">{{item.building.counts.gateways}}</td>
				<td>{{item.building.org.shortTitle}}

					@if(!item.building.org.isBillable){
					<app-chip pTooltip="Org not billable" label="not billable" size="small" [isDanger]="true" />
					}

				</td>
				<td><span *ngIf="item.building.license.id">{{item.building.license.expiresAt | amDateFormat:'DD MMM YYYY'}}</span></td>
			</tr>
			}
		</tbody>

	</table>

	<app-theme-page-section>
		<ul *ngIf="list">
			<li>{{list.length}} buildings. </li>
		</ul>
	</app-theme-page-section>
</section>

<section *ngIf="building">

	<app-theme-page-container [label]="building.title" [canNavigateBack]="true" (onBack)="building=null">
		<app-billing-buildings-home [building]="building"></app-billing-buildings-home>
		<!--		<app-building-license-home [building]="building"></app-building-license-home> -->
	</app-theme-page-container>

</section>

<div class="table-responsive">
  <table class="table table-sm table-striped">

    <thead class="hidden-xs hidden-sm">
      <ng-template ngFor let-heading [ngForOf]="days">
        <th>{{heading}}</th>
      </ng-template>
    </thead>
    <tbody>
      <tr *ngFor="let asset of tableData; let i=index">
        <td>{{asset.title}}</td>

        <ng-template ngFor let-value [ngForOf]="asset.values">
          <td>{{value || '--.-'}}</td>
        </ng-template>

      </tr>
    </tbody>

  </table>
</div>
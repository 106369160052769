<table class="table">
	<thead>
		<tr>
			<!--<th class="hidden-xs">Action</th>-->
			<th>Building</th>
			<th>Gateway</th>
			<th>Asset</th>
			<th>Received</th>
		</tr>
	</thead>
	<tbody>
		<ng-template [ngIf]="_mode==='charts'">
			<app-dashboard-charts [rows]="rows"></app-dashboard-charts>
		</ng-template>
		<ng-template [ngIf]="_mode==='realtime'">
			<ng-template ngFor let-row [ngForOf]="rows">

				<tr class="overview telemetry rag-from-{{row.rag.previous}} rag-{{row.rag.current}}">
					<!--<td class="hidden-xs">
						<span *ngIf="row.action  === 'telemetry'">
							telemetry
						</span>
						<span *ngIf="row.action  === 'rag_change'">
							setpoint
						</span>
					</td> -->
					<td
						[title]="row.action === 'rag_change' ? 'Setpoint change' :  row.action === 'state' ? 'State': 'Telemetry'">
						{{ row.building.title }} </td>
					<td> {{ row.gateway.title }} </td>
					<td>
						<span *ngIf="row.action === 'rag_change'">
							{{ row.asset.title }} ({{row.asset.value}})
						</span>
						<span *ngIf="row.action === 'state'" class="red">
							GATEWAY OFFLINE
						</span>
					</td>
					<td title="{{ row.receivedAt | amDateFormat:'HH:MM:ss' }}">
						{{ row.receivedAt | amDateFormat:'HH:mm' }} </td>
				</tr>
				<tr class="assets  rag-{{row.rag.current}}" *ngIf="row.action==='telemetry'">

					<td colspan="5">
						<div *ngFor="let asset of row.assets">
							<div title="{{asset.t}}">{{asset.t}}</div>
							<div title="{{asset.v}}">{{asset.value}}</div>
						</div>
					</td>
				</tr>
			</ng-template>
		</ng-template>
		<!-- BUILDINGS -->
		<ng-template [ngIf]="_mode==='buildings'">
			<ng-template ngFor let-building [ngForOf]="buildingService.buildings">
				<tr>
					<td colspan=5>{{building.title}}</td>
				</tr>
				<ng-template ngFor let-row [ngForOf]="rows">
					<ng-template [ngIf]="building.id == row.building.id">
						<tr class="overview building rag-{{row.rag.current}}">
							<!--<td>
								<span *ngIf="row.action  === 'telemetry'">
									telemetry
								</span>
								<span *ngIf="row.action  === 'rag_change'">
									setpoint
								</span>
							</td> -->

							<td colspan="2"> {{ row.gateway.title }} </td>
							<td>
								<span *ngIf="row.action === 'rag_change'">
									{{ row.asset.title }} ({{row.asset.value}})
								</span>
							</td>
							<td> {{ row.receivedAt | amDateFormat:'HH:mm' }} </td>
						</tr>
						<tr class="assets rag-{{row.rag.current}}" *ngIf="row.action==='telemetry'">
							<!--<td></td>-->
							<td colspan="4">
								<div *ngFor="let asset of row.assets">
									<div title="asset.t">{{asset.t}}</div>
									<div title="asset.v">{{asset.value}}</div>
								</div>
							</td>
						</tr>
					</ng-template>
				</ng-template>
			</ng-template>
		</ng-template>
	</tbody>
</table>

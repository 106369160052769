import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Org } from 'app/classes/org';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ManageService {
  /** 4d_org_admin api */
  static readonly BASE = 'https://mfrhfdayhg.execute-api.eu-west-2.amazonaws.com/prod/';

  private _fieldFocus: BehaviorSubject<string> = new BehaviorSubject(null);
  public fieldFocus: Observable<string> = this._fieldFocus.asObservable();

  constructor(private http: HttpClient, private apiService: APIService) { }

  setFieldFocus(element: string) {
    this._fieldFocus.next(element);
  }

  save(object: any) {
    const url = (ManageService.BASE + object.constructor.name).toLowerCase() + 's';
    const payload = object.serialise();
    const options = this.apiService.getUAOHeaders()
    return this.http.post(url, payload, <Object>options);
  }

  getOrg(): Observable<Org[]> {
    const url = (ManageService.BASE + 'orgs');
    const options = this.apiService.getUAOHeaders();

    return this.http.get<Org[]>(url, <Object>options);
  }

}

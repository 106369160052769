import { Component, OnInit } from '@angular/core';
import { StoreService } from "../../shared/store.service";
import { ConfirmationService } from "primeng/api";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Site } from '../../classes/site';
import { Address } from '../../classes/address';
import { Inventory } from "../../classes/inventory";
import { APIService } from '../../shared/api.service';
import { filter } from 'rxjs/operators';
import { SiteService } from 'app/shared/site.service';
import moment from 'moment';

@Component({
  selector: 'app-order-create',
  templateUrl: './order-create.component.html',
  styleUrls: ['./order-create.component.css']
})
export class OrderCreateComponent implements OnInit {

  public static MINIMUM_DELIVERY_LEAD_DAYS = '7';

  orderId: number;
  site: Site;
  newSiteAddress: Address;
  sites: Site[];
  inventorys: Inventory[];
  collections: any[];
  surveyCompleted: boolean;
  subtitle: string;
  detail: string;
  deliverAt: Date;
  installAt: Date;
  creatingSite: boolean;

  // Calendar
  minDeliveryDate: Date;
  minInstallDate: Date;
  en: any;

  dialogIcon: string;
  dialogShow: boolean;
  dialogText: string;
  dialogHeader: string;

  constructor(private siteService: SiteService, private confirmationService: ConfirmationService, private apiService: APIService, private router: Router, private route: ActivatedRoute, private storeService: StoreService) {
    route.params.subscribe((params: Params) => {
      let orderId = params['orderid'];
      if (orderId && orderId !== 'new') {
        // Load the order
        this.orderId = orderId;
        this.detail = `Amend order #${orderId}`;
      } else {
        this.detail = "Place a new order";
      }
    });
    this.newSiteAddress = new Address();
  }

  ngOnInit() {
    this.subtitle = "";

    this.siteService.getSites().then(sites => {
      if (sites) {
        this.sites = sites;
      }
    });

    // Retrieve the inventory list
    this.apiService.getInventory().then(inventorys => {
      if (inventorys) {
        // Retrieve collections of bundled inventory items
        this.apiService.getInventoryCollections().then(collections => {
          this.inventorys = inventorys;

          this.collections = collections || [];
          if (this.orderId) {
            this.apiService.getOrder(this.orderId).then(results => {
              // Order retrieved.
              let order = results.order;
              this.deliverAt = order.deliverAt;
              this.installAt = order.installAt;
              this.site = order.site;
              // Set collections qty to the orders
              order.surveyItems.forEach(orderSurveyItem => {
                let filter = this.collections.filter(c => c.id === orderSurveyItem.survey_id);
                if (filter) {
                  // The survey item exists, update it's qty
                  filter[0].qty = orderSurveyItem.qty;
                }
              });
            });
          }

          console.log(this.site);
        });
      }
    });

    this.storeService.sectionBannerBack
      .pipe(filter(sbb => !!sbb && sbb.state === true && sbb.section === 'orders'))
      .subscribe(state => {
        console.log('back to orders landing');
        // Set to handled
        state.state = false;
        this.storeService.setSectionBannerBack(state);
        // Back to training
        this.router.navigate(['/orders']);
      });

    this.en = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    };

    // Minimum delivery date is 1 week from now.
    this.minDeliveryDate = this.minInstallDate = moment().add(OrderCreateComponent.MINIMUM_DELIVERY_LEAD_DAYS, 'days').toDate();

  }

  selectSite(site: Site = null) {
    this.site = site;
    this.newSiteAddress = new Address();
  }

  incdec(value, item) {
    item.order.qty = (+item.order.qty) + value;
    if (item.order.qty < 0) {
      item.order.qty = 0;
    }
  }

  getDateFormat() {
    return this.apiService.getDateFormat();
  }

  dateBlur() {
    if (this.deliverAt) {
      this.minInstallDate = this.deliverAt;
    }
  }

  save(state: string) {
    let items: any[] = [];

    this.collections.forEach(c => {
      if (c.qty > 0) {
        items.push({ id: c.id, qty: c.qty });
      }
    });
    this.apiService.updateOrder(this.orderId, this.site, this.deliverAt, this.installAt, state, items).then(r => {
      let msg;
      if (state === 'editing') {
        msg = 'Order has been saved for later.';
      } else {
        msg = 'Completed order has been submitted';
      }
      this.apiService.toastSuccess(msg, '');
      this.router.navigate(['/orders']);

    });
  }

  orderCancel(deleteSite) {
    this.dialogIcon = 'fa fa-trash';
    this.dialogText = 'Any site or address created as part of this order will be deleted.';

    let msg = 'Cancel this order permanently?';

    if (deleteSite) {
      msg += ' Any site or address created as part of this order will be deleted.';
    }

    this.confirmationService.confirm(
      {
        message: 'Cancel this order permanently? ',
        accept: () => {

          this.apiService.deleteOrder(this.orderId, deleteSite).then(r => {
            this.apiService.toastSuccess('deleted', 'Order deleted');
            setTimeout(() => {
              this.router.navigate(['/orders']);
            });
          })
        }
      });
  }

  newSiteSubmit(address: Address) {
    // Must have a title
    if (address.addressTitle === '') {

      return;
    }
    this.site = new Site(address);
    // The address title becomes the site title
    this.site.title = this.site.address.addressTitle;
  }
}

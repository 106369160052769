import { Component, signal } from '@angular/core';
import { Site } from 'app/classes/site';
import { IDatesFromTo } from 'app/interfaces/dates-from-to';
import { APIService } from 'app/shared/api.service';
import { FloorplanService } from 'app/shared/floorplan.service';
import { SiteService } from 'app/shared/site.service';
import { IVisualisationConfig } from 'app/shared/visualise.service';
import moment from 'moment';
import { SitePlan } from '../../classes/site-plan';
import { IYourdashboardConfig } from './button-yourdashboard-config/button-yourdashboard-config.component';
@Component({
  selector: 'app-yourdashboard',
  templateUrl: './yourdashboard.component.html',
  styleUrls: ['./yourdashboard.component.css']
})
export class YourdashboardComponent {

  title: string = 'Your Dashboard';
  dates: IDatesFromTo;
  tiles: IVisualisationConfig[];
  _sites = signal<Site[]>([]);
  siteId: number;
  plans: SitePlan[];

  constructor(private siteService: SiteService, fs: FloorplanService, apiService: APIService) {
    //siteService.getSites().then(sites => this._sites.set(sites));
    apiService.getAllSiteFloorplans().then(sf => {
      this.plans = sf.plans;
      const sites = {};
      this.plans.forEach(plan => sites[plan.site.id] = plan.site);
      this._sites.set(Object.keys(sites).map(id => sites[id]));

    });
    const from = moment().subtract(1, 'month').startOf('month').toDate();
    const to = moment().subtract(1, 'month').endOf('month').toDate();
    this.dates = {
      from,
      to
    }

    this.setTiles();
  }

  configUpdated(config: IYourdashboardConfig) {
    if (!config) {
      return;
    }
    this.dates = config.dates;
    this.siteId = config.siteId;
    this.refresh();
  }

  refresh() {
    this.tiles = null;

    setTimeout(() => {
      this.setTiles();
    }, 1);

  }

  setTiles() {
    const from = this.dates.from;
    const to = this.dates.to;
    this.tiles = [
      { id: 1, siteId: this.siteId, dates: { from, to }, size: { height: '600px' } },
      { id: 2, siteId: this.siteId, dates: { from, to }, size: { height: '900px' } },
      { id: 3, siteId: this.siteId, dates: { from, to } }
    ];
  }

  siteSelected(ev: any) {
    console.log(ev);
    this.siteId = ev;
  }

}

import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { APIService } from '../shared/api.service';
import { User } from '../classes/user';
import { Session } from '../classes/session';
import { SelectItem } from 'primeng/api';
import { Org } from 'app/classes/org';
import { Subscription } from 'rxjs';
import { SiteService } from 'app/shared/site.service';
import { Router } from '@angular/router';
import { SessionService } from 'app/shared/session.service';
import { StoreService, ThemeType } from 'app/shared/store.service';
import { DataForm } from 'app/layout/data-form/data-form.component';
import { UserService } from 'app/shared/user.service';
import { WindowService } from 'app/shared/window.service';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {

	public static readonly PASSWORD_CHANGE_SUCCESS = 'Your password was changed';
	public static readonly PASSWORD_CHANGE_FAIL = 'Your current password is incorrect';
	public static readonly INVITE_SUCCESS = 'User invite sent';

	isChangingPassword: boolean;
	currentPassword: string;
	newPassword: string;
	user: User;
	session: Session;
	users: User[];
	invite: any = { email: '', name: '', notes: '', expiresAt: null };
	inviteUserVisible = false;

	siteList: SelectItem[];
	selectedSites: number[];

	moduleList: SelectItem[];
	selectedModules: number[];
	expiresAt: Date;
	today: Date = new Date();
	tomorrow: Date = new Date();
	org: Org;

	userSubscription: Subscription;
	sessionSubscription: Subscription;
	currentTheme = signal<ThemeType>(null);

	passwordDataFrom: DataForm;

	feedbackText = '';
	isMobile = signal<boolean>(false);

	constructor(private windowService: WindowService, private siteService: SiteService, private apiService: APIService, private router: Router, private sessionService: SessionService, private storeService: StoreService, private userService: UserService) {

		this.isMobile.set(windowService.isMobile());
		this.moduleList = Object
			.keys(apiService.MODULES)
			.map(v => { return { label: v, value: apiService.MODULES[v] } });
		// Only modules that are not status (sudo admin)
		if (this.apiService.getUserOrg().type !== 'integration') {
			this.selectedModules = Object.keys(apiService.MODULES)
				.filter(v => v !== 'footfall' && v !== 'timelapse' && v !== "planner" && v !== 'orders' && v !== 'status' && v !== 'export' && v !== 'engagement' && v !== 'reviews' && v !== 'camera' && v !== 'integration' && v !== 'api' && v !== 'partnership_engagement')
				.map(v => { return apiService.MODULES[v] });
		} else {
			// Only allow intregration module
			this.selectedModules = [apiService.MODULES['rules'], apiService.MODULES['integration']];
		}
		this.tomorrow.setDate(this.today.getDate() + 1);
		this.currentTheme.set(storeService.getTheme(false));
	}

	ngOnInit() {
		this.userSubscription = this.apiService.user.subscribe(user => {
			this.user = user;
			this.org = user.org;
		});

		this.sessionSubscription = this.sessionService.session.subscribe(session => {
			this.session = session;
		});

		this.getOrgUsers();
	}

	logout() {
		this.apiService.clearSession();
	}

	setTheme(theme: ThemeType) {
		this.storeService.setTheme(theme);
		this.currentTheme.set(this.storeService.getTheme(false));
	}

	ngOnDestroy(): void {
		console.log('DESTROY COMPONENT');
		if (this.userSubscription) {
			console.log('USER UNSUBSCRIBE');
			this.userSubscription.unsubscribe();
		}
		if (this.sessionSubscription) {
			console.log('SESSION UNSUBSCRIBE');
			this.sessionSubscription.unsubscribe();
		}
	}

	getOrgUsers() {
		this.apiService.getUsersForOrg().then(
			users => this.users = users
		);
	}

	wantsToChangePassword() {
		this.isChangingPassword = true;
		const payload = {
			items: [
				{ id: 'current', label: 'Current Password', value: '', type: 'password' },
				{ id: 'new', label: 'New Password', value: '', type: 'password' }
			]
		};

		this.passwordDataFrom = new DataForm(payload);
		this.passwordDataFrom.config = { labels: { width: 150 } };
	}
	/**
	 * Change the users password
	 */
	changePassword() {
		this.currentPassword = <string>this.passwordDataFrom.getValue('current');
		this.newPassword = <string>this.passwordDataFrom.getValue('new');

		this.currentPassword = this.currentPassword.trim();
		this.newPassword = this.newPassword.trim();

		if (this.currentPassword?.length < 5 || this.newPassword?.length < 5) {
			this.apiService.toastWarn('Warning', 'Password too short');
			return;
		}
		this.isChangingPassword = false;
		this.apiService.changePassword(this.currentPassword, this.newPassword).then(didChange => {
			if (didChange) {
				// The password has changed
				this.apiService.toastSuccess('Success', ProfileComponent.PASSWORD_CHANGE_SUCCESS);
				this.currentPassword = '';
				this.newPassword = '';
			} else {
				// There was a problem changing the password, the old password is incorrect
				this.apiService.toastWarn('Warning', ProfileComponent.PASSWORD_CHANGE_FAIL);
			}
		});
	}

	clickFeedback() {
		if (this.feedbackText?.trim().length < 3) {
			this.apiService.toastWarn('Please enter some feedback', '');

			return;
		}

		this.userService.sendFeedback(this.feedbackText).then(_ => {
			this.apiService.toastSuccess('Feedback has been sent, thankyou.', '');
			this.feedbackText = '';
		});
	}

	link(url: string) {
		window.open(url, '_blank');
	}
}

<article>

	<div class="tabview-list">
		<div [class.active]="tabIndex()===0" (click)="handleChange(0)">
			<i class="mdi mdi-chart-pie"></i> Calendar
		</div>
		<div [class.active]="tabIndex()===1" (click)="handleChange(1)">
			<i class="mdi mdi-calendar-account"></i> Users
		</div>
		<div></div>
	</div>

	<div class="tabview-content">
		@switch(tabIndex()) {
		@case(0) {

		<app-toolbar>
			<div class="right">
				<app-button-dates [value]="calendarDates()" (onSelect)="calendarDateChange($event)" />

				<div tooltipPosition="bottom" class="isDesktop" pTooltip="Adjust total sites per organisation to a quarter to promote smaller organisations">
					<app-button icon="mdi mdi-rotate-3d-variant" [label]="adjust() ? 'Adjustment applied' : 'No adjustment'" (click)="adjustChanged()"></app-button>

				</div>

			</div>
		</app-toolbar>

		<router-outlet></router-outlet>
		}
		@case(1) {
		<app-partner-dashboard [users]="users"></app-partner-dashboard>
		}

		}
	</div>


</article>
@if(isLoading) {
<app-please-wait></app-please-wait>
}

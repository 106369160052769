@defer(when insight?.configuration?.start) {
<article>
    <section class="header mb-2">

        <header>
            <div class="mb-1">
                <h2>{{headerTitle}}</h2>
                <small>{{insight.configuration.start | amDateFormat:'DD MMMM YYYY'}} to {{insight.configuration.end
                    | amDateFormat:'DD MMMM YYYY'}}</small>
            </div>
            <div>
                <img *ngIf="logo" [src]="logo | safeUrl">
            </div>
        </header>
        <div>
            <table class="table">
                <thead>
                    <tr>
                        <th>Address</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="address">
                            <p>{{building.site.address.address1}}</p>
                            <p>{{building.site.address.address2}}</p>
                            <p>{{building.site.address.addressTown}}</p>
                            <p>{{building.site.address.addressCounty}}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="opening-hours">
            <p class="sub-title">Opening hours</p>
            <table class="table">
                <thead>
                    <tr>
                        <th>Monday</th>
                        <th>Tuesday</th>
                        <th>Wednesday</th>
                        <th>Thursday</th>
                        <th>Friday</th>
                        <th>Saturday</th>
                        <th>Sunday</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td *ngFor="let day of building.openingHours.hours">
                            <span *ngIf="!day.isClosed">{{day.from}} - {{day.to}}</span>
                            <span *ngIf="day.isClosed ">Closed</span>
                        </td>
                    </tr>
                </tbody>

            </table>

        </div>

        <div *ngIf="isReady && hasData" class="mt-2">
            <p class="sub-title">Average measurements within opening hours</p>

            <div class="icons mt-1">
                <div class="icon {{insight.types.co2.colour}}">
                    <div>
                        <span tooltipposition="bottom" ptooltip="CO2" class="">
                            <img src="{{svgLocation}}icon-co2.svg">
                        </span>
                    </div>
                    <div>
                        <p>{{insight.types.co2.avg}}<small>ppm</small></p>
                        <p>CO2</p>
                    </div>
                </div>

                <div class="icon {{insight.types.humidity.colour}}">
                    <div>
                        <span tooltipposition="bottom" ptooltip="Humidity" class="">
                            <img src="{{svgLocation}}icon-humidity.svg">
                        </span>
                    </div>
                    <div>
                        <p>{{insight.types.humidity.avg}}<small>%</small></p>
                        <p>Humidity</p>
                    </div>
                </div>

                <div class="icon {{insight.types.temperature.colour}}">
                    <div>
                        <span tooltipposition="bottom" class="">
                            <img src="{{svgLocation}}icon-temperature.svg">
                        </span>
                    </div>
                    <div>
                        <p>{{insight.types.temperature.avg}}<small>c</small></p>
                        <p>Temperature</p>
                    </div>
                </div>

                <div class="icon {{insight.types.voc.colour}}">
                    <div>
                        <span tooltipposition="bottom" ptooltip="VOC">
                            <img src="{{svgLocation}}icon-voc.svg">
                        </span>
                    </div>
                    <div>
                        <p>{{insight.types.voc.avg}}<small>ppb</small></p>
                        <p>VOC</p>
                    </div>
                </div>
            </div>

            <div class="icons mt-1" *ngIf="insight.types.pm1?.count > 0">
                <div class="icon {{insight.types.pm1.colour}}">
                    <div>
                        <span tooltipposition="bottom" ptooltip="PM1">
                            <img src="{{svgLocation}}icon-pm10.svg">
                        </span>
                    </div>
                    <div>
                        <p>{{insight.types.pm1.avg}}<small>ppb</small></p>
                        <p>PM1</p>
                    </div>
                </div>

                <ng-template [ngIf]="insight.types['pm2.5']?.count > 0">
                    <div class="icon {{insight.types['pm2.5'].colour}}">
                        <div>
                            <span tooltipposition="bottom" ptooltip="PM2.5">
                                <img src="{{svgLocation}}icon-pm10.svg">
                            </span>
                        </div>
                        <div>
                            <p>{{insight.types['pm2.5'].avg}}<small>ppb</small></p>
                            <p>PM2.5</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="insight.types.pm10?.count > 0">
                    <div class="icon {{insight.types.pm10.colour}}">
                        <div>
                            <span tooltipposition="bottom" ptooltip="PM10">
                                <img src="{{svgLocation}}icon-pm10.svg">
                            </span>
                        </div>
                        <div>
                            <p>{{insight.types.pm10.avg}}<small>ppb</small></p>
                            <p>PM10</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="!insight.types.pm10">
                    <div class="icon"></div>
                </ng-template>

                <!-- Add an additional div to make 4 wide to line up-->

                <div class="icon"></div>


            </div>

            <div class="notes mt-1">
                This report presents the quality of the air in an office building environment.
                The recommended thresholds are based on guidance from WHO and national agencies. Measurements were made
                using indoor air quality monitors.
            </div>
        </div>

        <div *ngIf="isReady && !hasData">
            <h3>No data</h3>
        </div>

    </section>

    <section id="report" class="mt-1" *ngIf="isReady && hasData && insight.telemetryCollection">
        <div *ngFor="let t of insight.typeList">
            <ng-template [ngIf]="insight.types[t].count">
                <app-insights-report-type id="report-type-{{t}}" [insight]="insight" [type]="t" [wantsPageBreak]="true">
                </app-insights-report-type>
            </ng-template>
        </div>
    </section>

</article>
}
<!--<app-please-wait *ngIf="!isReady"></app-please-wait>-->

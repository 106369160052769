import { Component, EventEmitter, input, Output, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-asset-offline-landing',
  templateUrl: './asset-offline-landing.component.html',
  styleUrl: './asset-offline-landing.component.css'
})
export class AssetOfflineLandingComponent {

  asset = input.required<Asset>();
  can = signal<{ issues: boolean }>({ issues: false });

  @Output()
  onClose = new EventEmitter<boolean>();

  tabIndex = signal<number>(1);

  constructor(private apiService: APIService) {
    this.can.set({ issues: apiService.hasModule('experimental') });
  }

  goBack() {
    this.onClose.emit(true);
  }
}

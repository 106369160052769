<article *ngIf="api">

	<p-tabView>
		<p-tabPanel header="Interactive">
			<section>
				<h3>{{api.title}}</h3>
				<p>Key: {{api.apikey}}</p>
			</section>

			<section>
				<h3>Date Range</h3>
				<p>Date ranged API call</p>
				<app-dev-api-craft [api]="api" [filters]="filters"></app-dev-api-craft>
			</section>

			<section>
				<h3>Explore</h3>
				<p>Drill into an asset to reveal the API methods available.</p>
				<app-explorer [api]="api" (addToFilter)="addSettingToFilter($event)"></app-explorer>
			</section>

		</p-tabPanel>

		<p-tabPanel header="Documentation">
			<ng-template pTemplate="content">
				<app-dev-documentation [api]="api"></app-dev-documentation>
			</ng-template>
		</p-tabPanel>
	</p-tabView>

</article>

<footer>
</footer>

import { Component } from '@angular/core';

@Component({
  selector: 'app-occupancy-list',
  templateUrl: './occupancy-list.component.html',
  styleUrls: ['./occupancy-list.component.css']
})
export class OccupancyListComponent {

}

import { Injectable, ElementRef } from '@angular/core';
import { APIService } from './api.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { TicketKind } from "../classes/factory";

@Injectable({ providedIn: 'root' })
export class TicketService {

  private _ticketFilter: BehaviorSubject<TicketKind> = new BehaviorSubject(null);
  public ticketFilter: Observable<TicketKind> = this._ticketFilter.asObservable();

  constructor() { }

  setTicketFilter(ticketKind: TicketKind) {
    this._ticketFilter.next(ticketKind);
  }
}

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { APIService } from './api.service';
import { ReportConfig } from '../classes/reports/report-config';
import { ReportBlock } from 'app/classes/reports/report-block';

@Injectable({ providedIn: 'root' })
export class ReportService {
  private _block: BehaviorSubject<any> = new BehaviorSubject(null);
  public block: Observable<any> = this._block.asObservable();

  constructor(private apiService: APIService) { }

  getBlockData(reportId: number, block: ReportBlock, config: ReportConfig) {
    this.apiService.getReportBlockData(reportId, block.id, config)
      .then(results => {
        let master;
        if (results[0].length) {
          // MultiStatement response, inject fragment data on first array
          master = results[0][0];
          results[0].splice(0, 1);
        } else {
          master = results[0];
          results.splice(0, 1);
        }

        master.gateway = config.forGateways[0];

        this._block.next({ reportId, block, data: results, master });
        this._block.next(null); // Don't leave a block around
      });
  }

  /**
   * Colour for a day
   * @param day The day of the week as a three character string
   * @returns string
   */
  colourForDay(day: string): string {
    switch (day.toLowerCase()) {
      case 'mon':
        return '#02B801';
      case 'tue':
        return '#565656';
      case 'wed':
        return '#167ac6';
      case 'thu':
        return '#8B20BB';
      case 'fri':
        return '#fdba1e';
      case 'sat':
        return '#cc181e';
      case 'sun':
        return '#4182ef';
    }

    return '#000';
  }
}

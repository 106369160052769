@defer(when cats) {
@if(cats.listing?.length) {
<div class="content-container">
	<table class="table table-hover" aria-label="Floorplan areas">
		<thead>
			@if(!isMobile) {
			<tr>

				<th class="can-sort">
					<span pTooltip="Sort by sensor name" tooltipPosition="right" (click)="headerClicked('sensor')">
						Sensor
						<i class="fa" [ngClass]="{'fa-angle-down':sort.sensor==='desc', 'fa-angle-up':sort.sensor==='asc'}"></i>
					</span>
				</th>

				<th class="can-sort">
					<span pTooltip="Sort site" tooltipPosition="left" (click)="headerClicked('site')">
						Site
						<i class="fa" [ngClass]="{'fa-angle-down':sort.site==='desc', 'fa-angle-up':sort.site==='asc'}"></i>
					</span>
				</th>

				<th>Floor</th>
				<th class="can-sort">

					<span pTooltip="Sort by area" tooltipPosition="right" (click)="headerClicked('area')">
						Area
						<i class="fa" [ngClass]="{'fa-angle-down':sort.area==='desc', 'fa-angle-up':sort.area==='asc'}"></i>
					</span>
				</th>
				<th class="can-sort text-right">
					<span pTooltip="Sort by sensor value" tooltipPosition="left" (click)="headerClicked('value')">
						Value
						<i class="fa" [ngClass]="{'fa-angle-down':sort.value==='desc', 'fa-angle-up':sort.value==='asc'}"></i>
					</span>
				</th>

			</tr>
			}
		</thead>
		<tbody>
			@for(cat of cats.listing; track cat) {
			@if(isMobile) {
			<tr class="row-1" [routerLink]="['/profiling','sites',cat.siteId, 'floorplans']">
				<td colspan="5">{{cat.assetTitle}}</td>
			</tr>
			<tr class="row-2" [routerLink]="['/profiling','sites',cat.siteId, 'floorplans']">
				<td colspan="5">{{cat.siteTitle}}</td>
			</tr>
			<tr class="row-2" [routerLink]="['/profiling','sites',cat.siteId, 'floorplans']">
				<td>{{cat.floor === 0 ? 'G' : cat.floor}}</td>
				<td>{{cat.category}}</td>
				<td class="text-right">
					{{cat.assetValue | number:'0.1'}}
					<i class="{{cat.assetRag}} fa fa-square rag"></i>
				</td>
			</tr>
			} @else {
			<tr [routerLink]="['/profiling','sites',cat.siteId, 'floorplans']">
				<td>{{cat.assetTitle}}</td>
				<td>{{cat.siteTitle}}</td>
				<td>{{cat.floor === 0 ? 'G' : cat.floor}}</td>
				<td>{{cat.category}}</td>
				<td class="text-right">
					{{cat.assetValue | number:'0.1'}}
					<i class="{{cat.assetRag}} fa fa-square rag"></i>
				</td>
			</tr>
			}
			}
		</tbody>
	</table>
</div>
} @else {
<app-info-panel> No categories defined </app-info-panel>
}
} @placeholder {
<app-please-wait></app-please-wait>
}
<!--
	<p-tabPanel header="Grouped">
		<ng-template pTemplate="content">
			<section *ngIf="cats">
				<div *ngFor="let cat of cats.items">
					<h3>{{cat.title}}</h3>
					<div *ngFor="let shape of cat.shapes">
						<h4>{{shape.siteTitle}} - {{shape.title}}</h4>
						<span *ngFor="let asset of shape.assets">
							<span class="{{asset.rag}}">{{asset.title}} : {{asset.value}}</span>
						</span>
					</div>
				</div>
			</section>
		</ng-template>
	</p-tabPanel>
-->

/**
 * Weekly Occupancy for dashboard
 * @author tonymporter@gmail.com
 */
import { Component, Input, OnInit } from '@angular/core';
import { Site } from 'app/classes/site';
import { DialogOccupancyDayComponent } from 'app/dialogs/dialog-occupancy-day/dialog-occupancy-day.component';
import { APIService } from 'app/shared/api.service';
import { DialogService } from 'primeng/dynamicdialog';
import moment from 'moment';

@Component({
  selector: 'app-spd-summary',
  templateUrl: './spd-summary.component.html',
  styleUrls: ['./spd-summary.component.css'],
  providers: [DialogService]
})
export class SpdSummaryComponent implements OnInit {

  @Input()
  site: Site;

  summary: DashboardSummary;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  period: 'this' | 'last' | 'next' | 'previous' = 'this';
  can: { thisWeek: boolean, nextWeek: boolean } = { thisWeek: false, nextWeek: false };

  constructor(private apiService: APIService, public dialogService: DialogService) { }

  ngOnInit(): void {
    this.setDates('this');
  }

  printPage() {
    window.print();
  }

  get() {
    const df = this.apiService.dbDate(this.dateFrom.toDate());
    const dt = this.apiService.dbDate(this.dateTo.toDate());

    this.apiService.postQuery({ t: 'sites', id: this.site.id, df, dt, action: 'site-floorplan-dashboard-summary' })
      .then(r => {
        console.log('got site-floorplan-dashboard-summary', r);

        this.summary = new DashboardSummary(r);
      });
  }

  setDates(period: 'this' | 'last' | 'previous' | 'next') {

    this.can.thisWeek = (+moment().isoWeekday(1).startOf('isoWeek').startOf('day') !== +moment().startOf('day'));


    if (period === 'this' && !this.can.thisWeek) {
      // There will be no data on a monday, default to previous week.
      this.dateFrom = moment().isoWeekday(1).startOf('isoWeek').startOf('day');
      period = 'previous';
      this.can.nextWeek = false;
    } else {
      this.can.nextWeek = true;
    }


    this.period = period;
    switch (period) {
      case 'previous':
        this.dateFrom.subtract(1, 'weeks').isoWeekday(1).startOf('isoWeek').startOf('day');
        this.dateTo = this.dateFrom.clone().isoWeekday(1).endOf('isoWeek').endOf('day');
        break;
      case 'this':
        this.dateFrom = moment().isoWeekday(1).startOf('isoWeek').startOf('day');
        this.dateTo = moment();
        break;
      case 'next':
        this.dateFrom.add(1, 'weeks').isoWeekday(1).startOf('isoWeek').startOf('day');
        this.dateTo = this.dateFrom.clone().isoWeekday(1).endOf('isoWeek').endOf('day');
        break;
      default:
        return;
    }

    if (+this.dateTo > +moment()) {
      this.can.nextWeek = false;
    }

    this.get();
  }

  adc(asset, day: number, week: { c: string, d: Date, h: string, m: number, p: number }) {

    console.log(`adc`, asset, day, week);

    const df = week.d;
    const dt = week.d;
    const assetPackage = { asset, day, df, dt };
    const headerTitle = asset.shapeTitle + ' - ' + moment(df).format('ddd DD MMM YY');

    const dialogRef = this.dialogService.open(DialogOccupancyDayComponent, {
      header: headerTitle,
      width: '80%',
      data: { asset: assetPackage }
    });

    dialogRef.onClose.subscribe(() => {

    });
  }
}

export class DashboardSummary {
  df: Date;
  dt: Date;
  siteId: number;
  totalPerc = 0;
  assets: IDashboardSummaryAsset[];
  values: { asset_id: number, value: number, createdAt: Date }[]

  constructor(data: any) {
    this.df = new Date(data.master.df);
    this.dt = new Date(data.master.dt);
    this.siteId = data.master.siteId;

    data.assets.sort((a, b) => {
      if (a.floor === b.floor) {
        return a.title > b.title ? 1 : -1;
      } else {
        return a.floor > b.floor ? 1 : -1;
      }
    });

    this.assets = data.assets.map(a => {
      return {
        id: a.id,
        title: a.title,
        floor: a.floor,
        floorTitle: a.sfTitle,
        shapeId: a.shapeId,
        shapeTitle: a.shapeTitleUser,
        occDays: JSON.parse(a.occDays),
        occFullUtilisationMins: a.occFullUtilisationMins,
        weeks: [[0, 0, 0, 0, 0, 0, 0]]
      }
    });

    this.values = data.values.map(v => {
      const createdAt = new Date(v.createdAt);

      return {
        asset_id: v.id,
        createdAt,
        value: +v.value
      };
    });
    let count = 0;
    let perc = 0;

    this.assets.forEach(a => {
      a.values = this.values.filter(v => v.asset_id === a.id);

      a.values.forEach(v => {
        let h: any = '';
        if (v.value > 60) {
          h = parseInt(String(v.value / 60));
          h = `${h} hours ${(v.value - (h * 60))} minutes`;
        } else {
          h = `${v.value} minutes`;
        }

        const dow = v.createdAt.getDay() === 0 ? 6 : v.createdAt.getDay() - 1;
        if (a.occDays[dow]) {
          const shapePerc = (100 / a.occFullUtilisationMins);
          let value = +(Number(String(v.value * shapePerc)).toFixed(0));
          if (value > 100) {
            value = 100;
          }
          perc += value;
          count++;
          const colour = value > 80 ? 'green' : value > 60 ? 'lightgreen' : value > 40 ? 'yellow' : value > 20 ? 'orange' : 'red';

          a.weeks[0][dow] = { p: value, m: v.value, c: colour, h, d: v.createdAt };
        } else {
          a.weeks[0][dow] = { p: null, m: v.value, c: 'grey', h, d: v.createdAt };
        }
      });
    });
    this.totalPerc = +(Number(String(perc / count)).toFixed(0));
  }
}

export interface IDashboardSummaryAsset {
  id: number;
  floor: number,
  floorTitle: string,
  occDays: number[],
  occFullUtilisationMins: number,
  shapeId: number,
  shapeTitle: string,
  title: string,
  weeks: any;
  values: { asset_id: number, value: number, createdAt: Date }[];
}

<article>
	<div class="tabview-list">
		<div [class.active]="onTab()==='profiling'" (click)="tabClick('profiling')"><i class="mdi mdi-floor-plan"></i> Floor plans </div>
		<div [class.active]="onTab()==='areas'" (click)="tabClick('areas')"><i class="mdi mdi-view-list"></i> Areas </div>
		@for(screen of screens();track screen.id) {
		<div [class.active]="onTab()==='screen_' + screen.id" (click)="tabClick('screen_' + screen.id)"><i class="mdi mdi-account-details-outline"></i>{{screen.title}} </div>
		}
		<div> </div>
	</div>

	<div class="tabview-content">
		@switch(onTab())
		{
		@case ('profiling') {
		<app-profile-list></app-profile-list>
		}
		@case('areas') {
		<app-profile-cats-list></app-profile-cats-list>
		}
		@default {
		<app-user-screen [screenId]="onScreen()"></app-user-screen>
		}
		}

	</div>
</article>

<div class="col-md-6">

  <div class="form-group">
    <div>
      <label>Title </label>
      <input class="form-control" name="title" [(ngModel)]="finding.title">
    </div>
  </div>
</div>
<div class="col-md-6">
    <div class="form-group" >
        <div>
          <label>RAG</label>
          <select name="rag" class="form-control" [(ngModel)]="finding.rag">
            <option *ngFor="let obj of ['red','amber','green']" [value]="obj"> {{obj}}</option>
          </select>
        </div>
      </div>
</div>
<div class="form-group">
  <div>
    <label>Findings</label>
    <textarea name="findings" class="form-control" rows="5" [(ngModel)]="finding.findings"></textarea>
  </div>
</div>
<div class="data">
  <div class="col-md-6">
    <div class="form-group" *ngIf="finding.telemetry">
      <div>
        <label>Range Start</label>
        <select name="choices" class="form-control" [(ngModel)]="finding.telemetry.start.id">
          <option *ngFor="let obj of finding.telemetryChoices.start" [value]="obj.i"> {{obj.d | amDateFormat:'dd MMM - hh:mm'}} - {{obj.v}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group" *ngIf="finding.telemetry">
      <div>
        <label>Range End</label>
        <select name="choices" class="form-control" [(ngModel)]="finding.telemetry.end.id">
          <option *ngFor="let obj of finding.telemetryChoices.end" [value]="obj.i"> {{obj.d | amDateFormat:'dd MMM - hh:mm'}} - {{obj.v}}</option>
        </select>
      </div>
    </div>
  </div>
</div>
<div class="actions">
  <button class="btn btn-sm btn-secondary" (click)="onDelete.emit(true)">
    <i class="fa fa-fw fa-trash"></i> Delete</button>
    <button class="btn btn-sm btn-secondary" (click)="onSubmit.emit(true)">
        <i class="fa fa-fw fa-floppy-o"></i> Submit</button>
</div>
<span (click)="debugJSON()">DEBUG: </span>{{debug}}
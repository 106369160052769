@defer(when orgs?.length) {
<div>
	<div class="quick-swaps mb-1 mt-1">
		@for(item of orgSwaps;track item.id) {
		<div>
			@if(item.logo) {
			<img tooltipPosition="top" pTooltip="Switch to <div style='color:yellow'>{{item.title}}</div><i style='color:#a0a0a0'>Switched {{item.swapCount}} times, {{item.swapUpdatedAt | amTimeAgo}}.</i>" src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{item.logo}}" (click)="switchOrg(item)" [escape]="false" alt="org logo">
			}
		</div>
		}
	</div>

	<div class="fixed-width mb-1">
		@if(!orgs.length) {
		<div>
			<i class="mdi mdi-loading mdi-spin-x2"></i>
		</div>
		} @else {
		<table class="table table-hover table-sm org-list" aria-label="Org List">
			<thead>
				<tr>
					<th [colSpan]="showId ? 2 : 1">Org</th>
					<th class="icon"></th>
					<th colspan="2"></th>
					<th class="address isDesktop">Address</th>
					<th class="created-at isDesktop" (click)="sortOrgBy('createdAt')" pTooltip="Show latest orgs created">Created</th>
					<th class="isDesktop">Actions</th>
					<th class="isDesktop">Features</th>
				</tr>
			</thead>
			<tbody>
				@for(item of orgs; track item.id;let i=$index) {
				<tr [class.inactive]="!item.isActive" [class.hidden]="!item.isActive && !showInactive">
					@if(showId) {
					<td>{{item.id}}</td>
					}
					<td>
						@if(item.logo) {
						<img src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{item.logo}}" alt="logo">
						}

					</td>
					<td [title]="item.type" class="icon">
						<i class="fa fa-fw fa-{{item.type}}"></i>
					</td>
					<td>{{item.title}}</td>
					<td><span class="isDesktop">{{item.shortTitle}}</span>
						<div class="text-right">
							<div class="isMobile text-right">
								<app-button (click)="switchOrg(item)" label="Login" />
							</div>
						</div>
					</td>
					<td class="address isDesktop">{{item.address1}}</td>
					<td class="isDesktop">{{item.createdAt | date: 'dd/MM/YY'}}</td>
					<td>
						<button (click)="switchOrg(item)" class="isDesktop btn btn-sm btn-secondary pull-right" title="Switch to this organisation"><i class="fa fa-hand-o-left"></i>
							<span>
								Switch
								to</span></button>
					</td>
					<td class="isDesktop">
						@if(item.engagementType === 'all') {
						<app-chip label="engagement" pTooltip="Engagement across all orgs" tooltipPosition="top" />
						}
						@if(item.engagementType === 'limited') {
						<app-chip label="limited&nbsp;engagement" pTooltip="Engagement for this org only" />
						}
					</td>
				</tr>
				}
			</tbody>
		</table>

		<app-toolbar>
			<div class="right">
				<app-button label="Toggle inactive" icon="mdi mdi-rotate-3d-variant" (click)="toggleInactiveOrgs()" />
				<app-button label="Toggle id" icon="mdi mdi-rotate-3d-variant" (click)="toggleOrgId()" />
			</div>
		</app-toolbar>
		}

	</div>
</div>
}

@if(isWorking()) {
<app-dialog [dimensions]="{'width':330, 'height':160}" [canInteract]="false">
	<div class="switch-org-dialog">
		<img src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{switchToOrg?.logo}}" alt="logo">
		<h2>Switching org...</h2>
	</div>
</app-dialog>
}

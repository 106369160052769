import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Building } from 'app/classes/building';
import { CollectionAsset } from 'app/classes/collection-for-assets';
import { DialogInsightsWaitComponent } from 'app/dialogs/dialog-insights-wait/dialog-insights-wait.component';
import { APIService } from 'app/shared/api.service';
import { InsightsService, IWaitForBuildingReviewJob } from 'app/shared/insights.service';
import { DialogService } from 'primeng/dynamicdialog';
import { InsightReview } from "../../../classes/insight-review";
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { IStartBuildingJobAnnotation, InsightsReviewService } from 'app/shared/insights.review.service';
@Component({
  selector: 'app-insights-as-report',
  templateUrl: './insights-as-report.component.html',
  styleUrls: ['./insights-as-report.component.css'],
  providers: [DialogService]
})
export class InsightsAsReportComponent implements OnInit, OnDestroy {

  @Input()
  useOrgLogo = true;

  @Input()
  clearCache: boolean;

  @Input()
  startDate: Date;

  @Input()
  endDate: Date;

  @Input()
  building: Building;

  @Input()
  collection: CollectionAsset;

  @Output()
  onReportGenerated: EventEmitter<InsightReview> = new EventEmitter();

  @Output()
  onReportClosed: EventEmitter<any> = new EventEmitter();

  uuid: any;
  generatedAt: Date;
  isRunning: boolean;
  dialogRef: import("primeng/dynamicdialog").DynamicDialogRef;
  isReady: boolean;
  hasData: boolean;
  weather: { t: number, d: Date, desc: string, i: string }[];
  insight = new InsightReview();
  headerTitle: any;
  assetIds: string[];
  isAdmin: boolean;
  adminAnnotations: { annotation: string, rag: string }[];

  status = '';

  subs: Subscription[] = [];

  constructor(private apiService: APIService, private insightsReviewService: InsightsReviewService, private insightsService: InsightsService, public dialogService: DialogService, private router: Router) { }

  ngOnInit(): void {
    this.isAdmin = this.apiService.isAdmin();

    this.startJob();
  }

  startJob() {
    if (this.isRunning) {
      return;
    }
    this.isRunning = true;
    const qs = `collectionId=${this.collection.id}`;
    // Get this months data
    this.insightsReviewService
      .startBuildingJob(this.building, [this.collection], this.startDate, this.endDate, this.clearCache, 'Building Review', qs)
      .then(r => {
        this.uuid = r.uuid;
        let annotations: IStartBuildingJobAnnotation[];
        annotations = r.annotations;

        const stage = r.stage;

        if (r.createdAt) {
          this.generatedAt = new Date(r.createdAt);
        }

        setTimeout(() => {
          if (this.isRunning) {
            this.dialogRef = this.dialogService.open(DialogInsightsWaitComponent, {
              width: '60%',
              header: 'Waiting for report ' + r.uuid
            });
          }
        }, 1000);

        this.insightsService
          .waitForBuildingHourlyJob<IWaitForBuildingReviewJob>(this.uuid, r.wait)
          .then(r => {
            console.log('OK');

            const collection = r?.collection;

            if (this.dialogRef) {
              this.dialogRef.close();
            }
            this.isRunning = false;
            if (!collection) {
              this.isReady = false;
              this.hasData = false;
              this.apiService.toastWarn('No data', '');
              setTimeout(() => {
                this.isReady = true;
                this.onReportGenerated.emit(null);
                window.scrollTo(0, document.body.scrollHeight);
              }, 10);

              return
            }

            const { master, weather } = r;

            if (weather) {
              //  { t: row.w.t, d: row.w.d, desc: row.w.i }
              this.weather = weather.map(w => { return { d: new Date(w.timestamp), t: w.temp, desc: w.description, i: w.icon } });
            }

            this.assetIds = master.assets.map(a => String(a.id));
            this.insight.collection = this.collection;
            this.insight.generatedAt = this.generatedAt;
            this.insight.uuid = this.uuid;
            this.insight.stage = stage;
            this.insight.setConfiguration(master);
            this.headerTitle = this.insight.configuration.title.split(' from ')[0];
            this.insight.setTelemetry(this.building, collection, annotations);
            console.log(master);
            this.isReady = true;
            this.hasData = true;

            setTimeout(() => {
              const id = 'report';
              const yOffset = -410;
              const element = document.getElementById(id);
              if (!element) {
                return;
              }
              const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

              window.scrollTo({ top: y, behavior: 'smooth' });
            }, 10);

            this.onReportGenerated.emit(this.insight);
          });
      });
  }

  onChartCompleted(id: number, $event) {
    console.log(`chart done for `, id, $event);
  }

  insightsAdminUpdated(insight: InsightReview) {
    this.onReportClosed.emit(true);
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}

import { Component, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { StoreService } from 'app/shared/store.service';
import { Subscription } from 'rxjs';
import { FilterMenu } from '../classes/filter-menu';
import { FilterMenuItem } from '../classes/filter-menu-item';
@Component({
	selector: 'filter-menu',
	templateUrl: './filter-menu.component.html',
	styleUrls: ['./filter-menu.component.css']
})
export class FilterMenuComponent implements AfterViewInit, OnDestroy {
	_filterMenu: FilterMenu;
	@Input()
	public set filterMenu(v: FilterMenu) {
		this._filterMenu = v;
		if (v) {
			this.hasLoaded.emit(true);
			if (this.selectedId) {
				const item = this._filterMenu.items.find(item =>
					item.id === this.selectedId);
				if (item) {
					this.selectedItem = item;
				}
				// Applied the id, now forget it.
				this.selectedId = null;
			}
		}
	}

	@Output()
	clicked: EventEmitter<FilterMenuItem> = new EventEmitter<FilterMenuItem>();

	@Output()
	hasLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

	selectedItem: FilterMenuItem = new FilterMenuItem();
	selectedId: string;
	subscription: Subscription;

	constructor(private storeService: StoreService) {
		this.subscription = storeService.filterMenuSelect.subscribe(id => {
			// console.log('FILTER_MENU_CHANGE', id)
			if (!id) {
				return;
			}
			if (this._filterMenu) {
				const item = this._filterMenu.items.find(item =>
					item.id === id);
				if (item) {
					this.selectedItem = item;
				}
			} else {
				// No menu yet, store for later when we have menu items
				this.selectedId = id;
			}
		});
	}

	menuClick(item: FilterMenuItem) {
		// console.log(JSON.stringify(item, null, 2));
		this.clicked.emit(item);
		this.selectedItem = item;
	}

	ngAfterViewInit() {

	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

<!--<p-toolbar>
    <div class="p-toolbar-group-left">

    </div>

    <div class="p-toolbar-group-center no-print">
        <p-button icon="mdi mdi-clock-check-outline" styleClass="p-button-outlined p-button-sm p-button-secondary"
            (click)="toggleAllDay()" [label]="dataView"></p-button>
        <p-button label="Print page" icon="mdi mdi-printer"
            styleClass="p-button-outlined p-button-sm p-button-secondary" (click)="printPage()"></p-button>
        <span class="">&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <p-button label="Previous week" icon="mdi mdi-rewind"
            styleClass="p-button-outlined p-button-sm p-button-secondary" (click)="previousWeek()"></p-button>
        <p-button [disabled]="!canGoNextWeek" label="Next week" icon="mdi mdi-fast-forward"
            styleClass="p-button-outlined p-button-sm p-button-secondary" (click)="nextWeek()" iconPos="right">
        </p-button>
        <span class="">&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <p-button label="Previous day" icon="mdi mdi-calendar-arrow-left"
            styleClass="p-button-outlined p-button-sm p-button-secondary" (click)="previous()"></p-button>
        <p-button [disabled]="!canGoNext" label="Next day" icon="mdi mdi-calendar-arrow-right"
            styleClass="p-button-outlined p-button-sm p-button-secondary" (click)="next()" iconPos="right"></p-button>
    </div>
</p-toolbar>-->

<table *ngIf="assets && !isLoading" class="table table-sm">
    <thead>
        <tr>
            <th>Floor</th>
            <th>Asset</th>
            <th *ngFor="let h of [00,01,02,03,04,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23]"
                tooltipPosition="top" pTooltip="Occupancy between {{h}}:00 and {{h}}:59">{{h}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let asset of assets">
            <td>{{asset.floor.t}} <i class="mdi" [class.mdi-clock-outline]="asset.collOfDaysId"
                    [pTooltip]="'Restricted hours'"></i> </td>
            <td [pTooltip]="asset.i + ' ' + asset.title + ' ' ">{{asset.t}}</td>
            <td *ngFor="let hour of asset.hours; let i = index" [class.rating-e]="hour.v !== null && hour.v <= 12"
                [class.rating-d]="hour.v > 12 && hour.v <= 24" [class.rating-c]="hour.v > 24 && hour.v <= 36"
                [class.rating-b]="hour.v > 36  && hour.v <= 48" [class.rating-a]="hour.v > 48 && hour.v <= 60"
                [class.restricted]="asset.collection && (i < asset.collection.dayRow.startHour || i >= asset.collection.dayRow.endHour)">
                <span *ngIf="hour.v !== null" pTooltip="{{hour.v}} mins of occupancy">{{hour.v ? hour.v :
                    '-'}}</span>
            </td>
        </tr>
    </tbody>
</table>

<app-info-panel *ngIf="noDataExists">NO VALUES FOUND</app-info-panel>

<div class="pull-right">
    <app-rating-ae [width]="240"></app-rating-ae>
</div>

<app-please-wait *ngIf="isLoading"></app-please-wait>

import { Component, input, OnInit, signal } from '@angular/core';
import { Site } from 'app/classes/site';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-org-sites',
  templateUrl: './org-sites.component.html',
  styleUrl: './org-sites.component.css'
})
export class OrgSitesComponent implements OnInit {
  sites = input.required<Site[]>();
  tabIndex = signal<number>(0);
  searchFor: string;
  filteredSites = signal<Site[]>(null);

  constructor(private apiService: APIService) {
    this.getLocalStorage();
  }

  ngOnInit(): void {

    this.filter();
  }

  getLocalStorage() {
    try {
      const config = JSON.parse(localStorage.getItem(`org:${this.apiService.getUserOrg().id}:sites:list`));
      if (config) {
        this.searchFor = config.searchfor || null;
      }
    } catch (e) {
      console.log(e);
    }
  }

  setLocalStorage() {
    try {
      localStorage.setItem(`org:${this.apiService.getUserOrg().id}:sites:list`, JSON.stringify({ searchfor: this.searchFor.trim() }));
    } catch (e) {
      console.log(e);
    }
  }

  setTab(tabIndex: number) {
    this.tabIndex.set(tabIndex);
  }

  searchChanged(searchText: string) {
    this.searchFor = searchText;
    localStorage.setItem(`org:${this.apiService.getUserOrg().id}:insights:assets:list`, JSON.stringify({ searchFor: this.searchFor }));
    this.filter();
  }

  filter() {
    this.setLocalStorage();
    if (!this.searchFor || this.searchFor.length < 2) {
      this.filteredSites.set(this.sites());
      return;
    }
    this.filteredSites.set(this.sites().filter(r => {
      if (r.title.toLowerCase().includes(this.searchFor)) return true;
    }));
  }

}

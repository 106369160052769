import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SitePlan } from '../../classes/site-plan';
import { SiteFloorplanArea } from '../../classes/site-floorplan-area';
import { SiteFloorplanAsset } from '../../classes/site-floorplan-asset';
import { SiteFloorplanObject } from '../../classes/site-floorplan-object';
import { Viewbox } from '../../classes/svg/viewbox';
import { Site } from '../../classes/site';
import { Subscription } from 'rxjs';
import { StoreService } from '../../shared/store.service';
import { filter, take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { SiteFloorplanShape } from 'app/classes/site-floorplan-shape';
import { AssetService } from 'app/shared/asset.service';
import { D3ChartService } from 'app/shared/d3chart.service';
import moment from 'moment';
import { WindowService } from 'app/shared/window.service';
import { FloorplanService } from "app/shared/floorplan.service";

@Component({
  selector: 'app-site-plan',
  templateUrl: './site-plan.component.html',
  styleUrls: ['./site-plan.component.css']
})
export class SitePlanComponent implements OnInit, OnDestroy {
  @ViewChild('svgDataContainer', { static: false }) svgDataContainer: ElementRef;
  @ViewChild('info', { static: false }) infoRef: ElementRef;
  static readonly componentName: string = 'SitePlanComponent';
  static readonly DATA_REFRESH_EVERY = 60000;
  getTelemetyTimer: any;

  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  siteFloorplans: SitePlan[];
  // Selected floorplan (viewing)
  siteFloorplan: SitePlan;
  // Selected dashboard
  dashboardSelected: boolean;
  can: { admin: boolean } = { admin: false };

  assets: SiteFloorplanAsset[];
  areas: SiteFloorplanArea[] = [];
  shapes: SiteFloorplanShape[] = [];
  objects: SiteFloorplanObject[] = [];
  hasLoaded: boolean;
  viewbox: Viewbox;
  viewboxString: String;
  mode = '';

  stats = {
    clientX: 0,
    clientY: 0,
    svgX: 0,
    svgY: 0
  };

  areaFocus: SiteFloorplanArea;
  site: Site;
  // Set to an asset to view full screen data
  viewAsset: SiteFloorplanAsset;
  selected: { presentation: 'floorplan' | 'table' | 'chart', range: { startTime: string, endTime: string, type: string } } = {
    presentation: 'floorplan',
    range: { startTime: '08:00', endTime: '17:00', type: 'avg' }
  };

  sectionBannerBackSub: Subscription;
  scales: any = { r: 20, labelYOffset: 34 };
  dataRefreshTimer = null;

  selectedArea: SiteFloorplanArea;
  selectedShape: SiteFloorplanShape;

  isSVGImageRendered: boolean;
  isLoading: boolean;

  hoverObject: { type: string, obj: any, left: number, top: number } = null;

  viewShape: SiteFloorplanShape;

  hoverSensor: any;
  hasDashboard: boolean;

  userSubscription: Subscription;

  planId: number | string;

  zoomLevel = 0;

  svgMod = { x: 0, y: 0, width: 0, height: 0 };

  constructor(private floorplanService: FloorplanService, private windowService: WindowService, private el: ElementRef, private location: Location, private sanitizer: DomSanitizer, private apiService: APIService, private router: Router, private route: ActivatedRoute, private storeService: StoreService, private assetService: AssetService, private d3ChartService: D3ChartService) {

    this.isLoading = true;
    this.planId = this.route.snapshot.queryParamMap.get('planid');

    route.params.subscribe((params: Params) => {

      apiService.getSite(params.siteid).then(site => {
        this.site = site;

        this.hasDashboard = !!site.settings.floorplan_dashboard;
        apiService.getSiteFloorplans(params.siteid)
          .then(siteFloorPlans => {
            this.siteFloorplans = siteFloorPlans;
            this.planId = this.planId || siteFloorPlans[0].id;
            this.getSiteFloorplan(site.id, +this.planId);

          });
      });
    });

    this.sectionBannerBackSub = storeService.sectionBannerBack
      .pipe(filter(value => value != null && value.state))
      .subscribe(value => {
        value.state = false;
        storeService.setSectionBannerChangedDetail(null);
        storeService.setSectionBannerBack(value);
        this.back();
      });

    this.restoreLocalStorage();
    this.userSubscription = this.apiService.user.subscribe((user) => {
      if (user) {
        this.can.admin = (user.role === 'admin' || user.orgRoles.join(',').indexOf('floorplan_admin') !== -1);
      }
    });
  }

  svgLoaded() {
    const self = this;
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        self.isSVGImageRendered = true;

        this.getZoomFromLocalStorage();
      }
      );
    });
  }

  ngOnInit() {
  }

  panDirection(action: 'left' | 'right' | 'up' | 'down') {
    this.viewbox.panDirection(action);
    this.saveZoomToLocalStorage();
  }

  navigateBack() {
    this.location.back();
  }

  zoom(action: 'in' | 'out' | 'reset') {
    const zoomBy = 100;
    switch (action) {
      case 'out':
        this.zoomLevel -= 1;
        break;
      case 'in':
        this.zoomLevel += 1;
        break;
      case 'reset':
        this.zoomLevel = 0;
        break;
    }
    this.viewbox.zoomInToZoomLevel(this.zoomLevel);
    this.saveZoomToLocalStorage();
  }

  saveZoomToLocalStorage() {
    const payload = {
      coords: this.viewbox.calculated,
      zoomLevel: this.viewbox.zoomLevel
    };
    localStorage.setItem(`siteplan:${this.siteFloorplan.id}:zoom`, JSON.stringify(payload));
  }

  getZoomFromLocalStorage() {
    // Only for mobile view
    if (!this.windowService.isMobile()) {
      return;
    }
    const storage = localStorage.getItem(`siteplan:${this.siteFloorplan.id}:zoom`);
    try {
      const payload = JSON.parse(storage);
      this.viewbox.zoomInToZoomLevel(payload.zoomLevel);
      this.viewbox.calculated = payload.coords;
      this.zoomLevel = this.zoomLevel;
    } catch (e) { }
  }

  reset() {
    this.viewbox.reset();
  }

  back() {
    this.router.navigate(['/profiling'], { relativeTo: this.route });
  }

  updateOptions() {
    this.saveLocalStorage();
    const splan = this.siteFloorplan;
    this.siteFloorplan = null;
    setTimeout(() => {
      this.siteFloorplan = splan;
    }, 100);
  }

  clickFloorplanLabel(area?: SiteFloorplanArea) {
    if (!area) {
      this.viewbox = new Viewbox(this.siteFloorplan.vbx, this.siteFloorplan.vby, this.siteFloorplan.viewboxWidth, this.siteFloorplan.viewboxHeight);
      this.selectedArea = null;
    } else {
      // if (area.id === this.selectedArea?.id) {
      //   this.viewbox = new Viewbox(this.siteFloorplan.vbx, this.siteFloorplan.vby, this.siteFloorplan.viewboxWidth, //this.siteFloorplan.viewboxHeight);
      //    this.selectedArea = null;
      //  } else {
      this.viewbox = new Viewbox(area.x, area.y, area.width, area.height);
      this.selectedArea = area;
      // }
    }
  }

  sanitizedHTML(shape: SiteFloorplanShape) {
    return this.sanitizer.bypassSecurityTrustHtml(shape.svg);
  }
  /*
    shapeClicked(shape: SiteFloorplanShape) {
      this.clickFloorplanLabel(this.areas.filter(area => area.id === shape.siteFloorplanAreaId)[0]);
    }
  */
  initPlan() {
  }

  getSiteFloorplan(siteId: number, siteFloorplanId: number) {
    this.isLoading = true;
    const self = this;
    if (this.dataRefreshTimer) {
      console.log('clear refresh timer on previous floor');
      clearInterval(this.dataRefreshTimer);
    }

    this.floorplanService.getSiteFloorplan(siteId, siteFloorplanId)
      .then(results => {
        if (results.siteplan.type === 'shape') {
          // This is an enhanced plan with shapes.
          this.mode = 'overview';
        }
        this.assets = [];
        this.areas = [];
        this.shapes = [];
        this.siteFloorplan = results.siteplan;

        if (!this.siteFloorplan) {
          // No floorplan so exit.
          return;
        }

        // setTimeout(() => {
        if (this.siteFloorplan && this.siteFloorplan.fontSize) {
          // Adjust the circle size
          this.scales.r = this.siteFloorplan.fontSize * 1.8;
          this.scales.labelYOffset = this.siteFloorplan.fontSize * 1.2;
        }
        this.areas = results.areas || [];
        this.shapes = results.shapes || [];
        // console.log(`got shapes`, this.shapes.length);
        const defaultArea = this.areas.filter(area => area.defaultArea);
        if (defaultArea.length) {
          // The plan has a default area
          const area = defaultArea[0];
          this.viewbox = new Viewbox(area.x, area.y, area.width, area.height);
        } else {
          this.viewbox = new Viewbox(this.siteFloorplan.vbx, this.siteFloorplan.vby, this.siteFloorplan.viewboxWidth, this.siteFloorplan.viewboxHeight);
        }
        this.viewboxString = this.viewbox.toString();

        // Only assets mapped onto the plan
        this.assets = results.assets.filter(asset => asset.siteFloorplanId === this.siteFloorplan.id);

        this.checkRAGs(true);
        this.hasLoaded = true;
        this.isLoading = false;
        //  }, 1);

        this.apiService.user
          .pipe(take(1))
          .subscribe(u => {
            console.log(`user received`, u);
            let refreshEvery = u.prefs && u.prefs.floorplan_refresh ? u.prefs.floorplan_refresh * 1000 : SitePlanComponent.DATA_REFRESH_EVERY;
            if (refreshEvery < 5000) {
              // Minimum 5 seconds
              if (u.id === 10) { refreshEvery = 2000 } else { refreshEvery = 5000 }
            }
            /*
            this.dataRefreshTimer = setInterval(() => {
              this.isLoading = true;
              // Get data every X seconds to refresh floorplan
              this.floorplanService.getSiteFloorplan(siteId, siteFloorplanId)
                .then(planResults => {
                  // Reset changes
                  this.assets.forEach(asset => {
                    asset.changeDiff = 0;
                  });
                  planResults.assets.forEach(assetLatest => {
                    this.assets.forEach(asset => {
                      // Look for a change in value or updated At change on buttons
                      if (
                        asset.id === assetLatest.id &&
                        ((asset.originalValue !== assetLatest.originalValue) || (asset.assetType_id === 1))

                      ) {
                        console.log('ASSET CHANGED', asset, assetLatest);
                        //if (asset.isSetpointable) {
                        try {
                          asset.changeDiff = +(asset.originalValue) - +(assetLatest.originalValue);
                        } catch (e) { }
                        //}
                        asset.rag = assetLatest.rag;
                        // Let the asset sort out RAG from value if custom asset RAG exists
                        // - used by floorplans and overrides RAG from setpoints if exists,
                        //   although this is used so setpoints are not required for simple RAG 
                        //   triggers.
                        asset.setValue(assetLatest.originalValue, assetLatest.updatedAt);

                        asset.updatedAt = assetLatest.updatedAt;
                      }
                    });
                  });
                  console.log(this.assets.filter(a => !!a.changeDiff));
                  this.checkRAGs();
                  this.isLoading = false;
                });
            }, refreshEvery);*/
          });
      });
  }

  checkRAGs(wantsInitialisation: boolean = false) {
    console.log('CHECKRAGS()', wantsInitialisation, this.shapes?.length);
    // Reset shapes to green
    this.shapes.forEach(s => s.atRAG = 'green');
    // Get rags for assets
    this.assets.forEach(asset => {

      const isMotion = asset.assetType_id === 50;

      if (wantsInitialisation) {
        asset.assetTypeTitle = this.assetService.getTypeTitle(asset.assetType_id);
      }


      if (asset.shapeId) {
        const shape = this.shapes.find(s => s.id === asset.shapeId);

        if (isMotion) {
          //  console.log(asset.id, asset.value, asset.upDataOp, shape);
        }

        if (wantsInitialisation) {
          shape.assets.push(asset);
        }

        let rag = null;
        if (asset.upData === 'rag') {
          if (asset.rag === 'red' || asset.rag === 'amber') {
            rag = asset.rag
          }
        } else if (asset.upData === 'value') {
          switch (asset.upDataOp) {
            case '=':
              if (+asset.originalValue === asset.upDataValue) {
                rag = 'red';
              }
              break;
            case '>':
              if (+asset.originalValue > asset.upDataValue) {
                rag = 'red';
              }
              break;
            case '<':
              if (+asset.originalValue < asset.upDataValue) {
                rag = 'red';
              }
              break;
          }
          //console.log('RAG ASSET', asset.title, +asset.originalValue, asset.value, asset.upDataOp, asset.upDataValue, rag);
        }

        if (rag) {
          shape.atRAG = rag;
          //console.log(`RAG SET ON SHAPE`, shape.title, rag);
        }

        if (isMotion) {
          // console.log('DECISION:', asset.value, shape.title, shape.atRAG, rag);
        }
      }
    });
    console.log('CHECKRAGS DONE');
  }

  shapeClick(shape: SiteFloorplanShape) {
    console.log('SHAPE_CLICK', shape?.title);
    this.selectedShape = shape;
  }

  calcShapeRAG() {
  }

  previewArea(area: SiteFloorplanArea) {
    this.clickFloorplanLabel(area);
  }

  onMouseEnterAreaLabel(area: SiteFloorplanArea) {
    this.areaFocus = area;
    //this.clickFloorplanLabel(area);
  }

  onMouseLeaveAreaLabel(area: SiteFloorplanArea) {
    this.areaFocus = null;
    //this.clickFloorplanLabel(null);
  }

  onClickSitePlanLabel(siteFloorplan: SitePlan) {
    this.location.replaceState(window.location.pathname, `planid=${siteFloorplan.id}`);
    this.selectedArea = null;
    this.dashboardSelected = false;
    this.getSiteFloorplan(this.site.id, siteFloorplan.id);
  }

  clickSelectAsset(asset: SiteFloorplanAsset) {
    if (asset.assetType_id === 1) {
      // This is a button
    } else {
      this.viewAsset = asset;
    }
  }

  presentationClick(event: any) {
    if (event.target.tagName === 'LI') {
      switch (event.target.textContent.trim()) {
        case 'Charts':
          return this.switchTo('chart');
        case 'Table':
          return this.switchTo('table');
        case 'Floor plan':
          return this.switchTo('floorplan');
      }
    }
  }

  switchTo(presentation: 'floorplan' | 'table' | 'chart') {
    this.selected.presentation = presentation;
    if (presentation === 'table') {
      this.updateOptions();
    } else {
      this.saveLocalStorage();
    }
  }

  saveLocalStorage() {
    localStorage.setItem(this.apiService.getUserOrg().id + ':' + this.apiService.getUserId() + ':' + SitePlanComponent.componentName, JSON.stringify(this.selected));
  }

  restoreLocalStorage() {
    let payload: any = localStorage.getItem(this.apiService.getUserOrg().id + ':' + this.apiService.getUserId() + ':' + SitePlanComponent.componentName);
    if (!payload) {
      return;
    }
    try {
      this.selected = JSON.parse(payload);
    } catch (e) {
      console.log(e);

    }
    if (!this.selected.presentation) {
      // Must have a default
      this.selected.presentation = 'floorplan';
    }
  }



  swipe(currentIndex: number, action = this.SWIPE_ACTION.RIGHT) {
    console.log(currentIndex, action);
  }

  pinch(event) {
    event.preventDefault();

    console.log(event, event.additionalEvent);

    const pixelsW = Math.ceil(this.viewbox.w / 50);
    const pixelsH = Math.ceil(this.viewbox.h / 50);

    if (event.additionalEvent === 'pinchin') {
      this.viewbox.w = this.viewbox.w + pixelsW;
      this.viewbox.h = this.viewbox.h + pixelsH;
    }
    if (event.additionalEvent === 'pinchout') {
      this.viewbox.w = this.viewbox.w - pixelsW;
      this.viewbox.h = this.viewbox.h - pixelsH;
    }

    this.viewboxString = this.viewbox.toString();
  }

  pan(event) {
    event.preventDefault();

    console.log(event, event.additionalEvent);

    const pixels = Math.ceil(this.viewbox.w / 10);
    const pixelsY = Math.ceil(this.viewbox.h / 10);

    if (event.additionalEvent === 'panright') {
      this.viewbox.x = this.viewbox.x + pixels;
    }
    if (event.additionalEvent === 'panleft') {
      this.viewbox.x = this.viewbox.x - pixels;
    }
    if (event.additionalEvent === 'panup') {
      this.viewbox.y = this.viewbox.y - pixelsY;
    }
    if (event.additionalEvent === 'pandown') {
      this.viewbox.y = this.viewbox.y - pixelsY;
    }
    console.log(pixels, this.viewbox.x);

    this.viewboxString = this.viewbox.toString();
  }

  url(id: string) {
    return `url(${this.location.path()}#${id})`;
  }

  movementOnShape(shape: SiteFloorplanShape, event: any) {
    if (!shape) {
      this.hoverObject = null;
      return;
    }
    const { left, top } = event.target.getBoundingClientRect();
    let { offsetX, offsetY } = event;

    offsetX += 10;
    offsetY += 10;
    this.hoverObject = { type: 'shape', obj: shape, left: offsetX, top: offsetY };
  }

  svgClick(event: any) {
    console.log(event);
  }


  processCursor(svg, pt, event) {
    pt.x = event.clientX;
    pt.y = event.clientY;

    this.processPT(pt);

    this.stats.clientX = pt.x;
    this.stats.clientX = pt.y;

    const cursorPT = pt.matrixTransform(svg.getScreenCTM().inverse());
    cursorPT.x = Math.round(cursorPT.x);
    cursorPT.y = Math.round(cursorPT.y);

    this.stats.svgX = cursorPT.x;
    this.stats.svgY = cursorPT.y;

    return cursorPT;
  }

  processPT(pt) {

    return pt;
  }

  svgMouseMove(event: any) {

    const svg = this.svgDataContainer.nativeElement;
    const pt = svg.createSVGPoint();
    pt.x = event.clientX;
    pt.y = event.clientY;

    if (pt.x <= 0 && pt.y <= 0) {
      return;
    }
    this.processPT(pt);

    this.stats.clientX = pt.x;
    this.stats.clientY = pt.y;

    // The cursor point, translated into svg coordinates
    const cursorpt = pt.matrixTransform(svg.getScreenCTM().inverse());
    cursorpt.x = Math.round(cursorpt.x);
    cursorpt.y = Math.round(cursorpt.y);

    const toRight = pt.x < svg.clientWidth / 2;
    const toTop = pt.y < svg.clientHeight / 2;

    this.stats.svgX = cursorpt.x;
    this.stats.svgY = cursorpt.y;

    const asset = this.assets.find(a => a.svg.x <= cursorpt.x && a.svg.x + a.svgWidth >= cursorpt.x && a.svg.y <= cursorpt.y && a.svg.y + a.svgHeight + a.svgPrecision?.rect.h >= cursorpt.y);

    if (!asset) {
      this.hoverSensor = null;
      return;
    }

    if (this.hoverSensor && this.hoverSensor.asset && this.hoverSensor.asset.id === asset.id) {
      // Already have an asset that was the same

    } else {
      if (this.getTelemetyTimer) {
        clearTimeout(this.getTelemetyTimer);
      }
      this.getTelemetyTimer = setTimeout(() => {
        const assetId = asset.id;
        const df = moment().startOf('day').toDate();
        const dt = new Date();
        this.d3ChartService.getTelemetry(assetId, df, dt, [])
          .then(response => {
            if (this.hoverSensor && this.hoverSensor.asset?.id === assetId) {
              // Don't display if no data today
              if (response.packets && response.packets.length) {
                // Strip out weather as we use data min/max, complicates view
                this.hoverSensor.min = null;
                this.hoverSensor.max = null;

                if (this.hoverSensor.asset.assetType_id === 1) {
                  // Buttons are "Touch"
                  this.hoverSensor.telemetry = response.packets || [];
                  return;
                }

                this.hoverSensor.telemetry = response.packets.map(t => {
                  if (this.hoverSensor.min === null || t.v <= this.hoverSensor.min) {
                    this.hoverSensor.min = t.v;
                  }
                  if (this.hoverSensor.max === null || t.v >= this.hoverSensor.max) {
                    this.hoverSensor.max = t.v;
                  }
                  return { ...t, w: null };
                });
              }
            }
          });
      }, 1000);
    }

    if (toRight) {
      this.hoverSensor = { ...this.hoverSensor, x: pt.x + 30, y: (toTop ? pt.y + 10 : pt.y - 80), toRight };

    } else {
      this.hoverSensor = { ...this.hoverSensor, x: pt.x - 310, y: (toTop ? pt.y : pt.y - 80), toRight };
    }

    this.hoverSensor.asset = asset;
    this.infoRef.nativeElement.style.left = this.hoverSensor.x + 'px';
    this.infoRef.nativeElement.style.top = this.hoverSensor.y + 'px';
  }

  onDashboardButtonClick() {
    // this.siteFloorplan = null;
    this.dashboardSelected = true;
  }

  objectInteract(where: 'label', event: 'mouseleave' | 'mouseenter' | 'click' | 'add', what: SiteFloorplanObject) {
    console.log('OBJ', where, event, what);
  }

  ngOnDestroy() {
    this.sectionBannerBackSub.unsubscribe();
    clearInterval(this.dataRefreshTimer);
    if (this.getTelemetyTimer) {
      clearTimeout(this.getTelemetyTimer);
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    console.log('DESTROY SitePlanComponent');
  }
}



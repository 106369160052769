import { CalendarContext } from './calendar-context';
import { Document } from './document';
import moment from 'moment';
import { Building } from './building';
import { Org } from './org';
import { User } from './user';
import { Site } from './site';
import { Gateway } from './gateway';
import { Collection } from './collection';

export class CalendarEntry {
	static readonly TYPES = { ACTION: 'action', EVENT: 'event' };
	id: number;
	title: string;

	_startAt: Date;
	public get startAt(): Date {
		return this._startAt;
	}
	public set startAt(v: Date) {
		// Seconds are set to zero
		v.setSeconds(0);
		this._startAt = v;
		if (+this.endAt && +v > +this.endAt) {
			this.endAt = null;
		}
	}

	_endAt: Date;
	public get endAt(): Date {
		return this._endAt;
	}
	public set endAt(v: Date) {
		if (!v) {
			return;
		}
		if (+this.startAt && +v < +this.startAt) {
			// Can't accept a date that is before start
			console.error('End date before start.');
			return;
		}
		this._endAt = v;
		// If end date does not sit on the same day, set all day flag.
		if (this.endAt.getDay() !== this.startAt.getDay()) {
			this.allDay = true;
		}
		// If end date same as start, null end for all days
		if (this.allDay && this.startAt.toISOString().slice(0, 10) === this.endAt.toISOString().slice(0, 10)) {
			this.endAt = null;
		}
	}

	allDay: boolean;
	type = 'action';
	body: string;
	createdAt: Date;
	labels: string[];
	isCompleted: boolean;
	rag: string;
	document: Document;
	state: string;
	typeComposed: string;
	editable: boolean;
	colour: string;
	createdBy: User;
	context: CalendarContext;
	isInstalling: boolean;
	isLeave: boolean;
	isSurvey: boolean;
	isReport: boolean;
	isExhibit: boolean;
	isReview: boolean;
	isMaintenance: boolean;
	mapURL: any;
	mapPrefix = 'https://maps.google.com/maps?q=';
	users: Collection<User> = new Collection<User>();

	constructor(data?: any) {
		this.context = new CalendarContext();
		if (data) {
			this.id = data.id;
			this.startAt = data.start ? data.start : new Date(data.startAt);
			this.endAt = data.end ? data.end : data.endAt ? new Date(data.endAt) : null;
			this.allDay = !!(data.allDay === 'Y');
			this.type = data.type || CalendarEntry.TYPES.ACTION;
			this.body = data.body;
			this.createdAt = new Date(data.createdAt);
			this.title = data.title;
			this.labels = data.labels ? data.labels.split(' ').map(label => String(label).replace('_', ' ')) : [];
			this.document = new Document({ id: data.documentId, key: data.documentKey || data.dk, title: data.documentTitle, contentType: data.dt });
			this.isCompleted = (data.isCompleted === 'Y');
			this.typeComposed = this.type + '_' + data.labels;
			this.colour = data.colour;
			this.editable = data.editable;
			this.rag = data.rag;

			if (data.contextTarget) {
				this.setContextFromID(data.contextTarget, data.contextId, data.contextTitle, data.addressLat, data.addressLong);
			}

			if (data.createdBy) {
				this.createdBy = new User({ id: data.createdBy, name: data.name });
			}

			switch (this.isCompleted) {
				case false:
					if (+this.startAt >= +new Date()) {
						if (moment(this.startAt).diff(new Date(), 'days') < 7) {
							this.state = 'soon';
						} else {
							this.state = 'future';
						}
					} else {
						this.state = 'overdue';
					}
					break;
				case true:
					this.state = 'completed';
					break;
			}

			this.isInstalling = (this.type === CalendarEntry.TYPES.ACTION && this.hasLabel('install'));
			this.isSurvey = (this.type === CalendarEntry.TYPES.ACTION && this.hasLabel('survey'));
			this.isLeave = (this.type === CalendarEntry.TYPES.EVENT && this.hasLabel('leave'));
			this.isReport = this.hasLabel('quarterly report') || this.hasLabel('report');
			this.isExhibit = this.hasLabel('exhibit');
			this.isReview = this.hasLabel('review');
			this.isMaintenance = this.hasLabel('maintenance');
		} else {
			this.labels = [];
			this.document = null;
			this.state = '';
			this.startAt = new Date();
			const startHour = this.startAt.getHours() + 1;
			this.startAt.setHours(startHour);
			this.startAt.setMinutes(0);
			this.setDurationInMinutes(60);
			this.rag = null;

			console.log('default entry created', this.serialise());
		}
	}

	addLabel(label: string) {
		this.labels.push(label);
	}

	removeLabel(label: string) {
		this.labels = this.labels.filter(existingLabel => label !== existingLabel);
	}

	addUser(user: User) {
		this.users.add(user);
	}

	removeUser(user: User) {
		this.users.remove(user);
	}

	toggleLabel(label: string) {
		if (this.labels.indexOf(label) > -1) {
			this.removeLabel(label);
		} else {
			this.addLabel(label);
		}
	}

	hasLabel(label: string) {
		return !!this.labels.filter(aLabel => aLabel === label).length;
	}

	serialise() {
		const obj: any = {
			id: this.id,
			title: this.title || 'No Title',
			startAt: this.startAt,
			endAt: this.endAt,
			allDay: (this.allDay ? 'Y' : 'N'),
			type: this.type || CalendarEntry.TYPES.ACTION,
			body: this.body,
			labels: this.labels.map(label => label.replace(' ', '_')).join(' '),
			isCompleted: (this.isCompleted ? 'Y' : 'N'),
			context: this.context.serialise(),
			rag: this.rag
		}

		return obj;
	}

	canSave() {
		if (!this.title || this.title.length < 4) {
			return false;
		}

		if (!this.startAt) {
			return false;
		}

		return true;
	}

	/**
	 * Set the end date x minutes after the start date
	 * @param minutes
	 */
	setDurationInMinutes(minutes: number) {
		this.endAt = new Date(this.startAt.getTime() + minutes * 60000);
	}

	setContextFromID(target: string, id: number | string, title: string = '', lat: string = '', long: string = '') {
		this.context = new CalendarContext();
		switch (target) {
			case 'gateway':
				this.setContextObject(new Gateway({ id, title, addressLat: lat, addressLong: long }));
				break;
			case 'building':
				this.setContextObject(new Building({ id, title, addressLat: lat, addressLong: long }));
				break;
			case 'site':
				this.setContextObject(new Site({ id, title, addressLat: lat, addressLong: long }));
				break;
			case 'org':
				this.setContextObject(new Org({ id: +id, title: title }));
				break;
			default:
				console.error(target, id, title);
				break;
		}
	}

	setContextObject(object: Gateway | Site | Org | Building) {
		// If there is no title use the objects title.
		if (!this.title || this.title.length === 0) {
			this.title = object.title;
			if (this.labels.length) {
				this.title = this.labels[0].charAt(0).toUpperCase() + this.labels[0].substr(1) + ' ' + this.title;
			}
		}
		if (object instanceof Gateway) {
			this.context = new CalendarContext('gateway', object);
			return;
		}
		if (object instanceof Site) {
			this.context = new CalendarContext('site', object);
			return;
		}
		if (object instanceof Building) {
			this.context = new CalendarContext('building', object);
			this.mapURL = `${this.mapPrefix}${this.context.object.site.address.addressLat},${this.context.object.site.address.addressLong}&z=7&output=embed`;
			return;
		}
		if (object instanceof Org) {
			this.context = new CalendarContext('org', object);
			return;
		}
		console.error(object);
	}

	isBetween(dt: Date): boolean {
		const dtStartOfDay = new Date(dt.getTime());
		dtStartOfDay.setHours(0);
		dtStartOfDay.setMinutes(0);
		dtStartOfDay.setSeconds(0);
		dtStartOfDay.setMilliseconds(0);

		const checkStartAt = new Date(this.startAt);
		checkStartAt.setHours(0);
		checkStartAt.setMinutes(0);
		checkStartAt.setSeconds(0);
		checkStartAt.setMilliseconds(0);
		const startsOnThisDate = +checkStartAt === +dtStartOfDay;
		if (!this.endAt) {
			// No end date
			return startsOnThisDate;
		}

		const checkEndAt = new Date(this.endAt);
		checkEndAt.setHours(0);
		checkEndAt.setMinutes(0);
		checkEndAt.setSeconds(0);
		checkEndAt.setMilliseconds(0);

		const isBetweenDates = (+checkStartAt <= +dtStartOfDay) && (+checkEndAt >= +dtStartOfDay);

		return (startsOnThisDate || isBetweenDates);
	}
}

<div class="timeline" *ngFor="let timelineItem of alarm.timeline;let i = index">
  <div class="row" *ngIf="timelineItem.action === 'email'">
    <div class="col-md-12">
      <span><i class="fa fa-envelope fa-fw"></i> <i class="fa fa-long-arrow-right"></i> {{timelineItem.value}}</span>
    </div>
  </div>
  <div class="row" *ngIf="timelineItem.action === 'state_change'">
    <div class="col-md-12">
      <span><i class="fa fa-level-down fa-fw"></i> State changed - "{{timelineItem.value}}"</span>
    </div>
  </div>
  <div class="row" *ngIf="timelineItem.action === 'notes'">
    <div class="col-md-12">
      <span><i class="fa fa-pencil-square-o  fa-fw"></i> notes added - "{{timelineItem.value}}"</span>
    </div>
  </div>
</div>
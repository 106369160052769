@defer(when userExtended()) {

@if(isDirty) {
<app-dirty-message message="You have unsaved changes" />
}
<header class="messages">
	@if(!user().hasActivated) {
	<app-info-panel icon="info"> This user has not activated their account via the invite email. </app-info-panel>
	} @else {

	@if(userHasNotWatchedAllTraining()) {
	<!--<app-info-panel icon="info"> This user has not watched all the training videos. </app-info-panel>-->
	}
	}
</header>

<div class="stats">
	<div></div>
	<div>
		<p>Sites</p>
		<span>{{counts()?.sites.checked}}</span> / {{counts()?.sites.total}}
	</div>
	<div>
		<p>Modules</p>
		<span>{{counts()?.modules.checked}}</span> / {{ counts().modules.total }}
	</div>
	<div>
		<p>Training</p>
		<span>{{counts()?.training.watched}}</span> / {{ counts()?.training.total }}
	</div>
	<div></div>
</div>

<section class="user-details">
	<h4>User Details</h4>
	<div>
		<app-data-form [dataForm]="userDataForm" (onDataChange)="dataBlur()" />
	</div>
</section>

@if(userHasNotWatchedAllTraining()) {
<section class="unwatched-training">
	<h4>Training Not completed</h4>
	<app-toolbar>
		<div class="right">
			<app-button icon="mdi mdi-check" label="Mark all as watched" (click)="markAllAsWatched()" />
		</div>
	</app-toolbar>
	<table class="table">
		<thead>
			<tr>
				<th>Title</th>
				<th>Status</th>
			</tr>
		</thead>
		<tbody>
			@for(training of userExtended().training; track training.title) {
			<tr>
				<td>{{training.title}}</td>
				<td>{{training.watched ? 'Watched' : 'Not watched'}}</td>
			</tr>
			}
		</tbody>
	</table>
</section>
}


<section class="user-sites">
	<h4>Sites</h4>
	<app-toolbar>
		<div class="right">
			<app-button icon="mdi mdi-rotate-3d-variant" label="Toggle sites" (click)="toggleSites()" />
		</div>
	</app-toolbar>
	<table class="table table-hover">
		<thead>
			<tr>
				<th>Site</th>
				<th>Address</th>
			</tr>
		</thead>
		<tbody>
			@for(site of sites();track site.id) {
			<tr (click)="toggleSite(site)" [ngClass]="{'checked': site.checked}">
				<td>
					<i class="mdi" [class.mdi-checkbox-marked-outline]="site.checked" [class.mdi-checkbox-blank-outline]="!site.checked"></i>

					{{site.title}}
				</td>
				<td>{{site.address.displayForLine()}}</td>
			</tr>
			}
		</tbody>
	</table>
</section>

<section class="user-modules">
	<h4>Modules</h4>

	<table class="table table-hover">
		<thead>
			<tr>
				<th>Module</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			@for(module of masterModuleList(); track module.label) {
			<tr (click)="toggleModule(module)" [ngClass]="{'checked': module.checked}">
				<td><i class="mdi" [class.mdi-checkbox-marked-outline]="module.checked" [class.mdi-checkbox-blank-outline]="!module.checked"></i>
					@switch(module.label) {
					@case ('profiling') {
					floorplans
					}
					@default {
					{{module.label}}
					}
					}
				</td>
				<td>{{module.tooltip}}</td>
			</tr>
			}
		</tbody>
	</table>
</section>

} @placeholder {
<i class="mdi mdi-loading mdi-spin-x2"></i>
}

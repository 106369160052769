import { Component, Input, OnInit } from '@angular/core';
import { InsightReview } from 'app/classes/insight-review';

@Component({
  selector: 'app-insights-as-user',
  templateUrl: './insights-as-user.component.html',
  styleUrls: ['./insights-as-user.component.css']
})
export class InsightsAsUserComponent implements OnInit {

  @Input()
  insight: InsightReview;
  
  assetIds: string[];

  constructor() { }

  ngOnInit(): void {
    this.assetIds = this.insight.configuration.assets.map(a => String(a.id));
  }

  chartCompleted(a,b) {
    
  }
}

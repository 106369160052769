import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-circle-ring',
  templateUrl: './circle-ring.component.html',
  styleUrls: ['./circle-ring.component.css']
})
export class CircleRingComponent {
  @Input()
  perc: number;

  @Input()
  background: string;

  @Input()
  strokeWidth = 12;
}

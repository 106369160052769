import { Component, signal } from '@angular/core';
import { Building } from 'app/classes/building';
import { BuildingsService, IBuildingReviewListItem } from 'app/shared/buildings.service';
import { BuildingReview } from "app/classes/building-review";
import { APIService } from 'app/shared/api.service';
import { BuildingReviewConfigItem } from 'app/classes/building-review-config';
import { DocumentsService } from 'app/shared/documents.service';
import { Document } from "app/classes/document";
import { Site } from 'app/classes/site';

@Component({
  standalone: false,
  selector: 'app-insights-reviews-landing',
  templateUrl: './insights-reviews-landing.component.html',
  styleUrl: './insights-reviews-landing.component.css'
})
export class InsightsReviewsLandingComponent {
  building = signal<Building>(null);
  buildings = signal<Building[]>(null);
  dialogOpen = signal<'buildings' | 'create' | 'help' | 'collections'>(null);
  localStorageBuildingId: number;
  tabIndex = signal<number>(0);
  buildingReviewConfig = signal<BuildingReviewConfigItem>(null);
  // Review list items
  reviewsListItems = signal<IBuildingReviewListItem[]>(null);
  // Full reviews to view or update
  reviewItems = signal<BuildingReview[]>([]);
  noOfMonths = 3;
  message = signal<string>('');
  documents = signal<Document[]>(null);
  searchFor: string;
  hasUpdatedConfiguration = false;

  collections = signal<any[]>(null);

  filteredBuildings = signal<Building[]>(null);
  isFirstTimeUsing = signal(false);
  site = signal<Site>(null);
  isLoadingBuilding = signal<boolean>(false);
  can = { admin: false };

  constructor(private buildingService: BuildingsService, private apiService: APIService, private documentService: DocumentsService) {
    this.can.admin = this.apiService.isAdmin();
    this.getLocalStorage();
    this.buildingService.getBuildings().then(buildings => {
      this.buildings.set(buildings);

      if (this.localStorageBuildingId) {
        this.getBuilding(this.localStorageBuildingId);
      }
      this.filter();
    });


    const storageString = localStorage.getItem(`org:${this.apiService.getUserOrg().id}:insights:assets:list`);
    if (storageString) {
      const storageObject = JSON.parse(storageString);
      this.searchFor = storageObject.searchFor;
    }
  }

  async getDocumentsForBuilding() {
    const building = await this.documentService.getForBuilding(this.buildingReviewConfig().building.id);
    this.documents.set(building.documents);
  }

  async clickUpdateMonthsAndNotes() {
    this.apiService.toastSuccess('Updating', '');
    this.dialogOpen.set(null);
    await this.buildingService.updateMonthsAndNotesForReview(this.buildingReviewConfig().building.id, this.buildingReviewConfig().noOfMonths, this.buildingReviewConfig().notes, this.buildingReviewConfig().review.next.start);
    this.apiService.toastSuccess('Updated', '');
    this.loadReviewsForBuilding();
  }

  async collectionClick(collectionId: number) {
    this.apiService.toastSuccess('Updating', '');
    this.dialogOpen.set(null);
    await this.buildingService.updateCollectionForReview(this.buildingReviewConfig().building.id, collectionId);
    this.apiService.toastSuccess('Updated', '');
    this.loadReviewsForBuilding();
  }

  getBuilding(buildingId: number) {
    this.isLoadingBuilding.set(true);
    this.buildingService.getBuilding(buildingId).then(building => {
      this.building.set(building);
      this.loadReviewsForBuilding();
      this.isLoadingBuilding.set(false);
    });
  }

  unloadReview(index: number) {
    this.reviewItems.update(items => {
      items = items.filter((review, arrayIndex) => arrayIndex !== index);

      return [...items];
    });

    this.tabIndex.set(this.reviewItems().length + 1);
  }

  createReviewClick() {
    this.noOfMonths = this.buildingReviewConfig().noOfMonths;
    this.dialogOpen.set('create');
    this.setMessage();
  }

  reviewOnClose(index: number) {
    //Unload review
    this.unloadReview(index);
    // load reviews
    this.loadReviewsForBuilding();
    this.tabIndex.set(0);
  }

  setMessage() {
    const msg = this.buildingReviewConfig()?.review?.next.start.toLocaleDateString() + ' to ' + this.buildingReviewConfig()?.review?.next.end.toLocaleDateString();
    this.message.set(msg);
  }

  monthChange(date: Date) {
    this.buildingReviewConfig.update(config => {
      config.review.next.start = date;

      return config;
    });

    this.setMessage();
  }

  nextReviewDateChange(date: Date) {
    this.buildingReviewConfig().setNextReviewDate(date);
  }

  setMonths(noOfMonths: number) {
    this.noOfMonths = noOfMonths;
    this.buildingReviewConfig.update(config => {
      config.noOfMonths = noOfMonths;
      const end = new Date(config.review.next.start);
      end.setMonth(config.review.next.start.getMonth() + noOfMonths);
      end.setDate(0);
      config.review.next.end = end;

      return config;
    });

    this.setMessage();
  }

  reviewClick(review: IBuildingReviewListItem) {
    if (review.state === 'done' && !review.documentKey) {
      this.apiService.toastWarn('Version 1 Review', 'only Version 2');
      return;
    }

    if (!this.buildingReviewConfig()?.collection) {
      this.apiService.toastWarn('No collection', '');
      return;
    }
    const open = this.reviewItems().findIndex(item => item.building.id === this.building().id && item.review.dates.start === review.reviewDate);

    if (open >= 0) {
      this.tabIndex.set(2 + open);
      return;
    }

    this.reviewItems.update(items => {
      const end = new Date(review.reviewDate);
      end.setMonth(review.reviewDate.getMonth() + review.noOfMonths);
      end.setDate(0);
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      items.push(new BuildingReview({ building: this.building(), collection: this.buildingReviewConfig().collection, user: review.createdBy, start: review.reviewDate, end, state: review.state, rag: review.rag }));

      return [...items];
    });

    this.tabIndex.set(this.reviewItems().length + 1);
  }

  reviewMonthClick(start: Date) {
    this.dialogOpen.set(null);
    this.buildingReviewConfig.update(config => {
      if (start) {
        config.review.next.start = start;
      }
      config.noOfMonths = this.noOfMonths;
      const end = new Date(config.review.next.start);
      end.setMonth(config.review.next.start.getMonth() + config.noOfMonths);
      end.setDate(0);
      config.review.next.end = end;

      return config;
    });

    this.createReview();
  }

  createReview() {
    if (this.reviewItems().some(review => review.title === 'New Review')) {
      this.apiService.toastWarn('Only one open New Review', '');
      // Only one new item open at a time
      return;
    }

    this.reviewItems.update(items => {
      items.push(new BuildingReview({ isNew: true, building: this.building(), collection: this.buildingReviewConfig().collection, user: { id: this.apiService.getUserId(), name: this.apiService.getThisUser().name }, start: this.buildingReviewConfig().review.next.start, end: this.buildingReviewConfig().review.next.end, state: 'new' }));

      return [...items];
    });

    this.tabIndex.set(this.reviewItems().length + 1);
  }

  loadReviewsForBuilding() {
    if (!this.building()) {
      return;
    }

    this.buildingService.getReviewsForBuilding(this.building().id).then(reviews => {
      if (this.can.admin) {
        this.reviewsListItems.set(reviews);
      } else {
        // Users cant see V1 here
        this.reviewsListItems.set(reviews.filter(review => review.state !== 'done' || (review.state === 'done' && review.documentKey)));
      }

      this.buildingService.getReviewConfigForBuilding(this.building().id).then(config => {
        this.buildingReviewConfig.set(config);
        if (!config.collection?.id) {
          // No collection
          this.tabIndex.set(1);
        }
      });
    });
  }

  buildingChange(building: Building) {
    this.dialogOpen.set(null);
    if (!building) {
      return;
    }

    this.buildingReviewConfig.set(null);
    setTimeout(() => {

      this.setLocalStorage(building);
      this.getBuilding(building.id);
    }, 100);
  }

  setLocalStorage(building: Building = null) {
    let id;
    if (!building) {
      id = this.building().id;
    } else {
      id = building.id;
    }
    localStorage.setItem(`org:${this.apiService.getUserOrg().id}:insights:reviews:config`, JSON.stringify({ building: { id } }));
    this.isFirstTimeUsing.set(false);
  }

  async changeCollectionClick() {
    const collections = await this.buildingService.getCollections(this.building().id, 'reporting');
    this.collections.set(collections.collections);
    this.dialogOpen.set('collections');
  }

  getLocalStorage() {
    try {
      const config = JSON.parse(localStorage.getItem(`org:${this.apiService.getUserOrg().id}:insights:reviews:config`));
      if (config) {
        this.localStorageBuildingId = config.building.id || null;
      } else {
        this.isFirstTimeUsing.set(true);
      }
    } catch (e) {
      console.log(e);
    }
  }

  searchChanged(searchText: string) {
    this.searchFor = searchText;
    localStorage.setItem(`org:${this.apiService.getUserOrg().id}:insights:assets:list`, JSON.stringify({ searchFor: this.searchFor }));
    this.filter();
  }

  filter() {
    if (!this.searchFor || this.searchFor.length < 2) {
      this.filteredBuildings.set(this.buildings());
      return;
    }
    this.filteredBuildings.set(this.buildings().filter(r => {
      if (r.title.toLowerCase().includes(this.searchFor)) return true;
    }));
  }
}

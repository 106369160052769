<article [class.fullscreen]="isMaximised">
	@if("dashboard") {
	<header>
		<app-toolbar>
			@if(isAdmin()) {
			<app-button [isMuted]="true" icon="mdi mdi-shield-account-outline" label="rebuild" (click)="wantsToRebuild()"></app-button>
			}
			<div class="right">
				@if(settings?.tenants.length) {
				<app-button-site-plan-dashboard-config [value]="{tenantId:null, dates:datesFromTo}" [tenants]="settings.tenants" (onSelect)="toolbarGo($event)" />
				}
				<app-button label="Export" icon="mdi mdi-file" (click)="export()" />
				<app-button label="Previous month" icon="mdi mdi-rewind" (click)="previousMonth()" />
				<app-button label="This month" icon="mdi mdi-calendar-today" (click)="today()" />
				<app-button label="Next month" icon="mdi mdi-fast-forward" (click)="nextMonth()" iconPos="right" />
			</div>
		</app-toolbar>
	</header>
	@if(site) {
	<app-spd-occ-monthly [site]="site" (onAssetsReceived)="assetsReceived($event)"></app-spd-occ-monthly>
	} @else {
	<app-info-panel>No site selected</app-info-panel>
	}
	} @else {
	<i class="mdi mdi-loading mdi-spin-x2"></i>
	}
	@switch (isShowingDialog()) {
	@case('rebuild') {
	<app-dialog header="Rebuild assets" (onClose)="isShowingDialog.set(null)">
		<app-info-panel>Rebuild all assets for this dashboard from 01/01/2024 to today, please allow about 5+ seconds per asset.</app-info-panel>
		<div class="text-center mb-1 mt-1" (click)="startRebuild()">
			@if(!isRebuilding()) {
			<app-button label="Start rebuild now" />
			}
		</div>
		@if (assetsToRebuild()?.length) {
		<div class="progress">Progress</div>
		<div class="squares">
			@for(asset of assetsToRebuild(); track asset.id) {
			<span class="{{asset.state}}"></span>
			}
		</div>
		} @else {
		<p>No assets to rebuild</p>
		}
	</app-dialog>
	}

	}

</article>

<div class="box has-viewed-{{module.viewedAt}}" [class.is-viewable]="module.isViewable"
  [class.is-not-viewable]="!module.isViewable">
  <div class="module-image"><img [src]="module.image"></div>
  <div class="module-text">
    <div>
      <h3>{{module.title}}</h3>
      <p>{{module.body}}</p>
      <div *ngIf="module.isCompleted" class="viewed" title="You have viewed this module"><i
          class="mdi mdi-check-circle"></i></div>
      <div *ngIf="module.state==='new'" class="new" title="New module!"><i class="fa fa-asterisk"></i> new</div>
    </div>
    <div class="locked-overlay">&nbsp;</div>
    <div class="locked">
      Locked until the <span (click)="watch(nextModule)">{{nextModule?.title}}</span> has been watched.
    </div>
  </div>
</div>

<article>
    <section>
        <img src="https://stage4d.wpengine.com/wp-content/uploads/2017/11/4D-logo_footer.png" alt="4D Monitoring">
    </section>
    <section>
        <nav>
            <ul>
                <li>
                    <!--Product-->
                </li>
            </ul>
        </nav>
    </section>
</article>
<table class="table">
	<thead>
		<tr>
			<th></th>
			<th class="mon">Mon</th>
			<th class="tue">Tue</th>
			<th class="wed">Wed</th>
			<th class="thu">Thu</th>
			<th class="fri">Fri</th>
			<th class="sat">Sat</th>
			<th class="sun">Sun</th>
		</tr>
	</thead>
	<tbody *ngIf="data">
		<tr>
			<td>W/C {{config.startAt | amDateFormat:apiService.getDateFormat()}}</td>
			<td *ngFor="let item of data[0]; let i=index" class="day-{{i}}">
				<div *ngIf="item.type" class="bb">
					<div class="weather-icons" title="{{item.type}}">{{item.icon}}</div>

					<div class="temp" title="{{item.temp}}">{{item.temp}}</div>
				</div>
			</td>
		</tr>
		<tr>
			<td>W/C {{config.previousStartAt | amDateFormat:apiService.getDateFormat()}}</td>
			<td *ngFor="let item of data[1]; let i=index" class="day-{{i}}">
				<div *ngIf="item.type" class="bb">
					<div class="weather-icons" title="{{item.type}}">{{item.icon}}</div>

					<div class="temp" title="{{item.temp}}">{{item.temp}}</div>
				</div>
			</td>
		</tr>
	</tbody>
</table>

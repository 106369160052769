
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { ICalendarDay } from '../../../layout/calendar/calendar.component';
import { Site } from '../../../classes/site';
import moment from 'moment';

@Component({
  selector: 'app-button-yourdashboard-config',
  templateUrl: './button-yourdashboard-config.component.html',
  styleUrl: './button-yourdashboard-config.component.css'
})
export class ButtonYourdashboardConfigComponent {
  readonly DEFAULT_LABEL = 'Configure report';

  _onDialog = signal<boolean>(false);
  label = signal<string>(this.DEFAULT_LABEL);
  _config = signal<IYourdashboardConfig>({ siteId: null, dates: { from: new Date(), to: new Date() } });
  _caption = signal<string>('');
  _sites = signal<Site[]>([]);

  @Input()
  public set caption(v: string) {
    this._caption.set(v);
  }

  @Input()
  public set value(v: IYourdashboardConfig) {
    console.log(`set config`, v);
    this._config.set(v);

    this.changed();
  }

  @Input()
  public set sites(v: Site[]) {
    this._sites.set(v);
  }

  @Output()
  onSelect = new EventEmitter<IYourdashboardConfig>();

  @Input()
  size = signal<string>('medium');

  constructor() {

  }

  closed(event: any) {
    this.onSelect.emit(null);
    this._onDialog.set(false);
    this.buildLabel();
  }

  buildLabel() {

    if (this._config()) {
      const dates = this._config().dates;
      const from = dates.from ? moment(dates.from).format('DD/MM/YYYY') : null;
      const to = dates.to ? moment(dates.to).format('DD/MM/YYYY') : null;
      const s = this._config().siteId;
      let pre = '';
      if (s) {
        const site = this._sites().find(site => site.id === +s) || new Site();
        pre = site.id ? site.title + ', ' : 'All sites, ';
      }
      if (from === null || to === null) {
        this.label.set(`${pre} Select Dates`);
      } else {
        this.label.set(`${pre} ${from} to ${to}`);
      }
    } else {
      this.label.set(this.DEFAULT_LABEL);
    }
  }

  ngOnDestroy(): void {

  }

  changed() {

    if (this._config().dates.to && this._config().dates.from) {
      this.onSelect.emit(this._config());
    }

    this.buildLabel();
  }

  changeDate(part: 'from' | 'to', day: ICalendarDay) {
    console.log(part, day);
    this._config.update(obj => {
      obj.dates[part] = day.date;
      return obj;
    });
    this.changed();
  }

  clear() {
    this._config.set({ siteId: null, dates: { from: new Date(), to: new Date() } });
  }

  save() {
    this.onSelect.emit(this._config());
    this._onDialog.set(false);
    this.buildLabel();
  }

  siteSelected(ev: any) {
    console.log(ev);
    this._config.update(item => {
      item.siteId = ev;
      return item;
    });
    this.buildLabel();
  }
}

export interface IYourdashboardConfig {
  siteId: number | null;
  dates: { from: Date, to: Date };
}

<div class="row">

  <div class="col-xs-12 col-sm-12">
    <div class="form-group">
      <label for="addressTitle">{{titleLabel}}</label>
      <input id="addressTitle" name="addressTitle" class="form-control" type="text" [(ngModel)]="address.addressTitle" (focus)="focus('addressTitle')">
    </div>
  </div>

  <div class="col-xs-12 col-sm-12">
    <div class="form-group">
      <label for="address1">Address Line 1</label>
      <input id="address1" name="address1" class="form-control" type="text" [(ngModel)]="address.address1" (focus)="focus('address1')">
    </div>
  </div>
  <div class="col-xs-12 col-sm-12">
    <div class="form-group">
      <label for="address2">Address Line 2</label>
      <input id="address2" name="address2" class="form-control" type="text" [(ngModel)]="address.address2" (focus)="focus('address2')">
    </div>
  </div>
  <div class="col-xs-12 col-sm-12">
    <div class="form-group">
      <label for="address3">Address Line 3</label>
      <input id="address3" name="address3" class="form-control" type="text" [(ngModel)]="address.address3" (focus)="focus('address3')">
    </div>
  </div>

  <div class="col-xs-6 col-sm-6">
    <div class="form-group">
      <label for="addressPostcode">Address Postcode</label>
      <input (blur)="checkPostcode()" id="addressPostcode" name="addressPostcode" class="form-control" type="text" [(ngModel)]="address.addressPostcode" (focus)="focus('addressPostcode')">
    </div>
  </div>

</div>
<div class="row">

  <div class="col-xs-12 col-sm-6">
    <div class="form-group">
      <label for="addressTown">Address Town</label>
      <input id="addressTown" name="addressTown" class="form-control" type="text" [(ngModel)]="address.addressTown" (focus)="focus('addressTown')">
    </div>
  </div>
  <div class="col-xs-6 col-sm-6">
    <div class="form-group">
      <label for="addressCounty">Address County</label>
      <input id="addressCounty" name="addressCounty" class="form-control" type="text" [(ngModel)]="address.addressCounty" (focus)="focus('addressCounty')">
    </div>
  </div>

  <div class="col-xs-6 col-sm-6">
    <div class="form-group">
      <label for="addressLat">Latitude </label>
      <input id="addressLat" name="addressLat" class="form-control" type="text" [(ngModel)]="address.addressLat" (focus)="focus('addressLat')">
    </div>
  </div>

  <div class="col-xs-6 col-sm-6">
    <div class="form-group">
      <label for="addressLong">Longitude</label>
      <input id="addressLong" name="addressLong" class="form-control" type="text" [(ngModel)]="address.addressLong" (focus)="focus('addressLong')">
    </div>
  </div>
</div>
<div class="row" *ngIf="canSave">
  <div class="col-md-12">
    <button (click)="submitAddress()" class="btn btn-success">Submit</button>&nbsp;
    <button (click)="cancelAddress()" class="btn btn-secondary">Cancel</button>
  </div>
</div>

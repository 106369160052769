<!-- Asset View -->
<div class="row" *ngIf="asset && asset.id" [ngClass]="{'orientation-landscape':orientation === 'landscape'}">
  <div [ngClass]="{'col-xs-6':orientation === 'landscape'}">
    <div class="view card" *ngIf="styling === 'card'">
      <div class="col-xs-12 title">
        {{asset.title}}
      </div>
    </div>
    <div class="view" *ngIf="styling === 'default'">

      <div class="col-xs-12">
        <app-field-view [id]="asset.id" [value]="asset.title" [title]="'Title'" property="asset.title"
          [orgId]="asset.gateway.site.org.id">
        </app-field-view>
      </div>

      <div class="col-xs-12">
        <app-field-view [id]="asset.id" [value]="asset.assetType_id" [title]="'Type'" property="asset.assetType_id"
          [orgId]="asset.gateway.site.org.id">
        </app-field-view>
      </div>

      <div class="col-xs-12">
        <app-field-view [id]="asset.id" [value]="asset.location" [title]="'Location'" property="asset.location"
          [orgId]="asset.gateway.site.org.id">
        </app-field-view>
      </div>

      <div class="col-xs-12">
        <div><span>Identifier</span><span class="pull-right">{{asset.identifier}}</span></div>
      </div>
      <div class="col-xs-12">
        <div><span>Status</span><span class="pull-right">{{asset.status}}</span></div>
      </div>
      <div class="col-xs-12">
        <div><span>Updated</span><span class="pull-right">{{asset.updatedAt |
            amDateFormat:apiService.getDateTimeFormat()}}</span></div>
      </div>
      <!--
             <div class="col-xs-12" *ngIf="asset.satelliteTitle">
        <div><span>Satellite</span><span class="pull-right">{{asset.satelliteTitle}}</span></div>
      </div>
    -->
      <div class="col-xs-12 image" *ngIf="image">
        <div>
          <img class="img-rounded img-responsive" src="{{image}}">
          <div (click)="requestImage()" class="image-controls"><i class="fa fa-camera"></i></div>
        </div>
        <span class="pull-right"><a href="{{image}}">{{image}}</a></span>
      </div>
    </div>
  </div>
  <div [ngClass]="{'col-xs-12':orientation === 'portrait', 'col-xs-6':orientation === 'landscape'}">
    <app-asset-monitor [asset]="asset" [notitle]="styling === 'telemetry'" [fullscreen]="fullscreen">
    </app-asset-monitor>
  </div>

  <div class="col-xs-12"><small>&nbsp;&nbsp;&nbsp;#{{asset.id}}</small></div>

</div>

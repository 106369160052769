export class ChartPin {
	date: Date;
	text: string;
	size: number;
	fill: string;
	stroke: string;

	constructor(data?: ChartPinInterface) {
		if (data) {
			this.date = typeof data.date === 'string' ? new Date(data.date) : data.date;
			this.text = data.text;
			this.stroke = data.stroke;
			this.size = data.size;
			this.fill = data.fill;
		}
	}
}

export interface ChartPinInterface {
	date: Date | string;
	text: string;
	size: number;
	fill: string;
	stroke: string;
}

<main class="content-container">
	<h1>Gateway assets</h1>

	<div class="tabview-list">
		<div [class.active]="tabIndex()===0" (click)="tabIndex.set(0)">
			<i class="mdi mdi-check"></i> Compatible sensors
		</div>
		<div [class.active]="tabIndex()===1" (click)="tabIndex.set(1)">
			<i class="mdi mdi-view-list"></i> All Sensors
		</div>
		<div></div>
	</div>


	@switch(tabIndex()) {

	@case(0) {
	<app-toolbar>
		<div clas="right">
			<app-button label="Use these sensors" (click)="clickUseTheseSensors()" />
		</div>
	</app-toolbar>
	<table class="table" aria-label="Assets">
		<thead>
			<tr>
				<th>Asset</th>
				<th>Type</th>
			</tr>
		</thead>
		<tbody>
			@for(asset of filtered();track asset.id) {
			<tr>
				<td>{{asset.title}}</td>
				<td>{{asset.assetTypeTitle}}</td>
			</tr>
			}
		</tbody>
	</table>
	}

	@default {
	<table class="table" aria-label="Assets">
		<thead>
			<tr>
				<th>Asset</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			@for(asset of assets();track asset.id) {
			<tr>
				<td>{{asset.title}}</td>
				<td>{{asset.assetTypeTitle}}</td>
			</tr>
			}
		</tbody>
	</table>
	}


	}


</main>

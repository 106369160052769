import { Component, OnInit } from '@angular/core';
import { ShapeCategories } from 'app/classes/shape-categories';
import { APIService } from 'app/shared/api.service';
import { WindowService } from 'app/shared/window.service';

@Component({
  selector: 'app-profile-cats-list',
  templateUrl: './profile-cats-list.component.html',
  styleUrls: ['./profile-cats-list.component.css']
})
export class ProfileCatsListComponent implements OnInit {
  defaultTab = 0;
  sort: any = {};
  cats: ShapeCategories;
  groups: {};
  isMobile: boolean;

  constructor(private windowService: WindowService, private apiService: APIService) {

    this.apiService.getAllSiteFloorplans()
      .then(plansAndCats => {
        this.cats = plansAndCats.cats;
        this.buildGroups();
        this.sortListing();
      });
    try {
      const sort = localStorage.getItem('floorplans:areas:list:sort');
      if (sort) {
        this.sort = JSON.parse(sort);
      }
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit(): void {
    this.isMobile = this.windowService.isMobile();
  }

  buildGroups() {
  }

  handleTabChange(event) {
  }

  headerClicked(field: string) {
    let state = this.sort[field];
    // Only one field can be sorted
    this.sort = {};
    switch (state) {
      case 'asc':
        state = 'desc';
        break;
      case 'desc':
        state = null;
        break;
      default:
        state = 'asc';
        break;
    }
    this.sort[field] = state;

    localStorage.setItem('floorplans:areas:list:sort', JSON.stringify(this.sort));
    this.sortListing();
  }

  sortListing() {
    this.cats.listing.sort((a, b) => {
      if (this.sort.site) {
        const d = this.sort.site === 'asc' ? 1 : -1;
        return a.siteTitle > b.siteTitle ? d : d * -1;
      }
      if (this.sort.sensor) {
        const d = this.sort.sensor === 'asc' ? 1 : -1;
        return a.assetTitle > b.assetTitle ? d : d * -1;
      }
      if (this.sort.value) {
        const d = this.sort.value === 'asc' ? 1 : -1;
        return a.assetValue > b.assetValue ? d : d * -1;
      }
      if (this.sort.area) {
        const d = this.sort.area === 'asc' ? 1 : -1;
        return a.category > b.category ? d : d * -1;
      }

      return a.assetTitle > b.assetTitle ? 1 : -1;
    });
  }
}

<div class="item-container" *ngIf="review">
  <div class="perc" title="{{review.perc}}">
    <svg viewBox="0 0 36 36">
      <svg:path [attr.d]="review.svg.d" fill="none" stroke="#e0e0e0" stroke-width="3" />
      <svg:path class="progress-arc" [attr.d]="review.svg.d" fill="none" stroke="#019FE6" stroke-width="4"
        [attr.stroke-dasharray]="review.svg.strokeDashArray" />
      <svg:text text-anchor="middle" x="19" y="21.5">{{review.perc}}%</svg:text>
    </svg>
  </div>
  <div class="title"> <span>{{review.title}}</span>
    <small>{{review.counts.findings}} of {{review.counts.assets}} assets completed</small>
  </div>
  <div class="created-at isDesktop">
    <div>Generated</div>
    {{review.createdAt | amTimeAgo }}
  </div>
</div>

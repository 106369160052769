<p-chart *ngIf="chartData" type="bar" [data]="chartData">
</p-chart>

<div class="table-responsive">
	<table *ngIf="chartData" class="table table-sm">
		<thead class="thead-inverse inverse-grey">
			<tr>
				<th></th>
				<th>Mon</th>
				<th>Tue</th>
				<th>Wed</th>
				<th>Thu</th>
				<th>Fri</th>
				<th>Sat</th>
				<th> Sun</th>
				<th> Week Total</th>
			</tr>
		</thead>
		<tbody *ngIf="data">
			<tr *ngFor="let week of chartData.datasets; let i=index">
				<td><span *ngIf="i">Previous</span> <span *ngIf="!i">This</span> Week</td>
				<td *ngFor="let item of week.data; let i=index">
					{{item}}
				</td>
				<td>{{week.total}}</td>
			</tr>
			<tr>
				<td>% change</td>
				<td *ngFor="let item of chartData.datasets[1].perc; let i=index" [class.positive]="item>=0"
					[class.negative]="item < 0">
					<span>
						<app-perc-change [value]="item"></app-perc-change>
					</span>
				</td>
				<td [class.positive]="chartData.percTotalChange >= 0" [class.negative]="chartData.percTotalChange < 0">
					<app-perc-change [value]="chartData.percTotalChange"></app-perc-change>
				</td>
			</tr>
		</tbody>
	</table>

</div>
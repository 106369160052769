<table class="table">
	<thead>
		<tr>
			<th>For</th>
			<th>Action</th>
			<th>Date</th>
			<th>User</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let item of audit">
			<td *ngIf="item.bTitle">
				<span pTooltip="Building #{{item.bId}}"><i class="mdi mdi-office-building-outline"></i></span>
				{{item.bTitle}}
			</td>
			<td *ngIf="item.gTitle">
				<span pTooltip="Gateway #{{item.gId}}"><i class="mdi mdi-router-wireless"></i></span>
				{{item.gTitle}}
			</td>
			<td>{{item.action}}</td>
			<td>{{item.createdAt | amDateFormat:'DD MMM YYYY HH:mm:ss'}}</td>
			<td>{{item.uName}}</td>
		</tr>
	</tbody>
</table>

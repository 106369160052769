@defer(when gateways) {
<div class="col-xs-12">
  <div class="component-list">
    <table class="table table-sm table-hover" aria-label="Gateways">
      <thead>
        <tr>
          <th></th>
          <th>Gateway</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        @for(gateway of gateways;track gateway.id) {
        <tr>
          <td (click)="favGateway(gateway)">
            <i class="fa" [class.fa-star]="gateway.isFav" [class.fa-star-o]="!gateway.isFav"></i>
          </td>
          <td (click)="selectGateway(gateway)">{{gateway.title}} </td>
          <td (click)="selectGateway(gateway)">{{gateway.location}}</td>
        </tr>
        }
      </tbody>
    </table>
  </div>
</div>
} @placeholder {
<i class="mdi mdi-loading mdi-spin-x2"></i>
}

import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-cms-versions',
  templateUrl: './cms-versions.component.html',
  styleUrls: ['./cms-versions.component.css']
})
export class CmsVersionsComponent implements OnInit, AfterViewInit {

  @ViewChild('subtitle', { static: true })
  subtitle: ElementRef;
  @ViewChild('features', { static: true })
  features: ElementRef;

  featuresOn: boolean;
  subtitlesOn: boolean;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.subtitlesOn = true;
    });
    setTimeout(() => {
      this.featuresOn = true;
    }, 1500);
  }

  constructor() { }

  ngOnInit() {
  }

}

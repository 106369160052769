import { Component, OnInit } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { StoreService } from '../../shared/store.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.css']
})
export class AcceptTermsComponent implements OnInit {

	constructor(private router: Router, private apiService: APIService, private route: ActivatedRoute, private storeServive: StoreService) {
    storeServive.setIsLoading(false);
  }
  
  ngOnInit() {
  }

  acceptedTerms() {
    this.apiService.approvedTermsAndConditions();
    this.router.navigate(['/dashboard']);
  }

}

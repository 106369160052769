export class Widget {
    id: number;
    tag: string;
    /* Tile title */
    title: string;
    /* Details title */
    behindTitleTitle: string;
    data: any;
    type: string;
    state: string;
    dirty: boolean;
    dirtyCount: number = 0;
    alert: string;
    selected: boolean;
    style: any;
    perc: number;
    hidden: boolean;
    clickable: boolean;
    hiddenIfZero: boolean;

    constructor(tag: string, title: string, data: any, colour: string, behindTileTitle?: string, hidden?: boolean, hiddenIfZero?: boolean) {
        this.tag = tag;
        this.title = title;
        this.data = data;
        this.state = 'available';
        this.alert = 'clean';
        this.style = { colour: colour };
        this.behindTitleTitle = behindTileTitle || this.title;
        this.hidden = hidden;
        this.clickable = true;
        this.hiddenIfZero = hiddenIfZero;
    }

    setData(value: any, perc?: any) {
        if (this.data) {
            if (this.data.count !== value) {
                if (!this.dirty) {
                    this.dirty = true;

                    this.alert = 'dirty';
                    console.log(this.dirtyCount);
                }
                this.dirtyCount++;
            }
            this.data = { count: value };
        } else {
            this.data = { count: value };
        }

        if (perc) {
            this.perc = perc;
            console.log(this.perc);
        }

        return this.dirty;
    }

    setDirty(value: boolean) {
        if (!value) {
            this.dirty = false;
            this.dirtyCount = 0;
            this.alert = 'clean';
        } else {
            this.dirty = true;
            this.dirtyCount++;
            this.alert = 'dirty';
        }
        console.log(`count:${this.dirtyCount} ${this.dirty}`);
    }

    setState(value: string) {
        this.state = value;
    }
}



<main>
	<section>

		<div class="rule-title" [class.one-row]="!assets.right">
			<div>
				<div *ngIf="assets.left">
					<span class="asset-icon">
						<app-asset-icon [asset]="assets.left"></app-asset-icon>
					</span> {{assets.left.title}}
					<!--<span class="live-value"
						pTooltip="Current sensor value">{{assets.left.value}}</span>-->
				</div>
				<div *ngIf="assets.right">
					<span class="asset-icon">
						<app-asset-icon [asset]="assets.right"></app-asset-icon>
					</span> {{assets.right.title}}
					<!--<span class="live-value"
						pTooltip="Current sensor value">{{assets.right.value}}</span>-->
				</div>
			</div>
		</div>

		<div class="rule-operand">
			<div>
				<span>{{operand}}</span>

			</div>
		</div>

		<div class="rule-value">
			<div>
				<span pTooltip="Rule value" tooltipPosition="left">{{rightValue}}</span>
				<!--<span class="live-value" pTooltip="Current value for the sensor">({{liveAsset?.value}})</span>
				<p>Updated {{liveAsset?.updatedAt | amTimeAgo}} <span *ngIf="liveAsset">(No asset)</span></p>-->
			</div>
		</div>

	</section>
</main>
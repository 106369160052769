@defer(when asset) {
<div class="asset-view asset-type-{{asset().assetType}} rounded theme-{{theme}}">

    <div class="asset-container telemetry-row" (click)="assetClick()" [ngClass]="{'rag-amber': asset().rag === 'amber' || asset().rag === 'orange', 'rag-red': asset().rag === 'red', 'rag-green': asset().rag === 'green' }">

        <div class="asset-icon">
            <app-asset-icon [asset]="asset()"></app-asset-icon>
        </div>

        <div class="asset-title">
            <div>{{asset().title}}</div>
            <div title="{{asset().siteTitle}}">{{asset().siteTitle}}</div>
            <div>
                <small [class.over-one-day]="asset().daysOld >=1" pTooltip="{{asset().updatedAt | amDateFormat:'DD-MM-YYYY HH:mm'}}">{{asset().updatedAt |
                    amTimeAgo}}</small>
            </div>
        </div>

        <div class="asset-values">
            <div class="asset-value-container">
                <div class="asset-value asset-type-{{asset().assetType}}">
                    @if(asset().assetType !== 43 && asset().assetType !== 44) {
                    <span>{{displayValue(asset())}}</span>
                    } @else if (asset().assetType === 43 || asset().assetType === 44) {
                    <p-image src="https://s3.eu-west-2.amazonaws.com/4d-assets/{{asset().id}}/images/{{asset().value}}" alt="Image" width="100" [preview]="true"></p-image>
                    }
                    @if(asset().assetType==2 || asset().assetType==9 || asset().assetType==19 || asset().assetType==41 || asset().assetType==47) {
                    <span>
                        <sup>o</sup>
                    </span>
                    } @else if (asset().assetType==8 || asset().assetType == 67) {
                    <span>%</span>
                    } @else if(asset().assetType!==2&&asset().assetType!==8&&asset().assetType!==9&&asset().assetType!==19&&asset().assetType!==41&&asset().assetType!==47)
                    {
                    <span>&nbsp;</span>
                    }
                </div>
            </div>


            <div class="asset-min-max" *ngIf="asset().min !==null || asset().max !== null" title="min/max">
                {{asset().min}}/{{asset().max}}
            </div>
        </div>

    </div>
</div>
} @placeholder( minimum 100ms) {
<div class="asset-view rounded"> ... </div>
}

@defer(when compliance) {
<app-toolbar>
	<div class="right">
		<app-button label="Add new collection" icon="mdi mdi-plus" (click)="dialogShowing.set('New Collection')"></app-button>
	</div>
</app-toolbar>


@if(compliance.groups.length) {
<table class="table table-hover" [hidden]="complianceItem()">
	<thead>
		<tr>
			<th>Title</th>
			<th>Frequency</th>
		</tr>
	</thead>
	<tbody>
		@for(group of compliance.groups; track group.id) {
		<tr (click)="clickComplianceGroup(group)">
			<td>{{group.title}}</td>
			<td>{{group.frequency}}</td>
		</tr>
		}
	</tbody>
</table>
@if(complianceItem()) {
<app-org-compliance-item [complianceItemId]="complianceItem().id" (onGoBack)="complianceItem.set(null)"></app-org-compliance-item>
}
} @else {
<h5>
	No compliance setup.
</h5>
}


} @placeholder(minimum 100ms) {
<span><i class="mdi mdi-loading mdi-spin-x2"></i> Please wait...</span>
}


@switch(dialogShowing()) {
@case('New Collection') {
<app-dialog (onClose)="dialogShowing.set(null)">
	<app-org-compliance-add (hasSaved)="get()"></app-org-compliance-add>
</app-dialog>
}

}

<div class="tabview-list">
	<div [class.active]="tabIndex()===0" (click)="tabIndex.set(0)">
		<i class="mdi mdi-account"></i>Tenants
	</div>
	<div [class.active]="tabIndex()===1" (click)="tabIndex.set(1)">
		<i class="mdi mdi-account-wrench"></i>Services
	</div>
	<div></div>
</div>
@switch(tabIndex()) {
@case(0) {
<app-toolbar>
	<div class="right">
		<app-button size="medium" label="Create Tenant" icon="mdi mdi-account-plus" (click)="create()" />
	</div>
</app-toolbar>
@if(servicesForTenants?.length) {
<table class="table" aria-label="Tenants">
	<thead>
		<tr>
			<th>Title</th>
			<th>Shapes</th>
			<th>Assets</th>
			<th>Plans</th>
			<th></th>
		</tr>
	</thead>
	<tbody>
		@for(tenant of tenants;track tenant.id) {
		<tr [style.color]="tenant.shapeCount === 0 ? 'red' : ''">
			<td class="can-click" (click)="edit(tenant)">{{tenant.title}}</td>
			<td class="can-click" (click)="edit(tenant)">{{tenant.shapeCount}}</td>
			<td class="can-click" (click)="edit(tenant)">{{tenant.assets?.length}}</td>
			<td>
				@for(floorplan of tenant.floorplans;track floorplan.id) {
				<app-chip [label]="floorplan.title" tooltipPosition="top" pTooltip="Site {{floorplan.sTitle}}" (click)="floorplanClick($event, floorplan)" [ngClass]="{'can-click': isAdmin()}" />&nbsp;
				}
			</td>
			<td><app-button size="small" label="assets" (click)="assets.set(tenant.assets)" /></td>
			<!--<td [innerHTML]="tenant.notes"></td>-->
		</tr>
		}
	</tbody>
</table>
} @else {
@if(servicesForTenants?.length === 0) {
<app-info-panel>No Tenants</app-info-panel>
}
}
}
@case(1) {
<app-toolbar>
	<div class="right">
		<app-button size="medium" label="Create Service" icon="mdi mdi-account-plus" (click)="createServiceForTenant()" />
	</div>
</app-toolbar>
@if(servicesForTenants?.length) {
<table class="table table-hover table-striped" aria-label="Services">
	<thead>
		<tr>
			<th>Title</th>
			<th>Notes</th>
		</tr>
	</thead>
	<tbody>
		@for(item of servicesForTenants;track item.id) {
		<tr (click)="editServiceForTenants(item)">
			<td>{{item.title}}</td>
			<td [innerHTML]="item.notes"></td>
		</tr>
		}
	</tbody>
</table>
} @else {
<app-info-panel>No Services</app-info-panel>
}
}
}

@if(assets()) {
<app-dialog [canScroll]="true" (onClose)="assets.set(null)">
	<table class="table" aria-label="Assets">
		<thead>
			<tr>
				<th>ID</th>
				<th>Title</th>
				<th>Floorplan</th>
			</tr>
		</thead>
		<tbody>
			@for(asset of assets();track asset.id) {
			<tr>
				<td>{{asset.id}}</td>
				<td>{{asset.title}}</td>
				<td>{{asset.sfTitle}}</td>
			</tr>
			}
		</tbody>
	</table>
</app-dialog>
}

@switch(dialogOpen()) {
@case('tenant') {
<app-dialog (onClose)="closeDialog()" [header]="onTenant?.title || 'New Tenant'" [dimensions]="{width:500, height:400}">
	<app-dialog-tenant-edit [tenant]="onTenant" (onClose)="closeDialog()"></app-dialog-tenant-edit>
</app-dialog>

}
@case('service') {
<app-dialog (onClose)="closeDialog()" [header]="onService?.title || 'New Service'" [dimensions]="{width:500, height:370}">
	<app-dialog-tenant-service-edit [servicesForTenants]="onService" (onClose)="closeDialog()"></app-dialog-tenant-service-edit>
</app-dialog>
}
}

<app-dialog (onClose)="onClose.emit(true)" [dimensions]="{ width: 800, height: 500}">

	@if ( html()) {
	<header></header>

	<main class="content-container">
		<nav>

		</nav>
		<article [innerHTML]="html()">

		</article>
	</main>
	}
</app-dialog>

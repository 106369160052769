import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { StoreService } from 'app/shared/store.service';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { Engagement } from 'app/classes/engagement';
import moment from 'moment';

@Component({
  selector: 'app-engage-menus',
  templateUrl: './engage-menus.component.html',
  styleUrls: ['./engage-menus.component.css']
})
export class EngageMenusComponent implements OnInit {

  @ViewChild('calendar', { static: true })
  calRef: any;

  modes: SelectItem[] = [{ label: 'Month', value: 'month' }, { label: 'Week', value: 'week' }];

  engagement: Engagement;

  maximumDate: Date;
  minimumDate: Date;
  loading: boolean;
  en: any;
  startsAt: Date;
  endsAt: Date;
  selectedView = 'month';
  adjust: boolean;

  engagementSubscription: Subscription;
  unfilteredResults: any;

  constructor(private apiService: APIService, private storeService: StoreService) {

    this.maximumDate = new Date();
    this.minimumDate = new Date(2019, 1, 1);
    this.en = {
      firstDayOfWeek: 1,
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    };
    this.updateStore();
  }

  wantsView(view) {
    this.selectedView = view;
    this.toggleCal();
  }

  startAtChanged() {
    this.updateStore();
  }

  ngOnInit() {

  }

  toggleCal() {
    // this.calRef.toggle();
    this.calRef.showOverlay(this.calRef.inputfieldViewChild.nativeElement);
  }

  updateStore() {
    const view = this.selectedView ? this.selectedView : 'month';

    console.log('engagement for ', view);

    switch (view) {
      case 'month':
        this.startsAt = moment(this.startsAt).startOf('month').toDate(); // Align to start of month
        this.endsAt = moment(this.startsAt).endOf('month').toDate();
        break;
      case 'week':
        this.endsAt = moment(this.startsAt).add(6, 'days').toDate();
        break;
    }

    this.storeService.setEngagementStore({ startsAt: this.startsAt, endsAt: this.endsAt, view, wantsAdjustment: this.adjust, users: null });
  }

}

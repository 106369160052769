import { InsightFF } from "./insight-ff";
import moment from 'moment';

export class InsightFFContainer {

	totals = {};
	percentages = {};
	percentagesFullMonth = {};

	constructor(public current: InsightFF, public previous: InsightFF) {
		this.calc();
	}

	calc() {
		this.current.collections.forEach(collection => {
			const id = String(collection.id);
			this.percentages[id] = [];
			this.totals[id] = { month: 0 };
			this.percentagesFullMonth[id] = [];
			const cT = this.current.telemetryCollection[id].telemetryByDay;
			const pT = this.previous?.telemetryCollection[id].telemetryByDay || [];
			const telemetry = [...cT, ...pT];
			this.percentages[id] = telemetry.map(t => {
				const lastWeek = telemetry.find(t0 => {
					if (moment(t0.d).diff(moment(t.d), 'day') === -7) {
						return true;
					}
				});
				if (lastWeek) {
					let p;

					const v1 = lastWeek.v;
					const v0 = t.v;

					if (v0 || v1) {
						if (v0 > v1) {
							p = String((v0 - v1) / (v0 / 100));
						} else {
							p = String(((v1 - v0) / (v1 / 100)) * -1);
						}

						p = +(parseFloat(p).toFixed(1));
					} else {
						p = +(parseFloat("0").toFixed(1));
					}

					return { v: p, i: t.i, d: t.d, annotation: moment(t.d).format('DD-MM') + ' ' + t.v + ' (last week ' + lastWeek.v + ') perc is ' + p };
				} else {
					return null;
				}
			}).filter(t => t).filter(t => this.current.configuration.ranges.findIndex(r => moment(t.d).dayOfYear() === moment(r.from).dayOfYear()) !== -1);

			this.totals[id].month = Math.round(this.percentages[id].reduce((p, t) => p + t.v, 0) / this.percentages[id].length);

			const start = this.current.configuration.start;
			const days = moment(start).daysInMonth();
			this.percentagesFullMonth[id] = [];
			for (let index = 0; index < days; index++) {
				const dayOfYear = moment(start).add(index, 'day').dayOfYear();
				const element = this.percentages[id].find(t => moment(t.d).dayOfYear() === dayOfYear);
				if (element) {
					this.percentagesFullMonth[id].push(element);
				} else {
					this.percentagesFullMonth[id].push({ v: null, i: index + 1, d: moment(start).add(index, 'day').toDate() });
				}
			}
		});
	}

}

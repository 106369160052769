@defer (when isReady && hasData) {
  <article>
    <section class="header mb-2">
      <header>
        <div class="mb-1">
          <h2>{{ headerTitle }}</h2>
          <small>{{ insight.configuration.start | date: 'MMMM YYYY' }}</small>
        </div>
        <div>
          @if (logo) {
            <img [src]="logo | safeUrl" alt="logo" />
          }
        </div>
      </header>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="address">
                @if (building().site.title !== building().site.address.address1) {
                  <p>{{ building().site.title }}</p>
                }
                <p>{{ building().site.address.address1 }}</p>
                <p>{{ building().site.address.address2 }}</p>
                <p>{{ building().site.address.addressTown }}</p>
                <p>{{ building().site.address.addressCounty }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="opening-hours">
        <p class="sub-title">Opening hours</p>
        <table class="table">
          <thead>
            <tr>
              <th>Monday</th>
              <th>Tuesday</th>
              <th>Wednesday</th>
              <th>Thursday</th>
              <th>Friday</th>
              <th>Saturday</th>
              <th>Sunday</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              @for (day of building().openingHours.hours; track $index) {
                <td>
                  @if (day.isClosed) {
                    <span>Closed</span>
                  } @else {
                    <span>{{ day.from }} - {{ day.to }}</span>
                  }
                </td>
              }
            </tr>
          </tbody>
        </table>
      </div>
      @if (isReady) {
        @if (hasData) {
          <div class="mt-2">
            <p class="sub-title">Average measurements within opening hours.</p>
            <div class="icons mt-1">
              @if (insight.types['co2']?.count > 0) {
                <div class="icon {{ insight.types.co2.colour }}">
                  <div>
                    <span tooltipposition="bottom" ptooltip="CO2" class="">
                      <img src="{{ svgLocation }}icon-co2.svg" alt="co2" />
                    </span>
                  </div>
                  <div>
                    <p>{{ insight.types.co2.avg }}<small>ppm</small></p>
                    <p>CO2</p>
                  </div>
                </div>
              }
              @if (insight.types['humidity']?.count > 0) {
                <div class="icon {{ insight.types.humidity.colour }}">
                  <div>
                    <span tooltipposition="bottom" ptooltip="Humidity" class="">
                      <img src="{{ svgLocation }}icon-humidity.svg" alt="humidity" />
                    </span>
                  </div>
                  <div>
                    <p>{{ insight.types.humidity.avg }}<small>%</small></p>
                    <p>Humidity</p>
                  </div>
                </div>
              }
              @if (insight.types['temperature']?.count > 0) {
                <div class="icon {{ insight.types.temperature.colour }}">
                  <div>
                    <span tooltipposition="bottom" class="">
                      <img src="{{ svgLocation }}icon-temperature.svg" alt="temperature" />
                    </span>
                  </div>
                  <div>
                    <p>{{ insight.types.temperature.avg }}<small>c</small></p>
                    <p>Temperature</p>
                  </div>
                </div>
              }

              @if (insight.types['voc']?.count > 0) {
                <div class="icon {{ insight.types.voc.colour }}">
                  <div>
                    <span tooltipposition="bottom" ptooltip="VOC">
                      <img src="{{ svgLocation }}icon-voc.svg" alt="voc" />
                    </span>
                  </div>
                  <div>
                    <p>{{ insight.types.voc.avg }}<small>ppb</small></p>
                    <p>VOC</p>
                  </div>
                </div>
              }

              @if (insight.types['pm1']?.count > 0) {
                <div class="icon {{ insight.types.pm1.colour }}">
                  <div>
                    <span tooltipposition="bottom" ptooltip="PM1">
                      <img src="{{ svgLocation }}icon-pm10.svg" alt="pm1" />
                    </span>
                  </div>
                  <div>
                    <p>{{ insight.types.pm1.avg }}<small>ppb</small></p>
                    <p>PM1</p>
                  </div>
                </div>
              }

              @if (insight.types['pm2.5']?.count > 0) {
                <div class="icon {{ insight.types['pm2.5'].colour }}">
                  <div>
                    <span tooltipposition="bottom" ptooltip="PM2.5">
                      <img src="{{ svgLocation }}icon-pm10.svg" alt="pm2.5" />
                    </span>
                  </div>
                  <div>
                    <p>{{ insight.types['pm2.5'].avg }}<small>ppb</small></p>
                    <p>PM2.5</p>
                  </div>
                </div>
              }

              @if (insight.types['pm10']?.count > 0) {
                <div class="icon {{ insight.types.pm10.colour }}">
                  <div>
                    <span tooltipposition="bottom" ptooltip="PM10">
                      <img src="{{ svgLocation }}icon-pm10.svg" alt="pm10" />
                    </span>
                  </div>
                  <div>
                    <p>{{ insight.types.pm10.avg }}<small>ppb</small></p>
                    <p>PM10</p>
                  </div>
                </div>
              }
              <!-- close type icon block -->
            </div>
            <div class="notes mt-1">
              This report presents the quality of the air in an office building environment. The recommended thresholds are based on guidance from WHO and
              national agencies. Measurements were made using indoor air quality monitors.
            </div>
          </div>
        } @else {
          <div>
            <h3>No data</h3>
          </div>
        }
      }
    </section>

    @if (insight.telemetryCollection) {
      <section id="report" class="mt-1">
        @for (t of insight.typeList; track $index; let i = $index) {
          <div>
            @if (insight.types[t]?.count) {
              <app-aq-report-type
                id="report-type-{{ t }}"
                [insight]="insight"
                [type]="t"
                [collections]="collections()"
                [grandTotals]="grandTotals"
                [wantsPageBreak]="true" />
            }
          </div>
        }
      </section>
    }
  </article>
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrainingService } from '../../shared/training.service';
import { APIService } from '../../shared/api.service';

@Component({
  selector: 'app-training-course-selector',
  templateUrl: './training-course-selector.component.html',
  styleUrls: ['./training-course-selector.component.css']
})
export class TrainingCourseSelectorComponent implements OnInit {

  constructor(private apiService: APIService, private router: Router, private trainingService: TrainingService) {
    router.navigate(['/training', 3]);
  }

  ngOnInit() {
  }

}

<article>
  @defer (when openingHours()) {
    @switch (view()) {
      @case ('display') {
        @if (openingHours().isSet) {
          <section class="days" (click)="view.set('edit')">
            @for (day of OpeningHours.days; track day; let i = $index) {
              <div class="day can-click" [class.day-is-closed]="openingHours().hours[i].isClosed">
                <div>
                  {{ day }}
                </div>
                @if (openingHours().hours[i].isClosed) {
                  <div class="is-closed fourd">
                    <span>
                      <span><i class="mdi mdi-moon-waning-crescent"></i> Closed </span>
                    </span>
                  </div>
                } @else {
                  <div class="all-day"></div>
                  <div class="flex">
                    <div class="time fourd flex-1">
                      <span>From</span>
                      <span>{{ openingHours().hours[i].from }}</span>
                    </div>
                    <div class="time fourd flex-1">
                      <span>To</span>
                      <span>{{ openingHours().hours[i].to }}</span>
                    </div>
                  </div>
                }
              </div>
            }
          </section>
        } @else {
          <app-info-panel
            >Operational hours not setup <app-button label="Setup Now" size="small" (click)="view.set('edit')" icon="mdi mdi-pencil"></app-button
          ></app-info-panel>
        }
      }
      @default {
        <header>
          <app-toolbar>
            <div class="right">
              <app-button
                label="Duplicate"
                (click)="duplicate()"
                icon="mdi mdi-content-duplicate"
                pTooltip="Duplicate your first entry to all active days"
                tooltipPosition="left" />
              <app-button [isCTA]="true" label="Save" (click)="save()" icon="mdi mdi-check" />
            </div>
          </app-toolbar>
        </header>
        <section class="days">
          @for (day of OpeningHours.days; track day; let i = $index) {
            <div class="day">
              <div class="can-click" (click)="clickDay(i)">
                <i
                  class="mdi"
                  [class.mdi-toggle-switch]="!openingHours().hours[i].isClosed"
                  [class.mdi-toggle-switch-off-outline]="openingHours().hours[i].isClosed"></i>
                {{ day }}
              </div>
              @if (openingHours().hours[i].isClosed) {
                <div class="is-closed fourd">
                  <span>
                    <span><i class="mdi mdi-moon-waning-crescent"></i> Closed </span>
                  </span>
                </div>
              } @else {
                <div class="all-day">
                  <i class="mdi mdi-hours-24 can-click" (click)="set24Hours(i)" pTooltip="Set to open 24 hours" tooltipPosition="top"></i>
                </div>
                <div class="flex">
                  <div class="time fourd flex-1">
                    <span>From</span>
                    <span><input type="time" [(ngModel)]="openingHours().hours[i].from" /></span>
                  </div>
                  <div class="time fourd flex-1">
                    <span>To</span>
                    <span><input type="time" [(ngModel)]="openingHours().hours[i].to" /></span>
                  </div>
                </div>
              }
            </div>
          }
        </section>
      }
    }
  } @placeholder {
    <i class="mdi mdi-loading mdi-spin-x2"></i>
  }
</article>

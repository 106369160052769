import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ComplianceCollection } from 'app/classes/compliance/compliance-collection';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import moment from 'moment';

@Component({
  selector: 'app-compliance-home',
  templateUrl: './compliance-home.component.html',
  styleUrls: ['./compliance-home.component.css']
})
export class ComplianceHomeComponent implements OnInit {

  @Output()
  onComplianceRetrieved: EventEmitter<ComplianceCollection> = new EventEmitter();

  tabIndex: number;

  compliance: {
    current: ComplianceCollection,
    previous: ComplianceCollection
  } = { current: null, previous: null };

  asset: any;

  constructor(private assetService: AssetService, private apiService: APIService) {

  }

  handleChange(event: any) {

  }

  ngOnInit(): void {
    this.getCurrent();
  }

  getCurrent() {
    const df = moment().startOf('month').toDate();
    const dt = moment().toDate();

    this.apiService.getCompliance(df, dt)
      .then(r => {
        this.compliance.current = r;
        this.onComplianceRetrieved.emit(r);
        this.getPrevious();
      });
  }

  getPrevious() {
    const df = moment().subtract(1, 'month').startOf('month').toDate();
    const dt = moment().subtract(1, 'month').endOf('month').toDate();

    this.apiService.getCompliance(df, dt)
      .then(r => {
        this.compliance.previous = r;
      });
  }

  select(asset) {
    this.assetService.getAsset(asset.id).then(a => this.asset = a);
  }
}

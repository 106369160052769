<main *ngIf="siteRag" [class.fullscreen]="isFullscreen">
  <div class="row">

    <header>
      <div></div>

      <div class="calendar" (click)="isSelectingDate = !isSelectingDate">
        <span>{{ header.month }} {{ header.year }} <span *ngIf="header.toYear"> - {{ header.toMonth }} {{ header.toYear
            }} </span> </span>
        <div *ngIf="isSelectingDate">
          <p-calendar hideOnDateTimeSelect="true" (onSelect)="dateChanged()" [maxDate]="maxDate" [minDate]="minDate"
            inline="true" [(ngModel)]="selectingDate" view="month" dateFormat="mm/yy" [yearNavigator]="true"
            yearRange="2020:2022"></p-calendar>
        </div>
      </div>

      <div>
        <span class="value" pTooltip="Number of months to show" (click)="monthsClick()">{{defaults.months}}</span>
        <span class="mdi mdi-fullscreen" pTooltip="Toggle fullscreen" (click)="isFullscreen = !isFullscreen"></span>
        <span class="mdi" [class.mdi-table-of-contents]="defaults.layout === 'row'"
          [class.mdi-view-grid]="defaults.layout === 'grid'" pTooltip="Change layout" (click)="layoutClick()"></span>
      </div>
    </header>

  </div>

  <div class="row" [class.layout-row]="defaults.layout === 'row'">
    <div *ngFor="let assetId of siteRag.assetIds; let i = index" [class.col-md-6]="defaults.layout === 'grid'"
      [class.col-md-12]="defaults.layout === 'row'" [class.pagebreakbefore]="i % 2">
      <div class="bounds">
        <app-asset-rag-report [presentation]="defaults.layout" [assetId]="assetId" [days]="siteRag.items[assetId]"
          [focusItem]="focusDay"></app-asset-rag-report>
      </div>
    </div>
  </div>
</main>

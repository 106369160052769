@defer (when sites()) {
  <div class="tabview-list">
    <div (click)="setTab(0)" [class.active]="tabIndex() === 0">
      <span><i class="mdi mdi-view-list"></i> List </span>
    </div>
    <div (click)="setTab(1)" [class.active]="tabIndex() === 1">
      <span><i class="mdi mdi-map-outline"></i> Map </span>
    </div>
    <div></div>
  </div>

  @switch (tabIndex()) {
    @case (1) {
      <app-org-map [sites]="sites()" />
    }
    @default {
      <section>
        <app-search-input [searchText]="searchFor" (onSearchChanged)="searchChanged($event)" tooltipText="Search"></app-search-input>
      </section>
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th>Site</th>
            <th class="hidden-xs">Address</th>
            <th class="hidden-xs"></th>
            <th class="hidden-xs">Gateways</th>
            <th class="hidden-xs">Floorplans</th>
            <th>Org</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let site of filteredSites(); let i = index" [routerLink]="['sites', site.id]">
            <td>{{ site.title }}</td>
            <td class="hidden-xs">{{ site.address.address1 }}</td>
            <td class="hidden-xs">{{ site.address.addressPostcode }}</td>
            <td class="hidden-xs">{{ site.gatewayCount }}</td>
            <td class="hidden-xs">{{ site.floorplanCount }}</td>
            <td>{{ site.org.shortTitle }}</td>
          </tr>
        </tbody>
      </table>
    }
  }
} @placeholder {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
}

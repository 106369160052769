<article>
	<div id="{{chartid}}"></div>
	<div>
		<span pTooltip="Favourite" tooltipPosition="bottom" (click)="favClick()" class="mdi " [class.mdi-star]="fav"
			[class.mdi-star-outline]="!fav"></span>

		<span pTooltip="resize" tooltipPosition="bottom" (click)="sizeClick()" class="mdi "
			[class.mdi-rectangle]="fav"></span>

		<span pTooltip="Toggle points" tooltipPosition="bottom" (click)="pointToggle()"
			class="mdi mdi-music-note-whole"></span>

	</div>
</article>
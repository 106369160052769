<article>
	@if(!isActive()) {
	<p style="color:red; text-align: center;">This export is disabled and will not run.</p>
	}

	<div class="fourd">
		<section>
			<h1>Database</h1>
			<textarea [ngModel]="sql()" (ngModelChange)="onSQLchange($event)" rows="14"></textarea>
			<div>
				<p>The following will be added to the WHERE clause automatically to restrict the data to yesterday:</p>
				<pre>date(t.createdAt) = CURDATE() - INTERVAL 1 DAY</pre>
			</div>
		</section>
		<section class="options">
			<h1>Options</h1>
			<div>
				<div>
					<label>Frequency</label>
					<select>
						<option>Daily</option>
					</select>
				</div>
				<div>
					<label>Active</label>
					<select [ngModel]="isActive()" (ngModelChange)="isActive.set($event)" (change)="activeChange($event)">
						<option [ngValue]="true">Yes</option>
						<option [ngValue]="false">No</option>
					</select>
				</div>
			</div>
		</section>
	</div>
</article>

<div class="review-graph" [class.detail-view]="detailView" (dblclick)="toggleView()">
    <div class="graph-container">
        <svg class="trackable master" #graph [attr.width]="rect.w" height="220" [attr.viewBox]="viewBox"
            (click)="clickSVG('master', $event)" (mousemove)="onMousemove('master', $event)">
            <!--<path d="M 0 0 L 720 0 L 720 100 L 0 100 Z"></path>-->
            <g class="legend">
                <svg:path [attr.d]="legend"></svg:path>
            </g>
            <svg:path [attr.d]="path"></svg:path>
            <svg:g *ngIf="svg.master.cursor.x !== null" class="cursor">
                <svg:line y1="0" [attr.y2]="rect.h " [attr.x1]="svg.master.cursor.x" [attr.x2]="svg.master.cursor.x">
                </svg:line>
                <svg:line [attr.y1]="svg.master.cursor.y" [attr.y2]="svg.master.cursor.y" x1="0" [attr.x2]="rect.w">
                </svg:line>
                <svg:g class="callout">
                    <ng-template ngFor let-i="index" let-point [ngForOf]="svg.master.cursor.telemetry">

                        <svg:text [attr.x]="svg.master.cursor.x + 5" [attr.y]="20 + (i * 30)" text-anchor="left">
                            {{point.v}}</svg:text>
                        <svg:text [attr.x]="svg.master.cursor.x + 5" [attr.y]="30 + (i * 30)" text-anchor="left">
                            {{point.d | amDateFormat:'dd Do MMM, hh:mm'}}</svg:text>
                    </ng-template>

                </svg:g>
            </svg:g>
            <svg:g class="findings">
                <ng-template ngFor let-i="index" let-finding [ngForOf]="findings">
                    <svg:g *ngIf="!finding.telemetry.end.x">
                        <svg:line [attr.y1]="0" [attr.y2]="rect.h" [attr.x1]="finding.telemetry.start.x"
                            [attr.x2]="finding.telemetry.start.x"></svg:line>
                    </svg:g>
                    <svg:g *ngIf="finding.telemetry.end.x"
                        [ngClass]="{'green': finding.rag==='green', 'orange':finding.rag==='orange', 'red':finding.rag==='red'}">
                        <svg:rect [attr.y]="0" [attr.height]="rect.h" [attr.x]="finding.telemetry.start.x"
                            [attr.width]="(finding.telemetry.end.x - finding.telemetry.start.x)"></svg:rect>
                    </svg:g>
                    <svg:text [attr.x]="finding.telemetry.start.x" [attr.y]="rect.h + 13" text-anchor="left">
                        {{finding.title}} {{finding.rag}}</svg:text>
                </ng-template>
            </svg:g>
        </svg>
        <div class="actions" [class.visible]="showMenu">
            <div class="menu-button">
                <i class="fa fa-fw fa-bars" (click)="toggleMenu()"></i>
            </div>
            <div class="menu-choices">
                <div (click)="zoomIn()" class="zoom-in">Zoom In</div>
                <div (click)="zoomOut()" class="zoom-out">Zoom Out</div>
            </div>

        </div>
        <div *ngIf="detailView">
            <svg class="zoomed" #graphZoom [attr.width]="rect.w" height="220" [attr.viewBox]="viewBox"
                (click)="clickSVG('slave', $event)">
                <!--<path d="M 0 0 L 720 0 L 720 100 L 0 100 Z"></path>-->
                <g class="legend">
                    <svg:path [attr.d]="legendZoom"></svg:path>
                    <ng-template ngFor let-text [ngForOf]="legendZoom.text">
                        <svg:text [attr.x]="text.x" [attr.y]="text.y" [attr.text-anchor]="text.align">{{text.text}}
                        </svg:text>
                    </ng-template>
                </g>
                <svg:path [attr.d]="pathZoom"></svg:path>
            </svg>
            <div class="findings">


                <div class="finding" *ngFor="let finding of findings; let i = index">
                    <app-review-finding-edit [finding]="finding" (onDelete)="trashFinding(i)"
                        (onSubmit)="submitFinding(finding)"></app-review-finding-edit>
                </div>


            </div>
        </div>
    </div>
</div>

<app-data-table [dataTable]="dataTable"> </app-data-table>
<!--
<table class="table table-hover">
  <thead>
    <tr>
      <th>Gateway</th>
      <th>Asset</th>
      <th class="text-right">Value</th>
    </tr>
  </thead>
  <tbody>
    @for (asset of filteredAssets; track asset) {
      <tr (click)="clickAsset(asset)">
        <td><span class="rag rag-{{ asset.rag }}"></span> {{ asset.gatewayTitle }}</td>
        <td><app-asset-icon [assetTypeId]="asset.assetType" /> {{ asset.title }}</td>
        <td class="text-right">{{ asset.formattedValue }}</td>
      </tr>
    }
  </tbody>
</table>
-->

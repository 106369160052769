<article>
    <main class="header">
        <div>Details</div>
        <div></div>
    </main>
    <div *ngFor="let id of assetIds" class="mt-1 charts">
        <main>
            <div>
                <p>
                    <i class="fa fa-fw fa-circle {{insight.telemetry[id].review.rag}}"></i>&nbsp;
                    {{insight.telemetry[id].asset.title}}
                </p>
                <p><i class="fa fa-fw"></i>&nbsp; {{insight.telemetry[id].asset.gatewayTitle}}
                </p>
                <p> {{insight.telemetry[id].review.annotation}}</p>
            </div>
            <div class="chart">
                <app-d3-chart [asset]="insight.telemetry[id].asset" [hasToClickToInteract]="true" [canAnnotate]="true"
                    [canZoom]="false" height="200" [telemetry]="insight.telemetry[id].telemetry" [showLegend]="true"
                    [minMaxFromData]="true" penWidth="1" (chartCompletedMinMax)="chartCompleted(id, $event)"
                    [margin]="{ top: 10, right: 10, bottom: 10, left: 30 }">
                </app-d3-chart>
            </div>
        </main>
    </div>
</article>

<div class="row" *ngIf="history">
  <div class="col-xs-12 history-item" *ngIf="history[1]">

    <table class="table table-sm">
      <tbody>
        <ng-template ngFor let-alarm [ngForOf]="history[0]">
          <!--
          <h5>{{alarm.createdAt | amDateFormat:apiService.getDateFormat() + ' HH:mm'}} <small>{{alarm.createdAt | amTimeAgo:false}}</small></h5>
          <img src="https://s3.eu-west-2.amazonaws.com/4d-images-charts/alarm-{{alarm.id}}.png"/>
          -->
          <tr class="header">
            <td colspan=2> {{alarm.createdAt | amDateFormat:apiService.getDateFormat() + ' HH:mm'}} <small>{{alarm.createdAt | amTimeAgo:false}}</small></td>
            <td>{{alarm.findings}}</td>
          </tr>
          <tr *ngFor="let item of alarm.history">
            <td>{{item.action}}</td>
            <td>{{item.value}}</td>
            <td>{{item.createdAt | amDateFormat:apiService.getDateFormat() + ' HH:mm'}}</td>
          </tr>
        </ng-template>
      </tbody>
    </table>


  </div>
  <div class="col-xs-12" *ngIf="!history[1]">
    There is no alarm history.
  </div>
</div>

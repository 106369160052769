<table class="table table-sm">
  <thead>
    <th>Rule</th>
    <th>Status</th>
    <th>Created</th>
  </thead>
  <tbody>
    <tr *ngFor="let subscription of subscriptions;let i=index">
      <td>{{subscription.rulePackageTitle}}</td>
      <td>{{subscription.contactInviteStatus}}</td>
      <td>{{subscription.contactCreatedAt | amTimeAgo}}</td>
    </tr>
  </tbody>
</table>

import { Component, OnInit } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Alarm } from 'app/classes/alarm';
import { StoreService } from 'app/shared/store.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-rule-engine-v2-action',
  templateUrl: './rule-engine-v2-action.component.html',
  styleUrls: ['./rule-engine-v2-action.component.css']
})
export class RuleEngineV2ActionComponent implements OnInit {

  details: string = 'Loading...';

  alarm: Alarm;

  constructor(private storeService:StoreService, private router: Router, private route: ActivatedRoute, private apiService: APIService) {
    this.route.params.subscribe((params: Params) => {
      apiService.getAlarm(params.alarmid).then( alarm => {
        this.alarm = alarm;
        this.details = this.alarm.rulePackage.title + ' alarm.';
      });
    });
  }

  ngOnInit() {
    this.storeService.sectionBannerBack
    .pipe(filter(sbb => !!sbb && sbb.state === true && sbb.section === 'rules-alarm'))
    .subscribe(state => {
      console.log('back to rules landing');
      // Set to handled
      state.state = false;
      this.storeService.setSectionBannerBack(state);
      // Back to training
      this.router.navigate(['/rules', this.alarm.rulePackage.id]);
    });
  }

}

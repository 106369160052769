import { Building } from "./building";
import { Asset } from "./asset";
import { Gateway } from "./gateway";

export class WebsocketIn {
	building: Building;
	gateway: Gateway;
	asset: Asset;
	assets: any[] = [];
	message: string;
	action: string;
	rag = { previous: null, current: null };
	receivedAt: Date;
	title: string;
	html: string;
	state: string;

	constructor(message: any) {
		//const received = JSON.parse(message.data);
		const received = message;

		this.asset = new Asset({ id: received.assetId, title: received.assetTitle, value: received.assetValue });
		this.building = new Building({ id: received.buildingId, title: received.buildingTitle });
		this.gateway = new Gateway({ id: received.gatewayId, title: received.gatewayTitle });
		this.building.site.org.logo = received.logo || null;
		this.action = received.action;
		this.rag.current = received.newRag || null;
		this.rag.previous = received.oldRag || null;
		this.receivedAt = new Date();
		this.assets = received.assets || [];
		switch (this.action) {
			case 'state':
				this.title = 'Gateway offline';
				this.asset = new Asset();
				this.state = 'offline';
				break;
			case 'rag_change':
				this.title = 'Setpoint from ' + this.rag.previous + ' to ' + this.rag.current + ' on ' + received.assetTitle + ' at ' + received.gatewayTitle;
				this.asset = new Asset({ id: received.assetId, title: received.assetTitle, value: received.assetValue });
				break;
			case 'telemetry':
				this.title = 'Telemetry from ' + this.gateway.title;
				this.html = `<span class="btn btn-link">${this.gateway.title}</span>`;
				this.asset = new Asset();
				break;
		}
	}
}
import { Component, OnInit } from '@angular/core';
import { Site } from '../../classes/site';
import { Gateway } from '../../classes/gateway';
import { APIService } from '../../shared/api.service';
import { Asset } from '../../classes/asset';
import { SiteReview } from '../../classes/reviews/review';
import { ReviewAsset } from '../../classes/reviews/review-asset';

@Component({
  selector: 'app-review-new',
  templateUrl: './review-new.component.html',
  styleUrls: ['./review-new.component.css']
})
export class ReviewNewComponent implements OnInit {

  selectedSite: Site;
  selectedGateway: Gateway;
  // The assets to select from
  assetPool: Asset[];

  siteReview: SiteReview = new SiteReview({ review: {title: 'Initial findings'}});

  // Only show common sensors (not WIFI, battery etc)
  filterForCommonSensors = true;
  constructor(private apiService: APIService) { }

  ngOnInit() {
  }

  siteSelected(site: Site) {
    this.selectedSite = site;
    this.selectedGateway = null;
    this.siteReview.site = site;
  }

  gatewaySelected(gateway: Gateway) {
    this.selectedGateway = gateway;

    this.apiService.getAssetsForGatewayId(this.selectedGateway.id).then(assets => {
      this.assetPool = assets.sort(function (a, b) {
        return a.assetType_id - b.assetType_id;
      });;
      // Check any assets already in the review asset list
      this.assetPool.forEach(asset => {
        this.siteReview.assets.forEach(reviewAsset => {
          if (reviewAsset.id === asset.id) {
           asset.checked = true;
          }
        })
      });
    });
  }

  clickAssetPool(assetClicked: Asset) {
    this.assetPool.forEach(asset => {
      if (asset.id === assetClicked.id) {
        asset.checked = !asset.checked;
        if (asset.checked) {
          this.siteReview.assets.push(new ReviewAsset(asset));
        } else {
          this.siteReview.assets = this.siteReview.assets.filter(reviewAsset => reviewAsset.id !== assetClicked.id);
        }
      }
    });
  }

  clickCreateReview() {
    this.apiService.postReview(this.siteReview).then(r => {
      console.log(r);
    })
  }

}

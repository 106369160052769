<article>

  <section [hidden]="selectedUser?.user">
    <table class="table table-hover user-list">
      <thead>
        <tr>
          <th>Org</th>
          <th pTooltip="">
            Name
          </th>
          <th tooltipPosition="left" pTooltip="When any activity was registered for the user">
            Last Activity <i class="fa fa-info-circle"></i>
          </th>
          <!-- <th tooltipPosition="left" pTooltip="The last place the user was">
            Last route <i class="fa fa-info-circle"></i>
          </th>-->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users" [class.inactive]="!user.isActive" (click)="selectUser(user)">
          <td title="{{user.org.title}}">{{user.org.shortTitle}}</td>
          <td>{{user.name}} <span *ngIf="isLoadingUser === user"> <i class="fa fa-spin fa-refresh"></i></span></td>
          <td *ngIf="user.updatedAt" tooltipPosition="left" pTooltip="{{user?.updatedAt | amCalendar}}">
            {{user?.updatedAt | amTimeAgo}}</td>
          <td *ngIf="!user.updatedAt"></td>
          <!--<td><a target="_blank" href="{{user.lastRoute}}">{{user.lastRoute}}</a></td>-->
        </tr>
      </tbody>
    </table>
  </section>

  @if(selectedUser?.user) {
  <app-dialog [header]="selectedUser.user.name" [canScroll]="true" (onClose)="selectedUser=null">
    <div>
      <app-toolbar>
        <div class="right">
          <app-button icon="mdi mdi-rotate-3d-variant" (click)="compressDuplicatesClick()" [label]="compressDuplicates ? 'Hiding Duplicates' : 'Showing duplicates'" />
        </div>
      </app-toolbar>

      <table class="table table-sm user-routes" aria-label="Routes">
        <thead>
          <tr>
            <th>Date</th>
            <th>Path</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of selectedUser.filtered">
            <td tooltipPosition="bottom" pTooltip="{{row.row.createdAt | amDateFormat:'DD MMM YYYY HH:mm'}}">
              {{row.row.createdAt | amTimeAgo}}</td>
            <td>
              <a target="_blank" href="{{row.row.path}}">{{row.row.path}}</a>
              <span pTooltip="Site" *ngIf="row.row.siteTitle">{{row.row.siteTitle}}</span>
              <span pTooltip="Asset" *ngIf="row.row.assetTitle">{{row.row.assetTitle}}</span>
              <span pTooltip="Gateway" *ngIf="row.row.gatewayTitle">{{row.row.gatewayTitle}}</span>
              <span pTooltip="Report" *ngIf="row.row.reportTitle">{{row.row.reportTitle}}</span>
              <span pTooltip="Report" *ngIf="row.row.ruleTitle">{{row.row.ruleTitle}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </app-dialog>

  }
</article>

@if(isWorking()) {
<app-please-wait></app-please-wait>
}

@defer(when gateway()) {
<div class="embed">
  <div class="go-back">
    <app-page-title [label]="gateway().title" [canGoBack]="true" routerLink="/live"></app-page-title>
    <app-button [isMuted]="true" icon="mdi mdi-rotate-3d-variant" [label]="!showHelp() ? 'Hiding Identifiers' : 'Showing Identifiers'" (click)="toggleHelp()" class="isDesktop"></app-button>
  </div>

  <iframe #iframe [style.width]="width() ? width() + 'px' : '100vw'" (load)="iframeOnLoad($event)" allowscriptaccess="always" [src]="sanitizer.bypassSecurityTrustResourceUrl(iframeSrc())" height="100%" allowfullscreen></iframe>
  <br />
  @if(showHelp()) {
  <div class="help" [class.asset-has-been-selected]="selectedAsset()?.id">
    <table class="table table-sm borderless table-hover" aria-label="Sensors">
      <tbody>
        @for(asset of assets(); track asset.id) {
        <tr (click)="toggleAsset(asset)" [hidden]="selectedAsset() && selectedAsset()?.id !== asset.id">
          <td>
            @if(selectedAsset()) {
            <i class="mdi mdi-chevron-left"></i>
            }
            <app-asset-indicator [asset]="asset"></app-asset-indicator>
            <app-asset-icon [asset]="asset"></app-asset-icon> {{asset.identifier}}
          </td>
          <td>{{asset.title}}</td>
        </tr>
        @if(asset.id===selectedAsset()?.id) {
        <tr>
          <td colspan=99>
            <app-setpoints-explorer [asset]="asset" [setpoints]="setpoints()"></app-setpoints-explorer>
          </td>
        </tr>
        }
        }
      </tbody>
    </table>
  </div>
  }
</div>
} @placeholder(minimum 1000ms) {
<app-please-wait></app-please-wait>
}

import { Asset } from './asset';

export class SiteFloorplanAsset extends Asset {
  override svg: any = { x: null, y: null, id: null, label: null };
  svgPrecision: { rect: { x: number, y: number, w: number, h: number }, text: { x: number, y: number, w: number, h: number } };
  siteFloorplanId: number;
  declare rag: string;
  originalValue: string;
  history: any = [];
  shapeId: number;
  type: string;
  mx: number;
  my: number;
  mw: number;
  mh: number;
  mfs = 14;
  upData: 'rag' | 'value';
  upDataOp: '=' | '<' | '>' | '>=' | '<=' | '!=';
  upDataValue: number;
  svgIcon: string;
  svgIconPath: string;
  isAlwaysVisible: boolean;
  showValue: boolean;
  svgWidth = 50;
  svgHeight = 22;
  svgIconDimensions: { x: number, y: number, w: number, h: number } = { x: 0, y: 0, w: 20, h: 20 };
  svgTextAdjust: { x: number, y: number, w: number, h: number } = { x: 0, y: 0, h: 0, w: 0 };
  sfaType: string;
  // Track changes when refreshing.
  changeDiff: number;
  label: string;
  customIcon: string;

  border: { colour: string, stroke: 'solid' | 'dashed' } = { colour: 'black', stroke: 'solid' };
  background: { colour: string } = { colour: 'white' };
  rotateDeg = 0;
  isSelected = false;

  constructor(data?: any) {
    super(data);
    if (data) {

      this.svg.x = data.x;
      this.svg.y = data.y;
      this.svg.id = data.sfaId;
      this.svg.label = data.label;
      this.siteFloorplanId = data.sfId;
      this.rag = data.rag;
      this.shapeId = data.sfaShapeId;
      this.type = data.sfaType;
      this.mw = data.mw;
      this.my = data.my;
      this.mh = data.mh;
      this.mfs = data.mfs || 14;
      this.upData = data.upData;
      this.upDataOp = data.upDataOp;
      this.upDataValue = data.upDataValue;
      this.isAlwaysVisible = data.isAlwaysVisible;
      this.showValue = true;
      this.sfaType = data.sfaType;
      this.label = data.label;
      this.customIcon = data.customIcon;
      this.rotateDeg = data.rotateDeg || 0;
      this.processAsset();
    }
  }

  processAsset() {
    this.svgWidth = 50;
    this.svgHeight = 22;
    this.originalValue = this.value;
    // Fix silly precision
    if (this.value && +this.value === +this.value) {
      // Is a number,
      if (this.value.indexOf('.')) {
        // Has a decimal place, no moe than 1 decimal place
        this.value = (+this.value).toFixed(1);
      }
    }

    if (new Date(this.updatedAt.getTime() - 24 * 60 * 60 * 1000)) {
      // over 24 hours old
      this.border.stroke = 'dashed';
    }


    if (this.sfaType === 'icon') {
      let isInteger = false;
      switch (this.assetType_id) {
        case 1:
          this.svgIcon = 'iot-touch';
          this.value = this.displayValue();
          if (this.purpose.id === 12) {
            this.svgWidth = 50 + (this.value.length * 8);
          } else {
            this.svgWidth = 50;
          }
          isInteger = false;
          this.showValue = true;

          break;
        case 2:
          this.svgIcon = 'iot-temp';
          break;
        case 3:
          this.svgIcon = 'iot-pressure';
          isInteger = true;
          break;
        case 7:
        case 11:
          this.svgIcon = 'iot-light';
          isInteger = true;
          break;
        case 8:
          this.svgIcon = 'iot-humidity';
          isInteger = true;
          break;
        case 15:
          if (this.title.substr(-2) === 'in') {
            this.svgIcon = 'iot-motion-right';
          } else {
            this.svgIcon = 'iot-motion';
          }
          this.svgWidth = 50;
          isInteger = true;
          break;
        case 16:
          this.svgIcon = 'iot-power';
          this.svgWidth = 50;
          isInteger = true;
          break;
        case 17:
          if (this.displayValue().toLowerCase() === 'open') {
            this.svgIcon = 'iot-door-open';
          } else {
            this.svgIcon = 'iot-door-closed';
          }
          this.svgWidth = 70;
          isInteger = false;
          this.value = this.displayValue();
          break;
        case 19:
          this.svgIcon = 'iot-water-temp';
          this.svgWidth = 50;
          isInteger = true;
          break;
        case 23: // moisture
          this.svgIcon = 'iot-water';
          isInteger = false;
          this.svgWidth = 90;
          this.value = this.displayValue();
          break;
        case 25:
          this.svgIcon = 'iot-co2';
          this.svgWidth = 50;
          isInteger = true;
          break;
        case 29:
          this.svgIcon = 'iot-pm25';
          this.svgWidth = 50;
          isInteger = true;
          break;
        case 30:
          this.svgIcon = 'iot-pm10';
          this.svgWidth = 50;
          isInteger = true;
          break;
        case 26:
          this.svgIcon = 'iot-dust';
          this.svgWidth = 50;
          isInteger = true;
          break;
        case 27:
          this.svgIcon = 'iot-voc';
          this.svgWidth = 50;
          isInteger = true;
          break;
        case 49:
          this.svgIcon = 'iot-sound';
          break;
        case 50:
          this.svgIcon = 'iot-motion';
          this.showValue = false;
          break;
        case 51:
          this.svgIcon = 'iot-radon';
          isInteger = true;
          break;
        case 54:
          this.svgIcon = 'iot-virus-score';
          isInteger = true;
          break;
        case 56:
          this.svgIcon = 'iot-daily-minutes';
          isInteger = true;
          break;
        case 58:
          this.svgIcon = 'iot-motion';
          this.svgWidth = 50;
          isInteger = true;
          break;
        case 62:
          this.svgIcon = 'iot-pm1';
          this.svgWidth = 50;
          isInteger = true;
          break;
      }

      if (this.customIcon) {
        this.svgIcon = this.customIcon;
        this.border.colour = 'transparent';
        this.background.colour = 'transparent';
      }

      this.svgPrecision = { rect: { x: 0, y: 0, w: 0, h: 0 }, text: { x: 19, y: 17, w: 0, h: 0 } };

      if (this.showValue) {
        switch (this.mfs) {
          case 10:
            this.svgWidth = this.svgWidth - 16;
            this.svgHeight = this.svgHeight - 8;
            this.svgIconDimensions = { w: 16, h: 12, x: -2, y: 0 };
            this.svgTextAdjust.y = -6;
            this.svgTextAdjust.x = -8;
            break;
          case 12:
            this.svgWidth = this.svgWidth - 8;
            this.svgHeight = this.svgHeight - 4;
            this.svgIconDimensions = { w: 18, h: 14, x: 0, y: 0 };
            this.svgTextAdjust.y = -4;
            this.svgTextAdjust.x = -4;
            break;
          case 16:
          case 17:
            this.svgWidth = this.svgWidth + 6;
            break;
          case 18:
          case 19:
          case 20:
            this.svgWidth = this.svgWidth + 16;
            this.svgPrecision = { rect: { x: 0, y: -1, w: 0, h: 3 }, text: { x: 19, y: 19, w: 0, h: 0 } };
            break;
          case 26:
          case 27:
          case 28:
          case 29:
          case 30:
            this.svgWidth = this.svgWidth + 34;
            this.svgPrecision = { rect: { x: 0, y: -5, w: 0, h: 11 }, text: { x: 21, y: 22, w: 0, h: 0 } };

            switch (this.assetType_id) {
              case 3: // Pressure
                this.svgIconDimensions.x = 0;
                this.svgIconDimensions.y = -2;
                this.svgIconDimensions.w = 26;
                this.svgIconDimensions.h = 26;
                this.svgWidth = this.svgWidth + 10;
                break;
              case 7:
              case 11:
              case 25: //co2
                this.svgIconDimensions = { h: 26, w: 22, x: 0, y: -2 };
                break;
              case 27: // voc
                this.svgIconDimensions = { h: 26, w: 26, x: -2, y: -2 };
                break;
              case 15: // footfall
                this.svgIconDimensions = { h: 26, w: 28, x: -2, y: 1 };
                break;
              case 17: // door
                this.svgWidth += 14;
                this.svgIconDimensions = { h: 36, w: 36, x: -7, y: -8 };
                break;
              case 51:
                this.svgIconDimensions = { h: 26, w: 26, x: -2, y: -2 };
                break;
              default: {
                this.svgIconDimensions = { h: 30, w: 30, x: -5, y: -5 };
              }
            }
        }
      } else {
        this.svgWidth = 20;
        if (this.mfs > 0) {
          switch (this.assetType_id) {
            case 50:
              this.svgIconDimensions.h = this.mfs;
              this.svgIconDimensions.w = this.mfs;
              this.svgWidth = this.mfs;
              this.svgHeight = this.mfs;
              break;
          }
          switch (this.purpose.id) {
            case 12: // presence
            case 6: // occupancy
              this.svgIconDimensions.h = this.mfs;
              this.svgIconDimensions.w = this.mfs;
              this.svgWidth = this.mfs;
              this.svgHeight = this.mfs;
              break;
          }
        }
      }

      if (isInteger) {
        this.value = this.value ? Number(this.value).toFixed(0) : this.value;
      }
    }

    switch (this.label) {
      case '%.':
        this.svgIcon = 'iot-humidity';
        break;
      case '°C.':
        this.svgIcon = 'iot-temp';
        break;
    }

    this.checkRAG();
  }

  setValue(value: string, updatedAt: Date) {
    this.value = value;
    this.updatedAt = updatedAt;
    this.processAsset();
  }

  checkRAG() {
    if (this.upData === 'value') {
      let rag = 'green';
      switch (this.upDataOp) {
        case '>':
          if (+this.originalValue > this.upDataValue) {
            rag = 'red';
          }
          break;
        case '<':
          if (+this.originalValue < this.upDataValue) {
            rag = 'red';
          }
          break;
        case '=':
          if (+this.originalValue === this.upDataValue) {
            rag = 'red';
          }
          break;
        case '!=':
          if (+this.originalValue !== this.upDataValue) {
            rag = 'red';
          }
          break;
        case '<=':
          if (+this.originalValue <= this.upDataValue) {
            rag = 'red';
          }
          break;
        case '>=':
          if (+this.originalValue >= this.upDataValue) {
            rag = 'red';
          }
          break;
      }
      this.rag = rag;
    }

    if (this.svgIcon) {
      this.svgIconPath = `assets/svg/${this.rag || 'green'}/${this.svgIcon}.svg`;
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-perc-change',
  templateUrl: './perc-change.component.html',
  styleUrls: ['./perc-change.component.css']
})
export class PercChangeComponent implements OnInit {

	@Input()
	value: number;

  constructor() { }

  ngOnInit() {
  }

}

<div class="row" *ngIf="order">
  <div class="no-print">
    <app-section-banner [section]="'orders'" [title]="'4DML Ordering'" [detail]="detail"></app-section-banner>
  </div>

  <div class="col-xs-12">
    <div class="component-list">
      <table class="table">
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <app-order-status-table-rows [hideTitleRow]="true" [order]="order"></app-order-status-table-rows>
        </tbody>
      </table>
    </div>
  </div>
  <!-- address -->
  <div class="col-xs-12">

    <blockquote>
      <p class="">{{order.site.title}}</p>
      <p class="">{{order.site.address.address1}}</p>
      <p class="">{{order.site.address.addressTown}}</p>
      <p class="">{{order.site.address.addressCounty}}</p>
      <p class="">{{order.site.address.addressPostcode}}</p>
    </blockquote>

  </div>

  <div class="col-xs-12 dates">
    <p class="">Deliver {{order.deliverAt | amTimeAgo }} for {{order.deliverAt |
      amDateFormat:apiService.getDateFormat()}}</p>
    <p class="">Install {{order.installAt | amTimeAgo }} for {{order.installAt |
      amDateFormat:apiService.getDateFormat()}}</p>
  </div>

  <div class="col-xs-12">
    <table class="table">
      <thead>
        <tr>
          <th>Survey Item</th>
          <th>Qty</th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-item [ngForOf]="orderCollections">
          <tr>
            <td>{{item.title}}</td>
            <td>{{item.qty}}</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>

  <div class="col-xs-12" *ngIf="canAuthorise">
    <button class="btn btn-success">Authorise this order</button>
    <button (click)="modifyOrder()" class="btn btn-primary ">Return this order for amendments</button> &nbsp;
    <button (click)="orderCancel()" class="btn btn-danger pull-right">Cancel order</button>
    <button (click)="orderCancel(true)" class="btn btn-danger pull-right pad-right">Cancel order & remove site</button>
  </div>
</div>

<p-confirmDialog [icon]="dialogIcon" header="Confirmation">

</p-confirmDialog>

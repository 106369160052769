@defer (when data) {
  <article [class.pagebreakbefore]="wantsPageBreak()">
    <section>
      <main class="header">
        <div>
          <div>
            <h3>{{ insight().types[type()].title }}</h3>
          </div>

          <div class="alarms">
            <div *ngFor="let alarm of alarmBlocks" class="alarm">
              <div><span class="mdi mdi-circle {{ alarm.colour }}"></span> {{ alarm.header }}</div>
              <div>{{ alarm.title }}</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <img src="{{ svgLocation }}icon-{{ type() }}.svg" alt="Sensor type" />
          </div>
          <div class="average">
            {{ grandTotals()[typeIndex].avg }} <small>{{ insight().types[type()].measurement }}</small>
          </div>
        </div>
      </main>
      <div class="mt-1">
        {{ insight().types[type()].description }}
      </div>

      <div class="gateways mt-1">
        @if (data?.telemetry) {
          <div class="chart">
            <app-d3-chart
              [hasToClickToInteract]="true"
              [canAnnotate]="false"
              [canZoom]="false"
              height="200"
              [telemetry]="data.telemetry"
              [limitLines]="limitLines"
              [showLegend]="true"
              [minMaxFromData]="true" />
          </div>
        }
        <table class="table">
          <thead>
            <tr>
              <td></td>
              <td>Sensor</td>
              <td>Average</td>
              <td>Lowest</td>
              <td>Highest</td>
            </tr>
          </thead>
          <tbody>
            @for (item of totals; track $index) {
              <tr>
                <td colspan="5">
                  <div class="chart">
                    <app-d3-chart
                      [hasToClickToInteract]="true"
                      [canAnnotate]="false"
                      [canZoom]="false"
                      height="200"
                      [telemetry]="item.telemetry"
                      [limitLines]="limitLines"
                      [showLegend]="true"
                      [minMaxFromData]="true" />
                  </div>
                </td>
              </tr>
              <tr>
                <td><img src="{{ svgLocation }}icon-{{ type() }}.svg" alt="Sensor type" /></td>
                <td>{{ item.asset.title }}</td>

                @if (item.totals) {
                  <td>
                    <span class="mdi mdi-checkbox-blank-circle  {{ insight().getAlarmColourForValue(type(), item.totals?.avg) }}"></span> {{ item.totals?.avg
                    }}<small>{{ insight().types[type()].measurement }}</small>
                  </td>
                  <td>
                    <span class="mdi mdi-checkbox-blank-circle  {{ insight().getAlarmColourForValue(type(), item.totals?.min) }}"></span> {{ item.totals?.min
                    }}<small>{{ insight().types[type()].measurement }}</small>
                  </td>
                  <td>
                    <span class="mdi mdi-checkbox-blank-circle {{ insight().getAlarmColourForValue(type(), item.totals?.max) }}"></span> {{ item.totals?.max
                    }}<small>{{ insight().types[type()].measurement }}</small>
                  </td>
                } @else {
                  <td colspan="3">x {{ item | json }}</td>
                }
              </tr>
            }
          </tbody>
        </table>
      </div>
    </section>
  </article>
} @placeholder {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
}

<article>
	<div class="mobile-menu">
		<div class="menu-header">
			<div>
				<img src="assets/svg/4d_logo_dark-fourd.svg" alt="logo" aria-label="logo" />
			</div>
			<div>
				<i (click)="onMenuClick.emit(null)" class="mdi mdi-window-close"></i>
			</div>
		</div>
		<div class="menu-items">
			@if(!_isRestricted() && moduleAccess) {
			<div (click)="click('dashboard')">
				Dashboard
			</div>
			@if(moduleAccess?.organisation) {
			<div (click)="click('org')">
				Org
			</div>
			}
			@if(moduleAccess?.reports) {
			<div (click)="click('reports')">
				Reports
			</div>
			}
			@if(moduleAccess?.live) {
			<div (click)="click('realtime')">
				Realtime
			</div>
			}
			@if(moduleAccess?.footfall) {
			<div (click)="click('footfall')">
				Footfall
			</div>
			}
			@if(moduleAccess?.training) {
			<div (click)="click('training')">
				Training
			</div>
			}
			@if(moduleAccess?.engagement) {
			<div (click)="click('engagement')">
				Engagement
			</div>
			}
			@if(moduleAccess?.partnership_engagement) {
			<div (click)="click('engage')">
				Engagement
			</div>
			}
			@if(moduleAccess?.camera) {
			<div (click)="click('camera')">
				Camera
			</div>
			}
			@if(moduleAccess?.energy) {
			<div (click)="click('energy')">
				Energy
			</div>
			}
			@if(moduleAccess?.insights) {
			<div (click)="click('insights')">
				Insights
			</div>
			}
			<div (click)="click('profile')">
				Your Account
			</div>

			} @else {
			@if(_isRestricted() ) {
			<div *ngIf="user.modules.has('Profiling')" (click)="click('profiling')">
				<i class="fa fa-fw fa-building-o"></i> Floorplans
			</div>

			<div *ngIf="user.modules.has('Billing')" (click)="click('org')">
				<i class="fa fa-fw fa-wifi"></i> Billing
			</div>
			<div *ngIf="user.modules.has('Live')" (click)="click('live')">
				<i class="fa fa-fw fa-area-chart"></i> Live Feed
			</div>

			<div (click)="click('profile')">
				<i class="fa fa-fw fa-user-circle-o"></i> Your Account
			</div>
			}
			}
			<div class="bottom" (click)="click('login')">
				<i class="fa fa-fw fa-sign-out"></i> Logout
			</div>
		</div>
	</div>
</article>

@defer( when user) {
<article>
	<div>
		<div>
			<label>Name: </label>
			<input class=" fourd" maxlength="50" [(ngModel)]="user.name" name="name">
		</div>
		<div>
			<label>Email: </label>
			<input type="email" class="fourd" maxlength="250" [(ngModel)]="user.email" name="email">
		</div>
	</div>

	<div>
		<div>
			<label>Job Title: </label>
			<div>
				<input class="fourd" maxlength="50" placeholder="optional" [(ngModel)]="user.job_title" name="job_title">
			</div>
		</div>
	</div>

	<div>
		<div>
			<label>Notes: </label>
			<div>
				<textarea class="fourd" maxlength="1000" rows="2" [(ngModel)]="user.notes" placeholder="optional" name="notes"></textarea>
			</div>
		</div>
	</div>

	<footer class="mb-1">
		<p>Please note: This contact is for email notifications and will be unable to login.</p>
		<div class="buttons">
			<div>@if(user.id) {
				<app-button [isDanger]="true" icon="mdi mdi-trash-can-outline" (click)="deleteContact()" label="Delete contact" />
				}
			</div>
			<div>
				<app-button icon="mdi mdi-close" class=" mr-1" (click)="cancel()" label="Cancel" />
				<app-button [isCTA]="true" icon="mdi mdi-check" (click)="submitContact()" [label]="user.id ? 'Update contact' : 'Create contact'" />
			</div>
		</div>
	</footer>
</article>
}

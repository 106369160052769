import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APIService } from './api.service';
import { Site } from 'app/classes/site';
import { Org } from 'app/classes/org';
@Injectable({
  providedIn: 'root'
})
export class EngagementService {

  static readonly URL = 'https://mztvq2lbzd.execute-api.eu-west-2.amazonaws.com/prod/';

  constructor(private apiService: APIService, private http: HttpClient) { }

  async getConfig(): Promise<any> {
    const response = await this.get('config');

    return response;
  }

  /**
   * Get users routes for org
   */
  async getRoutes(startsAt: Date, endsAt: Date, type: 'org', includeMyOrg = false, wantsAdjustment = true): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const dbStartsAt = this.apiService.dbDate(startsAt);
      const dbEndsAt = this.apiService.dbDate(endsAt);
      const qs = `startsat=${dbStartsAt}&endsat=${dbEndsAt}&type=${type}&adjustBy=${wantsAdjustment ? 1 : 0}&myorg=${includeMyOrg ? 1 : 0}`;

      const routes = await this.get("routes", qs);

      if (routes.errorMessage) {
        reject(routes.errorMessage);

        return;
      }

      resolve(routes);
    });
  };

  async get(route: string, qs: string = ''): Promise<any> {
    return new Promise(async (resolve) => {
      const url = `${EngagementService.URL}${route}?${qs}`;

      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => resolve(b));
    });
  }
}


export class EngagementSharedSite {
  site: Site;
  orgs: EngagementSharedSiteOrg[];

  constructor(data: { id: number, title: string, orgs: { id: number, title: string }[] }) {
    this.site = new Site({ id: data.id, title: data.title });
    this.orgs = data.orgs.map(org => new EngagementSharedSiteOrg(org));
  }

  toString() {
    return this.orgs.map(o => o.org.title).join(', ');
  }
}

export class EngagementSharedSiteOrg {
  org: Org;
  count: number = 0;

  constructor(org: any) {
    this.org = new Org(org);
  }
}

export class EngagementUserLogItem {
  action: 'route' | 'update';
  actionedAt: Date;
  assetTitle: string;
  createdAt: Date;
  gatewayTitle: string;
  orgId: number;
  path: string;
  siteId: number;
  siteTitle: string;
  title: string;
  userName: string;

  constructor(data) {
    this.action = data.action;
    this.actionedAt = new Date(data.actionedAt);
    this.assetTitle = data.assetTitle;
    this.createdAt = new Date(data.createdAt);
    this.gatewayTitle = data.gatewayTitle;
    this.orgId = data.orgId;
    this.path = data.path;
    this.siteId = data.siteId;
    this.siteTitle = data.siteTitle;
    this.userName = data.userName;
    let title = '';
    switch (data.path) {
      case '/live':
        title = 'Live listing';
        break;
      case '/reports':
        title = 'Reports listing';
        break;
      case '/profiling':
        title = 'floorplan listing';
        break;
      case '/dashboard':
        title = 'Dashboard tiles';
        break;
      case '/dashboard/alarms-resolved-today':
        title = 'Alarms resolved today';
        break;
      case '/dashboard/gateways-online':
        title = 'Gateways online list';
        break;
      case '/dashboard/assets-inerror':
        title = 'Assets in error list';
        break;
      case '/dashboard/gateways-telemetry-today':
        title = 'Telemetry today list';
        break;
      case '/dashboard/gateways-disconnected':
        title = 'Gateways disconnected list';
        break;
      case '/realtime':
        title = 'Realtime';
        break;
      case '/setpoints/overview':
        title = 'Setpoint overview';
        break;

      default:
        if (this.path.indexOf('/download') > 0) {
          title = `Document downloaded`;
        } else if (this.path.indexOf('/generate') > 0) {
          title = `Report generated ${this.path}`;
        } else if (this.path.indexOf('/profiling') >= 0) {
          title = `Floorplan`;
        } else if (this.path.indexOf('/live/awair') >= 0) {
          title = `Live Awair`;
        } else if (this.path.indexOf('/live/dt_') >= 0) {
          title = `Live Tiles`;
        }
        break;
    }
    this.title = title;
  }
}

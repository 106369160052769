<div *ngIf="element === 'title'">
    <h2>Organisation Title</h2>
    <p>This is the name of the organisation.</p>
    <p>50 characters maximum</p>
</div>

<div *ngIf="element === 'shortTitle'">
    <h2>Short Title</h2>
    <p>This is a short version of the organisation title, this is displayed when there isn't much room on the screen.
    </p>
    <p>20 characters maximum</p>
</div>

<div *ngIf="element === 'isBillable'">
    <h2>Is Billable</h2>
    <p>If this organisation is to be billed, set to 'Yes'. If this is not billable, for example if it is initially a
        trial, you can set to 'No'.
    </p>
    <p>Yes or No</p>
</div>

<div *ngIf="element === 'isActive'">
    <h2>Status</h2>
    <p>If this organisation isn't ready to go live or is no longer required you can set to disabled.<br> When disabled
        no data is processed and this organisation won't appear in any lists other than the org listing here, and will
        be shown as disabled.
    </p>
    <p>Enabled or Disabled</p>
</div>

<div *ngIf="element === 'editor'">
    <h2>Notes</h2>
    <p>These notes are not visible to users, and only appear in the manage section.</p>
    <p>65,000 Characters</p>
</div>

<div *ngIf="element === 'addressTitle'">
        <h2>Address Title</h2>
        <p>The company name for the address, usually the organisations title</p>
        <p>50 Characters</p>
    </div>


<div *ngIf="element === 'address1'">
        <h2>Address Line 1</h2>
        <p>The first line of the organisations address</p>
        <p>50 Characters</p>
    </div>

<div class="recent inset can-click" routerLink="/setpoints/summary">
    <div class="rag">
        <div class="r large-text">
            <i class="fa fa-circle fa-fw"></i>
            <span *ngIf="totals">{{totals.red}}</span>
            <span *ngIf="!totals"><i class="fa fa-fw fa-spin fa-refresh"></i></span>
        </div>
        <div class="a large-text">
            <i class="fa fa-circle fa-fw"></i>
            <span *ngIf="totals">{{totals.amber}}</span>
            <span *ngIf="!totals"><i class="fa fa-fw fa-spin fa-refresh"></i></span>
        </div>
        <div class="g large-text">
            <i class="fa fa-circle fa-fw"></i>
            <span *ngIf="totals">{{totals.green}}</span>
            <span *ngIf="!totals"><i class="fa fa-fw fa-spin fa-refresh"></i></span>
        </div>
    </div>

</div>

<!-- used in gateway and building licenses -->


<main>
  <!-- <section *ngIf="license.gateway">
    There is no gateway license setup, to add a license on top of any building license <button
      class="btn bt-sm btn-secondary" (click)="createGatewayLicense()"> <i class="fa fa-plus"></i> click
      here</button>
  </section>-->

  <section>
    <div class="row">
      <div class="top-container">
        <div class="left-container">
          <div class="flex line-1">
            <div>
              <div class="form-group">
                <label for="package">Package</label>
                <select id="package" name="package" class="form-control" [(ngModel)]="license.package.id">
                  <ng-template ngFor [ngForOf]="packages" let-package>

                    <option *ngIf="(license.gateway && package.id >=5) || (!license.gateway && package.id <5)" [value]="package.id">
                      {{package.title}}</option>
                  </ng-template>
                </select>
              </div>
            </div>

            <div>
              <div class="form-group">
                <label for="bCycle">Billing Cycle</label>
                <select id="bCycle" name="bCycle" class="form-control" [(ngModel)]="license.billingCycle">
                  <option *ngFor="let cycle of billingCycles" [value]="cycle.id">{{cycle.title}}</option>
                </select>
              </div>
            </div>

            <div>
              <div class="form-group" *ngIf="billingClients">
                <label for="bClients">Billing Client</label>
                <select id="bClients" name="bClients" class="form-control" [(ngModel)]="license.billingClient.id" (change)="billingClientChanged()">
                  <option [ngValue]="null">No client (default)</option>
                  <option *ngFor="let client of billingClients" [value]="client.id">{{client.title}}</option>
                  <option [value]="9999">Add new client....</option>
                </select>
              </div>
            </div>


            <div>
              <div class="form-group">
                <label for="notes">License Status</label>
                <select id="active" name="active" class="form-control" [(ngModel)]="license.isActive">
                  <option [ngValue]="true">Active</option>
                  <option [ngValue]="false">Disabled</option>
                </select>
                <small>
                  <span *ngIf="license.isActive" class="green"><i class="mdi mdi-certificate"></i> Included in
                    billing</span>
                  <span *ngIf="!license.isActive" class="red"><i class="mdi mdi-close"></i> Not included in billing</span>
                </small>
              </div>
            </div>

            <div>
              <div class="form-group">
                <label for="notes">How license expires</label>
                <select id="active" name="active" class="form-control" [(ngModel)]="license.neverExpires">
                  <option [ngValue]="true">Never expires (perpetual)</option>
                  <option [ngValue]="false">Expiry date</option>
                </select>
              </div>
            </div>
          </div>
          <div class="notes">
            <div class="form-group">
              <div for="notes">Notes</div>
              <textarea class="fourd" [(ngModel)]="license.notes" [style]="{'height':'80px'}"></textarea>
            </div>
          </div>
        </div>

        <div class="right-container">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="form-group">
              <label for="value">Value £</label>
              <input id="value" name="value" maxlength="7" class="form-control" type="number" [(ngModel)]="license.value">
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="form-group">
              <label for="commission">Commission £</label>
              <input id="commission" name="commission" maxlength="7" class="form-control" type="number" [(ngModel)]="license.commission">
            </div>
          </div>

        </div>
      </div>

      <section class="dates">
        <div>
          <label>Licensed At</label>
          <div class="form-group">
            <p-calendar firstDayOfWeek="1" [selectOtherMonths]="true" [(ngModel)]="license.licensedAt" (onSelect)="dateBlur()" [locale]="en" [inline]="true">
            </p-calendar>
          </div>
        </div>

        <div>
          <label>Expires At </label>
          <div class="form-group" [class.disabled]="license.neverExpires">
            <p-calendar firstDayOfWeek="1" [selectOtherMonths]="true" [(ngModel)]="license.expiresAt" [locale]="en" [inline]="true"></p-calendar>
          </div>
        </div>
      </section>




      <div class="col-xs-12 col-sm-12" *ngIf="!license.id">
        <input type="checkbox" [(ngModel)]="addReportsToCalendar"> Add Reports to calendar
        <!-- <app-calendar-list *ngIf="addReportsToCalendar" [entries]="entries"></app-calendar-list>-->
      </div>

      <div class="col-sm-12">
        <app-button (click)="submit()" class="btn btn-success btn-block" [label]="'Submit ' + (license.gateway ? 'gateway license' :  'building license')" />&nbsp;
      </div>
      <!--
      <div class="col-sm-4">
        <button [disabled]="!license.id" (click)="delete()" class="btn btn-danger btn-block"><span
            *ngIf="confirmDelete">Are
            You sure?</span> <span *ngIf="!confirmDelete">Delete</span></button>
      </div>
      -->
    </div>
  </section>


</main>

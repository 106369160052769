import { Component, OnInit, signal } from '@angular/core';
import { User } from 'app/classes/user';
import { APIService } from 'app/shared/api.service';
import { WindowService } from 'app/shared/window.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-org-contacts-home',
  templateUrl: './org-contacts-home.component.html',
  styleUrls: ['./org-contacts-home.component.css']
})
export class OrgContactsHomeComponent implements OnInit {

  isShowingDialog = signal<boolean>(false);
  isLoading: boolean;
  contacts: any[];
  contact = signal<User>(null);

  constructor(private apiService: APIService, public messageService: MessageService, private windowService: WindowService) {

  }

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.isLoading = true;
    this.apiService.getUsersForOrg('a=contacts').then(
      users => {
        this.contacts = users.filter(u => u.role === 'contact');
        this.isLoading = false;
      }
    );
  }

  editContact(contact: User) {
    this.contact.set(contact);
    this.isShowingDialog.set(true);
  }

  createContact() {
    this.isShowingDialog.set(true);
    this.contact.set(new User());
  }

  hasSaved(contact: any) {
    this.isShowingDialog.set(false);
    if (contact) {
      this.messageService.add({ severity: 'info', summary: 'Updated contact', detail: '' });
      this.get();
    }
  }

  hasDeleted(contact: any) {
    this.messageService.add({ severity: 'info', summary: 'Deleted contact', detail: '' });
    this.isShowingDialog.set(false);
    this.get();
  }

  hasClosed() {
    this.isShowingDialog.set(false);
  }
}

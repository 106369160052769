import { Component, signal } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { FootfallService, IFootfallArea } from 'app/shared/footfall.service';

@Component({
  selector: 'app-foorfall-areas',
  templateUrl: './foorfall-areas.component.html',
  styleUrl: './foorfall-areas.component.css'
})
export class FoorfallAreasComponent {
  areas = signal<IFootfallArea[]>(null);
  area = signal<IFootfallArea>(null);
  isFullscreen = signal<boolean>(false);

  constructor(private apiService: APIService, private footfallService: FootfallService) {
    footfallService.getAreas().then(areas => {
      this.areas.set(areas);
      this.area.set(this.areas()[0]);
    });
  }

  selectArea(area: IFootfallArea) {
    this.area.set(null)
    setTimeout(() => {
      this.area.set(area);
    }, 10);
  }

  toggleFullscreen() {
    this.isFullscreen.update(value => !value);
  }
}

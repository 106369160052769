import { User } from "./user";
export class Message {
    id: number;
    body: string;
    createdBy: User;
    createdAt:  Date;
    constructor(data?: any) {
        if (data) {
            this.id = data.id;
            this.body = data.body;
            
            this.createdBy = new User();
            this.createdBy.id =  data.createdBy;
            this.createdBy.name =  data.createdByName;
            this.createdAt = data.createdAt;
        }
    }
}

@if(modules) {
<div class="modules">
  <div>
    <div class="tabview-list">
      <div [class.active]="tabIndex()===0" (click)="tabIndex.set(0)"><i class="mdi mdi-play"></i> Course Learning Materials</div>
      <!--<div [class.active]="tabIndex()===1" (click)="tabIndex.set(1)"><i class="mdi mdi-file-document-multiple-outline"></i> Additional Course Resources</div>-->
      <div> </div>
    </div>
    @switch(tabIndex()) {
    @case(0) {
    @for(module of modules;track module) {
    <div class="item">
      <app-training-module-list-item [nextModule]="nextModule" [module]="module" (click)="selectModule(module)">
      </app-training-module-list-item>
    </div>
    }
    }
    @case(1) {
    <div class="resources">
      <!--<div class="hidden">
        <div class="col-md-8 col-sm-12">
          4D Portal training document
        </div>
        <div class="col-md-4 col-sm-12 text-right">
          <a class="btn btn-sm btn-primary" href="https://s3-eu-west-1.amazonaws.com/4d-training/resources/4d-portal-training.pdf">
            <i class="fa fa-fw fa-file-pdf-o"></i> View</a>
        </div>
      </div>-->
      <table class="table mt-2">
        <thead>
          <tr>
            <th colspan="2">Document</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Glossary of terms used in this 4D Installation Training Course
            </td>
            <td>
              <a href="https://s3-eu-west-1.amazonaws.com/4d-training/resources/4d-glossary.pdf" target="_blank">
                <app-button size="small"><i class="fa fa-fw fa-file-pdf-o"></i> View</app-button></a>
            </td>
          </tr>
          <tr>
            <td>
              A handy guide for RJ45 Plug Termination.. keep it on your phone!
            </td>
            <td>
              <a href="https://s3-eu-west-1.amazonaws.com/4d-training/resources/rj45plugtermination.pdf" target="_blank">
                <app-button size="small"><i class="fa fa-fw fa-file-pdf-o"></i> View</app-button>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    }
    }



  </div>
</div>
}

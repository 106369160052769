import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { StoreService } from "../../shared/store.service";
import { Gateway } from "../../classes/gateway";
import { Asset } from "../../classes/asset";

@Component({
  selector: 'app-sensor-picker',
  templateUrl: './sensor-picker.component.html',
  styleUrls: ['./sensor-picker.component.css']
})
export class SensorPickerComponent implements OnInit, OnDestroy {
  static readonly componentName = 'SensorPickerComponent';

  @Output()
  submit: EventEmitter<Asset> = new EventEmitter<Asset>();
  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  gateway: Gateway;

  assets: Asset[];
  asset: Asset;

  selected = {
    types: []
  };

  constructor(private router: Router, private route: ActivatedRoute, private storeService: StoreService, private apiService: APIService) {
    // All asset types are selected by default
    for (let index = 0; index < 40; index++) {
      this.selected.types[index] = true;
    }
    this.loadOptions();
  }

  ngOnInit() {
    this.apiService.getAssetsForGatewayId(this.gateway.id).then(assets => {
      this.assets = assets.sort(function (a, b) {
        return a.assetType_id - b.assetType_id;
      });
    });
  }

  selectAsset(asset: Asset) {
    this.submit.emit(asset);
  }


  saveOptions() {
    localStorage.setItem(this.apiService.getUserOrg().id + ':' + this.apiService.getUserId() + ':' + SensorPickerComponent.componentName, JSON.stringify(this.selected));
  }

  loadOptions() {
    let payload: any = localStorage.getItem(this.apiService.getUserOrg().id + ':' + this.apiService.getUserId() + ':' + SensorPickerComponent.componentName);
    if (payload) {
      try {
        this.selected = JSON.parse(payload);
      } catch (e) { console.log(e) }
    }
  }

  ngOnDestroy() {
    this.saveOptions();
  }
}


<P CLASS="western" ALIGN=CENTER STYLE="margin-bottom: 0.42cm">

	<B>PRIVACY POLICY
	</B>

</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">4D Monitoring Ltd (&ldquo;<B>We</B>&rdquo;) are committed to protecting
	and respecting your privacy.</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">This policy (together with our <A HREF="/policies/terms">terms of use
	</A>
	and any other documents referred to on it) sets out the basis on which any Personal Data we collect from you, or that
	you
	provide to us, will be processed by us. Please read the following carefully to understand our views and practices
	regarding
	your Personal Data and how we will treat it. Before using our website you are required to give specific consent to the
	processing
	of your Personal Data for the purposes set out under section &ldquo;Uses made of the information&rdquo; which you have
	done
	by ticking the data processing consent box on our website.
</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">For the purpose of the Data Protection Laws.</P>
<P CLASS="western" STYLE="margin-bottom: 0cm">[Our nominated representative for the purpose of the Data Protection Laws
	is Tony Porter].
</P>
<P CLASS="western" STYLE="margin-bottom: 0cm">
	<BR>
</P>
<P STYLE="margin-left: 1.27cm; text-indent: -1.27cm; margin-bottom: 0.42cm">
	<B>Definitions</B>
</P>
<P STYLE="margin-left: 5.08cm; text-indent: -5.08cm; margin-bottom: 0.42cm">
	<B>&quot;Applicable Law&quot;</B>

	means (a) any law, statute, regulation, byelaw or subordinate legislation in force from time to time to which a Party
	is
	a subject and/or in any jurisdiction that the Master Agreement is performed in; (b) the common law and laws of equity
	as
	applicable to the Parties from time to time; (c) any binding court order, judgment or decree; (d) any applicable
	industry
	code, policy or standard; (e) any applicable direction, policy, rule or order that is binding on a party and that is
	made
	or given by any regulatory body having jurisdiction over a Party or any of that Party's assets, resources or business;
</P>
<P STYLE="margin-left: 5.08cm; text-indent: -5.08cm; margin-bottom: 0.42cm">
	<B>&quot;Data Protection Laws&quot;</B>
	means any Applicable Law relating to the processing, privacy, and use of Personal Data, including (a) in the United
	Kingdom,
	(i) the Data Protection Act 1998 and the Privacy and Electronic Communications (EC Directive) Regulations 2003, SI
	2003/2426,
	and any laws or regulations implementing Directive 95/46/EC (Data Protection Directive) or Directive 2002/58/EC
	(ePrivacy
	Directive); and/or (ii) the General Data Protection Regulation (EU) 2016/679 (GDPR), and/or any corresponding or
	equivalent
	national laws or regulations (Revised UK DP Law) (b) in member states of the European Union, the Data Protection
	Directive
	or the GDPR, once applicable, and the ePrivacy Directive, and all relevant member state laws or regulations giving
	effect
	to or corresponding with any of them; and (c) any judicial or administrative interpretation of any of the above, any
	guidance,
	guidelines, codes of practice, approved codes of conduct or approved certification mechanisms issued by any relevant
	Supervisory
	Authority;
</P>
<P STYLE="margin-left: 5.08cm; text-indent: -5.08cm; margin-bottom: 0.42cm">
	<B>&quot;Data&quot;</B>
	means information which is stored electronically, on a computer, or in certain paper-based filing systems;
</P>
<P STYLE="margin-left: 5.08cm; text-indent: -5.08cm; margin-bottom: 0.42cm">
	<A NAME="main"></A>
	<B>&quot;Personal Data&quot;</B>
	means any information relating to an identified or identifiable natural person (&quot;Data Subject&quot;); an
	identifiable
	natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such
	as
	a name, an identification number, location data, an online identifier or to one or more factors specific to the
	physical,
	physiological, genetic, mental, economic, cultural or social identity of that natural person and where referred to in
	this
	policy includes special categories of Personal Data.
</P>
<P CLASS="western" STYLE="margin-bottom: 0cm">
	<B>Information we collect from you</B>
</P>
<P CLASS="western" STYLE="margin-bottom: 0cm">
	<BR>
</P>
<P CLASS="western" STYLE="margin-bottom: 0cm">We will collect and process the following Data about you:</P>
<P CLASS="western" STYLE="margin-bottom: 0cm">
	<BR>
</P>
<UL>
	<LI>
		<P CLASS="western">
			<B>Information you give us.</B> This is information about you that you give us by filling in forms on our sites
			(&ldquo;<B>our site</B>&rdquo;) or by corresponding with us by phone, e-mail or otherwise. It includes information
			you provide when
			you register to use our site, subscribe to our service, participate in discussion boards or other social media
			functions
			on our site], [enter a competition, promotion or survey], and when you report a problem with our site. The
			information
			you give us may include your name, address, e-mail address and phone number, financial and credit card
			information, personal
			description and photograph.
		</P>
	<LI>
		<P CLASS="western">
			<B>Information we collect about you.</B> With regard to each of your visits to our site we will automatically
			collect the
			following information:
		</P>
</UL>
<UL>
	<UL>
		<LI>
			<P CLASS="western">technical information, including [the Internet protocol (IP) address used to connect your
				computer to the Internet,] [your
				login information,] [browser type and version,] [browser plug-in types and versions,] [operating system and
				platform,]
			</P>
		<LI>
			<P CLASS="western">
				<A NAME="_GoBack"></A>information about your visit, including [the full Uniform Resource Locators (URL),]
				[clickstream to, through and
				from our site (including date and time),] [products you viewed or searched for&rsquo;] [page response times,]
				[download
				errors,] [length of visits to certain pages,] [page interaction information (such as scrolling, clicks, and
				mouse-overs),]
				[methods used to browse away from the page,] and any phone number used to call our customer service number.
			</P>
	</UL>
</UL>
<UL>
	<LI>
		<P CLASS="western">
			<B>Information</B>
			<B>we receive from other sources.
			</B> This is information we receive about you if you use any of the other websites we operate or the other
			services we
			provide. [In this case we will have informed you when we collected that data if we intend to share those data
			internally
			and combine it with data collected on this site. We will also have told you for what purpose we will share and
			combine
			your data]. We are working closely with third parties (including, for example, business partners, sub-contractors
			in technical,
			payment and delivery services, advertising networks, analytics providers, search information providers, credit
			reference
			agencies). We will notify you when we receive information about you from them and the purposes for which we intend
			to
			use that information.
		</P>
</UL>
<P CLASS="western" STYLE="margin-bottom: 0cm">
	<BR>
</P>
<P CLASS="western" STYLE="margin-bottom: 0cm">
	<B>Cookies</B>
</P>
<P CLASS="western" STYLE="margin-bottom: 0cm">
	<BR>
</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">Our website uses cookies to distinguish you from other users of our
	website. This helps us to provide you with a good experience
	when you browse our website and also allows us to improve our site. For detailed information on the cookies we use and
	the
	purposes for which we use them see our <A CLASS="western" HREF="/policies/cookies">Cookie Policy</A>.</P>
<P STYLE="margin-bottom: 0.42cm">
	<B>Uses made of the information</B>
</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">We use information held about you in the following ways:</P>
<OL>
	<LI>
		<P CLASS="western">processing of an enquiry form completed by you;
		</P>
	<LI>
		<P CLASS="western">processing a request for a catalogue or expressing an interest in one or more of our products;
		</P>
	<LI>
		<P CLASS="western">providing your information to our marketing department who may contact you by post, telephone or
			email to request feedback
			and comments on our services or to provide information to you which may be of interest to you;</P>
	<LI>
		<P CLASS="western">providing your information to our agents and service providers for the purposes set out in 3
			above;</P>
	<LI>
		<P CLASS="western">if applicable processing your application for employment and we shall retain a copy of your CV in
			case of suitable
			future opportunities.
		</P>
</OL>
<P CLASS="western" STYLE="margin-left: 1.27cm"> </P>
<UL>
	<LI>
		<P CLASS="western">
			<B>Information you give to us.</B> We will use this information:
		</P>
</UL>
<UL>
	<UL>
		<LI>
			<P CLASS="western">to carry out our obligations arising from any contracts entered into between you and us and to
				provide you with the information,
				products and services that you request from us;
			</P>
		<LI>
			<P CLASS="western">to provide you with information about other goods and services we offer that are similar to
				those that you have already
				purchased or enquired about;</P>
		<LI>
			<P CLASS="western">to provide you, or permit selected third parties to provide you, with information about goods
				or services we feel may
				interest you. If you are an existing customer, we will only contact you by electronic means (e-mail or SMS) with
				information
				about goods and services similar to those which were the subject of a previous sale or negotiations of a sale to
				you.
				If you are a new customer, and where we permit selected third parties to use your data, we (or they) will
				contact you
				by electronic means only if you have consented to this. If you do not want us to use your data in this way, or
				to pass
				your details on to third parties for marketing purposes, you will be able to withdraw your consent by contacting
				<A CLASS="western" HREF="mailto:askus@4dml.com">askus&#64;4dml.com</A>;
			</P>
		<LI>
			<P CLASS="western">to notify you about changes to our service; and
			</P>
		<LI>
			<P CLASS="western">to ensure that content from our site is presented in the most effective manner for you and for
				your computer.
			</P>
	</UL>
	<LI>
		<P CLASS="western">
			<B>Information we collect about you.</B> We will use this information:
		</P>
		<UL>
			<LI>
				<P CLASS="western">to administer our site and for internal operations, including troubleshooting, data analysis,
					testing, research, statistical
					and survey purposes;</P>
			<LI>
				<P CLASS="western">to improve our site to ensure that content is presented in the most effective manner for you
					and for your computer;
				</P>
			<LI>
				<P CLASS="western">to allow you to participate in interactive features of our service, when you choose to do so;
				</P>
			<LI>
				<P CLASS="western">as part of our efforts to keep our site safe and secure;</P>
			<LI>
				<P CLASS="western">to measure or understand the effectiveness of advertising we serve to you and others, and to
					deliver relevant advertising
					to you;</P>
			<LI>
				<P CLASS="western">to make suggestions and recommendations to you and other users of our site about goods or
					services that may interest
					you or them.</P>
		</UL>
</UL>
<UL>
	<LI>
		<P CLASS="western" STYLE="margin-bottom: 0.42cm">
			<B>Information we receive from other sources.</B> We will combine this information with information you give to us
			and information
			we collect about you. We will use this information and the combined information for the purposes set out above
			(depending
			on the types of information we receive).
		</P>
</UL>
<P STYLE="margin-bottom: 0.42cm">
	<B>Disclosure of your information</B>
</P>
<P CLASS="western">You agree that we have the right to share your personal information with:</P>
<UL>
	<LI>
		<P CLASS="western">Selected third parties including:</P>
</UL>
<UL>
	<UL>
		<LI>
			<P CLASS="western">business partners, suppliers and sub-contractors for the performance of any contract we enter
				into with them or you;</P>
		<LI>
			<P CLASS="western">advertisers and advertising networks that require the data to select and serve relevant adverts
				to you and others. [We
				do not disclose information about identifiable individuals to our advertisers, but we will provide them with
				aggregate
				information about our users (for example, we may inform them that 500 men aged under 30 have clicked on their
				advertisement
				on any given day). We may also use such aggregate information to help advertisers reach the kind of audience
				they want
				to target (for example, women in SW1). We may make use of the Personal Data we have collected from you to enable
				us
				to comply with our advertisers&rsquo; wishes by displaying their advertisement to that target audience;]
			</P>
		<LI>
			<P CLASS="western">analytics and search engine providers that assist us in the improvement and optimisation of our
				site;</P>
		<LI>
			<P CLASS="western" STYLE="margin-bottom: 0cm">credit reference agencies for the purpose of assessing your credit
				score where this is a condition of us entering into
				a contract with you.</P>
	</UL>
</UL>
<P STYLE="margin-left: 3cm; margin-bottom: 0cm">
	<BR>
</P>
<P CLASS="western" STYLE="margin-bottom: 0cm">We will disclose your personal information to third parties:</P>
<P CLASS="western" STYLE="margin-bottom: 0cm">
	<BR>
</P>
<UL>
	<LI>
		<P CLASS="western">In the event that we sell or buy any business or assets, in which case we will disclose your
			Personal Data to the prospective
			seller or buyer of such business or assets.</P>
	<LI>
		<P CLASS="western">If 4D Monitoring Ltd or substantially all of its assets are acquired by a third party, in which
			case Personal Data held
			by it about its customers will be one of the transferred assets.
		</P>
	<LI>
		<P CLASS="western" STYLE="margin-bottom: 0cm">If we are under a duty to disclose or share your Personal Data in
			order to comply with any legal obligation, or in order
			to enforce or apply our <A CLASS="western" HREF="/policies/terms">Terms of Use</A> and other agreements; or to
			protect the rights, property, or safety of 4D Monitoring Limited, our customers, or others. This includes
			exchanging information with other companies and organisations for the purposes of fraud protection and credit risk
			reduction.</P>
</UL>
<P STYLE="margin-left: 1.5cm; margin-bottom: 0cm; line-height: 0.53cm">
	<BR>
</P>
<P STYLE="margin-bottom: 0.42cm">
	<B>Where we store your Personal Data</B>
</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">Where we have given you (or where you have chosen) a password which
	enables you to access certain parts of our site, you
	are responsible for keeping this password confidential. We ask you not to share a password with anyone.
</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">Unfortunately, the transmission of information via the internet is not
	completely secure. Although we will do our best to
	protect your Personal Data, we cannot guarantee the security of your data transmitted to our site; any transmission is
	at
	your own risk. Once we have received your information, we will use strict procedures and security features to try to
	prevent
	unauthorised access.</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">
	<B>Your rights</B>
</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">You have the right to ask us not to process your Personal Data for
	marketing purposes. We will usually inform you (before
	collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to
	any
	third party for such purposes. You can exercise your right to prevent such processing by notifying
	<A CLASS="western" HREF="mailto:askus@4dml.com">askus&#64;4dml.com</A>.
</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">Our site may, from time to time, contain links to and from the websites
	of our partner networks, advertisers and affiliates.
	If you follow a link to any of these websites, please note that these websites have their own privacy policies and
	that
	we do not accept any responsibility or liability for these policies. Please check these policies before you submit any
	Personal
	Data to these websites.</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">
	<B>Access to information
	</B>
</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">The Data Protection Laws give you the right to access information held
	about you. Your right of access can be exercised in
	accordance with the Data Protection Law. You also have the right to rectify Personal Data where it is no longer
	correct.
	If you wish to do so please contact us as set out below.</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">
	<B>Right to Lodge a Complaint with Information Commissioner's Office (&quot;ICO&quot;)</B>
</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">If you have any complaints about the way in which we process your
	Personal Data please do contact us, as set out below. Alternatively
	you have the right to lodge a formal complaint with the ICO.</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">
	<B>Changes to our privacy policy</B>
</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">Any changes we make to our privacy policy in the future will be posted
	on this page and, where appropriate, notified to you
	by e-mail. Please check back frequently to see any updates or changes to our privacy policy.</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">
	<B>Contact</B>
</P>
<P CLASS="western" STYLE="margin-bottom: 0.42cm">Questions, comments and requests regarding this privacy policy are
	welcomed and should be addressed to
	<A CLASS="western" HREF="mailto:askus@4dml.com">askus&#64;4dml.com</A>.
</P>
<P CLASS="western">
	<BR>
	<BR>
</P>
<article>

	<section>
		<p-tabView (onChange)="handleTabChange($event)">
			<!--<p-tabView (onChange)="handleTabChange($event)" [activeIndex]="defaultTab">-->
			<ng-template [ngIf]="can.list">
				<p-tabPanel [cache]="true">
					<ng-template pTemplate="header">
						<span><i class="mdi mdi-format-list-text heading"></i>
							<span class="isDesktop">&nbsp;List</span></span>
					</ng-template>
					<ng-template pTemplate="content">
						<app-occupancy-list></app-occupancy-list>
					</ng-template>
				</p-tabPanel>
			</ng-template>
			<ng-template [ngIf]="can.admin">
				<p-tabPanel [cache]="true">
					<ng-template pTemplate="header">
						<span><i class="mdi mdi-wrench-outline heading"></i> <span class="isDesktop">&nbsp;Admin</span></span>
					</ng-template>
					<ng-template pTemplate="content">
						<app-occupancy-admin></app-occupancy-admin>
					</ng-template>
				</p-tabPanel>
			</ng-template>
		</p-tabView>
	</section>

</article>

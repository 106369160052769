import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceForTenants } from 'app/classes/service-for-tenant';
import { Tenant } from 'app/classes/tenant';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  API_URL = ' https://on52k2d4ng.execute-api.eu-west-2.amazonaws.com/4d';

  constructor(private apiService: APIService, private http: HttpClient) { }

  async update(tenant: Tenant): Promise<any> {
    return new Promise((resolve) => {
      const url = `${this.API_URL}/tenants`;

      return this.http
        .put<any>(url, tenant.serialise(), this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  async updateServiceForTenants(serviceForTenants: ServiceForTenants): Promise<any> {
    return new Promise((resolve) => {
      const url = `${this.API_URL}/tenants/services`;

      return this.http
        .put<any>(url, serviceForTenants.serialise(), this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  async create(tenant: Tenant): Promise<any> {
    return new Promise((resolve) => {
      const url = `${this.API_URL}/tenants`;

      return this.http
        .post<any>(url, tenant.serialise(), this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  async createServicesForTenants(serviceForTenant: ServiceForTenants): Promise<any> {
    return new Promise((resolve) => {
      const url = `${this.API_URL}/tenants/services`;

      return this.http
        .post<any>(url, serviceForTenant.serialise(), this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  async getTenants(): Promise<Tenant[]> {
    return new Promise((resolve) => {
      const url = `${this.API_URL}/tenants`;

      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b.map(item => new Tenant(item)));
        });
    });
  }

  async getServices(): Promise<ServiceForTenants[]> {
    return new Promise((resolve) => {
      const url = `${this.API_URL}/tenants/services`;

      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b.map(item => new ServiceForTenants(item)));
        });
    });
  }

}

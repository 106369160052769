<div class="row">
  <!-- -->
  <app-section-banner [section]="'orders'" [title]="'4DML Ordering'" [subtitle]="'Order 4DML equipment, view outstanding orders.'"
    [detail]=""></app-section-banner>

  <!-- <div *ngIf="init===true" class="loader">Loading...</div> -->

  <div class="col-xs-6">
    <div class="panel panel-success" routerLink="/orders/new">
      <div class="panel-body">
        <i class="fa fa-shopping-cart"></i>&nbsp; Place an order
      </div>
    </div>
  </div>

  <div class="col-xs-6">
    <div class="panel panel-default">
      <div class="panel-body">
        <i class="fa fa-list"></i>&nbsp; Order history
      </div>
    </div>
  </div>

  <div class="col-xs-12">
    <h5 class="card-title">Saved orders</h5>

    <table class="table table-sm table-hover" *ngIf="savedOrders && savedOrders.length">
      <thead>
        <tr>
          <th>#</th>
          <th>Site</th>
          <th><i class="fa fa-calendar"></i> Created</th>
          <th><i class="fa fa-truck"></i> deliver</th>
          <th><i class="fa fa-plug"></i> Install</th>
        </tr>
      </thead>

      <tbody>

        <ng-template ngFor let-order [ngForOf]="savedOrders">
          <tr [routerLink]="['/orders', order.id, 'edit']">
            <td>{{order.id}}</td>
            <td>{{order.siteTitle}}</td>
            <td><span *ngIf="order.createdAt">{{order.createdAt | amTimeAgo}}</span><span *ngIf="!order.createdAt">-</span></td>
            <td><span *ngIf="order.deliverAt">{{order.deliverAt | amDateFormat:apiService.getDateFormat()}}</span></td>
            <td><span *ngIf="order.installAt">{{order.installAt | amDateFormat:apiService.getDateFormat()}}</span></td>
          </tr>
        </ng-template>
      </tbody>
    </table>

    <p *ngIf="savedOrders && savedOrders.length === 0" class="no-saved-orders">You have no orders waiting to be submitted for approval.</p>

  </div>

  <div class="col-xs-12">
    <h5 class="card-title">Outstanding orders</h5>
    <p *ngIf="activeOrders && activeOrders.length === 0" class="no-saved-orders">You have no submitted orders in progress.</p>
    <div class="component-list">
      <table class="table outstanding">
        <tbody>
          <ng-template ngFor let-order [ngForOf]="activeOrders">

            <app-order-status-table-rows [order]="order" [routerLink]="['/orders', order.id]"></app-order-status-table-rows>

          </ng-template>
        </tbody>
      </table>
    </div>



  </div>
</div>
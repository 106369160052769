<article>
    <section>
        <div class="form-group">
            <label for="title">Collection Title</label>
            <input maxlength="50" id="title" name="title" class="form-control" type="text" [(ngModel)]="title">
        </div>
    </section>
    <section>
        <label>Collection Assets</label>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th></th>
                    <th>Gateway</th>
                    <th>Asset</th>
                    <th>Custom title</th>
                </tr>

            </thead>
            <tbody>
                <tr *ngFor="let asset of assets" [class.checked]="asset.checked">
                    <td (click)="asset.checked=!asset.checked"> <i class="mdi"
                            [class.mdi-checkbox-marked-outline]="asset.checked"
                            [class.mdi-checkbox-blank-outline]="!asset.checked"></i></td>
                    <td (click)="asset.checked=!asset.checked">{{asset.gateway.title}}</td>
                    <td (click)="asset.checked=!asset.checked">
                        <app-asset-icon [asset]="asset"></app-asset-icon> {{asset.title}}
                    </td>
                    <td><input class="form-control" [(ngModel)]="asset.collectionTitle"></td>
                </tr>
            </tbody>
        </table>
    </section>

    <section *ngIf="title" class="text-center">
        <button (click)="submit()" pButton [label]="'Save ' + title + ' collection'" icon="fa fa-floppy-o"></button>
    </section>
</article>

<app-please-wait *ngIf="isWorking"></app-please-wait>

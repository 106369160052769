import { Component, HostListener, Input, OnDestroy, output, signal } from '@angular/core';
import { Site } from 'app/classes/site';
import { IGetMonthlyForSiteResponse, IToolbarSettings, OccupancyService } from 'app/shared/occupancy.service';
import { CalendarService } from 'app/shared/calendar.service';
import { Tenant } from 'app/classes/tenant';
import { ServiceForTenants } from 'app/classes/service-for-tenant';
import { ExportService } from 'app/shared/export.service';
import { SitePlanDashboardComponent } from 'app/profiling/site-plan/site-plan-dashboard/site-plan-dashboard.component';
import { APIService } from 'app/shared/api.service';
import { DialogService } from 'primeng/dynamicdialog';
import { IDatesFromTo } from "app/interfaces/dates-from-to";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spd-occ-monthly',
  templateUrl: './spd-occ-monthly.component.html',
  styleUrls: ['./spd-occ-monthly.component.css'],
  providers: [DialogService]
})
export class SpdOccMonthlyComponent implements OnDestroy {

  isShowingDialog = signal<'asset'>(null);
  selectedAssetId = signal<number>(null);
  selectedDates = signal<IDatesFromTo>(null);

  onAssetsReceived = output<any[]>();

  @Input() site: Site;

  @Input()
  public set dates(dates: IDatesFromTo) {
    if (dates?.from) {
      this.datesFromTo = dates;
      this.get();
    }
  }

  tenant: Tenant;
  sub: Subscription;
  datesFromTo: IDatesFromTo;
  dateFrom: Date;
  daysInMonth: number;
  grandTotals: number[];
  occupancyStats: IGetMonthlyForSiteResponse
  canGoNextWeek: boolean = true;
  pins: { date: number };
  filters: { floor: string, tenant: string, service: string } = { floor: null, tenant: null, service: null };
  lists: {
    assetIds: number[],
    floor: string[],
    tenant: Tenant[],
    service: ServiceForTenants[]
  } = { floor: [], tenant: [], service: [], assetIds: [] };
  isLoading: boolean;
  isMaximised: boolean;
  // true when the user is filtering out assets
  isFiltering: boolean;
  display: any[];
  isWorking: boolean = false;
  supressTenantAndService = signal<boolean>(false);

  sortColumn: ISort;


  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);
    if (event.key.toLowerCase() == "f") {
      this.isMaximised = !this.isMaximised;
    }
  }
  constructor(private exportService: ExportService, private apiService: APIService, private occupancyService: OccupancyService, protected calendarService: CalendarService, private parent: SitePlanDashboardComponent, public dialogService: DialogService) {
    this.getLocalStorage();

    this.parent.message.subscribe((message) => {
      switch (message) {
        case 'export':
          this.export();
          break;
      }
    });
  }

  ngOnInit(): void {
    this.sub = this.occupancyService.toolbar.subscribe(change => {
      if (!change) {
        return;
      }
      console.log('TOOLBAR', change);
      const value = change.value;

      switch (change?.action) {
        case 'dates':
          this.dates = <IDatesFromTo>change.value;
          this.get();
          break;
        case 'tenant':
          this.tenant = <Tenant>change.value;
          this.get();
          break;
        case 'settings':
          this.tenant = (<IToolbarSettings>value).tenant;
          this.datesFromTo = (<IToolbarSettings>value).datesFromTo;
          this.get();
          break;
      }
    });
  }

  assetClick(assetId: number, monthIndex?: number, forDate?: Date) {
    const dates = this.datesFromTo;
    const from = forDate || dates.from;
    const to = forDate || dates.to;

    this.selectedDates.set({ from, to });
    this.selectedAssetId.set(assetId);
    this.isShowingDialog.set('asset');
  }

  export() {
    this.apiService.toastSuccess('Exporting...', '');
    this.exportService.exportOccupancyForSite(this.occupancyStats);
  }

  sortClick(column: string) {
    if (this.sortColumn.column === column) {
      this.sortColumn.ascending = !this.sortColumn.ascending;
    } else {
      this.sortColumn = { column, ascending: true };
    }

    this.setLocalStorage();
    this.sort();
  }

  getLocalStorage() {
    const storage = localStorage.getItem('spd-occ-monthly:filters');
    this.sortColumn = JSON.parse(localStorage.getItem('spd-occ-monthly:sort') || '{"column":"floor","ascending":true}');
    if (storage) {
      try {
        this.filters = JSON.parse(storage);
        this.filter();
      } catch (e) { }
    }
  }

  setLocalStorage() {

    localStorage.setItem('spd-occ-monthly:filters', JSON.stringify(this.filters));
    localStorage.setItem('spd-occ-monthly:sort', JSON.stringify(this.sortColumn));
  }

  get() {
    console.log('GET', this.datesFromTo, this.site, this.tenant);
    if (!this.datesFromTo?.from || !this.datesFromTo?.to) {
      return;
    }

    if (this.isWorking) {
      console.log('IS_BUSY_TOO_MANY_CALLS');
      return;
    }
    this.isWorking = true;

    this.isLoading = true;
    this.occupancyService.getMonthlyForSite(this.site.id, this.datesFromTo.from, this.datesFromTo.to, this.tenant?.id)
      .then(response => {
        console.log('READY');
        this.lists.floor = [...new Set(response.plans.map(plan => String(plan.floor)))].sort((a, b) => a > b ? 1 : -1);
        this.lists.tenant = response.tenants.sort((a, b) => a.title > b.title ? 1 : -1);
        this.lists.service = response.services.sort((a, b) => a.title > b.title ? 1 : -1);
        this.isLoading = false;
        this.isWorking = false;
        this.occupancyStats = response;
        this.supressTenantAndService.set((this.lists.tenant.length <= 1 && this.lists.service.length <= 1));
        this.filter();
        this.sort();
        this.onAssetsReceived.emit(Object.keys(this.occupancyStats.assets)
          .map(id => {
            const element = this.occupancyStats.assets[id];
            return { id: element.a_id, title: element.title };
          }));
      });
  }

  onChanged() {
    this.setLocalStorage();
    this.filter();
  }

  filter() {
    if (!this.occupancyStats) {
      return;
    }
    this.lists.assetIds = [];
    this.isFiltering = (this.filters.floor !== '' && this.filters.floor !== null) || (this.filters.service !== null) || this.filters.tenant !== null;

    if (!this.isFiltering) {
      this.lists.assetIds = this.occupancyStats.assetIdArray;
    }
  }

  /*
  previousWeek() {
    this.dateFrom = moment(this.dateFrom).subtract(1, 'week').toDate();
    this.pins.date = +this.dateFrom;
    this.setLocalStorage();
    this.canGoNextWeek = true;
    this.get();
  }

  nextWeek() {
    this.dateFrom = moment(this.dateFrom).add(1, 'week').toDate();
    this.pins.date = +this.dateFrom;
    this.setLocalStorage();
    this.get();
  }
*/
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  sort() {

    let assetIds = [...this.lists.assetIds];
    console.log(assetIds);



    assetIds.sort((a, b) => {

      if (!this.sortColumn.ascending) {
        [a, b] = [b, a];
      }
      const floor0 = this.occupancyStats.assets[a].floor;
      const floor1 = this.occupancyStats.assets[b].floor;

      const title0 = this.occupancyStats.assets[a].title;
      const title1 = this.occupancyStats.assets[b].title;

      const tenant0 = this.occupancyStats.assets[a].tenantTitle;
      const tenant1 = this.occupancyStats.assets[b].tenantTitle;


      const service0 = this.occupancyStats.assets[a].serviceTitle;
      const service1 = this.occupancyStats.assets[b].serviceTitle;

      switch (this.sortColumn.column) {
        case 'floor':
          return ((floor0 - floor1) || title0.localeCompare(title1));
        case 'asset':
          return (title0.localeCompare(title1) || (floor0 - floor1));
        case 'service':
          return (service0.localeCompare(service1) || (floor0 - floor1));
        case 'tenant':
          return (tenant0.localeCompare(tenant1) || (floor0 - floor1));
      }
    });

    console.log(assetIds);

    this.lists.assetIds = assetIds;
  }
}


export interface ISort {
  column: string;
  ascending: boolean;
}

@defer (when rows() && dataTable()) {
  <table class="table">
    <thead>
      <tr>
        @for (column of dataTable().columns; track column.attribute) {
          @if (!column.isHidden) {
            <th>
              <div (click)="sortColumnClick(column)">
                <div>{{ column.title }}</div>
                <div>
                  <i
                    tooltipPosition="top"
                    [pTooltip]="
                      column.sortDirection === 'asc'
                        ? 'Ascending, sort by descending'
                        : column.sortDirection === 'desc'
                          ? 'Descending, sort by ascending'
                          : 'Not sorted, sort ascending'
                    "
                    class="mdi"
                    [class.mdi-sort-ascending]="column.sortDirection === 'asc'"
                    [class.mdi-sort-descending]="column.sortDirection === 'desc'"
                    [class.mdi-sort-reverse-variant]="column.sortDirection !== 'asc' && column.sortDirection !== 'desc'"></i>
                </div>
              </div>
            </th>
          }
        }
      </tr>
    </thead>
    <tbody>
      @for (row of rows(); track row) {
        <tr (click)="rowClick(row)">
          @for (column of dataTable().columns; track column.attribute) {
            @if (!column.isHidden) {
              <td class="text-{{ dataTable().dataClassConfig[column.attribute].align }}">{{ row[column.attribute] }}</td>
            }
          }
        </tr>
      }
    </tbody>
  </table>
} @placeholder {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
}

<ng-template [ngIf]="cameras && cameras.list.length">
  <div class="component-list">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Camera</th>
          <th></th>
        </tr>

      </thead>
      <tbody>
        <ng-template ngFor let-camera [ngForOf]="cameras.list">
          <tr class="title">
            <td>{{camera.title}} </td>
            <td><i [routerLink]="['/camera', camera.id, 'control']" class="fa fa-cog"></i></td>
          </tr>
          <tr class="images">
            <td>
              <ng-template ngFor let-preset [ngForOf]="camera.presets">
                <div class="shots" *ngIf="preset.shotNight || preset.recentBucketKey">
                  <app-camera-shot [camera]="camera" [preset]="preset"></app-camera-shot>
                </div>
              </ng-template>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
</ng-template>

<div *ngIf="!cameras" class="loader">Loading...</div>
<div *ngIf="cameras && !cameras.list.length">

  <div class="col-md-12 nothing-returned">

    <h3>Your organisation has no timelapse devices</h3>
    <div>
      <i class="fa fa-frown-o"></i>
    </div>
    <div class="find-out-more">
      <a target="_blank" href="https://4dmonitoring.co.uk" class="btn btn-primary">Find out more about the 4D timelapse solution &rarr;</a>
    </div>

  </div>
</div>
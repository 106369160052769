import { Component, Input, OnInit } from '@angular/core';
import { Alarm } from 'app/classes/alarm';
import { ElogbooksReactiveJob } from 'app/classes/elogbooks-reactive-job';
import { APIService } from 'app/shared/api.service';
import { PrimeIcons } from 'primeng/api';

@Component({
  selector: 'app-rulesv3-item-alarm-elogbooks',
  templateUrl: './rulesv3-item-alarm-elogbooks.component.html',
  styleUrls: ['./rulesv3-item-alarm-elogbooks.component.css']
})
export class Rulesv3ItemAlarmElogbooksComponent implements OnInit {
  _alarm: Alarm;
  @Input()
  public get alarm(): Alarm {
    return this._alarm;
  }

  public set alarm(v: Alarm) {
    this._alarm = v;
    if (v) {
      const job = v.jobs[0];
      if (!job || !job.site?.id) {
        this.job = null;
        return;
      }
      this.apiService.getElogbooks(`action=get-job&jobid=${job.id}&siteid=${job.site.id}`)
        .then(j => {
          job.importFromElogbooks(j);
          this.apiService.getElogbooks(`action=get-messages&jobid=${job.id}&siteid=${job.site.id}`)
            .then(j => {
              this.messages = j.messages;
              this.events = this.messages.map(m => {
                const entry = { icon: PrimeIcons.ENVELOPE, body: m.body, user: m._links.sender.title, date: new Date(m.createdAt) };

                return entry;
              });
              this.job = job;
            });
        });
    }
  }
  job: ElogbooksReactiveJob | null;
  isMessaging: boolean;
  note: string;
  messages: any;
  events: any;

  constructor(private apiService: APIService) { }

  ngOnInit(): void {
    this.note = '';
  }

  m(e) {
    console.log('blocked');
    e.stopPropagation();
  }
  cancel() {

  }

  modalOK() {
    if (!this.note) {
      this.isMessaging = false;
      return;
    }
    // http://fourd-api.integration.v2.elogbooks.net/sites/11/jobs/516/messages
    const payload = { message: this.note };
    this.apiService.postElogbooks(payload, `action=post-message&jobid=${this.job.id}&siteid=${this.job.site.id}`)
      .then(r => {
        this.apiService.toastSuccess('Message sent', '');
        this.isMessaging = false;
      });
  }

}

import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { SitePlan } from '../classes/site-plan';
import { SiteFloorplanAsset } from '../classes/site-floorplan-asset';
import { SiteFloorplanArea } from '../classes/site-floorplan-area';
import { SiteFloorplanShape } from '../classes/site-floorplan-shape';
import { Gateway } from '../classes/gateway';
import { Viewbox } from 'app/classes/svg/viewbox';
import { AssetService } from './asset.service';
import { IDatesFromTo } from 'app/interfaces/dates-from-to';
import { OccupancyService } from './occupancy.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FloorplanService {
  static readonly URL = 'https://zam7a4a2gc.execute-api.eu-west-2.amazonaws.com/4d/';

  dataSubject = new Subject<IDataSubject>();

  constructor(private apiService: APIService, private http: HttpClient) { }

  setDataSubject(key: IDataSubectKey, value: any) {
    this.dataSubject.next({
      key,
      value,
      timestamp: new Date()
    });
  }

  /**
   * Get a site floorplan, the assets and areas
   * @param siteId
   * @param siteplanId
   */
  getSiteFloorplan(siteId: number, siteplanId: number): Promise<Getsiteplan> {
    const url = FloorplanService.URL + `?sfid=${siteplanId}&sid=${siteId}`;

    return this.http.get<any>(url, this.apiService.getUAOHeaders())
      .toPromise()
      .then(response => {
        const body = response;
        return {
          siteplan: new SitePlan(body.siteplan),
          assets: body.assets.map(asset => new SiteFloorplanAsset(asset)),
          areas: body.areas.filter(area => area.isActive).map(area => new SiteFloorplanArea(area)),
          shapes: body.shapes.map(shape => new SiteFloorplanShape(shape)),
          gateways: body.gateways
        };
      });
  }

  /**
   * Not implemented.
   * @deprecated NOT USED - NEEDS IMPLEMENTATION
   * @param siteplanId 
   * @param dates 
   * @returns 
   */
  async getOccupancySummaryForRange(siteplanId: number, dates: IDatesFromTo): Promise<any> {
    return new Promise(async (resolve) => {
      let q = `siteplanId=${siteplanId}&df=${this.apiService.dbDate(dates.from)}&dt=${this.apiService.dbDate(dates.to)}`;
      this.http
        .get<any>(OccupancyService.API_URL + `/hourly?${q}`, this.apiService.getUAOHeaders())
        .subscribe(async response => resolve(response));
    });
  }
}

export interface IDataSubject {
  key: IDataSubectKey;
  value: any;
  timestamp: Date;
}

export type IDataSubectKey = 'range' | 'area' | 'occupancy';

export interface Getsiteplan {
  siteplan: SitePlan;
  assets: SiteFloorplanAsset[];
  gateways: Gateway[];
  shapes: any[];
  areas: SiteFloorplanArea[];
}

/**
 * A class that wraps all the data required for an interactive floorplan based on data from Getsiteplan
 */
export class SitePlanComplete {
  sitePlan: SitePlan;
  assets: SiteFloorplanAsset[];
  gateways: Gateway[];
  shapes: SiteFloorplanShape[];
  areas: SiteFloorplanArea[];
  mode: string;
  scales: any = { r: 20, labelYOffset: 34 };
  viewbox: Viewbox;
  viewboxString: String;

  constructor(siteplan: Getsiteplan, private assetService: AssetService = null) {
    this.gateways = siteplan.gateways;
    this.sitePlan = siteplan.siteplan;
    this.assets = siteplan.assets || [];
    this.areas = siteplan.areas || [];
    this.shapes = siteplan.shapes || [];

    if (siteplan.siteplan.type === 'shape') {
      // This is an enhanced plan with shapes.
      this.mode = 'overview';
    }

    if (!this.sitePlan) {
      // No floorplan so exit.
      return;
    }

    if (this.sitePlan && this.sitePlan.fontSize) {
      // Adjust the circle size
      this.scales.r = this.sitePlan.fontSize * 1.8;
      this.scales.labelYOffset = this.sitePlan.fontSize * 1.2;
    }

    // console.log(`got shapes`, this.shapes);
    const defaultArea = this.areas.filter(area => area.defaultArea);
    if (defaultArea.length) {
      // The plan has a default area
      const area = defaultArea[0];
      this.viewbox = new Viewbox(area.x, area.y, area.width, area.height);
    } else {
      this.viewbox = new Viewbox(this.sitePlan.vbx, this.sitePlan.vby, this.sitePlan.viewboxWidth, this.sitePlan.viewboxHeight);
    }
    this.viewboxString = this.viewbox.toString();

    // Only assets mapped onto the plan
    this.assets = siteplan.assets.filter(asset => asset.siteFloorplanId === this.sitePlan.id);

    this.checkRAGs(true);
  }


  checkRAGs(wantsInitialisation: boolean = false) {
    const isDebugging = false;
    // Reset shapes to green
    this.shapes.forEach(s => s.atRAG = 'green');
    // Get rags for assets
    this.assets.forEach(asset => {

      const isMotion = asset.assetType_id === 50;

      if (wantsInitialisation) {
        asset.assetTypeTitle = this.assetService.getTypeTitle(asset.assetType_id);
      }

      if (asset.shapeId) {
        const shape = this.shapes.find(s => s.id === asset.shapeId);

        if (isMotion) {
          if (isDebugging) console.log(asset.id, asset.value, asset.upDataOp, shape);
        }

        if (wantsInitialisation) {
          shape.assets.push(asset);
        }

        let rag = null;
        if (asset.upData === 'rag') {
          if (asset.rag === 'red' || asset.rag === 'amber') {
            rag = asset.rag
          }
        } else if (asset.upData === 'value') {
          switch (asset.upDataOp) {
            case '=':
              if (+asset.originalValue === asset.upDataValue) {
                rag = 'red';
              }
              break;
            case '>':
              if (+asset.originalValue > asset.upDataValue) {
                rag = 'red';
              }
              break;
            case '<':
              if (+asset.originalValue < asset.upDataValue) {
                rag = 'red';
              }
              break;
          }
          if (isDebugging) console.log('RAG ASSET', asset.title, +asset.originalValue, asset.value, asset.upDataOp, asset.upDataValue, rag);
        }

        if (rag) {
          shape.atRAG = rag;
          if (isDebugging) console.log(`RAG SET ON SHAPE`, shape.title, rag);
        }

        if (isMotion) {
          if (isDebugging) console.log('DECISION:', asset.value, shape.title, shape.atRAG, rag);
        }
      }
    });
  }
}

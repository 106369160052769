<p class="logo-container"> <img src="assets/svg/elogbooks-logo.svg" width="32px" height="32px" alt="logo"> Elogbooks integration</p>


<div class="tabview-list">
	<div class="active"><i class="mdi mdi-new"></i> Create Reactive Job</div>
	<div>&nbsp;</div>
</div>


<div class="mt-1">
	<div>
		<div class="form-group">
			<label for="title">Site</label>
			<p *ngIf="rule.integration.states[state].siteId">{{rule.integration.states[state].siteId}} -
				{{rule.integration.states[state].siteTitle}}</p>
			<p *ngIf="!rule.integration.states[state].siteId">No site selected.</p>
		</div>
	</div>
	<div>
		<div class="form-group">
			<label for="title"></label>
			<div>
				<button pButton pRipple type="button" label="Choose site" (click)="getSites()" class="p-button-outlined p-button-sm p-button-secondary"></button>
			</div>
		</div>
	</div>

	<div>
		<div class="form-group">
			<label for="title">Priority</label>
			<p *ngIf="rule.integration.states[state].priorityId">{{rule.integration.states[state].priorityId}} -
				{{rule.integration.states[state].priorityTitle}}</p>
			<p *ngIf="!rule.integration.states[state].priorityId">No priority selected.</p>
		</div>
	</div>
	<div>
		<div class="form-group">
			<label for="title"></label>
			<div>
				<button [disabled]="!rule.integration.states[state].siteId" pButton pRipple type="button" label="Choose priority" (click)="getPriorities()" class="p-button-outlined p-button-sm p-button-secondary"></button>
			</div>
		</div>
	</div>

	<div>
		<div class="form-group">
			<label for="summary">Job summary</label>
			<input maxlength="50" id="summary" name="summary" class="fourd" type="text" [(ngModel)]="rule.integration.states[state].summary">
		</div>
	</div>
	<div>
		<div class="form-group">
			<label for="description">Job description</label>
			<textarea id="description" name="description" class="fourd" type="text" [(ngModel)]="rule.integration.states[state].description" rows="3"></textarea>
		</div>
	</div>
</div>

@if(isSelecting?.action) {
<app-elogbooks-popup [target]="isSelecting.action" [id]="isSelecting.id" (onDismiss)="selected($event)">
</app-elogbooks-popup>
}

import { Injectable, ɵConsole } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { WebsocketIn } from 'app/classes/websocket-in';
import { BehaviorSubject, Observable } from 'rxjs';
import { webSocket } from "rxjs/webSocket";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  public subject;
  public isAuthenticated = false;

  private _recent: BehaviorSubject<WebsocketIn> = new BehaviorSubject(null);
  public recent: Observable<WebsocketIn> = this._recent.asObservable();

  public history: WebsocketIn[] = [];
  CONNECT_URL = 'wss://covzt2f4aj.execute-api.eu-west-2.amazonaws.com/prod';
  ieNotSupported: boolean;
  filter = 'all';

  constructor(private apiService: APIService, private http: HttpClient) { }

  disconnect() {
    if (this.subject) {
      //console.log(this.ws.readyState);
      //this.ws.close();

      this.subject.complete();
      this.subject.unsubscribe();
      //console.log(this.ws.readyState);
      this.subject = null;
      this.isAuthenticated = false;
      console.log('WEBSOCKET DISCONNECTED');
    }
  }

  connect() {
    console.log('dashboard.service connect()');

    if (!this.subject) {
      console.log('Connecting to ', this.CONNECT_URL);

      this.subject = webSocket(this.CONNECT_URL);

      this.subject.subscribe(
        message => {

          // console.log(`MESSAGE RECEIVED `, message);

          if (!message) return;

          if (message.data === 'AUTHORISED') {
            this.isAuthenticated = true;
            return;
          }


          let data = new WebsocketIn(message);
          if (this.filter !== 'all') {
            if (data.action !== this.filter) {
              return;
            }
          }

          // console.log(`Received at: ` + data.receivedAt)
          this._recent.next(data);
          this.history.unshift(data);
          if (this.history.length > 100) {
            // No more than 150 entries
            this.history.pop();
          }
        }, // Called whenever there is a message from the server.
        err => {
          console.log('ERR TRAP');
          console.log(err);
        }, // Called if at any point WebSocket API signals some kind of error.
        () => console.log('complete') // Called when connection is closed (for whatever reason).
      );


      console.log("Successfully connected: " + this.CONNECT_URL);
      this.subject.next({ "action": "auth", "message": this.apiService.getAuthToken() });
    } else {
      console.log('-- already have a subject --');
    }
    return this.subject;
  }

  setFilter(filter) {
    this.filter = (filter === 'setpoints' ? 'rag_change' : filter);
  }


  /*
    private create(url): Rx.Subject<MessageEvent> {
      const ws = new WebSocket(url);
      this.ws = ws;
  
      const observable = new Observable((obs: Rx.Observer<MessageEvent>) => {
        ws.onmessage = obs.next.bind(obs);
        ws.onerror = obs.error.bind(obs);
        ws.onclose = obs.complete.bind(obs);
        console.log('binded websocket queues');
        return ws.close.bind(ws);
      });
      const observer: any = {
        next: (data: any) => {
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(data.type);
          }
        }
      };
      this.events = Rx.Subject.create(observer, observable);
      this.events = new AnonymousSubject(observer, observable);
      // Store the events within the service for the application
      this.events
        .filter(o => o && o.type === 'message')
        .subscribe(message => {
          if (message.data === 'OK') {
            this.isAuthenticated = true;
            return;
          }
          // this.apiService.toastSuccess('Realtime data received', '');
          let data: WebsocketIn;
          try {
            data = new WebsocketIn(message);
            if (this.filter !== 'all') {
              if (data.action !== this.filter) {
                return;
              }
            }
          } catch (err) {
            console.log(err);
            return;
          }
          // console.log(`Received at: ` + data.receivedAt)
          this._recent.next(data);
          this.history.unshift(data);
          if (this.history.length > 100) {
            // No more than 150 entries
            this.history.pop();
          }
        });
  
      return this.events;
    }
    */

  getSetpointTotals() {
    // /orgs/{orgid}/assets/setpoints
    return this.http.get(this.apiService.getUserAPI() + '/assets/setpoints?totals=1', { headers: this.apiService.getHttpHeaders() });
  }

}

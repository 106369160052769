<section *ngIf="client">
	<app-theme-page-container label="Create a new billing client">
		<app-theme-page-section>

			<div class="form-group">
				<label for="title">Client name </label>
				<input id="title" name="title" maxlength="50" class="form-control" [(ngModel)]="client.title">
			</div>

			<div class="form-group">
				<label for="shortTitle">Client short name (optional) </label>
				<input id="shortTitle" name="shortTitle" maxlength="50" class="form-control" [(ngModel)]="client.shortTitle">
			</div>

			<div class="form-group">
				<div for="notes">Notes</div>
				<textarea [(ngModel)]="client.notes" [style]="{'height':'200px'}"></textarea>
			</div>

			<div class="buttons">
				<div class="text-right">
					<p-button label="Cancel" icon="pi pi-times" styleClass="p-button-outlined p-button-secondary mr-1" (click)="cancel()">
					</p-button>
					<p-button label="Create" icon="mdi mdi-content-save" styleClass="" (click)="submit()">
					</p-button>
				</div>
			</div>
		</app-theme-page-section>
	</app-theme-page-container>
</section>

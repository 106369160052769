export class Exporter {
  table: string;
  conditions?: ExportCondition[];
  toMyEmail: boolean;
  emails: string;
  columns: ExportColumn[];

  constructor(exportTable?: IExportTable) {
    if (exportTable) {
      this.table = exportTable.table;
      this.columns = exportTable.columns;
    } else {
      this.columns = [];
    }
  }

  selectAll() {
    this.columns.forEach(column => column.selected = true);
  }

  forExport() {
    let columns = this.columns.filter(column => column.selected).map(column => column.name);
    return {
      table: this.table,
      columns: columns
    };
  }
}

export interface IExportPost {
  table: string;
  columns: string[];
}

export class ExportCondition {
  column: string;
  operator: string;
  value: string;
}

export class ExportColumn {
  name: string;
  title: string;
  selected?: boolean;
  type?: string = 'string';

  constructor() {
    this.selected = true;
  }
}

export interface IExportTable {
  table: string;
  columns: ExportColumn[];
}

export class ExportTableGateways implements IExportTable {
  table: string = 'gateways';
  columns: ExportColumn[] = [
    { name: 'org_title', title: 'Org Name' },
    { name: 'site_title', title: 'Site Name' },
    { name: 'site_address', title: 'Site Address' },
    { name: 'id', title: 'Gateway ID' },
    { name: 'title', title: 'Title' },
    { name: 'location', title: 'Location' },
    { name: 'licensedAt', title: 'Licensed from', type: 'date' },
    { name: 'long', title: 'Longitude', type: 'number' },
    { name: 'lat', title: 'Latitude', type: 'number' },
  ];
}

export class ExportTableSetpoints implements IExportTable {
  table: string = 'setpoints';
  columns: ExportColumn[] = [
    { name: 'org_title', title: 'Org name' },
    { name: 'site_title', title: 'Site name' },
    { name: 'gateway_id', title: 'Gateway ID' },
    { name: 'gateway_title', title: 'Gateway Title' },
    { name: 'id', title: 'Set Point ID' },
    { name: 'asset_title', title: 'Sensor Title' },
    { name: 'weekday', title: 'Week Day' },
    { name: 'startsAt', title: 'Time Starts', type: 'date' },
    { name: 'endsAt', title: 'Time Ends', type: 'date' },
    { name: 'allday', title: 'All Day Indicator' },
    { name: 'enabled', title: 'Enabled' },
  ];
}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { AssetService } from 'app/shared/asset.service';
import { APIService } from 'app/shared/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Gateway } from 'app/classes/gateway';

import moment from 'moment';
import { GatewayIS } from 'app/classes/gateway-is';
import { SetpointsService } from 'app/shared/setpoints.service';

@Component({
  selector: 'app-sensor-popup',
  templateUrl: './sensor-popup.component.html',
  styleUrls: ['./sensor-popup.component.css']
})
export class SensorPopupComponent implements OnInit {

  _asset: Asset;
  icon: string;
  setpoints: any[];
  iframeSrc: string;
  gateway: Gateway;

  @Input()
  showPoints: boolean;

  @Input()
  startsAt: any;

  @Input()
  endsAt: any;

  @Input()
  public get asset(): Asset {
    return
  }
  public set asset(v: Asset) {
    const exists = (this.asset);
    this._asset = v;
    if (!v) {
      return;
    }
    if (v instanceof Asset) {

    } else {
      console.error('asset is not instance of Asset');
    }
    this.icon = this.assetService.getIcon(v);
    this.title = v.title;
    if (exists) {
      this.getSetpoints();
    }
  }

  title: string;
  content: string[];

  _show: string;
  @Input()
  public get show(): string {
    return this._show;
  }
  public set show(v: string) {
    this._show = v;
    if (!v) {
      return;
    }
  }

  pagePreferences: any = {};

  @Output()
  showChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private setpointService: SetpointsService, private assetService: AssetService, private apiService: APIService, public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.pagePreferences = JSON.parse(localStorage.getItem('sensor:popup:livefeed:prefs') || '{ }');

    if (!this.startsAt) {
      this.endsAt = moment().endOf('day');
      this.startsAt = moment().subtract(7, 'days');
    }
    if (this._asset) {
      this.getSetpoints();
    }
  }

  getSetpoints() {
    this.setpointService
      .getSetpointsForAssetAndRange(this._asset, this.apiService.RANGES_BY_TITLE.OPERATIONAL_HOURS)
      .then(setpointData => {
        console.log(setpointData);
        this.setpoints = setpointData.setpoints;
        this.apiService.getGateway(this._asset.gateway_id).then(gateway => {
          if (gateway instanceof GatewayIS) {
            this.iframeSrc = gateway.initialstate.iframeSrc;
          } else {
            this.iframeSrc = `https://iot.app.initialstate.com/embed/#/tiles/${gateway.embedLink}`;
          }
        });
      });
  }

  close() {
    this.show = null;
    this.showChange.emit(this.show);
  }

  iframeOnLoad(event: any) {

  }

  toggle(label: string) {
    console.log(label);
    try {
      this.pagePreferences[label] = !!this.pagePreferences[label];
      localStorage.setItem('sensor:popup:livefeed:prefs', JSON.stringify(this.pagePreferences));
    } catch (err) {
      console.log(err);
    }
  }
}

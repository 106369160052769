<table class="table table-hover">
  <thead>
    <tr>
      <th colspan="1">Review From</th>
      <th colspan="1">Review To</th>
      <th class="isDesktop">Created By</th>
      <th class="isDesktop">Created At</th>
      <th>State</th>
      <th class="text-right">Months</th>
      <th class="text-right">Document</th>
    </tr>
  </thead>
  <tbody>
    @for (review of reviews(); track $index) {
      <tr (click)="reviewClick(review)" class="rag-{{ review.rag }}">
        <td>{{ review.reviewDate | date: 'dd MMMM yyyy' }}</td>
        <td>{{ review.reviewEndDate | date: 'dd MMMM yyyy' }}</td>
        <td class="isDesktop">{{ review.createdBy.name }}</td>
        <td class="isDesktop">{{ review.createdAt | date: 'dd/MM/yyyy' }}</td>
        <td>{{ review.state }}</td>
        <td class="text-right">{{ review.noOfMonths }}</td>
        <td class="text-right">
          @if (review.documentKey) {
            <a target="_blank" href="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ review.documentKey }}">Download</a>
          }
        </td>
      </tr>
    }
  </tbody>
</table>

import { Component, Input, OnInit } from '@angular/core';
import { Building } from 'app/classes/building';
import { CollectionForAssets } from 'app/classes/collection-for-assets';
import { BuildingsService } from 'app/shared/buildings.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-manage-collections',
  templateUrl: './dialog-manage-collections.component.html',
  styleUrls: ['./dialog-manage-collections.component.css'],
  providers: [DialogService]
})
export class DialogManageCollectionsComponent implements OnInit {

  collectionForAssets: CollectionForAssets;
  building: Building;
  type: 'reporting' | 'footfall';

  constructor(public buildingService: BuildingsService, public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    if (this.config.data.collectionForAssets) {
      this.collectionForAssets = this.config.data.collectionForAssets;
      this.building = this.config.data.building;
      this.type = this.config.data.type;
    } else {
      // load the annotations
    }
  }

  cancel() {
    this.ref.close();
  }

  update() {
    this.ref.close(this.collectionForAssets);
  }

  clickCreateCollection() {

  }

  clickCollection(event: any) {
    throw new Error('NOT_HANDLED');
  }

  clickAddAnother() {

  }

  collectionsUpdated() {
    this.getCollections('close');
  }

  getCollections(action: 'close') {
    this.buildingService
      .getCollections(this.building.id, 'reporting')
      .then(collectionAssets => {
        // Pre select collections
        if (collectionAssets.collections.length === 1) {
          collectionAssets.collections[0].selected = true;
        } else {
          collectionAssets.collections.forEach(c => c.selected = false);
        }
        this.collectionForAssets = collectionAssets;
        if (action === 'close') {
          this.update();
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { StoreService } from '../../shared/store.service';

@Component({
  selector: 'app-profiling-container',
  templateUrl: './profiling-container.component.html',
  styleUrls: ['./profiling-container.component.css']
})
export class ProfilingContainerComponent implements OnInit {

  pageTitle: string = 'floorplan';

  constructor(private storeService: StoreService) {


   }

  ngOnInit() {
  }

}

export class SiteFloorplanArea {
    x: number;
    y: number;
    width: number;
    height: number;
    title: number;
    siteFloorplanId: number;
    id: number;
    defaultArea: boolean;

    constructor(data?: any) {
        if (data) {
            this.x = data.x ;
            this.y = data.y ;
            this.width = data.width;
            this.height = data.height;
            this.title = data.title
            this.siteFloorplanId = data.site_floorplan_id;
            this.id = data.id;
            this.defaultArea = data.default;
        }
    }
}

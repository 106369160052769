import { Site } from "./site";

export class FilterMenuItem {
  title: string;
  id: any;
  count: number;
  rag: string;
  site: Site;
  tooltip: string;

  constructor() { }
}

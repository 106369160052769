<div class="component">
  <div *ngIf="!inEditMode">
    <span>{{title}}</span>
    <span class="pull-right">{{value}} <span *ngIf="displayValue">{{displayValue}}</span>
      <i *ngIf="canEdit" class="mdi mdi-square-edit-outline can-click" (click)="editMode()" pTooltip="Edit"
        tooltipPosition="top"></i>
    </span>
  </div>
  <div *ngIf="inEditMode">
    <div class="row">
      <div class="col-xs-9 col-sm-10" *ngIf="!propertyType">
        <input class="form-control" [(ngModel)]="value">
      </div>
      <div class="col-xs-9 col-sm-10" *ngIf="propertyType === 'list'">
        <select [(ngModel)]="value"> // value is a string or number
          <option *ngFor="let obj of propertyList" [value]="obj.id">{{obj.title}}</option>
        </select>
      </div>
      <div class="col-xs-3 col-sm-2">
        <div class="icons pull-right">
          <i class="fa fa-check fa-fw" (click)="save()"></i>&nbsp;<i class="fa fa-times fa-fw" (click)="editMode()"></i>
        </div>
      </div>
    </div>
  </div>
</div>

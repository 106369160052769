<section class="no-print content-container">
	<app-theme-page-section>
		<app-select-building (onBuildingChanged)="buildingChanged($event)" (onBuildingSelected)="buildingSelected($event)" hasCollection="footfall">
		</app-select-building>
	</app-theme-page-section>

	@if ( building && collectionForAssets) {

	<h2>Areas</h2>
	<!--
	<app-toolbar>
		<div class="right">
			<app-button label="Manage areas" icon="mdi mdi-plus" (click)="manageCollections()" />
		</div>
	</app-toolbar>
-->

	<section class="no-print select-gateway-calendar">
		<div>
			<table class="table table-hover">
				<thead>
					<tr>
						<th>Area</th>
						<th>Assets</th>
					</tr>
				</thead>
				<tbody>
					@for(g of collectionForAssets.collections;track g) {
					<tr (click)="g.selected = !g.selected" [class.checked]="g.selected">
						<td>
							<i class="mdi" [class.mdi-checkbox-marked-outline]="g.selected" [class.mdi-checkbox-blank-outline]="!g.selected"></i>
							{{g.title}}
						</td>
						<td>
							@for(a of g.assets;track a.id) {
							@if (a.title.endsWith('_in')) {
							<app-chip [canClick]="false" [noColour]="true" size="small" [label]="a.title"></app-chip>
							}
							}
						</td>
					</tr>
					}
				</tbody>
			</table>
		</div>

		<div>
			<div><span *ngIf="month">Generate report for the month of {{month | amDateFormat:'MMMM, yyyy'}}</span> &nbsp;
			</div>
			<p-calendar [(ngModel)]="month" [inline]="true" [minDate]="minDate" [maxDate]="maxDate" view="month">
			</p-calendar>
			<div (click)="useOrgLogo = !useOrgLogo" class="can-click mt-1">
				<span class="mdi" [class.mdi-checkbox-marked-outline]="useOrgLogo" [class.mdi-checkbox-blank-outline]="!useOrgLogo"></span> Use logo for {{org.title}}
			</div>
		</div>
	</section>

	}
</section>

@if (building?.openingHours.isSet && month) {

@if(!(!runReport || reportWasGenerated)) {
<app-dialog [dimensions]="{'width':230, 'height':90}" [canInteract]="false">
	<div class="content-container">
		<h2>Generating report...</h2>
	</div>
</app-dialog>
}
<section class="no-print actions">
	<div class="mt-1 mb-1 text-center">
		<app-button (click)="generate()" [isCTA]="true" [isDisabled]="runReport && !reportWasGenerated" [label]="!runReport || reportWasGenerated ? 'Generate Report' : 'Generating report...'" icon="mdi mdi-chart-areaspline-variant" />

		@if(reportWasGenerated) {
		<app-button (click)="printPage()" label="Print Report" icon="mdi mdi-printer" />
		}

	</div>
</section>
@if (runReport && month && building) {
<section>
	<div class="mt-1">
		<app-insights-ff-report [building]="building" [startDate]="month" [collections]="reportCollections" [useOrgLogo]="useOrgLogo" (onReportGenerated)="onReportWasGenerated($event)" [clearCache]="clearCache" />
	</div>
</section>
}
}
<app-please-wait *ngIf="isLoading"></app-please-wait>

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, input } from '@angular/core';
import { Building } from 'app/classes/building';
import { Gateway } from 'app/classes/gateway';
import { APIService } from 'app/shared/api.service';
import { InsightsService, IWaitForBuildingHourlyJob } from "app/shared/insights.service";
import { Insight } from "app/classes/insight";
import { StoreService } from 'app/shared/store.service';
import { Subscription } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogInsightsWaitComponent } from 'app/dialogs/dialog-insights-wait/dialog-insights-wait.component';
import { CombinedCollectionItem } from 'app/shared/site.service';
@Component({
  selector: 'app-insights-airquality-report',
  templateUrl: './insights-airquality-report.component.html',
  styleUrls: ['./insights-airquality-report.component.css']
})
export class InsightsAirqualityReportComponent implements OnInit, OnDestroy {

  FOURD_LOGO = '/assets/svg/4d_logo_light.svg';

  collections = input.required<CombinedCollectionItem[]>();

  @Input()
  clearCache: boolean;

  @Input()
  startDate: Date;

  @Input()
  building: Building;

  @Input()
  gateways: Gateway[];

  @Input()
  useOrgLogo = true;

  insight: Insight = new Insight();

  isReady = false;
  hasData = false;
  isRunning = false;

  uuid: string;

  logo: string;
  subscriptions: Subscription[] = [];

  headerTitle: string;

  @Output()
  onReportGenerated: EventEmitter<Insight> = new EventEmitter();

  dialogRef: any;

  generatedAt: Date;
  isLight: boolean;
  svgLocation = 'assets/svg/';

  constructor(private apiService: APIService, private storeService: StoreService, private insightsService: InsightsService, public dialogService: DialogService) {
    this.isLight = storeService.getTheme(true) === 'light';
    if (storeService.getTheme(true) === 'dark') {
      this.svgLocation = 'assets/svg/white/';
    }
  }

  ngOnInit(): void {
    console.log(this.building);

    this.startJob();

    if (this.useOrgLogo) {
      const userOrg = this.apiService.getUserOrg();
      this.setLogo(userOrg.settings.theme_menu_logo);
    } else {
      this.setLogo(this.FOURD_LOGO);
    }
  }

  setLogo(logoKey: string) {
    if (logoKey.indexOf('/') !== -1) {
      // a path was passed.
      this.logo = logoKey;
    } else {
      this.logo = `https://s3.eu-west-2.amazonaws.com/4d-documents/${logoKey}`;
    }
  }

  startJob() {
    this.isRunning = true;
    this.insightsService
      .startBuildingHourlyJob(this.building, this.gateways, this.startDate, this.clearCache, this.collections())
      .then(r => {
        this.uuid = r.uuid;
        if (r.createdAt) {
          this.generatedAt = new Date(r.createdAt);
        }

        setTimeout(() => {
          if (this.isRunning) {
            this.dialogRef = this.dialogService.open(DialogInsightsWaitComponent, {
              width: '60%',
              header: 'Please wait'
            });
          }
        }, 500);

        this.insightsService
          .waitForBuildingHourlyJob<IWaitForBuildingHourlyJob>(this.uuid, r.wait)
          .then(r => {
            console.log('OK');

            const collection = r?.collection || null;
            const master = r?.master || null;

            if (this.dialogRef) {
              this.dialogRef.close();
            }
            this.isRunning = false;
            if (!collection || !master || collection.length === 0) {
              this.isReady = false;
              this.hasData = false;
              this.apiService.toastWarn('No data', '');
              setTimeout(() => {
                this.isReady = true;
                this.onReportGenerated.emit(null);
                window.scrollTo(0, document.body.scrollHeight);
              }, 10);

              return
            }
            this.insight.generatedAt = this.generatedAt;

            this.insight.setTelemetry(collection);
            this.insight.gateways = this.gateways;
            this.insight.collections = this.collections();
            this.insight.setConfiguration(master);
            this.headerTitle = this.insight.configuration.title.split(' from ')[0];

            console.log(master);
            this.isReady = true;
            this.hasData = true;
            setTimeout(() => {
              const id = 'report';
              const yOffset = -410;
              const element = document.getElementById(id);
              const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

              window.scrollTo({ top: y, behavior: 'smooth' });
            }, 10);
            this.onReportGenerated.emit(this.insight);
          });
      });
  }

  getTelemetry() {
    this.hasData = false;
    this.isReady = false;
    this.insightsService
      .getForBuilding(this.building, this.gateways, this.startDate)
      .then(r => {
        console.log(r);

        const { master, data } = r;

        if (data.length === 0) {
          this.isReady = true;
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 10);

          return
        }

        this.insight.setTelemetry(data);

        this.insight.gateways = this.gateways;
        this.insight.configuration = master;
        console.log(master);
        this.isReady = true;
        this.hasData = true;
        setTimeout(() => {
          const id = 'report';
          const yOffset = -410;
          const element = document.getElementById(id);
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }, 10);
      });
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();

    }
  }

}

<section>
	Users
	<div class="table">
		<table class="table">
			<thead>
				<tr>
					<th>Name</th>
					<th>Organisation</th>
					<th class="value">Sites</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let user of users">
					<td>{{user.name}}</td>
					<td>{{user.orgShortTitle}}</td>
					<td class="value">{{user.siteCount}}</td>
				</tr>
			</tbody>

		</table>
	</div>

</section>
import { Component, Input, OnInit } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Gateway } from 'app/classes/gateway';
import { Site } from 'app/classes/site';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { SiteService } from 'app/shared/site.service';

@Component({
  selector: 'app-dev-documentation',
  templateUrl: './dev-documentation.component.html',
  styleUrls: ['./dev-documentation.component.css']
})
export class DevDocumentationComponent implements OnInit {

  @Input()
  api: any;

  assets: Asset[];
  gateways: Gateway[];
  sites: Site[];
  siteSelected: Site;
  assetSelected: Asset;
  gatewaySelected: Gateway;

  today: Date = new Date();
  yesterday: Date;

  todayCurlDate: string;
  yesterdayCurlDate: string;

  uoms = [
    { id: '%', title: 'Humidity' },
    { id: '°C', title: 'Celsius' },
    { id: 'dBA', title: 'Decibels' },
    { id: 'lux', title: 'Light' },
    { id: 'Pa', title: 'Pascal unit' },
    { id: 'pm1', title: 'Particulates with diameter fewer than 1 microns' },
    { id: 'pm10', title: 'Particulates with diameter less than 10 microns' },
    { id: 'pm2.5', title: 'Particulates with diameter less than 2.5 microns' },
    { id: 'ppb', title: 'Parts per billion' },
    { id: 'ppm', title: 'Parts per million' },
    { id: 'μg/m3', title: 'Mass per unit volume', description: 'The concentration of an air pollutant (eg. ozone) is given in micrograms (one-millionth of a gram) per cubic meter air or µg/m3.' },
    { id: 'V', title: 'Voltage' },
    { id: 'VOC', title: 'Total Volatile Organic Compounds', description: '' },
    { id: 'Wh', title: 'Watt-hour' },
  ];

  aTypes =
    [
      {
        "id": 1,
        "title": "Button"
      },
      {
        "id": 2,
        "title": "Room Temperature"
      },
      {
        "id": 3,
        "title": "Pressure"
      },
      {
        "id": 4,
        "title": "Relay"
      },
      {
        "id": 6,
        "title": "Trim Pot"
      },
      {
        "id": 7,
        "title": "Light Sensor - Windows"
      },
      {
        "id": 8,
        "title": "Humidity"
      },
      {
        "id": 9,
        "title": "Processor Temperature"
      },
      {
        "id": 10,
        "title": "Light Switch"
      },
      {
        "id": 11,
        "title": "Light Sensor - No windows"
      },
      {
        "id": 12,
        "title": "WiFi"
      },
      {
        "id": 13,
        "title": "ADC"
      },
      {
        "id": 14,
        "title": "Voltaware"
      },
      {
        "id": 15,
        "title": "Footfall"
      },
      {
        "id": 16,
        "title": "Power"
      },
      {
        "id": 17,
        "title": "Door Sensor"
      },
      {
        "id": 18,
        "title": "Onboard Image Capture"
      },
      {
        "id": 19,
        "title": "Water Temperature"
      },
      {
        "id": 20,
        "title": "Transition"
      },
      {
        "id": 21,
        "title": "Battery Voltage"
      },
      {
        "id": 22,
        "title": "Motion"
      },
      {
        "id": 23,
        "title": "Moisture"
      },
      {
        "id": 24,
        "title": "Distance"
      },
      {
        "id": 25,
        "title": "CO₂"
      },
      {
        "id": 26,
        "title": "Dust"
      },
      {
        "id": 27,
        "title": "VOC"
      },
      {
        "id": 28,
        "title": "Air Score"
      },
      {
        "id": 29,
        "title": "PM2.5"
      },
      {
        "id": 30,
        "title": "PM10"
      },
      {
        "id": 31,
        "title": "IP Address"
      },
      {
        "id": 32,
        "title": "Heartbeat"
      },
      {
        "id": 33,
        "title": "UPS"
      },
      {
        "id": 34,
        "title": "Outside Temperature"
      },
      {
        "id": 35,
        "title": "Outside Wind Speed"
      },
      {
        "id": 36,
        "title": "Outside Wind Direction"
      },
      {
        "id": 37,
        "title": "Outside Weather Icon"
      },
      {
        "id": 38,
        "title": "Firewall"
      },
      {
        "id": 39,
        "title": "Orange Setpoints Minutes"
      },
      {
        "id": 40,
        "title": "Red Setpoints Minutes"
      },
      {
        "id": 41,
        "title": "Refrigerant Temperature"
      },
      {
        "id": 42,
        "title": "Generic String"
      },
      {
        "id": 43,
        "title": "Fixed IP Camera"
      },
      {
        "id": 44,
        "title": "Pan & Zoom IP Camera"
      },
      {
        "id": 45,
        "title": "Street Light Asset"
      },
      {
        "id": 46,
        "title": "Door Asset"
      },
      {
        "id": 47,
        "title": "Chilled Water"
      },
      {
        "id": 48,
        "title": "Bin"
      },
      {
        "id": 49,
        "title": "Sound"
      },
      {
        "id": 50,
        "title": "Occupancy"
      },
      {
        "id": 51,
        "title": "Radon"
      },
      {
        "id": 52,
        "title": "Footfall Cam"
      },
      {
        "id": 53,
        "title": "Hourly Counter"
      },
      {
        "id": 54,
        "title": "Virus Risk"
      },
      {
        "id": 55,
        "title": "Steam"
      },
      {
        "id": 56,
        "title": "Daily Minutes"
      },
      {
        "id": 57,
        "title": "Counter"
      },
      {
        "id": 58,
        "title": "Todays Sum"
      },
      {
        "id": 59,
        "title": "Pressure LW"
      },
      {
        "id": 60,
        "title": "Air Velocity"
      },
      {
        "id": 61,
        "title": "No2"
      },
      {
        "id": 62,
        "title": "PM1"
      },
      {
        "id": 63,
        "title": "No"
      },
      {
        "id": 64,
        "title": "Water Tension"
      },
      {
        "id": 65,
        "title": "Soil Temperature"
      },
      {
        "id": 66,
        "title": "Presence"
      }
    ];

  constructor(private assetService: AssetService, public apiService: APIService, private siteService: SiteService) {
    this.yesterday = new Date();
    this.yesterday.setDate(this.today.getDate() - 1);
    this.todayCurlDate = this.today.getFullYear() + '-' +
      ('00' + (this.today.getMonth() + 1)).slice(-2) + '-' +
      ('00' + this.today.getDate()).slice(-2);
    this.yesterdayCurlDate = this.yesterday.getFullYear() + '-' +
      ('00' + (this.yesterday.getMonth() + 1)).slice(-2) + '-' +
      ('00' + this.yesterday.getDate()).slice(-2);
  }

  ngOnInit(): void {
    this.assetService.getAssetsForUser().then(assets => {
      this.assets = assets;
      this.assetSelected = assets[0];
    });

    this.apiService.getGateways().then(gateways => {
      this.gateways = gateways;
      this.gatewaySelected = gateways[0];
    });

    this.siteService.getSites().then(sites => {
      this.sites = sites;
      // Ensure the site selected has a weather link
      this.siteSelected = sites.find(s => s.metobsid);
    });
  }

  printPage() {
    window.print();
  }
}

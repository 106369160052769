<app-theme-page-container>
  <main>
    @if (can.aq) {
      <app-theme-page-section>
        <div>
          <h2><i class="mdi mdi-air-filter"></i> Indoor Air Quality</h2>
          <section>
            Generate your buildings indoor air quality report, analysing sensor data from multiple gateways. The report helps you gain insights into indoor air
            quality and helps identify areas requiring attention.
          </section>
        </div>
        <footer>
          <!--<app-button routerLink="airquality" label="Awair/Airthings" />-->
          <app-button routerLink="airquality" [queryParams]="{ collections: true }" label="Generate report" />
        </footer>
      </app-theme-page-section>
    }
    @if (can.footfall) {
      <app-theme-page-section>
        <div>
          <h2><i class="mdi mdi-shoe-print"></i> Footfall</h2>
          <section>Generate your monthly footfall report. The report shows daily footfall over a month, with % change over previous weeks.</section>
          <section *ngIf="can.admin" class="admin-note"><i class="fa fa-lightbulb-o"></i> Footfall needs to be enabled per user.</section>
        </div>
        <footer><app-button routerLink="footfall">Generate report</app-button></footer>
      </app-theme-page-section>
    }
    @if (can.review) {
      <app-theme-page-section logo="admin" tooltipPosition="bottom">
        <div>
          <h2><i class="mdi mdi-office-building"></i> Building Review</h2>
          <section>
            <p>Generate building reviews. Reveal the health of a building and compare equipment performance.</p>
          </section>
        </div>
        <footer>
          <app-button routerLink="reviews" tooltipPosition="left">Generate report</app-button>
        </footer>
      </app-theme-page-section>
    }
    <!--
    <app-theme-page-section *ngIf="can.review" logo="admin" tooltipPosition="bottom" [isMuted]="true">
      <div>
        <h2><i class="mdi mdi-account-lock"></i>Legacy Building Review <small>for Administrators</small></h2>
        <section>
          <p>Generate quarterly building reviews, the review reveals the health of a building, comparing the performance of equipment.</p>
          <p></p>
        </section>
      </div>
      <footer>
        <app-button routerLink="assets/list" class="mr-1">List reports</app-button>
        <app-button routerLink="assets" pTooltip="Build or update a report">Build legacy report</app-button>
      </footer>
    </app-theme-page-section>
  --></main>
</app-theme-page-container>

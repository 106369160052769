import { Component } from '@angular/core';

@Component({
  selector: 'app-insights-review-help',
  templateUrl: './insights-review-help.component.html',
  styleUrl: './insights-review-help.component.css'
})
export class InsightsReviewHelpComponent {



}

import { Component, OnInit } from '@angular/core';
import { Building } from 'app/classes/building';
import { CollectionAsset, CollectionForAssets } from 'app/classes/collection-for-assets';
import { APIService } from 'app/shared/api.service';
import { BuildingsService } from 'app/shared/buildings.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InsightReview } from 'app/classes/insight-review';
import { DialogManageCollectionsComponent } from 'app/dialogs/dialog-manage-collections/dialog-manage-collections.component';
import { DialogLicenseComponent } from 'app/dialogs/dialog-license/dialog-license.component';
import { License, LicenseBuilding } from 'app/classes/license';
import { BillingService } from 'app/shared/billing.service';
import { IGetReviewsForBuildingItemReview, InsightsReviewService } from 'app/shared/insights.review.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-insights-as-landing',
  templateUrl: './insights-as-landing.component.html',
  styleUrls: ['./insights-as-landing.component.css'],
  providers: [DialogService]
})
export class InsightsAsLandingComponent implements OnInit {

  building: Building;
  license: LicenseBuilding;
  isLoading: boolean;
  collectionForAssets: CollectionForAssets;
  clearCache: boolean;
  runReport: boolean;

  startDate: Date;
  endDate: Date;

  reportWasGenerated: boolean;
  reportCollection: CollectionAsset;
  insight: InsightReview;
  dialogRef: DynamicDialogRef;

  selectedReview: IGetReviewsForBuildingItemReview;
  reviewList: any[];

  reviews: IGetReviewsForBuildingItemReview[];
  inProgressReview: IGetReviewsForBuildingItemReview;

  generateItems = [
    {
      label: 'Clear cache & run', icon: 'pi pi-refresh', command: () => {
        this.generateReport(true);
      }
    }];

  useOrgLogo = false;
  isGeneratingReport: boolean;

  constructor(public dialogService: DialogService, public messageService: MessageService, private apiService: APIService, private billingService: BillingService, private buildingService: BuildingsService, private insightsReviewService: InsightsReviewService) {

  }

  ngOnInit(): void {
    this.insightsReviewService
      .getReviewsForOrg()
      .then(reviewStates => {
        console.log(reviewStates);
        this.reviewList = reviewStates.reviews;
      });
  }

  selectExistingReview(event: any) {
    console.log(event);
    this.startDate = this.selectedReview.forDate;
    this.endDate = this.selectedReview.endDate;
    this.insightsReviewService.getReviewFromUUID(this.selectedReview.job_uuid);
  }

  dateChange() {
    if (this.endDate < this.startDate) {
      this.endDate = moment(this.startDate).add(2, 'months').toDate();
    }
  }

  dateToday() {
    this.startDate = moment().subtract(2, 'months').toDate();
    this.endDate = new Date();
  }

  manageCollections() {
    this.dialogRef = this.dialogService.open(DialogManageCollectionsComponent, {
      header: `Manage Collections`,
      width: '90vw',
      height: '80vw',
      data: { type: 'reporting', building: this.building, collectionForAssets: this.collectionForAssets }
    });
    this.dialogRef.onClose.subscribe((collectionForAssets: CollectionForAssets) => {
      this.buildingSelected(this.building);
    });
  }

  manageLicense() {
    this.dialogRef = this.dialogService.open(DialogLicenseComponent, {
      header: `License for ${this.license.title}`,
      width: '80%',
      data: { license: this.license }
    });
    this.dialogRef.onClose.subscribe((license: License) => {
      if (license) {
        this.messageService.add({ severity: 'info', summary: 'License updated', detail: license.title });
        this.building.license = license;
      }
    });
  }

  buildingChanged(building: Building) {
    this.collectionForAssets = null;
    this.building = null;
  }

  async reviewSelected(review: IGetReviewsForBuildingItemReview) {

    this.startDate = this.endDate = this.building = null;

    setTimeout(async () => {
      this.startDate = moment(review.forDate).toDate();
      this.endDate = moment(review.endDate).toDate();

      console.log('Retrieving building');
      const building = await this.getBuilding(review.building_id);
      await this.getCollections(building);
      console.log('Retrieving collections');

      await this.getBuildingLicenses(building);

      this.building = building;
      console.log(`collection id: ${review.collection_id}`);
      if (this.collectionForAssets.setSelectedForId(review.collection_id) === -1) {
        this.apiService.toastWarn('Collection error', 'Error with collection for review (does not link to building)');
        this.building = null;
        this.isGeneratingReport = false;
        this.runReport = false;
      };
      this.isLoading = false;
    }, 100);

  }

  async getBuilding(buildingId: number): Promise<Building> {
    const building: Building = await this.buildingService.getOne(buildingId);

    return building;
  }

  async getCollections(building: Building) {
    if (!building?.id) {
      return;
    }
    this.isLoading = true;
    this.collectionForAssets = null;

    const collectionAssets = await this.buildingService
      .getCollections(building.id, 'reporting');

    // Pre select collections
    if (collectionAssets.collections.length === 1) {
      collectionAssets.collections[0].selected = true;
    } else {
      collectionAssets.collections.forEach(c => c.selected = false);
    }
    this.collectionForAssets = collectionAssets;
    if (this.collectionForAssets.collections.length === 1) {
      // Always set a list of only one collection to "selected"
      this.collectionForAssets.setSelectedIndex(0);
    }
  }

  async getBuildingLicenses(building: Building) {
    const licenses = await this.billingService
      .getLicensesForBuilding(building.id)

    building.licenses = licenses;
    this.license = new LicenseBuilding(building.licenses[0]);
    this.license.setBuilding(building);
    if (!this.startDate) {
      this.startDate = this.license.licensedAt;
      this.endDate = this.calcDateRange(this.startDate).to;
    }
  }

  async buildingSelected(building: Building) {
    console.log('got building', building);
    this.isLoading = true; 1
    this.collectionForAssets = null;

    this.building = null;

    await this.getCollections(building);

    this.isLoading = false;

    this.insightsReviewService
      .getReviewsForBuilding(building, this.collectionForAssets.collections[0]?.id || null)
      .then(reviewStates => {
        reviewStates.reviews = reviewStates.reviews.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
        console.log(reviewStates);
        this.reviews = reviewStates.reviews;
        this.inProgressReview = this.reviews.find(r => r.state === 'new');

        if (this.inProgressReview) {
          this.building = building;
          this.startDate = new Date(this.inProgressReview.forDate);
          this.endDate = new Date(this.inProgressReview.endDate);

          this.generateReport();
        } else {
          this.building = building;

          this.isLoading = false;
          this.reportWasGenerated = false;
          this.runReport = false;
        }
      });
  }

  /**
   * Start another date range
   */
  completeReview() {
    this.startDate = moment(this.inProgressReview.endDate).add(1, 'day').toDate();
    this.endDate = moment(this.startDate).add(1, 'month').toDate();
    this.inProgressReview = null;
  }

  calcDateRange(date: Date): { from: Date, to: Date } {
    const days = 91;

    return {
      from: date, to: moment(date).add(days, 'days').toDate()
    };
  }

  clickCollection(index: number) {
    this.collectionForAssets.setSelectedIndex(index);
  }

  generateReport(reGenerateDate = false) {
    console.log('generateReport()', reGenerateDate);
    // If no data AND user is allowed, generate the report data.
    // Insights type is 'assets'

    this.clearCache = reGenerateDate;

    //this.dateSelected = this.dates[this.datesIndexSelected].date;

    if (this.runReport && !this.reportWasGenerated) {
      console.log('REPORT_GENERATING_PLEASE_WAIT');

      return;
    }

    if (this.runReport) {
      this.runReport = false;
      setTimeout(() => {
        this.runReport = true;
        this.reportWasGenerated = false;
      }, 10);
    } else {
      this.insight = null;
      this.reportCollection = this.collectionForAssets.collections.find(c => c.selected);

      if (!this.reportCollection) {
        this.messageService.add({ detail: 'Please select a collection', severity: 'info' });

        return;
      }
      this.reportWasGenerated = false;
      this.runReport = true;
    }
  }

  onReportWasGenerated(insight: InsightReview) {
    console.log('onReportWasGenerated()', insight);
    console.log(insight);
    this.reportWasGenerated = true;
    this.insight = insight;
  }

  closeReport() {
    console.log('closeReport()');
    this.runReport = false;
    this.reportWasGenerated = false;
    this.collectionForAssets.collections.forEach(c => c.selected = false);
  }

  toolbarClick(event: any) {
    this.manageLicense();
  }
}

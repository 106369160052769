import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { User } from 'app/classes/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { AssetChart, ChartFavs } from '../classes/charts';
import { APIService } from './api.service';



@Injectable({
  providedIn: 'root'
})
export class ChartService {

  private _charts: BehaviorSubject<ChartFavs> = new BehaviorSubject(null);
  public charts: Observable<ChartFavs> = this._charts.asObservable();

  favs: ChartFavs;
  user: User;

  constructor(private http: HttpClient, private apiService: APIService) {
    this.apiService.user.subscribe(user => {
      this.user = user;
      if (user) {
        const storedFavs = localStorage.getItem(`org:${user.org.id}:charts:favs`);
        this.favs = new ChartFavs(storedFavs);
        // console.log('Chart favourites', this.favs);
        this._charts.next(this.favs);
      } else {
        // No user reset favs
        this.favs = new ChartFavs();
      }
    });
  }

  saveFavs() {
    localStorage.setItem(`org:${this.user.org.id}:charts:favs`, JSON.stringify(this.favs.serialise()));
    this._charts.next(this.favs);
  }

  toggleAssetChart(asset: Asset) {
    const indx = this.favs.charts.findIndex(chart => chart.typeName === 'asset' && chart.getId() === asset.id);
    if (indx >= 0) {
      this.favs.charts.splice(indx, 1);
      this.saveFavs();
    } else {
      this.addAssetChart(asset);
    }

  }

  addAssetChart(asset: Asset) {
    this.favs.addAssetChart(asset);
    this.saveFavs();
  }

  updateChart(updateChart: AssetChart) {
    //const asset = this.favs.charts.find(chart => chart.typeName === 'asset' && chart.getId() === updateChart.id)

    this.saveFavs();
    console.log('Chart favourites', this.favs);
    // this._charts.next(this.favs);
  }

  chartTypes() {
    return ['line', 'spline', 'step', 'area', 'area-spline', 'area-step', 'bar', 'scatter', 'stanford', 'pie', 'donut', 'gauge'];
  }


  getS3(assetId: number, year: number, month: number): Promise<any[]> {
    return this.apiService.getChartS3(assetId, year, month);
  }
}


<article>
  <!-- discover building -->

  <section class="no-print">
    <!--
    <div class="text-right">
        <select [(ngModel)]="selectedReview" class="existing-reviews" (change)="selectExistingReview($event)">
            <option [ngValue]="undefined">Select an existing review...</option>
            <option *ngFor="let review of reviewList" [value]="review.id">{{review.title}} ({{review.collectionTitle}})
            </option>
        </select>
        </div>
        -->

    <app-theme-page-section>
      <div class="building-review-select">
        <div>
          <app-select-building
            (onBuildingChanged)="buildingChanged($event)"
            (onBuildingSelected)="buildingSelected($event)"
            (onReviewSelected)="reviewSelected($event)">
          </app-select-building>
        </div>
        <div></div>
      </div>
    </app-theme-page-section>
  </section>

  <!-- Building details -->
  @if (building && !collectionForAssets?.hasCollections && !isLoading) {
    <section class="no-print">
      <p-panel header="No asset collection">
        There is no defined collection of assets for this building, to proceed create the collection of assets required for the report.
        <!--       <app-theme-page-section>
            <app-collection-assets type="reporting" [building]="building"></app-collection-assets>
        </app-theme-page-section> -->
        <p class="p-mt-1 text-right">
          <button pButton label="Manage Collections" class="p-button-outlined p-button-sm p-button-secondary" (click)="manageCollections()"></button>
        </p>
      </p-panel>
    </section>
  }
  @if (building && collectionForAssets?.hasCollections) {
    <section class="no-print">
      <table class="table collections" aria-label="Collections">
        <thead>
          <tr>
            <th>Collection</th>
            <th>Assets</th>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor [ngForOf]="collectionForAssets.collections" let-collection let-i="index">
            <tr class="can-click" (click)="clickCollection(i)">
              <td>
                <i class="mdi" [class.mdi-radiobox-blank]="!collection.selected" [class.mdi-radiobox-marked]="collection.selected"></i> {{ collection.title }}
              </td>
              <td class="assets">
                @for (a of collection.assets; track a.id) {
                  <span [pTooltip]="a.title" tooltipPosition="bottom">{{ a.title || a.id }}</span>
                }
              </td>
            </tr>
          </ng-template>
          <tr>
            <td colspan="2" class="text-right">
              <button pButton label="Manage Collections" class="p-button-outlined p-button-sm p-button-secondary p-mt-1" (click)="manageCollections()"></button>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  }

  @if (building) {
    <section class="license no-print">
      <app-theme-page-section [label]="inProgressReview ? 'Review in progress' : 'Review date range'" (onToolbarClick)="toolbarClick($event)">
        <div class="info p-ml-1 p-mt-1">
          <div class="">Licensed At</div>
          <div class="">{{ building.license?.licensedAt | amDateFormat: 'ddd DD MMM YYYY' }}</div>
          <div class="">Start date</div>
          <div class="" *ngIf="inProgressReview">
            <h4>{{ startDate | amDateFormat: 'DD/MM/YYYY' }}</h4>
          </div>
          <div class="" *ngIf="!inProgressReview">
            <p-calendar [(ngModel)]="startDate" dateFormat="dd/mm/yy" (onSelect)="dateChange()"></p-calendar>
            <i class="mdi mdi-calendar-today can-hover" pTooltip="Today" (click)="dateToday()"></i>
          </div>
          <div class="">End date</div>
          <div class="" *ngIf="inProgressReview">
            <h4>{{ endDate | amDateFormat: 'DD/MM/YYYY' }}</h4>
          </div>
          <div class="" *ngIf="!inProgressReview">
            <p-calendar [(ngModel)]="endDate" dateFormat="dd/mm/yy"></p-calendar>
          </div>
        </div>
        <div *ngIf="inProgressReview">
          <button
            pButton
            pTooltip="Moved date ahead one month"
            label="Start another review"
            class="p-button-outlined p-button-sm p-button-secondary p-mt-1"
            (click)="completeReview()"></button>
        </div>
      </app-theme-page-section>
    </section>
  }
  <!--
    <section>
        <label>Select Quarter</label>
        <select class="form-control" [(ngModel)]="datesIndexSelected">
            <option *ngFor="let option of dates;let i = index" [value]="i">{{option.title}}</option>
        </select>
    </section>
-->
  <section></section>

  <section class="mt-1 text-center no-print" *ngIf="building && collectionForAssets?.selectedCollection">
    <p-splitButton [label]="'Generate Report'" icon="mdi mdi-chart-areaspline-variant" (onClick)="generateReport()" [model]="generateItems"></p-splitButton>
  </section>

  <section *ngIf="runReport">
    <div class="mt-1">
      <app-insights-as-report
        *ngIf="startDate && endDate && building"
        [building]="building"
        [startDate]="startDate"
        [endDate]="endDate"
        [collection]="reportCollection"
        [useOrgLogo]="useOrgLogo"
        (onReportGenerated)="onReportWasGenerated($event)"
        [clearCache]="clearCache"
        (onReportClosed)="closeReport()">
      </app-insights-as-report>
    </div>
  </section>
</article>
<app-please-wait *ngIf="isLoading"></app-please-wait>

import { Component, signal, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-header-item',
  templateUrl: './table-header-item.component.html',
  styleUrl: './table-header-item.component.css'
})
export class TableHeaderItemComponent {
  _label = signal<string>('');
  @Input()
  public set label(v: string) {
    this._label.set(v);
  }
  _value = signal<number>(null);
  @Input()
  public set value(v: number) {
    this._value.set(v);
  }

  @Output()
  onHeaderClick = new EventEmitter<number>();

  headerClick() {
    switch (this._value()) {
      case 1:
        this._value.set(2);
        break;
      case -1:
      case 2:
        this._value.set(0);
        break;
      default:
        this._value.set(1);
        break;
    }

    this.onHeaderClick.emit(this._value());
  }
}

import { Component, OnInit } from '@angular/core';
import { Site } from "../../classes/site";
import { Gateway } from "../../classes/gateway";
import { Asset } from "../../classes/asset";
import { APIService } from "../../shared/api.service";
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Ticket } from "../../classes/ticket";
import { createTicket, TicketKind } from "../../classes/factory";

@Component({
  selector: 'app-issue-update',
  templateUrl: './issue-update.component.html',
  styleUrls: ['./issue-update.component.css']
})
export class IssueUpdateComponent implements OnInit {

  selectedSite: Site;
  selectedGateway: Gateway;
  selectedAsset: Asset;

  title: string;
  description: string;

  step: number = 1;

  constructor(private router: Router, private route: ActivatedRoute, private apiService: APIService) { }

  ngOnInit() {
  }

  sitePickerSubmit(event: any) {
    console.log('picked', event);
  }

  siteSelected(site: Site) {
    this.selectedSite = site;
    setTimeout(() => {
      window.scroll(0, 0);
    }, 100);
  }

  gatewaySelected(gateway: Gateway) {
    this.selectedGateway = gateway;
  }

  sensorSelected(asset: Asset) {
    this.selectedAsset = asset;
    this.step = 2;
  }

  cancelSite() {
    this.selectedGateway = null;
    this.selectedAsset = null;
    this.selectedSite = null;
    this.step = 1;
  }

  cancelGateway() {
    this.selectedGateway = null;
    this.selectedAsset = null;
    this.step = 1;
  }

  cancelAsset() {
    this.selectedAsset = null;
    this.step = 1;
  }


  gotoStep(step: number) {
    this.step = 2;
  }

  submit() {

    let ticket: Ticket;
    let kindOfTicket: TicketKind;

    // Base ticket data

    let data = {
      title: this.title,
      description: this.description,
      assetId: null,
      siteId: null,
      gatewayId: null
    };

    if (this.selectedAsset) {
      kindOfTicket = TicketKind.Asset;      
      data.assetId = this.selectedAsset.id;
    } else if (this.selectedGateway) {
      kindOfTicket = TicketKind.Gateway;
      data.gatewayId = this.selectedGateway.id;
    } else {
      kindOfTicket = TicketKind.Site;
      data.siteId = this.selectedSite.id;
    }
    ticket = createTicket(kindOfTicket, data);

    this.apiService.postTicket(ticket).then(results => {

     this.router.navigate(['/issues']);
    });

  }

}

<article *ngIf="building">

	<div pTooltip="Go back" class="go-back">
		<span (click)="goBack()"><i class="fa fa-angle-left"></i> {{building.title}}</span>
	</div>

	<section class="building">
		<div pTooltip="{{building.license.licensedAt | amTimeAgo}}" tooltipPosition="bottom">
			<p>Online {{building.license.licensedAt | amDateFormat:apiService.getDateFormat()}}</p>
		</div>
	</section>

	<section class="assets cards">
		<h4>{{building.assets.length}} sensors</h4>
		<div class="panels">
			<div *ngFor="let asset of building.assets" class="panel panel-default">
				<div class="panel-heading">
					<div pTooltip="{{asset.title}}" tooltipPosition="bottom">{{asset.title}}</div>
				</div>
				<div class="panel-body">
					<div class="value">{{asset.value || asset.value === 0 ? asset.value : '-'}}</div>
					<div class="date" tooltipPosition="bottom"
						pTooltip="{{asset.updatedAt | amDateFormat:'DD MMM YYYY HH:mm:ss'}}">
						{{asset.updatedAt | amDateFormat:apiService.getDateFormat()}}</div>
					<div class="icon">
						<app-asset-icon [asset]="asset"></app-asset-icon>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="documents">
		<h4>{{building.documents.length}} documents</h4>
		<app-documents-list [documents]="building.documents"></app-documents-list>
	</section>

</article>

<app-toolbar>
    <div class="right">
        <app-button label="Create Contact" icon="mdi mdi-account-plus" (click)="createContact()"></app-button>
    </div>
</app-toolbar>
@defer(when contacts) {
@if(contacts.length) {
<table class="table table-hover table-striped">
    <thead>
        <tr>
            <th>Name</th>
            <th>Job Title</th>
            <th class="isDesktop">Email</th>
            <th class="isDesktop">Notes</th>
        </tr>
    </thead>
    <tbody>
        @for(contact of contacts;track contact) {
        <tr (click)="editContact(contact)">
            <td>{{contact.name}}</td>
            <td>{{contact.jobTitle}}</td>
            <td class="isDesktop">{{contact.email}}</td>
            <td class="isDesktop">{{contact.notes}}</td>
        </tr>
        }
    </tbody>
</table>
} @else {
<app-info-panel>No contacts</app-info-panel>
}
} @placeholder {
Please wait...
}

@if(isShowingDialog()) {
<app-dialog [dimensions]="{'width':500, 'height': 446}" (onClose)="isShowingDialog.set(false)">
    <app-org-contact-new [contact]="contact()" (hasSaved)="hasSaved($event)" (hasDeleted)="hasDeleted($event)" />
</app-dialog>
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monitor-container',
  templateUrl: './monitor-container.component.html',
  styleUrls: ['./monitor-container.component.css']
})
export class MonitorContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AssetFinding, BuildAssetFinding } from '../../../classes/reviews/asset-finding';

@Component({
  selector: 'app-review-finding-edit',
  templateUrl: './review-finding-edit.component.html',
  styleUrls: ['./review-finding-edit.component.css']
})
export class ReviewFindingEditComponent implements OnInit {

  @Input()
  finding: BuildAssetFinding;
 
  @Output()
  onDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onConvertToRange: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  @Output()
  onSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  debug: string = '';
  constructor() { }

  ngOnInit() {
  }

  debugJSON() {
    this.debug = JSON.stringify(this.finding, null, 2);
  }

}

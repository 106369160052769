<div class="live-view">
    @if(noLiveFeeds()) {
    <app-info-panel title="No live feeds">Your organisation has not yet been configured for live feeds.</app-info-panel>
    }

    @if(gateways && gateways.length) {
    <div class="search">
        <app-search-input [resultCount]="filtered.count" [searchText]="filtered.text" (onSearchChanged)="searchChanged($event)" tooltipText="Search gateway, site, address, org, online, offline & embed links"></app-search-input>
    </div>
    }

    @if(filtered.list && filtered.list.length) {
    <table>

    </table>

    @if (isMobile()) {
    @for(sg of sites();track sg.site.id) {
    <div class="gateway-container content-container">
        <header>{{sg.site.title}}</header>
        <main>
            @for(g of sg.gateways; track g.id) {
            <div [routerLink]="['/live', g.id]">{{g.title}}</div>
            }
        </main>
    </div>
    }
    } @else {
    <div class="content-container component-list">
        <table class="table table-sm table-hover sticky" aria-label="Gateways">
            <thead>
                <tr>
                    <th>Site</th>
                    <th>Gateway</th>
                    <th class="hidden-xs">Location</th>
                    <th class="hidden-sm hidden-xs">Org</th>
                    <th class="temp text-right hidden-sm hidden-xs" tooltipPosition="left" pTooltip="Outside temperature">Weather</th>
                    <th class="text-right hidden-sm hidden-xs" tooltipPosition="left" pTooltip="Gateways assets - includes sensors and wifi">
                        Assets</th>
                </tr>
            </thead>
            <tbody>
                @for(gateway of filtered.list; track gateway.id; let i = $index) {
                <tr [routerLink]="['/live', gateway.id]" [class.no-title]="i>0 && gateway.site.title === gateways[i-1].site.title">
                    <td><span *ngIf="i===0||gateway.site.title !== gateways[i-1].site.title" [escape]="false" tooltipPosition="top" [pTooltip]="'<div>' + gateway.site.address.address1 + '</div>' + '<div>' + gateway.site.address.addressTown + '</div>' + '<div>' + gateway.site.address.addressPostcode + '</div>'">{{gateway.site.title}}</span>
                    </td>
                    <td>
                        <app-gateway-indicator [gateway]="gateway"></app-gateway-indicator> {{gateway.title}}
                    </td>
                    <td class="hidden-xs">{{gateway.location}}</td>
                    <td class="hidden-sm hidden-xs" title="{{gateway.org.title}}">{{gateway.org.shortTitle}}</td>
                    <td class="text-right hidden-sm hidden-xs">
                        <app-weather-temp [weather]="gateway.weather" />
                    </td>

                    <td class="text-right hidden-sm hidden-xs">{{gateway.assetCount || 0}}</td>
                </tr>
                }
            </tbody>
        </table>
    </div>
    }
    }

</div>
@if(loading) {
<app-please-wait></app-please-wait>
}

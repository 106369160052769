<div class="row">
  <!-- PLEASE WAIT, LOADING-->

  <app-please-wait *ngIf="init"></app-please-wait>

  <!-- TOKEN OR CERTIFICATE DOES NOT EXIST-->
  <div *ngIf="!init && db === null" class="col-md-12">
    <h1>Certificate</h1>
    <p><i class="fa fa-times"></i><i> The certificate/token was not found or has been removed.</i></p>
  </div>
  <!-- RESULTS RECEIVED -->
  <div *ngIf="!init && db !== null" class="col-md-12">
    <h1>Certificate</h1>
    <div *ngIf="db?.id">
      <i class="fa fa-fw fa-certificate"></i> Certificate: {{db.id}}
      <p><i class="fa fa-check"></i><i> The certificate was created and is trusted.</i></p>
      <div *ngIf="!db.gatewayId">
        <p>Gateway #{{db.reference}} has not yet sent telemetry.</p>
        <p>The gateway record will be created when the telemetry is received.</p>
      </div>
    </div>

    <h1>Gateway</h1>
    <div *ngIf="db?.gatewayId">
      <p><i><i class="fa fa-database"></i> The gateway has sent telemetry, the gateway record has been created.</i>
      </p>
      <p><i class="fa fa-fw fa-wifi"></i> Gateway: {{db?.gatewayId}}</p>
      <p><i class="fa fa-fw fa-calendar"></i> Last seen {{db?.seenAt | amTimeAgo}}</p>
    </div>
    <div *ngIf="!db?.gatewayId">
      <p class="btn-warning"><i> <i class="fa fa-database"></i>The gateway does not yet have a record in the 4D
          platform, a thing cannot be created. The 4D process has not been completed. A gateway record is created on
          telemetry received or a timeline change.</i> </p>

    </div>
    <h1>Assets</h1>
    <div *ngIf="!assets">
      <p>Gateway #{{db.reference}} has <span *ngIf="db.gatewayId">sent telementry but <b>no assets have been
            created.</b></span><span *ngIf="!db.gatewayId"> not yet sent telemetry.</span></p>
      <p><span *ngIf="!db.gatewayId">The asset records will be created when the telemetry is received.</span></p>
    </div>
    <div *ngIf="assets">
      <p><i><i class="fa fa-database"></i> The gateway has sent telemetry, the asset records have been created.</i> </p>
      <table class="table">
        <tbody>
          <tr *ngFor="let asset of assets">
            <td>{{asset.value}}</td>
            <td>{{asset.title}}</td>
          </tr>
        </tbody>
      </table>

    </div>
    <h1>IoT</h1>
    <div *ngIf="!iot">
      <p><i class="fa fa-times"></i> The IoT thing has not been created.</p>
      <button [disabled]="!db?.gatewayId" class="btn btn-primary" (click)="createThing()">Create the IoT thing.</button>
    </div>
    <div *ngIf="iot">
      <p><i class="fa fa-check"></i> The IoT thing has been created.</p>
      <p><i class="fa fa-fw fa-circle-o"></i> Type: {{iot?.thingTypeName}}</p>
      <p><i class="fa fa-fw fa-building"></i> Type: {{iot.attributes?.Company}}</p>
    </div>
  </div>
</div>
<div class="bottom-right hidden-sm hidden-xs">

  <div class="data">
    <iframe type="application/x-shockwave-flash" style="border:none;overflow-x:hidden;" allowscriptaccess="always"
      [src]="sanitizer.bypassSecurityTrustResourceUrl(myUrl)" width="100%" height="100%" allowfullscreen></iframe>
  </div>
</div>

<div class="data-stream mobile hidden-md hidden-lg">
  <div class="data">
    <iframe type="application/x-shockwave-flash" style="border:none;overflow-x:hidden;" allowscriptaccess="always"
      [src]="sanitizer.bypassSecurityTrustResourceUrl(myUrl)" width="100%" height="100%" allowfullscreen></iframe>
  </div>

</div>

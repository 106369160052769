<app-toolbar>
	<div class="left no-print">
		<app-button tooltipPosition="left" icon="mdi mdi-rotate-3d-variant" size="medium" (click)="toggleView()" [label]="view === 'realtime' ? 'Realtime' : 'Occupancy'" [pTooltip]="view === 'realtime' ? 'Showing all sensors, click to show occupancy' : 'Showing occupancy, click to show all sensors'" />

		@if (view === 'occupancy daily') {
		<app-button icon=" mdi mdi-rewind" tooltipPosition="bottom" pTooltip="Go back" label="previous week" (click)="clickedPrevious('week')" />
		<app-button tooltipPosition="bottom" pTooltip="Go back" label="previous {{view === 'occupancy daily' ? ' day' : 'week'}}" (click)="clickedPrevious()" />
		<app-button tooltipPosition="bottom" pTooltip="go Forward" label="next {{view === 'occupancy daily' ? ' day' : 'week'}}" (click)="clickedNext()" />
		<app-button icon="mdi mdi-fast-forward" iconPos="right" tooltipPosition="bottom" pTooltip="go Forward" label="next week" (click)="clickedNext('week')" />


		<p-calendar firstDayOfWeek="1" dateFormat="D dd M yy" [(ngModel)]="from" inputId="icon" [showTime]="false" />

		<span class="from-days">{{fromDaysAgo}}</span>
		}
	</div>
</app-toolbar>

<article #chartcontainer [class.is-fullscreen]="isFullscreen()">
	<header>
		<app-toolbar>
			<div class="left flex">
				<app-select [list]="assetList()" [block]="false" [value]="value()" (onSelected)="assetSelectedByUser(1,$event)"> </app-select>
				<app-select [list]="assetList()" [block]="false" [value]="value2()" (onSelected)="assetSelectedByUser(2,$event)"> </app-select>
			</div>
			<div class="right">
				<app-button-dates [value]="dates()" (onSelect)="dateChange($event)"></app-button-dates>
			</div>
		</app-toolbar>
	</header>
	<main>
		<div class="chart">
			<canvas id="chart-1" (onSelect)="datesSelected($event)"></canvas>
		</div>
	</main>
</article>

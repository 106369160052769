export class CalendarContext {
	target: string;
	object: any;
	constructor(target: string = null, object: any = null) {
		this.target = target;
		this.object = object;
	}
	serialise() {
		return (this.object ? { target: this.target, id: this.object.id } : null);
	}
}

<div class="shot">
  <div class="buttons" (click)="buttonClick($event)" [class.first]="first">
    <span id="recent" [class.active]="onView==='Recent'">
      Recent
    </span>
    <span id="night" [class.disabled]="!preset.shotNight" [class.active]="onView==='Night'"><i class="fa fa-moon-o"></i> Night Reference</span>
    <span id="day" [class.disabled]="!preset.shotDay"  [class.active]="onView==='Day'"><i class="fa fa-sun-o"></i> Day Reference</span>
    <span>{{preset.title}}</span>
  </div>

  <div class="content">
    <div [class.active]="onView==='Recent'">
      <img *ngIf="preset.recentBucketKey" src="https://s3.eu-west-2.amazonaws.com/4d-test/{{preset.recentBucketKey}}" />
    </div>
    <div [class.active]="onView==='Night'">
      <img *ngIf="preset.shotNight" src="https://s3.eu-west-2.amazonaws.com/{{preset.shotNight}}" />
    </div>
    <div [class.active]="onView==='Day'">
        <img *ngIf="preset.shotDay" src="https://s3.eu-west-2.amazonaws.com/{{preset.shotDay}}" />
      </div>
  </div>
</div>
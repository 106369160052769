import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { VisualiseItem } from 'app/classes/visualise-item';
import { StoreService } from 'app/shared/store.service';
import { WindowService } from 'app/shared/window.service';
import { Chart } from 'chart.js';


@Component({
  selector: 'app-visualise-data-chart',
  templateUrl: './visualise-data-chart.component.html',
  styleUrls: ['./visualise-data-chart.component.css']
})
export class VisualiseDataChartComponent implements OnInit {

  @Input()
  visualisationItem: VisualiseItem;

  data: any;
  options: any;
  id: string;
  width: string;
  height: string;

  public chart: any;

  constructor(private el: ElementRef, private windowService: WindowService, private storeService: StoreService) {

  }

  ngOnInit(): void {
    this.id = `visualise-data-chart-${this.visualisationItem.id}`;
    setTimeout(() => {
      this.buildChart1();
    }, 100);
  }

  buildChart1() {

    const themeColours = this.storeService.getThemeColours();

    if (this.windowService.isMobile()) {
      this.height = this.visualisationItem.height || `100%`;
      this.width = `375px`;
    } else {

      this.height = `${this.el.nativeElement.offsetHeight}px`;
      this.width = `${this.el.nativeElement.offsetWidth}px`;
    }

    const min = this.visualisationItem.minmax.min;
    const max = this.visualisationItem.minmax.max;

    const ctx = (<any>document.getElementById(this.id)).getContext('2d');

    ctx.fillStyle = 'white';
    ctx.strokeStyle = 'white';

    this.chart = new Chart(this.id, {
      type: 'bar',
      data: {// values on X-Axis
        labels: this.visualisationItem.rows.map(row => row.label),
        datasets: [
          {
            label: this.visualisationItem.dataset.label,
            data: this.visualisationItem.rows.map(row => row.value),
            backgroundColor: this.visualisationItem.rows.map(row => row.backgroundColor),

          }
        ]
      },

      options: {

        plugins: {
          title: { color: themeColours.foreground_colour },
          legend: { labels: { color: themeColours.foreground_colour }, display: false },

        },
        indexAxis: 'y',
        maintainAspectRatio: false,

        scales: {
          x: {
            min, max
          },
          y: {
            ticks: {
              autoSkip: false
            }
          }
        }
      }
    });
  }


  buildChart() {
    const ctx = document.getElementById('myChart');
    this.chart = new Chart("My Chart",
      {
        type: 'bar',
        data: {
          labels: this.visualisationItem.rows.map(row => row.label),
          datasets: [
            {
              label: this.visualisationItem.dataset.label,
              data: this.visualisationItem.rows.map(row => row.value),
              backgroundColor: 'black'
            }
          ]
        },
        options: {
          indexAxis: 'y',
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: 'blue',
                font: {
                  size: 22
                }
              }
            }
          }
        }
      });

    //    const chartEl = document.getElementById("myChart");
    //    chartEl.style.height = "230px";
  }
}

import { Asset } from './asset';

export abstract class Chart {
	typeName: string;
	chartType: 'line' | 'spline' | 'step' | 'area' | 'area-spline' | 'area-step' | 'bar' | 'scatter' | 'stanford' | 'pie' | 'donut' | 'gauge' = 'line';
	size = 12;
	showPoints = false;

	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.size = data.size || 12;
		this.showPoints = data.showPoints || false;
	}

	getId(): number | string {
		throw new Error('NOT IMPLEMENTED');
	}
}

export class AssetChart extends Chart {
	id: number;

	constructor(data?: any) {
		super(data);
		this.typeName = 'asset';
		if (!data) {
			return;
		}
		this.id = data.id;
		this.chartType = data.chartType || 'line';

	}

	override getId(): number {
		return this.id;
	}
}

export class ChartFavs {
	charts: Chart[] = [];
	constructor(data?: any) {
		if (data) {
			if (typeof data === 'string') {
				data = JSON.parse(data);
			}
			data.charts.forEach(chart => {
				switch (chart.typeName) {
					case 'asset':
						this.charts.push(new AssetChart(chart));
						break;
				}
			});
		}
	}

	addAssetChart(asset: Asset) {
		const index = this.charts.findIndex(c => c.typeName === 'asset' && c.getId() === asset.id);

		if (index >= 0) {
			console.log('chart exists');
			return;
		}
		const chart = new AssetChart({ id: asset.id });
		this.charts.push(chart);
	}

	serialise() {
		return {
			charts: this.charts
		};
	}
}

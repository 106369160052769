import { Component, OnInit } from '@angular/core';
import { DocumentsService } from 'app/shared/documents.service';
import { ActivatedRoute } from '@angular/router';
import { Building } from 'app/classes/building';
import { APIService } from 'app/shared/api.service';
import { Location } from "@angular/common";
@Component({
  selector: 'app-building-landing',
  templateUrl: './building-landing.component.html',
  styleUrls: ['./building-landing.component.css']
})
export class BuildingLandingComponent implements OnInit {

  building: Building;
  constructor(public apiService: APIService, documentService: DocumentsService, route: ActivatedRoute, private location: Location) {

    route.params.subscribe(params => {
      documentService.getForBuilding(params.buildingid)
        .then(building => {
          this.building = building;
        });
    });

  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

}

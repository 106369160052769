import { Component, OnInit, OnDestroy } from '@angular/core';
import { Asset } from '../../classes/asset';
import { ActivatedRoute, Router } from '@angular/router'

import { CameraService } from '../../shared/camera.service';

@Component({
  selector: 'app-camera-landing',
  templateUrl: './camera-landing.component.html',
  styleUrls: ['./camera-landing.component.css']
})
export class CameraLandingComponent implements OnInit {

  cameras: Asset[];
  selectedCamera: Asset;

  constructor(private router: Router, private cameraService: CameraService, private route: ActivatedRoute) {
    cameraService.getCameras().then(cameras => {
      this.cameras = cameras.filter(asset => {
        return asset.purpose.category === 'camera';
      });
    });
  }

  ngOnInit() { }

  selectItem(camera: Asset) {
    switch (camera.purpose.id) {
      case 1:
        //this.router.navigate(['timelapse']);
        break;
      // case 2:
      //  this.router.navigate(['lightmonitor', camera.id], { relativeTo: this.route });
      //  break;
      default:
        this.router.navigate([camera.id, 'control'], { relativeTo: this.route });
        break;
    }
  }

  clickShowMore() {
    throw new Error("NOT_IMPLEMENTED");
  }


}

<article>

	<app-page-title label="Insight Building Reviews" [canGoBack]="true" routerLink="/insights"></app-page-title>

	<section>
		<app-search-input [searchText]="searchFor" (onSearchChanged)="searchChanged($event)" tooltipText="Search"></app-search-input>
	</section>

	<section>
		<table class="table table-hover" aria-label="Building reviews">
			<thead>
				<tr>
					<th>Title</th>
					<th>Collection</th>
					<th>Created</th>
					<th>Org</th>
					<th title="Created by ">Inits</th>
					<th>Debug</th>
				</tr>
			</thead>
			<tbody>
				@for(row of filteredResults;track row.collectionBuilding_id){
				<tr [ngClass]="{'xmismatch':row.collectionBuilding_id !== row.building_id}" (click)="reviewClick(row)">
					<td>{{row.title}}</td>
					<td>{{row.collectionTitle}} <span class="chip">#{{row.collectionOrg?.shortTitle}}</span> <span class="chip">#{{row.collection_id}}</span>

						<span *ngIf="row.collectionBuilding_id !== row.building_id" title="Building {{row.building_id}} does not match collection building {{row.collectionBuilding_id}}" class="chip"><i class="mdi red mdi-alert"></i> #{{row.building_id}} &lt;&gt;
							#{{row.collectionBuilding_id}}</span>
					</td>
					<td>{{row.createdAt | date:'dd MMM YY'}}</td>

					<td title="{{row.org?.title}}">{{row.org?.shortTitle}}</td>
					<td>{{row.createdBy.inits}}</td>
					<td (click)="getJSON(row.job_uuid)" class="hover">
						<i class="mdi mdi-code-json" title="{{row.job_uuid}}"></i>
				</tr>
				}
			</tbody>
		</table>
	</section>
</article>

<p-dialog header="Job Configuration" [(visible)]="json" [style]="{width: '75vw'}" *ngIf="json">
	<h3>Assets</h3>
	<table class="table" aria-label="Assets">
		<thead>
			<th>id</th>
			<th>Type id</th>
			<th>collectionId</th>
			<th>MetobsId</th>
		</thead>
		<tbody>
			<tr *ngFor="let asset of json.body.assets">
				<td>{{asset.id}}</td>
				<td>{{asset.assetType_id}}</td>
				<td>{{asset.collectionId}}</td>
				<td>{{asset.metobsid}}</td>
			</tr>
		</tbody>
	</table>
	<h3>Master Configuration</h3>
	<p>
		{{json.qs | json}}
	</p>
</p-dialog>

@if(quickReview) {

<app-dialog header="{{quickReview.review.title}}" [canScroll]="true" (onClose)="quickReview = null">
	<table class="table table-sm" aria-label="Review">
		<thead>
			<tr>
				<th>From</th>
				<th>To</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>{{quickReview.review.forDate | date:'dd MMM YY'}}</td>
				<td>{{quickReview.review.endDate | date: 'dd MMM YY'}}</td>
			</tr>
		</tbody>
	</table>

	<section>
		<h4>Building Calendar</h4>
		<table class="table table-sm">
			<thead>
				<tr>
					<th>Date</th>
					<th>CreatedBy</th>
					<th>RAG</th>
					<th width="60px">PDF</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of quickReview.calendarItems" (click)="calendarItemClick(item)" [ngClass]="{'active': item.isHighlighted}">
					<td pTooltip="{{item.createdAt | date:'dd MMM YY'}}">{{item.startAt | date:'dd MMM YY'}}</td>
					<td>{{item.createdByName }}</td>
					<td> <i class="fa fa-circle fa-fw {{item.rag}}"></i></td>
					<td>
						<a target="_blank" href="https://s3.eu-west-2.amazonaws.com/4d-documents/{{item.documentKey}}">
							<span pTooltip="View PDF document" tooltipPosition="bottom">
								<i class="fa fa-fw fa-file-pdf-o"></i>
							</span>
						</a>
					</td>
				</tr>
			</tbody>
		</table>

		<h4>Assets in Collection for review</h4>
		<table class="table table-sm">
			<thead>
				<tr>
					<th>Type</th>
					<th width="40%">asset</th>
					<th width="25%">Gateway</th>
					<th width="25%">Site</th>
					<th width="10%">Org</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of quickReview.review.collection.assets">
					<td><app-asset-icon [assetTypeId]="item.assetType_id"></app-asset-icon></td>
					<td>{{item.assetTitle }}</td>
					<td>{{item.gatewayTitle }}</td>
					<td>{{item.siteTitle}}</td>
					<td>{{item.orgShortTitle}}</td>

				</tr>
			</tbody>
		</table>
	</section>

	<p-panel [toggleable]="true" [collapsed]="true">
		<ng-template pTemplate="header"> <span class="text-secondary font-semibold text-sm">Debugging</span></ng-template>
		<p>
			{{review | json}}
		</p>
	</p-panel>

</app-dialog>

}

<app-please-wait *ngIf="isLoading()"></app-please-wait>

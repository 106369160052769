<article>
    <section class="groups">
        <header>
            <div>
                User Groups
            </div>
            <div>
                <app-button label="Modify" size="small" icon="mdi mdi-account-multiple-plus" (click)="modifyUserGroups()"></app-button>
            </div>
        </header>

        @if(rule.groupings?.length) {
        <table class="table" aria-label="User groups">
            <thead>
                <tr>
                    <th>Group</th>
                </tr>
            </thead>
            <tbody>
                @for(group of rule.groupings;track group.title) {
                <tr>
                    <td>{{group.title}}</td>
                </tr>
                }
            </tbody>
        </table>
        } @else {
        <div class="italic">No Groups selected</div>
        }
    </section>
</article>

@if(showDialog()) {
<app-dialog (onClose)="submit($event)" [dimensions]="{width:600,height:480}" [footer]="[{type:'action', label:'Update', id:'update'}]">
    <main class="dialog">
        <section class="details">
            <div>
                <div>User Group</div>
            </div>
            <div *ngFor="let grouping of dialogGroupings">
                <div class="grouping" [class.active]="groupingActive.id === grouping.id" (mouseenter)="groupingActive = grouping" (click)="grouping.selected = (grouping.selected ? false : true)">
                    <app-tick [selected]="grouping.selected"></app-tick>&nbsp; {{grouping.title}}
                </div>
            </div>
        </section>
        <section class="users">
            <div>
                <div class="selected" *ngIf="groupingActive">
                    <label>In Group</label>
                    <p *ngIf="groupingActive.users.length===0"><i class="red">No users</i></p>
                    <ul>
                        <li *ngFor="let user of groupingActive?.users"> {{user.name}}</li>
                    </ul>
                    <p class="notes" [innerHTML]="groupingActive.notes"></p>
                </div>
            </div>
        </section>
    </main>
</app-dialog>
}

@if(pleaseWait()) {
<app-please-wait></app-please-wait>
}

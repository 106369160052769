<article>
	<header>
		<h3>{{title}}</h3>
	</header>
	<main>
		<section *ngFor="let item of items" (click)="itemClick(item)">
			<div>
				<img [src]="item.thumbnail">
				<h5>{{item.title}}</h5>
				<p>{{item.overview}}</p>
			</div>

		</section>
	</main>
</article>


<p-dialog header="Title" [(visible)]="showVideo" *ngIf="showVideo">
	<video controls autoplay loop muted [poster]="showVideo.thumbnail">
		<source [src]="showVideo.target" type="video/mp4">
	</video>
</p-dialog>

import { Component, input } from '@angular/core';

@Component({
  selector: 'app-dirty-message',
  templateUrl: './dirty-message.component.html',
  styleUrl: './dirty-message.component.css'
})
export class DirtyMessageComponent {

  message = input<string>('');
}

import { Component, OnInit, signal } from '@angular/core';
import { StoreService } from '../../shared/store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { Site } from '../../classes/site';
import { CombinedCollectionItem, SiteService } from 'app/shared/site.service';

@Component({
  selector: 'app-site-dashboard',
  templateUrl: './site-dashboard.component.html',
  styleUrls: ['./site-dashboard.component.css']
})
export class SiteDashboardComponent {

  siteId: number;
  subtitle: string = 'Loading...';
  site = signal<Site>(null);
  orgUsers: any[] = [];
  orgUsersAvailable: any[] = [];
  tabIndex = signal<number>(0);
  combinedCollections = signal<CombinedCollectionItem[]>(null);

  constructor(public apiService: APIService, private router: Router, private route: ActivatedRoute, private siteService: SiteService) {
    route.params.subscribe(params => {
      this.siteId = params['siteid'];
      apiService.getSiteExtended(this.siteId).then(site => {
        this.site.set(site);
        this.subtitle = `Site ${site.title}`;
      });
      this.getCollections();
    });
    const tab = this.route.snapshot.queryParamMap.get('tab');
    if (tab) {
      this.tabSet(+(['notifications', 'collections'].indexOf(tab) ?? 0));
    }
  }

  getCollections() {
    this.siteService.getCollections(this.siteId).then(collections => this.combinedCollections.set(collections));
  }

  goBack() {
    this.router.navigate(['/org']);
  }

  tabSet(tabIndex: number) {
    if (tabIndex >= 0 && tabIndex < 2) {
      this.tabIndex.set(tabIndex);
    }
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { Report } from '../../classes/reports/report';
import { ReportBlock } from '../../classes/reports/report-block';
import { ReportFragment } from '../../classes/reports/report-fragment';

@Component({
  selector: 'app-report-text',
  templateUrl: './report-text.component.html',
  styleUrls: ['./report-text.component.css']
})
export class ReportTextComponent implements OnInit {
  // Raw data received from the API
  @Input()
  set data(data: any[]) {
    // console.log('ReportTextComponent setData', data);
    this._data = data;
  }
  _data: any;
  @Input()
  set config(data: any[]) {
    // console.log('ReportTextComponent setConfig', data);
    let hasData = typeof this._config !== 'undefined';
    if (data) {
      this._config = data;
      if (hasData) {
        // Fresh data received
        this.prepareData();
      }
    }
  }
  _config: any;
  @Input()
  reportBlock: ReportBlock;
  @Input()
  blockReady: boolean;
  body: string;

  constructor() { }

  ngOnInit() {

    this.prepareData();
  }

  prepareData() {
    // Get the text from the options
    let options = JSON.parse(this.reportBlock.options);
    this.body = options.body;
  }

}

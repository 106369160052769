<div *ngIf="plan">
  <img src="https://55auilnnlh.execute-api.eu-west-2.amazonaws.com/4d/floorplans/{{plan.planKey}}.svg?v=7&vbx={{viewbox.x}}&vby={{viewbox.y}}&vbw={{viewbox.w}}&vbh={{viewbox.h}}">
  <svg [attr.viewBox]="viewbox.x + ' ' + viewbox.y + ' '  + viewbox.w + ' ' + viewbox.h">
    <svg:circle (click)="popupDetails()" class="asset-circle" [attr.cx]="plan.assetX" [attr.cy]="plan.assetY" [attr.r]="radius">
      <animate attributeName="r" begin="0s" dur="6s" repeatCount="indefinite" from="1%" to="5%" />

    </svg:circle>
  </svg>
  <app-sensor-popup *ngIf="isShowingPopupSensorData" [(show)]="isShowingPopupSensorData" [asset]="asset"></app-sensor-popup>
</div>

<article [class.fullscreen]="isFullscreen" #webcamcontainer>

	<div class="">
		<button class="btn btn-primary btn-lg " (click)="triggerSnapshot()"><span *ngIf="width <= 600"><i
					class="mdi mdi-phone-rotate-landscape"></i> Rotate to
				landscape</span> <span *ngIf="width>600">Capture Image</span></button>
		<button class="btn btn-secondary btn-lg " (click)="isFullscreen = isFullscreen ? false : true"><i
				class="mdi mdi-fullscreen"></i></button>
	</div>

	<section>
		<webcam *ngIf="width > 600" (imageCapture)="handleImage($event)" [width]="width" [height]="height"
			[trigger]="triggerObservable" imageType="image/png" [imageQuality]="1"></webcam>
	</section>

</article>

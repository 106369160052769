import { Component, Input, OnInit, signal } from '@angular/core';
import { UserIdCardComponent } from 'app/chips/user-id-card/user-id-card.component';
import { User } from 'app/classes/user';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-partner-dashboard',
  templateUrl: './partner-dashboard.component.html',
  styleUrls: ['./partner-dashboard.component.css']
})
export class PartnerDashboardComponent implements OnInit {
  isWorking = signal<boolean>(false);
  @Input()
  users: User[];

  selectedUser: { user: any, results: any[], filtered?: any[] };

  compressDuplicates: boolean = true;

  isLoadingUser: any;

  constructor(private apiService: APIService) {

  }

  ngOnInit(): void {
  }

  selectUser(user: any) {
    console.log(user);
    this.isLoadingUser = user;
    this.apiService
      .getEngagementController(`user-routes&uid=${user.id}&o=0&l=1000`, null, null, null, null)
      .then(results => {
        this.isLoadingUser = null;
        this.selectedUser = { user, results };
        this.compress();
      });
  }

  compress() {
    if (this.compressDuplicates) {
      const filtered = [];
      this.selectedUser.filtered = this.selectedUser.results.filter(row => {
        const dt = new Date(row.createdAt);
        const chk = dt.getFullYear() + '-' + dt.getMonth() + '-' + dt.getDate();
        const fnd = filtered.find(item => {
          if (item.chk === chk && item.row.path === row.path) return true;
        });
        if (fnd) {
          fnd.c++;
        } else {
          filtered.push({ row, chk, c: 1 });
        }
      });
      this.selectedUser.filtered = filtered;
    } else {
      this.selectedUser.filtered = this.selectedUser.results.map(row => { return { row, c: 0, chk: null } });
    }
  }

  compressDuplicatesClick() {
    this.isWorking.set(true);
    this.selectedUser.filtered = [];
    setTimeout(() => {
      this.compressDuplicates = !this.compressDuplicates;
      this.compress();
      setTimeout(() => {
        this.isWorking.set(false);
      }, 200);
    }, 10);
  }

}

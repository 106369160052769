import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private router: Router, private apiService: APIService) { }

  ngOnInit() {
    this.apiService.toastWarn('Page not found', '');
    this.router.navigate(['/dashboard']);
  }

}

<p-tabView *ngIf="grouping">
    <p-tabPanel [cache]="false">
        <ng-template pTemplate="header">
            <span><i class="mdi mdi-pencil-box-outline"></i> Configuration</span>
        </ng-template>
        <ng-template pTemplate="content">
            <app-theme-page-section>

                <main>
                    <section class="details">
                        <div class="form-group">
                            <label for="title">Group title </label>
                            <input id="title" name="title" maxlength="50" class="form-control" [(ngModel)]="grouping.title">
                        </div>

                        <div class="form-group">
                            <div for="notes">Notes</div>
                            <textarea [(ngModel)]="grouping.notes" [style]="{'height':'200px', 'width':'100%'}"></textarea>

                        </div>
                    </section>
                    <section class="users">
                        <div>
                            <p-scrollPanel [style]="{width: '100%', height: '340px'}">
                                <div class="selected">
                                    <label>In Group</label>
                                    <p *ngIf="grouping.users.length===0"><i class="red">No users</i></p>
                                    <ul>
                                        <li *ngFor="let user of grouping.users" (click)="removeUser(user)" class="can-click" tooltipPosition="left" pTooltip="- Remove from group">
                                            {{user.name}}</li>
                                    </ul>
                                </div>

                                <div class="available">
                                    <label>Available</label>
                                    <ul>
                                        <li *ngFor="let user of usersAvailable" (click)="addUser(user)" class="can-click" tooltipPosition="left" pTooltip="+ Add to group">
                                            {{user.name}}
                                        </li>
                                    </ul>
                                </div>

                            </p-scrollPanel>
                        </div>
                    </section>
                </main>
                <footer>
                    <div class="buttons">
                        <div class="text-right">
                            <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-outlined p-button-secondary mr-1" (click)="cancel()">
                            </p-button>
                            <p-button [label]="grouping.id ? 'Update' : 'Create'" icon="mdi mdi-content-save" styleClass="" (click)="submit()">
                            </p-button>
                        </div>
                    </div>
                </footer>

            </app-theme-page-section>
        </ng-template>
    </p-tabPanel>


</p-tabView>

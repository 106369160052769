import { Component, OnInit, Input } from '@angular/core';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-dev-api-craft',
  templateUrl: './dev-api-craft.component.html',
  styleUrls: ['./dev-api-craft.component.css']
})
export class DevApiCraftComponent implements OnInit {

  @Input()
  api: any;

  @Input()
  public get filters(): string[] {
    return this._filters;
  }
  public set filters(v: string[]) {
    this._filters = v;
    this.rebuild();
  }


  _filters: string[];

  BASE = 'https://2rx4xrq7i1.execute-api.eu-west-2.amazonaws.com/v1/assets?key=';
  url = null;

  path = 'assets';

  assets: string;
  dateFrom: Date;
  dateTo: Date;
  en = {
    firstDayOfWeek: 1,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  };
  constructor(public apiService: APIService) { }

  ngOnInit() {
    this.rebuild();
  }

  removeFilter(index: number) {
    this.filters.splice(index, 1);
    this.rebuild();
  }

  rebuild() {
    this.url = this.BASE + this.api.apikey;
    this.path = 'assets';
    let parts = [];
    try {
      if (this.assets) {
        const work = this.assets.replace(/,/g, ' ');
        const e = work.split(' ');
        e.forEach(element => {
          if (+element === +element && +element > 0) {
            this.url += '&id=' + element;
            parts.push('id=' + element);
          }
        });
      }
      if (this.dateFrom) {
        try {
          this.url += '&date_from=' + this.dateFrom.toISOString().split('T')[0];
          parts.push('date_from=' + this.dateFrom.toISOString().split('T')[0]);
        } catch (e) { }
      }
      if (this.dateTo) {
        try {
          this.url += '&date_to=' + this.dateTo.toISOString().split('T')[0];
          parts.push('date_to=' + this.dateTo.toISOString().split('T')[0]);
        } catch (e) { }
      }

      if (this.filters) {
        this.filters.forEach(setting => this.url += '&field=' + setting);
        this.filters.forEach(setting => parts.push('field=' + setting));
      }

      const partsString = parts.join('&');
      if (partsString) {
        this.path += '?' + partsString;
      }
    } catch (e) {

    }

  }
}

<article>
  <header>
    <app-toolbar>
      <div class="right">
        @if (mode() === 'view') {
          <app-button label="Edit" icon="mdi mdi-pen" (click)="switchMode()"></app-button>
        }
        @if (collection().id && mode() === 'edit') {
          <app-button [label]="canDelete === 0 ? 'Delete' : 'To Delete click again '" icon="mdi mdi-eraser" (click)="delete()"></app-button>
        }
        <app-button label="Save Changes" icon="mdi mdi-check" (click)="save()"></app-button>
      </div>
    </app-toolbar>
  </header>

  <main class="content-container">
    @if (mode() === 'edit') {
      <section class="flex flex-center">
        <div class="flex-1">
          <h2>Title</h2>
          <input type="text" class="fourd" [(ngModel)]="collection().title" />
        </div>

        <div>
          <h2>Type</h2>
          <app-dropdown
            placeholder="Select type"
            [value]="collection().type.id"
            [list]="types"
            (onSelected)="onTypeSelected($event)"
            [dimensions]="{ height: 200 }" />
        </div>
      </section>
    }
    <h2>Sensors {{ unfilteredList?.length || 'none' }}</h2>

    @if (collection().type?.id) {
      <table class="table table-hover">
        <thead>
          <tr>
            <th></th>
            <th>Sensor</th>
            <th>Updated At</th>
            <th>Value</th>
            <th colspan="2">Gateway</th>
          </tr>
        </thead>
        <tbody>
          @if (mode() === 'view') {
            @for (item of collection().assets; track item.id) {
              <tr>
                <td></td>
                <td><app-asset-icon [assetTypeId]="item.typeId" /> {{ item.title }}</td>

                <td>{{ item.updatedAt | date: 'dd/MM/YY' }}</td>
                <td>{{ item.value }}</td>

                <td>{{ item.gateway.id }}</td>
                <td>{{ item.gateway.title }}</td>
              </tr>
            }
          }
          @if (mode() === 'edit' && list()) {
            @for (item of list(); track item.id) {
              <tr (click)="itemChecked(item)" [class.checked]="item.checked">
                <td><span class="mdi" [class.mdi-checkbox-marked-outline]="item.checked" [class.mdi-checkbox-blank-outline]="!item.checked"></span></td>
                <td><app-asset-icon [assetTypeId]="item.assetType_id" /> {{ item.assetType_id }} {{ item.title }}</td>

                <td>{{ item.updatedAt | date: 'dd/MM/YY' }}</td>
                <td>{{ item.value }}</td>

                <td>{{ item.gateway.id }}</td>
                <td>{{ item.gateway.title }}</td>
              </tr>
            }
            <!--
				<tr>
					<td><app-select [list]="list()" placeholder="Select asset..." (onSelected)="onAssetSelected($event)" /></td>
				</tr>
			-->
          }
        </tbody>
      </table>
    } @else {
      Sensors will display here when a colleciton type is selected.
    }
  </main>
</article>

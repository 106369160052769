<article>
	<div>
		<section>
			<app-d3-pie [fontSize]="20" [data]="data" [innerRing]="28" (onSliceClick)="onSliceClick($event)" [legend]="legend"
				[width]="100" [height]="100" [margin]="{top:0, left:0, right:0, bottom:0}" [value]="value"></app-d3-pie>
			<div>
				<ul>
					<li><label>Licensed</label> {{data.licensed}} ({{perc.licensed}})</li>
					<li><label>Expired</label> {{data.expired}} </li>
				</ul>
			</div>
		</section>

	</div>
</article>

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-section-icon',
  templateUrl: './section-icon.component.html',
  styleUrls: ['./section-icon.component.css']
})
export class SectionIconComponent implements OnInit {

  @Input()
  icon: string;
  
  constructor() { }

  ngOnInit() {
  }

}

<article class="content-container">

	@if(isMobile()) {
	<app-toolbar class="sticky">
		<div class="right">
			<app-button (click)="wantsToChangePassword()" icon="mdi mdi-pencil" label="Change password"></app-button>
			<app-button (click)="logout()" label="Logout" icon="mdi mdi-logout"></app-button>
		</div>
	</app-toolbar>
	} @else {
	<app-toolbar>
		<div class="right">
			<app-button (click)="setTheme('dark')" [selected]="currentTheme()==='dark'"><i class="mdi mdi-moon-waning-crescent mr-1"></i> Dark Mode (orange)</app-button>
			<app-button (click)="setTheme('dark-fourd')" [selected]="currentTheme()==='dark-fourd'"><i class="mdi mdi-moon-waning-crescent mr-1"></i> Dark Mode (blue)</app-button>
			<app-button (click)="setTheme('light')" [selected]="currentTheme()==='light'"><i class="mdi mdi-weather-sunny mr-1"></i> Light Mode</app-button>

			<app-button (click)="wantsToChangePassword()" icon="mdi mdi-pencil" label="Change password"></app-button>
			<app-button (click)="logout()" label="Logout" icon="mdi mdi-logout"></app-button>
		</div>
	</app-toolbar>
	}

	<main>
		<div class="details mt-1 mb-1">
			<div>
				<h5>Name</h5>
				<p>{{user.name}}</p>
			</div>
			<div>
				<h5>Email address</h5>
				<p>{{user.email}}</p>
			</div>
		</div>

		<div class="sites-container">
			<div class="sites mt-1">
				<div>
					<div class="site-card" (click)="link('https://4dmonitoring.co.uk/news/')">
						<h4 class="text-center">Projects</h4>
						<img src="https://4dmonitoring.co.uk/wp-content/uploads/2020/06/feature-disruptive-050820-490x300.jpg">

						<div class="mt-1">
							<app-button label="4D projects"></app-button>
						</div>
					</div>
				</div>

				<div>
					<div class="site-card" (click)="link('https://4dmonitoring.co.uk/projects/')">
						<h4 class="text-center">News</h4>
						<img src="https://4dmonitoring.co.uk/wp-content/uploads/2020/02/radon-feature-490x300.jpg" alt="projects">

						<div class="mt-1">
							<app-button label="Read 4D News"></app-button>
						</div>

					</div>
				</div>
			</div>
		</div>

		<div class="flex-1">
			<div class="feedback">
				<h5>Feedback notes</h5>

				<textarea [(ngModel)]="feedbackText"></textarea>
				<div>
					<app-button icon="mdi mdi-check" label="Submit feedback to 4D" (click)="clickFeedback()" />
				</div>
			</div>
		</div>

	</main>

	@if(isChangingPassword) {
	<app-dialog header="Change password" [hasToolbar]="true" [dimensions]="{width: 400, height: 220}" (onClose)="isChangingPassword=null">
		<div class="toolbar">
			<app-toolbar>
				<div class="right">
					<app-button label="Cancel" icon="mdi mdi-close" (click)="isChangingPassword = false" />
					<app-button label="Update" [isCTA]="true" icon="mdi mdi-check" (click)="changePassword()" />
				</div>
			</app-toolbar>
		</div>
		<div class="mt-1">
			<app-data-form [dataForm]="passwordDataFrom" />
		</div>
	</app-dialog>
	}

</article>

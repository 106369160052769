@defer(when tenant) {
<section>

	<div class="form-group">
		<label for="title">Tenant name </label>
		<input id="title" name="title" maxlength="50" class="form-control" [(ngModel)]="tenant.title">
	</div>

	<!--		<div class="form-group">
				<label for="services">Services</label>
				<table class="table table-sm">
					<tr *ngFor="let service of tenant.serviceCollection">
						<td>
							{{service.serviceForTenant.title}}
						</td>
					</tr>
				</table>
				<p-dropdown placeholder="Select a service..." [options]="servicesForTenants" [(ngModel)]="newServiceForTenantsId" optionLabel="title" optionValue="id" (onChange)="newServiceForTenantsChanged($event)">
				</p-dropdown>
				<p-button *ngIf="newServiceForTenantsId" label="Add service" icon="mdi mdi-content-save" styleClass="" (click)="addService()"></p-button>
			</div> -->

	<div class="form-group">
		<div for="notes">Notes</div>
		<textarea [(ngModel)]="tenant.notes" [style]="{ 'width':'100%','height': isMobile ? '200px' : '100px'}"></textarea>
	</div>

	@if(tenant.assets.length) {
	<div class="assets">
		<div>Assets</div>
		@for(asset of tenant.assets;track asset.id) {
		<app-chip [label]="asset.title"></app-chip>&nbsp;
		}
	</div>
	} @else {
	@if (tenant.assets.length === 0) {
	<app-info-panel>Not attached to any assets</app-info-panel>
	}
	}

	<div class="text-right">
		<app-button label="Cancel" icon="mdi mdi-close" (click)="cancel()" />
		<app-button [label]="tenant.id ? 'Update' : 'Create'" icon="mdi mdi-check" [isCTA]="true" (click)="submit()" />
	</div>

</section>
}

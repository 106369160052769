/**
 * Container receives inputs from components, generates results to be consumed by presentation.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService, EngagementStore } from 'app/shared/store.service';
import { FilterMenus } from 'app/classes/filter-menus';
import { APIService } from 'app/shared/api.service';
import { Engagement } from 'app/classes/engagement';
import { Subscription } from 'rxjs';
import { EngageService } from '../engage.service';
import { Org } from 'app/classes/org';

@Component({
  selector: 'app-engage-container',
  templateUrl: './engage-container.component.html',
  styleUrls: ['./engage-container.component.css']
})
export class EngageContainerComponent implements OnInit, OnDestroy {

  engagement: Engagement;
  subscription: Subscription;
  selectedOrg: number;
  userLog: any[];
  users: any[];

  results: any;
  unfilteredResults: any;
  orgs: any[];
  org: Org;

  isInitialising: number;

  totalSites: number;


  constructor(private storeService: StoreService, private apiService: APIService, private engageService: EngageService) {

    const fm = new FilterMenus();
    fm.display = 'engagement';
    this.storeService.setFiltering(fm);

    this.subscription = this.storeService.engagement
      .subscribe(engagement => {
        if (!engagement) {
          return;
        }
        console.log(engagement);
        // Convert to new class for compatibility
        this.engagement = new Engagement(engagement);

        this.engageService.get(this.engagement)
          .then(results => {
            this.unfilteredResults = results;
            this.filterResults(!!(this.isInitialising++));
            this.engageService.setProcessed(results);
          });
      });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.storeService.setFiltering(null);
  }

  filterResults(isInitialising: boolean) {
    const results = this.unfilteredResults;

    if (!results || results[1].message) {
      // Not setup for engagement or an error
      this.results = [];
      return;
    }

    this.results = results[0];
    if (this.results === null) {
      // This is a participating org
      this.selectedOrg = this.apiService.getUserOrg().id;
    }
    const usersFound = {};
    this.userLog = results[1].map(logRow => {
      let title = '';
      switch (logRow.path) {
        case '/live':
          title = 'Live listing';
          break;
        case '/reports':
          title = 'Reports listing';
          break;
        case '/profiling':
          title = 'floorplan listing';
          break;
        case '/dashboard':
          title = 'Dashboard tiles';
          break;
        case '/dashboard/alarms-resolved-today':
          title = 'Alarms resolved today';
          break;
        case '/dashboard/gateways-online':
          title = 'Gateways online list';
          break;
        case '/dashboard/assets-inerror':
          title = 'Assets in error list';
          break;
        case '/dashboard/gateways-telemetry-today':
          title = 'Telemetry today list';
          break;
        case '/dashboard/gateways-disconnected':
          title = 'Gateways disconnected list';
          break;
        case '/realtime':
          title = 'Realtime';
          break;
        case '/setpoints/overview':
          title = 'Setpoint overview';
          break;
        default:
          if (logRow.path.indexOf('/generate') > 0) {
            title = `Report generated ${logRow.path}`;
          } else if (logRow.path.indexOf('/profiling') >= 0) {
            title = `Floorplan`;
          } else if (logRow.path.indexOf('/live/awair') >= 0) {
            title = `Live Awair`;
          } else if (logRow.path.indexOf('/live/dt_') >= 0) {
            title = `Live Tiles`;
          }
          break;
      }
      logRow.title = title;
      usersFound[logRow.userName] = true;
      return logRow;
    });

    if (isInitialising) {
      Object.keys(usersFound).map(userName => {
        this.users.push({ label: userName, value: userName });
      });

    }

    this.userLog.sort((a, b) => {
      return a.createdAt > b.createdAt ? 1 : -1;
    });

    if (results.length && this.results) {
      this.calcResults(95);
    }

  }

  calcResults(orange: number) {
    orange = 100 - orange;

    const perc = 100 / this.results[0].engagementCount;
    this.totalSites = 0;
    this.results.map(org => {
      this.totalSites += org.siteCount;

      if (org.engagementCount * perc >= 4) {
        org.engagementStatus = 'green';
      } else if (org.engagementCount * perc >= orange) {
        org.engagementStatus = 'orange';
      } else {
        org.engagementStatus = 'red';
      }

    });
  }

}

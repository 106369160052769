import { Component, OnInit } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Gateway } from '../../classes/gateway';

@Component({
  selector: 'app-dashboard-site-list',
  templateUrl: './dashboard-site-list.component.html',
  styleUrls: ['./dashboard-site-list.component.css']
})
export class DashboardSiteListComponent implements OnInit {
  siteId: number;
  results: any;
  gateways: Gateway[];
  viewGateways = true;

  constructor(private router: Router, private route: ActivatedRoute, private apiService: APIService) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.siteId = params['site'];
      this.apiService.getGatewaysForSiteId(this.siteId).then((gateways) => this.gateways = gateways);
    });

    console.log('DashboardSiteListComponent()');
  }

  gotoGateway(gateway: Gateway) {
    this.viewGateways = false;
    this.router.navigate(['org', { outlets: { 'dashboard-asset-list': [gateway.id], 'dashboard-site-list': null } }]);
  }

  focusGateway(gateway) {
    console.log('Future use');
  }
}

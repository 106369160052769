<div class="assets">

  <h1>Sensors</h1>

  <div class="asset">
    <div>
      <div class="icon">
        <i class="fa fa-fw fa-thermometer-half "></i>
      </div>
    </div>
    <div>HWS Return</div>
  </div>
  <div class="asset">
    <div>
      <div class="icon">
        <i class="fa fa-fw fa-thermometer-half "></i>
      </div>
    </div>
    <div>CWS to Water Heater</div>
  </div>
  <div class="asset">
    <div>
      <div class="icon">
        <i class="fa fa-fw fa-thermometer-half "></i>
      </div>
    </div>
    <div>HWS Return</div>
  </div>
  <div class="asset">
      <div>
        <div class="icon">
          <i class="fa fa-fw fa-lightbulb-o "></i>
        </div>
      </div>
      <div>Plant Room Lights</div>
    </div>
</div>
<div class="" *ngIf="filteredAlarms">
    <div class=" history-item" *ngIf="filteredAlarms?.length > 0">
        <div>
            <table class="table table-sm table-hover">
                <thead>
                    <tr>
                        <th>Created</th>
                        <th>Resolved</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-template ngFor let-alarm [ngForOf]="filteredAlarms">
                        <tr (click)="alarmClick(alarm)" class="can-click">
                            <td>{{alarm.createdAt | amDateFormat:'DD/MM/YY HH:mm'}}</td>
                            <td>{{alarm.findings?.at | amDateFormat:'DD/MM/YY HH:mm'}}</td>
                            <td>{{alarm.findings?.text}}</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>
        <div class="mt-1">
            <app-rulesv3-item-alarm-calendar *ngIf="history" [items]="filteredAlarms"></app-rulesv3-item-alarm-calendar>
        </div>
    </div>
    <div class="" *ngIf="filteredAlarms?.length === 0">
        <p class="mt-1">No alarms.</p>
    </div>
</div>

<div class="_modal" *ngIf="alarm">
    <main>
        <nav>
            <span (click)="alarm=null" class="can-click"><i class="mdi mdi-chevron-left"></i> Alarm</span>
        </nav>
        <app-rulesv3-item-alarm [assets]="assets" [alarm]="alarm"></app-rulesv3-item-alarm>
    </main>
</div>
import { Component, OnInit, computed, effect, input, output, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Gateway } from 'app/classes/gateway';
import { APIService } from 'app/shared/api.service';
import { BuildingsService } from 'app/shared/buildings.service';
import { GatewayService } from 'app/shared/gateway.service';

@Component({
  selector: 'app-iac-gateway',
  templateUrl: './iac-gateway.component.html',
  styleUrl: './iac-gateway.component.css'
})
export class IacGatewayComponent implements OnInit {

  tabIndex = signal<number>(0);
  gateway = input.required<Gateway>();
  assets = signal<Asset[]>(null);

  filtered = signal<Asset[]>(null);

  onUseAssets = output<Asset[]>();

  constructor(private buildingService: BuildingsService, private gatewayService: GatewayService, private apiService: APIService) {
    effect(() => {
      if (!this.gateway()) {
        return;
      }

      this.apiService.getAssetsForGatewayId(this.gateway().id).then(assets => {
        this.assets.set(assets);
        this.filtered.set(assets.filter(asset => asset.assetType_id === 2 || asset.assetType_id === 8));
      });
    });
  }


  ngOnInit(): void {

  }

  clickUseTheseSensors() {
    this.onUseAssets.emit(this.filtered());
  }

}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { SitePlan } from '../../classes/site-plan';
import { SiteFloorplanAsset } from '../../classes/site-floorplan-asset';
import { AssetService } from '../../shared/asset.service';
import { CalendarService } from '../../shared/calendar.service';
import { Asset } from 'app/classes/asset';

import moment from 'moment';

import { FloorplanService, IDataSubject, SitePlanComplete } from 'app/shared/floorplan.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-site-table',
  templateUrl: './site-table.component.html',
  styleUrls: ['./site-table.component.css']
})
export class SiteTableComponent implements OnInit, OnDestroy {
  isNaN: Function = Number.isNaN;
  @Input()
  siteFloorplan: SitePlan;

  _range: RangeInterface;

  subscription: Subscription;

  @Input()
  public set range(v: RangeInterface) {
    if (this._range) {
      // Updating the range
      this._range = v;
      this.getHistory();
    } else {
      this._range = v;
    }
  }

  public get range(): RangeInterface {
    return this._range;
  }

  @Input()
  assets: SiteFloorplanAsset[];

  @Input()
  public set sitePlanComplete(v: SitePlanComplete) {
    this.siteFloorplan = v.sitePlan;
    this.assets = v.assets;

    this.getHistory();
  }

  startsAt: moment.Moment = moment().add(-6, 'days');
  endsAt: moment.Moment = moment();

  chartData: any;
  legends: any[] = [];
  sensorSelected: Asset;
  isLoading: boolean;

  constructor(public apiService: APIService, public assetService: AssetService, private calendarService: CalendarService, private floorplanService: FloorplanService) {

    let dow = moment().day();

    for (let idx = 1; idx <= 7; idx++) {
      const legend = {
        title: this.calendarService.DOW_NAMES[dow],
        dow: dow
      };
      dow--;
      if (dow < 0) {
        dow = 6;
      }
      this.legends.push(legend);
    }

    this.subscription = floorplanService.dataSubject.subscribe(data => {
      if (!data) {
        return;
      }
      switch (data.key) {
        case 'range':
          this.range = data.value;
          break;
      }
    });
  }

  ngOnInit() {
  }

  getHistory() {
    this.isLoading = true;

    if (!this.range) {
      return;
    }
    this.apiService
      .getSitePlanAssetHistory(this.siteFloorplan, this.range.startTime, this.range.endTime)
      .then(results => {
        console.log('got history');

        results.values.forEach((historyItem) => {
          this.assets.forEach(profileAsset => {
            if (profileAsset.id === historyItem.asset_id) {
              const rag = results.rags.filter(ragItem => ragItem.id === profileAsset.id && ragItem.daysAgo === historyItem.daysAgo);
              if (rag.length === 1) {
                const amber = rag[0].minOrangeAlert || rag[0].maxOrangeAlert;
                const red = rag[0].minAlert || rag[0].maxAlert;
                historyItem.rag = { amber, red, rag };
              } else {
                historyItem.rag = {};
              }
              profileAsset.history[historyItem.daysAgo] = historyItem;
            }
          });
        });

        this.isLoading = false;
      });
  }

  selectSensor(asset: SiteFloorplanAsset) {
    console.log('selected', asset);
    this.sensorSelected = asset;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

export interface RangeInterface {
  startTime: string;
  endTime: string;
  type: RangeTypes;
}


export type RangeTypes = "avg" | "min" | "max";

import { Component } from '@angular/core';
import { SetpointTemplateCategory } from 'app/classes/setpoint-template-category';
import { SetpointsService } from 'app/shared/setpoints.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-setpoint-template-category',
  templateUrl: './dialog-setpoint-template-category.component.html',
  styleUrls: ['./dialog-setpoint-template-category.component.css']
})
export class DialogSetpointTemplateCategoryComponent {

  setpointTemplateCategory: SetpointTemplateCategory;


  constructor(public dialogService: DialogService, public config: DynamicDialogConfig, public ref: DynamicDialogRef, public messageService: MessageService, public setpointsService: SetpointsService) {
    if (this.config.data.setpointTemplateCategory) {
      this.setpointTemplateCategory = this.config.data.setpointTemplateCategory;
    }
  }

  cancel() {
    this.ref.close();
  }

  async submit() {

    const response = await this.setpointsService.updateTemplateCategory(this.setpointTemplateCategory);

    console.log(response);

    this.ref.close(this.setpointTemplateCategory);
  }

}

<div class="asset-chip">
	<span (mouseenter)="assetop.show($event)">{{asset.title}}
		<span *ngIf="showLocation || showSite">(</span>
		<span *ngIf="showSite"> {{asset.siteTitle}}
			<span *ngIf="showLocation">,
			</span>
		</span>
		<span *ngIf="showLocation">{{asset.location}}</span>
		<span *ngIf="showLocation || showSite">)</span>
	</span>

	<div class="overlay-container">
		<p-overlayPanel #assetop (mouseleave)="assetop.hide()">
			<div class="row">

				<div class="col-md-7 map">
					<!-- AIzaSyAB0LPDOu0ffDGhQyr9mzEDclU6Z39S7xM -->
					<img *ngIf="gateway"
						src="https://maps.googleapis.com/maps/api/staticmap?zoom=6&center={{gateway.lat}},{{gateway.lng}}&size=310x310&key=AIzaSyAB0LPDOu0ffDGhQyr9mzEDclU6Z39S7xM&markers=color:blue|{{gateway.lat}},{{gateway.lng}}"
						class="img-responsive" />
				</div>

				<div class="col-md-5 gateway"><i *ngIf="!gateway" class="fa fa-refresh fa-spin"></i>
					<div class="row" *ngIf="gateway">
						<div class="col-md-4 title">
							Asset id
						</div>
						<div class="col-md-8">
							{{asset.id}}
						</div>
					</div>
					<div class="row" *ngIf="gateway">
						<div class="col-md-4 title">
							Gateway#
						</div>
						<div class="col-md-8">
							{{asset.gateway_id}}
						</div>
						<div class="col-md-4 title">
							Title</div>
						<div class="col-md-8">
							{{gateway.title}}
						</div>
						<div class="col-md-4 title">
							Updated
						</div>
						<div class="col-md-8">
							<app-date [date]='asset.updatedAt' [showTime]=true></app-date>&nbsp;
						</div>
						<div *ngIf="gateway">
							<div class="col-md-4 title">
								Status
							</div>
							<div title="Status id:{{gateway.statusId}}" class="col-md-8">
								{{gateway.statusTitle}}
							</div>
							<div class="col-md-4 title">
								Weather
							</div>
							<div title="{{gateway.localWeatherDate}}" class="col-md-8">
								{{gateway.localWeatherTemp}}c
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-5 site"><i class="fa fa-refresh fa-spin" *ngIf="!site"></i>
					<div class="row" *ngIf="site">

						<div class="col-md-12" title="Site id:{{site.id}}">
							<div><i class="fa fa-map-marker fa-fw"></i> {{site.title}}</div>
							<div title="Address"><i class="fa fa-fw"></i> {{site.address1}}</div>
							<div><i class="fa fa-fw"></i> {{site.address2}}</div>
							<div><i class="fa fa-fw"></i> {{site.address3}}</div>
							<div><i class="fa fa-fw"></i> {{site.addressTown}}</div>
							<div><i class="fa fa-fw"></i> {{site.addressCounty}}</div>
							<div><i class="fa fa-fw"></i> {{site.addressPostcode}}</div>
						</div>
						<div class="col-md-12">
							<i class="fa fa-phone-square fa-fw"></i> {{site.addressTel}}
						</div>
						<div class="col-md-12">
							<i class="fa fa-envelope fa-fw"></i> {{site.addressEmail}}
						</div>
					</div>
				</div>

				<div class="col-md-12 sampling">
					<div class="row chart-row" *ngFor="let item of charts;let i = index">

						<div class="col-md-12" *ngIf="asset.assetType !== apiService.ASSET_TYPES_BY_TITLE.WIFI">
							<p-chart *ngIf="data.datasets[i].data.length" name="chart" #chart type="bar" [data]="data"
								[options]="options"></p-chart>
						</div>
					</div>

				</div>
			</div>
		</p-overlayPanel>
	</div>
</div>

/**
 * @version 0.0.0
 */
import { Telemetry } from "./telemetry";
import { IConfiguration, IConfigurationRange, IConfigurationType, IInsightAPITypeItem } from "../classes/insight";
import { CollectionAsset } from "../classes/collection-for-assets";
import moment from 'moment';
import { IInsightFFFloorplanAsset } from "app/shared/insights.service";

export class InsightFF {
	configuration: IConfigurationFF;
	telemetry: Telemetry[];
	telemetryCollection: IInsightAPITypeItemFF[];
	collections: CollectionAsset[];
	floorplans: InsightFloorplans;
	generatedAt: Date;

	constructor() { }

	setTelemetry(telemetryCollection: any[], floorplanAssets: IInsightFFFloorplanAsset[]) {
		const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		const daysShort = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
		this.floorplans = new InsightFloorplans(floorplanAssets);

		this.floorplans.assets.forEach(fa => {
			const asset = this.configuration.assets.find(a => a.id === fa.asset_id);
			if (asset) {
				fa.collection_id = asset.collectionId;
			}
		});
		// Clean up telemetry, make value a number and timestamp a date
		// Footfall only has once collection of data currently (counts per hour)
		// Remove outside of hours
		const hours = this.configuration.ranges.map(r => {
			return { from: +new Date(r.from), to: +new Date(r.to), doy: moment(r.from).dayOfYear() };
		});

		// Initialise the day summary
		this.collections.forEach(c => c.days = []);
		const collectionKeys = Object.keys(telemetryCollection);
		const start = moment(this.configuration.start);
		const daysInMonth = start.daysInMonth();
		for (let index0 = 0; index0 < collectionKeys.length; index0++) {
			// Only telemetry for businsess hours
			const key = collectionKeys[index0];
			telemetryCollection[key].title = this.collections.find(c => c.id === +key).title;
			telemetryCollection[key].telemetryByDay = Array(31);
			telemetryCollection[key].telemetry = telemetryCollection[key].telemetry
				.filter(t => {
					const d = +new Date(t.d);
					return hours.findIndex(h => (d >= h.from && d <= h.to)) !== -1;
				})
				.map(t => {
					const d = new Date(t.d);
					const index = d.getDate() - 1;
					const indexValue = telemetryCollection[key].telemetryByDay[index];
					if (indexValue) {
						telemetryCollection[key].telemetryByDay[index].v += +t.v;
					} else {
						const dayDate = new Date(t.d);
						dayDate.setMinutes(0);
						dayDate.setHours(0);
						dayDate.setSeconds(0);
						telemetryCollection[key].telemetryByDay[index] = { v: +t.v, d: dayDate, i: d.getDate() };
					}

					return { i: t.i, v: +t.v, d };
				});

			// Clean up empty days
			for (let index1 = 0; index1 < daysInMonth; index1++) {
				const indexDay = moment(start).add(index1, 'day');
				if (!telemetryCollection[key].telemetryByDay[index1]) {
					telemetryCollection[key].telemetryByDay[index1] = {
						d: indexDay.toDate(),
						v: hours.findIndex(h => (indexDay.dayOfYear() === h.doy)) !== -1 ? 0 : null,
						i: (index1 + 1)
					};
				}
			}
			// Remove days over days in month
			telemetryCollection[key].telemetryByDay = telemetryCollection[key].telemetryByDay.filter(day => !!day);

		}
		this.telemetryCollection = telemetryCollection;
	}

	setConfiguration(configuration: IConfigurationFF) {
		this.configuration = configuration;
	}
}

export class InsightFloorplans {
	plans: InsightFloorplan[];
	assets: IInsightFFFloorplanAsset[];
	constructor(floorplanAssets: IInsightFFFloorplanAsset[]) {
		this.assets = floorplanAssets;
		const plans = floorplanAssets.reduce((r, a) => {
			r[a.planKey] = r[a.planKey] || [];
			r[a.planKey].push(a);
			return r;
		}, Object.create(null));
		console.log(plans);
		this.plans = Object.keys(plans).map(key => new InsightFloorplan(key, plans[key]));
	}
}

export class InsightFloorplan {
	plan: {
		key: string,
		vw: number,
		vh: number,
		viewbox: string;
		cr: number;
	};

	assets: IInsightFFFloorplanAsset[];
	constructor(planKey: string, assets: IInsightFFFloorplanAsset[]) {

		const { viewboxWidth, viewboxHeight } = assets[0];

		const cr = Math.round(viewboxWidth / 50);
		this.plan = {
			key: planKey,
			vw: viewboxWidth,
			vh: viewboxHeight,
			viewbox: `0 0 ${viewboxWidth} ${viewboxHeight}`,
			cr
		};
		this.assets = assets;
	}
}

export interface IConfigurationFF {
	// Assets used in telemetry
	assets: { id: number, collectionId: number, assetType_id: number, metobsid?: number }[];
	// The start date for the month
	start: any; // moment
	end: any; // moment
	// The date ranges used to pull the telemetry
	ranges: IConfigurationRange[];
	title: string;
	totals: { [key: number]: { perc: number, count: number, total: number } };
}

export interface IInsightAPITypeItemFF {
	floorplanIndex: number;
	telemetry: Telemetry[],
	telemetryByDay: { d: Date, i: number, v: number }[];
};

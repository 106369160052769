import { Component, Input } from '@angular/core';
import { VisualiseItem } from 'app/classes/visualise-item';
import { VisualiseService } from 'app/shared/visualise.service';

@Component({
  selector: 'app-visualise-data',
  templateUrl: './visualise-data.component.html',
  styleUrls: ['./visualise-data.component.css']
})
export class VisualiseDataComponent {

  _visualisationItem: VisualiseItem;

  @Input()
  public set visualisationItem(v: VisualiseItem) {
    this._visualisationItem = v;
    if (v) {
      this.processData();
    }
  }
  public get visualisationItem(): VisualiseItem {
    return this._visualisationItem;
  }

  isDebugging: boolean;

  constructor(private visualiseService: VisualiseService) {

  }

  processData() {

  }

}

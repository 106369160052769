import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from '../../../shared/api.service';
import { RulePackage } from '../../../classes/rule-service/rule-package';
import { StoreService } from "../../../shared/store.service";

@Component({
  selector: 'app-rule-engine-v2-history',
  templateUrl: './rule-engine-v2-history.component.html',
  styleUrls: ['./rule-engine-v2-history.component.css']
})
export class RuleEngineV2HistoryComponent implements OnInit {

  @Input()
  rule: RulePackage;

  history: any[];

  constructor(private storeService: StoreService, private apiService: APIService, private router: Router, private route: ActivatedRoute) { }

  async ngOnInit() {
    this.history = await this.apiService.getRulePackageAlarms(this.rule.id);

    this.history[0].forEach(alarm => {
      alarm.history = this.history[1].filter(item => item.alarm_id === alarm.id);

      const newState = alarm.history.find(item => item.value === "new");
      const resolvedState = alarm.history.find(item => item.value === "resolved");

      if (newState === undefined) {
        // Delay not met
        alarm.findings = 'Resolved within delay';
      } else if (newState && resolvedState) {
        const diff = (+new Date(resolvedState.createdAt)) - (+new Date(newState.createdAt));
        const diffDays = Math.floor(diff / 86400000); // days
        const diffHrs = Math.floor((diff % 86400000) / 3600000); // hours
        const diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes
        alarm.findings = 'Resolved in ';
        if (diffDays > 0) {
          alarm.findings += ' ' + diffDays + ' days';
        }
        if (diffHrs > 0) {
          alarm.findings += ' ' + diffHrs + ' hours';
        }
        if (diffMins > 0) {
          alarm.findings += ' ' + diffMins + ' minutes';
        }
      }
    });
  }

}

<article class="desktop">
    <div pTooltip="No setpoints defined for these assets" tooltipPosition="top">
        <div><i class="mdi mdi-square-rounded rag-none"></i></div>
        <div (click)="clickRag('none')" [class.can-click]="ragCounts()?.none">No RAG
            @if(ragCounts()?.none) {
            <app-chip size="small" [label]="ragCounts().none" [canClick]="false" />
            }

        </div>
    </div>
    <div pTooltip="Asset is within the defined setpoints" tooltipPosition="top">
        <div><i class="mdi mdi-square-rounded rag-green"></i></div>
        <div (click)="clickRag('green')" [class.can-click]="ragCounts()?.green">RAG Green
            @if(ragCounts()?.green) {
            <app-chip size="small" [label]="ragCounts().green" [canClick]="false" />
            }


        </div>
    </div>
    <div pTooltip="Asset is within the setpoints Amber warning" tooltipPosition="top">
        <div><i class="mdi mdi-square-rounded rag-amber"></i></div>
        <div (click)="clickRag('amber')" [class.can-click]="ragCounts()?.amber">RAG Amber

            @if(ragCounts()?.amber) {
            <app-chip size="small" [label]="ragCounts().amber" [canClick]="false" />
            }

        </div>
    </div>
    <div pTooltip="Asset is over the setpoints RED warning" tooltipPosition="top">
        <div><i class="mdi mdi-square-rounded rag-red"></i></div>
        <div (click)="clickRag('red')" [class.can-click]="ragCounts()?.red">RAG Red
            @if(ragCounts()?.red) {
            <app-chip size="small" [label]="ragCounts().red" [canClick]="false" />
            }


        </div>
    </div>
</article>

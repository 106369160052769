import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { APIService } from './api.service';
import { Asset } from '../classes/asset';
import { SelectedAsset } from '../classes/rule-service/selected-asset';
import { RuleCondition, RuleConditionOperator } from '../classes/rule-service/rule-condition';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class RuleService {

    readonly API_URL = 'https://v4wr0xxq8g.execute-api.eu-west-2.amazonaws.com/4d';

    private _selectedAsset: BehaviorSubject<SelectedAsset> = new BehaviorSubject(null);
    private _newRuleConditionRow: BehaviorSubject<RuleCondition> = new BehaviorSubject(null);
    private _choosingAsset: BehaviorSubject<any> = new BehaviorSubject(null);
    private _testState: BehaviorSubject<number> = new BehaviorSubject(null);

    public selectedAsset: Observable<SelectedAsset> = this._selectedAsset.asObservable();
    public newRuleConditionRow: Observable<RuleCondition> = this._newRuleConditionRow.asObservable();
    public choosingAsset: Observable<any> = this._choosingAsset.asObservable();
    public testState: Observable<number> = this._testState.asObservable();

    constructor(private http: HttpClient, private apiService: APIService) { }

    announceSelectedAsset(parentId: number, componentId: string, asset: Asset, value: string) {
        console.log('announceSelectedAsset ', JSON.stringify(asset, null, 2));;
        this._selectedAsset.next(new SelectedAsset(parentId, componentId, asset, value));
    }

    announceChoosingAsset(parentId: number, componentId: string) {
        this._choosingAsset.next({ parentId: parentId, componentId: componentId });
    }

    announceCompletedRuleConditionRow(ruleCondition: RuleCondition) {
        this._newRuleConditionRow.next(ruleCondition);
    }

    operatorAsWords(operator: RuleConditionOperator) {
        switch (operator) {
            case '<':
                return 'is less than';
            case '<=':
                return 'is less than or equal';
            case '=':
                return 'is equal to';
            case '>':
                return 'is greater than';
            case '>=':
                return 'is greater than or equal';
            case '<>':
                return 'is not';
            case 'diff':
                return 'difference';
            case '<diff':
                return 'is less than difference';
            case 'diff>':
                return 'is greater than difference';
        }
    }

    async getAlarmHistory(page: number): Promise<IAlarmHistoryItem[]> {
        const response: any = await new Promise(resolve => {
            this.http
                .get<any>(this.API_URL + `/alarms?page=${page}`, this.apiService.getUAOHeaders())
                .subscribe(async response => resolve(response.map(r => {
                    return {
                        id: r.aId,
                        title: r.title,
                        severity: r.severity,
                        createdAt: new Date(r.aCreatedAt),
                        updatedAt: new Date(r.aUpdatedAt),
                        state: r.aState,
                        ruleId: r.id,
                        age: r.age,
                        hoursInAlarm: r.aState === 'new' ? r.new_in_hours : r.resolved_in_hours
                    }
                })));
        });

        return response;
    }
}

export interface IAlarmHistoryItem {
    id: number;
    title: string;
    severity: string;
    updatedAt: Date;
    createdAt: Date;
    state: string;
    ruleId: number;
    age: number;
    hoursInAlarm: number;
}


import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { Building } from 'app/classes/building';
import { Gateway } from 'app/classes/gateway';
import { OpeningHour } from 'app/classes/opening-hours';
import { Org } from 'app/classes/org';

import { APIService } from 'app/shared/api.service';
import { BuildingsService } from 'app/shared/buildings.service';
import { IGetReviewsForBuildingItemReview, InsightsReviewService } from 'app/shared/insights.review.service';
import { StoreService } from 'app/shared/store.service';
import { MessageService } from 'primeng/api';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-building',
  templateUrl: './select-building.component.html',
  styleUrls: ['./select-building.component.css']
})
export class SelectBuildingComponent implements OnInit, OnDestroy {

  @Input()
  hasCollection: 'footfall' | 'reporting';

  // Select box changed for building
  @Output()
  onBuildingChanged: EventEmitter<Building> = new EventEmitter();

  // Buidling ready to use (has hours)
  @Output()
  onBuildingSelected: EventEmitter<Building> = new EventEmitter();

  @Output()
  onReviewSelected: EventEmitter<IGetReviewsForBuildingItemReview> = new EventEmitter();

  _buildingSelected: Building;

  public set buildingSelected(v: Building) {
    if (v === null) { return }
    this._buildingSelected = v;
  }

  public get buildingSelected(): Building {
    return this._buildingSelected;
  }

  buildings: Building[];
  buildingDropdown: any[];
  buildingSelectId = null;
  collectionSelected: any = null;
  buildingForReport: Building;
  gateways: Gateway[];
  gatewaysSelected: Gateway[];

  month: any;
  subs: Subscription[] = [];
  runReport: boolean;
  reportWasGenerated: boolean;
  org: Org;
  isLoading: boolean;
  collections: any[];
  collectionList: any[];
  can = { admin: false };

  isShowingReviews = signal(false);
  isShowingBuildingHours = signal(false);

  // Master list
  reviewsMaster: IGetReviewsForBuildingItemReview[];
  // Filtered list
  reviews: IGetReviewsForBuildingItemReview[];

  searchText: string;

  hoursEditing: any;

  constructor(public messageService: MessageService, private apiService: APIService, private buildingService: BuildingsService, private invitesReviewService: InsightsReviewService, private storeService: StoreService) {

  }

  ngOnInit(): void {
    const buildingSub = this.buildingService.buildingList.subscribe(list => {
      if (!list || this.buildingDropdown) {
        return;
      }
      this.buildings = list;

      this.buildingDropdown = [
        { name: 'Select a building', id: null },
        ...this.buildings.map(b => {
          return { name: b.title, id: +b.id };
        }).sort((a, b) => a.name > b.name ? 1 : -1)
      ];

      setTimeout(() => {
        if (!this.hasCollection) {
          this.loadLocalStorage();
        }

        if (this.hasCollection) {
          this.collectionList = [];
          this.buildingService.getAllCollectionsForUser(this.hasCollection).then(c => {
            console.log('COLLECTIONS:', c);
            this.collectionList.push(...c.assets.map(c => {
              return { label: c.buildingTitle + ' ' + c.collectionTitle, id: c.collectionId, buildingId: c.buildingId };
            }));
            this.loadLocalStorageForCollection();
          });
        }
      }, 1001);
    });

    this.subs.push(buildingSub);
    this.org = this.apiService.getUserOrg();
    this.can.admin = this.apiService.isAdmin();
  }

  selectBuildingChanged(buildingId: number | string) {
    this.buildingSelectId = +buildingId;
    this.buildingChanged();
  }

  clickShowReviews() {
    this.isShowingReviews.set(true);

    this.searchText = localStorage.getItem('reviews:list:search') || '';

    this.invitesReviewService.getReviewsForOrg().then(r => {
      this.reviewsMaster = r.reviews.map(review => {
        const org = this.storeService.getOrg(review.org_id);
        review.orgTitle = org?.title || `org ` + String(review.org_id) + ` not shared.`;
        review.orgShortTitle = org?.shortTitle || `org ` + String(review.org_id) + ` not shared.`;
        return review;
      });

      this.processSearch();
    });
  }

  loadLocalStorage() {
    const data = localStorage.getItem(this.apiService.getUserOrg().id + ':insights:building');
    if (data) {
      this.buildingSelected = new Building(JSON.parse(data));
      this.buildingSelectId = this.buildingSelected.id;
      this.buildingChanged();
    }
  }

  loadLocalStorageForCollection() {
    const data = localStorage.getItem(this.apiService.getUserOrg().id + ':insights:ff:collection');
    if (data) {
      this.collectionSelected = JSON.parse(data);
      this.collectionChanged();
    }
  }

  saveLocalStorage() {
    const payload = this.buildingSelected;
    localStorage.setItem(this.apiService.getUserOrg().id + ':insights:building', JSON.stringify(payload));
  }

  savelocalStorageForCollection() {
    const payload = this.collectionSelected;
    localStorage.setItem(this.apiService.getUserOrg().id + ':insights:ff:collection', JSON.stringify(payload));
  }

  buildingChanged() {
    this.buildingSelected = this.buildings.find(b => b.id === this.buildingSelectId);

    if (!this.buildingSelected || !this.buildingSelected.id) {
      this.buildingForReport = null;
      return;
    }

    this.onBuildingChanged.emit(this.buildingSelected);

    this.saveLocalStorage();
    console.log(this.buildingSelected);

    this.getBuilding(this.buildingSelected.id);
  }

  getBuilding(id: number) {
    this.isLoading = true;
    this.buildingService.getOne(id)
      .then(building => {
        this.isLoading = false;
        this.buildingForReport = building;
        if (building.openingHours.isSet) {
          this.buildingHasHours();
        }
      });
  }

  getGateways() {

  }

  updateBuildingHours() {
    this.buildingForReport.openingHours.hours.forEach(hour => {
      const modified = this.hoursEditing.hours.find(day => day.dow === hour.dow);
      if (modified) {
        if (modified.isClosed) {
          hour.isClosed = true;
          hour.from = null;
          hour.to = null;
        } else {
          hour.isClosed = false;
          hour.from = modified.from;
          hour.to = modified.to;
        }
      }
    });

    this.isShowingBuildingHours.set(false);

    this.buildingService.updateHours(this.buildingForReport).then(b => {
      this.messageService.add({ severity: 'info', summary: 'hours updated', detail: this.buildingForReport.title });

      this.getBuilding(this.buildingForReport.id);
    });
  }

  toolbarClick(event: any) {

    this.hoursEditing = { hours: this.buildingForReport.openingHours.hours.map(hour => hour.serialise()), isSet: this.buildingForReport.openingHours.isSet };
    this.isShowingBuildingHours.set(true);
  }

  clickReview(review: IGetReviewsForBuildingItemReview) {
    const building = this.buildings.find(building => building.id === review.building_id);
    this.buildingSelected = building;
    this.buildingSelectId = building.id;
    this.buildingChanged();
    this.saveLocalStorage();
    this.onReviewSelected.emit(review);
    this.isShowingReviews.set(false);
  }

  generate() {
    this.gatewaysSelected = this.gateways.filter(g => g.selected);
    if (this.gatewaysSelected.length === 0) {
      this.messageService.add({ detail: 'Please select one or more gateways', severity: 'info' });
      return;
    }
    if (!this.month) {
      this.messageService.add({ detail: 'Please select a month', severity: 'info' });
      return;
    }

    if (this.runReport && !this.reportWasGenerated) {
      console.log('REPORT_GENERATING_PLEASE_WAIT');

      return;
    }

    if (this.runReport) {
      this.runReport = false;
      setTimeout(() => {
        this.runReport = true;
        this.reportWasGenerated = false;
      }, 10);
    } else {
      this.reportWasGenerated = false;
      this.runReport = true;
    }
  }
  onReportWasGenerated() {
    this.reportWasGenerated = true;
  }

  printPage() {
    window.print();
  }

  copyToAll(hour: OpeningHour) {
    this.hoursEditing.hours.filter(day => !day.isClosed).forEach(day => {
      day.from = hour.from;
      day.to = hour.to;
    });
  }

  clearHour(hourIndex) {
    this.buildingForReport.openingHours.hours[hourIndex].isClosed = true;
    this.buildingForReport.openingHours.hours[hourIndex].from = null;
    this.buildingForReport.openingHours.hours[hourIndex].to = null;
  }

  buildingHasHours() {
    this.onBuildingSelected.emit(this.buildingForReport);
  }

  collectionChanged() {
    if (!this.collectionList) {
      return;
    }
    const item = this.collectionList.find(c => c.id === this.collectionSelected.id);
    if (!item) {
      return;
    }
    this.isLoading = true;
    this.buildingService.getOne(item.buildingId)
      .then(building => {
        this.isLoading = false;
        this.buildingForReport = building;
        if (building.openingHours.isSet) {
          this.buildingHasHours();
          this.onBuildingChanged.emit(building);
        }
      });
    this.saveLocalStorage();
  }


  search() {
    if (!this.searchText || this.searchText.length < 1) {

      localStorage.removeItem('reviews:list:search');
      this.processSearch();

      return;
    }

    localStorage.setItem('reviews:list:search', this.searchText);

    this.processSearch();
  }

  processSearch() {
    this.reviews = this.reviewsMaster.filter(review => {
      return (review.title.toLowerCase().includes(this.searchText) || review.orgShortTitle.toLowerCase().includes(this.searchText));
    });
  }

  searchChanged(text: string) {
    this.searchText = text;
    this.search();
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}

export interface IGetBuildingAndGateways {
  building: Building;
  gateways: Gateway[];
}

<div class="message">
  <div class="row">
    <div class="user col-sm-12">
      <div class="row">
        <div class="col-sm-6"> {{message.createdBy.name}}</div>
        <div class="col-sm-6"> <div class="text-right">{{message.createdAt | amTimeAgo: false}}</div>
        </div>
      </div>

    </div>
    <div class="col-sm-12">
      <span class="body">{{message.body}}</span>
    </div>
  </div>
</div>
@defer(when insight?.floorplans) {
<article>
    <section class="header mb-2">
        <header>
            <div class="mb-1">
                <h2>{{headerTitle}}</h2>
                <small>{{insight.configuration.start | amDateFormat:'DD MMMM YYYY'}} to {{insight.configuration.end
                    | amDateFormat:'DD MMMM YYYY'}}</small>
            </div>
            <div>
                @if(logo) {
                <img class="logo" [src]="logo | safeUrl" alt="logo">
                }
            </div>
        </header>
        <div>
            <table class="table">
                <thead>
                    <tr>
                        <th>Address</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="address">
                            <p>{{building.site.address.address1}}</p>
                            <p>{{building.site.address.address2}}</p>
                            <p>{{building.site.address.addressTown}}</p>
                            <p>{{building.site.address.addressCounty}}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="opening-hours">
            <p class="sub-title">Opening hours</p>
            <table class="table">
                <thead>
                    <tr>
                        <th>Monday</th>
                        <th>Tuesday</th>
                        <th>Wednesday</th>
                        <th>Thursday</th>
                        <th>Friday</th>
                        <th>Saturday</th>
                        <th>Sunday</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        @for(day of building.openingHours.hours; track $index) {
                        <td>
                            @if(day.isClosed) {
                            <span>Closed</span>
                            } @else {
                            <span>{{day.from}} - {{day.to}}</span>
                            }
                        </td>
                        }
                    </tr>
                </tbody>
            </table>
        </div>
        @if(isReady && !hasData) {
        <div>
            <h3>No data</h3>
        </div>
        }
    </section>

    <section class="plans" id="report">
        @for(floorplan of insight.floorplans.plans;track $index) {
        <div class="plan">
            <img src="https://55auilnnlh.execute-api.eu-west-2.amazonaws.com/4d/floorplans/{{floorplan.plan.key}}.svg?v=7&vbx=0&vby=0&vbw={{floorplan.plan.vw}}&vbh={{floorplan.plan.vh}}" alt="floorplan">
            <svg [attr.viewBox]="floorplan.plan.viewbox">
                <defs>
                </defs>
                @for(asset of floorplan.assets;let i = $index; track $index) {
                <g class="assets">
                    <circle class="asset-circle" [attr.cx]="asset.x" [attr.cy]="asset.y" [attr.r]="floorplan.plan.cr + 10" fill="white" stroke-width="4" [attr.stroke]="insight.configuration.totals[asset.asset_id].count > 0  ? 'green' : 'red'">
                    </circle>
                    <text [attr.x]="asset.x" [attr.y]="asset.y+8" text-anchor="middle">{{insight.configuration.totals[asset.asset_id].perc}}%</text>
                </g>
                }
            </svg>
        </div>
        }
    </section>

    <section class="day-counts ">
        <div *ngFor="let item of insight.telemetryCollection | keyvalue" class="pagebreakbefore">
            <main class="header">
                <div>
                    <h3>{{item.value.title}}</h3>
                </div>
                <div pTooltip="Total change from previous month">
                    <span class="mdi" [class.mdi-arrow-down-bold-outline]="insightContainer?.totals[item.key].month<0" [class.mdi-arrow-up-bold-outline]="insightContainer?.totals[item.key].month>0" [class.green]="insightContainer?.totals[item.key].month>0" [class.red]="insightContainer?.totals[item.key].month<0"></span>
                    {{insightContainer?.totals[item.key].month}}%
                </div>
            </main>
            <app-d3-bar [legendHasMultipleRows]="true" [telemetry]="item.value.telemetryByDay" [min]="0" [max]="10" [dateFrom]="insight.configuration.start" [dateTo]="insight.configuration.end" [weather]="weather" [ranges]="insight.configuration.ranges">
            </app-d3-bar>
            <section class="compare-weeks">
                <section *ngIf="insightContainer">
                    <app-insights-ff-compare [insightContainer]="insightContainer" [forCollection]="item.key">
                    </app-insights-ff-compare>
                </section>
            </section>
        </div>
    </section>

</article>
} @placeholder {
<i class="mdi-loading mdi mdi-spin-x2"></i>
}

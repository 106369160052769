@defer(when offlineIssues()) {
<article>
	<app-info-panel><i class="mdi mdi-test-tube"></i> You are testing an experimental feature </app-info-panel>
	@if(offlineIssues().length) {
	<table class="table" aria-label="Issues">
		<thead>
			<tr>
				<th>Date</th>
				<th>Issue</th>
				<th>Effects</th>
				<th>Asset</th>
				<th>Gateway</th>
				<th>Site</th>
			</tr>
		</thead>
		<tbody>
			@for(item of offlineIssues(); track item.id) {
			<tr>
				<td>{{item.createdAt | date:'dd/MM/YY'}}</td>
				<td>{{item.issue}}</td>
				<td class="capitalise">{{item.effected}}</td>
				<td>
					@if(item.effected === 'asset') {
					{{item.assetTitle}}
					}
				</td>
				<td>
					@if(item.effected === 'asset' || item.effected === 'gateway') {
					{{item.gatewayTitle}}
					}
				</td>
				<td>{{item.siteTitle}}</td>
			</tr>
			}
		</tbody>
	</table>
	} @else {
	<app-info-panel> No Issues have been recorded yet.</app-info-panel>
	}
</article>
} @placeholder {
<span><i class="mdi mdi-loading mdi-spin-x2"></i> </span>
}

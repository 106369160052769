import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.css']
})
export class HomeFooterComponent implements OnInit {

  contact: { name: string, email: string, message: string, terms: boolean, company: string }
    = { name: null, email: null, message: null, company: null, terms: false };

  constructor() { }

  ngOnInit() {
  }

  submit() {

  }

}

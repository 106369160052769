import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Building } from 'app/classes/building';
import { Insight } from 'app/classes/insight';
import { MapboxService } from "app/shared/mapbox.service";
import mapboxgl from 'mapbox-gl';

declare const google: any;


@Component({
  selector: 'app-insights-as-page1',
  templateUrl: './insights-as-page1.component.html',
  styleUrls: ['./insights-as-page1.component.css']
})
export class InsightsAsPage1Component implements OnInit, AfterViewInit {

  @Input()
  headerTitle: string;

  @Input()
  insight: Insight;

  @Input()
  building: Building;

  @Input()
  start: Date;

  @Input()
  end: Date;

  @Input()
  theme: Page1Theme = 'default';

  center: mapboxgl.LngLatLike;

  markers: mapboxgl.Marker[];

  dates: { start: Date, end: Date };

  FOURD_LOGO = '/assets/svg/4d_logo_light.svg';

  constructor(private mapboxService: MapboxService) { }

  ngOnInit(): void {
    const lat = +this.building.site.address.addressLat;
    const lng = +this.building.site.address.addressLong;

    this.center = { lat, lng };
    this.markers = [new mapboxgl.Marker().setLngLat({
      lat,
      lng,
    })];

    this.dates = {
      start: this.start || this.insight?.configuration.start,
      end: this.end || this.insight?.configuration.end
    };
  }

  ngAfterViewInit(): void { }
}

export type Page1Theme = 'classic' | 'default';

<div class="page-container" *ngIf="siteReview">

  <div class="heading">
    <h3>{{siteReview.site.title}} <small>{{siteReview.title}}</small></h3>
    <div class="dates">Review from <span>{{siteReview.startsAt | amDateFormat: "DD MMM YYYY"}}</span> to
      <span>{{siteReview.endsAt | amDateFormat: "DD MMM YYYY"}}</span>
    </div>
  </div>

  <div *ngFor="let asset of siteReview.assets">
    <div class="asset" *ngIf="asset.id !==711926">

      <table>
        <tbody>
          <tr>
            <td>
              <h4>{{asset.title}}</h4>
              <p>Maximum: {{asset.telemetry.max}}</p>
              <p>Minimum:
                <span *ngIf="asset.telemetry.min === null"><i class="fa fa-fw fa-refresh fa-spin"></i></span>
                {{asset.telemetry.min}}
              </p>

            </td>
            <td>
              <app-review-graph [review]="siteReview" [asset]="asset" (onFinishedLoading)="onFinishedLoading($event)">
              </app-review-graph>
            </td>
          </tr>
        </tbody>
      </table>
      <!--
      <div class="add-finding" *ngIf="isaddingAFinding">
        <button class="btn btn-block btn-secondary"><i class="fa fa-plus"></i> Add finding</button>
      </div>
    -->
    </div>
  </div>
</div>

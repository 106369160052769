import { Component, OnInit } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Gateway } from 'app/classes/gateway';
import { Org } from 'app/classes/org';
import { User } from 'app/classes/user';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ManageService } from 'app/shared/manage.service';

@Component({
  selector: 'app-manage-landing',
  templateUrl: './manage-landing.component.html',
  styleUrls: ['./manage-landing.component.css']
})
export class ManageLandingComponent implements OnInit {

  assets: Asset[];
  gateways: Gateway[];
  orgs: Org[];
  users: User[];

  results: {
    org: [],
    gateway: [],
    user: [],
    asset: []
  }

  tabs = { active: 'org' };

  createObject: User | Org | Gateway | Asset;

  constructor(private manageService: ManageService) { }

  ngOnInit() {
    this.getList();
  }

  saved() {
    this.getList();
  }

  getList() {
    switch (this.tabs.active) {
      case 'org':
        this.manageService.getOrg()
          .subscribe((orgs) => {
            this.orgs = orgs.map(org => new Org(org));
          });
        break;
    }
  }

  edit(item: any) {
    switch (item.constructor.name) {
      case 'Org':
        this.create('org', item);
    }
  }

  create(table: string, data?: any) {
    console.log('create ', table);

    switch (table) {
      case 'user':
        this.createObject = new User();
        break;
      case 'org':
        const org = new Org(data);
        if (!data) {
          org.isBillable = true;
          org.isActive = true;
        }
        this.createObject = org;

        break;
      case 'gateway':
        this.createObject = new Gateway();
        break;
      case 'asset':
        this.createObject = new Asset();
        break;
    }
  }

}

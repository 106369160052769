import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-assets',
  templateUrl: './section-assets.component.html',
  styleUrls: ['./section-assets.component.css']
})
export class SectionAssetsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

<table [hidden]="!viewGateways" class="table table-sm table-hover">
  <thead>
    <tr>
      <th> Gateways </th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let gateway of gateways; let i = index">
      <td (click)="focusGateway(gateway)">{{gateway.title}} </td>
      <td>
        <div class="pull-right"> <i (click)="gotoGateway(gateway)" class="fa fa-chevron-right"></i></div>
      </td>
    </tr>
  </tbody>
</table>
<article>

	<header>
		<app-toolbar>
			<div class="left">
				<app-button-yourdashboard-config [value]="{dates, siteId}" [sites]="_sites()" (onSelect)="configUpdated($event)"></app-button-yourdashboard-config>
			</div>
		</app-toolbar>
	</header>

	<main *ngIf="tiles">
		<section class="grid grid-1">
			<div>
				<div>
					<app-visualise-tile [visualisationConfig]="tiles[2]"></app-visualise-tile>
				</div>
			</div>
		</section>

		<section class=" grid grid-2 tall">
			<div>
				<app-visualise-tile [visualisationConfig]="tiles[0]"></app-visualise-tile>
			</div>
			<div>
				<app-visualise-tile [visualisationConfig]="tiles[1]"></app-visualise-tile>
			</div>
		</section>
	</main>

	<footer></footer>

</article>

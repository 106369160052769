import { Component, OnInit, Input } from '@angular/core';
import { Gateway } from '../../classes/gateway';

@Component({
  selector: 'app-gateway-indicator',
  templateUrl: './gateway-indicator.component.html',
  styleUrls: ['./gateway-indicator.component.css']
})
export class GatewayIndicatorComponent implements OnInit {
  
  @Input()
  gateway: Gateway;

  constructor() { }

  ngOnInit() {
  }

}

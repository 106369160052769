<div *ngIf="module">

  <app-page-title label="4D Training - {{module.title}}" [canGoBack]="true" (click)="goBack()"></app-page-title>


  <div class="video-container" *ngIf="module">
    <div class="" *ngIf="module.video">
      <video #video1 autoplay (timeupdate)="videoProgress($event)" #video controls id="video" autobuffer
        style="display:block; margin: 0 auto;">
        <source src="https://s3-eu-west-1.amazonaws.com/4d-training/videos/{{module.video}}">
      </video>
    </div>

    <div *ngIf="module.document">
      <a class="btn btn-primary " href="https://train.4dml.com/assets/4DGatewayOnboardingforEngineers.pdf">
        <i class="fa fa-file-pdf-o"></i> View Onboarding Guide</a>
    </div>
  </div>

  <!-- handle pause while transitioning to another module -->
  <div class="col-xs-12" *ngIf="transitionToModuleId && !module">
    <p>Next module loading, please wait...</p>
  </div>
</div>

<article class="global-theme white content-container">
	<header>
		<app-setpoint-schedules-calendar [schedules]=" schedules"></app-setpoint-schedules-calendar>
	</header>
	<main>
		<app-info-panel *ngIf="!isLoading && schedules?.length === 0">
			<div>There are no upcoming schedules, to create a schedule select a template.</div>
			<div class="mt-1">
				<button pButton type="button" class="p-button-outlined p-button-sm p-button-secondary" (click)="
					navigateTo('templates')" icon="mdi mdi-text-box-multiple-outline" label="Go to templates"></button>
			</div>
		</app-info-panel>

		<app-theme-page-section *ngIf="schedules?.length && assetTypes" theme="white" label=" " [toolbar]="[{icon:'mdi-plus',label:'Add a new schedule',tag:'ADD_SCHEDULE', text:'Add schedule', 'tooltipposition': 'bottom'}]" (onToolbarClick)="toolbarClick($event)">
			<table class="table table-hover table-striped">
				<thead>
					<tr>
						<th>Schedule</th>
						<th>Category</th>
						<th>Repeats</th>
						<th>Sites</th>
						<th>Assets</th>
						<th>Process</th>
						<th>Author</th>

					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of schedules" [routerLink]="['/setpoints', 'schedules', item.id]">
						<td>{{item.title}} <span *ngFor="let type of item.template.assetTypeIds">{{assetTypes[type]?.title || '???'
								}}</span>
						</td>
						<td>{{item.template.category.title}}</td>
						<td>{{item.repeats}}</td>
						<td>{{item.counts.sites || 'all'}}</td>
						<td>{{item.counts.assets || 'all'}}</td>
						<td>{{item.processAt | amDateFormat:'DD MMM YYYY'}}</td>
						<td>{{item.createdBy.name}}</td>
						<!--<td><a [routerLink]="['/setpoints','templates',item.template.id,'scheduler', item.id]"><i
									class="mdi mdi-text-box-multiple-outline"></i> template</a></td>-->
						<!--<td [innerHTML]="item.notes"></td>-->
					</tr>
				</tbody>
			</table>
		</app-theme-page-section>
	</main>
	<footer>
		<app-media-carousel [items]="[
			{title:'Setpoint Scheduler', 
			overview: 'setpoint scheduler overview basics',
			type:'video',
			 thumbnail:'https://4d-training.s3.eu-west-1.amazonaws.com/videos/v3/setpoint_scheduler_thumbnail.png',
			  target:'https://4d-training.s3.eu-west-1.amazonaws.com/videos/v3/setpoint_scheduler.mp4'}
			]"></app-media-carousel>
	</footer>
</article>

<app-please-wait *ngIf="isLoading"></app-please-wait>

@defer(when collections) {
<article>
	<footer>
		<app-toolbar>
			<div class="right">
				<app-button *ngIf="isAdmin" tooltipPosition="left" label="Build missing days" size="medium" icon="mdi mdi-shield-account-outline" pTooltip="Admin Users only" styleClass="mr-1 p-button-secondary" (click)="autorebuild()" />
				<app-button size="medium" label="Create Collection of days" icon="mdi mdi-account-plus" (click)="createOrUpdateCollectionOfDays()" />
			</div>
		</app-toolbar>
	</footer>
	<main>
		<section>
			@if(collections.length) {
			<table class="table collections" aria-label="Occupancy collection days">
				<tbody>
					@for(item of collections;track item.id) {
					<tr (click)="collectionClick(item)" class="collection">
						<td>
							<div>
								<h4>{{item.title}}</h4>
							</div>
							<div>
								<img src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{item.org.logo}}">
							</div>
							<div>
								<span class="asset-count" [ngClass]="{ 'has-assets': item.assetCount}">{{item.assetCount ?
									item.assetCount + ' assets in collection' : 'No assets'}}</span>
							</div>
						</td>
						<td>
							<table class="table">
								<thead>
									<tr>
										<th>Days</th>
										<th>From</th>
										<th>To</th>
										<th>Duration</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let days of item.days">
										<td>
											<span *ngFor="let dow of days.days;let i = index" class="dow" [class.selected]="dow === 1">
												<span>{{dows[i]}}</span>
											</span>
										</td>
										<td>{{days.startTime}}</td>
										<td>{{days.endTime}}</td>
										<td>{{days.duration}} <span class="gray">Mins</span></td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					@if(item.bgJobs.length) {
					<tr>
						<td colspan="2" class="bg-jobs">
							<h5>Background update jobs for {{item.title}}</h5>
							<table class="table" aria-label="Background jobs">
								<tbody>
									<tr *ngFor="let job of item.bgJobs">
										<td>
											<div class="bg-job">
												<div tooltipPosition="bottom" pTooltip="Updates are delayed when the system is busy or may require a 4D admin to process">
													{{job.state === 'new' ? 'Queued for processing' : 'Processing'}}, requested at {{job.createdAt | date:'dd-MM-yy HH:mm'}} by {{job.createdByName}}
												</div>
												<div *ngIf="isAdmin">
													<p-button tooltipPosition="left" pTooltip="Admins may run this immediately" label="Run job #{{job.id}} Now" icon="mdi mdi-shield-account-outline" styleClass="p-button-xsm p-button-outlined p-button-secondary" (click)="rebuild(item.id, job.id)"></p-button>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					}
					}
				</tbody>
			</table>
			} @else {
			<app-info-panel>No collections.</app-info-panel>
			}
		</section>
	</main>
</article>
}
@if(collection()) {
<app-dialog [header]="'#' + (collection()?.id || '')  + ' ' + (collection()?.title || 'New')" (onClose)="collection.set(null)">
	<app-dialog-occ-collection-update [collection]="collection()" (onUpdated)="get()" (onClose)="collection.set(null)"></app-dialog-occ-collection-update>
</app-dialog>
}

@if(isWorking()) {
<app-please-wait></app-please-wait>
}

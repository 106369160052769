import { Component, Input, OnInit, input } from '@angular/core';
import { LicenseCollection } from "../../classes/license-collection";

@Component({
  selector: 'app-billing-stats',
  templateUrl: './billing-stats.component.html',
  styleUrls: ['./billing-stats.component.css']
})
export class BillingStatsComponent implements OnInit {

  counts = input.required<any>();

  data: any = { a: 1 };
  perc: any;
  value: string;

  legend: any = {
    expired: { label: '', colour: 'red' },
    licensed: { label: '', colour: 'green' }
  };


  constructor() { }

  ngOnInit(): void {
    this.compute();
  }

  compute() {
    const total = this.counts().all.l;

    const perc = 100 / total;

    this.data = {
      expired: this.counts().expired.l,
      licensed: total - this.counts().expired.l
    };
    this.value = parseInt(String((total - this.counts().expired.l) * perc)) + '%';
    this.perc = {
      licensed: this.value,
      expired: parseInt(String(this.counts().expired.l * perc))
    };
  }

  onSliceClick(event: any) {

  }

}

import { Component, OnInit, computed, signal } from '@angular/core';
import { AdminBuilderService } from 'app/admin/services/admin-builder.service';

@Component({
  selector: 'app-profiling-landing',
  templateUrl: './profiling-landing.component.html',
  styleUrls: ['./profiling-landing.component.css']
})
export class ProfilingLandingComponent implements OnInit {

  onTab = signal<string>('profiling');
  screens = signal<IScreen[]>([]);
  onScreen = computed(() => {
    if (this.onTab().startsWith('screen_')) {
      return +this.onTab().split('_')[1];
    }

    return null;
  });
  readonly LOCALSTORAGE_GET = 'profilinglanding:tab';

  constructor(private builderService: AdminBuilderService) {
    builderService.getScreensForModule('profiling').then(pages => {
      this.screens.set(pages.map(page => {
        return <IScreen>{ id: page.screen_id, title: page.title };
      }));
    });
  }

  ngOnInit() {
  }

  handleTabChange(event: any) {
  }

  tabClick(tab: string) {
    this.onTab.set(tab);
  }
}


export interface IScreen {
  id: number;
  title: string;
}

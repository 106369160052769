/**
 * cookies selector renamed to kookies to avoid css issues with cookies keywork
 */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kookies-policy',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}

@defer(when rule) {
<main>
	<section class="control">
		<div>
			@if(!isWorking()) {
			<app-button icon="mdi mdi-eye-check-outline" label="Acknowledge" (click)="changeState('acknowledged')" [isDisabled]="!isReady || alarm.state === 'resolved'"></app-button>
			<app-button icon="mdi mdi-pause" label="Pause" (click)="changeState('paused', 60)" [isDisabled]="!isReady || alarm.state === 'paused'   || alarm.state === 'resolved'"></app-button>
			<app-button icon="mdi mdi-check" label="Resolved" (click)="changeState('resolved')" [isDisabled]="!isReady || alarm.state === 'resolved'"></app-button>
			} @else {
			<app-button label="Acknowledge" [isDisabled]="true"></app-button>
			<app-button label="Pause" [isDisabled]="true"></app-button>
			<app-button label="Resolved" [isDisabled]="true"></app-button>
			}
		</div>
		<div>
			<div *ngIf="rule.instructions" class="instructions">
				<h5>Instructions</h5>
				<p>{{rule.instructions}}</p>
			</div>
			<h5>Add notes</h5>
			<textarea class="fourd" [disabled]="!isReady" rows="3" [(ngModel)]="notes" (ngModelChange)="dirty=true"></textarea>
			@if(dirty && notes) {
			<app-toolbar>
				<div class="right">
					<app-button [isDisabled]="!isReady" (click)="saveNotes()" label="Save Note" icon="mdi mdi-check" />
				</div>
			</app-toolbar>
			}
		</div>
	</section>

	<section class="timeline">
		<div #timeline>
			<app-rulesv3-item-alarm-timeline [alarm]="alarm"></app-rulesv3-item-alarm-timeline>
		</div>
	</section>
</main>
} @placeholder {
<i class="mdi mdi-loading mdi-spin-x2"></i>
}
@if(_rule?.hasIntegration) {
<app-theme-page-section label="Reactive Job" logo='elogbooks'>
	<app-rulesv3-item-alarm-elogbooks [alarm]="alarm"></app-rulesv3-item-alarm-elogbooks>
</app-theme-page-section>
}

import { Component, ElementRef, Input, OnDestroy, ViewChild, Output, EventEmitter, signal, QueryList, AfterViewInit, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Site } from 'app/classes/site';
import { SiteFloorplanArea } from 'app/classes/site-floorplan-area';
import { SiteFloorplanAsset } from 'app/classes/site-floorplan-asset';
import { SiteFloorplanObject } from 'app/classes/site-floorplan-object';
import { SiteFloorplanShape } from 'app/classes/site-floorplan-shape';
import { Viewbox } from 'app/classes/svg/viewbox';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { D3ChartService } from 'app/shared/d3chart.service';
import { FloorplanService, SitePlanComplete } from 'app/shared/floorplan.service';
import { StoreService } from 'app/shared/store.service';
import { WindowService } from 'app/shared/window.service';
import { Location } from '@angular/common';
import { take } from 'rxjs/operators';
import { IToolbarChanged, ViewType } from './site-plan-floorplan-toolbar/site-plan-floorplan-toolbar.component';
import { IgetHourlyForAssetFromRangeResponse, OccupancyService } from 'app/shared/occupancy.service';
import { IDatesFromTo } from 'app/interfaces/dates-from-to';
import { Subscription } from 'rxjs';

import moment from 'moment';

@Component({
  selector: 'app-site-plan-floorplan',
  templateUrl: './site-plan-floorplan_v2.component.html',
  styleUrls: ['./site-plan-floorplan.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SitePlanFloorplanComponent implements OnDestroy, AfterViewInit {

  planMessage = signal<string>('Initialising....');

  _sitePlanId: number;
  _siteId: number;
  _dates: IDatesFromTo;
  imgTimerRef: NodeJS.Timeout;

  @Input()
  public set config(v: { planId: number, siteId: number, dates?: IDatesFromTo }) {
    if (!v || !v.planId || !v.siteId) {
      return;
    }
    if (v.planId !== this._sitePlanId) {
      this._sitePlanId = v.planId;
      this._siteId = v.siteId;
      if (v.dates) {
        this._dates = v.dates;
      }
      this.getFloorplanFromAPI();
    }
  }

  @Output()
  onFloorplanLoaded = new EventEmitter<SitePlanComplete>();

  sitePlanComplete: SitePlanComplete;

  @ViewChild('svgDataContainer', { static: false }) svgDataContainer: ElementRef;

  @ViewChild('info', { static: false }) infoRef: ElementRef;

  @ViewChild('svgparent') svgParent: QueryList<HTMLDivElement>;
  @ViewChild('svgparent') svgParentEl: ElementRef<HTMLDivElement>;

  @ViewChild('svgimage') svgImage: ElementRef<HTMLImageElement>;

  static readonly componentName: string = 'SitePlanFloorplanComponent';
  static readonly DATA_REFRESH_EVERY = 60000;

  getTelemetyTimer: any;
  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };
  can: { admin: boolean } = { admin: false };

  _assets: SiteFloorplanAsset[] = [];

  public set assets(v: SiteFloorplanAsset[]) {
    this._assets = v;
    if (this.lastToolbarChanged) {
      // Apply the toolbar
      this.toolbarChanged(this.lastToolbarChanged);
    }
  }

  public get assets(): SiteFloorplanAsset[] {
    return this._assets;
  }

  areas: SiteFloorplanArea[] = [];
  shapes: SiteFloorplanShape[] = [];
  objects: SiteFloorplanObject[] = [];

  chunkedAssets: SiteFloorplanAsset[] = [];

  hasLoaded: boolean;

  mode = '';
  stats = {
    clientX: 0,
    clientY: 0,
    svgX: 0,
    svgY: 0
  };
  areaFocus: SiteFloorplanArea;
  site: Site;
  // Set to an asset to view full screen data
  viewAsset: SiteFloorplanAsset;

  scales: any = { r: 20, labelYOffset: 34 };
  dataRefreshTimer = null;
  selectedArea = signal<SiteFloorplanArea>(null);
  selectedShape: SiteFloorplanShape;
  isSVGImageRendered: boolean;
  isLoading: boolean;
  hoverObject = signal<{ type: string, obj: any, left: number, top: number }>(null);
  viewShape: SiteFloorplanShape;

  hoverSensor = signal<any>(null);
  hoverSensorTelemetry = signal<any>(null);

  changeDetectHover: any;
  changeDetectTimerRef: any;

  zoomLevel = 0;
  svgMod = { x: 0, y: 0, width: 0, height: 0 };

  lastToolbarChanged: IToolbarChanged;

  daysOfHour = 0;

  assetsHourlySummary: IgetHourlyForAssetFromRangeResponse;

  @Input()
  view: ViewType;

  userSub: Subscription;
  dataSubjectSub: Subscription;

  constructor(private occupancyService: OccupancyService, private floorplanService: FloorplanService, private windowService: WindowService, private el: ElementRef, private location: Location, private sanitizer: DomSanitizer, private apiService: APIService, private router: Router, private route: ActivatedRoute, private storeService: StoreService, private assetService: AssetService, private d3ChartService: D3ChartService, private zone: NgZone) {

    this.dataSubjectSub = floorplanService.dataSubject.subscribe(data => {
      switch (data.key) {
        case 'area':
          this.clickFloorplanLabel(data.value);
          break;
        case 'occupancy':
          this.toolbarChanged(data.value);
          break;
        default:
          break;
      }
    });

    this.changeDetectTimerRef = setInterval(() => {
      if (this.changeDetectHover && !this.hoverSensor()) {
        this.hoverSensor.set(this.changeDetectHover);
      } else {
        if (this.hoverSensor() && !this.changeDetectHover) {
          this.hoverSensor.set(null);
        } else if (this.hoverSensor()?.asset?.id !== this.changeDetectHover?.asset?.id) {
          this.hoverSensor.set(this.changeDetectHover);
        }
      }
    }, 100);

  }

  ngAfterViewInit(): void {
    this.imgTimerRef = setInterval(() => {
      if (this.svgImage?.nativeElement?.complete) {
        clearInterval(this.imgTimerRef);
        this.svgLoaded();
        this.zone.runOutsideAngular(() => {
          const d = this.svgParent;
          this.svgParentEl.nativeElement.addEventListener('mousemove', (e) => this.svgMouseMove(e));
        });
      }
    }, 250);
  }

  navigateBack() {
    this.router.navigateByUrl('/profiling');
    // this.router.navigate(['..'], { relativeTo: this.route })
  }

  clickFloorplanLabel(area?: SiteFloorplanArea) {
    if (!area) {
      this.sitePlanComplete.viewbox = new Viewbox(this.sitePlanComplete.sitePlan.vbx, this.sitePlanComplete.sitePlan.vby, this.sitePlanComplete.sitePlan.viewboxWidth, this.sitePlanComplete.sitePlan.viewboxHeight);
      this.selectedArea.set(null);
    } else {
      this.sitePlanComplete.viewbox = new Viewbox(area.x, area.y, area.width, area.height);
      this.selectedArea.set(area);
    }
  }

  toolbarChanged(dates: any) {
    switch (this.view) {
      case 'occupancy daily':
        //this.view = 'occupancy daily';
        if (this.assets.length) {
          this.getOccupancy(dates);
        }
        break;
      case 'occupancy weekly':
        if (this.assets.length) {
          this.getOccupancy(dates, 'weekly');
        }
        break;
    }
  }

  /**
   * Get occupancy for desks only
   * @param datesFromTo 
   * @param view 
   */
  getOccupancy(datesFromTo: IDatesFromTo, view: 'daily' | 'weekly' = 'daily') {
    this.planMessage.set('retrieving occupancy from API');
    this.occupancyService.getHourlyForAssetFromRange(this.assets.filter(a => a.assetType_id === 50).map(a => a.id), datesFromTo.from, datesFromTo.to, true, view).then(r => {
      this.planMessage.set(null);
      this.assetsHourlySummary = r;
    });
  }

  siteplanLoaded() {
    this.userSub = this.apiService.user
      .pipe(take(1))
      .subscribe(u => {
        let refreshEvery = u.prefs && u.prefs.floorplan_refresh ? u.prefs.floorplan_refresh * 1000 : SitePlanFloorplanComponent.DATA_REFRESH_EVERY;
        if (refreshEvery < 5000) {
          // Minimum 5 seconds
          if (u.id === 10) { refreshEvery = 2000 } else { refreshEvery = 5000 }
        }
        this.dataRefreshTimer = setInterval(() => {
          this.isLoading = true;
          // Get data every X seconds to refresh floorplan

        }, refreshEvery);
      });
  }

  loadChuckedAssets() {
    console.log('loadChuckedAssets()', this.chunkedAssets?.length);
    if (!this.chunkedAssets?.length) {
      console.log('NO ASSETS TO PROCESS', this._assets.length);
      this.planMessage.set(null);
      return;
    }
    const chunked = this.chunkedAssets.splice(0, 20);
    this._assets = this._assets.concat(chunked);
    const perc0 = (this.sitePlanComplete.assets.length / 100);

    const perc = Math.round(this._assets?.length / perc0);
    this.planMessage.set(`Plotting assets...${perc}%`);
    setTimeout(() => {
      this.loadChuckedAssets();
    }, 200);
  }

  getFloorplanFromAPI() {
    console.log('FLOORPLAN LOAD STARTED');
    this.planMessage.set('retrieving plan from API');
    this.floorplanService.getSiteFloorplan(this._siteId, this._sitePlanId)
      .then(planResults => {
        this.planMessage.set('plan retrieved from API');
        // Reset changes
        this.sitePlanComplete = new SitePlanComplete(planResults, this.assetService);

        if (!this.assets.length) {
          // Cache the assets for timeline rotation, heatmap
          if (this.sitePlanComplete.assets.length > 140) {
            this.chunkedAssets = [...this.sitePlanComplete.assets];
          } else {
            this._assets = this.sitePlanComplete.assets;
          }
        }

        if (this.sitePlanComplete.sitePlan.fontSize) {
          // Adjust the circle size
          this.scales.r = this.sitePlanComplete.sitePlan.fontSize * 1.8;
          this.scales.labelYOffset = this.sitePlanComplete.sitePlan.fontSize * 1.2;
        }

        if (this.view === 'occupancy daily' && this.sitePlanComplete.sitePlan.hasOccupancy === false) {
          this.view = 'realtime';
        }
        this.areas = this.sitePlanComplete.areas || [];
        this.shapes = this.sitePlanComplete.shapes || [];
        const defaultArea = this.areas.filter(area => area.defaultArea);

        if (defaultArea.length) {
          // The plan has a default area
          const area = defaultArea[0];
          this.sitePlanComplete.viewbox = new Viewbox(area.x, area.y, area.width, area.height);
        } else {
          this.sitePlanComplete.viewbox = new Viewbox(this.sitePlanComplete.sitePlan.vbx, this.sitePlanComplete.sitePlan.vby, this.sitePlanComplete.sitePlan.viewboxWidth, this.sitePlanComplete.sitePlan.viewboxHeight);
        }
        this.sitePlanComplete.viewboxString = this.sitePlanComplete.viewbox.toString();
        this.checkRAGs(true);
        this.hasLoaded = true;
        this.isLoading = false;
        console.log('FLOORPLAN LOAD COMPLETED');
        this.onFloorplanLoaded.emit(this.sitePlanComplete);
        this.planMessage.set('Waiting for floorplan plan to load');
        if (this.view !== 'realtime' && this._dates) {
          this.getOccupancy(this._dates);
        }
        /*
          this.assets.forEach(asset => {
            asset.changeDiff = 0;
          });
          planResults.assets.forEach(assetLatest => {
            this.assets.forEach(asset => {
              // Look for a change in value or updated At change on buttons
              if (
                asset.id === assetLatest.id &&
                ((asset.originalValue !== assetLatest.originalValue) || (asset.assetType_id === 1))
   
              ) {
                console.log('ASSET CHANGED', asset, assetLatest);
                //if (asset.isSetpointable) {
                try {
                  asset.changeDiff = +(asset.originalValue) - +(assetLatest.originalValue);
                } catch (e) { }
                //}
                asset.rag = assetLatest.rag;
                // Let the asset sort out RAG from value if custom asset RAG exists
                // - used by floorplans and overrides RAG from setpoints if exists,
                //   although this is used so setpoints are not required for simple RAG 
                //   triggers.
                asset.setValue(assetLatest.originalValue, assetLatest.updatedAt);
   
                asset.updatedAt = assetLatest.updatedAt;
              }
            });
          });
          console.log(this.assets.filter(a => !!a.changeDiff));
          this.checkRAGs();
          this.isLoading = false;
          */
      });
  }

  svgComplete() {
    if (this.planMessage() === 'Waiting for floorplan plan to load') {
      this.svgLoaded();
    }
  }

  svgLoadedFromInternet() {
    this.planMessage.set('SVG loaded');
  }

  svgLoaded() {
    this.planMessage.set('Floorplan diagram fully loaded');

    setTimeout(() => {

      this.loadChuckedAssets();
      this.planMessage.set(null);
      this.isSVGImageRendered = true;
      this.getZoomFromLocalStorage();
      if (this.sitePlanComplete.assets.length === 0) {
        this.planMessage.set('No assets on the plan');
      }
    }, 100);
  }

  saveZoomToLocalStorage() {
    const payload = {
      coords: this.sitePlanComplete.viewbox.calculated,
      zoomLevel: this.sitePlanComplete.viewbox.zoomLevel
    };
    localStorage.setItem(`siteplan:${this._sitePlanId}:zoom`, JSON.stringify(payload));
  }

  getZoomFromLocalStorage() {
    // Only for mobile view
    if (!this.windowService.isMobile()) {
      return;
    }
    const storage = localStorage.getItem(`siteplan:${this._sitePlanId}:zoom`);
    try {
      const payload = JSON.parse(storage);
      this.sitePlanComplete.viewbox.zoomInToZoomLevel(payload.zoomLevel);
      this.sitePlanComplete.viewbox.calculated = payload.coords;
    } catch (e) { }
  }

  zoom(action: 'in' | 'out' | 'reset') {
    // const zoomBy = 100;
    switch (action) {
      case 'out':
        this.zoomLevel -= 1;
        break;
      case 'in':
        this.zoomLevel += 1;
        break;
      case 'reset':
        this.zoomLevel = 0;
        break;
    }
    this.sitePlanComplete.viewbox.zoomInToZoomLevel(this.zoomLevel);
    this.saveZoomToLocalStorage();
  }

  panDirection(action: 'left' | 'right' | 'up' | 'down') {
    this.sitePlanComplete.viewbox.panDirection(action);
    this.saveZoomToLocalStorage();
  }

  reset() {
    this.sitePlanComplete.viewbox.reset();
  }

  sanitizedHTML(shape: SiteFloorplanShape, index?: number, counter?: number) {

    // console.log(`SHAPE`, index, counter, shape.svg);

    if (shape.svg) {
      return this.sanitizer.bypassSecurityTrustHtml(shape.svg);
    } else {
      return null;
    }
  }

  shapeClick(shape: SiteFloorplanShape) {
    console.log('SHAPE_CLICK', shape?.title);
    this.selectedShape = shape;
  }

  calcShapeRAG() {
  }

  objectInteract(where: 'label', event: 'mouseleave' | 'mouseenter' | 'click' | 'add', what: SiteFloorplanObject) {
    console.log('OBJ', where, event, what);
  }

  clickSelectAsset(asset: SiteFloorplanAsset) {
    if (asset.assetType_id === 1) {
      // This is a button
    } else {
      this.viewAsset = asset;
    }
  }

  movementOnShape(shape: SiteFloorplanShape, event: any) {
    if (!shape) {
      this.hoverObject.set(null);
      return;
    }
    const { left, top } = event.target.getBoundingClientRect();
    let { offsetX, offsetY } = event;

    offsetX += 10;
    offsetY += 10;
    this.hoverObject.set({ type: 'shape', obj: shape, left: offsetX, top: offsetY });
  }

  svgClick(event: any) {
    console.log(event);
  }

  processCursor(svg, pt, event) {
    pt.x = event.clientX;
    pt.y = event.clientY;

    this.processPT(pt);

    this.stats.clientX = pt.x;
    this.stats.clientX = pt.y;

    const cursorPT = pt.matrixTransform(svg.getScreenCTM().inverse());
    cursorPT.x = Math.round(cursorPT.x);
    cursorPT.y = Math.round(cursorPT.y);

    this.stats.svgX = cursorPT.x;
    this.stats.svgY = cursorPT.y;

    return cursorPT;
  }

  processPT(pt) {

    return pt;
  }

  svgMouseMove(event: any) {
    const svg = this.svgDataContainer?.nativeElement;
    if (!svg) {
      return;
    }
    const pt = svg.createSVGPoint();
    pt.x = event.clientX;
    pt.y = event.clientY;

    if (pt.x <= 0 && pt.y <= 0) {
      return;
    }
    this.processPT(pt);

    this.stats.clientX = pt.x;
    this.stats.clientY = pt.y;

    // The cursor point, translated into svg coordinates
    const cursorpt = pt.matrixTransform(svg.getScreenCTM().inverse());
    cursorpt.x = Math.round(cursorpt.x);
    cursorpt.y = Math.round(cursorpt.y);

    const toRight = pt.x < svg.clientWidth / 2;
    const toTop = pt.y < svg.clientHeight / 2;

    this.stats.svgX = cursorpt.x;
    this.stats.svgY = cursorpt.y;

    const asset = this.assets.find(a => a.svg.x <= cursorpt.x && a.svg.x + a.svgWidth >= cursorpt.x && a.svg.y <= cursorpt.y && a.svg.y + a.svgHeight + a.svgPrecision?.rect.h >= cursorpt.y);

    if (!asset) {
      this.changeDetectHover = null;
      return;
    }

    if (this.changeDetectHover && this.changeDetectHover.asset && this.changeDetectHover.asset.id === asset.id) {
      // Already have an asset that was the same

    } else {
      if (this.getTelemetyTimer) {
        clearTimeout(this.getTelemetyTimer);
      }

      // Pull telemetry if not occupancy, which has its own component.
      if (asset.assetType_id !== 50) {

        this.getTelemetyTimer = setTimeout(() => {
          const assetId = asset.id;
          const df = moment().startOf('day').toDate();
          const dt = new Date();
          this.d3ChartService.getTelemetry(assetId, df, dt, [])
            .then(response => {
              if (this.changeDetectHover && this.changeDetectHover.asset?.id === assetId) {
                // Don't display if no data today
                if (response.packets && response.packets.length) {
                  // Strip out weather as we use data min/max, complicates view
                  const obj = { min: null, max: null, telemetry: [] };
                  // this.changeDetectHover.min = null;
                  //this.changeDetectHover.max = null;

                  if (this.changeDetectHover.asset.assetType_id === 1) {
                    // Buttons are "Touch"
                    obj.telemetry = response.packets || [];
                    return;
                  }

                  obj.telemetry = response.packets.map(t => {
                    if (obj.min === null || t.v <= obj.min) {
                      obj.min = t.v;
                    }
                    if (obj.max === null || t.v >= obj.max) {
                      obj.max = t.v;
                    }
                    return { ...t, w: null };
                  });
                  this.hoverSensorTelemetry.set(obj);
                }
              }
            });
        }, 1000);

      }

    }

    if (toRight) {
      this.changeDetectHover = { ...this.changeDetectHover, x: pt.x + 30, y: (toTop ? pt.y + 10 : pt.y - 80), toRight };

    } else {
      this.changeDetectHover = { ...this.changeDetectHover, x: pt.x - 320, y: (toTop ? pt.y : pt.y - 80), toRight };
    }

    this.changeDetectHover.asset = asset;
    this.infoRef.nativeElement.style.left = this.changeDetectHover.x + 'px';
    this.infoRef.nativeElement.style.top = this.changeDetectHover.y + 'px';

  }

  checkRAGs(wantsInitialisation: boolean = false) {
    console.log('CHECKRAGS()', wantsInitialisation, this.shapes?.length);
    // Reset shapes to green
    this.shapes.forEach(s => s.atRAG = 'green');
    // Get rags for assets
    this.assets.forEach(asset => {

      const isMotion = asset.assetType_id === 50;

      if (wantsInitialisation) {
        asset.assetTypeTitle = this.assetService.getTypeTitle(asset.assetType_id);
      }

      if (asset.shapeId) {
        const shape = this.shapes.find(s => s.id === asset.shapeId);

        if (isMotion) {
          //  console.log(asset.id, asset.value, asset.upDataOp, shape);
        }

        if (wantsInitialisation) {
          shape.assets.push(asset);
        }

        let rag = null;
        if (asset.upData === 'rag') {
          if (asset.rag === 'red' || asset.rag === 'amber') {
            rag = asset.rag
          }
        } else if (asset.upData === 'value') {
          switch (asset.upDataOp) {
            case '=':
              if (+asset.originalValue === asset.upDataValue) {
                rag = 'red';
              }
              break;
            case '>':
              if (+asset.originalValue > asset.upDataValue) {
                rag = 'red';
              }
              break;
            case '<':
              if (+asset.originalValue < asset.upDataValue) {
                rag = 'red';
              }
              break;
          }
          // console.log('RAG ASSET', asset.title, +asset.originalValue, asset.value, asset.upDataOp, asset.upDataValue, rag);
        }

        if (rag) {
          shape.atRAG = rag;
          // console.log(`RAG SET ON SHAPE`, shape.title, rag);
        }

        if (isMotion) {
          // console.log('DECISION:', asset.value, shape.title, shape.atRAG, rag);
        }
      }
    });
    console.log('CHECKRAGS DONE');
  }

  ngOnDestroy(): void {
    this.dataSubjectSub.unsubscribe();

    console.log('DESTROY');
    if (this.userSub) {
      this.userSub.unsubscribe();
      console.log('unsub');
    }

    if (this.imgTimerRef) {
      clearInterval(this.imgTimerRef);
    }

    if (this.changeDetectTimerRef) {
      clearInterval(this.changeDetectTimerRef);
    }
  }
}

<article>
	<header></header>
	<main>
		<app-visualise-data-chart [visualisationItem]="visualisationItem"></app-visualise-data-chart>
	</main>
	<footer>
		<!--<i class=" mdi mdi-code-braces" class="dim" (click)="isDebugging = !isDebugging"></i>
		<div [hidden]="!isDebugging">
			{{visualisationItem.rows | json}}
		</div>-->
	</footer>
</article>

<p-card>

	<div class="form">
		<div class="row dates">
			<div class="col-md-6">
				<div class="form-group">
					<div for="assets">Date from</div>
					<p-calendar [(ngModel)]="dateFrom" [dateFormat]="apiService.getDateFormat().toLowerCase()" [locale]="en"
						[showIcon]="true" (onBlur)="rebuild()" (onSelect)="rebuild()" [inline]="true"></p-calendar>
				</div>
			</div>

			<div class="col-md-6">
				<div class="form-group">
					<div for="assets">Date to</div>
					<p-calendar [(ngModel)]="dateTo" [dateFormat]="apiService.getDateFormat().toLowerCase()" [locale]="en"
						[showIcon]="true" (onBlur)="rebuild()" (onSelect)="rebuild()" [inline]="true"></p-calendar>
				</div>
			</div>

		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label for="assets">Asset ID list, seperate by space or comma</label>
					<input type="text" [(ngModel)]="assets" class="form-control" id="assets" (change)="rebuild()">
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label for="assets">Custom properties to return</label>
					<div class="filters">
						<span *ngFor="let filter of filters; let i = index">
							{{filter}} <span (click)="removeFilter(i)"><i class="fa fa-close"></i></span>
						</span>
					</div>
				</div>
			</div>

			<div class="col-md-6">
			</div>

		</div>
	</div>


	<section>
		<app-dev-curl [api]="api" [path]="this.path"></app-dev-curl>
	</section>
	<!--
	<div>
		<a href="{{this.url}}" target="_blank">{{this.url}}</a>
	</div>
-->
</p-card>

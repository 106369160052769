import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { S3SiteRagAsset } from '../../../../../classes/s3-chart/s3-chart';
import { APIService } from 'app/shared/api.service';
import { Moment } from 'moment';
import { Asset } from 'app/classes/asset';
import * as d3 from 'd3';
import { SetpointsService } from 'app/shared/setpoints.service';
import { AssetService } from 'app/shared/asset.service';
import moment from 'moment';

@Component({
  selector: 'app-asset-rag-report',
  templateUrl: './asset-rag-report.component.html',
  styleUrls: ['./asset-rag-report.component.css']
})
export class AssetRagReportComponent implements OnInit {

  // Array of days to plot (can span many months)
  _days: any[];
  @Input()
  public set days(v: any[]) {
    this._days = v;
    if (this.chartReady) {
      this.buildChartFromDays();
    }
  }

  public get days(): any[] {
    return this._days;
  }

  @Input()
  assetId: number;

  /** @deprecated */
  @Input()
  s3SiteAsset: S3SiteRagAsset;

  _presentation: string = 'grid';
  @Input()
  public set presentation(v: string) {
    this._presentation = v;
    // Don't build if chart hasnt been built yet
    if (this.chartReady) {
      // this.buildChart();
    }
  }
  public get presentation(): string {
    return this._presentation;
  }

  _chartMonths: number;
  @Input()

  public set chartMonths(v: number) {
    if (v) {
      this._chartMonths = v;
      if (this.chartReady) {
        this.buildChart();
      }
    }
  }

  @Input()
  public set focusItem(focusItem: DataItemShared) {
    if (this.asset && (this.asset.id !== focusItem.assetId)) {
      this.hoverItem = focusItem;
      this.manualHover();
    }
  }

  @Output()
  onHover: EventEmitter<DataItemShared> = new EventEmitter();

  asset: Asset;
  setpoints: any;

  idPrefix: string;

  svg: any;
  focus: any;

  g: any;
  width = 600;
  height = 220;
  margin = { top: 20, right: 30, bottom: 20, left: 40 };

  colorScale;
  hostElement;
  x;
  y;
  yWeather;
  xDomain;
  yDomain;

  fromDate: Moment;
  toDate: Moment;
  penWidth = 1;
  dataAccuracy: boolean;
  dateSelected: Moment;

  missingDaysCount: number;

  // The day the user hovers over
  hoverItem: DataItemShared | DataItem;
  chartReady: boolean;

  @ViewChild('svg', { static: true })
  svgRef: ElementRef;

  defaults: { ragStyle: 'line' | 'rect' } = { ragStyle: 'line' };

  constructor(private assetService: AssetService, private setpointService: SetpointsService, private apiService: APIService, private elRef: ElementRef) {
    this.hostElement = this.elRef.nativeElement;
  }

  manualHover() {
    if (!this.focus) {
      // No SVG yet
      return;
    }
    this.focus.style('display', null);
    this.plotOverlay(this.focus, this.hoverItem);
  }

  ngOnInit(): void {
    this.hostElement = this.svgRef.nativeElement;


    if (this.days && this.assetId) {
      // V2
      this.fromDate = this.days[0].date;
      this.toDate = this.days[this.days.length - 1].date;

      this.assetService
        .getAsset(this.assetId)
        .then(asset => {
          this.asset = asset;
          this.setpointService.getSetpointsForAssetAndRange(asset, 2)
            .then(setpoints => {
              this.setpoints = setpoints;
              this.prepareData();
              this.getParts();
            });
        });
    } else {


      const dt = new Date(this.s3SiteAsset.year, this.s3SiteAsset.month - 1, 1);
      this.dateSelected = moment(dt);
      this.assetService
        .getAsset(this.s3SiteAsset.assetId)
        .then(asset => {
          this.asset = asset;
          this.setpointService.getSetpointsForAssetAndRange(asset, 2)
            .then(setpoints => {
              this.setpoints = setpoints;
              this.prepareData();
              this.getParts();
            });
        });
    }
  }

  private removeExistingChartFromParent() {
    d3.select(this.hostElement).select('svg').remove();
  }

  private setChartDimensions() {
    const viewBoxHeight = this.height;
    const viewBoxWidth = this.width;
    // .attr('width', this.presentation === 'grid' ? '100%' : this.width)
    // .attr('height', this.height)
    this.svg = d3.select(this.hostElement).append('svg')
      .attr('preserveAspectRatio', 'none')
      .style('user-select', 'none')
      .attr('viewBox', '0 0 ' + viewBoxWidth + ' ' + viewBoxHeight);
  }

  private addGraphicsElement() {
    this.g = this.svg.append("g")
      .attr("transform", "translate(0,0)");
  }

  // When reading the csv, I must format variables:
  transform(d) {
    return { date: d3.timeParse("%Y-%m-%d")(d.date), value: d.value };
  }

  /**
  * Present data based on zoom
  */
  prepareData() {
    // Check if we have data
    if (this.dateSelected) {
      this.fromDate = this.dateSelected.clone().startOf('month');
      this.toDate = this.dateSelected.clone().endOf('month');
    }
    this.penWidth = 1;
    this.dataAccuracy = true;
  }

  getParts() {
    if (this.s3SiteAsset) {
      this.buildChart();
    } else {
      this.buildChartFromDays();
    }
  }


  buildChartFromDays() {
    const self = this;
    //const totalDays = this.days.length;
    const bisectDate = d3.bisector((d: any) => d.date);

    const ticks = this.days.length <= 31 ? this.days.length : 0;

    // ========= Prepare the data for use in code =========
    const filtered: DataItem[] = this.days.map(day => {
      return {
        date: day.date,
        min: day.totals ? day.totals.min : null,
        max: day.totals ? day.totals.max : null,
        avg: day.totals ? day.totals.avg : null,
        rag: day.totals ? day.totals.rag : null
      };
    });

    const filteredCount = filtered.length;

    this.idPrefix = `#A${this.asset.id}`;
    this.removeExistingChartFromParent();
    this.setChartDimensions();
    this.addGraphicsElement();
    this.prepareData();

    // =========== Prepeare axis =========
    this.xDomain = d3.extent(filtered, (d: any) => d.date);

    // Merge min/max into v for extent
    this.yDomain = d3.extent(
      [
        ...filtered.map(d => { return { v: d.max } }),
        ...filtered.map(d => { return { v: d.min } })
      ], (d: any) => d.v)
      .reverse();

    this.x = d3
      .scaleTime()
      .range([this.margin.left, this.width - this.margin.right])
      .domain(this.xDomain);
    /* NOT WORKING
        this.svg.append("g")
          .attr("transform", `translate(0,${(this.height - this.margin.bottom)})`)
          .call(d3.axisBottom(this.x)
            .ticks(ticks, 0, ticks)
            .tickFormat(d => {
              // console.log(d, moment(d).get('date'));
              if (filteredCount <= 31) {
                return moment(d).get('date');
              }
    
              return moment(d).format('D MMM');
            }));
    */
    this.y = d3.scaleLinear()
      .domain(this.yDomain)
      .range([this.margin.top, this.height - this.margin.top]);

    this.svg.append("g")
      .attr("transform", `translate(${this.margin.left - 1},0)`)
      .call(d3.axisLeft(this.y));

    // ========= Plot data =========

    // Plot average
    // Break up the data into arrays without nulls.
    const averageRanges = [];
    const minMaxRanges = [];

    let averageRange = [];
    let minMaxRange = { min: [], max: [] };
    this.missingDaysCount = 0;
    filtered.forEach(day => {
      if (day.avg === null || isNaN(day.avg)) {
        if (day.date < moment().toDate()) this.missingDaysCount++;
        if (averageRange.length) {
          // Add this block of data
          averageRanges.push(averageRange);
          averageRange = [];
          minMaxRange.min.push({ date: day.date, v: day.min });
          minMaxRanges.push([...minMaxRange.max, ...minMaxRange.min.reverse()]);
          minMaxRange = { min: [], max: [] };
        }
      } else {


        averageRange.push(day);
        minMaxRange.min.push({ date: day.date, v: day.min });
        minMaxRange.max.push({ date: day.date, v: day.max });
      }
    });
    if (averageRange.length) {
      averageRanges.push(averageRange);
      minMaxRanges.push([...minMaxRange.max, ...minMaxRange.min.reverse()]);
    }

    // Plot each min max block
    minMaxRanges.forEach(block => {
      // Strip out any nulls
      block = block.filter(item => item.v !== null);
      // plot
      this.svg
        .append('path')
        .style('fill', '#b0b0b0')
        .datum(block)
        .attr('class', 'minmax')
        .attr('stroke', 'none')
        .attr('opacity', '1')
        .attr('stroke-width', 0)
        .attr('d', d3.line()
          .x((d: any) => this.x(d.date))
          .y((d: any) => this.y(d.v))
          .curve(d3.curveLinear)
        );
    });

    // Plot each average block
    averageRanges.forEach(block => {
      console.log(block);
      this.svg
        .append('path')
        .style('fill', 'none')
        .datum(block)
        .attr('class', 'v')
        .attr('stroke', '#555')
        .attr('opacity', '1')
        .attr('stroke-width', 4)
        .attr('d', d3.line()
          .x((d: any) => this.x(d.date))
          .y((d: any) => this.y(d.avg))
          .curve(d3.curveLinear)
        );

    });

    const columnWidth = ((this.width - this.margin.left - this.margin.right) / this.days.length).toFixed(1);

    // The RAG overlays
    for (let dayOfMonth = 0; dayOfMonth < this.days.length; dayOfMonth++) {
      const telemetry = this.days[dayOfMonth];
      const date = moment(telemetry.date);
      const day = self.setpoints.setpoints
        .find(day => day.weekday === date.weekday());
      const redMax = day.red_max;
      const redMin = day.red_min;
      const amberMax = day.amber_max;
      const amberMin = day.amber_min;

      // Do we have anything over/under amber/red?
      if (filtered.filter(item => item.min !== null).find(item => item.min <= amberMin)) {
        const ragHeight = (this.height - this.margin.top) - this.y(amberMin);
        this.svg
          .append('rect')
          .attr('class', 's3g-rag-amber')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.y(amberMin))
          .attr('width', columnWidth)
          .attr('height', ragHeight);

        this.svg
          .append('rect')
          .attr('stroke', 'orange')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.y(amberMin))
          .attr('width', columnWidth)
          .attr('height', .5);

      }

      if (filtered
        .filter(item => item.max !== null)
        .find(item => item.max >= amberMax)) {
        const ragHeight = this.y(amberMax) - this.margin.top;
        this.svg
          .append('rect')
          .attr('class', 's3g-rag-amber')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.margin.top)
          .attr('width', columnWidth)
          .attr('height', ragHeight);

        this.svg
          .append('rect')
          .attr('stroke', 'orange')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.margin.top + ragHeight)
          .attr('width', columnWidth)
          .attr('height', .5);
      }

      if (filtered
        .filter(item => item.min !== null)
        .find(item => item.min <= redMin)) {
        const ragHeight = (this.height - this.margin.top) - this.y(redMin);
        this.svg
          .append('rect')
          .attr('class', 's3g-rag-red')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.y(redMin))
          .attr('width', columnWidth)
          .attr('height', ragHeight);

        this.svg
          .append('rect')
          .attr('stroke', 'red')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.y(redMin))
          .attr('width', columnWidth)
          .attr('height', .5);

      }

      if (filtered
        .filter(item => item.max !== null)
        .find(item => item.max >= redMax)) {
        const ragHeight = this.y(redMax) - this.margin.top;
        this.svg
          .append('rect')
          .attr('class', 's3g-rag-red')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.margin.top)
          .attr('width', columnWidth)
          .attr('height', ragHeight);

        this.svg
          .append('rect')
          .attr('stroke', 'red')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.margin.top + ragHeight)
          .attr('width', columnWidth)
          .attr('height', .5);
      }
    }

    // ========= HOVER OVERLAY =========

    // cursor lines
    this.focus = this.svg
      .append('g')
      .attr('class', 'focus-overlay')
      .style('display', 'none');

    // True lines
    this.focus.append('line')
      .attr('id', `${self.idPrefix}focusLineX`)

      .attr('class', 's3g-focusLine');

    // Min/Max lines
    this.focus.append('line')
      .attr('id', `${this.idPrefix}focusLineY2`)
      .attr('class', 's3g-focusLine');

    this.focus.append('line')
      .attr('id', `${this.idPrefix}focusLineY1`)
      .attr('class', 's3g-focusLine');

    this.focus.append('line')
      .attr('id', `${this.idPrefix}focusLineY`)
      .attr('class', 's3g-focusLine');

    // Mouse interation

    this.focus.append('circle')
      .attr('id', `${this.idPrefix}focusCircle1`)
      .attr('r', 4);
    this.focus.append('circle')
      .attr('id', `${this.idPrefix}focusCircle2`)
      .attr('r', 4);

    this.focus.append('circle')
      .attr('id', `${this.idPrefix}focusCircle3`)
      .attr('r', 4);


    this.svg.append('rect')
      .attr('class', 's3g-overlay')
      .attr('width', this.width)
      .attr('height', this.height)
      .on('mouseover', () => { self.focus.style('display', null); })
      .on('mouseout', () => { self.focus.style('display', 'none'); })
      .on('mousemove', function (event) {
        const mouse = d3.mouse(this);
        const mouseDate = self.x.invert(mouse[0]);
        const i = bisectDate.left(filtered, mouseDate);
        const d0 = (i > 1) ? filtered[i - 1] : null;
        const d1 = (i <= filtered.length) ? filtered[i] : null;

        if (typeof d0 === "undefined" || typeof d1 === "undefined") {
          // Must have two objects to compare
          return;
        }
        // date value closest to mouse
        const d = mouseDate - +(d0?.date || 0) > +(d1?.date || 0) - mouseDate ? d1 : d0;
        // Store the datapoint for on hover div
        if (!d) {
          return;
        }

        self.hoverItem = d;

        self.manualHover();

        self.onHover.emit({ assetId: self.asset.id, ...d });

      });

    this.chartReady = true;
  }

  /** @deprecated */
  buildChart() {
    const self = this;
    const year = this.s3SiteAsset.year;
    const month = this.s3SiteAsset.month;
    const daysInMonth = this.dateSelected.daysInMonth();
    const bisectDate = d3.bisector((d: any) => d.date);

    // ========= Prepare the data for use in code =========
    const filtered: DataItem[] = this.s3SiteAsset.days.map(day => {
      return {
        date: new Date(year, month - 1, day.day),
        min: day.totals ? day.totals.min : null,
        max: day.totals ? day.totals.max : null,
        avg: day.totals ? day.totals.avg : null,
        rag: day.totals ? day.totals.rag : null
      };
    });

    const f = this.s3SiteAsset.days.map(day => {
      return {
        date: new Date(year, month - 1, day.day),
        day: day.day
      };
    });

    //this.missingDaysCount = daysInMonth - this.s3SiteAsset.days.length;

    this.idPrefix = `#A${this.asset.id}`;
    this.removeExistingChartFromParent();
    this.setChartDimensions();
    this.addGraphicsElement();
    this.prepareData();

    // =========== Prepeare axis =========
    this.xDomain = d3.extent(f, (d: any) => d.date);

    // Merge min/max into v for extent
    this.yDomain = d3.extent(
      [
        ...filtered.map(d => { return { v: d.max } }),
        ...filtered.map(d => { return { v: d.min } })
      ], (d: any) => d.v)
      .reverse();

    this.x = d3
      .scaleTime()
      .range([this.margin.left, this.width - this.margin.right])
      .domain(this.xDomain);
    /* NOT WORKING
        this.svg.append("g")
          .attr("transform", `translate(0,${(this.height - this.margin.bottom)})`)
          .call(d3.axisBottom(this.x)
            .ticks(daysInMonth, 0, daysInMonth)
            .tickFormat(d => {
              // console.log(d, moment(d).get('date'));
              return moment(d).get('date');
            }));
    */
    this.y = d3.scaleLinear()
      .domain(this.yDomain)
      .range([this.margin.top, this.height - this.margin.top]);

    this.svg.append("g")
      .attr("transform", `translate(${this.margin.left - 1},0)`)
      .call(d3.axisLeft(this.y));

    // ========= Plot data =========

    // Plot average
    // Break up the data into arrays without nulls.
    const averageRanges = [];
    const minMaxRanges = [];

    let averageRange = [];
    let minMaxRange = { min: [], max: [] };
    this.missingDaysCount = 0;
    filtered.forEach(day => {
      if (day.avg === null) {
        if (day.date < moment().toDate()) this.missingDaysCount++;
        if (averageRange.length) {
          // Add this block of data
          averageRanges.push(averageRange);
          averageRange = [];
          minMaxRange.min.push({ date: day.date, v: day.min });
          minMaxRanges.push([...minMaxRange.max, ...minMaxRange.min.reverse()]);
          minMaxRange = { min: [], max: [] };
        }
      } else {
        averageRange.push(day);
        minMaxRange.min.push({ date: day.date, v: day.min });
        minMaxRange.max.push({ date: day.date, v: day.max });
      }
    });
    if (averageRange.length) {
      averageRanges.push(averageRange);
      minMaxRanges.push([...minMaxRange.max, ...minMaxRange.min.reverse()]);
    }

    // Plot each min max block
    minMaxRanges.forEach(block => {
      // Strip out any nulls
      block = block.filter(item => item.v !== null);
      // plot
      this.svg
        .append('path')
        .style('fill', '#b0b0b0')
        .datum(block)
        .attr('class', 'minmax')
        .attr('stroke', 'none')
        .attr('opacity', '1')
        .attr('stroke-width', 0)
        .attr('d', d3.line()
          .x((d: any) => this.x(d.date))
          .y((d: any) => this.y(d.v))
          .curve(d3.curveLinear)
        );
    });

    // Plot each average block
    averageRanges.forEach(block => {
      this.svg
        .append('path')
        .style('fill', 'none')
        .datum(block)
        .attr('class', 'v')
        .attr('stroke', '#555')
        .attr('opacity', '1')
        .attr('stroke-width', 4)
        .attr('d', d3.line()
          .x((d: any) => this.x(d.date))
          .y((d: any) => this.y(d.avg))
          .curve(d3.curveLinear)
        );

    });


    const columnWidth = ((this.width - this.margin.left - this.margin.right) / daysInMonth).toFixed(1);

    // The RAG overlays
    for (let dayOfMonth = 1; dayOfMonth < daysInMonth; dayOfMonth++) {
      const date = moment(new Date(year, month - 1, dayOfMonth));
      const day = self.setpoints.setpoints
        .find(day => day.weekday === date.weekday());
      const redMax = day.red_max;
      const redMin = day.red_min;
      const amberMax = day.amber_max;
      const amberMin = day.amber_min;

      // Do we have anything over/under amber/red?
      if (filtered.filter(item => item.min !== null).find(item => item.min <= amberMin)) {
        const ragHeight = (this.height - this.margin.top) - this.y(amberMin);
        this.svg
          .append('rect')
          .attr('class', 's3g-rag-amber')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.y(amberMin))
          .attr('width', columnWidth)
          .attr('height', ragHeight);

        this.svg
          .append('rect')
          .attr('stroke', 'orange')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.y(amberMin))
          .attr('width', columnWidth)
          .attr('height', .5);

      }

      if (filtered
        .filter(item => item.max !== null)
        .find(item => item.max >= amberMax)) {
        const ragHeight = this.y(amberMax) - this.margin.top;
        this.svg
          .append('rect')
          .attr('class', 's3g-rag-amber')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.margin.top)
          .attr('width', columnWidth)
          .attr('height', ragHeight);

        this.svg
          .append('rect')
          .attr('stroke', 'orange')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.margin.top + ragHeight)
          .attr('width', columnWidth)
          .attr('height', .5);
      }

      if (filtered
        .filter(item => item.min !== null)
        .find(item => item.min <= redMin)) {
        const ragHeight = (this.height - this.margin.top) - this.y(redMin);
        this.svg
          .append('rect')
          .attr('class', 's3g-rag-red')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.y(redMin))
          .attr('width', columnWidth)
          .attr('height', ragHeight);

        this.svg
          .append('rect')
          .attr('stroke', 'red')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.y(redMin))
          .attr('width', columnWidth)
          .attr('height', .5);

      }

      if (filtered
        .filter(item => item.max !== null)
        .find(item => item.max >= redMax)) {
        const ragHeight = this.y(redMax) - this.margin.top;
        this.svg
          .append('rect')
          .attr('class', 's3g-rag-red')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.margin.top)
          .attr('width', columnWidth)
          .attr('height', ragHeight);

        this.svg
          .append('rect')
          .attr('stroke', 'red')
          .attr('x', this.x(date.toDate()))
          .attr('y', this.margin.top + ragHeight)
          .attr('width', columnWidth)
          .attr('height', .5);
      }
    }

    // ========= HOVER OVERLAY =========

    // cursor lines
    this.focus = this.svg
      .append('g')
      .attr('class', 'focus-overlay')
      .style('display', 'none');

    // True lines
    this.focus.append('line')
      .attr('id', `${self.idPrefix}focusLineX`)

      .attr('class', 's3g-focusLine');

    // Min/Max lines
    this.focus.append('line')
      .attr('id', `${this.idPrefix}focusLineY2`)
      .attr('class', 's3g-focusLine');

    this.focus.append('line')
      .attr('id', `${this.idPrefix}focusLineY1`)
      .attr('class', 's3g-focusLine');

    this.focus.append('line')
      .attr('id', `${this.idPrefix}focusLineY`)
      .attr('class', 's3g-focusLine');

    // Mouse interation

    this.focus.append('circle')
      .attr('id', `${this.idPrefix}focusCircle1`)
      .attr('r', 4);
    this.focus.append('circle')
      .attr('id', `${this.idPrefix}focusCircle2`)
      .attr('r', 4);

    this.focus.append('circle')
      .attr('id', `${this.idPrefix}focusCircle3`)
      .attr('r', 4);


    this.svg.append('rect')
      .attr('class', 's3g-overlay')
      .attr('width', this.width)
      .attr('height', this.height)
      .on('mouseover', () => { self.focus.style('display', null); })
      .on('mouseout', () => { self.focus.style('display', 'none'); })
      .on('mousemove', function (event) {
        const mouse = d3.mouse(this);
        const mouseDate = self.x.invert(mouse[0]);
        const i = bisectDate.left(filtered, mouseDate);
        const d0 = (i > 1) ? filtered[i - 1] : null;
        const d1 = (i <= filtered.length) ? filtered[i] : null;

        if (typeof d0 === "undefined" || typeof d1 === "undefined") {
          // Must have two objects to compare
          return;
        }
        // date value closest to mouse
        const d = mouseDate - +(d0?.date || 0) > +(d1?.date || 0) - mouseDate ? d1 : d0;
        // Store the datapoint for on hover div
        if (!d) {
          return;
        }

        self.hoverItem = d;

        self.manualHover();

        self.onHover.emit({ assetId: self.asset.id, ...d });

      });

    this.chartReady = true;
  }

  plotOverlay(focus: any, dataItem: DataItem | DataItemShared) {
    let d: any;

    if (this.s3SiteAsset) {
      d = this.s3SiteAsset.days.filter(day => day.day === dataItem.date.getDate());
    } else {
      d = this.days.filter(day => day.date === dataItem.date);
    }

    if (!d.length || !d[0].totals) {
      return;
    }

    // console.log(d);

    d = d[0].totals;

    const rag = ['#46c23b', 'orange', 'red'][d.rag];
    const x = this.x(dataItem.date);

    const yAvg = this.y(d.avg);
    const yMin = this.y(d.min);
    const yMax = this.y(d.max);

    this.plotCircle(focus, `${this.idPrefix}focusCircle1`, x, yAvg, rag);
    this.plotCircle(focus, `${this.idPrefix}focusCircle2`, x, yMin, rag);
    this.plotCircle(focus, `${this.idPrefix}focusCircle3`, x, yMax, rag);

    let el = document.
      getElementById(`${this.idPrefix}focusLineX`);
    el.setAttribute('x1', x);
    el.setAttribute('y1', this.y(this.yDomain[0]));
    el.setAttribute('x2', x);
    el.setAttribute('y2', this.y(this.yDomain[1]));

    el = document.
      getElementById(`${this.idPrefix}focusLineY`);
    el.setAttribute('x1', this.x(this.xDomain[0]));
    el.setAttribute('y1', yAvg);
    el.setAttribute('x2', this.x(this.xDomain[1]));
    el.setAttribute('y2', yAvg);

    el = document.
      getElementById(`${this.idPrefix}focusLineY1`);
    el.setAttribute('x1', this.x(this.xDomain[0]));
    el.setAttribute('y1', yMin);
    el.setAttribute('x2', this.x(this.xDomain[1]));
    el.setAttribute('y2', yMin);

    el = document.
      getElementById(`${this.idPrefix}focusLineY2`);
    el.setAttribute('x1', this.x(this.xDomain[0]));
    el.setAttribute('y1', yMax);
    el.setAttribute('x2', this.x(this.xDomain[1]));
    el.setAttribute('y2', yMax);
  }

  plotCircle(svg: any, id: string, x: number, y: number, colour: string) {
    const el = document.
      getElementById(id);
    el.setAttribute('cx', String(x));
    el.setAttribute('cy', String(y));
    el.setAttribute('fill', colour);
    el.setAttribute('stroke', 'white');
  }
}

export interface DataItem {
  date: Date,
  min: number,
  max: number,
  avg: number,
  rag: number
}

export interface DataItemShared {
  assetId: number,
  date: Date,
  min: number,
  max: number,
  avg: number,
  rag: number
}

<section>
  @defer (when orgs) {
    <div class="table">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Organisation</th>
            <th class="value">Sites</th>
            <th class="value">% Activity</th>
          </tr>
        </thead>

        <tbody>
          @for (org of orgs; track org.orgPartnershipId) {
            <tr class="rag-{{ org.engagementStatus }}" [class.no-activity]="org.activity === 0">
              <td>
                {{ org.title }}
              </td>
              <td class="value">{{ org.siteCount }}</td>
              <td class="value">{{ org.activity }}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  } @placeholder {
    <i class="mdi mdi-loading mdi-spin-x2"></i>
  }
</section>

<section class="left">
  @if (orgFilter) {
    <div class="table org-sites">
      <table class="table table-hover">
        @for (org of orgFilter; track org.id) {
          <tbody class="org" [class.collapsed]="org.isCollapsed">
            <tr (click)="org.isCollapsed = !org.isCollapsed" class="collapsable">
              <th class="org-title" colspan="2">
                <div class="flex">
                  <i class="mdi" [class.mdi-checkbox-blank-circle-outline]="!org.isCompleted" [class.mdi-checkbox-marked-circle]="org.isCompleted"></i>
                  <span class="flex-1">&nbsp;{{ org.title }}</span>

                  <i class="mdi" [class.mdi-chevron-up]="!org.isCollapsed" [class.mdi-chevron-down]="org.isCollapsed"> </i>
                </div>
              </th>
            </tr>
            <tr class="sub-header">
              <td>Site</td>
              <td class="value">Viewed</td>
            </tr>
            @for (site of org.sites; track site.id) {
              <tr class="site" [class.no-views]="site.accessCount === 0">
                <td>{{ site.title }}</td>
                <td class="value">{{ site.accessCount }}</td>
              </tr>
            } @empty {
              <tr>
                <td colspan="2">No sites</td>
              </tr>
            }
          </tbody>
        }
      </table>
    </div>
  } @else {
    <i class="mdi mdi-loading mdi-spin-x2"></i>
  }
</section>

<section class="right">
  @if (siteList) {
    <div class="table org-sites">
      <table class="table table-hover">
        <tbody>
          @for (site of siteList; track site.id) {
            <tr class="site" [class.no-views]="!site.isViewed">
              <td title="{{ site.path }}">
                <span class="mdi" [class.mdi-checkbox-blank-circle-outline]="!site.isViewed" [class.mdi-checkbox-marked-circle]="site.isViewed"></span>
                {{ site.title }}
              </td>
              @if (site.org) {
                <td pTooltip="{{ site.org?.title }} - {{ site.userName }}" tooltipPosition="bottom">
                  <img class="org" src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ site.org.logo }}" alt="logo" /> {{ site.accessedAt | amTimeAgo }}
                </td>
              } @else {
                <td><span pTooltip="No engaged org for site">-</span></td>
              }
            </tr>
          }
        </tbody>
      </table>
    </div>
  } @else {
    <i class="mdi mdi-loading mdi-spin-x2"></i>
  }
</section>

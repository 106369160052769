import { Component, OnInit, signal } from '@angular/core';
import { ComplianceCollection } from 'app/classes/compliance/compliance-collection';
import { ComplianceItem } from 'app/classes/compliance/compliance-item';
import { APIService } from 'app/shared/api.service';
import { sign } from 'crypto';

@Component({
  selector: 'app-org-compliance-home',
  templateUrl: './org-compliance-home.component.html',
  styleUrls: ['./org-compliance-home.component.css']
})
export class OrgComplianceHomeComponent implements OnInit {

  compliance: ComplianceCollection;
  dialogShowing = signal<'New Collection'>(null);
  complianceItem = signal<ComplianceItem>(null);

  constructor(private apiService: APIService) { }

  ngOnInit(): void {
    this.get();
  }

  clickComplianceGroup(item:ComplianceItem) {
    this.complianceItem.set(item);
  }

  get() {
    this.dialogShowing.set(null)
    this.apiService.getComplianceConfig()
      .then(compliance => {
        this.compliance = compliance;
      });
  }

  addToCollection() {

  }


}

<div class="row alarm">
    <div class="col-xs-12">
        <br />
        <!--<p>Token: {{tokenId}}</p>-->
        <p *ngIf="tokenAction">Action: {{tokenAction}}</p>
        <p *ngIf="!tokenResult">Checking token, please wait....</p>

        <app-subs-list *ngIf="tokenAction === 'managesub' && tokenResult" [token]="tokenId"
            [subscriptions]="tokenResult"></app-subs-list>

        <div class="details" *ngIf="alarm">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th width="100px"></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Alarm</td>
                        <td class="title">{{alarm.ruleTitle}}</td>
                    </tr>
                    <tr>
                        <td>Severity</td>
                        <td><span class="severity {{alarm.severity}}">
                                <i class="fa fa-fw"
                                    [ngClass]="{'fa-arrow-down': alarm.severity === 'low','': alarm.severity === 'medium','fa-arrow-up': alarm.severity === 'high'}"></i>

                                {{alarm.severity}}</span></td>
                    </tr>
                    <tr>
                        <td>State</td>
                        <td><span class="state {{alarm.alarmState}}"><i class="fa fa-fw"
                                    [ngClass]="{'fa-check': alarm.alarmState === 'resolved','fa-question': alarm.alarmState === 'new','fa-pause': alarm.alarmState === 'paused', 'fa-thumbs-up': alarm.alarmState === 'acknowledged' }"></i>
                                {{alarm.alarmState}}</span></td>
                    </tr>
                    <tr>
                        <td>Created</td>
                        <td>{{alarm?.alarmCreatedAt | amTimeAgo }} at {{alarm?.alarmCreatedAt | amDateFormat:'HH:MM'}}
                        </td>
                    </tr>
                    <tr>
                        <td>Updated</td>
                        <td>{{alarm?.alarmUpdatedAt | amTimeAgo }} at {{alarm?.alarmUpdatedAt | amDateFormat:'HH:MM'}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4>Timeline</h4>
            <div class="timeline">
                <div *ngFor="let timelineRow of alarm?.timeline;let i = index">
                    <div class="row">
                        <div class="col-xs-3">
                            {{timelineRow.createdAt | amDateFormat:'HH:MM:ss'}}
                        </div>
                        <div class="col-xs-2">
                            <div class="action-{{timelineRow.action}}">
                                <span class="fa-stack">
                                    <i class="fa fa-circle-thin fa-stack-2x"></i>
                                    <i *ngIf="timelineRow.action==='notes'" class="fa fa-pencil fa-stack-1x"></i>
                                    <i *ngIf="timelineRow.action==='email'" class="fa fa-envelope fa-stack-1x"></i>
                                    <i *ngIf="timelineRow.action==='state_change'"
                                        class="fa fa-level-down fa-stack-1x"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-xs-7">
                            <div>{{timelineRow.value}}</div>
                        </div>
                    </div>
                    <div class="row" *ngIf=" alarm?.timeline.length > 0">
                        <div class="col-xs-3">

                        </div>
                        <div class="col-xs-2">
                            <span *ngIf="i <  alarm?.timeline.length - 1" class="fa-stack">
                                <i class="fa fa-long-arrow-up fa-stack-1x"></i>
                            </span>
                        </div>
                        <div class="col-xs-7 timestamp">
                            <small> {{timelineRow.createdAt | amTimeAgo}}</small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="alarm.alarmState !== 'resolved'">
                <div class="col-xs-12">
                    <div class="form-group">
                        <label>Note</label>
                        <textarea [disabled]="pleaseWait" class="form-control" [(ngModel)]="newNote"></textarea>
                    </div>
                </div>

                <div class="col-xs-4 col-sm-3">
                    <button class="btn btn-block"
                        [ngClass]="{'btn-primary':alarm.alarmState === 'new', 'btn-secondary': alarm.alarmState !== 'new'}"
                        (click)="submitNote('acknowledged')"
                        [disabled]="pleaseWait || alarm.alarmState === 'acknowledged'">Acknowledge</button>
                </div>

                <div class="col-xs-4 col-sm-3">
                    <button class="btn btn-block"
                        [ngClass]="{'btn-primary':alarm.alarmState === 'acknowledged', 'btn-secondary': alarm.alarmState !== 'acknowledged'}"
                        (click)="submitNote('paused')"
                        [disabled]="pleaseWait || alarm.alarmState === 'paused'">Pause</button>
                </div>

                <div class="col-xs-4 col-sm-3">
                    <button class="btn btn-block"
                        [ngClass]="{'btn-primary':alarm.alarmState === 'acknowledged' || alarm.alarmState === 'paused', 'btn-secondary': alarm.alarmState !== 'acknowledged' && alarm.alarmState !== 'paused'}"
                        (click)="submitNote('resolved')"
                        [disabled]="pleaseWait || alarm.alarmState === 'resolved'">Resolve</button>
                </div>
                <div class="col-xs-12 col-sm-3 add-note"><button class="btn btn-secondary btn-block"
                        (click)="submitNote()" [disabled]="pleaseWait">Add
                        note</button></div>
            </div>
        </div>

        <div *ngIf="!alarm && tokenResult">
            <p>{{tokenResult}}</p>
        </div>

    </div>
</div>
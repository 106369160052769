import { SettingCollection } from './setting-collection';
import { SettingItem } from './setting-item';
/**
 * @version 1.0.9
 */
export class Theme {
    LOGO_LOCATION = 'https://s3.eu-west-2.amazonaws.com/4d-documents/';

    settingCollection: SettingCollection;

    logo: string;

    constructor(data?: any) {
        this.initialise();
        // Must be a JSON object or an array with items
        if (data && ((Array.isArray(data) && data.length !== 0) || !Array.isArray(data))) {
            this.settingCollection.mergeSettings(data);
            this.setLogo();
        }
    }

    initialise() {
        this.settingCollection = new SettingCollection();
        const defaultSettings = [
            new SettingItem({ setting: 'theme_menu_bg', type: 'string', value: '#323642' }),
            new SettingItem({ setting: 'theme_menu_fg', type: 'string', value: '#d0d0d0' }),
            new SettingItem({ setting: 'theme_menu_fg_active', type: 'string', value: '#ffffff' }),
            new SettingItem({ setting: 'theme_menu_bg_active', type: 'string', value: '#02acee' }),
            new SettingItem({ setting: 'theme_menu_fg_hover', type: 'string', value: '#000000' }),
            new SettingItem({ setting: 'theme_menu_bg_hover', type: 'string', value: '#ffffff' }),
            new SettingItem({ setting: 'theme_menu_border_right', type: 'string', value: null }),
            new SettingItem({ setting: 'theme_menu_logo', type: 'string', value: null }),
            new SettingItem({ setting: 'theme_logo', type: 'string', value: null }),
        ];
        this.settingCollection.setSettings(defaultSettings);
    }

    asObject() {
        return this.settingCollection.asObject();
    }

    updateDOM() {
        const head = document.getElementsByTagName('head')[0];
        const existing = document.getElementById('dynamic-css-org-theme');

        if (this.settingCollection.settings.list.length === 0) {
            // Remove if exists
            if (existing) {
                existing.remove();
            }
            return;
        }
        const logoKey = this.settingCollection.get('theme_menu_logo');
        const borderRightValue = this.settingCollection.get('theme_menu_border_right');
        const logo = logoKey && logoKey.value ?
            `.root-container .theme4d.mainmenu4d > div.logo-placeholder {
                background-image: url(${this.LOGO_LOCATION}${logoKey.value});
                background-size: cover;
            }` :
            ``;

        const borderRight = borderRightValue && borderRightValue.value ? `
            .root-container .theme4d.mainmenu4d {
                border-right: 1px solid ${borderRightValue.value};
            }
        ` : ``;

        const dynamicStyle = `

          ${logo}

          ${borderRight}

          .root-container .theme4d.mainmenu4d {
            background-color: ${this.settingCollection.get('theme_menu_bg').value} !important;
            color: ${this.settingCollection.get('theme_menu_fg').value};
          }

          .root-container .theme4d.mainmenu4d > div {
            background-color: ${this.settingCollection.get('theme_menu_bg').value};  
            color: ${this.settingCollection.get('theme_menu_fg').value};
          }

          .theme4d.mainmenu4d > div > div {
            color: ${this.settingCollection.get('theme_menu_fg').value};
          }

          .root-container .theme4d.mainmenu4d > div:hover {
            background-color: ${this.settingCollection.get('theme_menu_bg_hover').value} !important;
            color: ${this.settingCollection.get('theme_menu_fg_hover').value} !important;
          }

          .root-container .theme4d.mainmenu4d > div:hover > div {
            color: ${this.settingCollection.get('theme_menu_fg_hover').value} !important;
          }

          .root-container .theme4d.mainmenu4d > div.active, .root-container .theme4d.mainmenu4d > div.active > div  {
            background-color: ${this.settingCollection.get('theme_menu_bg_active').value} !important;
            color: ${this.settingCollection.get('theme_menu_fg_active').value} !important;
          }
        `;

        const style = document.createElement('style');
        style.type = 'text/css';
        style.id = 'dynamic-css-org-theme';
        style.appendChild(document.createTextNode(dynamicStyle));

        if (existing) {
            existing.replaceWith(style);
        } else {
            head.appendChild(style);
        }
    }

    serialse() {
        const payload = {
            settings: this.settingCollection.settings.list
        }

        return payload;
    }

    setLogo() {
        const logo = this.settingCollection.get('theme_menu_logo');
        if (!logo) {
            return;
        }
        this.logo = `${this.LOGO_LOCATION}${logo.value}`;
    }
}

<div class="row">
  <app-section-banner section="export" title="Export" [subtitle]="export.table"></app-section-banner>
  <div class="col-sm-12">
    <form>
      <h3>Columns</h3>
      <div class="row">

        <div class="form-group">
          <ng-template ngFor let-column [ngForOf]="export.columns">
            <div class="col-sm-4">
              <app-tick-button theme="none" [title]='column.title' [(selected)]="column.selected"></app-tick-button>
            </div>
          </ng-template>
        </div>
      </div>
      <div [hidden]="true">
      <h3>Options</h3>
      <div class="well well-sm">
        <i class="fa fa-lightbulb-o"></i>&nbsp; The export is emailed to you by default.
      </div>
      <div class="form-group">
        <label for="emails">Email to</label>
        <input name="emails" [(ngModel)]="export.emails" class="form-control" id="emails" aria-xdescribedby="emails" placeholder="Space or comma seperated list of email addresses">
        <small id="emailsHelp" class="form-text text-muted">Optional, space or comma separated list of email addresses.</small>
      </div>
    </div>
    <p>&nbsp;</p>
      <div class="well well-sm">
          <i class="fa fa-lightbulb-o"></i>&nbsp; Exports expire after 1 day.
        </div>
     <!-- <button type="submit" class="btn btn-primary" (click)="submit()">
        <i class="fa fa-envelope"></i> &nbsp;Email export</button>-->

     <button [disabled]="isExporting" type="submit" class="btn btn-primary" (click)="submit()">
        <span *ngIf="!isExporting"><i class="fa fa-fw fa-cloud-download"></i> &nbsp;Start export</span>
        <span *ngIf="isExporting"><i class="fa fa-spin fa-fw fa-refresh"></i> &nbsp;Exporting...</span>
      </button>
        &nbsp;
    </form>
    <p></p>
    <div class="downloaded well" *ngIf="exportKey">
        Export has completed, <a href="https://s3.eu-west-2.amazonaws.com/4d-exporter/{{exportKey}}"><i class="fa fa-fw fa-file-excel-o"></i> click here</a> to download.
    </div>
  </div>
</div>
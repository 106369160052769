import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { OccCollection, OccCollectionDay } from "../../classes/occ-collection";
import { OccupancyService } from 'app/shared/occupancy.service';
import { APIService } from 'app/shared/api.service';
import { Asset } from 'app/classes/asset';
import { AssetService } from 'app/shared/asset.service';

@Component({
  selector: 'app-dialog-occ-collection-update',
  templateUrl: './dialog-occ-collection-update.component.html',
  styleUrls: ['./dialog-occ-collection-update.component.css']
})
export class DialogOccCollectionUpdateComponent implements OnInit {
  original: OccCollection;
  newDays: OccCollectionDay;
  rebuildRequest: boolean = true;

  @Input() collection: OccCollection;
  // True to rebuild
  @Output() onClose = new EventEmitter<boolean>();
  @Output() onUpdated = new EventEmitter<boolean>();

  days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  tabIndex = signal<number>(0);
  assetTabIndex = signal<number>(0);

  isRebuildingAssetsLinked = signal<boolean>(false);
  isGatheringAnalyseRequest = signal<boolean>(false);
  isSaving = signal<boolean>(false);
  asset = signal<Asset>(null);

  constructor(public occupancyService: OccupancyService, private apiService: APIService, private assetService: AssetService) { }

  async rebuildAssetsLinked() {
    this.isRebuildingAssetsLinked.set(true);
    await this.occupancyService.relinkAssets(this.collection.id, null);
    this.isRebuildingAssetsLinked.set(false);
    this.apiService.toastSuccess('Assets re-linked', '');
    this.onUpdated.emit(true);
  }

  async rebuildAll() {
    this.isRebuildingAssetsLinked.set(true);
    this.apiService.toastSuccess('Rebuilding, may take a while', '');
    const assets = this.collection.assets.filter(a => a.dayCount === 0);
    if (assets.length === 0) {
      this.apiService.toastWarn('Run Analyse Assets to use this', '');

      this.isRebuildingAssetsLinked.set(false);

      return;
    }

    for (let index = 0; index < assets.length; index++) {
      const asset = assets[index];
      await this.occupancyService.rebuildHourlyForAsset(asset.id, new Date(), new Date(), true);
      await new Promise(resolve => setTimeout(resolve, 2000));
    }
    this.apiService.toastSuccess('requests done', '');
    this.isRebuildingAssetsLinked.set(false);
  }

  ngOnInit(): void {
    if (this.collection) {
      this.original = this.collection.clone();
    } else {
      // load the annotations
    }

    this.startNewRow();
  }

  async getExtendedData() {
    this.isGatheringAnalyseRequest.set(true);
    const response = await this.occupancyService.getAnalysisForCollection(this.collection.id);
    this.isGatheringAnalyseRequest.set(false);
    if (!response?.counts) {
      return;
    }
    console.log(response);
    this.collection.assets.forEach(asset => asset.dayCount = 0);
    response.counts.forEach(assetCounts => {
      const asset = this.collection.assets.find(a => a.id === assetCounts.asset_id);
      if (asset) {
        asset.dayCount = assetCounts.dayCount;
      }
    });
  }

  deleteRow(index: number) {
    this.collection.days.splice(index, 1);
    this.cancelNewRow();
  }

  cancel() {
    this.collection.days = this.original.days;
    this.collection.title = this.original.title;
    this.collection.isActive = this.original.isActive;
    this.collection.title = this.original.title;
    this.onClose.emit(false);
  }

  update() {
    this.onClose.emit(this.rebuildRequest);
  }

  clickAsset(asset: any) {
    this.assetTabIndex.set(1);
    this.assetService.getAsset(asset.id).then(a => this.asset.set(a));
  }

  clickCreateCollection() {

  }

  clickCollection(event: any) {
    throw new Error('NOT_HANDLED');
  }

  clickAddAnother() {

  }

  startNewRow() {
    this.newDays = this.collection.getSuggestedNewDayRow();
  }

  cancelNewRow() {
    this.startNewRow();
  }

  daysClick(dayRow: OccCollectionDay) {
    this.newDays = dayRow;
  }

  submitNewRow() {
    if (!this.newDays.duration) {
      return;
    }
    if (!this.newDays.id) {
      this.collection.days.push(this.newDays);
    }
    this.startNewRow();
  }

  changed(ev: any) {
    if (this.newDays.maxDuration && (!this.newDays.duration || this.newDays.duration > this.newDays.maxDuration)) {
      this.newDays.duration = this.newDays.maxDuration;
    }
    return true;
  }

  submit() {
    if (!this.collection.days.length || !this.collection.title) {
      return;
    }

    this.isSaving.set(true);
    this.occupancyService.updateCollection(this.collection, this.rebuildRequest).then(() => {
      this.isSaving.set(false);
      this.update();
    });


  }
}

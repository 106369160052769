import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-journey-container',
  templateUrl: './journey-container.component.html',
  styleUrls: ['./journey-container.component.css']
})
export class JourneyContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

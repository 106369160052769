import { Component, signal } from '@angular/core';
import { AssetService } from 'app/shared/asset.service';
import { OfflineIssue } from 'app/shared/offline-issue';

@Component({
  selector: 'app-offline-communication-landing',
  templateUrl: './offline-communication-landing.component.html',
  styleUrl: './offline-communication-landing.component.css'
})
export class OfflineCommunicationLandingComponent {

  offlineIssues = signal<OfflineIssue[]>(null);
  page = signal<number>(1);

  constructor(private assetService: AssetService) {
    assetService.getOfflineCommunicationsForOrg().then(issues => this.offlineIssues.set(issues));
  }

}

<div class="menu" *ngIf="_filterMenu">
	<div>
		<span>{{_filterMenu.title}}</span>
		<span class="pull-right">{{_filterMenu.count}}</span>
	</div>
	<div class="nav-list">
		<div *ngFor="let item of _filterMenu.items" [class.active]="item.id === selectedItem.id" (click)="menuClick(item)"
			pTooltip="{{item.tooltip}}">
			<!--<i *ngIf="item.rag === 'red' || item.rag === 'amber'" class="fa fa-circle x-rag-{{item.rag}}"></i>-->
			<span>{{item.title}}</span>
			<span class="pull-right counter" *ngIf="item.count !== null ">{{item.count}}</span>
		</div>
	</div>
</div>

<p STYLE="margin-bottom: 0.42cm">
  <A NAME="main"></A>
  <B>Information about our use of cookies</B>
</p>
<p STYLE="margin-bottom: 0.42cm">Our website uses cookies to distinguish you from other users of our website. This helps
  us to provide you with a good experience
  when you browse our website and also allows us to improve our site.</p>
<p STYLE="margin-bottom: 0.42cm">
  <A NAME="a445609"></A>
  A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if
  you agree.
  Cookies contain information that is transferred to your computer&rsquo;s hard drive.
</p>
<p STYLE="margin-bottom: 0.42cm">
  <A NAME="a810832"></A>
  We use the following cookies:
</p>
<p STYLE="margin-bottom: 0.42cm; line-height: 100%">
  <B>Strictly necessary cookies.</B> These are cookies that are required for the operation of our website. They include,
  for
  example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing
  services.
</p>
<p STYLE="margin-bottom: 0.42cm; line-height: 100%">
  <B>Analytical/performance cookies.
  </B> They allow us to recognise and count the number of visitors and to see how visitors move around our website when
  they
  are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what
  they are looking for easily.
</p>
<p STYLE="margin-bottom: 0.42cm; line-height: 100%">
  <B>Functionality cookies.
  </B> These are used to recognise you when you return to our website. This enables us to personalise our content for
  you,
  greet you by name and remember your preferences (for example, your choice of language or region).
</p>
<p STYLE="margin-bottom: 0.42cm; line-height: 100%">
  <B>Targeting cookies.
  </B> These cookies record your visit to our website, the pages you have visited and the links you have followed. We
  will
  use this information to make our website and the advertising displayed on it more relevant to your interests. We may
  also
  share this information with third parties for this purpose.
</p>
<p STYLE="margin-bottom: 0.42cm">You can find more information about the individual cookies we use and the purposes for
  which we use them in the table below:</p>
<table width="100%" style="border: 1px solid #a0a0a0">
  <thead>
    <tr VALIGN=top>
      <th WIDTH=80>
        <p>
          <B>Cookie</B>
        </p>
      </th>
      <th WIDTH=135>
        <p>
          <B>Name</B>
        </p>
      </th>
      <th>
        <p>
          <B>Purpose</B>
        </p>
      </th>
    </tr>
  </thead>
  <tr VALIGN=top>
    <td WIDTH=80>
      <p>user</p>
      <p>
        <br>
      </p>
    </td>
    <td WIDTH=135>
      <p>Login user details</p>
      <p>
        <br>
      </p>
    </td>
    <td>
      <p>This cookie enables us to store information about your login, and organisation, allowing us to customise our
        site,
        recognise you when you return to our site and allows you to use our site in a way that makes your browsing
        experience
        more convenient.
      </p>
    </td>

  </tr>
  <tr VALIGN=top>
    <td WIDTH=80>
      <p>session</p>
    </td>
    <td WIDTH=135>
      <p>Login session tokens</p>
    </td>
    <td>
      <p>This cookie stores the 24 hour session token, essential for accessing secure information within the
        organisation.
      </p>
    </td>

  </tr>
  <tr VALIGN=top>
    <td WIDTH=134>
      <p>modules</p>
    </td>
    <td WIDTH=135>
      <p>Modules available</p>
    </td>
    <td WIDTH=135>
      <p>This cookie caches the users module access.</p>
    </td>

  </tr>
</table>

<p STYLE="margin-bottom: 0.42cm">
  <br>
  <br>
</p>
<p STYLE="margin-bottom: 0.42cm">Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
<p STYLE="margin-bottom: 0.42cm">
  <A NAME="a780095"></A>
  You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some
  cookies.
  However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.
</p>

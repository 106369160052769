import { Component, OnInit, Output, EventEmitter, Input, signal } from '@angular/core';
import { RuleCondition } from "../../classes/rule-service/rule-condition";
import { Site } from "../../classes/site";
import { Gateway } from "../../classes/gateway";

@Component({
  selector: 'app-rule-condition-picker',
  templateUrl: './rule-condition-picker.component.html',
  styleUrls: ['./rule-condition-picker.component.css']
})
export class RuleConditionPickerComponent implements OnInit {

  showDialog = signal(false);

  @Output()
  submit: EventEmitter<RuleCondition> = new EventEmitter<RuleCondition>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onDeleteCondition: EventEmitter<number> = new EventEmitter<number>();

  _modifyCondition: RuleCondition;

  @Input()
  public set modifyCondition(value: RuleCondition) {
    if (value) {
      this._modifyCondition = value;
    }
  }

  action: IRuleConditionPickerAction
  details: string;

  @Input()
  defaultGateway: Gateway;

  @Input()
  defaultSite: Site;

  _config: IRuleConditionPickerConfig;

  @Input()
  public set config(config: IRuleConditionPickerConfig) {
    if (!config) {
      return;
    }

    this._config = config;
    this.index = config.index;
    this.defaultGateway = config.gateway;
    this.defaultSite = config.site;
    this._modifyCondition = config.ruleCondition;
    if (config.ruleCondition?.id || config.ruleCondition?.id === 0) {
      this.action = { action: 'modify', type: config.ruleCondition.rightAsset?.asset?.id ? 'asset-to-asset' : null }
      this.editRuleCondition();
    }
  }

  index: number;

  constructor() { }

  ngOnInit() {
  }

  newCondition(typeOfCondition: string) {
    this.action = { action: 'new', type: typeOfCondition };
    this._modifyCondition = null;
    this.index = null;
    this.editRuleCondition();
  }

  deleteCondition() {
    this.showDialog.set(false);
    console.log('delete condition ', this.index);
    this.onDeleteCondition.emit(this.index);
  }

  dialogClose($event: any) {
    this.showDialog.set(false);
    if (this._modifyCondition) {
      this.submit.emit(this._modifyCondition);
    } else {
      this.cancel.emit();
    }
  }

  editRuleCondition() {
    this.showDialog.set(true);
  }

  newRuleConditionSubmitted(ruleCondition: RuleCondition) {
    this.action = null;
    this.showDialog.set(false);
    this.submit.emit(ruleCondition);
    this._modifyCondition = null;
  }

  newRuleConditionCancelled() {
    this.action = null;
    this.showDialog.set(false);
    this._modifyCondition = null;
    this.cancel.emit();
  }
}

export interface IRuleConditionPickerConfig {
  gateway: Gateway;
  site: Site;
  ruleCondition: RuleCondition;
  action?: IRuleConditionPickerAction;
  index?: number;
}

export interface IRuleConditionPickerAction {
  action: 'new' | 'modify';
  type: 'asset-to-asset' | string;
}

import { Help } from "../../../classes/help";

export class RulePackageHelp extends Help {

    constructor() {
        super();

        this.table = 'rulePackage';

        this.content = {
            ActiveHours: {
                title: 'Active Hours',
                body: [
                    'By default, rules are checked 24 hours a day, 7 days a week.',
                    'Click the "Restrict day and time" button to restrict when this rule is checked.']
            },
            autoResolve: {
                title: 'Auto Resolve Alarm',
                body: [
                    'If set to "Always Resolve", the alarm generated by this rule will automatically resolve if the conditions are no longer met.',
                    '[break]',
                    'If set to "No, the alarm is manually resolved", the alarm generated will never resolve automatically and can only be resolved by going into the alarm and manually resolving.'
                ]
            },
            Conditions: {
                title: 'Conditions',
                body: [
                    'A rule will need at least one condition.',
                    'These conditions are checked when a value is received by the 4D platform.',
                    '[break]',
                    '* All of the conditions must be met to trigger an alarm.']
            },
            instructions: {
                title: 'Instructions',
                body: [
                    'Instructions are optional, and are included within notification emails and also when viewing alarms.']
            },
            Notifications: {
                title: 'Notifications',
                body: [
                    'By default, rules will not send notifications when they alarm.',
                    'To send notifications, emails currently, enter the recipients email addresses.',
                    '[break]',
                    '* The recipient does not need an account on 4D, they receive a unique token that allows them to interact with the alarm generated.']
            },
            Options: {
                title: 'Alarm Options',
                body: [
                    'Configure alarm options.',
                    '[break]',
                    '* How the generated alarm is resolved',
                    '* How long to delay the alarm going into the "new" state and notifying users']
            },
            severity: {
                title: 'Severity',
                body: [
                    'Set the severity to convey the importance of the alarms this rule generates.',
                    'By setting this to high, the emails importance flag is set on notifications.'
                ]
            },
            SiteNotificationGroup: {
                title: 'Site Notification Group',
                body: [
                    'Include users selected as part of the "site notification users" group.',
                    'These can be configured by selecting a site from the Org navigation panel to the left.',
                    '[break]',
                    '* This switch allows you to control the users on a per-site basis, changing the users for the site changes them on all rules for that site automatically. '
                ]
            },
            title: {
                title: 'Rule Title',
                body: [
                    'Rules must include a title, with a maximum of 50 characters.',
                    '[break]',
                    '* Rule titles become the title of any alarms they generate. '
                ]
            },
            triggerAfter: {
                title: 'Delay Alarm',
                body: [
                    'By default, rules trigger notifications and perform actions as soon as the conditions are met.',
                    'Setting a value above zero in the rule delay field, the rule generates a "delayed" alarm, this does not send any notifications until the amount of minutes entered has passed.',
                    '[break]',
                    '* Rules with delayed alarms have an orange marker - <i class="fa fa-square orange"></i> ',
                    '* Delayed alarms have a tile on the dashboard',
                    '* Delayed alarms can be resolved at any time, the timer will restart when the conditions are met on the next received telemetry']
            }
        };
    }

}
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tick-button',
  templateUrl: './tick-button.component.html',
  styleUrls: ['./tick-button.component.css']
})
export class TickButtonComponent implements OnInit {

  _selected: boolean;

  @Input()
  public get selected() : boolean {
    return  this._selected;
  }

  public set selected(v : boolean) {
    console.log('set', v);
    this._selected = v;
    this.selectedChange.emit(this._selected);
  }

  @Input()
  theme: string = '';
  
  @Output()
  selectedChange:EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input()
  title: string;

  constructor() { }

  ngOnInit() {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Site } from 'app/classes/site';
import { APIService } from 'app/shared/api.service';
import { DateService } from 'app/shared/date.service';
import { ExportService } from 'app/shared/export.service';
import { DialogService } from 'primeng/dynamicdialog';
import moment from 'moment';

@Component({
  selector: 'app-spd-monthly',
  templateUrl: './spd-monthly.component.html',
  styleUrls: ['./spd-monthly.component.css'],
  providers: [DialogService]
})
export class SpdMonthlyComponent implements OnInit {

  @Input()
  site: Site;

  summary: DashboardMonthly;

  period: 'this' | 'last' = 'this';
  asset: any;
  daysInMonth: number;
  isLoading: boolean;
  dtAsText: string;
  df: any;
  dt: any;

  constructor(private exportService: ExportService, private apiService: APIService, private dateService: DateService, public dialogService: DialogService) { }

  ngOnInit(): void {
    console.log('MONTHLY-INIT');
    this.setDates(this.period);
  }

  exportDialog() {

    this.exportService.exportOccupancyMonthly(this.summary);

    /*
    const dialogRef = this.dialogService.open(DialogOccupancyExportComponent, {
      header: `Export Site  ${this.site.title}`,
      width: '80%',
      data: { site: this.site }
    });
    dialogRef.onClose.subscribe(() => {
    });
    */
  }

  printPage() {
    window.print();
  }

  get(df, dt) {
    this.isLoading = true;
    this.apiService.postQuery({ t: 'sites', id: this.site.id, df, dt, action: 'site-floorplan-dashboard-summary' })
      .then(r => {
        console.log('MONTHLY-GOT DATA');
        this.isLoading = false;
        this.summary = new DashboardMonthly(r);
      });
  }

  setDates(period: 'this' | 'forward' | 'backward' | 'last') {
    let df, dt;
    //this.period = period;

    df = this.df || null;
    dt = this.dt || null;


    switch (period) {
      case 'last':
        df = moment(df).subtract(1, 'month').startOf('month').startOf('day');
        dt = moment(dt).subtract(1, 'month').endOf('month').endOf('day');
        break;
      case 'forward':
        df = moment(df).add(1, 'month').startOf('month').startOf('day');
        dt = moment(dt).add(1, 'month').endOf('month').endOf('day');
        if (df > moment()) {
          // Don't go into future months
          df = moment().isoWeekday(1).startOf('isoWeek').startOf('month');
          dt = moment();
        }
        break;
      case 'this':
        df = moment().isoWeekday(1).startOf('isoWeek').startOf('month');
        dt = moment();
        break;
      default:
        return;
    }
    this.df = df;
    this.dt = dt;
    this.dtAsText = moment(df).format('dddd') + ' ' + DateService.date(df.toDate());
    df = this.apiService.dbDate(df.toDate());
    dt = this.apiService.dbDate(dt.toDate());
    console.log('MONTHLY-SETDATES', df, dt);
    this.get(df, dt);
  }

  adc(asset, day: number, week: any) {
    const df = week.d;
    const dt = week.d;

    this.asset = { asset, day, df, dt };
  }

  onMouseEnter(d) {
    this.summary.assets.forEach(a => a.days.forEach(d => d.hover ? d.hover = false : null));
    this.summary.assets.forEach(a => {
      a.days[d.day].hover = true;
    });
  }
}

export class DashboardMonthly {
  df: Date;
  dt: Date;
  siteId: number;
  totalPerc = 0;
  daysInMonth: number;
  header: { days: any[] };

  assets: {
    id: number;
    floor: number,
    floorTitle: string,
    occDays: number[],
    occFullUtilisationMins: number,
    shapeId: number,
    shapeTitle: string,
    title: string,
    occId: number,
    days: { p: number, m: number, c: "grey" | "green" | "lightgreen" | "yellow" | "orange" | "red", h: string, d: Date, hover: boolean }[],
    values: { asset_id: number, value: number, createdAt: Date }[]
  }[];

  values: { asset_id: number, value: number, createdAt: Date }[]

  constructor(data: IDashboardMonthlyConstruct) {

    this.df = moment(new Date(data.master.df), 'YYYY-MM-DD').toDate();
    this.dt = moment(new Date(data.master.dt), 'YYYY-MM-DD').toDate();
    this.siteId = data.master.siteId;

    data.assets.sort((a, b) => {
      if (a.floor === b.floor) {
        return a.title > b.title ? 1 : -1;
      } else {
        return a.floor > b.floor ? 1 : -1;
      }
    });

    this.daysInMonth = moment(this.df).daysInMonth();

    console.log('MONTHLY-SETTING HEADER', this.df, this.daysInMonth);
    this.header = { days: [] };
    for (let day = 0; day < this.daysInMonth; day++) {
      const m = moment(this.df).add(day, 'days');
      const dow = m.day() === 0 ? 6 : m.day() - 1;
      const dd = m.format('dd');
      this.header.days.push({ day, dow, m, dd, f: m.format('DD/MM/YY') });
    }

    console.log('MONTHLY-SETTING HEADER DONE', JSON.stringify(this.header));
    this.assets = data.assets.map(a => {

      return {
        id: a.id,
        title: a.title,
        floor: a.floor,
        floorTitle: a.sfTitle,
        shapeId: a.shapeId,
        shapeTitle: a.shapeTitleUser,
        occId: a.occId,
        occDays: JSON.parse(a.occDays),
        occFullUtilisationMins: a.occFullUtilisationMins,
        days: this.header.days.map(m => 0)
      };
    });

    this.values = data.values.map(v => {
      const createdAt = new Date(v.createdAt);

      return {
        asset_id: v.id,
        createdAt,
        value: +v.value
      };
    });
    let count = 0;
    let perc = 0;


    this.assets.forEach(a => {
      const collection = a.occId && data.occupancyCollection[a.occId];

      a.values = this.values.filter(v => v.asset_id === a.id).map(a => {
        return {
          asset_id: a.asset_id,
          value: a.value,
          createdAt: new Date(a.createdAt)
        };
      });

      if (collection) {
        // Clean up the values
        const values = [];
        a.values.forEach(asset => {
          const minutes = asset.value;
          const d = asset.createdAt;
          const dow = d.getDay() === 0 ? 6 : d.getDay() - 1;

          const cDayRow = collection.items.find(item => item.days[dow]);

          if (!cDayRow) {
            // Not interested in Day of Week
            return false;
          }

          const response = this.isDateBetweenStringTimes(d, cDayRow.duration, cDayRow.startTime, cDayRow.endTime)

        });
      }

      a.values.forEach(v => {
        let h: any = '';
        if (v.value > 60) {
          h = parseInt(String(v.value / 60));
          h = `${h} hours ${(v.value - (h * 60))} minutes`;
        } else {
          h = `${v.value} minutes`;
        }

        const dow = v.createdAt.getDay() === 0 ? 6 : v.createdAt.getDay() - 1;
        const dateInMonth = v.createdAt.getDate();
        // Only process if the room is marked as having the day of week in use
        if (a.occDays[dow]) {
          const shapePerc = (100 / a.occFullUtilisationMins);
          let value = +(Number(String(v.value * shapePerc)).toFixed(0));
          if (value > 100) {
            value = 100;
          }
          perc += value;
          count++;
          const colour = value > 80 ? 'green' : value > 60 ? 'lightgreen' : value > 40 ? 'yellow' : value > 20 ? 'orange' : 'red';

          a.days[dateInMonth - 1] = { p: value, m: v.value, c: colour, h, d: v.createdAt, hover: false };
        } else {
          a.days[dateInMonth - 1] = { p: null, m: v.value, c: 'grey', h, d: v.createdAt, hover: false };
        }
      });
    });
    this.totalPerc = +(Number(String(perc / count)).toFixed(0));
  }

  /**
   * Get the date / duration tuned to the restrictions
   * 
   * @param checkDate 
   * @param duration 
   * @param startTime 
   * @param endTime 
   * @returns {dt:Date, duration: number}
   */
  isDateBetweenStringTimes = (checkDate, duration, startTime, endTime) => {
    const startDate = new Date(checkDate);
    const endDate = new Date(checkDate);

    // Parse the string times into hours and minutes
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);

    // Set the date object to the given date and time
    startDate.setHours(startHour, startMinute, 0, 0);

    // Set the date object to the given date and end time
    endDate.setHours(endHour, endMinute, 0, 0);

    // Compare the timestamps to check if the date falls between the start and end times



    return checkDate.getTime() >= startDate.getTime() && checkDate.getTime() <= endDate.getTime();
  };
}


/*
export interface IDashboardMonthlyConstruct {
  assets: { floor: number, id: number, occDays: string, occFullUtilisationMins: number, sfTitle: string, shapeId: number, title: string, shapeTitleUser: string }[];
  master: { df: Date, dt: Date, siteId: number };
  occupancyCollection: { [key: number]: { items: { days: number[], duration: number, endTime: string, startTime: string }, title: string } };
  values: { id: number, createdAt: Date, value: number }[];
}
*/

export interface IDashboardMonthlyConstruct {
  assets: any;
  master: { df: Date, dt: Date, siteId: number };
  occupancyCollection: { [key: number]: { items: { days: boolean[], duration: number, endTime: string, startTime: string }[], title: string } };
  values: { id: number, createdAt: Date, value: number }[];
}

<article>
  <!--
  <div class="installation">
    <div class="card-container">
      <div [routerLink]="[4]" class="card">
        <div>
          <img src="https://4d-training.s3.eu-west-1.amazonaws.com/images/36-sites-feature-img-490x300.png" />
        </div>
        <p>Installation</p>
        <p>This course is a refresher for anyone who has attended the 1-day on-site Installation Training day. It
          provides some background information on 4D and explains how to install, configure and on-board the 4D
          monitoring equipment. </p>
      </div>
    </div>
  </div>
-->
  <div>
    <div [routerLink]="[3]" class="card">
      <div>
        <img src="https://4d-training.s3.eu-west-1.amazonaws.com/images/course_3.png" />
      </div>

      <p>4D Portal version 3.0</p>
      <p>Training for anyone using the 4D Portal to view the live data feeds, run historical reports, and optionally
        configure features such as Set Points, Rules and Alarms.</p>
    </div>
  </div>
</article>

<table>
  <thead>
    <tr>
      <th>Name</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users">
      <td>{{user.name}}</td>
        <td>-</td>
    </tr>
  </tbody>
</table>

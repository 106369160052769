<section class="no-print fourd-design content-container">
  <div class="flex" style="align-items: baseline">
    <h2 class="flex-1">Indoor Air Quality</h2>
    <app-button size="small" label="Help" (click)="showHelp.set('insight-aq')"></app-button>
  </div>
  <p>Insights into buildings indoor air quality, analysing sensor data from multiple gateways.</p>
</section>

@defer (when buildings()) {
  <section class="fourd-design content-container mb-1">
    <div class="no-print">
      <app-toolbar>
        <div class="left">
          @if (isGettingBuilding()) {
            <app-button [label]="building()?.title || 'Please wait'" [isDisabled]="true" [isMuted]="true" icon="mdi mdi-loading mdi-spin-x2" iconPos="right" />
          } @else {
            <app-button [label]="building()?.title || 'Select Building'" (click)="dialogOpen.set('buildings')"> </app-button>
            @if (buildingForReport()?.aqConfig) {
              <app-button [label]="buildingForReport().aqConfig.title + ' RAG'" (click)="chooseRAGClick()" class="ml-1" icon="mdi mdi-traffic-light-outline" />
            }
            @if (buildingForReport()?.openingHours.isSet) {
              <app-button
                label="Update Building Hours"
                (click)="isEditingExistingHours.set(!isEditingExistingHours())"
                class="ml-1"
                icon="mdi mdi-rotate-3d-variant" />
            }
          }
        </div>
        <div class="right">
          <app-button label="Configure RAGS" (click)="clickConfigureRags()" icon="mdi mdi-cog-outline"></app-button>
        </div>
      </app-toolbar>
      @if (dialogOpen() === 'buildings') {
        <app-dialog header="Select a building" [canScroll]="true" (onClose)="buildingChange(null)">
          <table class="table table-hover" aria-label="Buildings">
            <thead>
              <tr>
                <th colspan="1">Building</th>
                <th colspan="3">Address</th>
              </tr>
            </thead>
            <tbody>
              @for (building of previousBuildings(); track building.id) {
                <tr class="not-selectable">
                  <td colspan="4">Previous building</td>
                </tr>
                <tr (click)="buildingChange({ id: building.id, title: building.title })">
                  <td>{{ building.title }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr class="not-selectable">
                  <td colspan="4">All Buildings</td>
                </tr>
              }
              @for (building of buildings(); track building.id) {
                <tr (click)="buildingChange(building)">
                  <td>{{ building.title }}</td>
                  <td>{{ building.site.address.addressTown }}</td>
                  <td>{{ building.site.address.addressCounty }}</td>
                  <td>{{ building.site.address.addressPostcode }}</td>
                </tr>
              }
            </tbody>
          </table>
        </app-dialog>
      }
    </div>

    @if (buildingForReport()) {
      <div class="mt-1 no-print">
        <table class="table" aria-label="Buildings">
          <thead>
            <tr>
              <th>Building</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ buildingForReport().title }}</td>
              <td>{{ buildingForReport().site.address.address1 }}</td>
            </tr>
          </tbody>
        </table>

        <div>
          <section>
            @if (buildingForReport().openingHours.isSet) {
              @if (isEditingExistingHours()) {
                <app-site-hours [buildingId]="buildingForReport().id" view="edit" (onSave)="isEditingExistingHours.set(false)" />
              } @else {
                <table class="table can-click">
                  <thead>
                    <tr>
                      <th>Monday</th>
                      <th>Tuesday</th>
                      <th>Wednesday</th>
                      <th>Thursday</th>
                      <th>Friday</th>
                      <th>Saturday</th>
                      <th>Sunday</th>
                    </tr>
                  </thead>
                  <tr>
                    <td *ngFor="let day of buildingForReport().openingHours.hours">
                      <span *ngIf="!day.isClosed">{{ day.from }} - {{ day.to }}</span>
                      <span *ngIf="day.isClosed">Closed</span>
                    </td>
                  </tr>
                </table>
              }
            } @else {
              <div>
                <p>No hours set for the building, please update the building hours to continue.</p>
                <app-site-hours [buildingId]="buildingForReport().id" view="edit" (onSave)="hoursUpdated()" />
              </div>
            }
          </section>
        </div>
      </div>
    } @else {}

    @if (buildingForReport()?.openingHours.isSet && collectionForAssets()) {
      <section class="no-print">
        <h1>Areas</h1>
        <div>
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Area</th>
                <th>Assets</th>
              </tr>
            </thead>
            <tbody>
              @for (g of collectionForAssets().collections; track g) {
                <tr (click)="g.selected = !g.selected" [class.checked]="g.selected">
                  <td>
                    <i class="mdi" [class.mdi-checkbox-marked-outline]="g.selected" [class.mdi-checkbox-blank-outline]="!g.selected"></i>
                    {{ g.title }}
                  </td>
                  <td>
                    @if (g.assets.length < 10) {
                      @for (a of g.assets; track a.id) {
                        <app-chip [canClick]="false" [noColour]="true" size="small" [label]="a.title"></app-chip>
                      }
                    } @else {
                      {{ g.assets.length }} Assets
                    }
                  </td>
                </tr>
              } @empty {
                <tr>
                  <td colspan="2">
                    No areas defined for this building,
                    <a [routerLink]="['/org', 'sites', buildingForReport().site.id]"
                      >create an office air quality collection for {{ buildingForReport().site.title }}</a
                    >.
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        @if (!gateways) {
          <app-theme-page-section label="Calendar" class="mt-1">
            <p-calendar view="month" [(ngModel)]="month" dateFormat="smmm yyyy" [inline]="true" [maxDate]="today"></p-calendar>
            <div (click)="useOrgLogo = !useOrgLogo" class="can-click mt-1">
              <span class="mdi" [class.mdi-checkbox-marked-outline]="useOrgLogo" [class.mdi-checkbox-blank-outline]="!useOrgLogo"></span> Use logo for
              {{ org.title }}
            </div>
          </app-theme-page-section>
        }
      </section>
    } @else {
      @if (buildingForReport()) {
      } @else {
        <!-- no building report -->
      }
    }

    @if (buildingForReport()?.openingHours.isSet) {
      <section class="no-print actions pb-1">
        <div class="mt-1 mb-1 text-center">
          <app-button
            (click)="generate()"
            [isCTA]="true"
            [isDisabled]="runReport && !reportWasGenerated"
            [label]="!runReport || reportWasGenerated ? 'Generate Report' : 'Generating report...'"
            icon="mdi mdi-chart-areaspline-variant" />

          @if (reportWasGenerated) {
            <app-button (click)="printPage()" label="Print Report" icon="mdi mdi-printer" />
          }
        </div>
        <div *ngIf="reportWasGeneratedAt" class="text-right gray">Report generated: {{ reportWasGeneratedAt | amDateFormat: 'DD MMM YYYY HH:mm' }}</div>
      </section>
    }

    @if (runReport) {
      <section>
        <div class="mt-1">
          @if (month && buildingForReport()) {
            <app-aq-collections-report
              [building]="buildingForReport()"
              [startDate]="month"
              [collections]="selectedCollectionsAssets"
              [useOrgLogo]="useOrgLogo"
              (onReportGenerated)="onReportWasGenerated($event)"
              [clearCache]="clearCache" />
          }
        </div>
      </section>
    }
  </section>
} @placeholder {
  <div class="content-container">
    <i class="mdi-loading mdi mdi-spin-x2"></i>
  </div>
}

@if (showHelp()) {
  <app-dialog-help [id]="showHelp()" (onClose)="showHelp.set(null)"></app-dialog-help>
}

@if (dialogOpen() === 'configure') {
  <app-dialog [dimensions]="{ width: 900, height: 570 }" header="Configure RAGS" [canScroll]="true" (onClose)="dialogOpen.set(null)">
    <app-aq-configure-rag></app-aq-configure-rag>
  </app-dialog>
}
@if (dialogOpen() === 'aq-select') {
  <app-dialog [dimensions]="{ width: 500, height: 370 }" header="Select a RAG configuration" [canScroll]="true" (onClose)="dialogOpen.set(null)">
    <table class="table table-hover">
      <thead>
        <tr>
          <td>RAG Configuration</td>
        </tr>
      </thead>
      <tbody>
        @for (item of aqList(); track item.id) {
          <tr (click)="selectAQConfig(item)">
            <td>{{ item.title }}</td>
          </tr>
        }
      </tbody>
    </table>
  </app-dialog>
}

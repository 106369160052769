import { Component, OnInit } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { StoreService } from 'app/shared/store.service';
import { PlatformModuleService } from 'app/shared/platform-module.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  flashMessage: boolean;
  messageColour: string;
  message: string;
  email: string;
  password: string;
  attempts = 0;

  constructor(private apiService: APIService, private storeService: StoreService, private router: Router) {
  }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { SchematicsService } from "../../shared/schematics.service";
@Component({
  selector: 'app-schematics-landing',
  templateUrl: './schematics-landing.component.html',
  styleUrls: ['./schematics-landing.component.css']
})
export class SchematicsLandingComponent implements OnInit {

  //schematics: Schematic[] = [];

  //selectedSchematic: Schematic;

  constructor(private schematicsService: SchematicsService) { }

  ngOnInit(): void {
    /*
        this.schematicsService.getAll()
        .then( s =>  {
          this.schematics = s;
          this.selectedSchematic = s[0];
        } );*/
  }
  /*
    schematicClick(schematic: Schematic) {
      this.selectedSchematic = schematic;
    }
    */
}

<article *ngIf="visualisationItem; else loading">
	<header> {{visualisationItem.title}} </header>
	<main>

		<app-visualise-data [visualisationItem]="visualisationItem"></app-visualise-data>

	</main>
	<footer></footer>
</article>

<ng-template #loading>
	<i class="fa fa-fw fa-spin fa-refresh"></i>
</ng-template>

export class ElogbooksSite {
	id: number;
	name: string;
	reference: string;
	poRequired: boolean;
	pcdExclude: boolean;
	p2pExclude: boolean;
	reactiveJobApprovalRequired: boolean;
	plannedJobApprovalRequired: boolean;
	area: number;
	activeAt: Date;
	isActive: boolean;
	links: any;
	contactInformation: any;

	constructor(data: any) {
		this.id = data.id;
		this.name = data.name;
		this.reference = data.reference;
		this.poRequired = data.poRequired;
		this.p2pExclude = data.p2pExclude;
		this.pcdExclude = data.pcdExclude;
		this.reactiveJobApprovalRequired = data.reactiveJobApprovalRequired;
		this.plannedJobApprovalRequired = data.plannedJobApprovalRequired;
		this.area = data.area;
		this.activeAt = new Date(data.activeAt);
		this.isActive = data.isActive;
		this.links = data.links;
		this.contactInformation = data.contactInformation;
	}
}


<article class="mb-2" *ngIf="isReady">
	<section class="mb-1" class="admin-info">
		<div class="no-print">
			<span>
				You are in admin mode, this report is currently
				<span *ngIf="insight.stage==='new'" class="red">PRIVATE</span>
				<span *ngIf="insight.stage==='done'" class="green">public</span>
			</span>
		</div>
	</section>
	<section class="no-print">
		<div>
			<button icon="mdi mdi-phone-rotate-landscape" class="p-button-secondary" pButton label="Switch view" (click)="isPortraitView=!isPortraitView"></button>
		</div>
	</section>
	<section class="view landscape" [class.active]="!isPortraitView" *ngIf="isReady && !isPortraitView">
		<app-insights-as-user [insight]="insight"></app-insights-as-user>
	</section>
	<section class="view portrait" [class.active]="isPortraitView" *ngIf="isReady && isPortraitView">
		<div *ngFor="let id of assetIds" class="mt-1 charts" style="break-inside: avoid;">
			<app-theme-page-section [toolbar]="toolbars[id]" (onToolbarClick)="toolbarClick(id, $event)" [label]="(insight.telemetry[id]?.asset.title || assetTitles[id])  + ' ' + (insight.telemetry[id]?.asset.location || '') + ' [ MIN: ' + (insight.telemetry[id]?.chartCompleted?.dataMin || 'n/a') + ',  MAX: ' + (insight.telemetry[id]?.chartCompleted?.dataMax || 'n/a') + ' ] UOM:' + (assetTypes[insight.telemetry[id]?.asset.assetType_id].uom || '') ">
				<div class="chart" *ngIf="insight.telemetry[id]?.telemetry.length">
					<app-d3-chart [asset]="insight.telemetry[id].asset" [hasToClickToInteract]="true" [canAnnotate]="true" [canZoom]="false" height="200" [telemetry]="insight.telemetry[id].telemetry" [showLegend]="true" [minMaxFromData]="true" [weather]="weather" [pins]="alarmPins[id]" [annotations]="telemetryAnnotations[id]" (chartCompletedMinMax)="chartCompleted(id, $event)">
					</app-d3-chart>
				</div>
				<div class="chart" *ngIf="!insight.telemetry[id]?.telemetry.length">
					<app-info-panel icon="mdi-database-alert-outline" iconColour="red">
						<span class="red">No telemetry</span>
					</app-info-panel>
				</div>
				<div>

				</div>
				<label class="no-print">Annotation <app-select-review-annotation [adminAnnotations]="adminAnnotations" (onSelected)="annotationRequested(id, $event)">
					</app-select-review-annotation></label>
				<textarea [(ngModel)]="insight.telemetry[id].review.annotation" [rows]="1" class="form-control"></textarea>

				<div>
					<div class="colours">
						<div class="note-colour-red" (click)="insight.telemetry[id].review.rag='red'" pTooltip="Set note colour red" tooltipPosition="bottom"><i class="fa fa-fw" [class.fa-circle-o]="insight.telemetry[id].review.rag !== 'red'" [class.fa-circle]="insight.telemetry[id].review.rag === 'red'"></i></div>
						<div class="note-colour-orange" (click)="insight.telemetry[id].review.rag='amber'" pTooltip="Set note colour orange" tooltipPosition="bottom"><i class="fa fa-fw" [class.fa-circle-o]="insight.telemetry[id].review.rag !== 'amber'" [class.fa-circle]="insight.telemetry[id].review.rag === 'amber'"></i></div>
						<div class="note-colour-green" (click)="insight.telemetry[id].review.rag='green'"><i pTooltip="Set note colour green" tooltipPosition="bottom" class="fa fa-fw" [class.fa-circle-o]="insight.telemetry[id].review.rag !== 'green'" [class.fa-circle]="insight.telemetry[id].review.rag === 'green'"></i></div>
					</div>
				</div>
			</app-theme-page-section>
		</div>
	</section>

	<section class="buttons no-print">
		<div class="text-center">
			<div [class.active]="insight.stage==='new'" tooltipPosition="bottom" [pTooltip]="insight.stage === 'new' ? 'Currently private' : 'Stop being public, make private'">
				<button icon="mdi mdi-cloud-lock-outline" pButton label="Update annotations, keep private" (click)="updateAnnotations('new')"></button>
			</div>
			<div [class.active]="insight.stage==='done'" tooltipPosition="bottom" [pTooltip]="insight.stage === 'done' ? 'Currently public' : 'Stop being private, make public'">
				<button icon="mdi mdi-earth" class="p-button-success" pButton label="Update annotations, make public" (click)="updateAnnotations('done')"></button>
			</div>
		</div>
	</section>
</article>

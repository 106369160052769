<div class="tabview-list">
    <div [class.active]="tabIndex()===0" (click)="tabIndex.set(0)">
        <i class="mdi mdi-view-list"></i>Legacy
    </div>
    <div [class.active]="tabIndex()===1" (click)="tabIndex.set(1)">
        <i class="mdi mdi-view-list"></i>Areas
    </div>
    <div></div>
</div>


@switch(tabIndex()) {

@case(0) {
<div class="col-md-12 no-footfall-gateways" *ngIf="noFootfallgateways">

    <app-info-panel>
        <p>Your organisation has no footfall devices.</p>
    </app-info-panel>

</div>

<div class="col-md-12" *ngIf="gateways.length >= 1">
    <div class="component-list">
        <table class="table table-hover table-sm assets">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Location</th>
                    <th>Org</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of gateways; let i=index" (click)="selectGateway(item)" [class.selected]="gateway && item.id===gateway.id">
                    <td>
                        <app-gateway-indicator [gateway]="item"></app-gateway-indicator> {{item.title}}
                    </td>
                    <td>{{item.location}}</td>
                    <td>{{item.org?.title}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="col-md-12 mt-4" *ngIf="gateway?.id">
    <div class="row">

        <div class="col-md-3"></div>
        <div class="col-md-6 ">
            <p *ngIf="this.timerSubscription" class="text-center" title="People in and out since showing this page"><i class="fa fa-refresh fa-spin"></i> Monitoring footfall from {{now | amTimeAgo}}</p>
        </div>
    </div>
    <!-- TESTING <button class="btn btn-sm" (click)="use('redis')">REDIS {{useThis==='redis'?'X':''}}</button>
    <button class="btn btn-sm" (click)="use('sql')">SQL {{useThis==='sql'?'X':''}}</button> -->
    <div class="row chart-row" *ngFor="let item of charts;let i = index">
        <div class="col-md-12">
            <p-chart *ngIf="data.datasets[i].data.length" data-id="{{item.id}}" name="chart" #chart type="bar" [data]="data" [options]="options"></p-chart>
        </div>
    </div>
</div>
<div class="col-12 mt-2" *ngIf="asset.id"></div>

<div class="col-md-6 count col-sm-6 col-xs-6 counter" *ngIf="asset.id">
    <p class="text-center">In</p>
    <p class="text-center">{{count.in}}</p>
</div>
<div class="col-md-6 count col-sm-6 col-xs-6 counter" *ngIf="asset.id">
    <p class="text-center">Out</p>
    <p class="text-center">{{count.out}}</p>

</div>
<div class="col-md-12 info" *ngIf="asset.id && subdomain !== 'public'">
    <div class="row">
        <div class="col-md-12">
            <h2>Footfall process</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <img src="assets/4dmotion_640.png" class="img-responsive">
        </div>
        <div class="col-md-6">
            <p>As a person walks past the 4D footfall gateway (as seen in the white ipad) the software running on the 4D footfall gateway tracks the person (as seen on the black windows tablet).</p>
            <p>As the person walks in or out, the 4D footfall gateway transmits the amount of people back to the 4D platform.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 mt-2">
            <p>The chart shows these values in realtime as people move past the 4D footfall gateway.</p>
            <p> The In and Out totals show the total counts since displaying this page.</p>
        </div>
        <div class="col-md-6 mt-2">
            <img src="assets/4dmotion_chart_640.png" class="img-responsive">
        </div>
    </div>
</div>

}
@case(1) {

<app-foorfall-areas></app-foorfall-areas>

}

}

<section class="timeline">
	<div *ngFor="let item of timeline" class="action">
		<div>
			<p [style.color]="item.colour">
				<i *ngIf="item.icon" class="fa fa-fw {{item.icon}}"></i>
				<img *ngIf="item.logo" width="24px" src="assets/svg/elogbooks-logo.svg">
			</p>
			<span class="connector">{{item.connector}}</span>
		</div>
		<div>
			<p [innerHTML]="item.words"></p>
			<p class="date">
				<span *ngIf="item.date">{{item.date | amDateFormat:'DD MMM YYYY HH:mm'}}</span>
				<span *ngIf="item.name"><i>, <span class="">{{item.name}}</span></i></span>
			</p>
		</div>
	</div>
</section>

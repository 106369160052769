import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APIService } from './api.service';
import { Asset } from '../classes/asset';
import { Site } from 'app/classes/site';
@Injectable({
  providedIn: 'root'
})
export class FootfallService {
  static readonly API_URL = 'https://xxzkr4dbi3.execute-api.eu-west-2.amazonaws.com/v1';

  constructor(private http: HttpClient, private apiService: APIService) { }

  async getAreas(): Promise<IFootfallArea[]> {
    return new Promise(async (resolve) => {
      this.http
        .get<IFootfallArea[]>(FootfallService.API_URL + '/areas', { ...this.apiService.getUAOHeaders(), params: {} })
        .subscribe(async response => {
          resolve(response);
        });
    });
  }
}


export class FootfallAsset extends Asset {
  direction: FootfallDirection;
}


export interface IFootfallArea {
  id: number;
  title: string;
  site: Site;
  createdAt: Date;
  assets: FootfallAsset[];
}


export type FootfallDirection = 'in' | 'out';

import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Building } from 'app/classes/building';
import { Gateway } from 'app/classes/gateway';
import { CollectionAsset } from 'app/classes/collection-for-assets';
import { IInsightAPITypeItem } from 'app/classes/insight';
import { CombinedCollectionItem } from './site.service';
import moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class InsightsService {
	static readonly API_URL = 'https://t59hry4us9.execute-api.eu-west-2.amazonaws.com/4d/';
	S3_JOBS = 'https://4d-jobs.s3.eu-west-2.amazonaws.com/';

	constructor(private apiService: APIService, private http: HttpClient,) { }

	getFootfallInsights(buildingId: number, collectionIds: number[], startDate: Date, forHours): Promise<any> {
		return new Promise(async resolve => {
			const headers = this.apiService.getUAOHeaders().headers;
			const params = { bid: buildingId, cids: collectionIds.join(), start: this.apiService.dbDate(startDate) };
			this.http
				.get<any>(InsightsService.API_URL + 'footfall', { headers, params })
				.subscribe(async response => {
					resolve(response);
				});
		});
	}

	updateAQRAG(id: number, title: any, config: any): Promise<any> {
		return new Promise(async resolve => {
			const headers = this.apiService.getUAOHeaders().headers;
			const params: any = { a: 'update' };
			if (id) {
				params.id = id;
			}
			this.http
				.post<any>(InsightsService.API_URL + 'aq/config', { title, config }, { headers, params })
				.subscribe(async response => {
					resolve(response);
				});
		});
	}

	updateAQRAGConfigList(): Promise<any> {
		return new Promise(async resolve => {
			const headers = this.apiService.getUAOHeaders().headers;
			const params: any = { a: 'list' };

			this.http
				.get<any>(InsightsService.API_URL + 'aq/config', { headers, params })
				.subscribe(async response => {
					resolve(response);
				});
		});
	}

	updateAQRAGforBuiilding(id: number, buildingId: number): Promise<any> {
		return new Promise(async resolve => {
			const headers = this.apiService.getUAOHeaders().headers;
			const params: any = { a: 'attach-building', bid: buildingId, id };

			this.http
				.get<any>(InsightsService.API_URL + 'aq/config', { headers, params })
				.subscribe(async response => {
					resolve(response);
				});
		});
	}

	deleteAQRAGConfigItem(id: number): Promise<any> {
		return new Promise(async resolve => {
			const headers = this.apiService.getUAOHeaders().headers;
			const params: any = { a: 'delete', id };

			this.http
				.get<any>(InsightsService.API_URL + 'aq/config', { headers, params })
				.subscribe(async response => {
					resolve(response);
				});
		});
	}


	getAQInsights(buildingId: number, collectionIds: number[], startDate: Date): Promise<any> {
		return new Promise(async resolve => {
			const headers = this.apiService.getUAOHeaders().headers;
			const params = { bid: buildingId, cids: collectionIds.join(), start: this.apiService.dbDate(startDate) };
			this.http
				.get<any>(InsightsService.API_URL + 'aq', { headers, params })
				.subscribe(async response => {
					resolve(response);
				});
		});
	}


	async getMaster(uuid: string,): Promise<any> {
		return new Promise(async (resolve, reject) => {
			const filename = this.S3_JOBS + `${uuid}-new.json`;
			this.http
				.get<any>(filename)
				.subscribe(async response => {
					resolve(JSON.parse(response));
				});
		});
	}

	async getPart(uuid: string, assetId: number | string): Promise<any> {
		return new Promise(async (resolve, reject) => {
			const filename = this.S3_JOBS + `${uuid}-${assetId}.json`;
			this.http
				.get<any>(filename)
				.subscribe(async telemetry => {
					resolve({ assetId: assetId, telemetry });
				});
		});
	}

	async getParts(uuid: string, assets: number[]): Promise<any> {
		return new Promise(async (resolve, reject) => {
			const fn = [];
			const master = await this.getMaster(uuid);
			assets.forEach(assetId => fn.push(this.getPart(uuid, assetId)));

			Promise
				.all(fn)
				.then(assets => {

					let collection = {};
					assets.forEach(asset => {
						collection[asset.assetId] = asset.telemetry;
					});

					resolve({ master: master.body, collection });
				});
		});
	}

	async waitForBuildingHourlyJob<T>(uuid, waitMs): Promise<T> {
		const filename = this.S3_JOBS + `${uuid}.json`;
		return new Promise(async (resolve, reject) => {
			console.log('WAITING FOR ', uuid);
			let counter = 120;

			if (!waitMs) {
				try {
					this.http
						.get<any>(filename)
						.subscribe(async b => {
							if (b.assets) {
								// We have multi-part file
								const response = await this.getParts(uuid, b.assets);
								resolve(response);
							} else {
								resolve(b);
							}
						}, () => resolve(null)
						);
				} catch (e) {
					resolve(null);
				}
				return;
			}

			const timer = setInterval(async () => {
				counter--;
				console.log(counter, filename);
				if (!counter) {
					clearInterval(timer);
					reject({ message: 'TIMEOUT' });

					return reject();
				}
				try {
					this.http
						.get<any>(filename)
						.subscribe(b => {
							clearInterval(timer);

							try {
								this.http
									.get<any>(filename)
									.subscribe(async b => {
										console.log('GOT_ASSETS');
										if (b.assets) {
											// We have multi-part file
											const response = await this.getParts(uuid, b.assets);
											resolve(response);
										} else {
											resolve(b);
										}
									}, error => {
										resolve(null);
									});
							} catch (e) {
								resolve(null);
							}
							return;
						});
				} catch (e) {
					console.log(e);
				}
			}, waitMs);


		});
	}

	startBuildingHourlyJobForCollections(building: Building, collectionAssets: CollectionAsset[], startDate: Date, clearCache: boolean, title = 'Indoor air quality'): Promise<any> {
		return new Promise((resolve, reject) => {
			const payload = this.buildPayload(building, startDate, title);

			collectionAssets
				.forEach(c => c.assets.forEach(a => payload.assets.push({
					id: a.id,
					assetType_id: a.assetType_id,
					collectionId: c.id,
					metobsid: a.metobsid
				})));

			payload.assets.forEach(a => {
				switch (a.assetType_id) {
					case 15: //footfall
						payload.types.push({ id: a.id, type: a.assetType_id, title: a.title, c: a.collectionId, metobsid: a.metobsid });
						break;
				}
			});

			if (payload.assets.length === 0) {
				throw new Error('No assets');
			}

			const dbStart = payload.start.clone().utc().format('YYYY-MM-DD HH:mm:ss');
			const dbEnd = payload.end.clone().utc().format('YYYY-MM-DD HH:mm:ss');

			// console.log(JSON.stringify(payload, null, 2));


			let qs = `a=start-background-job&job=get-hourly-footfall&&i=${building.id}&start=${dbStart}&end=${dbEnd}`;
			if (clearCache) {
				qs += `&clearcache=1`;
			}

			return this.post(payload, qs)
				.then(b => {
					resolve(b);
				});
		});
	}

	private startEndDate(startDate: Date, months = 0) {
		const start = moment(startDate).startOf('month').startOf('day');
		const end = moment(startDate).add(months, 'months').endOf('month').endOf('day');
		const days = Math.abs(end.diff(start, 'days')) + 1;
		console.log('DIFF_DATE', start.format('DD/MM/YY HH:mm:ss'), end.format('DD/MM/YY HH:mm:ss'), days);
		if (months == 2 && days > 93) {
			console.error('ERROR_DIFF_DATE');
		}
		const dbStart = start.clone().utc().format('YYYY-MM-DD HH:mm:ss');
		const dbEnd = end.clone().utc().format('YYYY-MM-DD HH:mm:ss');

		return { start, end, days, dbStart, dbEnd };
	}

	/**
	 * Build payload in UTC
	 * 
	 * @param building 
	 * @param startDate 
	 * @param title 
	 * @returns 
	 */
	private buildPayload(building: Building, startDate: Date, title: string, months = 0) {
		const { start, end, days } = this.startEndDate(startDate, months);

		title += ' for ' + building.title + ' from ' + start.format('DD/MM/YYYY') + ' to ' + end.format('DD/MM/YYYY');
		const payload = { ranges: [], assets: [], types: [], title, start, end };

		for (let index = 0; index < days; index++) {
			const dt = moment(start).add(index, 'days');
			const dow = dt.isoWeekday() - 1;
			const hours = building.openingHours.hours.find(day => day.dow === dow && !day.isClosed);
			if (hours) {
				const timeFrom = hours.from;
				const timeTo = hours.to;
				const from = moment(dt).set('hour', +timeFrom.substr(0, 2)).set('minutes', +timeFrom.substr(3, 2)).set('seconds', 0).utc();
				const to = moment(dt).set('hour', +timeTo.substr(0, 2)).set('minutes', +timeTo.substr(3, 2)).set('seconds', 0).utc();

				payload.ranges.push({ from: from.format('YYYY-MM-DD HH:mm:ss'), to: to.format('YYYY-MM-DD HH:mm:ss') });

			}
			// SERVER NEEDS UTC
		}

		return payload;
	}

	startBuildingHourlyJob(building: Building, gateways: Gateway[], startDate: Date, clearCache: boolean, collections: CombinedCollectionItem[]): Promise<any> {
		return new Promise((resolve, reject) => {
			const start = moment(startDate).startOf('month').startOf('day');
			const end = moment(startDate).endOf('month').endOf('day');

			const days = Math.abs(start.diff(end, 'days')) + 1;
			console.log(start, end, 'DAYS', days);

			const title = 'Indoor air quality for ' + building.title + ' from ' + start.format('DD/MM/YYYY') + ' to ' + end.format('DD/MM/YYYY');
			const payload = { ranges: [], assets: [], types: [], date: Date, title };
			// Decide the assets required
			gateways.forEach(g => g.assets.list.forEach(a => {
				switch (a.assetType_id) {
					case 62: // pm1
					case 29: // pm2.5
					case 30: // pm10
					case 25: // co2
					case 8: //  humidity
					case 2: //  Temperature
					case 27: // VOC
						payload.assets.push(a.id);
						payload.types.push({ id: a.id, type: a.assetType_id, title: a.title, g: a.gateway_id });
				}
			}));

			collections.forEach(c => {
				c.assets.forEach(a => {
					payload.assets.push(a.id);
					payload.types.push({ id: a.id, type: a.typeId, title: a.title, g: a.gateway.id, c: c.id, co: c.origin });
				});
			});

			for (let index = 0; index < days; index++) {
				const dt = moment(start).add(index, 'days');
				const dow = dt.isoWeekday() - 1;
				const hours = building.openingHours.hours.find(day => day.dow === dow && !day.isClosed);
				if (hours) {
					const timeFrom = hours.from;
					const timeTo = hours.to;
					const from = moment(dt).set('hour', +timeFrom.substr(0, 2)).set('minutes', +timeFrom.substr(3, 2)).set('seconds', 0).utc();
					const to = moment(dt).set('hour', +timeTo.substr(0, 2)).set('minutes', +timeTo.substr(3, 2)).set('seconds', 0).utc();

					payload.ranges.push({ from: from.format('YYYY-MM-DD HH:mm:ss'), to: to.format('YYYY-MM-DD HH:mm:ss') });

				}
				// SERVER NEEDS UTC
				// console.log('SQL:', payload);
			}

			if (payload.assets.length === 0) {
				throw new Error('No assets');
			}

			const dbStart = start.clone().utc().format('YYYY-MM-DD HH:mm:ss');
			const dbEnd = end.clone().utc().format('YYYY-MM-DD HH:mm:ss');

			let qs = `a=get-hourly-building-create-job&i=${building.id}&start=${dbStart}&end=${dbEnd}`;
			if (clearCache) {
				qs += `&clearcache=1`;
			}

			return this.post(payload, qs)
				.then(b => {
					resolve(b);
				});
		});
	}

	getForBuilding(building: Building, gateways: Gateway[], startDate: Date): Promise<any> {
		return new Promise((resolve, reject) => {
			const start = moment(startDate).startOf('month').startOf('day');
			const end = moment(startDate).endOf('month').endOf('day');

			const days = Math.abs(start.diff(end, 'days')) + 1;
			console.log(start, end, 'DAYS', days);

			const payload = { ranges: [], assets: [], types: [], date: Date };
			// Decide the assets required
			gateways.map(g => g.assets.list.forEach(a => {
				switch (a.assetType_id) {
					case 25: //co2
					case 8: // humidity
					case 2: // Temperature
					case 27: // VOC
						payload.assets.push(a.id);
						payload.types.push({ id: a.id, type: a.assetType_id, title: a.title, g: a.gateway_id });
				}
			}));

			for (let index = 0; index < days; index++) {
				const dt = moment(start).add(index, 'days');
				const dow = dt.isoWeekday() - 1;
				const hours = building.openingHours.hours.find(day => day.dow === dow && !day.isClosed);
				if (hours) {
					const timeFrom = hours.from;
					const timeTo = hours.to;
					const from = moment(dt).set('hour', +timeFrom.substr(0, 2)).set('minutes', +timeFrom.substr(3, 2)).set('seconds', 0).utc();
					const to = moment(dt).set('hour', +timeTo.substr(0, 2)).set('minutes', +timeTo.substr(3, 2)).set('seconds', 0).utc();

					payload.ranges.push({ from: from.format('YYYY-MM-DD HH:mm:ss'), to: to.format('YYYY-MM-DD HH:mm:ss') });

				}
				// SERVER NEEDS UTC
				// console.log('SQL:', payload);
			}

			if (payload.assets.length === 0) {
				throw new Error('No assets');
			}

			const dbStart = start.clone().utc().format('YYYY-MM-DD HH:mm:ss');
			const dbEnd = end.clone().utc().format('YYYY-MM-DD HH:mm:ss');
			return this.post(payload, `a=get-hourly-building&i=${building.id}&start=${dbStart}&end=${dbEnd}`)
				.then(b => {
					resolve({ data: b.collection, master: { start, end, ...payload } });
				});
		});
	}

	get(qs: string = ''): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `${InsightsService.API_URL}?${qs}`;
			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	post(body, qs: string = ''): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `${InsightsService.API_URL}?${qs}`;
			return this.http
				.post<any>(url, body, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}
}

export interface IWaitForBuildingHourlyJob {
	collection: IInsightAPITypeItem[];
	master: {
		ranges: { from: string, to: string }[],
		assets: number[],
		types: { id: number, type: number, title: string, g: string }[],
		title: string,
		start: any; // moment
		end: any;
	};
	floorplans?: IInsightFFFloorplanAsset[];
}

export interface IWaitForBuildingHourlyJobFF {
	collection: IInsightAPITypeItem[];
	master: {
		ranges: { from: string, to: string }[],
		assets: { id: number, collectionId: number, metobsid: number, assetType_id: number }[],
		types: { id: number, type: number, title: string, g: string }[],
		title: string,
		start: any; // moment
		end: any;
	};
	floorplans?: IInsightFFFloorplanAsset[];
	weather: { timestamp: Date, icon: string }[];
}


export interface IWaitForBuildingReviewJob {
	collection: { a: number, i: number, d: Date, v: number }[];
	master: {
		ranges: { from: string, to: string }[],
		assets: { id: number, collectionId: number, metobsid: number, assetType_id: number }[],
		types: { id: number, type: number, title: string, g: string }[],
		title: string,
		start: any; // moment
		end: any;
	};
	weather: { timestamp: Date, description: string, temp: number, icon: string, type: string, pressure: number }[];
}

export interface IInsightFFFloorplanAsset {
	building_id: number;
	site_id: number;
	asset_id: number;
	site_floorplan_id: number;
	planKey: string;
	x: number;
	y: number;
	type: string;
	shape_id: number;
	viewboxWidth: number;
	viewboxHeight: number;
	collection_id: number;
}

<P STYLE="margin-bottom: 0.42cm">
  <A NAME="main"></A>PLEASE READ THE TERMS OF THIS POLICY CAREFULLY BEFORE USING THE SITE
</P>
<P STYLE="margin-bottom: 0.42cm">
  <B>What&rsquo;s in these terms?</B>
</P>
<P CLASS="western">This acceptable use policy sets out the content standards that apply when you upload content to our
  site, make contact with other users on our site, link to our site, or interact with our site in any other way.</P>
<P STYLE="margin-bottom: 0.42cm">
  <BR>

</P>
<P STYLE="margin-bottom: 0.42cm">
  <B>Who we are and how to contact us</B>
</P>
<P STYLE="margin-bottom: 0.42cm">
  <a href="https//portal.4dml.com">https//portal.4dml.com</a> is a site operated by 4D Monitoring Limited
  (&ldquo;<B>We</B>&rdquo;). We are registered in
  England and Wales under company number 09839120 and have our registered office
  at
  LDH House, Parsons Green, St. Ives, Cambridgeshire PE27 4AA which is also our main trading address. Our VAT number is
  268313866.
</P>

<P STYLE="margin-bottom: 0.42cm">
  We are a limited company.
</P>

<P CLASS="western">To contact us, please email
  <U>
    <A CLASS="western" HREF="mailto:askus@4dml.com">askus&#64;4dml.com</A>
  </U>
</P>
<P STYLE="margin-bottom: 0.42cm">


</P>
<P STYLE="margin-bottom: 0.42cm">
  <B>By using our site you accept these terms</B>
</P>
<P STYLE="margin-left: 2.54cm; text-indent: -1.27cm; margin-bottom: 0.42cm">
  1 By using our site, you confirm that you accept the terms of this policy and that you agree to comply with them.</P>
<P STYLE="margin-left: 1.27cm; margin-bottom: 0.42cm">2 If you do not agree to these terms, you must not use our site.
</P>
<P CLASS="western">We recommend that you print a copy of these terms for future reference.</P>
<P STYLE="margin-left: 1.27cm; margin-bottom: 0.42cm">There are other terms that may apply to you</P>
<OL>
  <LI>
    <P STYLE="margin-bottom: 0.42cm">Our Terms of website use

      <U>
        <A CLASS="western" HREF="https://portal.4dml.com/policies/terms">Terms of Use</A>
      </U>

      also apply to your use of our site.
    </P>
</OL>
<P STYLE="margin-bottom: 0.42cm">We may make changes to the terms of this policy</P>
<P CLASS="western">We amend these terms from time to time. Every time you wish to use our site, please check these terms
  to ensure you understand
  the terms that apply at that time.</P>
<P STYLE="margin-bottom: 0.42cm">
  <BR>
</P>
<P STYLE="margin-bottom: 0.42cm">
  <B>Prohibited uses</B>
</P>
<P CLASS="western">You may use our site only for lawful purposes. You may not use our site:</P>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">In any way that breaches any applicable local, national or
      international law or regulation.
    </P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">In any way that is unlawful or fraudulent, or has any unlawful or
      fraudulent purpose or effect.</P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">For the purpose of harming or attempting to harm minors in any
      way.</P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">To transmit, or procure the sending of, any unsolicited or
      unauthorised advertising or promotional material or any other
      form of similar solicitation (spam).</P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">To knowingly transmit any data, send or upload any material that
      contains viruses, Trojan horses, worms, time-bombs,
      keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely
      affect
      the operation of any computer software or hardware.</P>
  </LI>
</UL>
<P CLASS="western">
  <BR>
</P>
<P CLASS="western">You also agree:</P>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Not to reproduce, duplicate, copy or re-sell any part of our site
      in contravention of the provisions of our terms of
      website use

      <U>
        <A CLASS="western" HREF="/policies/terms">Terms of Use</A>
      </U>

      .
    </P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Not to access without authority, interfere with, damage or
      disrupt:</P>
</UL>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm">any part of our site;</P>
  <LI>
    <P STYLE="margin-bottom: 0cm">any equipment or network on which our site is stored;</P>
  <LI>
    <P STYLE="margin-bottom: 0cm">any software used in the provision of our site; or</P>
  <LI>
    <P STYLE="margin-bottom: 0cm">any equipment or network or software owned or used by any third party.</P>
</UL>
<P STYLE="margin-bottom: 0.42cm">
  <BR>
  <BR>
</P>
<P STYLE="margin-bottom: 0.42cm">
  <B>Interactive services</B>
</P>
<P CLASS="western">We may from time to time provide interactive services on our site, including, without limitation:</P>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Chat rooms.</P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Bulletin boards.
    </P>
  </LI>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Portal.</P>
  </LI>
</UL>
<P CLASS="western">(
  <B>&ldquo;interactive services.&rdquo;</B>)
</P>
<P CLASS="western">Where we do provide any interactive service, we will provide clear information to you about the kind
  of service offered,
  if it is moderated and what form of moderation is used (including whether it is human or technical).</P>
<P CLASS="western">We will do our best to assess any possible risks for users (and in particular, for children) from
  third parties when they
  use any interactive service provided on our site, and we will decide in each case whether it is appropriate to use
  moderation
  of the relevant service (including what kind of moderation to use) in the light of those risks. However, we are under
  no
  obligation to oversee, monitor or moderate any interactive service we provide on our site, and we expressly exclude
  our
  liability for any loss or damage arising from the use of any interactive service by a user in contravention of our
  content
  standards, whether the service is moderated or not.</P>
<P CLASS="western">The use of any of our interactive services by a minor is subject to the consent of their parent or
  guardian. We advise parents
  who permit their children to use an interactive service that it is important that they communicate with their children
  about their safety online, as moderation is not fool proof. Minors who are using any interactive service should be
  made
  aware of the potential risks to them.</P>
<P CLASS="western">Where we do moderate an interactive service, we will normally provide you with a means of contacting
  the moderator, should
  a concern or difficulty arise.</P>
<P STYLE="margin-bottom: 0.42cm">
  <BR>

</P>
<P STYLE="margin-bottom: 0.42cm">
  <B>Content standards</B>
</P>
<P CLASS="western">These content standards apply to any and all material which you contribute to our site (
  <B>&ldquo;Contribution&rdquo;</B>), and to any interactive services associated with it.
</P>
<P CLASS="western">The Content Standards must be complied with in spirit as well as to the letter. The standards apply
  to each part of any Contribution
  as well as to its whole.</P>
<P CLASS="western">We will determine, in its discretion, whether a Contribution breaches the Content Standards.</P>
<P CLASS="western">A Contribution must:</P>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Be accurate (where it states facts).</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Be genuinely held (where it states opinions).</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Comply with the law applicable in England and Wales and in any
      country from which it is posted.</P>
</UL>
<P CLASS="western">A Contribution must not:</P>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Be defamatory of any person.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Be obscene, offensive, hateful or inflammatory.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Promote sexually explicit material.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Promote violence.
    </P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Promote discrimination based on race, sex, religion, nationality,
      disability, sexual orientation or age.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Infringe any copyright, database right or trade mark of any other
      person.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Be likely to deceive any person.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Breach any legal duty owed to a third party, such as a
      contractual duty or a duty of confidence.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Promote any illegal activity.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Be in contempt of court.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Be threatening, abuse or invade another&rsquo;s privacy, or cause
      annoyance, inconvenience or needless
      anxiety.
    </P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Be likely to harass, upset, embarrass, alarm or annoy any other
      person.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Impersonate any person, or misrepresent your identity or
      affiliation with any person.
    </P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Give the impression that the Contribution emanates from us, if
      this is not the case.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Advocate, promote, incite any party to commit, or assist any
      unlawful or criminal act such
      as (by way of example only) copyright infringement or computer misuse.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Contain a statement which you know or believe, or have reasonable
      grounds for believing,
      that members of the public to whom the statement is, or is to be, published are likely
      to understand as a direct or indirect encouragement or other inducement to the commission,
      preparation or instigation of acts of terrorism.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Contain any advertising or promote any services or web links to
      other sites.</P>
</UL>
<P STYLE="margin-bottom: 0.42cm">
  <BR>
</P>
<P STYLE="margin-bottom: 0.42cm">
  <B>Breach of this policy</B>
</P>
<P CLASS="western">When we consider that a breach of this acceptable use policy has occurred, we may take such action as
  we deem appropriate.
</P>
<P CLASS="western">Failure to comply with this acceptable use policy constitutes a material breach of the terms of use

  <U>
    <A CLASS="western" HREF="/policies/terms">Terms of Use</A>
  </U>

  upon which you are permitted to use our site, and may result in our taking all or any of the following actions:
</P>
<UL>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Immediate, temporary or permanent withdrawal of your right to use
      our site.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Immediate, temporary or permanent removal of any Contribution
      uploaded by you to our site.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Issue of a warning to you.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Legal proceedings against you for reimbursement of all costs on
      an indemnity basis (including, but not limited
      to, reasonable administrative and legal costs) resulting from the breach.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Further legal action against you.</P>
  <LI>
    <P STYLE="margin-bottom: 0cm; line-height: 0.53cm">Disclosure of such information to law enforcement authorities as
      we reasonably feel is necessary or as required
      by law.</P>
</UL>
<P CLASS="western">
  <BR>
</P>
<P CLASS="western">We exclude our liability for all action we may take in response to breaches of this acceptable use
  policy. The actions we
  may take are not limited to those described above, and we may take any other action we reasonably deem appropriate.
</P>
<P STYLE="margin-bottom: 0.42cm">
  <BR>
</P>
<P STYLE="margin-bottom: 0.42cm">
  <B>Which country&rsquo;s laws apply to any disputes?</B>
</P>

<ol>
  <li>If you are a consumer, please note that the terms of this policy, its subject matter and its formation are
    governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction
    except that if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you
    are resident of Scotland, you may also bring proceedings in Scotland.
  </li>
  <br>
  <li>If you are a business, the terms of this policy, its subject matter and its formation (and any non-contractual
    disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of
    England and Wales.
  </li>
</ol>
<br>

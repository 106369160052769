<!--<div>within <input [(ngModel)]="percOf" type="number" /> <button (click)="clac"> of first place</button></div>-->
@if((results && results.length) || ( userLog )) {
<article>

    <div class="go-back isMobile" (click)="selectedOrg=null" [class.hidden]="!selectedOrg">
        <span><i class="mdi mdi-chevron-left"></i> Back to organisations</span>
    </div>

    <div class="tabview-list">
        <div [class.active]="tabIndex()===0" (click)="tabIndexClick(0)">
            <i class="mdi mdi-lan"></i> Organisations
        </div>
        <div [class.active]="tabIndex()===1" (click)="tabIndexClick(1)">
            <i class="mdi mdi-office-building"></i> Sites
        </div>
        <div></div>
    </div>

    <div class="tabview-content">
        @switch(tabIndex()) {
        @case(0){
        <div class="grid-container" [class.org-selected]="selectedOrg" [class.is-participant]="!results" *ngIf="!selectedOrg">

            <div class="grid" [class.is-participant]="!results">
                <span></span>
                <span>Organisation</span>
                <span class="site-count">Engagement</span>
                <span class="site-count">Sites</span>
                <ng-template ngFor let-i="index" let-result [ngForOf]="results">
                    <span class="position-{{i+1}} org-{{result.orgPartnershipId}} activity">
                        <app-circle-ring [perc]="result.activity" [background]="result.engagementStatus" [strokeWidth]="10" [showDelay]="500" [escape]="false" tooltipPosition="top" pTooltip="<div>User activity <span style='color:yellow'>{{result.activity}}%</span></div>"></app-circle-ring>
                    </span>
                    <span class="position-{{i+1}} org-{{result.orgPartnershipId}} title" [class.is-selected]="selectedOrg===result.orgPartnershipId" (click)="selectOrg(result.orgPartnershipId)">{{result.title}}</span>
                    <span [class.is-selected]="selectedOrg===result.orgPartnershipId" class="position-{{i+1}} org-{{result.orgPartnershipId}} site-perc" (click)="selectOrg(result.orgPartnershipId)">{{result.activity}}%</span>
                    <span [class.is-selected]="selectedOrg===result.orgPartnershipId" class="position-{{i+1}} org-{{result.orgPartnershipId}} site-count" [class.selected]="result.orgPartnershipId === selectedOrg" (click)="selectOrg(result.orgPartnershipId)">{{result.siteCount}}</span>
                </ng-template>
                <span class="total-row"></span>
                <span class="total-row"></span>
                <span class="total-row"></span>
                <span class="site-count total total-row">{{totalSites}}</span>

                <span></span>
                <span>
                    @if(isAdmin()) {
                    <app-button [isMuted]="true" size="small" icon="mdi mdi-shield-account-outline" (click)="displayAudit=true" label="Show  Audit"></app-button>
                    }
                </span>
                <span></span>
                <span></span>

            </div>
        </div>

        <div *ngIf="selectedOrg">
            <app-partner-org-users-breakdown [selectedOrg]="selectedOrg" [userLog]="userLog" [users]="users" [usersSelected]="usersSelected" (onUserSelectionChanged)="userSelectionChanged($event)" (onGoBack)="selectedOrg=null"></app-partner-org-users-breakdown>
        </div>
        }
        @case(1) {
        <app-partner-shared-sites [engagementSharedSites]="sharedSites" [userLog]="userLog"></app-partner-shared-sites>
        }
        }
    </div>



</article>

}

@if(isFiltering) {
<div>filtering...</div>
}

@if(results && results.length === 0) {
<app-info-panel>
    <h3>Your organisation has not been configured for this page.</h3>
    <p>Other organisations managing sites belonging to your organisation appear here.</p>
</app-info-panel>
}

@if(loading) {
<app-please-wait></app-please-wait>
}

@if(displayAudit) {
<app-dialog header="Audit" [dimensions]="{width: '600', height:'500'}" [canScroll]="true" (onClose)="displayAudit=false">
    <table class="table">
        <tbody>
            <tr *ngFor="let row of unfilteredResults[2]">
                <td>{{row}}</td>
            </tr>
        </tbody>
    </table>
</app-dialog>
}

import { Component, Input, OnInit } from '@angular/core';
import { RulePackage } from 'app/classes/rule-service/rule-package';

@Component({
  selector: 'app-rulesv3-item-elogbooks-details',
  templateUrl: './rulesv3-item-elogbooks-details.component.html',
  styleUrls: ['./rulesv3-item-elogbooks-details.component.css']
})
export class Rulesv3ItemElogbooksDetailsComponent implements OnInit {

  @Input()
  rule: RulePackage;
  
  constructor() { }

  ngOnInit(): void {
  }

}

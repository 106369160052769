<div class="timeline">

  <div>
    <app-section-icon icon="home"></app-section-icon>
  </div>

  <div>
    <!-- <img class="cover" src="https://www.vslandp.com/uploadedsysimages/Property/AdditionalD966.jpg" /> -->
  </div>

  <div>
  </div>
  <div>
    <div class="dots" style="margin-top: 6px;">
      <div>
        <i class="fa fa-circle"></i>
      </div>
      <div>
        <i class="fa fa-circle"></i>
      </div>
      <div>
        <i class="fa fa-chevron-down "></i>
      </div>
    </div>
  </div>

  <div>
    <app-section-icon icon="power-off"></app-section-icon>
  </div>

  <div class="text">
    <h1>BUILDING ONE <span>ONLINE</span></h1>
    <h2>3rd March 2017</h2>
  </div>

  <div>
  </div>
  <div>
    <div class="dots" style="margin-bottom: 6px;">
      <div>
        <i class="fa fa-circle"></i>
      </div>
      <div>
        <i class="fa fa-circle"></i>
      </div>
      <div>
        <i class="fa fa-chevron-down "></i>
      </div>
    </div>
  </div>
  <div>
    <app-section-icon icon="map"></app-section-icon>
  </div>

  <div>
    <!--app-section-map [long]="-1.302437" [lat]="51.67651"></app-section-map>-->
  </div>
  <div>
  </div>
  <div>
    <div class="dots" style="margin-top: 6px;">
      <div>
        <i class="fa fa-circle"></i>
      </div>
      <div>
        <i class="fa fa-circle"></i>
      </div>
      <div>
        <i class="fa fa-chevron-down"></i>
      </div>
    </div>
  </div>

  <div>
    <app-section-icon icon="cubes"></app-section-icon>
  </div>

  <div>
    <app-section-assets></app-section-assets>
  </div>

  <div>
  </div>
  <div>
    <div class="dots" style="margin-top: 6px;">
      <div>
        <i class="fa fa-circle"></i>
      </div>
      <div>
        <i class="fa fa-circle"></i>
      </div>
      <div>
        <i class="fa fa-chevron-down"></i>
      </div>
    </div>
  </div>

  <div>
    <app-section-icon icon="book"></app-section-icon>
  </div>

  <div class="text">
    <h1>INITIAL REPORT</h1>
    <h2>1st JUNE 2017</h2>
  </div>

</div>

<i style="color:#f0f0f0" class="fa fa-desktop" (click)="toggleFullscreen()"></i>

<tr *ngIf="order && !hideTitleRow" class="site">
  <td colspan="5"><span class="title">{{order.site.title}}</span> <span class="pull-right">{{order.deliverAt | amDateFormat:apiService.getDateFormat()}} delivery.</span></td>
</tr>
<tr *ngIf="order">
  <td colspan="5">
    <ol class="etapier">
      <li [class.done]="order.status === 'shipped' || order.status === 'submit'" [class.last]="order.status==='submit'">
        <span class="visible-xs"><i class="fa fa-user"></i></span><span class="hidden-xs">Authorise</span>
      </li>
      <li [class.done]="order.status === 'shipped'"><span class="visible-xs"><i class="fa fa-wpforms"></i></span><span class="hidden-xs">Order stock</span></li>
      <li [class.done]="order.status === 'shipped'"><span class="visible-xs"><i class="fa fa-archive"></i></span><span class="hidden-xs">Packaging</span></li>
      <li [class.done]="order.status === 'shipped'" [class.last]="order.status==='shipped'"><span class="visible-xs"><i class="fa fa-truck"></i></span><span class="hidden-xs">Shipped</span></li>

      <li class="todo"><span class="visible-xs"><i class="fa fa-check"></i></span><span class="hidden-xs">Delivered</span></li>
    </ol>
  </td>
</tr>
@defer(when rulePackage) {
<div class="bg-grey" [ngClass]="{'please-wait':pleaseWait}">

    <app-page-title [label]="rulePackage?.id ? rulePackage.title : 'New Rule'" [canGoBack]="true" (click)="goBack()"></app-page-title>

    <!--<app-toolbar>
        <div class="right">
            <app-button label="Submit" icon="mdi mdi-check" (click)="submitRule()" />
        </div>
    </app-toolbar>-->

    <div>
        <section class="title">
            <div>
                <h3>Rule Title</h3>
                <div>
                    <input #titleReference maxlength="100" id="title" name="title" class="fourd" type="text" [class.has-issues]="issues.title && !rulePackage.title" [(ngModel)]="rulePackage.title">
                </div>
            </div>
            <div>
                <h3>Severity</h3>
                <div>
                    <app-button label="Low" [selected]="rulePackage.severity === 'low'" (click)="rulePackage.severity = 'low'" />
                    <app-button label="Normal" [selected]="rulePackage.severity === 'medium'" (click)="rulePackage.severity = 'medium'" />
                    <app-button label="High" [selected]="rulePackage.severity === 'high'" (click)="rulePackage.severity = 'high'" />
                </div>
            </div>
        </section>
    </div>

    <div>
        <app-panel header="Conditions">
            @if(rulePackage.conditions && rulePackage.conditions.length) {
            <div [class.has-issues]="issues.conditions && rulePackage.conditions.length === 0">
                <div class="boxed">
                    <table class="table table-hover" aria-label="Conditions">
                        <thead>
                            <tr>
                                <th>Site</th>
                                <th>Gateway</th>
                                <th>Left Sensor</th>
                                <th>Operator</th>
                                <th [hidden]="!hasRightSensors">Right Sensor</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            @for(ruleCondition of rulePackage.conditions;let i = $index; track ruleCondition) {
                            <tr (click)="changeRuleCondition(ruleCondition, i)" class="rule-condition">
                                <td> {{ruleCondition.leftAsset.asset.gateway.site.title}} </td>
                                <td> {{ruleCondition.leftAsset.asset.gateway.title}} </td>
                                <td> {{ruleCondition.leftAsset.asset.title}}</td>
                                <td>
                                    @switch(ruleCondition.operatorSafe) {
                                    @case('EQ') { <span>equals</span> }
                                    @case('LT') { <span>less than</span> }
                                    @case('GT') { <span>greater than</span> }
                                    @case('GE') { <span>greater than or equal</span> }
                                    @case('LE') { <span>less than or equal</span> }
                                    @case('DIFF') { <span pTolltip="Absolute(+/-) difference is greater than">greater than abs.diff.</span> }
                                    @case('LTDIFF') { <span>less than difference</span> }
                                    @case('GTDIFF') { <span>greater than difference</span> }
                                    }
                                </td>
                                <td [hidden]="!hasRightSensors">
                                    <span *ngIf="ruleCondition.rightAsset.asset">
                                        {{ruleCondition.rightAsset.asset.title}} <i class="fa fa-eye hidden" (click)="drillInto(ruleCondition.rightAsset.asset, $event)"></i></span>
                                    <span *ngIf="!ruleCondition.rightAsset.asset">n/a</span>
                                </td>
                                <td> {{ruleCondition.rightAsset.value}} </td>

                            </tr>

                            @if(i+1 < rulePackage.conditions.length) { <tr>
                                <td colspan="7" class="text-center">
                                    <span class="logic">AND</span>
                                </td>
                                </tr>
                                }
                                }
                        </tbody>
                    </table>
                </div>

            </div>
            }

            <div class="picker" [class.has-issues]="issues.conditions && rulePackage.conditions.length === 0">
                <app-rule-condition-picker [config]="ruleConditionConfig" [modifyCondition]="modifyCondition" [defaultSite]="defaultSite" [defaultGateway]="defaultGateway" (cancel)="newRuleConditionCancelled()" (submit)="newRuleConditionSubmitted($event)" (onDeleteCondition)="deleteCondition($event)"></app-rule-condition-picker>
            </div>
        </app-panel>

        <app-panel header="Options">
            <div class="panel-cols">
                <div class="col-6">
                    <div class="form-group">
                        <label for="autoResolve" title="Does this rule resolve when conditions clear?" class="help-source" (click)="showHelp('autoResolve')">Auto
                            Resolve</label>
                        <div>
                            <app-button label="Always Resolve" [selected]="rulePackage.autoResolve === 'Y'" (click)="rulePackage.autoResolve = 'Y'"></app-button>
                            <app-button label="Manually Resolve" [selected]="rulePackage.autoResolve === 'N'" (click)="rulePackage.autoResolve = 'N'"></app-button>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="delay pull-right" [ngClass]="{'in-delay': rulePackage.triggerAfter}">

                        <label for="autoResolve" title="Does this rule resolve when conditions clear?" class="help-source" (click)="showHelp('autoResolve')">Delay Alarm</label>
                        <div class="p-inputgroup">
                            <input type="number" class="fourd" [(ngModel)]="rulePackage.triggerAfter" inputmode="numeric" pattern="[0-9]*" size="4" [class.has-issues]="issues.triggerAfter">
                            <span class="p-inputgroup-addon"> minutes.</span>
                        </div>

                    </div>
                </div>
            </div>
        </app-panel>

    </div>

    <div>
        <app-panel header="Active Hours">
            @if(!ruleHasRestrictedHours) {
            <div class="p-d-flex p-jc-between">
                <div class="">
                    <p>This rule runs 24 hours a day, 7 days a week.
                        @if(rulePackage.bankHoliday) {
                        <span class="red"> Does not run on bank holidays.</span>
                        } @else {
                        <span>Includes bank holidays.</span>
                        }
                    </p>
                </div>
                <div class="">
                    <app-button (click)="ruleHasRestrictedHours=true"><i class="fa fa-calendar"> </i> Restrict day and time</app-button>
                </div>
            </div>
            }
            @if(rulePackage.ruleTimeRanges[0].monday || rulePackage.ruleTimeRanges[0].tuesday || rulePackage.ruleTimeRanges[0].wednesday || rulePackage.ruleTimeRanges[0].thursday || rulePackage.ruleTimeRanges[0].friday || rulePackage.ruleTimeRanges[0].saturday || rulePackage.ruleTimeRanges[0].sunday || ruleHasRestrictedHours) {
            <div>
                <div class="day-time">
                    <div class="day-of-weeks">
                        <app-chip [checked]="rulePackage.ruleTimeRanges[0].monday" (onChanged)="rulePackage.ruleTimeRanges[0].monday = $event" label="Monday" />
                        <app-chip [checked]="rulePackage.ruleTimeRanges[0].tuesday" (onChanged)="rulePackage.ruleTimeRanges[0].tuesday = $event" label="Tuesday" />
                        <app-chip [checked]="rulePackage.ruleTimeRanges[0].wednesday" (onChanged)="rulePackage.ruleTimeRanges[0].wednesday = $event" label="Wednesday" />
                        <app-chip [checked]="rulePackage.ruleTimeRanges[0].thursday" (onChanged)="rulePackage.ruleTimeRanges[0].thursday = $event" label="Thursday" />
                        <app-chip [checked]="rulePackage.ruleTimeRanges[0].friday" (onChanged)="rulePackage.ruleTimeRanges[0].friday = $event" label="Friday" />
                        <app-chip [checked]="rulePackage.ruleTimeRanges[0].saturday" (onChanged)="rulePackage.ruleTimeRanges[0].saturday = $event" label="Saturday" />
                        <app-chip [checked]="rulePackage.ruleTimeRanges[0].sunday" (onChanged)="rulePackage.ruleTimeRanges[0].sunday = $event" label="Sunday" />
                    </div>

                    <div class="times">
                        <div class="start">
                            <div>Start Time</div>
                            <input class="fourd" type="time" [(ngModel)]="startTimeAt" (blur)="setWords()">
                        </div>

                        <div class="end">
                            <div>End Time</div>
                            <input class="fourd" type="time" [(ngModel)]="endTimeAt" (blur)="setWords()">
                        </div>
                    </div>
                </div>
            </div>
            }
            <div>
                <div class="bank-holiday">
                    <div>
                        <app-button icon="mdi mdi-rotate-3d-variant" [label]="rulePackage.bankHoliday ?  'Does not run on bank holidays' :'Runs on bank holidays'" (click)="rulePackage.bankHoliday = !rulePackage.bankHoliday" [pTooltip]="rulePackage.bankHoliday ? 'Click to change this to run on bankholidays':'Click to change this NOT to run on bank holidays'"></app-button>
                    </div>
                </div>
            </div>
        </app-panel>
    </div>

    <!-- notifications -->
    <div>
        <app-panel header="Notifications">
            <div class="boxed">
                <app-rule-engine-notifications [rule]="rulePackage"></app-rule-engine-notifications>
            </div>

            <div class="boxed emails">
                <div>
                    <h4>Email Notifications</h4>
                    <div>
                        <app-button size="small" label="Add notification" icon="mdi mdi-email-plus" (click)="addNotification('email')"></app-button>
                    </div>
                </div>
                @if(rulePackage.notifications && rulePackage.notifications.length) {
                <table class="table table-sm" aria-label="Notifications">
                    <thead>
                        <tr>
                            <th><span pTooltip="How the notification is delivered">Method</span></th>
                            <th>Value</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        @for(notification of rulePackage.notifications; track notification;let i = $index) {
                        <tr [class.is-suspended]="notification.isSuspended">
                            <td> <span style="text-transform: capitalize;">{{notification.method}}</span> </td>
                            <td> {{notification.value}} <span *ngIf="notification.isSuspended" title="Email may of bounced, is it correct?">DISABLED</span> </td>
                            <td>
                                <i class="fa fa-trash pull-right" (click)="removeNotification(i)"></i>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
                } @else {
                <p>This rule has no individual email notifications.</p>
                }
            </div>
            @if(siteUserGroups) {
            <div class="user-groups">
                <label class="switch">
                    <input type="checkbox" name="typetoggle" [(ngModel)]="rulePackage.includeSiteNotifications">
                    <span class="checkboxslider round"></span>
                </label> <span (click)="rulePackage.includeSiteNotifications = !rulePackage.includeSiteNotifications">
                    Include the site notification group</span> <small class="pull-right help-source" (click)="showHelp('SiteNotificationGroup')">What
                    does this do?</small>

                <table class="table" aria-label="Site Notifications">
                    <tr class="site-notification-users" *ngFor="let userGroup of siteUserGroups" [ngClass]="{'active-users':rulePackage.includeSiteNotifications}">
                        <td>email</td>
                        <td>{{userGroup.user.name}}</td>
                        <td></td>
                    </tr>
                </table>
                @if(rulePackage.includeSiteNotifications && siteUserGroups.length === 0) {
                <div class="no-site-users-configured">
                    There are no users currently configured against the site, when users are added to the site notification user group - this rule will automatically update.
                </div>
                }
            </div>
            }

            <div class="boxed">
                <ng-template [ngIf]="addingNotification">
                    <div *ngIf="addingNotification==='user'">
                        <label>Users</label>
                        <select name="users" class="form-control" id="users" [(ngModel)]="selectedEmail" (change)="userSelected($event)">
                            <option *ngFor="let obj of orgUsers" [value]="obj.email">{{obj.name}} - {{obj.email}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="addingNotification==='email'">
                        <div class="form-group">
                            <label for="email">Email address</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="person@email.com" [(ngModel)]="email">
                                <span class="input-group-btn">
                                    <button (click)="addEmailNotification()" class="btn btn-secondary" type="button">submit</button>
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </app-panel>
    </div>

    <!-- Integration ONLY if rule exists -->
    <div *ngIf="integration && can.integration && rulePackage.id">
        <app-panel>
            <ng-template pTemplate="header">
                <div class="text-left fw600">
                    <span class="mdi mdi-application-import"></span> Integration
                </div>
            </ng-template>
            <div class="form-group">
                <app-rule-engine-v2-integration [rule]="rulePackage" [integration]="integration">
                </app-rule-engine-v2-integration>
            </div>
        </app-panel>
    </div>

    <!-- INSTRUCTIONS -->
    <div>
        <app-panel header="Instructions">
            <textarea [(ngModel)]="rulePackage.instructions" name="instructions" id="instructions" rows="4" placeholder="Optional, instructions to include with alarms and emails."></textarea>
        </app-panel>
    </div>

    <!-- ACTIONS -->
    <div>
        <app-panel header="Actions">
            <div *ngIf="rulePackage.actions && rulePackage.actions.length">
                <div class="boxed">
                    <table class="table table-sm" aria-label="Actions">
                        <thead>
                            <tr>
                                <th>Site</th>
                                <th>Gateway</th>
                                <th>Sensor</th>
                                <th>Trigger</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-template ngFor let-action let-i="index" [ngForOf]="rulePackage.actions">
                                <tr>
                                    <td> {{action.asset.gateway.site.title}} </td>
                                    <td> {{action.asset.gateway.title}} </td>
                                    <td> {{action.asset.title}} </td>
                                    <td> {{action.trigger}} </td>
                                    <td> {{action.value}} </td>
                                    <td>
                                        <i *ngIf="apiService.hasOrgRole('rule_control')" class="fa fa-trash" (click)="removeAction(i)"></i>
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                @if(!addingAction && !rulePackage.actions || (rulePackage.actions && rulePackage.actions.length === 0)) {
                <div class="col-sm-12">
                    <p>This rule has no actions.</p>
                </div>
                }
                @if(can.ruleControl) {
                <app-rule-action-picker [modifyAction]="modifyAction" [defaultSite]="defaultSite" [defaultGateway]="defaultGateway" (cancel)="newActionCancelled()" (submit)="newRuleActionSubmitted($event)" />
                }
            </div>
        </app-panel>
    </div>
</div>
@if(pleaseWait) {
<footer>
    <app-button icon="pi pi-times" label="Cancel" [isMuted]="true"></app-button>
    @if(rulePackage?.id) {
    @if(rulePackage.isEnabled==='N') {
    <app-button icon="fa fa-eye" label="Enable" [isMuted]="true"></app-button>
    } @else {
    <app-button icon="fa fa-eye-slash" label="Disable" [isMuted]="true"></app-button>
    }
    <app-button icon="fa fa-clone" label="Copy" [isMuted]="true"></app-button>
    <app-button icon="pi pi-trash" [isMuted]="true" label="Delete"></app-button>
    }
    <app-button class="text-right" [isMuted]="true" icon="pi pi-check" label="Please wait." />
</footer>
} @else {
<footer>
    <app-button (click)="cancelRule()" icon="pi pi-times" label="Cancel" [isMuted]="true" />
    @if(rulePackage?.id) {
    @if(rulePackage.isEnabled==='N') {
    <app-button (click)="enableRule()" icon="fa fa-eye" label="Enable" [isMuted]="true" />
    } @else {
    <app-button (click)="disableRule()" icon="fa fa-eye-slash" label="Disable" [isMuted]="true" />
    }
    <app-button (click)="copyRule()" icon="fa fa-clone" label="Copy" [isMuted]="true" />
    <app-button icon="pi pi-trash" [isMuted]="true" (click)="deleteRule()" label="Delete" />
    }
    <app-button (click)="submitRule()" [isCTA]="true" class="text-right" icon="pi pi-check" label="Submit" />
</footer>
}
@if(helpShow) {
<app-help-popup [help]="help" [(show)]="helpShow"></app-help-popup>
}

<app-sensor-popup *ngIf="selectedAsset" [(show)]="isShowingPopupSensorData" [asset]="selectedAsset"></app-sensor-popup>
} @placeholder (minimum 100ms) {
<p>Please wait...</p>
}
@if(pleaseWait) {
<app-please-wait></app-please-wait>
}

@if(showDialog()) {
@switch (showDialog()) {
@case('delete') {
<article class="content-container">
    <app-dialog [dimensions]="{width:470, height:160}" (onClose)="confirmedDeleteRule(null)" header="Delete this rule">
        <h3 class="mt-1 mb-1"><i class="mdi mdi-alert red"></i> You are about to delete this rule and it's history</h3>
        <app-toolbar [hasBackground]="false">
            <div class="right">
                <app-button label="Cancel" [isMuted]="true" (click)="confirmedDeleteRule(null)"></app-button>
                <app-button label="DELETE" (click)="confirmedDeleteRule('YES')" [isDanger]="true"></app-button>
            </div>
        </app-toolbar>
    </app-dialog>
</article>
}
@case('notification') {
<app-dialog header="Notifications" [dimensions]="{ width: 600, height: 400}" (onClose)="userSelected(null)" [canScroll]="true">
    @if(orgUsers) {
    <article>
        <main>
            <table class="table table-hover" aria-label="Users">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of orgUsers" (click)="userSelected(user)">
                        <td>{{user.name}}</td>
                        <td>{{user.email}}</td>
                    </tr>
                </tbody>
            </table>
        </main>
        <p style="font-style: italic;">If the user does not appear above, enter their email address below.</p>
        <app-toolbar [hasBackground]="false">
            <div class="left">
                <div class="flex">
                    <input class="fourd flex-1" [(ngModel)]="email" placeholder="email address">
                    <app-button label="Use email" (click)="userSelected({ name: '', email })" />
                </div>
            </div>
        </app-toolbar>
    </article>
    } @else {
    <span>Please wait... <i class="mdi mdi-loading mdi-spin-x2"></i></span>
    }
</app-dialog>
}
}
}

import { group } from "console";
import { CollectionGroup } from "./collection-group";

export class CollectionForAssets {

  groups: CollectionGroup[] = [];
  collections: CollectionAsset[] = [];
  hasCollections: boolean;
  selectedCollection: CollectionAsset;

  constructor(data: any) {
    this.setCollections(data);
  }

  setCollections(data: ICollectionForAssets) {
    const { assets, groups } = data;
    assets.forEach(a => {
      let collection = this.collections.find(c => c.id === a.collectionId);
      if (!collection) {
        collection = { id: a.collectionId, title: a.collectionTitle, assets: [], selected: false };
        this.collections.push(collection);
      }
      const collectionAsset = {
        id: a.assetId,
        title: a.collectionAssetTitle || a.assetTitle,
        assetType_id: a.assetType_id,
        value: a.value,
        status: a.status,
        metobsid: a.metobsid,
        assetTitle: a.assetTitle,
        location: a.location,
        gatewayId: a.gateway_id,
        gatewayTitle: a.gatewayTitle
      };
      collection.assets.push(collectionAsset);
    });
    this.hasCollections = !!(this.collections.length);
  }

  setSelectedIndex(index: number) {
    this.clearSelected();
    this.collections[index].selected = true;
    this.selectedCollection = this.collections[index];
  }

  setSelectedForId(id: number): number {
    const index = this.collections.findIndex(c => c.id === id);
    if (index >= 0) {
      this.setSelectedIndex(index);
    }

    return index;
  }

  clearSelected() {
    this.collections.forEach(c => c.selected = false);
    this.selectedCollection = null;
  }

  serialise() {
    const response = [];
    this.collections.forEach(collection => {
      collection.assets.forEach(asset => {
        response.push({
          collectionId: collection.id,
          assetId: asset.id,
          assetType_id: asset.assetType_id,
          assetTitle: asset.title
        });
      });
    });

    return response;
  }
}


export class CollectionAsset {
  id: number;
  title: string;
  selected: boolean;
  assets: { id: number, title: string, assetTitle: string, assetType_id: number, value: string, status: string, metobsid: number, location: string, gatewayId: string }[];
  days?: { i: string, d: Date, v: number }[];
}


export interface ICollectionForAssets {
  assets: any[];
  groups: any[];
}

@defer() {
@if(_builderItem()){
<article>
	<table class="fourd {{_builderItem().alignment}}">
		<tbody>
			@for(row of _builderItem().rows; track row.uuid; let idx = $index) {

			<tr class="row">
				@if(idx===0) {
				<th pTooltip="{{row.uuid}}" [style.padding-right.px]="_builderItem().padding" [style.padding-left.px]="_builderItem().padding">{{row.title}}</th>
				} @else {
				<td pTooltip="{{row.uuid}}" [style.padding-right.px]="_builderItem().padding" [style.padding-left.px]="_builderItem().padding">{{row.title}}</td>
				}
				@for(column of row.columns; track column.uuid) {
				@if(idx===0) {
				<th class="column">
					{{column.title}}
				</th>
				} @else {
				<td class="column" [ngClass]="{
					'sensor-no-leak':column.asset.displayValue()=== 'No Leak',
					'sensor-leak':column.asset.displayValue()=== 'Leak',
				}" [style.padding-right.px]="_builderItem().padding" [style.padding-left.px]="_builderItem().padding" pTooltip="{{column.uuid}}" [pTooltip]="column.title">
					@if(column.asset.id) {
					{{column.asset.displayValue() }}
					} @else {
					{{column.title}}
					}
				</td>
				}

				}
			</tr>
			}
		</tbody>
	</table>
</article>
}
} @placeholder (minimum 500ms) {
<p>Please wait...</p>
}

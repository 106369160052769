<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12">
        <h4>Details</h4>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-8">
        <div class="form-group">
            <label for="title"><span class="req">*</span> Organisation Title</label>
            <input autofocus id="title" name="title" maxlength="50" class="form-control" type="text" [(ngModel)]="org.title" (focus)="focus('title')">
        </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-4">
        <div class="form-group">
            <label for="isBillable"><span class="req">*</span> Billable</label>
            <select id="isBillable" name="isBillable" class="form-control" [(ngModel)]="org.isBillable" (focus)="focus('isBillable')">
                <option value="1">Yes</option>
                <option value="0">No</option>
            </select>
        </div>
    </div>

    <div class="col-xs-12 col-md-4">
        <div class="form-group">
            <label for="shortTitle"><span class="req">*</span> Short Title</label>
            <input id="shortTitle" name="shortTitle" maxlength="12" class="form-control" type="text" [(ngModel)]="org.shortTitle" (focus)="focus('shortTitle')">
        </div>
    </div>
    <!--
                <div class="col-xs-12 col-md-4">
                    <div class="form-group">
                        <label for="dateFormat">Date Format</label>
                        <input placeholder="optional" id="dateFormat" name="dateFormat" class="form-control" type="text"
                            [(ngModel)]="org.dateFormat">
                    </div>
                </div>
            -->

    <div class="col-xs-12 col-md-4" title="{{org.isActive}}">
        <div class="form-group">
            <label for="isActive"><span class="req">*</span> Status</label>
            <select id="isActive" name="isActive" class="form-control" type="text" [(ngModel)]="org.isActive" (focus)="focus('isActive')">
                <option [ngValue]="1">Enabled</option>
                <option [ngValue]="0">Disabled</option>
            </select>
        </div>
    </div>

    <div class="col-xs-12 col-sm-12">
        <div class="form-group">
            <label for="notes">Notes</label>
            <textarea [(ngModel)]="org.notes" [style]="{'height':'200px'}"></textarea>

        </div>
    </div>
    <!--
                <div class="ol-xs-12 col-md-8 mb-2">
                    <label for="loc">cert.locality</label>
                    <input id="loc" name="loc" maxlength="50" class="form-control" type="text"
                        [(ngModel)]="org.certlocality" placeholder="4dml.xx">
                    <small>Optional cert locality that links this orgs devices.</small>
                </div>
            -->
</div>

<div class="row">
    <div class="col-md-12">
        <h4> Address</h4>
        <app-address-entry titleLabel="Title" [address]="org.address" [required]="true" [canSave]="false" (gotFocus)="focus($event)">
        </app-address-entry>
    </div>
</div>
<!--
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label for="type"><span class="req">*</span> Type</label>
                <select id="type" name="type" class="form-control" [(ngModel)]="org.type">
                    <option value="owner">Owner</option>
                    <option value="contractor">Contractor</option>
                    <option value="integration">Integration</option>
                </select>
            </div>
        </div>
    -->

<div class="row">
    <div class="col-sm-12">
        <button (click)="submit()" class="btn btn-success btn-block">Submit</button>&nbsp;
    </div>
</div>

<table class="table table-condensed table-hover" *ngIf="gateways">
  <thead>
    <th>Gateway</th>
    <th class="hidden-xs">Location</th>
    <th>Site</th>
    <th>Org</th>
    <th></th>
  </thead>
  <tbody>
    <ng-template ngFor [ngForOf]="gateways" let-gateway>
      <tr *ngIf="show === 'all' || show === 'favourites' && favs[gateway.id]" [class.active]="gateway.selected">
        <td (click)="selectGateway(gateway)">
          <app-tick [selected]="gateway.selected"></app-tick>&nbsp;
          <app-gateway-indicator [gateway]="gateway"></app-gateway-indicator>{{gateway.title}}
        </td>
        <td class="hidden-xs">{{gateway.location}}</td>
        <td>{{gateway.siteTitle}}</td>
        <td>{{gateway.orgTitle}}</td>
        <td><i pTooltip="Toggle favourite gateway" (click)="clickFav(gateway)" class="mdi"
            [class.mdi-star]="favs[gateway.id]" [class.mdi-star-outline]="!favs[gateway.id]"></i></td>
      </tr>
    </ng-template>
  </tbody>
</table>
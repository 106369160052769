@defer(when servicesForTenants) {
<section>
	<div>
		<label for="title">Tenant service name </label>
		<input id="title" name="title" maxlength="50" class="form-control" [(ngModel)]="servicesForTenants.title">
	</div>
	<div>
		<label for="notes">Notes</label>
		<textarea [(ngModel)]="servicesForTenants.notes" [style]="{ 'width':'100%','height':'180px' }"></textarea>
	</div>
	<div class="buttons">
		<div class="text-right">
			<app-button label="Cancel" icon="mdi mdi-close" class="mr-1" (click)="cancel()" />
			<app-button [label]="servicesForTenants.id ? 'Update' : 'Create'" icon="mdi mdi-content-save" icon="mdi mdi-check" [isCTA]="true" (click)="submit()" />
		</div>
	</div>
</section>
}

<app-please-wait *ngIf="loading"></app-please-wait>

<div *ngIf="!loading" class="gateways-container days-{{maximumDays}}">
  <div class="gateway" *ngFor="let gateway of results">
    <div class="row">
      <div class="col-sm-12 title">
        <span title="{{gateway.id}}">{{gateway.title}}</span>
      </div>
      <div class="col-sm-12">
        <div class="table assets">

          <div *ngFor="let asset of gateway.assets">
            <span title="#{{asset.id}}" class="title">
              {{asset.title}}
            </span>
            <span class="bars">
              <app-setpoint-bars [screenAdjust]="false" [asset]="asset" [maximumDays]="maximumDays" [days]="asset.days"
                (onClick)="hasClicked($event)"></app-setpoint-bars>
            </span>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="well well-sm">Setpoint performance over the last <select (change)="maximumDaysChanged()"
      [(ngModel)]="requestedMaximumDays">
      <option value="30">30</option>
      <option value="60">60</option>
    </select> days.</div>

  <div *ngIf="results.length === 0">
    <app-info-box title="No setpoints exist" link="Add setpoint" (trayLinkClicked)="gotoList()">Your organisation
      has not created any setpoints, when these are created traffic light statuses can be tracked here.</app-info-box>
  </div>
</div>
<app-sensor-popup *ngIf="sensorSelected" [show]="!!sensorSelected" (showChange)="sensorSelected = $event" [asset]="sensorSelected" [startsAt]="startsAt"
  [endsAt]="endsAt" [showPoints]="true"></app-sensor-popup>

import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { SiteReview } from '../../../classes/reviews/review';
import { Asset } from '../../../classes/asset';
import { Point } from '../../../classes/svg/point';
import { APIService } from '../../../shared/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from '../../../shared/store.service';
import { BuildAssetFinding } from '../../../classes/reviews/asset-finding';

@Component({
  selector: 'app-review-graph',
  templateUrl: './review-graph.component.html',
  styleUrls: ['./review-graph.component.css']
})
export class ReviewGraphComponent implements OnInit {

  @ViewChild('graph', { static: true }) graph: ElementRef;

  @Input()
  review: SiteReview;

  @Input()
  asset: Asset;

  findings: BuildAssetFinding[] = [];

  slots: any[] = [];

  @Output()
  onAddFinding: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onFinishedLoading: EventEmitter<OnFinishedLoadingInterface> = new EventEmitter<OnFinishedLoadingInterface>();

  // Default is graph, true for full screen
  detailView: boolean;
  telemetry: TelemetryPacketInterface[] = [];
  showMenu: boolean;
  clickMode = 'zoomin';
  path = '';
  pathZoom = '';
  legend = '';
  legendZoom = '';
  legendZoomText: any[] = [];
  rect: any = { w: 720, h: 200 };
  viewBox = '0 0 720 220';

  throttleMove: number;
  moveDebounce: number;

  svg = {
    master: {
      legend: null,
      cursor: { x: null, y: null, telemetry: [] }
    },
    slave: {
      legend: null,
      cursor: { x: null, y: null }
    }
  };
  // Telemetry under the cursor
  telemetryHere: TelemetryPacketInterface[] = [];

  // The active finding (user has to select 'done' to create another)
  activeFinding: BuildAssetFinding;

  constructor(private storeService: StoreService, private apiService: APIService, private route: ActivatedRoute, private router: Router) { }


  toggleView() {
    this.detailView = !this.detailView;
    if (this.detailView) {
      const { width, height } = this.storeService.getPageWidth();

      this.viewBox = `0 0 ${width} 220`;
      this.rect.w = width;
      this.path = '';
      setTimeout(() => {
        this.path = this.plotAllData(this.telemetry).path;
      }, 100);

    } else {

    }

  }

  zoomIn() {
    this.clickMode = 'zoomin';
  }

  zoomOut() {
    this.clickMode = 'zoomout';
  }

  onMousemove(whichSVGElement: string, event: MouseEvent) {

    const cx = event.clientX;
    const cy = event.clientY;

    console.log(Point.mouseToSVGCords(this.graph.nativeElement, cx, cy));

    this.svg[whichSVGElement].cursor = {
      x: cx,
      y: cy,
      telemetry: this.telemetry.filter(item => Math.floor(item.x) >= cx && Math.floor(item.x) <= cx)
    };

    if (this.moveDebounce && +new Date() - this.moveDebounce < 300) {
      console.log('bounce');
      return;
    }

    const telemetry = this.telemetry.filter(item => item.x > cx);
    if (telemetry.length === 0) {
      // Out of bounds
      return;
    }
    const startDate = new Date(telemetry[0].d);
    const endDate = new Date(telemetry[0].d);

    const NoLaterThan = new Date(telemetry[telemetry.length - 1].d);

    endDate.setDate(startDate.getDate() + 7);
    const data = this.plotAllData(telemetry, true, startDate, endDate);
    this.pathZoom = data.path;

    this.legendZoom = data.legend;
    this.moveDebounce = +new Date();
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  clickSVG(whichSVGElement: string, event: any) {
    if (!this.detailView) {
      return;
    }
    const cx = event.clientX;
    const cy = event.clientY;

    console.log(Point.mouseToSVGCords(this.graph.nativeElement, cx, cy));

    if (this.activeFinding) {
      if (!this.activeFinding.telemetryChoices.start) {

      } else {
        this.activeFinding.telemetryChoices.end = this.svg[whichSVGElement].cursor.telemetry;
        this.activeFinding.telemetry.end.x = cx;
      }
    } else {
      this.activeFinding = new BuildAssetFinding({ title: 'New finding', createdAt: new Date() });
      // Pass the telemetry that exists at the pixel.
      this.activeFinding.telemetryChoices.start = this.svg[whichSVGElement].cursor.telemetry;
      this.activeFinding.telemetry.start.x = cx;
      this.findings.push(this.activeFinding);
    }
  }

  ngOnInit() {
    const cacheId = 'review:' + this.review.id + ':asset:' + this.asset.id;
    const cached = localStorage.getItem(cacheId);
    if (!cached) {
      const startsAt = this.apiService.dbDate(this.review.startsAt);
      const endsAt = this.apiService.dbDate(this.review.endsAt);

      this.apiService.getSiteReviewAssetTelemetry(this.review.id, this.asset.id, startsAt, endsAt)
        .then(r => {
          localStorage.setItem(cacheId, JSON.stringify(r));
          this.telemetry = r;
          this.path = this.plotAllData(this.telemetry).path;
        });
    } else {
      this.telemetry = JSON.parse(cached);
      this.path = this.plotAllData(this.telemetry).path;
    }
    //  });
  }

  plotAllData(telemetry, readonly?: boolean, slotDate?: Date, slotEndDate?: Date): IPlotAllDataResponse {

    let path = '', legend = { path: '', text: [] };

    const box = { x: 20, h: 200, w: this.rect.w - 40 };

    if (telemetry.length === 0) {
      console.log('NO_DATA ', this.asset.id);

      return null;
    }

    let min = 99999;
    let max = -99999
    telemetry.forEach(dataPoint => {
      if (+dataPoint.v > max) {
        max = +dataPoint.v;
      }
      if (+dataPoint.v < min) {
        min = +dataPoint.v;
      }
    });
    const negativeMin = (min < 0) ? Math.abs(min) : 0;

    const yStep = (box.h / (max - (min + negativeMin)));
    if (isNaN(yStep)) {
      debugger;
      console.error(isNaN(yStep), yStep);
    }

    if (!slotDate) {
      slotDate = new Date(this.review.startsAt);
      slotEndDate = new Date(this.review.endsAt);
    }

    const rangeInMinutes = (Math.abs(+slotDate - +slotEndDate) / 60000);
    // Each minute plots to this column in the rectangle.
    const xStep = box.w / rangeInMinutes;

    console.log('Minutes to fit in ', box.w, rangeInMinutes);
    const telementryLength = telemetry.length;
    let command = 'M';


    if (readonly) {
      legend.path = `M ${box.x} 0 L ${box.x} ${box.h + 1} M ${box.x} ${box.h + 1} L ${box.w} ${box.h + 1} `;
      const legendDate = new Date(+slotDate);
      const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const monthNames = ['January', 'February', 'March', 'Apr', 'May', 'June', 'July', 'August', 'Sept', 'Oct', 'Nov', 'Dec'];
      for (let index = +slotDate; index < +slotEndDate; index += 86400000) {
        const startOfDay = new Date(legendDate.getFullYear(), legendDate.getMonth(), legendDate.getDate(), 0, 0, 0, 0);

        const minutesSinceStartDate = ((+startOfDay - +slotDate) / 60000);
        if (minutesSinceStartDate > 0) {
          const x = box.x + (+Number(minutesSinceStartDate * xStep).toFixed(2));
          legend.path += `M ${x} 0 L ${x} ${box.h} `;

          legend.text.push({ type: 'day', align: 'left', x, y: box.h + 10, text: dayNames[startOfDay.getDay()] + ' ' + startOfDay.getDate() + ' ' + monthNames[startOfDay.getMonth()] });

        }

        legendDate.setDate(legendDate.getDate() + 1);
      }
      legend.text.push({ x: 0, y: 10, text: max, align: 'right' });
      legend.text.push({ x: 0, y: box.h - 2, text: min, align: 'right' });
    }

    let y;
    for (let index = 0; index < telementryLength; index++) {
      const element = telemetry[index];
      const dt = new Date(element.d);
      const minutesSinceStartDate = (Math.abs(+slotDate - +dt) / 60000);
      const x = +Number(minutesSinceStartDate * xStep).toFixed(2);

      y = box.h - (+element.v >= 0 ? (+element.v - (min + negativeMin)) * yStep : Math.abs(element.v) * yStep);
      // console.log(dt, x, +element.v, min, negativeMin, y, yStep);
      if (isNaN(y)) {
        debugger;
      }

      if (typeof y !== 'undefined' && typeof x !== 'undefined') {
        if (!readonly) {
          element.x = box.x + x;
          element.y = y;
        }
        path += `${command} ${box.x + x} ${y} `;
        command = 'L';
      }
    }
    // this.onFinishedLoading.emit({ asset: this.asset, minimum: min, maximum: max });

    return { path, legend };
  }


  generatePath() {

  }

  plotDataByHour() {

    /* Only interested in this assets telemetry */
    const assetData = this.review.telemetry.filter(dataPoint => dataPoint.asset_id === this.asset.id);
    /* Find the lowest and highest telemetry values */
    let min = 99999;
    let max = -99999
    assetData.forEach(dataPoint => {
      if (+dataPoint.maximum > max) max = +dataPoint.maximum;
      if (+dataPoint.minimum < min) min = +dataPoint.minimum;
    });

    let negativeMin = (min < 0) ? Math.abs(min) : 0;

    const yStep = (this.rect.h / (max - (min + negativeMin)));
    if (isNaN(yStep)) {
      debugger;
    }
    if (assetData.length === 0) {
      return console.log('NO_DATA ', this.asset.id);
    }
    /* Slot each value into their date+hour slot ready for plotting */

    const slotDate = new Date(this.review.endsAt);
    let slotHour = 23;
    const lastDay = new Date(this.review.startsAt);
    let isBuildingSlots = true;
    let startFromIndex = 0;
    while (isBuildingSlots) {

      let yMax, yMin;
      for (let index = startFromIndex; index < assetData.length; index++) {
        const element = assetData[index];
        const dt = new Date(element['DATE(av.createdAt)']);
        if (element.hour === slotHour && dt.toDateString() === slotDate.toDateString()) {
          yMax = this.rect.h - ((+element.maximum - (min + negativeMin)) * yStep);
          yMin = this.rect.h - (+element.minimum >= 0 ? (+element.minimum - (min + negativeMin)) * yStep : Math.abs(element.minimum) * yStep);
          if (isNaN(yMax) || isNaN(yMin)) {
            debugger;
          }
          // No need to look at indexes before for next hour */
          startFromIndex = index + 1;
          break;
        }
      }

      const slot = { slotHour, slotDate, yMax, yMin };
      this.slots.push(slot)
      slotHour--
      if (slotHour < 0) {
        // Check here as we want 24 hours of the last day
        isBuildingSlots = !(slotDate.toDateString() === lastDay.toDateString());
        slotHour = 23;
        slotDate.setDate(slotDate.getDate() - 1);
      }
    }

    let x = this.rect.w - 10;

    for (let index = 0; index < this.slots.length; index++) {
      const element = this.slots[index];
      this.path += `M ${x} ${element.yMin} L ${x} ${element.yMax} `;
      if (element.slotHour === 0) {
        this.legend += `M ${x} ${0} L ${x} ${this.rect.h}`;
      }
      x--;
    }
    this.onFinishedLoading.emit({ asset: this.asset, minimum: min, maximum: max });

  }

  trashFinding(index: number) {
    const finding = this.findings[index];

    if (finding.trackid === this.activeFinding.trackid) {
      this.activeFinding = null;
    }
    this.findings.splice(index, 1);
  }

  submitFinding(finding: BuildAssetFinding) {
    this.apiService.postSiteReviewFindings(this.review).then(results => {
      for (let index = 0; index < this.findings.length; index++) {
        let element = this.findings[index];
        if (element.trackid === finding.trackid) {
          element = finding;
        }
      }
      this.activeFinding = null;
    });
  }
}

export interface OnFinishedLoadingInterface {
  asset: Asset;
  minimum: number;
  maximum: number;
}

export interface TelemetryPacketInterface {
  v: number;
  d: Date;
  x?: number;
  y?: number;
}

export interface IPlotAllDataResponse {
  path: any;
  legend: any;
}

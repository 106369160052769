import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Document } from 'app/classes/document';
import { Building } from 'app/classes/building';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private http: HttpClient, private apiService: APIService) { }

  get(querystring?: string): Promise<Document[]> {
    const url = `${this.apiService.getUserAPI()}/documents?v=2&offset=0&limit=20&${querystring}`;
    return this.http
      .get<any[]>(url, { headers: this.apiService.getHttpHeaders() })
      .toPromise()
      .then(response => {
        return response.map(document => new Document(document));
      });
  }

  getForBuilding(buildingId: number): Promise<Building> {
    const url = `${this.apiService.getUserAPI()}/documents?buildingid=${buildingId}&building=1`;
    return this.http
      .get<any>(url, { headers: this.apiService.getHttpHeaders() })
      .toPromise()
      .then(response => {
        return new Building(response);
      });
  }

  putNotes(document: Document) {
    const url = `${this.apiService.getUserAPI()}/documents?id=${document.id}`;
    return this.http
      .put<any>(url, { notes: document.notes, notesColour: document.notesColour }, { headers: this.apiService.getHttpHeaders() })
      .toPromise();
  }
}

<article *ngIf="rule.hasIntegration">
    <app-theme-page-section [label]="'elogbooks'" logo="elogbooks">
        <section>
            <label>Summary</label>
            <div>{{rule.integration.states.new.summary}}</div>
            <label>Description</label>
            <div>{{rule.integration.states.new.description}}</div>
            <label>Priority</label>
            <div>{{rule.integration.states.new.priorityTitle}}</div>
            <label>Site</label>
            <div>{{rule.integration.states.new.siteTitle}}</div>
        </section>
    </app-theme-page-section>
</article>
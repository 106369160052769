import { Component, OnInit, signal } from '@angular/core';
import { BillingService } from "../../shared/billing.service";

@Component({
  selector: 'app-billing-home',
  templateUrl: './billing-home.component.html',
  styleUrls: ['./billing-home.component.css']
})
export class BillingHomeComponent implements OnInit {

  tabIndex = signal<number>(0);

  constructor(private billingService: BillingService) { }

  ngOnInit(): void {

  }

}

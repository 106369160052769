<div class="tabs">

    <div class="tab" [class.active]="tabs.active==='user'" (click)="tabs.active='user'">
        <h6><i class="fa fa-fw fa-users"></i>
            <span class="hide-mobile">
                Users
                <span *ngIf="users" class="count">
                    ({{users.length}})
                </span>
            </span>
        </h6>
    </div>
    <div class="tab" [class.active]="tabs.active==='org'" (click)="tabs.active='org'">
        <h6><i class="fa fa-fw fa-sitemap"></i>
            <span class="hide-mobile">
                Orgs
            </span>
        </h6>
    </div>
    <div class="tab" [class.active]="tabs.active==='gateway'" (click)="tabs.active='gateway'">
        <h6><i class="fa fa-fw fa-microchip"></i>
            <span class="hide-mobile">
                Gateways
                <span *ngIf="gateways" class="count">
                    {{gateways.length}}
                </span>
            </span>
        </h6>
    </div>
    <div class="tab" [class.active]="tabs.active==='asset'" (click)="tabs.active='asset'">
        <h6><i class="fa fa-fw fa-cubes"></i>
            <span class="hide-mobile">
                Assets
                <span *ngIf="assets" class="count">
                    {{assets.length}}
                </span>
            </span>
        </h6>
    </div>
</div>

<div class="tab-container">
    <div class="shared">
        <button (click)="create(tabs.active)"> <span class="mdi mdi-pencil-plus-outline"></span> Create
            {{tabs.active}}</button>
    </div>
    <div *ngIf="tabs.active === 'org'">

        <table class="">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Short</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let org of orgs" (click)="edit(org)">
                    <td>{{org.title}}</td>
                    <td>{{org.shortTitle}}</td>
                    <td>{{org.isActive ? 'Enabled' : 'Disabled'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="tabs.active === 'user'">

    </div>
    <div *ngIf="tabs.active === 'gateway'">

    </div>
</div>

<div *ngIf="createObject" class="popup">
    <app-manage-create [object]="createObject" (didSave)="saved()"></app-manage-create>
</div>

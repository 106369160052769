import { Component, OnInit, Input } from '@angular/core';
import { Asset } from '../../classes/asset';
import { ActivatedRoute, Router } from '@angular/router'
import { CameraService } from '../../shared/camera.service';
import { AssetPreset } from '../../classes/asset-preset';

@Component({
  selector: 'app-camera-shot',
  templateUrl: './camera-shot.component.html',
  styleUrls: ['./camera-shot.component.css']
})
export class CameraShotComponent implements OnInit {

  @Input()
  camera: Asset;
  @Input()
  preset: AssetPreset;
  // animations that only happen onload
  first = true;

  onView = 'Recent';

  constructor(private router: Router, private cameraService: CameraService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    setTimeout(() => {
      this.first = false;
    }, 2000);
  }

  buttonClick(event: any) {
    const id = event.target.id;

    switch (id) {
      case 'night':
        if (this.preset.shotNight) {
          this.onView = 'Night';
        }
        break;
      case 'day':
        if (this.preset.shotDay) {
          this.onView = 'Day';
        }
        break;
      case 'recent':
        this.onView = 'Recent';
        break;
    }

  }

  mousemove(e: any) {
    console.log(e);
  }
}

@defer() {
@if(isReady) {
<article>
	@if(sitePlanComplete?.sitePlan.hasOccupancy) {
	<header>
		<app-site-plan-floorplan-toolbar [view]="view" [sitePlanComplete]="sitePlanComplete" (onToolbarChanged)="toolbarChanged($event)" />
	</header>
	}
	<main>
		<app-site-plan-floorplan [config]="{planId,siteId, dates}" (onFloorplanLoaded)="floorplanLoaded($event)" [view]="view"></app-site-plan-floorplan>
	</main>
</article>
}
} @placeholder (minimum 100ms) {

}

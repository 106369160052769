<article *ngIf="insight?.configuration?.start" class="mb-1">
	<section class="header mb-2">
		<app-insights-as-page1 [insight]="insight" [building]="building" [headerTitle]="headerTitle">
		</app-insights-as-page1>
	</section>

	<section id="report">
		<div *ngIf="insight.stage === 'new'">

			<app-insights-as-admin [insight]="insight" [adminAnnotations]="adminAnnotations" *ngIf="isAdmin"
				[weather]="weather" (onUpdated)="insightsAdminUpdated($event)">
			</app-insights-as-admin>
			<div *ngIf="!isAdmin">
				<h2>This report has not yet been prepared.</h2>
			</div>
		</div>
		<div *ngIf="insight.stage === 'done'">
			<div *ngFor="let id of assetIds" class="mt-1 charts">
				<app-theme-page-section [label]="insight.telemetry[id].asset.title" [rag]="insight.telemetry[id].review.rag"
					[canChangeRag]="false" [toolbar]="[{ icon:'',text:insight.telemetry[id].chartCompleted?.dataMin }]">
					<div class="chart">
						<app-d3-chart [asset]="insight.telemetry[id].asset" [hasToClickToInteract]="true" [canAnnotate]="true"
							[canZoom]="false" height="200" [telemetry]="insight.telemetry[id].telemetry" [showLegend]="true"
							[minMaxFromData]="true" [weather]="weather">
						</app-d3-chart>
					</div>
					<div>

					</div>
					<p class="form-control">{{insight.telemetry[id].review.annotation}}</p>
				</app-theme-page-section>
			</div>
		</div>
	</section>
</article>

<app-please-wait *ngIf="!isReady"></app-please-wait>
